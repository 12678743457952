<mat-form-field *ngIf="documents != null && documents.length > 0" class="{{cssClass}}">
    <mat-label class="placeholder">{{placeholder}} <i class="fa fa-question-circle-o warning-color" title="{{placeholderHelp}}"></i></mat-label>
    <mat-select  [(ngModel)]="selectedDocumentID" (selectionChange)="documentChanged()">
        <mat-option *ngFor="let d of documents" [value]="d.id" class="auto">
            {{d.name}}
            <app-view-document-link *ngIf="d.canRead === true" [documentID]="d.id" [label]="''" [title]="d.name" [showIcon]="true" [showLabel]="false" [class]="'float-right'"></app-view-document-link>
            <i class="fa fa-times-circle float-right" *ngIf="d.canDelete === true" (click)="removeDoc(d)"></i>
            <i class="fa-fa-eye float-right" *ngIf="d.canEdit === true" (click)="editDoc(d)"></i>
        </mat-option>
    </mat-select>
</mat-form-field>