import { Router } from '@angular/router';
import { AppBase } from '../../models/base.model';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { NgZone, Injectable } from '@angular/core';
import { LocalCache, LocalKVP } from '../../models/appCommon.models';
import { DataService } from '../../util/APICaller.component';
import { of, throwError } from 'rxjs';
import { clearSession } from '../sessionService';

export class BaseService extends AppBase {

    protected static _cache: LocalCache = new LocalCache();

    protected requestingModule: string = '';
    protected router: Router;
    constructor(r: Router, sModule: string = '', protected _dataService: DataService = null) {
        super(); this.router = r;
        this.requestingModule = sModule;
    }

    public IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    protected onError(err: any): Observable<any> {
        if (err && (err.status === '401' || err.status === '404' || err.status === '403' || err.status === 403 || err.status === 401 ||  err.status === 404)) {
                clearSession();
                this.router.navigate(['default']);
            }

        // tslint:disable-next-line: align
        return throwError(new Error(err));
    }

    protected getCacheData(sKey: string, oData: any = null, secured: boolean = true): Observable<any> {
        const _ep = sKey;
        const _v = BaseService._cache.getValue(_ep);
        if (_v != null)
            return of({ data: _v });
        else {
            const data = oData;
            let _s: any;

            if (secured == null || secured === true)
                _s = this._dataService.APIPost(_ep, data, this.requestingModule);
            else
                _s = this._dataService.APIPreLoginPost(_ep, data, this.requestingModule);

            if (_s != null) {
                _s.subscribe((_o) => {
                    if (_o != null && _o.data != null) {
                        BaseService._cache.add(new LocalKVP(_ep, _o.data));
                        return of({ data: _v });
                    }
                });
                return _s;
            } else
                return of({ data: [] });
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class StreamService {

    evs: EventSource;
    private subj = new BehaviorSubject([]);

    constructor(private http: HttpClient, private _zone: NgZone) { }

    returnAsObservable() {
        return this.subj.asObservable();
    }

    GetExchangeData(sURL: string) {
        const subject = this.subj;

        if (typeof (EventSource) !== 'undefined') {
            this.evs = new EventSource(sURL);
            this.evs.onopen = function (e) {
                console.log('“Opening connection.Ready State is ' + this.readyState);
            };

            this.evs.onmessage = function (e) {
                console.log('Message Received.Ready State is ' + this.readyState);
                subject.next(JSON.parse(e.data));
            };

            this.evs.addEventListener('timestamp', function (e) {
                console.log('Timestamp event Received.Ready State is ' + this.readyState);
                subject.next(e['data']);
            });

            this.evs.onerror = function (e) {
                console.log(e);
                if (this.readyState === 0) {
                    console.log('“Reconnecting…');
                }
            };

        }
    }

    getServerSentEvent(url: string, sessionID: string = ''): Observable<any> {
        return new Observable(observer => {
            const eventSource = this.getEventSource(url, sessionID);
            eventSource.onmessage = event => {
                this._zone.run(() => {
                    observer.next(event);
                });
            };
            eventSource.onerror = error => {
                this._zone.run(() => {
                    observer.error(error);
                });
            };
        });
    }

    stopExchangeUpdates() {
        this.evs.close();
    }

    private getEventSource(url: string, sessionID: string = ''): EventSource {
        return new EventSource(url, {
            withCredentials: true
        });
    }
}
