import { Component, ViewChild, Inject} from '@angular/core';
import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { DatePickerComponent } from '../../common/datepicker.component';
import { ListingService } from '../listing.service';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-add-visit-dialog',
    templateUrl: './addVisit.dialog.html'
})
export class AddVisitDialog extends BaseDialog {

    id: number = 0;
    forOpenHouse: boolean = false;

    readOnly: boolean = false;
    action: string = '';
    visitDate: Date = this.today;
    visitTime: any = moment().hour(8).minute(0);
    duration: number = 15;
    maxDate: Date = moment(this.today).add(30, 'days').toDate();

    @ViewChild('txtVisitDate') txtVisitDate: DatePickerComponent;

    dialogHeader: string = 'Visit';
    constructor(dataservice: DataService, r: Router, dialogRef: MatDialogRef<AddVisitDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addVisitDialog', dataservice, r, dialogRef, data);

        if (data) {

            if (data.id) this.id = data.id;
            if (data.forOpenHouse) this.forOpenHouse = data.forOpenHouse;
            if (data.visitDate) this.visitDate = data.visitDate;
            if (data.action) this.action = data.action;
            if (data.duration) this.duration = data.duration;
            if (data.header)
                this.dialogHeader = data.header;
            else {
                if (this.forOpenHouse === true)
                    this.dialogHeader = (this.action === 'change' ? 'Change' : 'New') + ' Open House';
                else if (this.action === 'confirm')
                    this.dialogHeader = 'Confirm Visit Request';
                else if (this.action === 'change')
                    this.dialogHeader = 'Change Visit Request';
                else
                    this.dialogHeader = 'New Visit Request to Seller';
            }
        }

        this.readOnly = this.action === 'confirm';
    }

    get readyForSubmit() {
        return !this.busy && this.id > 0 && this.duration > 0 && !moment(this.visitDate).isSame(this.Utils.dateTimeMinValue);
    }

    visitDateChanged($value) {
        this.error = null;
        if ($value)
            this.visitDate = $value;
        else
            this.visitDate = this.today;
    }

    addVisit() {
        this.visitDate = this.txtVisitDate.value;

        this.visitDate = this.txtVisitDate.value;
        this.visitDate.setHours(0, 0, 0, 0);
        if (moment(this.visitTime).isValid()) {
            this.visitDate.setHours(moment(this.visitTime).utc(true).toDate().getHours(), moment(this.visitTime).toDate().getMinutes(), 0, 0);
        } else {
            let _v = (this.visitTime as string).split(' ');

            const _pm: boolean = (_v.length > 1 && _v[1] && _v[1].toString().toLowerCase() === 'pm');
            _v = _v[0].split(':');

            let _hr = parseInt(_v[0]);
            let _min = 0;
            if (_v.length > 1) _min = parseInt(_v[1]);

            if (_hr < 12 && _pm) _hr += 12;

            if (!this.forOpenHouse)
                this.visitDate = moment(this.visitDate).utc(true).add(_hr, 'hours').add(_min, 'minutes').toDate();

        }

        let _response: any;
        this.busy = true;

        if (this.action != null && this.action === 'confirm') {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).confirmViewing(this.id, this.visitDate, parseInt(this.duration.toString())).subscribe(
                (data) => _response = data
                , (error) => { this.close(null); this.onApiError(error); }
                , () => { this.busy = false; this.onActionComplete(_response); }
            );
        } else if (this.action != null && this.action === 'change') {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).changeViewing(this.id, this.visitDate, parseInt(this.duration.toString())).subscribe(
                (data) => _response = data
                , (error) => { this.close(null); this.onApiError(error); }
                , () => { this.busy = false; this.onActionComplete(_response); }
            );
        } else if (this.action == null || this.action != null) {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).addViewing(this.id, this.visitDate, parseInt(this.duration.toString()), this.forOpenHouse).subscribe(
                (data) => _response = data
                , (error) => { this.close(null); this.onApiError(error); }
                , () => { this.busy = false; this.onActionComplete(_response); }
            );
        }
    }

    onActionComplete(response: any) {
        this.busy = false;
        if (response) {
            if (Utils.isNullOrEmpty(response.error)) {
                this.close(response.data);
            } else {
                this.error = response.error;
            }
        }
    }
}
