import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DataService } from '../util/APICaller.component';
import { Router} from '@angular/router';

import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';
import { Utils } from './utils';

@Component({
    selector: 'app-new-loan',
    template: `<a (click)=startNewLoan() class='{{class}}'>{{label}}</a>`
})
export class NewLoanComponent extends BaseComponent {

    @Input() label: string = 'Start New Loan Application';
    @Input() class: string;

    @Output() public loanAdded: EventEmitter<object> = new EventEmitter<object>();

    constructor(private dataservice: DataService, ro: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('ucLoans', ro, g);
    }

    startNewLoan() {
        let responseData;
        this.dataservice.APIPost('/loan/createNewApplication', null, this.myResourceCategory).subscribe(
            (data) => { responseData = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (responseData)
                    if (!Utils.isNullOrEmpty(responseData.error)) {
                        this.showError(responseData.error);
                    } else {
                        let _s = '';

                        if (responseData.data)
                            _s = 'Loan Application # ' + (responseData.data as string) + ', ';

                        this.showMessage(_s + this.geti18nTag('Loan.NewLoanApplicationCreated'));

                        this.loanAdded.emit(responseData.data);
                    }
            }
        );
    }
}
