<div class="appContent">
    <ng-template [ngIf]="(this.escrowID && this.escrowID > 0)" [ngIfElse]="offerSummary">
        <h2>Escrow/Purchase Summary</h2>
    </ng-template>
    <ng-template #offerSummary>
        <ng-template [ngIf]="(this.offerID && this.offerID > 0)" [ngIfElse]="listingSummary">
            <h2>Offer Summary</h2>
        </ng-template>
        <ng-template #listingSummary>
            <h2>Listing Summary</h2>
        </ng-template>
    </ng-template>
        <mat-card *ngIf="showHeader && listings && listings.length > 0" class="section">
            <mat-form-field>
                <mat-select [(ngModel)]="listingID">
                    <mat-label class="placeholder">Properties<i class="fa fa-question-circle-o warning-color" title="Properties for Sale"></i></mat-label>
                    <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card>
        <div *ngIf="statusData != null">
            <mat-card *ngIf="!showHeader || listings == null || listings.length == 0">
                <a hrefToRouterLink [innerHTML]="statusData.address | safeHtml" (click)="details()" class="link"></a>
            </mat-card>
            <br />
            <div *ngIf="!utils.isNullOrEmpty(statusData.parcel)">
                <label class="caption">APN/Parcel#</label><label class="data-value" auto>{{statusData.parcel}}</label>
                <hr />
            </div>
            <mat-card class="section">
                <div class="app-flex-container app-2col" *ngIf="statusData.escrowOpen && statusData.escrowOpen == true">
                    <div><label class="caption">Buyers</label><label class="data-value" auto>{{statusData.buyers}}</label></div>
                    <div *ngIf="!utils.isNullOrEmpty(statusData.buyerAgent)"><label class="caption">Agent/Broker</label><label class="data-value" auto>{{statusData.buyerAgent}}</label></div>
                </div>
                <div class="app-flex-container app-2col">
                    <div><label class="caption">Sellers</label><label class="data-value" auto>{{statusData.sellers}}</label></div>
                    <div *ngIf="!utils.isNullOrEmpty(statusData.listingAgent)"><label class="caption">Agent/Broker</label><label class="data-value" auto>{{statusData.listingAgent}}</label></div>
                </div>
                <div *ngIf="(this.escrowID && this.escrowID > 0) || (this.offerID && this.offerID > 0)">
                    <hr />
                    <span><label class="caption">Purchase Price</label><label class="data-value" auto>{{statusData.price}}</label></span>
                    <span *ngIf="statusData.deposit > 0"><label class="caption">Deposit Required</label><label class="data-value" auto>{{statusData.deposit}}</label></span>
                </div>
            </mat-card>
            <mat-card *ngIf="statusData.escrowOpen === true" class="section">
                <div><label class="caption">Escrow Company</label><label class="data-value" auto>{{statusData.escrowCompany}}</label></div>
                <div><label class="caption">Closing Date</label><label class="data-value" auto>{{statusData.closingDate}}</label></div>
            </mat-card>
            <mat-card class="section">
                <ngx-datatable class="material expandable"
                               [headerHeight]="headerHeight" [footerHeight]="0" [rowHeight]="'auto'"
                               [rows]="carItems" [columnMode]="'force'" [limit]="50">
                    <ngx-datatable-column name="Item">
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">{{row.Description}}</ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                            <div hrefToRouterLink [innerHTML]="row.Value | safeHtml"></div>
                            <a *ngIf="row.messageTo != null" (click)="sendMessage(row.MessageTo)"><span class='fa-stack'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-comment fa-stack-1x fa-inverse'></i></span></a>
                            <a *ngIf="!utils.isNullOrEmpty(row.actionURL)" (click)="gotoRoute(row.ActionURL)" title="View/Complete"><i class='fa fa-step-forward'></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template
                                     let-rowCount="rowCount"
                                     let-pageSize="pageSize"
                                     let-selectedCount="selectedCount"
                                     let-curPage="curPage"
                                     let-offset="offset">
                            <div><mat-checkbox>Show completed Items</mat-checkbox></div>
                            <div class="ngxDataTableFooter">
                                Total Items: {{rowCount}} |
                                Items per Page: {{isNaN(pageSize) ? "" : pageSize}} |
                                Current Page #: {{isNaN(pageSize) ? "" : curPage}}
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </mat-card>
        </div>
</div>