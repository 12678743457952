import { Component, ChangeDetectorRef, ViewChild, OnInit, Input, Inject, AfterViewInit } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';

import { item, Payment, propertyType } from '../models/appCommon.models';
import { appEnvironment } from '../../environments/environment';
import { AddressComponent } from '../common/address.component';

import { ProductService } from '../common/service/product.service';
import { newListing, Role, Address } from '../models';
import { PersonService } from '../account/person.service';
import { handover } from '../models/constants';
import { delay } from 'rxjs/operators';
import { Utils, rolePosition } from '../common/utils';

@Component({
    selector: 'app-new-seller-listing',
    templateUrl: './newSellerListing.component.html'
})
export class SellerNewListingComponent extends BaseComponent implements AfterViewInit {

    isoCountryCode: number;
    stateID: number;

    legalVersionID: number;
    legalContent: string;

    @ViewChild('listingAddress') ucListingAddress: AddressComponent;
    newListing: newListing = new newListing();
    @Input() renewListingID: number = 0;
    propertyTypes: item[] = [];
    saving: boolean = false; saveReady: boolean = false;
    hasTerms: boolean = false;

    constructor(private dataservice: DataService, r: Router, @Inject(GlobalComponent) gc: GlobalComponent) {
        super('pgSellerNewListing', r, gc);

        this.newListing.propertyProductID = appEnvironment.defaultPropertyProductID;
        // Server does not allow to use Agent Account to BUY/SELL for personal properties.
        // DISABLE Change.
        this.newListing.isAgent = this.gc.hasRole(rolePosition.reAgent);

        const _id = parseInt(sessionStorage.getItem(handover.renewListingID));
        if (_id != null && !this.isNaN(_id))
            this.renewListingID = _id;

        sessionStorage.removeItem(handover.renewListingID);

        let _response;
        (new ProductService(this.dataservice, this.route, this.myResourceCategory)).getListingProducts().subscribe(
            (data) => { _response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response && _response.data) {
                    this.propertyTypes = _response.data.map((pt) => { return Utils.castTo(pt, new propertyType()); });
                }
            }
        );

        this.legalVersionID = 0;

        if (this.renewListingID > 0) {
            this.loadTerms(1000);

            let _renewResponse;
            (new PersonService(this.dataservice, this.route, this.myResourceCategory)).initRenewListing(this.renewListingID).subscribe(
                (data) => { _renewResponse = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_renewResponse) {
                        if (Utils.isNullOrEmpty(_renewResponse.error) && (_renewResponse.data)) {
                            this.newListing.propertyProductID = _renewResponse?.data?.productID ?? 0;
                            this.newListing.commission = _renewResponse?.data?.commission ?? 0;
                            this.newListing.listingPrice = _renewResponse?.data?.todaysPrice ?? 0;
                            this.legalContent = _renewResponse?.data?.content ?? '';
                            this.hasTerms = Utils.isNullOrEmpty(this.legalContent);
                            if (!Utils.isNullOrEmpty(this.legalContent))
                                this.legalVersionID = _renewResponse?.data?.versionID ?? 0;
                            else {
                                this.legalVersionID = 0;
                            }
                        }
                    }
                }
            );
        } else {
            this.propertyTypeChanged();
        }
    }

    ngAfterViewInit() {
        this.setSaveReady();
    }

    setSaveReady() {
        this.newListing.tncVersionID = this.legalVersionID;
        this.saveReady = (this.newListing.propertyProductID ?? 0) > 0 && (this.hasTerms ? (this.newListing?.tncAgreed ?? false) : true);
    }

    propertyTypeChanged() {
        this.loadTerms();

        let _response;
        (new ProductService(this.dataservice, this.route, this.myResourceCategory)).getProductDetails(this.newListing.propertyProductID, this.newListing.sellingPrice).subscribe(
            (data) => { _response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                this.newListing.listingPrice = _response?.data?.todaysPrice ?? 0;
                this.newListing.commission = _response?.data?.commission ?? 0;
            }
        );
    }

    addressChanged($event) {
        let _changedForTNC: boolean = false;
        const _address = $event.address != null ? $event.address : $event;

        if (this.newListing.address == null)
            this.newListing.address = new Address();

        if (_address) {
            _changedForTNC = (_address.ISOCountryCode > 0 || _address.StateID > 0)
                && (this.newListing.address.ISOCountryCode !== _address.ISOCountryCode || this.newListing.address.StateID !== _address.StateID);

            this.newListing.address = _address;
            this.setSaveReady();

            if (_changedForTNC === true) this.loadTerms();
        }
    }

    expiryDateChanged(value) {
        if (value)
            this.newListing.expiryDate = value;
        else
            this.newListing.expiryDate = this.Utils.dateTimeMinValue;
    }

    onPaymentStatus($event) {
        const e = $event;

        // Call Listing Purchase on Approval.
        if (e.Errors == null || e.Errors.length === 0) {
            const _r = { id: e.Target.Id, authCode: e.Target.ProcessorAuthorizationCode, amount: this.newListing.listingPrice };
            this.submit();
        }
    }

    onDropinLoaded($event) { }

    onAgreementChanged($event) {
        this.newListing.tncVersionID = this.legalVersionID;
        this.setSaveReady();
    }

    submit(oPayment: any = null) {

        const _payment = new Payment();
        if (oPayment) {
            if (oPayment.id) _payment.authorizationID = oPayment.id;
            if (oPayment.authCode) _payment.authorizationCode = oPayment.authCode;
            if (oPayment.amount) _payment.amount = oPayment.amount;
        }
        this.newListing.payment = _payment;

        if (this.ucListingAddress)
            this.newListing.address = this.ucListingAddress.Address;

        if (Utils.isNullOrEmpty(this.newListing.address.StreetName)) {
            if (!confirm('ALERT. You have not provided address. Are you sure you want to proceed?'))
                return;
        }
        // Server does not allow to use Agent Account to BUY/SELL for personal properties.
        // DISABLE/FORCE Change.
        this.newListing.isAgent = this.gc.hasRole(rolePosition.reAgent);
        this.newListing.renewListingID = this.renewListingID;
        this.newListing.address.Ownership = 2; // OWN
        this.gc.setBusy(true);

        let _response;
        (new PersonService(this.dataservice, this.route, this.myResourceCategory)).buyNewListing(this.newListing).subscribe(
            (data) => { this.gc.setBusy(false); _response = data; }
            , (error) => { this.gc.setBusy(false); this.onApiError(error); }
            , () => {
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (_response.data) {
                        this.showMessage('Your New Listing request Order has been processed.');
                        if (_response.data.id) {
                            this.gotoListing(_response.data.id);
                        } else
                            this.GotoWelcome();
                    }
                }
            }
        );
    }


    private async loadTerms(iDelayedFor: number = 0) {

        if (this.newListing.propertyProductID === 0) return;

        this.legalContent = '';
        this.legalVersionID = 0;

        if (iDelayedFor > 0)
            await delay(iDelayedFor);

        let responseData: any;

        const _isoCountryCode = this.ucListingAddress?.Address?.ISOCountryCode ?? (appEnvironment?.defaultISOCountryCode ?? 0);
        const _stateID = this.ucListingAddress?.Address?.StateID ?? 0;

        (new ProductService(this.dataservice, this.route, this.myResourceCategory)).getLegalTerm(this.newListing.propertyProductID, Role.Seller, _isoCountryCode, _stateID).subscribe(
            (data) => { responseData = data; }
            , (error) => { this.newListing.tncAgreed = false; this.onApiError(error); }
            , () => {
                if (responseData && responseData.data) {
                    this.legalContent = responseData.data.content;
                    if (!Utils.isNullOrEmpty(this.legalContent)) {
                        // Retain TNC Agreement if the TNC Agreement done for the Same Version
                        this.newListing.tncAgreed = this.newListing.tncAgreed && this.legalVersionID > 0 && this.legalVersionID === responseData.data.versionID && responseData.data.versionID > 0;
                        this.hasTerms = true;
                        this.legalVersionID = responseData.data.versionID;
                    } else {
                        this.newListing.tncAgreed = false;
                        this.hasTerms = false;
                        this.legalVersionID = 0;
                    }
                }
            }
        );
    }
}
