<div class="appContent">
	<h2 class="pageHeader">Escrow Details</h2>
	<div class="app-flex-container app-2col" *ngIf="componentBusy === false">
		<ng-template [ngIf]="(this.listings && this.listings.length > 0)" [ngIfElse]="noListings">
			<mat-form-field class="first" *ngIf="listings && listings.length > 0">
				<mat-label class="placeholder">Properties<i class="fa fa-question-circle-o warning-color" title="Properties for Sale"></i></mat-label>
				<mat-select [(ngModel)]="listingID" (selectionChange)="setListingID(this.listingID)">
					<mat-option *ngFor="let l of listings" [value]="l.id" class="auto">{{l.address}}</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-template>
		<ng-template #noListings>
			<span>Currently there are no properties in processing</span>
		</ng-template>
		<ng-template [ngIf]="(this.escrows && this.escrows.length > 0)" [ngIfElse]="noEscrows">
			<mat-form-field class="first" *ngIf="escrows && escrows.length > 0">
				<mat-label class="placeholder">Escrow for Properties<i class="fa fa-question-circle-o warning-color" title="Escrow in Progress"></i></mat-label>
				<mat-select [(ngModel)]="escrowID" (selectionChange)="onEscrowListing()" [disabled]="escrows.length === 1">
					<mat-option *ngFor="let e of escrows" [value]="e.id">Escrow ID # {{e.id}}</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-template>
		<ng-template #noEscrows>
			<span *ngIf="listingID === 0 && (this.listings && this.listings.length > 0)">Please select a property from the list (if any)  in Escrow.</span>
		</ng-template>
	</div>
	<mat-progress-bar *ngIf="componentBusy === true" mode="indeterminate"></mat-progress-bar>
	<div *ngIf="currentEscrow && currentEscrow.id > 0">
		<hr />
		<div class="section message warning-background" *ngIf="this.currentEscrow">
			<label class="caption">Escrow Status</label><label class="data-value auto">{{this.currentEscrow.statusName}}<span *ngIf="currentEscrow.currentStatusDate"><i class="fa fa-calendar" title="{{utils.DisplayDateTime(currentEscrow.currentStatusDate)}}"></i></span></label>
		</div>
		<mat-card *ngIf="showHeader || currentEscrow.isBuyer" class="section">
			<div *ngIf="showHeader" class="app-flex-container app-2col">
				<div><label class="caption">Seller(s)</label><label class="data-value auto">{{currentEscrow.sellers}}</label></div>
				<div><label class="caption">Buyers(s)</label><label class="data-value auto">{{currentEscrow.buyers}}</label></div>
			</div>
			<mat-form-field *ngIf="buyerLiens && buyerLiens.length > 0">
				<mat-label class="placeholder">Loan(s)</mat-label>
				<mat-select style="width:Calc(100% - 24px)" [(ngModel)]="selectedLoanID">
					<mat-option *ngFor="let l of buyerLiens" [value]="l.id">{{l.name}}</mat-option>
				</mat-select>
				<a *ngIf="selectedLoanID > 0" (click)="addLoanToEscrow()" class="float-right" title="Add this Loan to Escrow"><i class="material-icons green-text">playlist_add</i></a>
			</mat-form-field>
		</mat-card>
		<mat-card class="section">
			<h2 class="pageHeader">Purchase Details</h2>
			<div *ngIf="showHeader" class="app-flex-container app-2col">
				<div><label class="caption large">Purchase Price</label>{{FormatNumberToCurrency(currentEscrow.purchasePrice)}}</div>
				<div *ngIf="currentEscrow.creditsFromSeller > 0"><label class="caption large">Credits from Seller</label>{{FormatNumberToCurrency(currentEscrow.creditsFromSeller)}}</div>
			</div>
			<div *ngIf="showHeader" class="app-flex-container app-2col">
				<div><label class="caption large">Deposit</label>{{FormatNumberToCurrency(currentEscrow.deposit)}}</div>
				<div><label class="caption large">Balance Due</label>{{FormatNumberToCurrency(currentEscrow.balanceDue)}}</div>
			</div>
			<mat-card *ngIf="role == Role.Buyer && currentEscrow.financedAmount > 0">
				<mat-card-title>Buyer Financing</mat-card-title>
				<div><label class="caption large">Lender</label><label class="data-value auto">{{currentEscrow.lender}}</label></div>
				<div><label class="caption large">Loan Terms</label><label class="data-value auto">{{currentEscrow.loanTerms}}</label></div>
				<div><label class="caption large">Down Payment</label><label class="data-value auto">{{FormatNumberToCurrency(currentEscrow.downPayment)}}</label></div>
				<div><label class="caption large">Finance Amount</label><label class="data-value auto">{{FormatNumberToCurrency(currentEscrow.financedAmount)}}</label></div>
			</mat-card>
		</mat-card>
	</div>
	<mat-tab-group [ngClass]="{'hidden' : escrowID == 0}">
		<mat-tab label="Statement">
			<app-escrow-statement #escrowStatement [showHeader]="false"></app-escrow-statement>
		</mat-tab>
		<mat-tab label="Documents">
			<app-documents #escrowDocuments [entityType]="DocumentEntityType.Escrow" [showTitle]="false" [entityTypeKeyID]="escrowID"></app-documents>
		</mat-tab>
		<mat-tab label="Demands">
			<app-escrow-demand #demands [noParent]="false" [showHeader]="false"></app-escrow-demand>
		</mat-tab>
	</mat-tab-group>
		
	<mat-card *ngIf="(currentEscrow == null || currentEscrow.id === 0) && listingID > 0 && componentBusy === false" class="section">
		<label class="bold auto">
			Currently Escrow details are not available&nbsp;{{this.forRole === roleType.Buyer ? 'for any offers you are party to' : 'for Selected Property' }}. <span *ngIf="this.forRole === roleType.Buyer">Let's <app-find-home-lookup [label]="'find a home'" [class]="'btn'"></app-find-home-lookup> and make an offer.</span>
		</label>
		<br /><br />
		<label class="bold auto">There may not be any Escrows (Open or Closed) for this property.</label>
	</mat-card>
</div>