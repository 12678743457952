<div mat-dialog-title>
    <a class="close"title="Close"  (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
       <div hrefToRouterLink [innerHTML]="agentTermsHTML | safeHtml"></div>
        <mat-card-footer *ngIf="showAcceptance">
            <input type="submit" class="btn" value="Accept" (click)="acceptTerms()">
        </mat-card-footer>
    </mat-card>
</div>
