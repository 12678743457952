import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from '../../util/APICaller.component';
import { BaseDialog } from '../../base.dialog';
import { Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ListingService } from '../../listing/listing.service';
import { OfferService } from '../../offer/offer.service';
import { Role } from 'src/app/models';
import { Utils } from '../utils';

export class Partner {
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    ownershipStake: number = 0;
    asAgent: boolean = false;
    enRole: Role = Role.NotSet;
    assignFromMyShare: boolean = false;
}

@Component({
    selector: 'app-add-partner-dialog',
    templateUrl: './addPartner.dialog.html'
})
export class AddPartnerDialog extends BaseDialog implements OnInit {
    entityID: number = 0;
    maxOwnership: number = 0;
    dialogHeader: string = '';
    assign: boolean = false;
    forRole: Role = Role.NotSet;
    forOffer: boolean = false;
    partner: Partner = new Partner();

    saving: boolean = false;
    constructor(dataservice: DataService, r: Router, public dialogRef: MatDialogRef<AddPartnerDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addPartnerDialog', dataservice, r, dialogRef, data);
    }

    ngOnInit() {
        if (this.data) {
            if (this.data.id) this.entityID = this.data.id;
            if (this.data.header) this.dialogHeader = this.data.header;
            if (this.data.maxOwnership) this.maxOwnership = this.data.maxOwnership;
            if (this.data.forOffer) this.forOffer = this.data.forOffer;
            if (this.data.assign) this.assign = this.data.assign;
        }

        if (this.entityID === 0 || this.maxOwnership === 0 || this.maxOwnership > 100) {
            setTimeout(() => {
                this.close(null);
            }, 1000);
        }
    }

    saveReady() {
        return !Utils.isNullOrEmpty(this.partner.email) && (this.partner.ownershipStake > 0 && this.partner.ownershipStake <= this.maxOwnership);
    }
    saveAgentReady() {
        return !Utils.isNullOrEmpty(this.partner.email) && !Utils.isNullOrEmpty(this.partner.firstName) && !Utils.isNullOrEmpty(this.partner.lastName);
    }
    addPartner() {
        this.partner.asAgent = false;
        this.addPerson();
    }
    addAgent() {
        this.partner.asAgent = true;
        this.addPerson();
    }

    private addPerson() {

        this.partner.enRole = this.forRole;
        this.partner.assignFromMyShare = this.assign == null ? false : this.assign;

        this.saving = this.componentBusy = true;
        this.error = '';

        let _response;
        if (this.forOffer) {
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).addPartner(this.entityID, this.partner).subscribe(
                (data) => _response = data
                , (error) => { this.saving = this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.saving = this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.error = _response.error;
                        else {
                            this.close({ role: _response.data, change: this.partner.ownershipStake});
                        }
                }
            );
        } else {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).addPartner(this.entityID, this.partner).subscribe(
                (data) => { _response = data; }
                , (error) => { this.saving = this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.saving = this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.error = _response.error;
                        else {
                            this.close({ role: _response.data, change: this.partner.ownershipStake });
                        }
                }
            );
        }
    }
}
