import { Component, Inject } from '@angular/core';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, NavigationEnd } from '@angular/router';
import { ChangeDetectionStrategy } from '@angular/core';

import { SignInComponent } from './security/signin.component';
import { returnRoute, cookiePolicy, divCookiePolicy } from './models';
import { MatDialog } from '@angular/material/dialog';
import * as version from '../version.json';
import { Subscription } from 'rxjs';

import { AppURLs, handover } from './models/constants';
import { Utils } from './common/utils';

@Component({
  selector: 'app-footer',
    templateUrl: './footer.component.html'
    , changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent extends BaseComponent {

    ip: any = {};
    buildInfo: string = '';

    appURLs = AppURLs;
    handover = handover;

    private _es: Subscription;

    constructor(private r: Router, @Inject(GlobalComponent) g: GlobalComponent
        , private dialog: MatDialog) {
        super('', r, g);
        this.ip = this.getIPAddress();
        this.setVersion();

        if (this.ip == null || this.ip.toString().trim() === '') {
            this._es = this.r.events.subscribe((e) => {
                if (e instanceof NavigationEnd) {
                    this.ip = this.getIPAddress();
                    this._es.unsubscribe();
                }
            });
        }
    }

    start(sURL: string) {
        if (this.gc.loggedIn) {
            this.gotoURL(sURL);
        } else {
            const dialogRef = this.dialog.open(SignInComponent, {
                data: { returnRoute: sURL, css: '', inPopup: true }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((e) => {
                if (e != null) {
                    const _retRoute: returnRoute = e as returnRoute;
                    if (_retRoute != null && !Utils.isNullOrEmpty(_retRoute.routePath))
                        this.gotoRoute(_retRoute.routePath, _retRoute.queryParams);
                }
            });
        }
    }

    agreedCookiePolicy() {
        localStorage.setItem(cookiePolicy, '1');
        const _cookiePolicy = document.getElementById(divCookiePolicy);
        if (_cookiePolicy != null)
            _cookiePolicy.style.display = 'none';
    }

    private getIPAddress(): string {
        const _i = sessionStorage.getItem('myip');
        if (_i != null && _i.trim() !== '')
            return _i;
        else
            return '';
    }

    private setVersion() {
        const _sv = sessionStorage.getItem('lastServerBuild');
        const _versionInfo = (version as any).default;
        const _myVersion = _versionInfo.buildMajor + '.' + _versionInfo.buildMinor + '.' + _versionInfo.buildRevision + '.' + _versionInfo.buildTag;

        this.buildInfo = 'Version - ' + _myVersion + ((_sv != null && _sv.toString().trim() !== '') ? ('/' + _sv) : '');
    }
}
