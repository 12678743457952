import { Component, Inject } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { WorkOrderService } from '../workorder.service';
import { BaseDialog } from '../../base.dialog';
import { appEnvironment } from '../../../environments/environment';
import { SPBid } from '../../models';
import * as cloneDeep from 'lodash/cloneDeep';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-bids-dialog',
    templateUrl: './bids.dialog.html'
})
export class BidsDialog extends BaseDialog {

    dialogHeader: string = 'Bids';
    workOrderID: number = 0;
    forRFQs: boolean = false;
    bids: SPBid[] = [];
    propertyAddress: any;
    serviceType: any;

    constructor(dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent
        , public dialogRef: MatDialogRef<BidsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addDependencyDialog', dataservice, r, dialogRef, data);

        if (data) {
            if (data.id) this.workOrderID = data.id;
            if (data.header) this.dialogHeader = data.header;
            if (data.forRFQs) this.forRFQs = data.forRFQs;
        }

        if (this.workOrderID === 0) {
            setTimeout(() => { this.close(null) }, appEnvironment.delayedNGInit);
        } else
            this.getBids();
    }

    changeStatus(action$) {
        this.componentBusy = true;
        let _actionResponse;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).changeBidStatus(parseInt(action$.argument), action$.name).subscribe((r) => _actionResponse = r
            , (error) => { this.componentBusy = false; this.onApiError(error); }
            , () => {
                this.componentBusy = true;
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.error = _actionResponse.error;
                    else {
                        if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                        this.showMessage(_actionResponse.message);

                        if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                            this.gotoURL(_actionResponse.redirectURL);
                        else {
                            this.bids = cloneDeep(this.bids.filter((wo) => (wo.id !== parseInt(action$.argument)))); // Update Work Order on Client
                            if (_actionResponse.data)
                                this.bids.splice(0, 0, Utils.castTo(_actionResponse.data, new SPBid()));
                        }
                    }
                }
            }
        );
    }

    private getBids() {
        this.componentBusy = true;
        let _response;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).getBids(this.workOrderID).subscribe((r) => _response = r
            , (error) => { this.componentBusy = false; this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.error = _response.error;
                    else if (_response.data && _response.data.length > 0) {
                        this.propertyAddress = _response.data[0].address;
                        this.serviceType = _response.data[0].serviceType;
                        this.bids = _response.data.map((b) => Utils.castTo(b, new SPBid()));
                    }
                }
            });
    }
}
