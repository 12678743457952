import * as moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import { appEnvironment } from '../../environments/environment';
import { Utils } from '../common/utils';
export class AppBase {
    private get user(): any {
        const _s = sessionStorage.getItem('sessionUser');
        if (Utils.isNullOrEmpty(_s))
            return null;
        else
            return JSON.parse(sessionStorage.getItem('sessionUser'));
    }
    private get OffsetMinutesFromServer(): number {
        const _u = this.user;
        if (_u != null && _u.OffsetMinutesFromServer != null)
            return _u.OffsetMinutesFromServer;
        else
            return 0;
    }

    public GetCurrencyPipe() {
        // let _currencyCode = 'USD'; //this.SessionISOCurrencyCode();
        // if (_currencyCode != '')
        //    return new CurrencyPipe(_currencyCode);
        // else
        return new CurrencyPipe(appEnvironment.defaultISOLanguageCode); // default it to $
    }

    public FormatNumberToCurrency(amount: number, currency?: string): string {
        if (amount == null) amount = 0;
        const _currencyPipe = this.GetCurrencyPipe();
        let _currencyCode = currency;
        if (Utils.isNullOrEmpty(_currencyCode)) _currencyCode = appEnvironment.defaultISOCurrencyCode;
        return _currencyPipe.transform(amount, _currencyCode, 'symbol', null, (this.user && this.user.isoLanguageCode) ? this.user.isoLanguageCode : appEnvironment.defaultISOLanguageCode);
    }

    public castToMe(oObject: any) {
        return Utils.castTo(oObject, this);
    }
}

export const cookiePolicy: string = 'cookiePolicy';
export const divCookiePolicy: string = 'divCookiePolicy';

export class RecordTimeStamp extends AppBase {

    public id: number = 0;
    active: boolean = true;
    createDate: Date = new Date();
    lastUpdateDate: Date = new Date();
    createdBy: number = 0; lastUpdatedBy: number = 0;
    createdByName: string = ''; lastUpdatedByName: string = '';
    createdByPersonName: string = ''; lastUpdatedByPersonName: string = '';
    isReadOnly: boolean = false;
    isDirty: boolean = false;

    editing: boolean = false;

    protected _disposeDB: boolean;

    constructor() { super(); }

    public get CreateDate(): Date {
        return this.createDate;
    }
    public set CreateDate(value: Date) {
        if (this.IsReadOnly)
            return;
        this.createDate = value;
    }
    public get LastUpdateDate(): Date {
        return this.lastUpdateDate;
    }
    public set LastUpdateDate(value: Date) {
        if (this.IsReadOnly)
            return;
        this.lastUpdateDate = value;
    }
    public get CreatedBy(): number {
        return this.createdBy;
    }
    public set CreatedBy(value: number) {
        if (this.IsReadOnly)
            return;
        this.createdBy = value;
    }
    public get CreatedByName(): string {
        return this.createdByName;
    }
    public set CreatedByName(value: string) {
        if (this.IsReadOnly)
            return;
        this.createdByName = value;
    }
    public get CreatedByPersonName(): string {
        return this.createdByPersonName;
    }
    public set CreatedByPersonName(value: string) {
        if (this.IsReadOnly)
            return;
        this.createdByPersonName = value;
    }

    public get LastUpdatedBy(): number {
        return this.lastUpdatedBy;
    }
    public set LastUpdatedBy(value: number) {
        if (this.IsReadOnly)
            return;
        this.lastUpdatedBy = value;
    }
    public get LastUpdatedByName(): string {
        return this.lastUpdatedByName;
    }
    public set LastUpdatedByName(value: string) {
        if (this.IsReadOnly)
            return;
        this.lastUpdatedByName = value;
    }
    public get LastUpdatedByPersonName(): string {
        return this.lastUpdatedByPersonName;
    }
    public set LastUpdatedByPersonName(value: string) {
        if (this.IsReadOnly)
            return;
        this.lastUpdatedByPersonName = value;
    }
    public get IsReadOnly(): boolean {
        return this.isReadOnly;
    }
    public set IsReadOnly(value: boolean) {
        this.isReadOnly = value;
    }

    public get ToolTipHTML(): string {
        let _userInfo: string = '';
        if (this.CreatedBy > 0)
            _userInfo = 'Created by ' + (Utils.isNullOrEmpty(this.CreatedByName) ? 'Unknown' : this.CreatedByName) + (!moment(this.CreateDate).isSame(Utils._minDate) ? (' on ' + Utils.DisplayDateTime(this.CreateDate)) : '');
        else if (!moment(this.CreateDate).isSame(Utils._minDate))
            _userInfo = 'Created  on ' + Utils.DisplayDateTime(this.CreateDate);
        if (this.LastUpdatedBy > 0)
            _userInfo += (Utils.isNullOrEmpty(_userInfo) ? '' : '\r\n') + 'Last Updated by ' + (Utils.isNullOrEmpty(this.LastUpdatedByName) ? 'Unknown' : this.LastUpdatedByName)
                + (!moment(this.LastUpdateDate).isSame(Utils._minDate) ? (' on ' + Utils.DisplayDateTime(this.LastUpdateDate)) : '');
        else if (!moment(this.LastUpdateDate).isSame(Utils._minDate))
            _userInfo += (Utils.isNullOrEmpty(_userInfo) ? '' : '\r\n') + 'Last Updated on ' + Utils.DisplayDateTime(this.LastUpdateDate);
        return _userInfo;
    }

    public get CRUDHTML(): string {
        return '<i class="float-right fa fa-user-circle-o" title=""' + this.ToolTipHTML + ' style="color:lightgrey"></i>';
    }

    public Activate(): void {
        if (this.active === false)
            this.isDirty = true;
        this.active = true;
    }
    public DeActivate(): void {
        if (this.active === true)
            this.isDirty = true;
        this.active = false;
    }

    public clearDirty() {
        try {
            this.isDirty = false;
        } catch (e) { }
    }
    public setDirty() {
        try {
            this.isDirty = true;
        } catch (e) { }
    }

    public UpdateAfterChangeStatus(oResponse) {
        if (oResponse.currentStatus)
            this['currentStatus'] = oResponse.currentStatus;
        if (oResponse.nextSteps)
            this['nextSteps'] = oResponse.nextSteps;
        if (oResponse.lastUpdateDate)
            this['currentStatusDate'] = oResponse.lastUpdateDate;
        else if (oResponse.currentStatusDate)
            this['currentStatusDate'] = oResponse.currentStatusDate;
    }

    public castToMe(oObject: any) {
        return Utils.castTo(oObject, this);
    }
}

export class BaseResponse {
    public data: any = {};
    public error: string = '';
    public message: string = '';
    public status: number = 0;
    public redirectURL: string = '';
}

export class DialogData {
    isConfirmation: boolean;
    message: string;
    title: string;
    isMessageAsHTML: boolean;
}

export class BrainTree {
    account: string;
    threeDSEnabled: boolean;
}

export class TagModel extends RecordTimeStamp {
    id: number = 0;
    tagCode: string = '';
    tagValue: string = '';
    constructor() {
        super();
    }
}
