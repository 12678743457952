import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';
import { GlobalComponent } from '../global.component';
import { navIA } from '../models/constants';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, @Inject(GlobalComponent) private gc) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (state.url.indexOf('login') >= 0 || state.url.indexOf('default') >= 0) return true;

        if (this.gc.loggedIn)
            return true;
        else {
            if (state.url == null || state.url.trim() === '' || state.url.trim() === '/')
                this.router.navigate(['login']);
            else
                this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return true;
        }
        return false;
    }
}

@Injectable()
export class CheckDirtyGuard implements CanDeactivate<any> {
    canDeactivate(target: any) {
        const _c = document.getElementById('pageContextSave');
        if (_c != null) {
            const _a = _c.getAttribute('class');
            if (_a != null && _a.indexOf('hide') < 0)
                return confirm('Are you sure you want to discard your changes?');
        }
        return true;
    }
}

@Injectable()
export class SystemGuard implements CanActivate {

    constructor(private router: Router, @Inject(GlobalComponent) private gc) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (state.url.indexOf('login') >= 0 || state.url.indexOf('default') >= 0) return true;

        const _user = this.gc.user;
        if (this.gc.loggedIn && document.getElementById(navIA) != null)
            return true;
        else {
            if (state.url == null || state.url.trim() === '' || state.url.trim() === '/')
                this.router.navigate(['login']);
            else
                this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return true;
        }
        return false;
    }
}
