import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TextMaskModule } from 'ngx-textmask';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgxBraintreeModule } from 'ngx-braintree';

import { MaterialModule } from '../common/shared/material.module';
import { ResizeService } from './service/resize.service';

import {
    NewLoanComponent, AddressComponent, AutoCompleteComponent, DatePickerComponent, NextActionComponent
    , ProgressBarComponent, SVGGuageComponent
    , DocumentActionComponent, ViewDocumentLink, LegalTermComponent, EntityRoleComponent, MessageBoxComponent, GeographicSelectionComponent
    , AssetContentComponent
    , ListingLookupComponent, ListingDisclosureComponent, NewDisclosureDialog
} from './';

import {
    AddPartnerDialog, AgentDialog, ConfirmationDialogComponent, HTMLDialog, MessageDialog, EditHTMLDialog, AgentTermsDialog
    , PowerOfAttorneyDialog, UploadFileDialog
} from './dialogs';

import { BrainTreeDialog } from './shared/brainTreeDropIn.dialog';
import { MergeComponent } from './shared/merge.component';

import { DocumentsComponent } from './documents';
import { DocumentPermissionsDialog } from './documents/dialog';

import {
    CustomMinDirective, CustomMaxDirective, DirtyWatchDirective, DirtyWatchSelectDirective, EmailValidator, PasswordValidator, RequiredValidator, AutofocusDirective, SelectOnFocusDirective
    , DisableAutofillDirective, EqualValidator, HrefToRouterLinkDirective, HideMeOnMyPageDirective, SetHeightDirective, NumberDirective
    , ClickStopPropagationDirective, InputMaxLengthDirective
} from './directives';

import { AngularEditorModule } from '../htmlEditor/angular-editor.module';

import { AssetLinkComponent } from './asset.link.component';
import { AssetPageComponent } from './asset.pagedirect.component';

import { EllipsisPipe, TagCodePipe, TranslateTagPipe } from '../util/_pipes';
import { KeysPipe, SafeHtmlPipe, SafeStylePipe, BlankOnNullOrEmptyPipe, IsNullOrEmptyPipe, IsNotNullOrEmptyPipe, DataTimeStampPipe } from './pipes';

import { AddDemandDialog, NewDisbursementDialog, NewEscrowDepositDialog, NewEscrowTaxDialog, UploadContingencyDocumentDialog } from '../escrow/dialog';
import {
    EscrowContingencyComponent, EscrowDemandComponent, EscrowDepositComponent, EscrowDepositInstructionsComponent
    , EscrowDisrbursementComponent, EscrowStatementComponent, EscrowTaxesComponent, EscrowDetailComponent
} from '../escrow';

import { ServiceProviderWorkOrdersComponent } from '../workorders/workorders.component';

import { AddDependencyDialog, AddContingencyDialog, BidsDialog, CreateRepairWorkOrderDialog, AddAdditionalServiceDialog } from '../workorders/dialogs';
import { ListingWorkOrdersComponent, BuyerNewListingComponent, SellerNewListingComponent } from '../listing';
import { ListingGeneralComponent } from '../listing/edit';
import { AddAssessmentDialog, AddSellerLienDialog, AddVisitDialog, ListingMediaDialog } from '../listing/dialogs';
import { AddMediaComponent, PropertyDataValueComponent, ListingServicesComponent } from '../listing/controls';

import { DndModule } from 'ngx-drag-drop';
import { SearchSPDialog } from '../sp/dialogs';
import { ServiceProviderSearchComponent, ServiceProviderProfileComponent } from '../sp';
import { ListingsComponent } from '../listing/listings.component';
import { NgxGalleryModule } from '@murbanczyk-fp/ngx-gallery';

@NgModule({
    imports: [
        RouterModule, CommonModule, FormsModule, ReactiveFormsModule, MaterialModule
        , NgxBraintreeModule
        , AngularEditorModule, FileUploadModule, TextMaskModule
        , NgxPaginationModule, DndModule
    ],
    declarations: [
        NewLoanComponent, AddressComponent, AutoCompleteComponent, DatePickerComponent, NextActionComponent
        , ProgressBarComponent, SVGGuageComponent
        , DocumentActionComponent, ViewDocumentLink
        , AddPartnerDialog, AgentDialog, BrainTreeDialog, ConfirmationDialogComponent, HTMLDialog, MessageDialog, EditHTMLDialog, AgentTermsDialog, PowerOfAttorneyDialog
        , UploadFileDialog
        , LegalTermComponent, GeographicSelectionComponent
        , EntityRoleComponent, MessageBoxComponent, AssetContentComponent, MergeComponent
        , KeysPipe, SafeHtmlPipe, SafeStylePipe, EllipsisPipe, TagCodePipe, TranslateTagPipe, BlankOnNullOrEmptyPipe, IsNullOrEmptyPipe, IsNotNullOrEmptyPipe, DataTimeStampPipe
        , AssetPageComponent, AssetLinkComponent
        , CustomMinDirective, CustomMaxDirective, PasswordValidator, RequiredValidator, EmailValidator, AutofocusDirective, SelectOnFocusDirective
        , DisableAutofillDirective, EqualValidator
        , HrefToRouterLinkDirective, HideMeOnMyPageDirective, DirtyWatchDirective, DirtyWatchSelectDirective, SetHeightDirective, NumberDirective
        , ClickStopPropagationDirective, InputMaxLengthDirective
        , ListingLookupComponent, ListingDisclosureComponent, NewDisclosureDialog
        , DocumentsComponent, DocumentPermissionsDialog
        , AddDemandDialog, NewDisbursementDialog, NewEscrowDepositDialog, NewEscrowTaxDialog, UploadContingencyDocumentDialog
        , EscrowDetailComponent, EscrowContingencyComponent, EscrowDemandComponent, EscrowDepositComponent, EscrowDepositInstructionsComponent
        , EscrowDisrbursementComponent, EscrowStatementComponent, EscrowTaxesComponent
        , ServiceProviderWorkOrdersComponent, SearchSPDialog, ServiceProviderSearchComponent, ServiceProviderProfileComponent
        , AddDependencyDialog, AddContingencyDialog, BidsDialog, CreateRepairWorkOrderDialog, AddAdditionalServiceDialog
        , ListingWorkOrdersComponent, BuyerNewListingComponent, ListingGeneralComponent, SellerNewListingComponent
        , ListingsComponent
        , AddAssessmentDialog, AddSellerLienDialog, AddVisitDialog, ListingMediaDialog
        , AddMediaComponent, PropertyDataValueComponent, ListingServicesComponent
    ],
    exports: [NewLoanComponent, AddressComponent, AutoCompleteComponent, DatePickerComponent, NextActionComponent
        , ProgressBarComponent, SVGGuageComponent
        , AddPartnerDialog, AgentDialog, BrainTreeDialog, ConfirmationDialogComponent, HTMLDialog, MessageDialog, EditHTMLDialog, LegalTermComponent, AgentTermsDialog, PowerOfAttorneyDialog
        , UploadFileDialog
        , DocumentActionComponent, ViewDocumentLink, MessageBoxComponent, GeographicSelectionComponent, EntityRoleComponent, AssetContentComponent, MergeComponent
        , KeysPipe, SafeHtmlPipe, SafeStylePipe, EllipsisPipe, TagCodePipe, TranslateTagPipe, BlankOnNullOrEmptyPipe, IsNullOrEmptyPipe, IsNotNullOrEmptyPipe, DataTimeStampPipe
        , AssetPageComponent, AssetLinkComponent
        , CustomMinDirective, CustomMaxDirective, PasswordValidator, RequiredValidator, EmailValidator, AutofocusDirective, SelectOnFocusDirective
        , DisableAutofillDirective, EqualValidator
        , HrefToRouterLinkDirective, HideMeOnMyPageDirective, DirtyWatchDirective, DirtyWatchSelectDirective, SetHeightDirective, NumberDirective
        , ClickStopPropagationDirective, InputMaxLengthDirective
        , ListingLookupComponent, ListingDisclosureComponent, NewDisclosureDialog
        , DocumentsComponent, DocumentPermissionsDialog
        , AddDemandDialog, NewDisbursementDialog, NewEscrowDepositDialog, NewEscrowTaxDialog, UploadContingencyDocumentDialog
        , EscrowDetailComponent, EscrowContingencyComponent, EscrowDemandComponent, EscrowDepositComponent, EscrowDepositInstructionsComponent
        , EscrowDisrbursementComponent, EscrowStatementComponent, EscrowTaxesComponent
        , ServiceProviderWorkOrdersComponent, SearchSPDialog, ServiceProviderSearchComponent, ServiceProviderProfileComponent
        , AddDependencyDialog, AddContingencyDialog, BidsDialog, CreateRepairWorkOrderDialog, AddAdditionalServiceDialog
        , ListingWorkOrdersComponent, BuyerNewListingComponent, ListingGeneralComponent, SellerNewListingComponent
        , ListingsComponent
        , AddAssessmentDialog, AddSellerLienDialog, AddVisitDialog, ListingMediaDialog
        , AddMediaComponent, PropertyDataValueComponent, ListingServicesComponent
    ],
    providers: [DataTimeStampPipe]
})
export class AppCommonModule { }

export const selectTag: string = ' -- Select -- ' ;
export const selectChangeTag: string = '-- Select/Change --';
export const selectProperty = ' -- Select a Property --';
export const allItems = ' -- All --';
