import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from '../../../app/models';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmationDialog.component.html'
})

export class ConfirmationDialogComponent {

    message: string = '';
    title: string = '';
    isConfirmation: boolean = false;
    lblYes: string = 'lblYes'; lblNo: string = 'lblNo'; lblOk: string = 'lblOk';
    isMessageAsHTML: boolean = false;
    baseComponent: any;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.lblOk = 'OK';
        this.lblNo = 'No';
        this.lblYes = 'Yes';

        if (this.data != null) {
            this.isConfirmation = this.data.isConfirmation;
            this.message = this.data.message;
            this.title = this.data.title;
            this.isMessageAsHTML = this.data.isMessageAsHTML;
        }
    }

    onYes() { this.dialogRef.close(true); }
    onNo() { this.dialogRef.close(false); }
    cancel(event: any) { this.dialogRef.close(false); }

}
