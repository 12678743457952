import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, ViewChild, Input, Inject, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { GlobalComponent } from '../../global.component';


import { appEnvironment } from '../../../environments/environment';

import { Merge, ParameterDataType, Parameter, SubSQL} from '../../models/merge.model';
import { MergeService } from '../service/merge.service';
import { AngularEditorComponent } from '../../htmlEditor/angular-editor.component';
import { AngularEditorConfig } from '../../htmlEditor/config';
import { BaseResponse } from '../../models';
import { Utils } from '../utils';


@Component({
    selector: 'app-merge'
    , templateUrl: './merge.component.html'
    , styleUrls: ['./merge.component.css']
})
export class MergeComponent extends BaseComponent {

    dataTypes = ParameterDataType;
    paramDisplayedColumns = ['id', 'name', 'type', 'defaultValue'];
    loggingDisplayedColumns = ['name', 'loggingValue'];
    readonly helpNote: string = '* when embeding, Embeded Data should have JSON String  { emailtype :  x, mergeid :  x, parameters : { } } , For e.g. - <columnname embedjob>Embeded Data</columnname>';
    @ViewChild('subQueryContentEditor') subQueryContentEditor: AngularEditorComponent;
    @ViewChild('messageContentEditor') messageContentEditor: AngularEditorComponent;
    @ViewChild('emailContentEditor') emailContentEditor: AngularEditorComponent;

    @Input() mergeType: number = 1;
    @Input() merge: Merge = new Merge();

    emailTemplate: string = '';
    messageTemplate: string = '';
    subQuery: SubSQL = new SubSQL();
    subQueryTemplate: string;
    _pto: any;
    _lto: any;
    canExecute: boolean = false;

    currentParameter: Parameter = new Parameter();
    columns: any[];

    editConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '25rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        sanitize: false,
        uploadUrl: 'v1/images',
        fonts: [
            { name: 'Arial', class: 'arial' }
            , { name: 'Calibri', class: 'calibri' }
            , { name: 'Comic Sans MS', class: 'comic-sans-ms' }
            , { name: 'Times New Roman', class: 'times-new-roman' }
        ]
    };

    saving: boolean = false;

    //#region Framework Event Handlers
    constructor(private ds: DataService, private rs: Router, private changeDetectorRef: ChangeDetectorRef, media: MediaMatcher
        , @Inject(GlobalComponent) g: GlobalComponent) {
        super('merge', rs, g);
    }
    //#endregion

    public setMergeForID(id: number) {
        this.componentBusy = true;
        this.columns = [];
        if (id != null && id > 0) {
            let _response: BaseResponse;
            (new MergeService(this.ds, this.rs, this.myResourceCategory)).getMerge(id, this.mergeType).subscribe(
                (r) => {
                    _response = r;
                }
                , (error) => { this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.componentBusy = false;
                    if (_response.data && _response.data.id > 0)
                        this.merge = _response.data;
                    else {
                        this.merge = new Merge();
                        this.merge.campaignReferenceID = id;
                        this.merge.type = this.mergeType;
                    }

                    this.setExecuteEnabled();

                    if (this.merge.id > 0 && !Utils.isNullOrEmpty(this.merge.query)) {
                        (new MergeService(this.ds, this.rs, this.myResourceCategory)).getColumns(this.merge.id).subscribe((r) => _response = r
                            , (error) => { this.onApiError(error); }
                            , () => {
                                if (_response.data) {
                                    this.columns = _response.data;
                                    if (this.columns.length > 0)
                                        this.columns.splice(0, 0, '');
                                }
                            });
                    } else
                        this.columns = [];
                });
        } else
            this.merge = new Merge();
    }

    save() {
        if (this.merge.queryParsed == null || this.merge.queryParsed === true) {

            if (this.messageContentEditor != null)
                this.merge.messageTemplate = this.messageContentEditor.html;

            if (this.emailContentEditor != null)
                this.merge.emailTemplate = this.emailContentEditor.html;

            let saveResponseData;
            (new MergeService(this.ds, this.rs, this.myResourceCategory)).save(this.merge).subscribe((r) => saveResponseData = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (!Utils.isNullOrEmpty(saveResponseData.error)) {
                        this.showError(saveResponseData.error);
                    }
                    if (saveResponseData.data) {
                        this.columns = saveResponseData.data;
                        if (this.columns.length > 0)
                            this.columns.splice(0, 0, '');
                    }
                    this.merge.isDirty = false;
                    this.merge.queryParsed = true;
                });
        } else if (Utils.isNullOrEmpty(this.merge.query))
            this.parseAndVerifyMain();
    }

    parseAndVerifyMain() {
        let saveResponseData;
        (new MergeService(this.ds, this.rs, this.myResourceCategory)).parseAndVerifyMain(this.merge).subscribe((r) => saveResponseData = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (!Utils.isNullOrEmpty(saveResponseData.error)) {
                    this.showError(saveResponseData.error);
                } else {
                    if (saveResponseData.data) {
                        this.columns = saveResponseData.data;
                        if (this.columns.length > 0)
                            this.columns.splice(0, 0, '');
                    }
                    this.merge.queryParsed = true;
                }
            });
    }

    onParameterSelect($event) {
        if ($event)
            this.currentParameter = $event;
        else
            this.currentParameter = new Parameter();

    }
    addParameter() {
        const _p = new Parameter();

        if (this.merge.parameters == null) this.merge.parameters = [];
        if (this.merge.parameters && this.merge.parameters.length > 1)
            this.merge.parameters.splice(0, 0, _p);
        else
            this.merge.parameters.push(_p);

        this.merge.parameters = [...this.merge.parameters];
    }

    activateParameter(oParam: Parameter) {
        this.merge.parameters.forEach((p) => { if (p.id === oParam.id) { p.active = true; p.isDirty = true; } });
        this.merge.parameters = [...this.merge.parameters];
        this.triggerParameterSave(oParam);
    }
    removeParameter(oParam: Parameter) {
        this.merge.parameters.forEach((p) => { if (p.id === oParam.id) { p.active = false; p.isDirty = true; }});
        this.merge.parameters = [...this.merge.parameters];
        this.triggerParameterSave(oParam);
    }

    checkDuplicateParameter(oParam: Parameter) {
        if (this.merge.parameters.some((p) => p.id !== oParam.id && p.name.toLowerCase() === oParam.name.toLowerCase())) {
            this.showError('Duplicate name !!!');
            oParam.name = '';
        } else
            this.triggerParameterSave(oParam);
    }

    triggerParameterSave(oParameter: Parameter, forceQueryStatus: boolean = true) {
        this.setExecuteEnabled();
        if (forceQueryStatus) this.merge.queryParsed = false;

        if (oParameter) this.merge.parameters.forEach((p) => {
            if (p.id === oParameter.id) {
                if (p.isDirty)
                    p.isDirty = true;
                else
                    p['isDirty'] = true;
            }
        });

        if (this._pto && this.saving) clearTimeout(this._pto);
        if (!this.saving) this.saving = true;

        this._pto = setTimeout(() => { this.saveParameters(this.merge.parameters); }, appEnvironment.autoSaveTime);
    }

    triggerLoggingSave() {
        if (this._lto && this.saving) clearTimeout(this._lto);
        if (!this.saving) this.saving = true;

        this._lto = setTimeout(() => { this.saveLogging(); }, appEnvironment.autoSaveTime);
    }

    addNewSubSQL() {
        if (this.merge.subSQLs && this.merge.subSQLs.length > 0)
            this.merge.subSQLs.splice(0, 0, new SubSQL());
        else
            this.merge.subSQLs.push(new SubSQL());
        this.subQuery = this.merge.subSQLs[0];
    }
    selectSubSQL(q: SubSQL) {
        this.subQuery = q;
        this.subQueryTemplate = this.subQuery.template;
    }

    checkDuplicateQueryName() {
        const _q = this.merge.subSQLs.some((q) => q.name === this.subQuery.name && q.id !== this.subQuery.id);
        if (_q === true) {
            this.showError('Duplicate Query Name');
            this.subQuery.name = '';
        }
    }

    saveSubQuery() {

        if (this.subQueryContentEditor) this.subQuery.template = this.subQueryContentEditor.html;

        let saveResponseData;
        (new MergeService(this.ds, this.rs, this.myResourceCategory)).saveSubQuery(this.merge.id, this.subQuery).subscribe((r) => saveResponseData = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (!Utils.isNullOrEmpty(saveResponseData.error)) {
                    this.showError(saveResponseData.error);
                } else {
                    this.subQuery.isDirty = false;
                    this.subQuery.queryParsed = true;
                    this.cancelSubQuery();
                }
            });
    }

    cancelSubQuery() {
        this.subQuery = new SubSQL();
    }

    setMergeDirty() {
        if (this.merge) this.merge.isDirty = true;
    }
    setSubQueryDirty() {
        if (this.subQuery) this.subQuery.isDirty = true;
    }

    execute() {
        clearTimeout(this._pto);
        this.saveParameters(this.merge.parameters, () => {
            // Call Save API
            let saveResponseData;
            (new MergeService(this.ds, this.rs, this.myResourceCategory)).execute(this.merge).subscribe((r) => saveResponseData = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (!Utils.isNullOrEmpty(saveResponseData.message))
                        this.showMessage(saveResponseData.message);
                });
        });
    }

    clear() {
        this.merge = new Merge();
    }

    private setExecuteEnabled() {
        this.canExecute = this.merge.isDirty !== true;
        const breakException = {};
        try {
            this.merge.parameters.forEach((p) => {
                if (p.required === true && (p.designValue == null || p.designValue.trim() === '')) {
                    throw breakException;
                }
            });
        } catch (e) {
            this.canExecute = false;
        }
    }

    private saveParameters(oParameters, callback: any = null) {
        let _itemsToUpdate: number = 0;

        if (oParameters != null)
            _itemsToUpdate = oParameters.filter((c) => c.id === 0 || c.isDirty).length;

        if (_itemsToUpdate > 0) {
            const _readyToSave = oParameters.filter((c) => (c.id === 0 || c.isDirty));
            if (_readyToSave == null || _readyToSave.length === 0) return;

            // To Save Data for Objects with Properies having GET/SET, have to use toJSON() and JSON.parse
            const _saveData: object[] = _readyToSave.map((x) => x); // JSON.parse(x.toJSON());

            // Call Save API
            let saveResponseData;
            (new MergeService(this.ds, this.rs, this.myResourceCategory)).saveParameters(this.merge.id, _saveData).subscribe((r) => saveResponseData = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (saveResponseData.data)
                        this.merge.parameters = [...saveResponseData.data];

                    if (callback) callback();
                });
        }
    }

    private saveLogging() {
            // Call Save API
        let saveResponseData;
        (new MergeService(this.ds, this.rs, this.myResourceCategory)).saveMapLog(this.merge.id, this.merge.logMapping).subscribe((r) => saveResponseData = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (saveResponseData.data)
                        this.merge.parameters = [...saveResponseData.data];
                });
    }

}
