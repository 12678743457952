<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <div class="app-flex-container app-2col" *ngIf="serviceTypes.length > 0">
        <mat-card>
            <mat-form-field>
                <input matInput appDisableAutofill  [(ngModel)]="repairDetails.requiredRepairSummary" placeholder="Required Repair(s)" [disabled]="true" />
            </mat-form-field>
            <mat-form-field>
                <input matInput appDisableAutofill  [(ngModel)]="repairDetails.requiredRepairCost" placeholder="Quoted Cost of Required Repair(s)" [disabled]="true" />
            </mat-form-field>
            <br />
            <mat-form-field>
                <input matInput appDisableAutofill  [(ngModel)]="repairDetails.optionalRepairSummary" placeholder="Optional Repair(s)" [disabled]="true" />
            </mat-form-field>
            <mat-form-field>
                <input matInput appDisableAutofill  [(ngModel)]="repairDetails.optionalRepairCost" placeholder="Quoted Cost of Optional Repair(s)" [disabled]="true" />
            </mat-form-field>
        </mat-card>
        <mat-card>
            <mat-form-field>
                <mat-label class="placeholder">Repair Services <i class="fa fa-question-circle-o warning-color" title="Select available service which you want to fix and submit new Work Order"></i></mat-label>
                <mat-select [(ngModel)]="selectedServiceTypeID">
                    <mat-option *ngFor="let p of repairDetails.repairServiceTypes" [value]="p.id">{{p.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <textarea matInput appDisableAutofill [(ngModel)]="repairInstructions" placeholder="Instruction(s)" maxlength="1000" ></textarea>
            </mat-form-field>
            <mat-button-toggle-group name="repairPaidBy" aria-label="Repair Paid By" [value]="paidBy" (change)="repairByChange(event$)" >
                <mat-button-toggle value="bold" [value]="Role.Buyer">Buyer</mat-button-toggle>
                <mat-button-toggle value="italic" [value]="Role.Seller">Seller</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-checkbox [(ngModel)]="requiredBeforeCOE">New Work Order to be completed before Close of Escrow</mat-checkbox>
            <p>
                Creation of new work order will result in a new Addendum, which has to be Accepted by {{paidBy == Role.Buyer ? 'BUYER(s)' : 'SELLER(s)' }}
            </p>
        </mat-card>
        <mat-card *ngIf="saveReady">
            <a class="button" (click)="createWO()">Create Repair Work Order</a>
        </mat-card>
    </div>
    <app-search-providers *ngIf="serviceTypes.length > 0 && allowToPickSP" (providerSelected)="onServiceProviderSelected($event)" [hideServiceType]="true" #searchSP [selectButtonText]="'Add This'" [collectPaymentIfRequired]="false" [inPopup]="true"></app-search-providers>
    <button mat-button *ngIf="!allowToPickSP && selectedServiceTypeID > 0" (click)="onServiceProviderSelected(null)">Add this Service</button>
    <span *ngIf="serviceTypes == null || serviceTypes.length == 0">No other Additional Service(s) available, all authorized services has already been added.</span>
</div>