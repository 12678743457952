import { Utils } from './utils';
declare var $: any;

export class ScriptService { 
 static removeExternalScript(fileName: string) {
    const len = $('script[src="' + fileName + '"]').length;
    if (len > 0) {
        const tags = document.getElementsByTagName('script');
        for (let i = tags.length; i >= 0; i--) { // search backwards within nodelist for matching elements to remove
            if (tags[i] && tags[i].attributes) { // getAttribute('src') != null && tags[i].getAttribute('src').indexOf(fileName) !== -1) {
                const _t = Array.from(tags[i].attributes);
                _t.forEach((t) => {
                    if (t && t.value && t.value === fileName) {
                        tags[i].parentNode.removeChild(tags[i]); // remove element by calling parentNode.removeChild()
                        return;
                    }
                });
            }
        }
    }
}

    static removeGoogleReCaptcha() {
    $('.grecaptcha-badge').each((g) => {
        if ($(g).parent().remove)
            $(g).parent().remove();
    });
}

    static loadExternalScript(scriptUrl: string, checkURLString: string = '') {

    let len: number = 0;
    if (Utils.isNullOrEmpty(checkURLString))
        len = $('script[src="' + scriptUrl + '"]').length;
    else {
        $('script').each(function () {
            if ($(this).attr('src').indexOf(checkURLString) >= 0) {
                len = 1;
            }
        });
    }

    if (len === 0) {
        return new Promise((resolve) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = scriptUrl;
            scriptElement.onload = resolve;
            document.body.appendChild(scriptElement);
        });
    } else {
        return new Promise((resolve) => { });
    }
}

    static loadExternalStyles(styleUrl: string) {
    return new Promise((resolve, reject) => {
        const styleElement = document.createElement('link');
        styleElement.href = styleUrl;
        styleElement.onload = resolve;
        document.head.appendChild(styleElement);
    });
}
}