<div class="section appContent">
    <h2 class="pageHeader">
        Bids/RFQs
    </h2>
    <mat-progress-bar *ngIf="componentBusy === true" mode="buffer"></mat-progress-bar>
	<mat-tab-group (selectedTabChange)="groupChanged($event)" #bidTabs [(selectedIndex)]="selectedIndex">
		<mat-tab label="Search For Possible Work">
			<ng-template [ngIf]="canSearch" [ngIfElse]="canNotSearch">
				Your search results are restricted to 
				<span *ngFor="let st of this.serviceTypes">
					<label class="caption">{{st.name}}</label> <span *ngIf="st.searchForBidInStates && st.searchForBidInStates.length > 0">in state of <span *ngFor="let s of st.searchForBidInStates;let i = index">{{s.name + (i+1 === st.searchForBidInStates ? '' : ', ')}}</span></span><br/>
				</span>
				<hr/>
			</ng-template>
			<ng-template #canNotSearch>
				You have NOT purchased option to Search for additional open work orders. OR You have not been provided privileges by your company administrator.
				<div class="section">
					NOTE : You are allowed to process Work Orders assigned to you or Request for Quotes sent to you.
				</div>
			</ng-template>
			<div *ngIf="canSearch">
				<div class="app-flex-container app-2col">
					<mat-form-field>
						<mat-select [(ngModel)]="selectedServiceTypes" placeholder="Service Types" multiple>
							<mat-option *ngFor="let st of serviceTypes" [value]="st.id" class="auto">{{st.name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<button matButton (click)="searchForWork()">Lookup</button>
				</div>
				<div class="section">
					<ng-template [ngIf]="(this.possibleWork && this.possibleWork.length > 0)" [ngIfElse]="noPossibleWork">
						<mat-accordion>
							<mat-expansion-panel *ngFor="let work of possibleWork  | paginate: { itemsPerPage: itemsPerPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
								<mat-expansion-panel-header>
									<mat-panel-title>
										<span>{{work.type.description + (work.address ? ( ' [' + work.address.StreetName + ']' ): '' )}}</span>
									</mat-panel-title>
									<mat-panel-description style="display:initial;flex-grow:1">
										<a (click)="createBid($event, work)" class="float-right">Create Bid</a>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<div class="section">
									<mat-form-field *ngIf="work.options && work.options.length > 0">
										<mat-select placeholder="Selected Options">
											<mat-option *ngFor="let o of work.options" [value]="o.id">{{o.name}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<mat-action-row *ngIf="possibleWork.length > 1">
									<button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && possibleWork.length > 1">Previous</button>
									<button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < possibleWork.length && possibleWork.length > 1">Next</button>
								</mat-action-row>
							</mat-expansion-panel>
						</mat-accordion>
						<div style="text-align:center">
							<hr />
							<pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
						</div>
					</ng-template>
					<ng-template #noPossibleWork>
						<mat-card class="section" *ngIf="searched && (possibleWork == null || possibleWork.length === 0)">
							There are no Work Orders open for bidding for selected criteria OR you did not purchase Option (paid) to allow search.
						</mat-card>
					</ng-template>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="My Bids">
			<div class="section">
				<ng-template [ngIf]="(this.myBids && this.myBids.length > 0)">
					<mat-accordion>
						<mat-expansion-panel *ngFor="let bid of myBids  | paginate: { itemsPerPage: itemsPerPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<span [ngClass]="{'danger' : !bid.isOpen}" title="{{(bid.isOpen === true ? ' ' : 'Bidding Closed, ') + '[ID - '+ bid.id +']'}}">{{bid.serviceType.description + (bid.address != null ? ( ' [' + bid.address.StreetName + ', ' + bid.address.City + ', ' +  bid.address.State + ']' ): '')}}</span>
									<span *ngIf="bid.otherNumberOfBids && bid.otherNumberOfBids > 0" matBadge="{{bid.otherNumberOfBids}}" matBadgeOverlap="false" title="# of other Bids">!</span>
								</mat-panel-title>
								<mat-panel-description style="display:initial;flex-grow:1">
									{{this.FormatNumberToCurrency(bid.price)}}<span *ngIf="bid.isEstimated"> *</span>
									<a (click)="update($event, bid.id)" *ngIf="bid.canUpdate" title="Change Bid." class="float-right">Change</a>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div class="section">
								<mat-form-field *ngIf="bid.options && bid.options.length > 0">
									<mat-select placeholder="Selected Options">
										<mat-option *ngFor="let o of bid.options" [value]="o.id" class="auto">{{o.name}}</mat-option>
									</mat-select>
								</mat-form-field>
								<div>
									<div><label class="bold">{{'System.CurrentStatus' | translateTag}}</label> - {{bid.statusDescription}}</div>
									<app-next-status-actions [actions]="bid.nextSteps" (itemSelected)="changeStatus($event)"></app-next-status-actions>
								</div>
							</div>
							<mat-action-row *ngIf="myBids.length > 1">
								<button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && myBids.length > 1">Previous</button>
								<button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < myBids.length && myBids.length > 1">Next</button>
							</mat-action-row>
						</mat-expansion-panel>
					</mat-accordion>
					<div style="text-align:center">
						<hr />
						<pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
					</div>
				</ng-template>
				<ng-template #noBids>
					<mat-card class="section" *ngIf="myBids == null || myBids.length === 0">
						You have not submitted any bids or have not received any Request for Quotes.
					</mat-card>
				</ng-template>
			</div>
		</mat-tab>
	</mat-tab-group>

</div>