import { Component, Input, Output, OnInit , Inject} from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { item, entityRoles, Role } from '../models';

import { AddPartnerDialog , AgentTermsDialog, PowerOfAttorneyDialog } from './dialogs';
import { EscrowService } from '../escrow/escrow.service';
import { ListingService } from '../listing/listing.service';
import { OfferService } from '../offer/offer.service';
import { appEnvironment } from '../../environments/environment';
import { Utils } from './utils';

@Component({
    selector: 'app-entity-roles',
    templateUrl: './entityRoles.component.html'
})
export class EntityRoleComponent extends BaseComponent implements OnInit {
    Role: typeof Role = Role;

    @Input() forRole: Role = Role.NotSet;
    @Input() allowAddPersonnel: boolean = false;
    @Input() @Output() canAddPersonnel: boolean = false;
    @Input() @Output() readonly: boolean = false;
    @Input() @Output() roles: entityRoles[] = new Array<entityRoles>();
    @Input() @Output() powerOfAttornies: any = [];

    @Input() @Output() forOffer: boolean = false;
    @Input() @Output() entityID: number = 0;
    @Input() @Output() maxAvailablePartnership: number = 0;
    @Input() @Output() escrowID: number = 0;
    @Input() @Output() myRole: Role = Role.NotSet;
    @Input() @Output() agentName: string = '';
    @Input() @Output() brokerName: string = '';
    @Input() @Output() propertyAddress: string = '';
    saving: number = 0;

    myPID: number = 0;

    private _to: any;
    private _dataservice: DataService;
    private _router: Router;

    constructor(ds: DataService, rs: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog) {
        super('entityRole', rs, g);
        this._dataservice = ds;
        this._router = rs;
    }

    ngOnInit() {
        setTimeout(() => {
            if (this.forOffer) {
                if (this.myRole === Role.NotSet) this.myRole = Role.Buyer;
            } else {
                if (this.myRole === Role.NotSet) this.myRole = Role.Seller;
            }
        }, 1000);

        this.myPID = this.user && this.user.details ? this.user.details.PID : 0;

    }

    addPartner(fromMyOwnership: number = null) {

        let _availableValue: number = 0;
        if (this.maxAvailablePartnership === 0 && (fromMyOwnership == null || fromMyOwnership <= 0)) {
            const _r = this.roles.find((r) => r.pid === this.myPID);
            if (_r != null)
                _availableValue = _r.ownershipStake;
        } else
            _availableValue = this.maxAvailablePartnership;

        const _addTypeNew = fromMyOwnership == null || fromMyOwnership <= 0;

        if (fromMyOwnership == null) {
            const _myRole = this.roles.find((r) => r.pid = (this.user.details && this.user.details.PID ? this.user.details.PID : 0));
            fromMyOwnership = _myRole == null ? 0 : _myRole.ownershipStake;
        }

        const dialogRef = this.dialog.open(AddPartnerDialog, {
            data: {
                id: this.entityID
                , forOffer: this.forOffer
                , forRole: this.forRole
                , maxOwnership: (fromMyOwnership == null || fromMyOwnership <= 0) ? _availableValue : fromMyOwnership
                , header: (_addTypeNew === true) ? ('Add Partner' + (this.forOffer ? '/Agent' : '')) : 'Assign part of Ownership to Someone Else'
                , assign: fromMyOwnership > 0
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {

                const _role = result.role;

                this.roles = this.roles.filter((r) => { return r.pid !== _role.pid; });
                if (this.roles.length > 1)
                    this.roles.splice(0, 0, Utils.castTo(_role, new entityRoles()));
                else
                    this.roles.push(Utils.castTo(_role, new entityRoles()));

                if (fromMyOwnership == null || fromMyOwnership <= 0) {

                    if (this.maxAvailablePartnership === 0 && (fromMyOwnership == null || fromMyOwnership <= 0)) {
                        const _r = this.roles.find((r) => r.pid === this.myPID);
                        if (_r != null)
                            _r.ownershipStake -= result.change;
                    } else
                        this.maxAvailablePartnership -= result.change;

                    this.showMessage('Assigned Successfully');
                } else if (result.change) {
                    this.showMessage('Assigned Successfully');
                    this.roles.forEach((r) => {
                        if (r.pid === this.user.details.PID)
                            r.ownershipStake -= result.change;
                    });
                }
                this.roles = [...this.roles]; // ngx-datatable -- force rebind
            }
        });
    }

    viewDC(id: number) {
        let _response;
        this.gc.setComponentBusy(true);
        const _es = (new EscrowService(this._dataservice, this._router, this.myResourceCategory)).deathCertificate(this.escrowID, id);
        if (_es != null) {
            _es.subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && !Utils.isNullOrEmpty(_response.error))
                        this.OpenDownloadableDoc(_response.data, 'deathCertificate.pdf', 'pdf');
                }
            );
        }
    }

    verifyDC(id: number) {
        let _response;
        this.gc.setComponentBusy(true);
        const _es = (new EscrowService(this._dataservice, this._router, this.myResourceCategory)).verifyDeathCertificate(this.escrowID, id);
        if (_es != null) {
            _es.subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && !Utils.isNullOrEmpty(_response.error))
                        this.showMessage('Power of Attorney has been approved to be part of current transaction.');
                }
            );
        }
    }

    removePerson(id: number) {
        let _response;
        this.gc.setComponentBusy(true);
        if (!this.forOffer) {
            (new ListingService(this._dataservice, this._router, this.myResourceCategory)).removePerson(this.entityID, id).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.roles = this.roles.filter(_r => { return _r.id !== id; });
                        }
                }
            );
        } else {
            (new OfferService(this._dataservice, this._router, this.myResourceCategory)).removePerson(this.entityID, id).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.roles = this.roles.filter(_r => { return _r.id !== id; });
                        }
                }
            );
        }
    }

    showAgentTerms(id: number, needAcceptance: boolean) {
        const dialogRef = this.dialog.open(AgentTermsDialog, {
            data: {
                id: this.entityID
                , forOffer: this.forOffer
                , needAcceptance: needAcceptance
                , agentRepresenting: this.forOffer ? Role.Buyer : Role.Seller
                , agentName: this.agentName
                , brokerName: this.brokerName
                , propertyAddress: this.propertyAddress
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (result != null && result === true)
                    this.showMessage('Real Estate Brokerage Terms Acceptance has been recorded');
                const _role = this.roles.find(_r => { return _r.id === id; });
                _role.canAcceptAgentTerms = false;
            }
        });
    }

    viewPOA(id: number) {
        let _response;
        this.gc.setComponentBusy(true);
        if (!this.forOffer) {
            (new ListingService(this._dataservice, this._router, this.myResourceCategory)).getPowerOfAttorney(this.entityID, id).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => { this.onViewPOA(_response); }
            );
        } else {
            (new OfferService(this._dataservice, this._router, this.myResourceCategory)).getPowerOfAttorney(this.entityID, id).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => { this.onViewPOA(_response); }
            );
        }
    }

    approvePOA(id: number) {
        let _response;
        this.gc.setComponentBusy(true);
        const _es = (new EscrowService(this._dataservice, this._router, this.myResourceCategory)).approvePowerOfAttorney(this.escrowID, id);
        if (_es != null) {
            _es.subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && !Utils.isNullOrEmpty(_response.error))
                        this.showMessage('Power of Attorney has been approved to be part of current transaction.');
                }
            );
        }
    }

    declinePOA(id: number) {
        let _response;
        this.gc.setComponentBusy(true);
        if (!this.forOffer) {
            (new ListingService(this._dataservice, this._router, this.myResourceCategory)).revokePowerOfAttorney(this.entityID, id).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => { this.afterDeclinePOA(_response, id); }
            );
        } else {
            (new OfferService(this._dataservice, this._router, this.myResourceCategory)).revokePowerOfAttorney(this.entityID, id).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => { this.afterDeclinePOA(_response, id); }
            );
        }
    }

    uploadPOA(id: number) {
        const _data = {
            id: this.entityID
            , myRole: this.forOffer ? Role.Buyer : Role.Seller
            , partners: this.roles.filter((r) => { return r.pid !== this.user.details.PID && r.roleID === (this.forOffer ? Role.Buyer : Role.Seller); }).map((c) => { return new item(c.pid, c.fullName); })
            , roleID: id
        };

        const dialogRef = this.dialog.open(PowerOfAttorneyDialog, {
            data: _data
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (result != null && result === true)
                    this.showMessage('Power of attorney has been recorded');
                const _role = this.roles.find(_r => { return _r.id === id; });
                _role.poaPresent = true;
                this.roles = [...this.roles];
            }
        });
    }

    representCompany(id: number) {
        let _response;
        this.gc.setComponentBusy(true);
        if (!this.forOffer) {
            (new ListingService(this._dataservice, this._router, this.myResourceCategory)).representCompany(this.entityID).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && !Utils.isNullOrEmpty(_response.error))
                        this.showMessage('Your request has been recorded.');
                }
            );
        } else {
            (new OfferService(this._dataservice, this._router, this.myResourceCategory)).representCompany(this.entityID).subscribe(
                (r) => { this.gc.setComponentBusy(false); _response = r; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && !Utils.isNullOrEmpty(_response.error))
                        this.showMessage('Your request has been recorded.');
                }
            );
        }
    }

    acceptPartnership(id: number, ownership: number) {
        this.gc.setComponentBusy(true);
        let _response;
        if (!this.forOffer) {
            (new ListingService(this._dataservice, this._router, this.myResourceCategory)).acceptPartnership(this.entityID, id, ownership).subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => { this.afterAcceptance(_response, id); }
            );
        } else {
            (new OfferService(this._dataservice, this._router, this.myResourceCategory)).acceptPartnership(this.entityID, id, ownership).subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => { this.afterAcceptance(_response, id); }
            );
        }
    }
    valueChanged(partner) {
        this.roles.forEach((r) => {
            if (partner.pid && r.pid && r.pid === partner.pid) {
                r.isDirty = true;
                this.triggerSave();
            }
        });
    }

    private afterAcceptance(response, id) {
        this.gc.setComponentBusy(false);
        if (response != null)
            if (Utils.isNullOrEmpty(response.error)) {
                this.showMessage('Your acceptance has been recorded.');
                const _r = this.roles.find((r) => { return (r.id === id); });
                if (_r) { _r.canAcceptPartnerShip = false; _r.canAssign = true; }
                this.roles = [...this.roles];
            } else {
                this.showMessage(response.error);
            }
    }

    private afterDeclinePOA(response, id) {
        this.gc.setComponentBusy(false);
        if (response != null)
            if (Utils.isNullOrEmpty(response.error)) {
                this.showMessage('Power of Attorney has been removed/declined from current transaction.');
                const _role = this.roles.find(_r => { return _r.id === id; });
                _role.canDeclinePOA = _role.canVerifyPOA = false;
                this.roles = [...this.roles];
            } else {
                this.showMessage(response.error);
            }
    }

    private onViewPOA(response) {
        if (response && response.data)
            this.OpenDownloadableDoc(response.data, 'poa', 'pdf');
    }

    private triggerSave() {

        if (this._to && this.saving <= 0) clearTimeout(this._to);
        if (this.saving < 0) this.saving = 0;

        this._to = setTimeout(() => { this.save(this.roles); }, appEnvironment.autoSaveTime);
    }
    private save(items) {
        let _itemsToUpdate: number = 0;

        if (items != null)
            _itemsToUpdate = items.filter((c) => { return c.isDirty; }).length;

        if (_itemsToUpdate > 0) {
            // Call Save API
            let saveResponseData;
            this.saving++;

            this.gc.setComponentBusy(true);
            if (this.forOffer) {
                (new OfferService(this._dataservice, this.route, this.myResourceCategory)).updatePartners(this.entityID, items.filter((c) => { return c.isDirty; })).subscribe(
                    (r) => { this.gc.setComponentBusy(false); saveResponseData = r; }
                    , (error) => { this.saving--; this.onApiError(error); }
                    , () => { this.saving--; });
            } else {
                (new ListingService(this._dataservice, this.route, this.myResourceCategory)).updatePartners(this.entityID, items.filter((c) => { return c.isDirty; })).subscribe(
                    (r) => { this.gc.setComponentBusy(false); saveResponseData = r; }
                    , (error) => { this.saving--; this.onApiError(error); }
                    , () => { this.saving--; });
            }
        }
    }
}
