import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { GlobalComponent } from '../../global.component';

import { Router, Route } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { escrowDisbursement, Role, item, escrow } from '../../models';
import { EscrowService } from '../escrow.service';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-new-disbursement-dialog',
    templateUrl: './newDisbursement.dialog.html'
})
export class NewDisbursementDialog extends BaseDialog implements OnInit {
    dialogHeader: string = 'Disburse Funds';

    newDisbursement: escrowDisbursement = new escrowDisbursement();
    escrowID: number = 0;
    amountAvailable: number = 0;
    roles: any = [];
    saving: boolean = false;
    error: string = '';
    mode: any = [];
    saveReady: boolean = false;

    currentEscrow: escrow = new escrow();
    constructor(dataservice: DataService, r: Router, dialogRef: MatDialogRef<NewDisbursementDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addDisbursementDialog', dataservice, r, dialogRef, data);
    }

    setSaveReady() {
        this.saveReady = this.newDisbursement.amount > 0 && this.newDisbursement.amount <= this.amountAvailable
            && (this.newDisbursement.disburseTo > 0 || this.newDisbursement.companyID > 0);
    }

    ngOnInit() {
        if (this.data) {
            if (this.data.id) this.escrowID = this.data.id;
            if (this.data.roles)
                this.roles = this.data.roles;
            else {
                this.roles = [];
                this.roles.push(new item(1, 'Buyer(s)'));
                this.roles.push(new item(2, 'Sellers(s)'));
            }
            if (this.data.amountAvailable) this.amountAvailable = this.data.amountAvailable;
            if (this.data.header) this.dialogHeader = this.data.header;
        }


        this.mode.push(new item(1, 'Check'));
        this.mode.push(new item(2, 'Electronic Fund Transfer'));
        if (this.escrowID === 0 || this.roles == null || this.roles.length === 0) {
            setTimeout(() => { this.dialogRef.close(null); this.showError('Escrow information missing'); }, 1000);
        } else if (this.amountAvailable <= 0) {
            setTimeout(() => { this.dialogRef.close(null); this.showError('Not enough funds available to disburse.'); }, 1000);
        }

        this.componentBusy = true;
        let _response;
        if (this.escrowID > 0) {
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getEscrow(this.escrowID);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { this.componentBusy = false; }
                    , () => {
                        this.componentBusy = false;
                        if (_response && _response.data)
                            this.currentEscrow = _response.data;
                    });
            }
        }
    }

    roleSelected(id: string) {
        this.newDisbursement.disburseTo = Role.NotSet;
        this.newDisbursement.companyID = 0;

        if (!Utils.isNullOrEmpty(id)) {
            const _id = id.split(':');
            if (_id.length > 0 && parseInt(_id[0]) > 0)
                this.newDisbursement.disburseTo = parseInt(_id[0]);
            if (_id.length > 1 && parseInt(_id[1]) > 0)
                this.newDisbursement.companyID = parseInt(_id[1]);
        }

        this.setSaveReady();
    }

    close(data) {
        this.dialogRef.close(data);
    }

    onSubmit() {
        this.saving = true;
        let _response;
        if (typeof this.newDisbursement.debitTo === 'string' && !Utils.isNullOrEmpty(this.newDisbursement.debitTo)) {
            const _id = (this.newDisbursement.debitTo as string).split(':');
            if (_id.length > 0 && parseInt(_id[0]) > 0)
                this.newDisbursement.debitTo = parseInt(_id[0]);
            else
                this.newDisbursement.debitTo = 0;
        }

        if (this.newDisbursement.debitTo !== Role.NotSet && this.newDisbursement.disburseTo !== Role.NotSet && this.newDisbursement.debitTo === this.newDisbursement.disburseTo) {
            this.showError('Cannot Submit to Same Role');
            return;
        }

        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).newDisbursement(this.escrowID, this.newDisbursement);
        if (_es) {
            _es.subscribe((r) => _response = r
                , (error) => { this.saving = false; }
                , () => {
                    this.saving = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.error = _response.error;
                        else if (_response.data)
                            this.close(_response.data);
                        else
                            this.close(null);
                });
        }
    }
}
