import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {

  transform(text: string, length: number = 100 ): string {
        if (text != null && text.length > length) {
     text = text.substring(0, length) + '...';
    }

    return text;
  }
}
