import { Component, Inject } from '@angular/core';


import { SignUp, BaseResponse } from '../models';
import { DataService } from '../util/APICaller.component';
import { Router } from '@angular/router';
import { LegalService } from '../common/service';
import { HTMLDialog } from '../common/dialogs';
import { PersonService } from '../account/person.service';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { appEnvironment } from '../../environments/environment';
import { ListingService } from '../listing/listing.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScriptService } from '../common/scriptService';
import { Utils } from '../common/utils';

declare const grecaptcha: any;

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html'
})
export class SignUpComponent extends BaseComponent {

    account: SignUp = new SignUp();
    submitted: boolean = false;
    listingID: number = 0;
    message: string = '';
    inPopup: boolean = false;
    css: string = 'appContent';
    heading: string = 'Message to Seller(s)';

    constructor(private dataservice: DataService, r: Router, private dialog: MatDialog, @Inject(GlobalComponent) g: GlobalComponent
        , public dialogRef: MatDialogRef<SignUpComponent>, @Inject(MAT_DIALOG_DATA) public data: any    ) {
        super('pgRegister', r, g);
        ScriptService.loadExternalScript(appEnvironment.reCaptchaScript);

        if (this.data) {
            if (this.data.listingID != null) this.listingID = this.data.listingID;
            if (this.data.css != null) this.css = this.data.css;
            if (this.data.message != null) this.message = this.data.message;
            if (this.data.heading != null) this.heading = this.data.heading;
            if (this.data.inPopup != null) this.inPopup = this.data.inPopup;
        }
    }

    get saveReady() {

        if (this.listingID == null || this.listingID < 0)
            this.listingID = 0;

        return !Utils.isNullOrEmpty(this.account.firstName) && !Utils.isNullOrEmpty(this.account.lastName) && !Utils.isNullOrEmpty(this.account.emailAddress)
            // && !Utils.isNullOrEmpty(this.account.confirmEmailAddress)
            // && this.account.emailAddress === this.account.confirmEmailAddress
            && ((!this.loggedIn && this.account.tncAgreed && (this.listingID === 0 || (this.listingID > 0 && !Utils.isNullOrEmpty(this.message)))) || this.loggedIn);
    }

    onSubmit() {
        if (GlobalComponent._cv === false && grecaptcha != null) {
            let _response: BaseResponse;
            grecaptcha.ready(() => {
                grecaptcha.execute(appEnvironment.googleReCaptcha, { action: 'submit' }).then((token) => {
                    (new PersonService(this.dataservice, this.route, this.myResourceCategory)).verifyRecaptcha(token).subscribe(
                        (data) => _response = data,
                        (error) => { this.showResourceError('System.CaptchaFailed'); },
                        () => {
                            GlobalComponent._cv = true;
                            this.onVerifyCatcha_Register();
                        });
                });
            });
        } else if (GlobalComponent._cv === true)
            this.onVerifyCatcha_Register();
    }

    cancelRegister() {
        if (this.inPopup)
            this.close();
        else
            this.gotoURL('/');
    }

    gotoSignin() {
        if (!this.inPopup)
            this.gotoLogin();
    }

    openTerms() {
        let _response: BaseResponse;
        (new LegalService(this.dataservice, this.route, this.myResourceCategory)).getTermsOfUse().subscribe(
            (data) => _response = data,
            (error) => { this.onApiError(error); },
            () => {
                if (_response && Utils.isNullOrEmpty(_response.error)) {
                    if (_response.data.content)
                        this.dialog.open(HTMLDialog, { data: { html: _response.data.content } });
                }
            }
        );
    }

    openPolicy() {
        let _response: BaseResponse;
        (new LegalService(this.dataservice, this.route, this.myResourceCategory)).getPrivacyPolicy().subscribe(
            (data) => _response = data,
            (error) => { this.onApiError(error); },
            () => {
                if (_response && Utils.isNullOrEmpty(_response.error)) {
                    if (_response.data.content)
                        this.dialog.open(HTMLDialog, { data: { html: _response.data.content } });
                }
            }
        );
    }

    private close() {
        if (this.dialogRef && this.dialogRef.close)
            this.dialogRef.close();
    }

    private onVerifyCatcha_Register() {

        if (!this.saveReady) return;

        this.gc.setBusy(true);
        this.submitted = true;
        let _response: BaseResponse;

        // Person on Invitation cannot give consent
        if (this.loggedIn) {
            this.account.dne = true;
            this.account['invited'] = true;
        } else if (this.listingID == null || this.listingID === 0) {
            this.account['ignoreDuplicate'] = true;
        }

        if (this.listingID != null && this.listingID > 0) {
            this.account['message'] = { id: this.listingID, message: this.message };
        }

        (new PersonService(this.dataservice, this.route, this.myResourceCategory)).signup(this.account).subscribe(
            (data) => _response = data,
            (error) => { this.onApiError(error); },
            () => {
                this.gc.setBusy(false);
                this.account = new SignUp();
                if (_response)
                    if (Utils.isNullOrEmpty(_response.error)) {
                        if (this.loggedIn) {
                            this.showMessage('Thank You. Invitation has been sent. You may invite another Friend or Family member.');
                            this.account = new SignUp();
                        } else {
                            if (this.listingID == null || this.listingID === 0) {
                                this.showMessage('Your account has been created and a temporary password has been sent to the email you provided. Click on the link in the email to verify receipt of email and sign back in.');
                                this.gotoSignin();
                            } else
                                this.sendMessageToSeller(_response.data);
                        }
                    } else if (_response.error === 'DUPLICATE_EMAIL') {
                        if (!this.loggedIn)
                            this.showError('Profile for provided email already exists, if you forgot your password, Please go to "Sign In" page and use Forgot Password link.');
                        else
                            this.showError('Profile for provided email already exists, it seems the person you invited is already using ' + this.myBRAND + '. ');
                    } else
                        this.showError(_response.error);
            }
        );
    }

    private sendMessageToSeller(oData: any) {
        if (oData)
            if (oData.messageSent) {
                this.sentMessageToSeller(oData.messageSent);
            } else {
                const _s = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).sendMessageToSeller(this.listingID, this.message);
                if (_s) {
                    let _response: BaseResponse;
                    _s.subscribe(
                        (data) => _response = data,
                        (error) => { this.onApiError(error); },
                        () => {
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.sentMessageToSeller();
                        });
                }
            }
    }

    private sentMessageToSeller(sMessage: string = null) {
        if (Utils.isNullOrEmpty(sMessage))
            this.showMessage('Thank You. Your message has been sent to the Seller(s). You should hear from them soon.');
        else
            this.showMessage(sMessage);

        this.close();
    }
}
