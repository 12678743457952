import {AddressType} from '../address.model';
import { PersonAddress } from './person.address.model';
import { PhoneNumber, PhoneNumberType } from './person.phonenumber.model';
import { Demographic } from './person.demographics.model';
import { CertificationEntity } from './person.certification.model';
import { Staff } from '../providers/staff.model';

import { RecordTimeStamp } from '../base.model';
import * as moment from 'moment';
import { Utils } from '../../common/utils';

export class Person extends RecordTimeStamp {

    // tslint:disable-next-line: variable-name
    public TaxID: string = '';
    // tslint:disable-next-line: variable-name
    public Phone: PhoneNumber = new PhoneNumber();
    // tslint:disable-next-line: variable-name
    public Addresses: PersonAddress[] = [];
    // tslint:disable-next-line: variable-name
    public DemoGraphics: Demographic[] = [];
    // tslint:disable-next-line: variable-name
    public Certifications: CertificationEntity[] = [];
    // tslint:disable-next-line: variable-name
    public CompanyAssociations: Staff[] = [];

    private _firstName: string = '';
    private _lastName: string = '';
    private _middleName: string = '';
    private _emailAddress: string = '';
    private _companyID: number; private _pid: number;
    private _companyName: string = '';
    private _jobTitle: string = ''; private _occupation: string = '';
    private _preferredLanguage1: string = '';
    private _companyLocationID: number; private _maritalStatusID: number;
    private _doNotEmail: boolean = false; private _emailable: boolean = true;
    private _TNCAcceptDate: Date; private _dateOfBirth: Date;

    constructor() {
        super();
        this.Phone = new PhoneNumber();
    }

    public get PID(): number { return this._pid; }
    public set PID(v: number) { this._pid = v; }

    public get FirstName(): string {
        return this._firstName;
    }
    public set FirstName(value: string) {
        if (this._firstName !== value)
            this.isDirty = true;
        this._firstName = value;
    }
    public get MiddleName(): string {
        return this._middleName;
    }
    public set MiddleName(value: string) {
        if (this._middleName !== value)
            this.isDirty = true;
        this._middleName = value;
    }
    public get LastName(): string {
        return this._lastName;
    }
    public set LastName(value: string) {
        if (this._lastName !== value)
            this.isDirty = true;
        this._lastName = value;
    }

    public get Name(): string { return this.FirstName + ' ' + ((this.MiddleName && this.MiddleName.trim() === '') ? '' : this.MiddleName + ' ') + this.LastName; }

    public get DateOfBirth(): Date {
        return this._dateOfBirth;
    }
    public set DateOfBirth(value: Date) {
        if (!moment(this._dateOfBirth).isSame(value))
            this.isDirty = true;
        this._dateOfBirth = value;
    }
    public get FullName(): string {
        if (Utils.isNullOrEmpty(this._firstName) && Utils.isNullOrEmpty(this._middleName) && Utils.isNullOrEmpty(this._lastName))
            return this._emailAddress;
        return (this._firstName + (Utils.isNullOrEmpty(this._middleName) ? '' : ' ') + this._middleName + ' ' + this._lastName).trim();
    }
    public get CompanyID(): number {
        return this._companyID;
    }
    public set CompanyID(value: number) {
        if (this._companyID !== value)
            this.isDirty = true;
        this._companyID = value;
    }
    public get CompanyName(): string {
        return this._companyName;
    }
    public set CompanyName(value: string) {
        this._companyName = value;
    }
    public get IdentityComplete(): boolean {
        return (!Utils.isNullOrEmpty(this.FullName) && this.PrimaryAddress.IsComplete === true );
    }

    public get EmailAddress(): string {
        return this._emailAddress;
    }
    public set EmailAddress(value: string) {
        if (this._emailAddress !== value)
            this.isDirty = true;
       this._emailAddress = value;
    }
    public get DoNotEmail(): boolean {
        return this._doNotEmail;
    }
    public set DoNotEmail(value: boolean) {
        if (this._doNotEmail !== value)
            this.isDirty = true;
       this._doNotEmail = value;
    }
    public get Emailable(): boolean {
        return this._emailable;
    }
    public set Emailable(value: boolean) {
        if (this._emailable !== value)
            this.isDirty = true;
       this._emailable = value;
    }

    public get CompanyLocationID(): number {
        return this._companyLocationID;
    }
    public set CompanyLocationID(value: number) {
        if (this._companyLocationID !== value)
            this.isDirty = true;
       this._companyLocationID = value;
    }

    public get PreferredLanguage(): string {
        return this._preferredLanguage1;
    }
    public set PreferredLanguage(value: string) {
        if (this._preferredLanguage1 !== value)
            this.isDirty = true;
       this._preferredLanguage1 = value;
    }
    public get Occupation(): string {
        return this._occupation;
    }
    public set Occupation(value: string) {
        if (this._occupation !== value)
            this.isDirty = true;
       this._occupation = value;
    }
    public get JobTitle(): string {
        return this._jobTitle;
    }
    public set JobTitle(value: string) {
        if (this._jobTitle !== value)
            this.isDirty = true;
       this._jobTitle = value;
    }
    public get MaritalStatusID(): number {
        return this._maritalStatusID;
    }
    public set MaritalStatusID(value: number) {
        if (this._maritalStatusID !== value)
            this.isDirty = true;
       this._maritalStatusID = value;
    }

    public get TNCAcceptDate(): Date {
        return this._TNCAcceptDate;
    }

    public get PrimaryAddress(): PersonAddress {
        if (this.Addresses != null) {
            const v: PersonAddress[] = this.Addresses.filter((_a) => _a.AddressType === AddressType.Physical);
            if (v != null && v.length > 0)
                return v[0];
        }
        return new PersonAddress();
    }

}
