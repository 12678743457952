import { Router, Route } from '@angular/router';
import { BaseService } from '../common/service/base.service';

import { DataService } from '../util/APICaller.component';
import { SPBid, BidRFQStatus } from '../models/providers/serviceProvider.model';

import { WorkOrderSaveModel } from '../models/workOrder.model';
import { ServiceWorkOrderStatus } from '../models/enums.component';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WorkOrderService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    getWorkOrder(iWorkOrderID: number, bFullObject: boolean = false) {
        const data = { id: iWorkOrderID, fullObject: bFullObject };
        return this.ds.APIPost('/workOrder/workOrder', data, this.requestingModule);
    }
    acceptWorkOrderTerms(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };
        return this.ds.APIPost('/workOrder/acceptAllTerms', data, this.requestingModule);
    }
    getTermsDetail(iWorkOrderID: number, iTermID: number) {
        const data = { id: iWorkOrderID, termID: iTermID };
        return this.ds.APIPost('/workOrder/getTermsDetail', data, this.requestingModule);
    }
    changeStatus(iWorkOrderID: number, enNewStatus: ServiceWorkOrderStatus) {
        const data = { id: iWorkOrderID, newStatus: enNewStatus };
        return this.ds.APIPost('/workOrder/changeStatus', data, this.requestingModule);
    }
    createNewPrivateWorkOrder(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };
        return this.ds.APIPost('/workOrder/createNewPrivate', data, this.requestingModule);
    }
    saveWorkOrdersforSP(items: WorkOrderSaveModel[]) {
        const data = { items };
        return this.ds.APIPost('/workOrder/saveWorkOrderForSP', data, this.requestingModule);
    }
    changeSP(data: any) {
        return this.ds.APIPost('/workOrder/assignServiceProvider', data, this.requestingModule);
    }
    payNow(data: any) {
        return this.ds.APIPost('/workOrder/payNow', data, this.requestingModule);
    }
    assumeLiability(id: number) {
        const data = { id };
        return this.ds.APIPost('/workOrder/assumeLiability', data, this.requestingModule);
    }
    prepareAddDemand(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };
        return this.ds.APIPost('/workOrder/prepareToAddDemand', data, this.requestingModule);
    }

    prepareToAddContingencies(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };
        return this.ds.APIPost('/workOrder/prepareToAddContingencies', data, this.requestingModule);
    }
    prepareToAddDependencies(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };

        return this.ds.APIPost('/workOrder/prepareToAddDependencies', data, this.requestingModule);
    }
    prepareToAddVisit(iWorkOrderID: number, showUpcomingVisits: boolean = false) {
        const data = { id: iWorkOrderID, showUpcomingVisits };

        return this.ds.APIPost('/workOrder/prepareToAddVisit', data, this.requestingModule);
    }
    addDependencies(iWorkOrderID: number, serviceIDs: number[], reason: string = '') {
        const data = { id: iWorkOrderID, ids: serviceIDs, reason: reason };

        return this.ds.APIPost('/workOrder/addDependencies', data, this.requestingModule);
    }
    removeDependency(iWorkOrderID: number, dependencyID: number) {
        const data = { id: iWorkOrderID, dependencyID };

        return this.ds.APIPost('/workOrder/removeDependency', data, this.requestingModule);
    }
    addVisit(iWorkOrderID: number, visistDate: Date, duration: number, iPID: number, reason: string) {
        const data = { id: iWorkOrderID, visitDate: visistDate, duration, pid: iPID, reason };
        return this.ds.APIPost('/workOrder/addVisit', data, this.requestingModule);
    }

    removeVisit(iWorkOrderID: number, visitID: number) {
        const data = { id: iWorkOrderID, visitID };
        return this.ds.APIPost('/workOrder/removeVisit', data, this.requestingModule);
    }

    getAdditionalOrderableServices(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };
        return this.ds.APIPost('/workOrder/additionalOrderableServices', data, this.requestingModule);
    }
    addAdditionalService(oServiceDetails) {
        const data = oServiceDetails;

        return this.ds.APIPost('/workOrder/addAdditionalService', data, this.requestingModule);
    }
    notifyRepairs(oData: any) {
        return this.ds.APIPost('/workOrder/notifyRepairs', oData, this.requestingModule);
    }
    getRepairDetails(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };
        return this.ds.APIPost('/workOrder/getRepairDetails', data, this.requestingModule);
    }
    createRepairWorkOrder(oData: any) {
        const data = oData;
        return this.ds.APIPost('/workOrder/createRepairWorkOrder', data, this.requestingModule);
    }
    getBids(iWorkOrderID: number) {
        const data = { id: iWorkOrderID, bidsOnly : true };
        return this.ds.APIPost('/workOrder/bidsAndRFQs', data, this.requestingModule);
    }
    getRFQs(iWorkOrderID: number) {
        const data = { id: iWorkOrderID, rfQsOnly : true };
        return this.ds.APIPost('/workOrder/bidsAndRFQs', data, this.requestingModule);
    }
    createBid(iWorkOrderID: number) {
        const data = { id: iWorkOrderID };
        return this.ds.APIPost('/workOrder/createBid', data, this.requestingModule);
    }
    saveBid(iWorkOrderID: number, oBid: SPBid) {
        const data = { id: iWorkOrderID, bid: oBid };
        return this.ds.APIPost('/workOrder/saveBid', data, this.requestingModule);
    }
    changeBidStatus(iBidID: number, enNewStatus: BidRFQStatus) {
        const data = { id: iBidID, newStatus: enNewStatus };
        return this.ds.APIPost('/workOrder/bid/changeStatus', data, this.requestingModule);
    }
    changeVisitStatus(iWorkOrderID: number, iVisitID: number, enNewStatus, notes: string = '') {
        const data = { id: iWorkOrderID, visitID: iVisitID, newStatus: enNewStatus, notes};
        return this.ds.APIPost('/workOrder/visit/changeStatus', data, this.requestingModule);
    }
}
