<mat-card class="section" *ngIf="this.powerOfAttornies?.length > 0">
    <div class="app-flex-container app-2col">
        <select size="5" >
            <option *ngFor="let poa of this.powerOfAttornies" [value]="poa.id">{{poa.name}}</option>
        </select>
    </div>
</mat-card>

    <ng-template [ngIf]="(this.roles?.length > 0)" [ngIfElse]="noRoles">
        <div>
            <a  (click)="addPartner()" class="green float-right" title="Add {{Role[this.forRole]}} or an Agent" *ngIf="canAddPersonnel && allowAddPersonnel && !readonly"><i class="material-icons float-right">person_add</i></a>
            <span *ngIf="canAddPersonnel && allowAddPersonnel && !readonly">Use <i class="fa fa-share-alt"></i> to Assign Ownership to Partners</span>
            <hr/>
        </div>
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let role of roles; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span title="{{role.pid}}">{{(role.firstName + ' '  + role.lastName) + (user.details && user.details.PID == role.pid ? ' [Me]' : '')}}</span>
                    </mat-panel-title>
                    <mat-panel-description style="display:initial">
                        {{role.roleID != forRole ? role.roleName : ''}}
                        <i class="fa fa-trash-o danger float-right" title="Remove person." *ngIf="entityID > 0 && role.canDelete && !readonly" (click)="removePerson(role.id)"></i>
                        <i class="material-icons danger" title="Death Certificate" *ngIf="role.isDCPresent" (click)="viewDC(role.id)">description</i>
                        <i class="material-icons" title="Verify Death Certificate" *ngIf="entityID > 0 && role.canVerifyDC" (click)="verifyDC(role.id)">offline_pin</i>
                        <i class="material-icons info" title="View and accept Agent Terms" *ngIf="role.canAcceptAgentTerms || role.hasAcceptedAgentTerms" (click)="showAgentTerms(role.id, !role.accepted ? role.canAcceptAgentTerms : false)">{{!role.accepted ? 'spellcheck' : 'attach_file'}}</i>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="app-flex-container first">
                    <div *ngIf="utils.isNullOrEmpty(role.firstName) || utils.isNullOrEmpty(role.lastName)" class="input-row first" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline">
                            <mat-label>First Name<i class="fa fa-question-circle-o warning-color" title="First Name"></i></mat-label>
                            <input matInput appDisableAutofill [(ngModel)]="role.firstName" (change)="valueChanged(row)"
                                   appInputMaxLength="50" [appInputShowCount]="true" />
                        </mat-form-field>
                        <mat-form-field appearance="outline small">
                            <mat-label>MI<i class="fa fa-question-circle-o warning-color" title="Middle Initial"></i></mat-label>
                            <input matInput appDisableAutofill appInputMaxLength="1" [appInputShowCount]="true" [(ngModel)]="role.middleName" class="x-small" (change)="valueChanged(row)" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Last Name<i class="fa fa-question-circle-o warning-color" title="Last/Family Name"></i></mat-label>
                            <input matInput appDisableAutofill appInputMaxLength="50" [appInputShowCount]="true" [(ngModel)]="role.lastName" (change)="valueChanged(row)" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="role?.poaPresent === true">
                        <i class="material-icons" *ngIf="entityID > 0 && role.poaPresent" title="Power of Attorney" (click)="viewPOA(role.id)">inbox</i>
                        <i class="material-icons" *ngIf="entityID > 0 && role.canVerifyPOA" title="Approve Power of Attorney" (click)="approvePOA(role.id)">link</i>
                        <i class="material-icons" *ngIf="entityID > 0 && role.canDeclinePOA" title="Decline Power of Attorney" (click)="declinePOA(role.id)">link_off</i>
                        <i class="material-icons" *ngIf="entityID > 0 && role.canUploadPOA" title="Upload Power of Attorney" (click)="uploadPOA(role.id)">how_to_vote</i>
                    </div>
                    <div *ngIf="role?.isREAgent === true">
                        <label class="caption">Company</label><label class="data-value auto">{{role.company}}</label>
                        <i class="material-icons" (click)="representCompany(role.id)" *ngIf="entityID > 0 && role.canRepresentCompany">cast_for_education</i>
                        <i class="fa fa-exclamation-triangle warning" *ngIf="role.isREAgent && !role.hasValidCertification" title="Agent is not licensed"></i>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="medium">
                            <mat-label>Ownership %</mat-label>
                            <input matInput appDisableAutofill type="number" [disabled]="!role.canDelete || readonly" [(ngModel)]="role.ownershipStake" class="numeric"
                                   (keydown)="utils.numOnlyLimitingLength($event, 10, false)" selectOnFocus />
                        </mat-form-field>
                        <i class="fa fa-check-circle fa-2x" (click)="acceptPartnership(role.id, role.ownershipStake)" *ngIf="entityID > 0 && !readonly && role.canAcceptPartnerShip" matTooltip="Accept Partnership"></i>
                        <i class="fa fa-share-alt" (click)="addPartner(role.ownershipStake)" *ngIf="entityID > 0 && !readonly && role.canAssign" matTooltip="Assign % of my share to another Partner"></i>
                    </div>
                </div>
                <mat-action-row *ngIf="roles?.length > 1">
                    <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && roles.length > 1">Previous</button>
                    <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < roles.length && roles.length > 1">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
    <ng-template #noRoles>
        <h3 >&nbsp;</h3>
    </ng-template>
