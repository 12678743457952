import { Component, Inject } from '@angular/core';
import { DataService } from './util/APICaller.component';
import { BaseComponent } from './base.component';
import { GlobalComponent } from './global.component';
import { appEnvironment } from '../environments/environment';
import { PersonService } from './account/person.service';
import { BaseResponse } from './models';
import { Utils } from './common/utils';
import { ScriptService } from './common/scriptService';

declare var $: any;
declare const grecaptcha: any;

@Component({
    selector: 'app-contact-us',
    templateUrl: './contactus.component.html'
})
export class ContactUsContentComponent extends BaseComponent {
    name: string = '';
    email: string = '';
    message: string = '';
    constructor(private ds: DataService, @Inject(GlobalComponent) g: GlobalComponent) {

        super('', null, g);
        ScriptService.loadExternalScript(appEnvironment.reCaptchaScript, 'www.gstatic.com/recaptcha/');

        this.clear(true);
    }

    clear(bFromInit: boolean = false) {
        if (this.gc.loggedIn) {
            this.name = this.gc.user.details.Name;
            this.email = this.gc.user.details.EmailAddress;
        } else {
            this.name = this.email = '';
        }
        this.message = '';

        if (!bFromInit)
            $('#divM').html('');
    }

    contact() {
        let _response: BaseResponse;
        if (GlobalComponent._cv === false && grecaptcha != null) {
            grecaptcha.ready(() => {
                grecaptcha.execute(appEnvironment.googleReCaptcha, { action: 'submit' }).then((token) => {
                    (new PersonService(this.ds, this.route, this.myResourceCategory)).verifyRecaptcha(token).subscribe(
                        (data) => _response = data,
                        (error) => { this.showResourceError('System.CaptchaFailed'); },
                        () => {
                            GlobalComponent._cv = true;
                            this.onVerify_Contact();
                        });
                });
            });
        } else if (GlobalComponent._cv === true)
            this.onVerify_Contact();

    }

    private onVerify_Contact() {
        let _response;
        if (this.name || this.email) {
            const _data = { name: this.name, email: this.email, message: this.message };
            this.ds.APIPreLoginPost('/contactMe', _data, '').subscribe((data) => { _response = data; }
                , (error) => { }
                , () => {
                    if (_response && Utils.isNullOrEmpty(_response.error)) {
                        this.showMessage('Thank you. Someone will contact you shortly.');
                        this.clear();
                    }
                });
        }
    }
}
@Component({
    selector: 'app-about-iamorea',
    template: '<div class=appContent><app-page-asset [assetID]=4114></app-page-asset></div>'
})
export class AboutContentComponent { }
