import { Component, ChangeDetectorRef, ViewChild, ViewChildren, Inject, OnInit, AfterViewInit, Input, Output, QueryList, ElementRef, EventEmitter } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';
import { Router } from '@angular/router';

import * as moment from 'moment';

import * as address from '../models/address.model';
import { item } from '../models/appCommon.models';
import { GeographicService } from '../common/service/geographic.service';

import { AutoCompleteComponent } from '../common/autocomplete.component';
import { AddressType } from '../models/address.model';
import { appEnvironment } from '../../environments/environment';
import { Utils } from './utils';

@Component({
    selector: 'app-base-address',
    templateUrl: './address.component.html'
})
export class AddressComponent extends BaseComponent implements OnInit, AfterViewInit {

    @ViewChildren(AutoCompleteComponent) autoCompleteList: QueryList<AutoCompleteComponent>;

    @Input() @Output() public index?: number;
    @Output() public addressChanged: EventEmitter<object> = new EventEmitter<object>();
    @Output() public addressAdded: EventEmitter<object> = new EventEmitter<object>();
    @Output() @Input() required: boolean;
    @Input() public canChangeAddressType: boolean;
    @Input() public disabled: boolean;
    @Input() public showOwnerShip: boolean;
    @Input() @Output() id: string = ''; // get id(): string { if (this._id == null) this._id = ''; return this._id; } set id(v: string) { if (v == null) v = ''; this._id = v; }
    idOSD: string = '';

    ownershipTypes: item[] = new Array<item>();

    // Use Only got Module Binding
    _address: address.Address = new address.Address();
    private _addressTypes: item[] = new Array<item>();

    private acCountry: AutoCompleteComponent;
    private acState: AutoCompleteComponent;
    private acCity: AutoCompleteComponent;
    private _countries: item[] = new Array<item>();

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('baseAddress', r, g);
        this.idOSD = this.id + 'OSD';
        this.loadCountries();

        this._addressTypes = new Array<item>();
        this._addressTypes.push(new item(4, 'Billing'));
        this._addressTypes.push(new item(5, 'Legal'));
        this._addressTypes.push(new item(3, 'Mailing'));
        this._addressTypes.push(new item(2, 'Physical/Shipping'));
        this._addressTypes.push(new item(6, 'Tax'));
        this._addressTypes.push(new item(-7, 'Other'));

        this.canChangeAddressType = true;

        this.ownershipTypes = new Array<item>();
        this.ownershipTypes.push(new item(1, 'Rent'));
        this.ownershipTypes.push(new item(2, 'Own'));

        // Set default if value is not set
        setTimeout(() => {
            if (this._address == null)
                this._address = new address.Address();
            if (this._address.ISOCountryCode === 0)
                this.countrySelected(appEnvironment.defaultISOCountryCode);
        }, appEnvironment.delayedNGInit);
    }

    public get Countries(): item[] {
        return this._countries;
    }

    get addressTypes() { return this._addressTypes; }

    @Input() @Output() get Address(): address.Address {
        return this._address;
    }
    set Address(v: address.Address) {
        if (v == null) v = new address.Address();
        if (this.acCity && v.CityID === 0) this.acCity.clear();
        if (this.acState && v.StateID === 0) this.acState.clear();
        if (this.acCountry && v.ISOCountryCode === 0) this.acCountry.clear();
        this._address = Object.assign(new address.Address(), v);
        if (v && v.ISOCountryCode > 0) {

            // Just when the Data is present and set the display before all lists builds are complete
            if (this.acCountry) {
                this.acCountry.text = this._address?.Country ?? '';
                this.acCountry.value = this._address?.ISOCountryCode ?? 0;

                if (this.acState) {
                    this.acState.text = this._address?.State ?? '';
                    this.acState.value = this._address?.StateID ?? 0;
                    if (this.acCity) {
                        this.acCity.text = this._address?.City ?? '';
                        this.acCity.value = this._address?.CityID ?? 0;
                    }
                }
            }

            this.countrySelected(v.ISOCountryCode);
        }
        if ((v?.AddressType ?? AddressType.NotSet) === AddressType.NotSet)
            v.AddressType = AddressType.Physical;

        this._address.isDirty = false;
    }

    @Output() get IsComplete(): boolean {
        if (this._address.ISOCountryCode > 0)
            return this._address.StateID > 0 && this._address.CityID > 0 && !Utils.isNullOrEmpty(this._address.StreetName) && !Utils.isNullOrEmpty(this._address.PostalCode);
        return !this.required;
    }

    ngOnInit() {
        this.addressAdded.emit(this);
    }

    ngAfterViewInit() {
        this.initACControls();
        if (this._address && this._address.ISOCountryCode > 0)
            this.countrySelected(this._address.ISOCountryCode, false);
    }

    clear() {
        this._address.isDirty = false;
        this._address = new address.Address();
        this.addressChanged.emit(null);
    }

    countrySelected($selectedID: number, bUserInititated?: boolean) {
        if (bUserInititated == null) bUserInititated = false;
        if (this._address.ISOCountryCode !== $selectedID) {
            if (this.acState) this.acState.clear();
            if (this.acCity) this.acCity.clear();
        }
        this._address.ISOCountryCode = $selectedID;
        this.addressUpdated(bUserInititated);
        let responseData: any;

        if (this._address.ISOCountryCode > 0) {
            if (this.acState) {
                this.acState.loading = true;
                (new GeographicService(this.dataservice, this.r, this.myResourceCategory)).getStates(this._address.ISOCountryCode).subscribe(
                    (data) => responseData = data,
                    (error) => { this.acState.loading = false; this.onApiError(error); },
                    () => {
                        if (this.acState) {
                            this.acState.loading = false; this.acState.values = responseData.data;
                            if (!bUserInititated) {
                                this.acState.value = this._address.StateID ?? 0;
                            }
                        }
                        if (this._address.StateID > 0) {
                            this.stateSelected(this._address.StateID);
                        }
                    }
                );
                return;
            }
        }

        if (this.acCountry)
            this._address.Country = this.acCountry.text;
        if (this.acState && this.acCity) {
            this.acCity.values = this.acState.values = [];
            this.acCity.clear(); this.acState.clear();
        }
    }

    stateSelected($selectedID: number, bUserInititated?: boolean) {
        if (bUserInititated == null) bUserInititated = false;

        if (this._address.StateID !== $selectedID) {
            if (this.acCity) this.acCity.clear();
        }
        this._address.StateID = $selectedID;
        this.addressUpdated(bUserInititated);

        if (this._address.StateID > 0) {
            let responseData: any;

            if (this.acCity) {

                // Keep it silent // UnComment is visual ques are needed // this.acCity.loading = true;
                // Temporary Until API returns value
                const _c: item[] = [];
                _c.push(new item(this._address.CityID, this._address.City));
                this.acCity.values = _c;
                //

                (new GeographicService(this.dataservice, this.r, this.myResourceCategory)).getCities(this._address.ISOCountryCode, this._address.StateID).subscribe(
                    (data) => responseData = data,
                    (error) => { this.acCity.loading = false; this.onApiError(error); },
                    () => {
                        if (this.acCity) {
                            this.acCity.loading = false; this.acCity.values = responseData.data;
                            if (!bUserInititated)
                                this.acCity.value = this._address.CityID;
                        }
                        if (this._address.CityID)
                            this.citySelected(this._address.CityID);
                    }
                );
                return;
            }
        }

        if (this.acState) this._address.State = this.acState.text;
        if (this.acCity) this.acCity.values = [];
    }

    citySelected($selectedID: number, bUserInititated?: boolean) {
        if (bUserInititated == null) bUserInititated = false;
        this._address.CityID = $selectedID;
        this.addressUpdated(bUserInititated);

        if (this._address.CityID === 0) {
            this._address.City = this.acCity.text;
        }

    }

    startDateChanged(value) {
        if (value && moment(value).isValid) {
            this._address.OwnershipStartDate = value;
            this.addressUpdated();
        }
    }

    endDateChanged(value) {
        if (value && moment(value).isValid) {
            this._address.OwnershipEndDate = value;
            this.addressUpdated();
        }
    }

    addressUpdated(bUserInititated?: boolean) {
        if (bUserInititated == null) bUserInititated = false;
        if (bUserInititated) {
            if (!this._address.isDirty)
                this._address.isDirty = true;
            if (this._address == null)
                this._address = new address.Address();
            if (this.addressChanged && this._address.IsComplete === true) {
                if (this.index == null)
                    this.addressChanged.emit({ address: this._address });
                else
                    this.addressChanged.emit({ address: this._address, index: this.index });
            }
        }
    }

    private loadCountries() {
        let responseData: any;
        (new GeographicService(this.dataservice, this.r, this.myResourceCategory)).getCountries().subscribe(
            (data) => responseData = data
            , (error) => { this.onApiError(error); }
            , () => {
                this._countries = responseData.data;
                if (this.autoCompleteList && this.autoCompleteList.length > 0 && this.autoCompleteList[0]) {
                    this.autoCompleteList[0].values = this._countries;
                }
            }
        );
    }

    private initACControls() {
        if (this.autoCompleteList) {
            this.acCountry = this.autoCompleteList.find((i) => i.id.startsWith('acCountry'));
            this.acState = this.autoCompleteList.find((i) => i.id.startsWith('acState'));
            this.acCity = this.autoCompleteList.find((i) => i.id.startsWith('acCity'));
        }
    }
}
