<mat-expansion-panel [hideToggle]="static" *ngIf="hasLegalAgreement">
    <mat-expansion-panel-header>{{title}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <mat-card class="section" style="background-color:lightgray">
            <mat-card-title>
                <mat-form-field *ngIf="passwordProtectOnExport " style="width:calc(100% - 24px)">
                    <input matInput appDisableAutofill  maxlength="20" placeholder="Password on Export" [(ngModel)]="passwordForExport" />
                </mat-form-field>
                <i class="fa fa-download float-right" (click)="download()"></i>
            </mat-card-title>
            <mat-card-content style="height:{{height}}">
                <div hrefToRouterLink [innerHTML]="legalContent | safeHtml"></div>
                <div *ngIf="needToGiveAgreement">
                    <div style="font-family: mayqueen;font-size: 32px;padding-top: 15px;margin: 15px;width: auto;" class="panel" *ngIf="hasGivenAgreement">{{user.details.FullName}}</div>
                    <mat-checkbox [(ngModel)]="hasGivenAgreement">I have read and agree to Terms and Conditions</mat-checkbox>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-template>
</mat-expansion-panel>