import { RecordTimeStamp } from '../base.model'
import {CertificationEntity} from '../people/person.certification.model';
import * as moment from 'moment';
import { Utils } from '../../common/utils';

export class Staff extends RecordTimeStamp {
    public EmailAddress: string;
    public FirstName: string;
    public LastName: string;
    public ImageURL: string;
    public SpokenLanguages: string;

    public Rating: number;
    public OrdersCompleted: number;
    public AvgCompletionDays: number;
    public MinCompletionDays: number;
    public MaxCompletionDays: number;

    public ServiceProvider: string;
    public Role: string;
    public PersonRoleID: number;

    private _PID: number; private _serviceProviderID: number; private _roleID: number;
    private _roleExpiryDate: Date;
    private _isAdministrator: boolean = false;
    private _certifications: CertificationEntity[] = new Array<CertificationEntity>();
    private _designation: string = '';

    constructor() {
        super();
        this.ServiceProvider = this.Role = this.FirstName = this.LastName = this.EmailAddress = '';
    }

    public get PID(): number {
        return this._PID;
    }
    public set PID(value: number) {
        if (this._PID != value)
            this.isDirty = true;
        this._PID = value;
    }
    public get FullName(): string {
        return Utils.isNullOrEmpty(this.FirstName) ? '' : (this.FirstName + ' ' + (Utils.isNullOrEmpty(this.LastName) ? '' : this.LastName));
    }

    public get Designation(): string {
        return this._designation;
    }
    public set Designation(value: string) {
        if (this._designation !== value)
            this.isDirty = true;
        this._designation = value;
    }
    public get ServiceProviderID(): number {
        return this._serviceProviderID;
    }
    public set ServiceProviderID(value: number) {
        if (this._serviceProviderID !== value)
            this.isDirty = true;
        this._serviceProviderID = value;
    }

    public get RoleID(): number {
        return this._roleID;
    }
    public set RoleID(value: number) {
        if (this._roleID !== value)
            this.isDirty = true;
        this._roleID = value;
    }

    public get ExpiryDate(): Date {
        return this._roleExpiryDate;
    }
    public set ExpiryDate(value: Date) {
        if (this._roleExpiryDate !== value)
            this.isDirty = true;
        this._roleExpiryDate = value;
    }
    public get IsAdministrator(): boolean {
        return this._isAdministrator;
    }
    public set IsAdministrator(value: boolean) {
        if (this._isAdministrator !== value)
            this.isDirty = true;
        this._isAdministrator = value;
    }
    public get Certifications(): CertificationEntity[] {
        return this._certifications;
    }
    public set Certifications(value: CertificationEntity[]) {
        this._certifications = value;
    }
    public Certified(iServiceTypeID: number): boolean {
        return (moment(this.ExpiryDate).isAfter(new Date()) || moment(this.ExpiryDate).isSame(Utils._minDate))
            && this.Certifications.some(_c => _c.Verified && _c.ServiceTypeID === iServiceTypeID && (moment(_c.ExpiryDate).isAfter(new Date()) || moment(_c.ExpiryDate).isSame(Utils._minDate)));
    }
}
