import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { assessment } from '../../models';
import { ListingService } from '../listing.service';

import { DatePickerComponent } from '../../common/datepicker.component';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-addAssessmentDialog',
    templateUrl: './addAssessment.dialog.html'
})
export class AddAssessmentDialog extends BaseComponent implements OnInit {

    dialogHeader: string = 'Add new assessment';
    newAssessment: assessment = new assessment();
    listingID: number = 0;
    saving: boolean = false;

    @ViewChild('txtExpiryDate') txtExpiryDate: DatePickerComponent;
    error: string = '';

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent, public dialogRef: MatDialogRef<AddAssessmentDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addAssessmentDialog', r, gc);
    }

    get saveReady() { return this.newAssessment.amount > 0 && !Utils.isNullOrEmpty(this.newAssessment.description); }

    ngOnInit() {
        if (this.data) {
            if (this.data.id) this.listingID = this.data.id;
            if (this.data.header) this.dialogHeader = this.data.header;
        }

        if (this.listingID === 0) {
            setTimeout(() => { this.close(null); }, 1000);
        }
    }

    close(data) {
        this.dialogRef.close(data);
    }

    onSubmit() {

        this.newAssessment.expiryDate = this.txtExpiryDate.value;
        this.saving = true;
        // Send API Request to Add
        let _response;
        (new ListingService(this.dataservice, this.r, this.myResourceCategory)).addAssessment(this.listingID, this.newAssessment).subscribe(
            (data) => _response = data
            , (error) => { this.saving = false; this.onApiError(error); }
            , () => {
                this.saving = false;
                if (_response != null) {
                    if (Utils.isNullOrEmpty(_response.error))
                        this.close(_response.data);
                    else
                        this.error = _response.error;
                }
            }
        );
    }
}
