import { RecordTimeStamp } from '../base.model'

export class Demographic extends RecordTimeStamp {
    protected _demographicID: number; _pid: number; _categoryTypeID: number;_valueID :number;
    protected _person: string = "";_category: string = ""; _value: string = "";
    public get DemographicID(): number {
        return this._demographicID;
    }
    public set DemographicID(value: number) {
        this._demographicID = value;
    }
    public get PID(): number {
        return this._pid;
    }
    public set PID(value: number) {
        if (this._pid != value)
            this.isDirty = true;
        this._pid = value;
    }
    public get CategoryTypeID(): number {
        return this._categoryTypeID;
    }
    public set CategoryTypeID(value: number) {
        if (this._categoryTypeID != value)
            this.isDirty = true;
        this._categoryTypeID = value;
    }
    public get ValueID(): number {
        return this._valueID;
    }
    public set ValueID(value: number) {
        if (this._valueID != value)
            this.isDirty = true;
        this._valueID = value;
    }
    public get Person(): string {
        return this._person;
    }
    public set Person(value: string) {
        this._person = value;
    }
    public get CategoryType(): string {
        return this._category;
    }
    public set CategoryType(value: string) {
        this._category = value;
    }
    public get Value(): string {
        return this._value;
    }
    public set Value(value: string) {
        this._value = value;
    }
}