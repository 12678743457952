<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <div *ngIf="existingContingencies > 0">
        <mat-form-field class="normal">
            <mat-label class="placeholder">Existing Contingencies<i class="fa fa-question-circle-o warning-color" title="Currently open contigngencies, pending fulfillment."></i></mat-label>
            <mat-select [(ngModel)]="openContingencyID">
                <mat-option *ngFor="let p of existingContingencies" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <hr />
    </div>
    <mat-form-field class="auto first" required>
        <mat-label class="placeholder">Description<i class="fa fa-question-circle-o warning-color" title="Describe the nature of contingency"></i></mat-label>
        <input matInput appDisableAutofill  type=text maxlength="200" [(ngModel)]="contigencyDescription" />
    </mat-form-field>
    <hr />
    <div class="app-flex-container app-2col">
        <mat-form-field>
            <mat-label class="placeholder">To be fulfilled By<i class="fa fa-question-circle-o warning-color" title="Select the Role which will fulfill the Contingency"></i></mat-label>
            <mat-select [(ngModel)]="selectedFulfilmentRole">
                <mat-option *ngFor="let p of toBeFulfilledBy" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="this.verificationRoles.length > 0">
            <mat-label class="placeholder">Verification Role<i class="fa fa-question-circle-o warning-color" title="Select the role, other than you who also can verify that contingency has been met."></i></mat-label>
            <mat-select [(ngModel)]="verificationRole">
                <mat-option *ngFor="let p of verificationRoles" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupFileAddon01">Fullfilment Instructions<i class="fa fa-question-circle-o warning-color" title="you can upload a document with fullfilment instructions"></i></span>
        </div>
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="inputGroupFile01"
                   aria-describedby="inputGroupFileAddon01"
                   #inst (change)="fileUploaded($event)" accept=".pdf,application/pdf">
            <label class="custom-file-label" for="inputGroupFile01">{{this.doc ? this.doc.name + ' [Change' : '[Choose'}} file]</label>
        </div>
    </div>
    <mat-checkbox [(ngModel)]="optional">Fulfilment of this contingency is Optional</mat-checkbox>
    <hr />
    <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
    <button mat-raised-button (click)="addContingency()" *ngIf="readyToSubmit">Submit</button>
</div>
