<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close"  (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-progress-bar *ngIf="this.componentBusy === true" mode="buffer"></mat-progress-bar>
    <mat-form-field class="first">
        <mat-label class="placeholder">Disburse To</mat-label>
        <mat-select (selectionChange)="roleSelected($event.value)">
            <mat-option *ngFor="let l of currentEscrow.settlementRoles" [value]="l.id" class="auto">{{l.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label class="placeholder">On Behalf of</mat-label>
        <mat-select [(ngModel)]="newDisbursement.debitTo">
            <mat-option *ngFor="let l of roles" [value]="l.id" class="auto">{{l.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label class="placeholder">Mode</mat-label>
        <mat-select [(ngModel)]="newDisbursement.type">
            <mat-option *ngFor="let l of mode" [value]="l.id"  class="auto">{{l.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field><textarea matInput appDisableAutofill maxlength=500 placeholder="Details" [(ngModel)]="newDisbursement.details"></textarea></mat-form-field>
    <mat-form-field><input matInput appDisableAutofill  maxlength=10 placeholder="Amount Available" [ngModel]="amountAvailable" [disabled]="true" type="number" class="numeric" /></mat-form-field>
    <mat-form-field>
        <input matInput appDisableAutofill  maxlength=10 placeholder="Amount" [(ngModel)]="newDisbursement.amount" type="number" class="numeric" (blur)="setSaveReady()"/>
        <mat-error *ngIf="newDisbursement.amount > amountAvailable">Invalid amount</mat-error>
    </mat-form-field>
    <mat-form-field><textarea matInput appDisableAutofill maxlength=1000 placeholder="Notes" [(ngModel)]="newDisbursement.notes"></textarea></mat-form-field>
    <br />
    <input type="submit" class="btn" value="Submit" *ngIf="saveReady && !saving" (click)="onSubmit()">
    <div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to save disbursement details</div>
</div>