<!--The content below is only a placeholder and can be replaced.-->
<div id=divDir>
    <app-navbar></app-navbar>
    <div id="appMsgDiv" class="hidden" style="color:black;padding:10px;background-color:lightgray">
        <div id="appDivError" style="padding:5px">
            <div id="appMsgDivError" style="width: Calc(100% - 48px); padding: 10px; display:inline-block"></div>
            <i class="fa fa-times-circle-o float-right;" style="margin-left: 15px; display: inline-block" (click)="closeMessage()" ></i>
        </div>
        <div id="appDivMsg" style="padding:5px">
            <div id="appMsgDivMsg" style="width: Calc(100% - 48px); padding: 10px; display: inline-block "></div>
            <i class="fa fa-times-circle-o float-right;"  style="margin-left: 15px;display:inline-block" (click)="closeMessage()"></i>
        </div>
    </div>
    <mat-drawer-container autosize class="appTop" id="appTop" [hasBackdrop]="false">
        <mat-drawer #helpDrawer class="sidenav" id="sidenav" mode="over">
            <div class="pageContentView">
                <p><i class="fa fa-times-circle-o float-right" (click)="closeHelp()" title="Close"></i></p>
                <div class="helpContent" id="pageHelpContent" hrefToRouterLink [innerHTML]="pageHelpContent | safeHtml"></div>
                <div>
                    <section>
                        <div *ngIf="(this.previousTopics && this.previousTopics.length > 0)" class="full">
                            <hr />
                            <label class="large bold">Something you have already might have completed : </label><br />
                            <span class="link help-help {{i%2 === 0 ? 'alt' : ''}}" *ngFor="let t of this.previousTopics; let i = index" (click)="gotoAssetPage(t.id)" title="Click to review - {{t.title}}">
                                {{t.title}}
                            </span>
                        </div>
                    </section>
                    <section>
                        <div *ngIf="(this.nextTopics && this.nextTopics.length > 0)" class="full">
                            <hr />
                            <label class="large bold">Things you might be interested in or need to know : </label><br />
                            <span class="link help-help {{i%2 === 0 ? 'alt' : ''}}" *ngFor="let t of this.nextTopics; let i = index" (click)="gotoAssetPage(t.id)" title="Click to review - {{t.title}}">
                                {{t.title}}
                            </span>
                        </div>
                    </section>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <main id="divRouter">
                <!--<div class="appContent">
            <h2 class="pageHeader" *ngIf="pageTitle != null && pageTitle.toString().trim() !== ''">{{pageTitle}}</h2>
        </div>-->
                <router-outlet></router-outlet>
            </main>
            <a (click)="showHelp()" id="pageContextHelp" title="View Help about this page" class="hide mobileHide">
                <br /><div style="transform:rotate(90deg);color:white">Help</div>
            </a>
            <a (click)="saveClicked()" id="pageContextSave" title="Click to save your changes (auto save is enabled)" class="hide mobileHide">
                <br /><div style="transform:rotate(-90deg);color:white">Save&nbsp;<i class="fa fa-refresh"></i></div>
            </a>
        </mat-drawer-content>
    </mat-drawer-container>
    <app-footer></app-footer>
</div>
