import { RecordTimeStamp } from '../base.model';
import { LienType } from '../lien'
import { ToBePaidBy, GeographicEntityLevel, TaxWithHoldingType, Role, ListingStatus, ListingViewingStatus } from '../enums.component'
import { Payment } from '../appCommon.models';
import { Address } from '../address.model';
import { Utils } from '../../common/utils';

export class listing extends RecordTimeStamp {
    public id: number = 0;
    public address: Address = new Address();

    public description: number = 0;
    public propertyTypeID: number = 0;
    public currentPrice: number = 0;
    public purchasePrice: number = 0;

    public creditOfferedBySeller: number = 0;
    public creditOfferedBySellerWithAgent: number = 0;

    public minimumDeposit: number = 0;
    public expiryDate: Date = Utils._minDate;

    public taxYear: number = 0;
    public taxPaidAmount: number = 0;

    public closingDate: Date = Utils._minDate;
    public currentStatus: ListingStatus = ListingStatus.None;
    public currentStatusDate: Date = Utils._minDate;

    public statusName: string = '';
    public nextSteps: any[] = [];

    public isBuyer: boolean = false;
    public isSeller: boolean = false;
    public isBuyerOrSeller: boolean = false;
    public isProspectiveBuyer: boolean = false;
    public unsolicited: boolean = false;
    public sellerHasAgent: boolean = false;
    public acceptingOffers: boolean = false;

    public buyersAgentCommission: number = 0;

    public occupied: boolean = false;
    public occupiedByTenants: boolean = false;
    public updateFacebook: boolean = false;

    public canAddAssessment: boolean = false;
    public fees: TaxFeeNegotiation[] = [];
    public assessments: any = [];
    public characteristics: any = [];
    public canEditLien: boolean = false;
    public liens: any = [];
    public media: any = [];
    public mainPhoto: string = '';

    public disclosuresPending: boolean = false;
    public canSignDisclosures: boolean = false;
    public canSendDisclosureNotice: boolean = false;
    public canCreateDisclosureServiceOrder: boolean = false;
    public canRevokeAcceptance: boolean = false;
    public canRequestNewDisclosure: boolean = false;

    public canPublish: boolean = false;
    public canSendNoticeToSeller: boolean = false;

    public disclosures: listingDisclosure[] = [];
    public agentDisclosures: any = [];
    public viewings: any = [];
    public services: any = [];
    public maxAvailablePartnership: number = 0;
    public roles: any = [];
    public myRole: Role = Role.NotSet;

    public powerOfAttornies: [];

    public editableProperties: string[] = [];

    public isEscrowOpen: boolean = false;
    public currentEscrowID: number = 0;

    public includedProperty: string = '';
    public excludedProperty: string = '';

    public offerCount: number = 0;

    getAddressString(): string { if (this.address) return this.address.FullAddress; else return ''; }
    isEditable(sName: string): boolean {
        if (this.editableProperties && this.editableProperties.length > 0 && !Utils.isNullOrEmpty(sName))
            return this.editableProperties.indexOf(sName.toLowerCase()) >= 0;
        else if (this.isSeller)
            return !Utils.isNullOrEmpty(sName);
        else
            return false;
    }
}

export class assessment extends RecordTimeStamp {
    public id: number = 0;
    public description: string = '';
    public expiryDate: Date = Utils._minDate;
    public amount: number = 0;
    public canEdit: boolean = false;
}

export class forCAR extends RecordTimeStamp {
    public Name: string = '';
    public Export: boolean = false;
    public HideIfBlank: boolean = false;
    public DataType: string = '';
    public Category: string ='' ;
    public Description: string = '';
    public MessageToPropertyName: string = '';
    public MessageToDescription: string = '';
    public EnumerableItemValuePropertyName: string = '';
    public ActionIDColumn: string = '';
    public ActionURLConfig: string = '';
}

export class StatusDisplayItem extends forCAR {
    public Value: string = '';
    public MessageTo: string = '';
    public DataTypeBool: boolean = false;
    public BoolValueFalse: boolean = false;
    public HideByDefault: boolean = false;
    public ActionURL: string = '';
    constructor(oItem: forCAR) {
        super();
        if (oItem) {
            this.Category = oItem.Category;
            this.DataType = oItem.DataType;
            this.Description = oItem.Description;
            this.EnumerableItemValuePropertyName = oItem.EnumerableItemValuePropertyName;
            this.Export = oItem.Export;
            this.MessageToDescription = oItem.MessageToDescription;
            this.MessageToPropertyName = oItem.MessageToPropertyName;
            this.Name = oItem.Name;
            this.HideIfBlank = oItem.HideIfBlank;
        }
    }
}

export class TaxWithHolderConfiguration extends RecordTimeStamp {
    public id: number = 0;
    public withholdingType: TaxWithHoldingType = TaxWithHoldingType.NotSet;
    public withholdingLevel: GeographicEntityLevel = GeographicEntityLevel.Global;
    public withholdingEntityID: number = 0;
    public withholdingEntityName: string = '';
    public collectingEntityID: number = 0;
    public withholdingEntityKeyID: number = 0;
    public informationURL: string = '';
    public taxCode: string = '';
    public description: string = '';
    public liablePerson: ToBePaidBy = ToBePaidBy.NotSet;
    public forPrimaryResidence: boolean = false;
    public negotiable: boolean = false;
    public feeApplicable: number = 0;
    constructor() {
        super();
    }
}

export class TaxFeeNegotiation extends RecordTimeStamp{
    public id: number = 0;
    public offerID: number = 0 ;
    public paidBy: ToBePaidBy = ToBePaidBy.NotSet;
    public amount: number = 0;
    public estimated: boolean = false;
    public configuration: TaxWithHolderConfiguration = new TaxWithHolderConfiguration();
    constructor() {
        super();
    }
}

export class newListing extends RecordTimeStamp{
    public propertyProductID: number = 0;

    public companyID: number = 0;
    public isAgent: boolean = false;
    public isExclusive: boolean = false;
    public expiryDate: Date = Utils._minDate;

    public tncVersionID: number = 0;
    public tncAgreed: boolean = false;
    public renewListingID: number = 0;
    public listingPrice: number = 0;
    public commission: number = 0;
    public payment: Payment = new Payment();
    public address: Address = new Address();
    public sellingPrice: number = 0; 
    public role: Role = Role.Seller;
    constructor() {
        super();
    }
}

export class listingViewing extends RecordTimeStamp {
    public id: number = 0;
    public currentStatus: ListingViewingStatus = ListingViewingStatus.None;
    public statusName: string = '';
    public canCancel: boolean = false;
    public canConfirm: boolean = false;
    public canDecline: boolean = false;
    public canUpdateOpenHouse: boolean = false;
    public requestedTime: Date = Utils._minDate;
    public confirmedTime: Date = Utils._minDate;
    public endTime: Date = Utils._minDate;
    public isOpenHouse: boolean = false;
    public requestorPID: number = 0;
    public requestor: string = '';
    public requestorAddress: string = '';
    public nextSteps: [] = [];
    public dateString: string = '';
    constructor() {
        super();
        this.nextSteps = [];
    }
}

export class ListingSearchRequest {
    public lat: number;
    public lng: number;
    public distance: number;
    public rooms: number = 0;
    public baths: number = 0;
    public minPrice: number;
    public maxPrice: number;
    public size: number;
    public zip: number;
    public propertyTypeIDs: number[] = new Array<number>();
}

export class ListingSearchResponse extends RecordTimeStamp {
    public id: number = 0;
    public description: string = '';
    public lng: number = 0;
    public lat: number = 0;
    public address: string = '';
    public addressClean: string = ''; // For Search Operations only
    public streetAddress: string = '' ;
    public price: number = 0;
    public creditOffered: number = 0;
    public offerCount: number = 0;
    public rooms: number = 0;
    public baths: number = 0;
    public mapIcon: string = '';
    public image: string = '';
    public distance: number = 0;
    public dom: number = 0;
    public sold: boolean = false;
    public expired: boolean = false;
    public canSubmitOffer: boolean = false;
    public isSeller: boolean = false;
    public sellers: string = '';
    public hasLockBox: boolean = false;
    public brokerName: string = '';
    public buyersAgentCommission: number = 0;
    public sellerPIDs: number[] = new Array<number>();
    public bookmarked: boolean = false;
    public priceChangeDays: number = 0;
    public propertyTypeID: number = 0;
    public rank: number = 0;
    public media: any[] = [];
    constructor() {
        super();
    }

    public get hasSellers(): boolean { return this.sellerPIDs && this.sellerPIDs.length > 0; }
}

export enum ListingSearchSort {
    'Price - Low To High' = 1, 'Price - High to Low' = 2
    , 'Days on Market - Low to High' = 3, 'Days on Market - High to Low' = 4
    , 'Price Changed Days - Low to High' = 5, 'Price Changed Days - High to Low' = 6
    , 'Distance - Low to High' = 7, 'Distance - High to Low' = 8
}

export enum DisclosureDataType { String = 1, Integer = 2, DateTime = 3, Boolean = 4, Double = 5, Nothing = 0, ControllerEntity = 6 }

export class listingDisclosure extends RecordTimeStamp {
    public id: number = 0;
    public typeID: number = 0;
    public dataType: DisclosureDataType = DisclosureDataType.Nothing;
    public fromProspectiveBuyer: boolean = false;
    public disclosure: string = '';
    public helpText: string = '';
    public serviceProvider: string = '';
    public pending: boolean = true;
    public canUpdate: boolean = false;
    public required: boolean = false;
    public requiredForListing: boolean = false;
    public responseRequired: false;
    public type: string = '';
    public stringValue: string;
    public integerValue: number;
    public moneyValue: number;
    public booleanValue: boolean;
    public dateValue: Date;
    public readonlyValue: string = '';
    public availableOptions: any = [];
    public selectedOptions: any = [];
    public selectedOption: number = 0;
    public multipleOptions: boolean = false;
    public documents: any = [];

    public castToMe(oObject: any): any {
        super.castToMe(oObject);

        if (oObject != null) {

            if (oObject['stringValue'])
                this['stringValue'] = oObject['stringValue'];
            else
                this['IsDirty'] = null;

            if (oObject['integerValue'])
                this['integerValue'] = oObject['integerValue'];
            else
                this['integerValue'] = null;

            if (oObject['moneyValue'])
                this['moneyValue'] = oObject['moneyValue'];
            else
                this['moneyValue'] = null;

            if (oObject['booleanValue'])
                this['booleanValue'] = oObject['booleanValue'];
            else
                this['booleanValue'] = null;

            if (oObject['dateValue'])
                this['dateValue'] = oObject['dateValue'];
            else
                this['dateValue'] = null;

        }
        return this;
    }

}
