<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card *ngIf="rateItem.productID > 0">
        <mat-form-field>
            <mat-label class="placeholder">Charge Item Description<i class="fa fa-question-circle-o warning-color" title="Enter Item description which is part of pricing structure."></i></mat-label>
            <textarea matInput appDisableAutofill row="10" cols="50" class="small" [(ngModel)]="rateItem.name"></textarea>
        </mat-form-field>
        <div class="app-flex-container app-2col">
            <mat-form-field style="display:initial">
                <mat-label class="placeholder">Fixed Price <i class="fa fa-question-circle-o warning-color" title="Enter the value here if the Item price is fixed"></i></mat-label>
                <input matInput appDisableAutofill type="number" [(ngModel)]="rateItem.fixedPrice" (change)="onFixedPriceChanged()" (keydown)="utils.numOnlyLimitingLength($event,10)" class="numeric" />
            </mat-form-field>
            <mat-form-field style="display:initial">
                <mat-label class="placeholder">Percentage price <i class="fa fa-question-circle-o warning-color" title="Enter the value here if the Item price is charged in percentage"></i></mat-label>
                <input matInput appDisableAutofill type="number" [(ngModel)]="rateItem.percentagePrice" (change)="onPercentagePriceChanged()" (keydown)="utils.numOnlyLimitingLength($event,6)" class="numeric" />
            </mat-form-field>
        </div>
    </mat-card>
    <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
    <mat-card *ngIf="isValid && !saving" class="section">
        <button mat-raised-button (click)="onSubmit()">Save</button>
    </mat-card>
    <mat-card *ngIf="rateItem.productID == 0">
        Invalid or Missing Product and/or PropertyType ID.
    </mat-card>
    <div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to add/update rate schedule.</div>
</div>
