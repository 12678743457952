
import { NgModule, APP_INITIALIZER} from '@angular/core';
import { RouterModule, UrlSerializer, DefaultUrlSerializer, UrlTree } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { FlexLayoutModule } from 'ng-flex-layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OverlayModule } from '@angular/cdk/overlay';

import { EmptyComponent } from './emptyComponent';
import { AppComponent } from './app.component';

import { GlobalComponent } from './global.component';
import { AppCommonModule } from './common/appCommon.module';
import { HelpModule } from './help/help.module';

import { LoginComponent } from './account/login.component';
import { MaterialModule } from './common/shared/material.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { approutes } from '../app.routing';

import { NavBarComponent } from '../app/navbar.component';
import { HomeComponent, HomeCarouselContentComponent } from '../app/home.component';
import { ContactUsContentComponent, AboutContentComponent } from '../app/contactus.component';
import { SecurityContentComponent } from './security.component';

import { PrivacyPolicyContentComponent, TermsOfUseContentComponent } from '../app/privacyPolicy.component';

import { FooterComponent } from '../app/footer.component';
import { AuthGuard, CheckDirtyGuard, SystemGuard } from '../app/security/AuthGuard';
import { SignInComponent } from '../app/security/signin.component';
import { UnsubscribeComponent } from '../app/security/unsubscribe.component';
import { SignUpComponent } from '../app/security/signup.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';

import { CustomHttpInterceptorService } from './common/service/customHttpInterceptor.service';

import { SessionExpirationAlert, SessionInterruptService } from 'session-expiration-alert';
import { AppSessionInteruptService } from './app-session-interupt.service';
import { StaticAppComponent } from './static.app.content.component';

import { appEnvironment } from '../environments/environment';

const _sessionLimit: number = appEnvironment.sessionTimeout;

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        return super.parse(url.toLowerCase());
    }
}

// Configs
// export function getAuthServiceConfigs() {
//    let config = new AuthServiceConfig(
//        [
//            {
//                id: FacebookLoginProvider.PROVIDER_ID,
//                provider: new FacebookLoginProvider("Your-Facebook-app-id")
//            },
//            {
//                id: GoogleLoginProvider.PROVIDER_ID,
//                provider: new GoogleLoginProvider("Your-Google-Client-Id")
//            },
//        ]
//    );
//    return config;
// }

import { importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { EscrowService } from './escrow/escrow.service';
import { NgxGalleryModule } from '@murbanczyk-fp/ngx-gallery';
import { ListingMediaComponent, ListingAssessmentComponent, ListingLiensComponent, ListingPersonalPropertyComponent, ListingCharacteristicsComponent } from './listing/edit';
import { ListingEditComponent } from './listing/listingEdit.component';
import { ListingOfferCompareComponent, ListingClosingStatusComponent, ListingRoleComponent, ListingTaxesAndFeesComponent, ListingOffersComponent, ListingViewingComponent } from './listing';

@NgModule({
    declarations: [
        AppComponent, EmptyComponent,
        NavBarComponent, HomeComponent, HomeCarouselContentComponent, FooterComponent
        , ListingMediaComponent, ListingEditComponent, ListingOfferCompareComponent
        , ListingAssessmentComponent, ListingClosingStatusComponent, ListingLiensComponent, ListingRoleComponent, ListingTaxesAndFeesComponent
        , ListingCharacteristicsComponent, ListingPersonalPropertyComponent, ListingOffersComponent
        , ListingViewingComponent
        , SignInComponent, SignUpComponent, LoginComponent, StaticAppComponent, UnsubscribeComponent, SecurityContentComponent,
        ContactUsContentComponent, AboutContentComponent, PrivacyPolicyContentComponent, TermsOfUseContentComponent
    ],
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, BrowserModule, BrowserAnimationsModule
        , RouterModule.forRoot(approutes,
            {
                scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', scrollOffset: [0, 64]
            })
        , SessionExpirationAlert.forRoot({ totalMinutes: _sessionLimit })
        , AppCommonModule, MaterialModule, HelpModule, NgxDatatableModule
        , OverlayModule, NgxGalleryModule
        , NgxPaginationModule, FlexLayoutModule
    ],
    exports: [SignInComponent],
    providers: [AuthGuard, CheckDirtyGuard, SystemGuard, GlobalComponent
        // ,{
        //  provide: AuthServiceConfig,
        //  useFactory: getAuthServiceConfigs
        // }
        ,
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
        {
            provide: SessionInterruptService,
            useClass: AppSessionInteruptService
        },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        }, provideHttpClient()
        , EscrowService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
