import { Component, Inject, OnInit, Input, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { AngularEditorComponent } from '../../htmlEditor/angular-editor.component';
import { AngularEditorConfig, CustomClass } from '../../htmlEditor/config';

@Component({
    selector: 'app-edit-html-dialog',
    template: `<div mat-dialog-title>
    <a class='close' *ngIf=canEdit (click)=save() style='top:50px;position:fixed;right:50px' title='{{saveLabel}}'><i class='fa fa-save fa-2x'></i></a>
    <a class='close float-right' (click)='close()' style='top:50px;position:fixed;right:10px'><i class='fa fa-times-circle fa-2x warning'></i></a>
</div>
<angular-editor #contentEditor [(ngModel)]='content' (config)='editorConfig'></angular-editor>
`
})
export class EditHTMLDialog implements OnInit {

    @ViewChild('contentEditor') contentEditor: AngularEditorComponent;

    @Input() canEdit: boolean = false;
    @Input() saveLabel = 'Save';
    content: string = '';
    editorConfig: AngularEditorConfig = {
        editable: this.canEdit,
        spellcheck: true,
        height: '25rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        sanitize: false,
        uploadUrl: 'v1/images',
        fonts: [
            { name: 'Arial', class: 'arial' }
            , { name: 'Calibri', class: 'calibri' }
            , { name: 'Comic Sans MS', class: 'comic-sans-ms' }
            , { name: 'Times New Roman', class: 'times-new-roman' }
        ]
    };

    constructor(public dialogRef: MatDialogRef<EditHTMLDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (this.data) {
            if (this.data.html) this.content = this.data.html;
            if (this.data.canEdit) this.canEdit = this.data.canEdit;
        }
    }

    save() {
        this.dialogRef.close(this.contentEditor.html);
    }

    close() { this.dialogRef.close(); }

}
