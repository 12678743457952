import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    FormControl,
    ValidatorFn,
    Validator,
    AbstractControl
} from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { isUndefined } from 'util';
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[emailvalidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: EmailValidator,
            multi: true
        }
    ]
})
export class EmailValidator implements Validator {
    validator: ValidatorFn;
    emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    private _required: boolean = true;
    constructor() {
        this.validator = this.emailValidator();
    }

    @Input() get required(): boolean { return this._required; }
    set required(value: boolean) {
        this._required = value != null && `${value}` !== 'false';
    }

    validate(control: AbstractControl) {
        return this.validator(control);
    }

    emailValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            let isValid = true;

            if (this._required)
                isValid = (control.value != null && control.value.toString().trim() !== '' && control.value !== 0);

            if (control.value && isValid)
                isValid = this.emailPattern.test(control.value.toString().trim());

            if (isValid) {
                return null;
            } else {
                return {
                    emailvalidator: {
                        valid: false
                    }
                };
            }
        };
    }
}