<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <div class="app-flex-container app-2col">
        <app-datepicker #txtVisitDate id="txtVisitDate" [value]="visitDate" [placeholder]="'Visit Date'" [minDate]="today" [maxDate]="maxDate" 
                        (valueChanged)="visitDateChanged($event)" [cssClass]="'first'" [disabled]="readOnly"></app-datepicker>
        <div>
            <mat-form-field class="first medium">
                <input matInput appDisableAutofill placeholder="Time of Visit" readonly [(ngModel)]="visitTime" class="noborder"
                       (ngModelChange)="error = null" [disabled]="readOnly">
            </mat-form-field>
            <!--<ngx-material-timepicker-toggle></ngx-material-timepicker-toggle>
            <ngx-material-timepicker #with5Gap [minutesGap]="15" (max)="'06:00 pm'" (min)="'07:00 am'" (value)="visitTime"></ngx-material-timepicker>-->
        </div>
    </div>
    <mat-form-field *ngIf="!forOpenHouse">
        <mat-select [(ngModel)]="duration" placeholder="Duration of visit" (ngModelChange)="error = null" [disabled]="readOnly">
            <mat-option value=15>15 Minutes</mat-option>
            <mat-option value=30>30 Minutes</mat-option>
            <mat-option value=60>One Hour</mat-option>
            <mat-option value=120>2 Hour</mat-option>
            <mat-option value=180>3 Hour</mat-option>
            <mat-option value=240>4 Hour</mat-option>
            <mat-option value=480>Entire Day</mat-option>
        </mat-select>
    </mat-form-field>
    <hr />
    <mat-progress-bar mode="buffer" *ngIf="busy"></mat-progress-bar>
    <button mat-raised-button (click)="addVisit()" *ngIf="readyForSubmit && !busy">Submit</button>
    <div class="alert alert-danger section" *ngIf="(error ?? '') !== ''">Failed to add visiting details. {{error}}</div>
    <br/><br />
</div>