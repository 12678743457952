import { Component, Inject } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BaseDialog } from '../../base.dialog';
import { StarRatingComponent } from '@khajegan/ng-starrating';
import { appEnvironment } from '../../../environments/environment';
import { ServiceProviderService } from '../serviceprovider.service';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-reviews-dialog',
    templateUrl: './reviews.dialog.html'
})
export class AddReviewsDialog extends BaseDialog {

    reviews: any = [];

    allowToAddReview: boolean;
    allowToResponsd: boolean;

    dialogHeader: string = 'Reviews';
    workOrderID: number = 0;
    serviceProviderID: number = 0;
    canPostReview: boolean = false;
    canRevokeReview: boolean = false;
    canPostRebuttal: boolean  = false;

    rating: number = 0;
    comments: string = '';
    rebuttal: string = '';

    reviewPageNumber: number = 1;

    constructor(ds: DataService, r: Router, dialogRef: MatDialogRef<AddReviewsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('dlgReviews', ds, r, dialogRef, data);

        if (this.data) {
            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Reviews for' + this.data.header;
            }

            if (data.workOrderID) this.workOrderID = data.workOrderID;
            if (data.serviceProviderID) this.serviceProviderID = data.serviceProviderID;
            if (data.canPostReview) this.canPostReview = data.canPostReview;
            if (data.canRevokeReview) this.canRevokeReview = data.canRevokeReview;
            if (data.canPostRebuttal) this.canPostRebuttal = data.canPostRebuttal;
        }

        if (this.serviceProviderID === 0 && this.workOrderID === 0) {
            setTimeout(() => { this.close(null); }, appEnvironment.delayedNGInit);
        } else {
            this.bindReviews();
        }
    }

    onRate($event) {
        this.rating = $event.newValue;
    }

    submitReview() {
        let _response;
        new ServiceProviderService(this.dataservice, this.route, this.myResourceCategory).submitReview(this.workOrderID, this.rating, this.comments).subscribe(
            (data) => { _response = data; }
            , (error) => { this.componentBusy = false; }
            , () => {
                this.componentBusy = false;
                if (_response.error)
                    this.error = _response.error;
                else if (_response.data) {
                    this.reviews.push(_response.data);
                    this.canPostReview = false;
                }
            });
    }

    revokeReview(oReview: any) {
        let _response;
        new ServiceProviderService(this.dataservice, this.route, this.myResourceCategory).revokeReview(oReview.id).subscribe(
            (data) => { _response = data; }
            , (error) => { this.componentBusy = false; }
            , () => {
                this.componentBusy = false;
                if (_response.error)
                    this.error = _response.error;
                else
                    oReview.canRevokeReview = false;
            });
    }

    submitRebuttal(oReview: any) {
        let _response;
        new ServiceProviderService(this.dataservice, this.route, this.myResourceCategory).submitRebuttal(oReview.id, oReview.rebuttal).subscribe(
            (data) => { _response = data; }
            , (error) => { this.componentBusy = false; }
            , () => {
                this.componentBusy = false;
                if (_response.error)
                    this.error = _response.error;
                else {
                    oReview.canPostRebuttal = false;
                }
            });
    }

    private bindReviews() {
        this.componentBusy = true;
        this.reviews = [];
        let _response;
        new ServiceProviderService(this.dataservice, this.route, this.myResourceCategory).getReviews(this.serviceProviderID, this.workOrderID).subscribe(
            (data) => { _response = data; }
            , (error) => { this.componentBusy = false; }
            , () => {
                this.componentBusy = false;
                if (_response && _response.data) {
                    this.reviews = _response.data;
                }

                if (this.serviceProviderID === 0 && this.workOrderID === 0 && (this.reviews == null || this.reviews.length === 0)) {
                    setTimeout(() => { this.close(null); }, appEnvironment.delayedNGInit);
                }
        });
    }
}
