import { NgModule, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { AppCommonModule } from '../common/appCommon.module';

import { MaterialModule } from '../common/shared/material.module';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { helpRouting } from './help.routing';
import { AngularEditorModule } from '../htmlEditor/angular-editor.module';

import { HelpComponent } from './help.component';
import { GlossaryComponent } from './glossary.component';
import { SearchHelpComponent } from './searchHelp.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { TagLoadService } from '../util/service/tagLoad';
import { GlobalComponent } from '../global.component';
import { DndModule } from 'ngx-drag-drop';

@NgModule({
    imports: [RouterModule, CommonModule, FormsModule, ReactiveFormsModule, MaterialModule
        , AppCommonModule, AngularEditorModule
        , NgxPaginationModule, DndModule
        , helpRouting
    ],
    declarations: [HelpComponent, GlossaryComponent, SearchHelpComponent],
    exports: [HelpComponent]
    , providers: [
        {
            provide: TagLoadService,
            deps: [GlobalComponent, HttpClient]
        }
    ]
})
export class HelpModule {
    constructor(service: TagLoadService, @Inject(GlobalComponent) gc: GlobalComponent, httpClient: HttpClient) {
        service.init('Help', gc, httpClient);
    }
}

export function getHelpModule() {
    return HelpModule;
}
