<div mat-dialog-title>
    <h4>{{header}}</h4>
    <a class="close" title="Close" (click)="close()"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-form-field *ngIf="messageID == null || messageID == 0" class="first">
        <input matInput appDisableAutofill maxlength=100 placeholder="Subject" [(ngModel)]="subject" />
    </mat-form-field>
    <mat-form-field class="first"><textarea matInput appDisableAutofill placeholder="Message" row="10" cols="50" class="small" [(ngModel)]="message" maxlength="5000"></textarea></mat-form-field>
    <mat-checkbox [(ngModel)]="replyToAll" *ngIf="messageID > 0">Copy to everyone in this conversation</mat-checkbox>
    <br />
    <input type="submit" class="btn" value="Send" *ngIf="(message ?? '') != ''" (click)="onSubmit()">
    <div class="alert alert-danger" *ngIf="(error ?? '') !== ''">Failed Sending your message.</div>
</div>
