import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        if (value && typeof value === 'string')
            return this.sanitized.bypassSecurityTrustHtml(value);
        else
            return '';
    }
}

@Pipe({ name: 'safeStyle' })
export class SafeStylePipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        if (value && typeof value === 'string')
            return this.sanitized.bypassSecurityTrustStyle(value);
        else
            return '';
    }
}
