import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, UntypedFormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { DataService } from '../../../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { downloadableContent, listing, mediaItem } from '../../../models';
import { ListingService } from '../../../listing/listing.service';

import { FileUploader } from 'ng2-file-upload';
import { BaseDialog } from '../../../base.dialog';
import { Utils } from '../../utils';

const URL = '/api/';

@Component({
    selector: 'app-new-disclosure-request-dialog',
    templateUrl: './newDisclosureRequest.dialog.html'
})
export class NewDisclosureDialog extends BaseDialog {
    form: UntypedFormGroup;
    entityID: number;
    dialogHeader: string = 'Request Disclosure';
    saving: boolean = false;

    request: string = '';
    response: string = '';
    doc: downloadableContent = new downloadableContent();

    currentListing: listing = new listing();
    error: string = '';
    @ViewChild('newDisc') newDisc: any;
    fileExtension: string = 'pdf';

    saveReady: boolean = false;

    media: mediaItem = new mediaItem();
    public uploader: FileUploader = new FileUploader({ url: URL });
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;

    constructor(ds: DataService, r: Router, public dialogRef: MatDialogRef<NewDisclosureDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('newDisclosureRequestDialog', ds, r, dialogRef, data);

        if (this.data) {
            if (this.data.header) this.dialogHeader = this.data.header;
            if (this.data.currentListing) this.currentListing = this.data.currentListing;
        }

        if (this.currentListing == null || this.currentListing.id === 0) {
            setTimeout(() => { this.close(null); }, 1000);
        }
    }

    checkReadyForSave() {
        this.saveReady = !Utils.isNullOrEmpty(this.request) && (this.currentListing.isSeller ? !Utils.isNullOrEmpty(this.response) : true)
            && this.doc && !Utils.isNullOrEmpty(this.doc.content) ;
    }

    close(data) {
        this.dialogRef.close(data);
    }

    onSubmit() {
        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).addDisclosure(this.currentListing.id, this.request, this.response, this.doc).subscribe(
            (data) => _response = data
            , (error) => {
                this.close(null);
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.close(_response.data);
                        this.saving = false;
                    }
            }
        );
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    fileUploaded($event): void {
        this.fileHandler($event);
        this.newDisc.nativeElement.value = '';
    }

    fileHandler($event): void {
        const _file = $event.srcElement.files;

        if (_file) {
            const _fName = _file[0].name;
            if (_fName.lastIndexOf('.') > 0) {
                const _fExtension = _fName.substring(_fName.lastIndexOf('.') + 1);
                if (this.fileExtension.indexOf(_fExtension) < 0) {
                    this.showMessage('Invalid file uploaded');
                    return;
                }
                this.doc = new downloadableContent();
                this.doc.name = _fName;
                if (_file[0].type)
                    this.doc.mime = _file[0].type;
            } else {
                this.showMessage('Invalid file uploaded');
                return;
            }
        }

        const input = $event.target;
        const reader = new FileReader();
        reader.readAsDataURL(input.files[0]);

        reader.onloadend = (data) => {
            this.doc.content = reader.result;
        };
        reader.onerror = () => {
            alert('Unable to read ' + input.files[0]);
            return;
        };

        this.checkReadyForSave();
    }
}
