<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close"  (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
        If you have been given Power of Attroney by someone to take actions on their behalf, Click on Assume.
        If you want to give someone else Power of Attorney who will take actions on your behalf, Click on Give.
        <br/><hr/><br/>Please upload Power of Attorney. This will be verified by Escrow personnel.
    </mat-card>
    <mat-card>
        <mat-form-field>
            <mat-label class="placeholder">Partner(s)<i class="fa fa-question-circle-o warning-color" title="Partners in current transaction, for Power of attroney"></i></mat-label>
            <mat-select [(ngModel)]="pidWithPOA">
                <mat-option *ngFor="let p of partners" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroupFileAddon01">Upload</span>
            </div>
            <div class="custom-file">
                <input type="file" class="custom-file-input" id="inputGroupFile01"
                       aria-describedby="inputGroupFileAddon01"
                       #poaDOC (change)="fileUploaded($event)" accept=".pdf,application/pdf">
                <label class="custom-file-label" for="inputGroupFile01">{{this.doc ? this.doc.name + ' [Change' : '[Choose'}} file]</label>
            </div>
        </div>
        <mat-card-footer>
            <hr />
            <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
            <button mat-raised-button (click)="assumePOA()" *ngIf="!saving && saveReady">Assume</button>
            <button mat-raised-button (click)="givePOA()" *ngIf="!saving && saveReady" class="float-right">Give</button>
        </mat-card-footer>
    </mat-card>
</div>

