import { Injectable, Inject } from '@angular/core';
import { SessionInterruptService } from 'session-expiration-alert';

import { DataService } from './util/APICaller.component';
import { Router } from '@angular/router';
import { GlobalComponent } from './global.component';
import { PersonService } from './account/person.service';

@Injectable({ providedIn: 'root' })
export class AppSessionInteruptService extends SessionInterruptService {
    constructor(private ds: DataService, private r: Router, @Inject(GlobalComponent) private g: GlobalComponent) {
        super();
    }
    continueSession() {
        // NOTE : Tell Server I am ALIVE.
        this.ds.APIPost('/continueSession', null, '').subscribe(() => { });
    }
    stopSession() {
        (new PersonService(this.ds, this.r, '')).logout().subscribe(() => { });
        this.g.loginChanged.emit(null);
        this.g.clear();
        this.r.navigate(['/']);
    }
}
