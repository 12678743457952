import { Component, ViewChild, Output, EventEmitter, Inject} from '@angular/core';
import { Router } from '@angular/router';

import { FileUploader } from 'ng2-file-upload';
import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { item, downloadableContent, mediaItem } from '../../models/appCommon.models';

import { ListingService } from '../listing.service';
import { Utils } from '../../common/utils';

const URL = '/api/';

@Component({
    selector: 'app-addmedia',
    templateUrl: './addmedia.component.html',
    styleUrls: ['./addMedia.component.css']
})
export class AddMediaComponent extends BaseComponent {

    @Output() mediaChanged: EventEmitter<any> = new EventEmitter<any>();
    listingID: number = 0;
    media: mediaItem = new mediaItem();

    //#region FileUpload Variables
    public uploader: FileUploader = new FileUploader({ url: URL });
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;
    @ViewChild('mediaFile') mediaFile: any;
    //#endregion

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('listingMedia', r, g);

        this.media.type = 'url';
    }

    //#region FILE UPLOAD
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    fileUploaded($event): void {
        this.fileHandler($event);
    }

    fileHandler($event): void {
        const _file = $event.srcElement.files;

        if (_file) {
            const _fName = _file[0].name;
            if (!this.isValidMediaExtension(_fName)) {
                this.showMessage('Invalid file selected');
                this.mediaFile.nativeElement.value = '';
                return;
            }
            this.media.content.name = _fName;
            if (_file[0].type)
                this.media.content.mime = _file[0].type;
        }

        const input = $event.target;
        const reader = new FileReader();
        reader.readAsDataURL(input.files[0]);

        reader.onloadend = (data) => {
            this.media.content.content = reader.result;
        };

        reader.onerror = () => {
            alert('Unable to read ' + input.files[0]);
        };
    }

    //#endregion

    saveMedia() {
        if (Utils.isNullOrEmpty(this.media.url) && this.media.content.content == null) return;

        if (this.media.type === 'url') {
            if (Utils.isNullOrEmpty(this.media.url) || !this.isValidMediaExtension(this.media.url)) {
                this.showError('Invalid media location.');
                return;
            }
        } else {
            if (this.media.content == null || Utils.isNullOrEmpty(this.media.content.name) || this.media.content.content == null) {
                this.showError('Invalid media location.');
                return;
            }
        }

        this.componentBusy = true;
        this.media['id'] = this.listingID;
        let _response;
        (new ListingService(this.dataservice, this.r, this.myResourceCategory)).addMedia(this.media).subscribe(
            (data) => _response = data
            , (error) => {
                this.componentBusy = false;
                this.onApiError(error);
            }
            , () => {
                this.componentBusy = false;
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.mediaFile.nativeElement.value = '';
                        this.media = new mediaItem();
                        this.mediaChanged.emit(_response.data);
                    }
            }
        );
    }

}
