import { Component, OnInit } from '@angular/core';
import { BaseResponse } from './models';
import { LegalService } from './common/service';
import { DataService } from './util/APICaller.component';
@Component({
    selector: 'app-privacy-policy',
    template: '<div class=appContent><div class="legal" hrefToRouterLink [innerHTML]="html | safeHtml"></div></div>'
})
export class PrivacyPolicyContentComponent implements OnInit {

    html: string = '';
    constructor(private ds: DataService) { }

    ngOnInit() {
        let _response: BaseResponse;
        (new LegalService(this.ds, null, '')).getPrivacyPolicy().subscribe(
            (data) => _response = data,
            (error) => { },
            () => {
                if (_response && _response.error === '') {
                    this.html = _response.data.content;
                }
            }
        );
    }
}

@Component({
    selector: 'app-terms-of-use',
    template: '<div class=appContent><div class="legal" hrefToRouterLink [innerHTML]="html | safeHtml"></div></div>'
})
export class TermsOfUseContentComponent implements OnInit {

    html: string;
    constructor(private ds: DataService) { }

    ngOnInit() {
        let _response: BaseResponse;
        (new LegalService(this.ds, null, '')).getTermsOfUse().subscribe(
            (data) => _response = data,
            (error) => { },
            () => {
                if (_response && _response.error === '') {
                    this.html = _response.data.content;
                }
            }
        );
    }
}
