<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
	<mat-card *ngIf="rate.productID > 0 && rate.propertyTypeID > 0">
		<ul class="bulleted" *ngIf=" !isLoanProduct">
			<li><b>Fixed Price</b> - Use this when you have fixed rate for this service and it does not vary based on selling price of a property.</li>
			<li><b>Percentage</b> - Use this when service cost is based on the selling price of a property, listed on purchase agreement.</li>
			<li><b>None</b> - Use this when you want to provide price upon request only and submit a subsequent Quote.</li>
		</ul>
		<mat-card *ngIf="rate != null && (rate.id ==null || rate.id <=0 )">
			<app-datepicker #txtStartDate id="txtStartDate" [placeholder]="'New Rate Start Date'"></app-datepicker>
		</mat-card>
		<mat-card>
			<div class="app-flex-container app-2col">
				<mat-form-field *ngIf=" !isLoanProduct">
					<mat-label class="placeholder">Fixed Price <i class="fa fa-question-circle-o warning-color" title="Enter the value here if the price is fixed for the property sale."></i></mat-label>
					<input matInput appDisableAutofill type="number" [(ngModel)]="rate.fixedPrice" (change)="fixedPriceChanged()" (keydown)="utils.numOnlyLimitingLength($event,10)" class="numeric" />
				</mat-form-field>
				<mat-form-field>
					<mat-label class="placeholder">Percentage price <i class="fa fa-question-circle-o warning-color" title="Enter the value here if the price is charged in percentage of the property sale value."></i></mat-label>
					<input matInput appDisableAutofill type="number" [(ngModel)]="rate.percentagePrice" (change)="percentagePriceChanged()" (keydown)="utils.numOnlyLimitingLength($event,6)" class="numeric" />
				</mat-form-field>
			</div>
			<div class="app-flex-container app-2col"  *ngIf="rate.fixedPrice == null || rate.fixedPrice == 0">
				<mat-form-field>
					<mat-label class="placeholder">Minimum Charge <i class="fa fa-question-circle-o warning-color" title="Enter the value here if  there is minimum charge to be paid by person placing the order."></i></mat-label>
					<input matInput appDisableAutofill type="number" [(ngModel)]="rate.minPrice" (keydown)="utils.numOnlyLimitingLength($event,10)" class="numeric" />
				</mat-form-field>
				<mat-form-field>
					<mat-label class="placeholder">Maximum Charge <i class="fa fa-question-circle-o warning-color" title="Enter the value here if  there is maximum charge to be paid by person placing the order."></i></mat-label>
					<input matInput appDisableAutofill type="number" [(ngModel)]="rate.maxPrice" (keydown)="utils.numOnlyLimitingLength($event,10)" class="numeric" />
				</mat-form-field>
			</div>
			<div *ngIf="isLoanProduct">
				<mat-form-field>
					<mat-label class="placeholder">Down Payment %<i class="fa fa-question-circle-o warning-color" title="Enter the value here if  there is Down Payment required by the Loan Applicant."></i></mat-label>
					<input matInput appDisableAutofill type="number" [(ngModel)]="rate.downPayment" (keydown)="utils.numOnlyLimitingLength($event,6)" class="numeric" />
				</mat-form-field>
				<mat-checkbox disabled [(ngModel)]="rate.adjustable" (change)="onAdjustableChange($event)">Adjustable <i class="fa fa-question-circle-o warning-color" title="Enter the value here if mortgage rate is adjustable."></i></mat-checkbox>
				<mat-form-field class="first">
					<mat-label class="placeholder">Pre-Payment Penalty <i class="fa fa-question-circle-o warning-color" title="Enter the value here if  there is pre-payment penalty applicable duration."></i></mat-label>
					<input matInput appDisableAutofill type="number" [(ngModel)]="rate.prePaymentPenaltyDuration" (keydown)="utils.numOnlyLimitingLength($event,10)" class="numeric" />
				</mat-form-field>
				<div class="app-flex-container app-2col">
					<mat-form-field>
						<mat-label class="placeholder">Fee Point(s) <i class="fa fa-question-circle-o warning-color" title="Enter the value here if  there is fee points charged part of loan processing."></i></mat-label>
						<input matInput appDisableAutofill type="number" [(ngModel)]="rate.points" (keydown)="utils.numOnlyLimitingLength($event,6)" class="numeric" />
					</mat-form-field>
					<mat-form-field *ngIf="rate.adjustable">
						<mat-label class="placeholder">Rate Cap. <i class="fa fa-question-circle-o warning-color" title="Enter the value here if  there is Rate Cap i.e. max rate for the Loan Term."></i></mat-label>
						<input matInput appDisableAutofill type="number" [(ngModel)]="rate.rateCap" (keydown)="utils.numOnlyLimitingLength($event,6)" class="numeric" />
					</mat-form-field>
				</div>
				<div class="app-flex-container app-2col" *ngIf="this.rate.amortizationDuration > 0">
					<div *ngIf="rate.adjustable">
						<label class="caption" title="Rate is fixed during this period (in months)">Fixed Duration</label>
						<label class="data-value  auto">{{this.rate.fixedDuration}} (in months)</label>
					</div>
					<div>
						<label class="caption" title="Amortization Duration. Loan is paid off during this period (in months)">Amort. Duration</label>
						<label class="data-value  auto">{{this.rate.amortizationDuration}} (in months)</label>
					</div>
				</div>
			</div>
		</mat-card>
		<mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
		<mat-card *ngIf="isValid && !saving">
			<button mat-raised-button (click)="onSubmit()">Save</button>
		</mat-card>
		<div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to add new rate. {{error}}</div>
	</mat-card>
    <mat-card *ngIf="(rate.productID == 0 || rate.propertyTypeID == 0) && rate.id == 0">
        Invalid or Missing Product and/or PropertyType ID.
    </mat-card>
</div>
