<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="lienType = $event.value">
            <mat-button-toggle [value]="LienType.Loan">Loan</mat-button-toggle>
            <mat-button-toggle [value]="LienType.Tax">Tax Lien</mat-button-toggle>
            <mat-button-toggle [value]="LienType.Judgement">Judgement Lien</mat-button-toggle>
        </mat-button-toggle-group>
    </mat-card>
    <mat-card>
        <mat-form-field *ngIf="lienType && lienType != LienType.Loan">
            <mat-select [(ngModel)]="newLien.entityLevel" placeholder="Lien Tax/Judgement By">
                <mat-option *ngFor="let p of geographicLevel" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <app-service-provider-profile #spProfile [editingMyCompany]="false" [forLookup]="true" [captureTax]="false" [captureWebInfo]="false" 
                                      [allowSave]="false" [allowMultipleAddresses]="false" [showHeader]="false" [allowNew]="true"></app-service-provider-profile>
    </mat-card>
    <hr/>
    <mat-card>
        <mat-form-field class="first">
            <input matInput appDisableAutofill [(ngModel)]="newLien.referenceNumber" maxlength="20" placeholder="Lien/Reference Number" />
        </mat-form-field>
        <mat-form-field>
            <input matInput appDisableAutofill [(ngModel)]="newLien.amount" maxlength="20" placeholder="Amount" class="numeric" type="number" (keydown)="utils.numOnlyLimitingLength($event,15)" />
        </mat-form-field>
        <app-datepicker #txtLienDate id="txtLienDate" [value]="newLien.lienDate" [placeholder]="'Lien Date'"></app-datepicker>
    </mat-card>
    <hr />
    <input type="submit" class="btn" value="Submit" *ngIf="saveReady && !busy" (click)="onSubmit()">
    <mat-progress-bar mode="buffer" *ngIf="busy"></mat-progress-bar>
    <div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to save new Lien.</div>
</div>
