<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
        <mat-form-field>
            <textarea matInput appDisableAutofill [(ngModel)]="request" maxlength="200" palceholder="Request"></textarea>
        </mat-form-field>
        <mat-form-field *ngIf="currentListing.isSeller">
            <input matInput appDisableAutofill [(ngModel)]="response" maxlength="500" palceholder="Response" (change)="checkReadyForSave()"/>
        </mat-form-field>
        <mat-card *ngIf="currentListing.isSeller" >
            <div class="app-flex-container app-2col">
                <mat-form-field>
                    <mat-label class="placeholder">Disclsoure Detail</mat-label>
                    <input matInput [(ngModel)]="media.title" disabled />
                </mat-form-field>
                <button matButton (click)="newDisc.click()">Upload</button>
            </div>
            <input type="file" ng2FileSelect [uploader]="uploader" #newDisc class="btn btn-primary" (change)="fileUploaded($event)" style="display:none" />
        </mat-card>
    </mat-card>
        <hr />
        <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
        <input type="submit" class="btn" value="Submit" *ngIf="saveReady && !saving" (click)="onSubmit()">
</div>