import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, AfterViewChecked, Output, OnDestroy } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { listingViewing, ListingViewingStatus, BaseResponse, listing, SPVisit } from '../models';

import { ListingService } from './listing.service';

import { ListingBaseComponent } from './listing.base.component';
import { AddVisitDialog } from './dialogs/addVisit.dialog';
import { GlobalComponent } from '../global.component';
import { Subscription } from 'rxjs/internal/Subscription';

import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { ServiceProviderService } from '../sp/serviceprovider.service';
import { WorkOrderService } from '../workorders/workorder.service';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-listing-viewing',
    templateUrl: './listingViewing.component.html'
})
export class ListingViewingComponent extends ListingBaseComponent implements OnInit, OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    vlistings: any[] = [];
    visits: any[] = [];

    viewingSchedule: listingViewing[] = new Array<listingViewing>();
    viewings: listingViewing[] = new Array<listingViewing>();
    selectedStatus: ListingViewingStatus = ListingViewingStatus.None;

    searched: boolean = false;
    private _lcs: Subscription;
    constructor(ds: DataService, rs: Router, private dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent
        , private currentRoute: ActivatedRoute) {
        super('pgListingViewing', ds, rs, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe((v) => {
            this.initMe();

            if (typeof (v) === 'number' && this.vlistings && this.vlistings.find((l) => l.id === parseInt(v.toString())) != null) {
                if (this.noParent)
                    this.setCurrentListing(this.vlistings.filter((l) => l.id === parseInt(v.toString()))[0]);
                this.bindViewings();
                this.bindVisits();
            }

        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngOnInit() {
        this.showHeader = this.noParent;

        if (this.currentRoute.snapshot.queryParams && this.currentRoute.snapshot.queryParams.l)
            this.setListingID(parseInt(this.currentRoute.snapshot.queryParams.l.toString()));

        if (this.listingID === 0) {
            const _id = parseInt(sessionStorage.getItem(this.handover.listingID));
            if (!isNaN(_id) && _id > 0) {
                this.listingID = _id;
                this.setListing();
            }
        }

        if (this.currentListing && this.currentListing.id === this.listingID && this.listingID > 0) {
            this.vlistings = [];
            this.vlistings.push({ id : this.currentListing.id, address : this.currentListing.getAddressString() });
            this.setViewings();
        } else
            this.bindData();
    }

    details() { this.gotoListing(this.listingID); }

    // User Changed Status
    changeStatus(viewing: listingViewing, action$) {
        if (parseInt(action$.name as string) === ListingViewingStatus.Confirmed || (action$.name as string).toLowerCase() === 'change') {
            const _name = parseInt(action$.name as string) === ListingViewingStatus.Confirmed ? 'confirm' : 'change';
            const dialogRef = this.dialog.open(AddVisitDialog, {
                data: {
                    id: parseInt(action$.argument), action: _name, forOpenHouse: viewing.isOpenHouse
                    , visitDate: viewing.isOpenHouse ? viewing.confirmedTime : viewing.requestedTime
                    , duration: moment(viewing.endTime).diff(viewing.requestedTime, 'minutes')
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result != null) {
                    this.currentListing.viewings = this.currentListing.viewings.filter((vs) => vs.id !== parseInt(action$.argument));
                    if (this.currentListing.viewings.length > 0)
                        this.currentListing.viewings.splice(0, 0, Utils.castTo(result, new listingViewing()));
                    else
                        this.currentListing.viewings.push(Utils.castTo(result, new listingViewing()));

                    this.setViewings();
                }
            });
        } else {
            let _actionResponse: BaseResponse;
            const _s = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).changeViewingStatus(parseInt(action$.argument), action$.name);
            if (_s) {
                _s.subscribe((r) => _actionResponse = r
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_actionResponse) {
                            if (!Utils.isNullOrEmpty(_actionResponse.error))
                                this.showError(_actionResponse.error);
                            else {
                                if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                                this.showMessage(_actionResponse.message);

                                if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                                    this.gotoURL(_actionResponse.redirectURL);
                                else {
                                    this.viewingSchedule = this.viewingSchedule.filter((vs) => vs.id !== parseInt(action$.argument));

                                    if (this.viewingSchedule.length > 0)
                                        this.viewingSchedule.splice(0, 0, Utils.castTo(_actionResponse.data, new listingViewing()));
                                    else
                                        this.viewingSchedule.push(Utils.castTo(_actionResponse.data, new listingViewing()));

                                    this.setViewings(false);
                                }
                            }
                        }
                    }
                );
            }
        }
    }

    addOpenHouse() {
        const dialogRef = this.dialog.open(AddVisitDialog, {
            data: { id: this.listingID, forOpenHouse: true, action: null }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result != null) {
                this.currentListing.viewings.splice(0, 0, Utils.castTo(result, new listingViewing()));
                this.setViewings();
                this.showSuccessMessage();
            }
        });
    }

    changeVisitStatus(oVisit, action$) {

        const iWorkOrderID = oVisit.workOrderID;

        let _actionResponse;
        (new WorkOrderService(this.dataservice, this.route, this.myResourceCategory)).changeVisitStatus(iWorkOrderID, parseInt(action$.argument), action$.name, oVisit.responseNotes).subscribe((r) => _actionResponse = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                        this.showMessage(_actionResponse.message);

                        if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                            this.gotoURL(_actionResponse.redirectURL);
                        else {
                            this.visits = cloneDeep(this.visits.filter(wo => { return (wo.id !== parseInt(action$.argument)); })); // Update Visit on Client
                            if (_actionResponse.data)
                                this.visits.splice(0, 0, Utils.castTo(_actionResponse.data, new SPVisit()));
                        }
                    }
                }
            }
        );
    }

    setListing() {
        this.componentBusy = true;
        this.gc.setListingID(this.listingID);
        if (this.vlistings) {
            const _l = this.vlistings.find((l) => l.id = this.listingID);
            if (_l != null) {
                this.setCurrentListing(_l);
            }
        }
        this.componentBusy = false;
    }

    private bindViewings() {
        this.viewings = [];
        if (this.currentListing != null && this.currentListing.id > 0) {
            let _response;
            this.componentBusy = true;
            const _s = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getViewings(this.currentListing.id);
            if (_s) {
                _s.subscribe(
                    (data) => { _response = data; }
                    , (error) => { this.onApiError(error); }
                    , () => {
                        this.componentBusy = false;
                        if (_response)
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.showError(_response.error);
                            else {
                                if (this.noParent && this.currentListing.id === 0)
                                    this.setCurrentListing(_response.data);
                                this.setViewings();
                            }
                    }
                );
            }
        } else
            this.setViewings();
    }

    private bindVisits() {
        if (this.currentListing && this.currentListing.id > 0) {
            let _response;

            this.searched = true;
            this.componentBusy = true;
            const _listings = [];
            _listings.push(this.currentListing.id);
            (new ServiceProviderService(this.dataservice, this.route, this.myResourceCategory)).visits(0, _listings, [], null, null, false, false).subscribe((r) => _response = r
                , (error) => { this.onApiError(error); }
                , () => {

                    this.componentBusy = false;

                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else if (_response.data) {
                            this.visits = _response.data.map(b => { return Utils.castTo(b, new SPVisit()); });
                        }
                });
        }
    }

    private setViewings(bRefresh: boolean = true) {
        if ((bRefresh == null || bRefresh === true) && this.currentListing && this.currentListing.viewings)
            this.viewingSchedule = this.currentListing.viewings.map((c) => Utils.castTo(c, new listingViewing()));
        if (this.selectedStatus !== ListingViewingStatus.None)
            this.viewings = [...this.viewingSchedule.filter((r) => r.currentStatus === this.selectedStatus)];
        else
            this.viewings = [...this.viewingSchedule];
    }

    private bindData() {
        this.getMyListingsForSale(this.dataservice, (listings) => {
            this.vlistings = listings;
            if (this.vlistings && this.vlistings.length > 0) {
                this.setCurrentListing(this.vlistings[0]);
                this.setViewings();
            }
        });
    }
}
