<div class="appContent">
    <h2>Offer(s) for My Properties on Sale</h2>
    <ng-template [ngIf]="(this.offerListings && this.offerListings.length > 0)" [ngIfElse]="noProperties">
        <mat-card *ngIf="showHeader && offerListings && offerListings.length > 0" class="section">
            <mat-form-field>
                <mat-label class="placeholder">Select a Property<i class="fa fa-question-circle-o warning-color" title="Properties for Sale"></i></mat-label>
                <mat-select [(ngModel)]="listingID" style="width: Calc(100% - 24px)" (selectionChange)="setListingID()">
                    <mat-option *ngFor="let l of offerListings" [value]="l.id" class="auto">
                        {{l.address.StreetName ? (l.address.StreetName + (l.address.City ? ', ' + l.address.City : '') + (l.address.State ? ', ' + l.address.State : '')) : l.address}}
                    </mat-option>
                </mat-select>
                <i class="fa fa-arrows-h" *ngIf="this.offers && this.offers.length > 1" (click)="this.GoToURLWithSession(appURLS.listingCompare, handover.listingID, listingID)" title="Compare Side by Side"></i>
            </mat-form-field>
            <div class="section" *ngIf="currentListing && currentListing.buyersAgentCommission && currentListing.buyersAgentCommission > 0">{{this.FormatNumberToCurrency(currentListing.buyersAgentCommission)}} to be Paid to Buyer's Agent</div>
        </mat-card>
    </ng-template>
    <ng-template #noProperties>
        <div class="section" *ngIf="this.componentBusy === false" >
            You do not have any property listed for sale. Click <a class="btn" (click)="gotoRoute(appURLs.sellerNewListing)">here</a> to list a property for free.
        </div>
    </ng-template>
    <mat-progress-bar *ngIf="this.componentBusy === true" mode="indeterminate"></mat-progress-bar>
    <ng-template [ngIf]="(this.offers && this.offers.length > 0)" [ngIfElse]="noOffers">
        <mat-card class="section">
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let offer of offers; let i = index" [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span title="{{offer.id}}">{{offer.otherParty}}</span>
                        </mat-panel-title>
                        <mat-panel-description style="display:initial">
                            <a (click)="showProofOfFunds(offer.id)" title="Show Proof of Funds" *ngIf="offer.hasProofOfFunds" class="float-right"><i class="fa fa-money"></i></a>
                            <span class="float-right">{{this.FormatNumberToCurrency(offer.offeredPrice)}}&nbsp;[{{offer.fundingStatus}}]</span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <label class="caption">Deposit</label><label class="data-value">{{this.FormatNumberToCurrency(offer.deposit)}}</label>
                        <br /><span title="Service(s) to be Paid by Seller (In Full/Partial)">{{offer.includedServices}}</span>
                        <br />{{offer.negotiationStatus}}
                        <mat-accordion *ngIf="offer.documents && offer.documents.length > 0">
                            <br />
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Documents
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-documents [documents]="offer.documents"></app-documents>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <mat-action-row style="display:initial">
                        <div><label class="caption">{{'System.CurrentStatus' | translateTag}}</label><label class="data-value auto">{{offer.statusName}}<span *ngIf="offer.currentStatusDate"><i class="fa fa-calendar" title="{{utils.DisplayDateTime(offer.currentStatusDate)}}"></i></span></label><br /></div>
                        <label class="caption auto" *ngIf="(offer.canEdit && offer.currentStatus == 1) || offer.currentStatus == 2 || offer.currentStatus == 3">
                            Click here to &nbsp;
                            <a class="link" (click)="this.GoToURLWithSession(appURLs.offer, handover.offerID, offer.id)">review offer and take available actions</a>
                        </label>
                        <div>
                            <a title="Offer Message" *ngIf="!offer.hasPurchaseAgreement" (click)="showOfferMessage(offer.id)" class="float-right"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-bars fa-stack-1x fa-inverse"></i></span></a>
                            <a title="Purchase Agreement" *ngIf="offer.hasPurchaseAgreement" (click)="showPurchaseAgreement(offer.id)" class="float-right"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-list-alt fa-stack-1x fa-inverse"></i></span></a>
                            <a *ngIf="offer.canSendMessage" title="Send Message to {{offer.otherParty}}" (click)='sendMsgToOtherParty(offer.otherParty, offer.otherPartyPIDs, offer.address?.StreetName)' class="float-right"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-comment fa-stack-1x fa-inverse"></i></span></a>
                        </div>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
    </ng-template>
    <ng-template #noOffers>
        <div class="section">
            <span *ngIf="this.componentBusy === false && listingID > 0"> No one has submitted any offers for selected listing.</span>
        </div>
    </ng-template>
</div>