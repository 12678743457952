<div mat-dialog-title>
    <h4>{{(addCredit ? 'Credit' : 'Demand') + dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content *ngIf="escrowID > 0">
    <div class="app-flex-container app-2col">
        <mat-button-toggle-group [(ngModel)]="addCredit" aria-label="Demand Or Credit" aria-labelledby="">
            <mat-button-toggle [value]="false">Demand</mat-button-toggle>
            <mat-button-toggle [value]="true">Credit</mat-button-toggle>
        </mat-button-toggle-group>
        <div  *ngIf="showOpenDemands">
            <label *ngIf="openDemands == null">Fetching Previous Demands....</label>
            <mat-form-field *ngIf="openDemands && openDemands.length > 0">
                <mat-label class="placeholder">Open Demands <i class="fa fa-question-circle-o warning-color" title="Currently Open Demand(s)/Credit(s), pending payment distribution."></i></mat-label>
                <mat-select [(ngModel)]="openDemandID">
                    <mat-option *ngFor="let p of openDemands" [value]="p.id">{{p.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <hr />
    <div *ngIf="!addCredit">
        <mat-form-field>
            <mat-label class="placeholder">Demand Description<i class="fa fa-question-circle-o warning-color" title="Select or Enter demand description"></i></mat-label>
            <input matInput appDisableAutofill  [(ngModel)]="selectedDemand" list="demandList"  (focus)="selectAllContent($event)" (mouseup)="$event.preventDefault()"/>
            <datalist id="demandList">
                <ng-template ngFor let-a [ngForOf]="availableDemands" let-i="index">
                    <option value="{{a.name}}">{{a.name}}</option>
                </ng-template>
            </datalist>
        </mat-form-field>
        <hr />
    </div>
    <div class="app-flex-container app-2col">
        <mat-form-field>
            <mat-label class="placeholder">Demand From<i class="fa fa-question-circle-o warning-color" title="{{addCredit ? 'Select the service giving Credit' : 'Select the Service for which demand is being submitted'}}"></i></mat-label>
            <mat-select [(ngModel)]="selectedDemandFrom">
                <mat-option *ngFor="let p of demandFromServices" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label class="placeholder">Demand To<i class="fa fa-question-circle-o warning-color" title="{{addCredit ? 'Select the Role getting Credit' : 'Select the role who is to fulfil the demand'}}"></i></mat-label>
            <mat-select [(ngModel)]="selectedDemandTo">
                <mat-option *ngFor="let p of demandToRoles" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-form-field>
        <mat-label class="placeholder">Amount<i class="fa fa-question-circle-o warning-color" title="{{addCredit ? 'Enter amounted being credited' : 'Enter the amount being demanded'}}"></i></mat-label>
        <input matInput appDisableAutofill  type="number" [(ngModel)]="demandAmount" class="numeric" (focus)="selectAllContent($event)" (mouseup)="$event.preventDefault()"/>
    </mat-form-field>
    <hr />
    <mat-error *ngIf="(error ?? '') !== ''">Failed to submit your request. {{error}}</mat-error>
    <mat-progress-bar mode="buffer" *ngIf="componentBusy === true"></mat-progress-bar>
    <button mat-raised-button (click)="addDemand()" *ngIf="readyToSubmit && componentBusy === false">Submit</button>
</div>
