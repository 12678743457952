<main>

    <!--Main layout-->
    <div class="container">
        <div class="row">

            <!--Main column-->
            <div class="col-lg-12">

                <!--First row-->
                <div class="row wow fadeIn" data-wow-delay="0.4s">
                    <div class="col-lg-12">
                        <div class="divider-new">
                            <h2 class="h2-responsive">I Am My Own Real Estate Agent<sup>&reg;</sup></h2>
                        </div>
                        <!--Carousel Wrapper-->
                        <div id="carousel-example-1z" class="carousel slide carousel-fade" data-ride="carousel">
                            <!--Indicators-->
                            <ol class="carousel-indicators">
                                <li data-target="#carousel-example-1z" data-slide-to="0" class="active"></li>
                                <li data-target="#carousel-example-1z" data-slide-to="1"></li>
                                <li data-target="#carousel-example-1z" data-slide-to="2"></li>
                                <li data-target="#carousel-example-1z" data-slide-to="3"></li>
                            </ol>
                            <!--/.Indicators-->
                            <!--Slides-->
                            <div class="carousel-inner" role="listbox">
                                <!--First slide-->
                                <div class="carousel-item active">
                                    <img src="/assets/images/slide1.jpg" alt="First slide">
                                    <div class="carousel-caption">
                                        <h4 style="color: white; font-weight: bolder">{{'Root.slide1' | translateTag}}</h4>
                                        <br>
                                    </div>
                                </div>
                                <!--/First slide-->
                                <!--Second slide-->
                                <div class="carousel-item">
                                    <img src="/assets/images/slide2.jpg" alt="Second slide" loading="lazy">
                                    <div class="carousel-caption">
                                        <h4 style="color: white; font-weight: bolder">{{'Root.slide2' | translateTag}}</h4>
                                        <br>
                                    </div>
                                </div>
                                <!--/Second slide-->
                                <!--Third slide-->
                                <div class="carousel-item">
                                    <img src="/assets/images/slide3.jpg" alt="Third slide" loading="lazy">
                                    <div class="carousel-caption">
                                        <h4 style="color: white; font-weight: bolder">{{'Root.slide3' | translateTag}}</h4>
                                        <br>
                                    </div>
                                </div>
                                <!--/Third slide-->
                                <!--Third slide-->
                                <div class="carousel-item">
                                    <img src="/assets/images/slide4.jpg" alt="Fourth slide" loading="lazy">
                                    <div class="carousel-caption">
                                        <h4 style="color:white;font-weight:bolder">{{'Root.slide4' | translateTag}}</h4>
                                        <br>
                                    </div>
                                </div>
                                <!--/Third slide-->
                            </div>
                            <!--/.Slides-->
                            <!--Controls-->
                            <a class="carousel-control-prev" href="#carousel-example-1z" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carousel-example-1z" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                            <!--/.Controls-->
                        </div>
                        <!--/.Carousel Wrapper-->
                        <div class="divider-new nomargin-bottom">
                            <h2 class="h2-responsive">{{'Root.reaNotRequired' | translateTag}}</h2>
                        </div>
                    </div>
                </div>
                <!--/.First row-->
                <br>
                <hr class="extra-margins">

                <!--Second row-->
                <div class="row">
                    <!--First columnn-->
                    <div class="col-lg-6">
                        <mat-card style="width: 100%;background-size: cover; background-repeat:no-repeat">
                            <mat-card-header style="justify-content:center">
                                <mat-card-title>Sell your home for Free!<sup>*</sup></mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <ol style="list-style:decimal" class="bold">
                                    <li>List your Property</li>
                                    <li>Receive, Negotiate and Accept Offer</li>
                                    <li>Fulfil your dues</li>
                                    <li>Close the Sale</li>
                                </ol>
                                <p class="card-text">List your property "now" and start receiving instant offers for all active buyers, counter/negotiate offers, hire service providers ..., <b>right here right now</b>.</p>
                                <div>
                                    <a (click)="start(appURLs.sellerNewListing)" class="btn btn-default">List your Property and Accept Offers</a>
                                    <app-asset-link [css]="'link float-right'" [label]="'Learn How'" [assetID]="'5241'" [style]="'margin-top:10px'"></app-asset-link>
                                </div>
                            </mat-card-content>
                            <img mat-card-image src="/assets/images/seller.jpg" alt="Buyer" />
                        </mat-card>
                    </div>
                    <!--First columnn-->
                    <!--Second columnn-->
                    <div class="col-lg-6">
                        <mat-card style="width: 100%;background-size: cover; background-repeat:no-repeat">
                            <mat-card-header style="justify-content:center">
                                <mat-card-title>Find a Home and Submit a Offer Now.</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <ol style="list-style:decimal" class="bold">
                                    <li>Search and Select your Home</li>
                                    <li>Submit and Negotiate Offer</li>
                                    <li>Fulfil your dues</li>
                                    <li>Close the Sale</li>
                                </ol>
                                <p class="card-text">Find your home and submit your offers right away, apply for loan, hire service providers ...., <b>right here right now</b>.</p>
                                <div>
                                    <a (click)="gotoURL(appURLs.findHome)" class="btn btn-default">Find a Home and Submit Offer</a>
                                    <app-asset-link [css]="'link float-right'" [label]="'Learn How'" [assetID]="5243" [style]="'margin-top:10px'"></app-asset-link>
                                </div>
                            </mat-card-content>
                            <img mat-card-image src="/assets/images/buyer.jpg" alt="Buyer" />
                        </mat-card>
                    </div>
                    <!--Second columnn-->

                </div>
                <!--/.Second row-->
                <br>
                <hr class="extra-margins">
            </div>
            <!--/.Main column-->
            <div class="row">
                <!--First columnn-->
                <div class="col-lg-12">
                    * - IAMOREA does not charge Listing Fee or Selling Commision from sellers, for one (1) listing every 2 years, for a residential home (single unit or single family home) valued upto $300,000 (median US home price). Investors (Home Flippers) pay small commission on Close of Escrow.
                    If median Home price in your area is higher, contact us we will waive/reduce applicable IAMOREA Fees and/or Commissions. <br/>Currently we do not service Commercial Real Estate or Homes with 4+ units.
                    <br />
                </div>
            </div>
        </div>
    </div>
    <!--/.Main layout-->

</main>