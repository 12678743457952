import { Component, ChangeDetectorRef, ViewChild, OnInit, EventEmitter, Inject, OnDestroy } from '@angular/core';
declare var $: any;
import { Title } from '@angular/platform-browser';
import { HubConnection, HubConnectionBuilder } from 'signalr';
import { filter } from 'rxjs/operators';
import { map, mergeMap } from 'rxjs/internal/operators';

import { DataService } from './util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';

import { SignInComponent } from './security/signin.component';

import { returnRoute, Profile } from './models/appCommon.models';
import { PersonService } from './account/person.service';
import { appEnvironment } from '../environments/environment';
import { UserProfileGroup, BaseResponse, Session, MessageName, User, Address } from './models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { buyerStart, sellerStart, spStart, appSnackBarDisplay, appSnackBarDisplayTop, handover, AppURLs, navIA } from './models/constants';
import { AssetService } from './common/service';
import { Subscription } from 'rxjs/internal/Subscription';
import * as uuid from 'uuid';
import * as moment from 'moment';
import { Utils, rolePosition } from './common/utils';
import { ScriptService } from './common/scriptService';

const userSessionID = 'sessionID';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavBarComponent extends BaseComponent implements OnInit, OnDestroy {

    messageReceived = new EventEmitter<any>();
    connectionEstablished = new EventEmitter<boolean>();

    @ViewChild('buyerMenu') buyerMenu: MatMenuTrigger;
    @ViewChild('sellerMenu') sellerMenu: MatMenuTrigger;
    @ViewChild('spMenu') spMenu: MatMenuTrigger;
    @ViewChild('accountMenu') accountMenu: MatMenuTrigger;

    signalrSubscriber: Subscription;
    loginSubscriber: Subscription;

    buyer = buyerStart;
    seller = sellerStart;
    sp = spStart;

    notAtHelpURL: boolean = true;

    isLoginPage: boolean = false;
    editContent: boolean = false;
    canIA: boolean = false;

    timeoutWarning: number = appEnvironment.timeoutWarningInMinutes * 60;

    // For Optimization
    captchaProtected: boolean = false;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) private g: GlobalComponent
        , private dialog: MatDialog, private titleService: Title, private cdref: ChangeDetectorRef
        , private activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar
    ) {
        super('navBar', r, g);

        this.gc.matDialogFullHeight = (window.innerHeight - 130) + 'px';

        this.restoreUser();

        // Detect Any Route Changes
        this.r.events.subscribe((e) => {
            //.pipe(filter((event) => event instanceof NavigationEnd), map((e) => {
            if (e instanceof NavigationEnd) {
                this.notAtHelpURL = !(e['url'] != null && e['url'].toString().endsWith('/help'));

                let ar = activatedRoute.firstChild;
                let child = ar;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        ar = child;
                    } else {
                        child = null;
                    }
                }

                if (ar?.snapshot?.data != null && ar?.snapshot?.data['title'] != null)
                    document.title = this.myBRAND + ' - ' + ar.snapshot.data['title'];
                else
                    document.title = this.myBRAND;

                if (ar?.snapshot?.data != null && ar?.snapshot?.data['captchaProtected'] != null)
                    this.captchaProtected = true;
                else
                    this.captchaProtected = false;

                let _id = 0;
                if (ar?.snapshot?.data != null && ar?.snapshot?.data['id'] != null)
                    _id = parseInt(ar.snapshot.data['id']);

                if (ar?.snapshot?.data != null && ar?.snapshot?.data['profileGroup'] != null)
                    this.setUserTypeProfile(ar.snapshot.data['profileGroup']);

                this.navEnded(e, _id);

                try {
                    // DO NOT DELETE - Use for Testing
                    if (document.getElementById(navIA) != null) {
                        if (ar?.snapshot?.data != null && ar?.snapshot?.data['description'] != null) {
                            this.sendIM(ar.snapshot.data['description'].toString());
                        }
                    }
                } catch (e) { }

                return ar;
            }
        }
           // ), mergeMap((ar) => ar.data)
        );

        // Moved to app.component (setMataTags)
        // .subscribe((e) => {
        //    if (e instanceof NavigationEnd) {
        //        const title = this.getTitle(this.r.routerState, this.r.routerState.root).join(' | ');
        //        this.titleService.setTitle(title + ' - ' + this.appBRAND);
        //        this.navEnded(e, 0);
        //    }
        // });

        if (this.loginSubscriber != null) this.loginSubscriber.unsubscribe();

        // Hide Menu and Top Status Bar if login is rendered.
        this.loginSubscriber = this.gc.loginChanged.subscribe((v) => {
            this.canIA = false;
            if (v == null || (v as string) === '') {
                // DO NOT End SignalR Connection // this.endConnection();
                this.g.clearAppSession();
                this.restoreUser();
                if (this.isRouteGuarded()) {
                    this.GotoHome();
                    return;
                }
            } else {
                this.canIA = this.g.hasRole(rolePosition.sysadmin);
                this.restoreUser();
                if (v.showMessage != null && v.showMessage === true)
                    this._snackBar.open('Signed In.', '', appSnackBarDisplayTop);
            }


            // if (Utils.isNullOrEmpty(this.g.hubConnectionID)) {
            this.createSignalRConnection();
            // }

            this.cdref.detectChanges();
        });

        if (this.signalrSubscriber != null) this.signalrSubscriber.unsubscribe();

        // this.signalrSubscriber = this.gc.signalRBroadcast.subscribe((v) => {
        //    this.signalRMessageProcessor(v);
        // });

        setTimeout(() => {
            this.clearQueryParams();

            // Restore Session if Page being invoke in New Window.
            if (!this.loggedIn && this.gc.savedQueryParams && this.gc.savedQueryParams[userSessionID]) {
                this.gc.setBusy(true);
                const _data = { value: this.gc.savedQueryParams[userSessionID] };
                let _response: BaseResponse;
                (new PersonService(this.dataservice, this.route, this.myResourceCategory)).restore(_data)
                    .subscribe((data) => { _response = data; }
                        , (error) => { this.gc.setBusy(false); this.onApiError(error); }
                        , () => {
                            this.gc.setBusy(false);
                            if (_response && _response.data) {
                                if (_response?.data?.details) {
                                    this.userdetails = Utils.castTo(_response.data.details, new User());
                                    if (_response.data.details.PrimaryAddress) {
                                        this.userdetails.PrimaryAddress = Utils.castTo(_response.data.details.PrimaryAddress, new Address());
                                    }
                                    return;
                                }
                            }
                            this.GotoHome();
                    });
            }
        }, appEnvironment.delayedNGInit);
    }

    ngOnDestroy() {
        if (this.loginSubscriber != null) this.loginSubscriber.unsubscribe();
        if (this.signalrSubscriber != null) this.signalrSubscriber.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngOnInit() {
        document.title = 'Welcome to ' + this.myBRAND;
        ScriptService.loadExternalScript(appEnvironment.jquerySignalR).then(() => {
            ScriptService.loadExternalScript(appEnvironment.signalRProxy).then(() => {
                this.createSignalRConnection();
            }).catch(() => { });
        }).catch(() => { });

        this.canIA = this.g.hasRole(rolePosition.sysadmin);
    }

    signin(returnToRouteOnClose?: string, oRouteParams?: any) {
        if (this.route.url.endsWith('register')) {
            this.navTo(AppURLs.login, true);
        } else {
            this.showSignUp();
        }
    }

    startWithSignUp(sURL: string) {
        if (this.loggedIn) {
            this.gotoURL(sURL);
        } else {
            this.showSignUp(sURL);
        }
    }

    signup() {
        this.navTo(AppURLs.register, true);
    }

    signOut() {
        (new PersonService(this.dataservice, this.route, this.myResourceCategory)).logout()
            .subscribe(() => { });
        this.gc.clearAppSession();
        this.gc.loginChanged.emit(null);
        sessionStorage.clear();
        console.clear();
        this._snackBar.open('Signed Out.', '', appSnackBarDisplayTop);
     }

    navToURL(routeName: string) {
        if (!Utils.isNullOrEmpty(routeName)) {
            routeName = routeName.toLowerCase();

            if (this.loggedIn)
                this.gotoRoute(routeName);
            else
                this.signin(routeName);
        }
    }

    navTo(routeName: string, publicAccess: boolean = false) {
        if (!Utils.isNullOrEmpty(routeName)) {
            routeName = routeName.toLowerCase();
            this.gc.setBusy(true);
            if (publicAccess || this.loggedIn)
                this.gotoRoute(routeName);
            else
                this.signin(routeName);
        }
    }

    closeOthers(menu: string) {
        if (menu === 'buyer') {
            if (this.sellerMenu) this.sellerMenu.closeMenu();
            if (this.spMenu) this.spMenu.closeMenu();
            if (this.accountMenu) this.accountMenu.closeMenu();
        } else if (menu === 'seller') {
            if (this.buyerMenu) this.buyerMenu.closeMenu();
            if (this.spMenu) this.spMenu.closeMenu();
            if (this.accountMenu) this.accountMenu.closeMenu();
        } else if (menu === 'sp') {
            if (this.sellerMenu) this.sellerMenu.closeMenu();
            if (this.buyerMenu) this.buyerMenu.closeMenu();
            if (this.accountMenu) this.accountMenu.closeMenu();
        } else if (menu === 'account') {
            if (this.sellerMenu) this.sellerMenu.closeMenu();
            if (this.spMenu) this.spMenu.closeMenu();
            if (this.buyerMenu) this.buyerMenu.closeMenu();
        }
    }

    newLoan() {
        let responseData;
        const _s = this.dataservice.APIPost('/loan/createNewApplication', null, this.myResourceCategory).subscribe(
            (data) => { responseData = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (responseData)
                    if (!Utils.isNullOrEmpty(responseData.error)) {
                        this.showError(responseData.error);
                    } else {

                        let _msg = '';
                        if (responseData.data)
                            _msg = 'Loan Application # ' + (responseData.data as string) + ', ';
                        if (!Utils.isNullOrEmpty(responseData.message)) {
                            this.showMessage(_msg + responseData.message);
                        } else {
                            this.showMessage(_msg + 'New Loan application created successfully.');
                        }

                        sessionStorage.setItem(handover.lienID, responseData.data.toString());
                        this.gotoRoute(AppURLs.loan);
                    }
            }
        );
    }

    changeContentEdit() {
        this.gc.setContentEdit(this.editContent);
    }

    // #region Private
    private resetHeader() { }
    private showSignUp(returnToRouteOnClose?: string, oRouteParams?: any) {
        if (this.gc.mobileScreen === true) {
            this.gotoLogin();
        } else {
            const dialogRef = this.dialog.open(SignInComponent, {
                data: { returnRoute: returnToRouteOnClose, routeParams: oRouteParams, css: '', inPopup: true }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((e) => {
                if (e != null) {
                    const _retRoute: returnRoute = e as returnRoute;
                    if (_retRoute != null && !Utils.isNullOrEmpty(_retRoute.routePath))
                        this.gotoRoute(_retRoute.routePath, _retRoute.queryParams);
                }
            });
        }
    }

    private async logSessionTracking(iStaticAssetID: number = 0) {
        if (appEnvironment.sessionTrackingEnabled === true) {
            let _s: any = localStorage.getItem('stid');
            if (!Utils.isNullOrEmpty(_s)) {
                try {
                    const _so = JSON.parse(_s);
                    if (_so != null && !Utils.isNullOrEmpty(_so.i) && !Utils.isNullOrEmpty(_so.e)) {
                        if (moment(_so.e).isValid() && moment(_so.e).isAfter(Date())) {
                            _so.e = moment(_so.e).add(appEnvironment.sessionTimeout, 'minutes').toDate();
                            localStorage.setItem('stid', JSON.stringify(_so));
                            this.writeSessionInfo(_so);
                            return;
                        }
                    }
                } catch (e) { }
            }

            _s = {};
            _s['i'] = uuid.v4();
            _s['e'] = moment(Date()).add(appEnvironment.sessionTimeout, 'minutes').toDate();
            localStorage.setItem('stid', JSON.stringify(_s));
            this.writeSessionInfo(_s);
        }
    }

    private writeSessionInfo(oSession: any, iStaticAssetID: number = 0) {
        oSession['url'] = this.route.url;
        oSession['assetID'] = iStaticAssetID;
        const _user = this.gc.user;
        if (_user != null && _user.details.PID > 0) {
            oSession['pid'] = _user.details.PID;
        }
        this.dataservice.APIPreLoginPost('/trackingSession', oSession, this.myResourceCategory).subscribe((data) => { }, (error) => { }, () => { });
    }

    private async navEnded(e, iStaticAssetID: number = 0, captchaProtected: boolean = false) {
        this.gc.currentPageAssetID = iStaticAssetID;
        this.clearMessage();

        if (iStaticAssetID === 0 && this.notAtHelpURL && (this.loggedIn || !this.isRouteGuarded())) {
            setTimeout(() => {
                let _assets: any = localStorage.getItem('assets');
                let _f: boolean = false;
                const _u = e['url'].toString();

                if (_assets != null && Utils.isJsonString(_assets)) {
                    _assets = JSON.parse(_assets);
                    const _asset = _assets.find((a) => a.url === _u);
                    if (_asset != null) {
                        if (Array.isArray(_asset) && _asset.length > 0 && _asset[0].id) {
                            this.gc.currentPageAssetID = _asset[0].id;
                            _f = true;
                        } else if (!Utils.isNullOrEmpty(_asset['id'])) {
                            this.gc.currentPageAssetID = _asset['id'];
                            _f = true;
                        } else if (!Utils.isNullOrEmpty(_asset['url']))
                            _f = true;
                    }
                } else
                    _assets = [];

                if (!_f) {
                    let _resp: BaseResponse;
                    // Get Asset ID with Help Content Defined
                    (new AssetService(this.dataservice, this.route, this.myResourceCategory)).getAssetForURL(_u, true).subscribe(
                        (data) => { _resp = data; }
                        , (error) => { }
                        , () => {
                            if (_resp && _resp.data && !this.isNaN(parseInt(_resp.data.id.toString()))) {
                                const _i = parseInt(_resp.data.id.toString());
                                if (!this.isNaN(_i) && _i > 0) {
                                    this.gc.currentPageAssetID = _i;
                                    _assets.push({ id: this.gc.currentPageAssetID, url: _u});
                                    localStorage.setItem('assets', JSON.stringify(_assets));
                                }
                            }
                        }
                    );
                }

            }, 0);
        }

        this.gc.setBusy(false);

        if (!captchaProtected) {
            ScriptService.removeExternalScript(appEnvironment.reCaptchaScript);
            ScriptService.removeGoogleReCaptcha();
        }

        this.resetHeader();
        this.editContent = this.gc.editContent();
        this.restoreUser();

        if (e) {
            this.isLoginPage = e['url'] != null && e['url'].toString().indexOf(AppURLs.login) >= 0;
        }

        this.logSessionTracking(iStaticAssetID);

        this.clearQueryParams();
    }

    private clearQueryParams() {
        this.gc.savedQueryParams = [];
        const queryParams: Params = {}; // { returnUrl: null, sessionID: null, sessionID: null, id: null };
        const _this = this;
        if (this.activatedRoute && this.activatedRoute.snapshot.params != null) {
            const _sourceKeys = Object.keys(this.activatedRoute.snapshot.params);
            const _params = this.activatedRoute.snapshot.params;

            _sourceKeys.forEach((_key) => {
                const _prop: PropertyDescriptor = _this.getPropertyDescriptor(_params, _key);
                if ((_prop == null && _params[_key]) || (_prop != null)) {
                    if (!_key.toLowerCase().startsWith('session'))
                        this.gc.savedQueryParams[_key] = _params[_key];
                    queryParams[_key] = null;
                }
            });
        }
        if (this.activatedRoute && this.activatedRoute.snapshot.queryParams != null) {
            const _sourceKeys = Object.keys(this.activatedRoute.snapshot.queryParams);
            const _params = this.activatedRoute.snapshot.queryParams;

            _sourceKeys.forEach((_key) => {
                const _prop: PropertyDescriptor = _this.getPropertyDescriptor(_params, _key);
                if ((_prop == null && _params[_key]) || (_prop != null)) {
                    if (!_key.toLowerCase().startsWith('session'))
                        this.gc.savedQueryParams[_key] = _params[_key];
                    queryParams[_key] = null;
                }
            });
        }

        this.r.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
    }

    private getPropertyDescriptor(obj: any, prop: string): PropertyDescriptor {
        let desc;
        do {
            desc = Object.getOwnPropertyDescriptor(obj, prop);
            // tslint:disable-next-line: no-conditional-assignment
        } while (!desc && (obj = Object.getPrototypeOf(obj)));
        return desc;
    }

    private setUserTypeProfile(sProfileGroup: string) {

        // Set User Profile Group, Buyer, Seller or Provider, based on max visited Funactionality

        if (sProfileGroup != null && sProfileGroup.toString().trim() !== '') {
            setTimeout(() => {
                let _p: any = localStorage.getItem('pr');
                if (_p == null || _p.toString().trim() === '')
                    _p = new Profile();
                else {
                    try {
                        _p = JSON.parse(_p) as Profile;
                    } catch (e) {
                        _p = new Profile();
                    }
                }

                if (_p.bc == null) _p.bc = 0;
                if (_p.sc == null) _p.sc = 0;
                if (_p.pc == null) _p.pc = 0;

                if (sProfileGroup === '1') {
                    _p.bc++;
                } else if (sProfileGroup === '2') {
                    _p.sc++;
                } else if (sProfileGroup === '3') {
                    _p.pc++;
                }

                const _a = { b: _p.bc, s: _p.sc, p: _p.pc };
                let max = 0;
                let maxKey;

                for (const key in _a) {
                    if (_a[key] > max) {
                        maxKey = key;
                        max = _a[key];
                    }
                }

                if (maxKey == null) {
                    if (sProfileGroup === '1') {
                        maxKey = 'b';
                    } else if (sProfileGroup === '2') {
                        maxKey = 's';
                    } else if (sProfileGroup === '3') {
                        maxKey = 'p';
                    }
                }

                if (maxKey === 'b')
                    _p.pg = UserProfileGroup.Buyer;
                else if (maxKey === 's')
                    _p.pg = UserProfileGroup.Seller;
                else if (maxKey === 'p')
                    _p.pg = UserProfileGroup.Provider;
                else
                    _p.pg = UserProfileGroup.Unknown;

                localStorage.setItem('pr', JSON.stringify(_p));
            });
        }
    }
    private onMessageReceived(): void {
        this.gc.hubProxy.on('broadcastMessage', (data: any) => {
            this.processReceivedIM(data);
        });
        this.gc.hubProxy.on('broadcastMessageToGroup', (data: any) => {
            this.processReceivedIM(data);
        });
        this.gc.hubProxy.on('ReceiveMessage', (data: any) => {
            this.processReceivedIM(data);
        });
        this.gc.hubProxy.on('Send', (data: any) => {
            this.processReceivedIM(data);
        });
        this.gc.hubProxy.on('sendMessageTo', (data: any) => {
            this.processReceivedIM(data);
        });
        this.gc.hubProxy.on('SendMessageTo', (data: any) => {
            this.processReceivedIM(data);
        });
        this.gc.hubProxy.on('send', (data: any) => {
            this.processReceivedIM(data);
        });
    }

    private processReceivedIM(data: any) {
        let msg: string = data;
        let senderID: string = '';

        let _o: any;
        if (Utils.isJsonString(data.toString())) {
            _o = (JSON.parse(data));
            if (_o.message) msg = _o.message;
            if (_o.senderID) senderID = _o.senderID;
        } else {
            msg = _o = data;
        }

        this.g.signalRBroadcast.emit(_o);
        this.signalRMessageProcessor(senderID, msg);

    }

    private createSignalRConnection() {

        if ($.connection) {
            $.connection.hub.url = appEnvironment.signalREndPoint;

            this.g.hubConnection = $.hubConnection(appEnvironment.signalRProxy);
            this.g.hubConnection.logging = true;

            try {
                $.connection.hub.start().done(() => {
                    this.g.hubConnectionID = $.connection.centralHub.connection.id;
                    this.g.hubProxy = this.g.hubConnection.createHubProxy('centralHub');
                    // $.connection.centralHub.server.announce(this.g.hubConnectionID);
                    $.connection.centralHub.server.joinDefaultGroup(this.g.hubConnectionID);
                    this.onMessageReceived();
                });
            } catch (e) { }
        }
    }

    private endConnection() {
        // DO NOT END Connection
        try {
            $.connection.hub.stop().done(() => { });
        } catch (e) { }
    }

    private signalRMessageProcessor(sSender: string, sMessage) {
        if (!Utils.isNullOrEmpty(this.gc.hubConnectionID) && sSender !== this.gc.hubConnectionID) {
            if (!Utils.isNullOrEmpty(sMessage) && this._snackBar) {

                let _o = sMessage;
                if (_o !== null && typeof _o === 'string')
                    if (Utils.isJsonString(sMessage))
                        _o = JSON.parse(sMessage);

                if (_o !== null && typeof _o === 'object') {
                    if (_o.userID != null && this.loggedIn) {
                        if (_o.name === MessageName.offerRefreshed && _o.userID !== this.userdetails.PID) {
                            if (!Utils.isNullOrEmpty(_o.message)) this._snackBar.open(_o.message, '', appSnackBarDisplay);
                        } else if (_o.name === MessageName.listingRefreshed && _o.userID !== this.userdetails.PID) {
                            if (!Utils.isNullOrEmpty(_o.message)) this._snackBar.open(_o.message, '', appSnackBarDisplay);
                        } else if (_o.name === MessageName.serviceProviderRefreshed && _o.userID !== this.userdetails.PID) {
                            if (!Utils.isNullOrEmpty(_o.message)) this._snackBar.open(_o.message, '', appSnackBarDisplay);
                        } else if (_o.name === MessageName.newInternalMessage) {
                            if (this.loggedIn && _o.userID && _o.userID === this.userdetails.PID) {
                                // Show if message to current user
                                this._snackBar.open(_o.message, '', appSnackBarDisplay);
                                this.gc.incrementMessageCount();
                            } else if (this.loggedIn && _o.userIDs) {
                                // Show if message to one of the Users
                                const _s = (_o.userIDs as string).split(',');
                                if (_s.indexOf(this.userdetails.PID.toString()) >= 0) {
                                    this._snackBar.open(_o.message, '', appSnackBarDisplay);
                                    this.gc.incrementMessageCount();
                                }
                            } else if (Utils.isNullOrEmpty(_o.userID) && Utils.isNullOrEmpty(_o.userIDs)) {
                                // Show only if really a broadcast to ALL
                                this._snackBar.open(sMessage, '', appSnackBarDisplay);
                            }
                        } else if (Utils.isNullOrEmpty(_o.userID) && Utils.isNullOrEmpty(_o.userIDs)) {
                            // Show only if really a broadcast to ALL
                            this._snackBar.open(sMessage, '', appSnackBarDisplay);
                        }
                    }
                } else {
                    // DO NOT DELETE - Use for Testing to receive IM message
                    // FOR TESTING ONLY -
                    if (this._snackBar)
                        this._snackBar.open(sMessage, '', appSnackBarDisplay);
                }
            }
        }
    }

    private getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.meta && parent.snapshot.data.meta.title) {
            data.push(parent.snapshot.data.meta.title);
        } else if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }
    /*

    private connectionIsEstablished: boolean = false;
      // import { HubConnection } from 'signalr';
     // hubConnection: HubConnection; // Move this to Global Component when Migrating to .NET CORE

    private startConnectionCore(): void {
        this.g.hubConnection
            .start()
            .then(() => {
                this.connectionIsEstablished = true;
                console.log('Hub connection started');
                this.connectionEstablished.emit(true);
            })
            .catch((err) => {
                console.log('Error while establishing connection, retrying...');
                console.log(err);
                setTimeout(() => { this.startConnectionCore(); }, 5000);
            });
    }

    private registerOnServerEventsCore(): void {
        this.g.hubConnection.on('MessageReceived', (data: any) => {
            this.messageReceived.emit(data);
        });
    }

    private connectToSignalRCore() {
        this.createConnection();
        this.registerOnServerEventsCore();
        this.startConnectionCore();
    }

    private connectToSignalR() {
        this.createConnection();
    }

    private bindtoCentralHub() {
        this.gc.notificationConnection = $.connection;
        this.gc.notificationConnection.hub.url = environment.signalREndPoint;
        this.gc.notificationConnection.hub.start().done(() => {
            console.log('Connected');
        });
        this.gc.notificationConnection.received(function(m) {
            this._snackBar.open(JSON.stringify(m), '', { duration: 6000 });
            console.log(m);
            console.log('message received');
        });
    }

    private createConnectionCore() {
        this.g.hubConnection = new HubConnectionBuilder()
            .withUrl(environment.signalREndPoint)
            .build();
    }
    private endConnectionCore() {
        this.g.hubConnection.stop();
    }
    */
    // #endregion
}
