import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, Output, OnDestroy } from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';

import { ListingService } from '../listing.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ListingBaseComponent } from '../listing.base.component';
import { GlobalComponent } from '../../global.component';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-listing-services',
    templateUrl: './listingServices.component.html'
})
export class ListingServicesComponent extends ListingBaseComponent implements OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;
    offerId: number = 0;
    serviceID: number = 0;
    rfqs: any = [];
    services: any = [];
    @ViewChild('listingServiceTable') table;
    private _lcs: Subscription;
    constructor(ds: DataService, rs: Router, @Inject(GlobalComponent) @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingServices', ds, rs, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindServices();
        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }


    bindServices() {

        if (this.listingID && this.listingID > 0 && (this.currentListing == null || this.currentListing.services == null)) {
            let _response;
            const _s = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getServices(this.listingID);
            if (_s) {
                _s.subscribe(
                    (data) => _response = data
                    , (error) => {
                        this.onApiError(error);
                    }
                    , () => {
                        if (_response)
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.showError(_response.error);
                            else {
                                this.setCurrentListing(_response.data);
                                this.setvalues();
                            }
                    }
                );
            }
        } else
            this.setvalues();
    }

    removeService(iServiceID: number) {
        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).removeService(this.currentListing.id, iServiceID).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.services = _response.data.services.filter((_s) => { return _s.id !== iServiceID; });
                    }
            }
        );
    }

    private setvalues() {
        this.services = [...this.currentListing.services];
    }
}
