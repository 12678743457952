import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    FormControl,
    ValidatorFn,
    Validator, Validators,
    AbstractControl
} from '@angular/forms';
import { Directive } from '@angular/core';
@Directive({
    selector: '[passwordvalidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PasswordValidator,
            multi: true
        }
    ]
})
export class PasswordValidator implements Validator {
    validator: ValidatorFn;
    constructor() {
        this.validator = this.passwordValidator();
    }
    validate(control: AbstractControl) {
        return this.validator(control);
    }

    passwordValidator(): ValidatorFn {
        return (control: AbstractControl) => {

            let _v = '';
            if (control && control.value)
                _v = control.value.toString().trim();

            // One Upper, One Lower, One Number and One Special Character
            const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,15}$/;
            const isValid = regex.test(_v);
            if (isValid) {
                return null;
            } else {
                return {
                    passwordvalidator: {
                        valid: false
                    }
                };
            }
        };
    }

    private  validatePassword(p) {
        if (p.length < 8 || p.length > 15 || p.search(/[a-z]/i) < 0 || p.search(/[A-Z]/i) < 0 || p.search(/[0-9]/) < 0) {
            return false;
        }
        return true;
    }
}
