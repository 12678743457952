import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';

import { item, propertyType } from '../models/appCommon.models';
import { appEnvironment } from '../../environments/environment';
import { AddressComponent } from '../common/address.component';

import { ProductService } from '../common/service/product.service';
import { Role, Address, BaseResponse } from '../models';
import { OfferService } from '../offer/offer.service';
import { handover, AppURLs } from '../models/constants';
import { delay } from 'rxjs/operators';
import { Utils, rolePosition } from '../common/utils';
declare var $: any;

export class NewOfferListing {
    public email: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public sellerIsAgent: boolean = false;
    public propertyProductID: number = 0;
    public address: Address = new Address();
    public legalTermsVersionID: number = 0;
    public askingPrice: number = 0;
    public offeredPrice: number = 0;
    public buyerAgentsCommission: number = 0;
    public commission: number = 0;
}

@Component({
    selector: 'app-new-buyer-listing',
    templateUrl: './newBuyerListing.component.html'
})
export class BuyerNewListingComponent extends BaseComponent implements AfterViewInit {

    submitted: boolean = false;
    newOffer: NewOfferListing = new NewOfferListing();
    legalContent: string;
    tncAgreed: boolean = false;
    @ViewChild('listingAddress') ucListingAddress: AddressComponent;
    propertyTypes: item[] = [];
    isAgent: boolean = false;

    constructor(private dataservice: DataService, r: Router, @Inject(GlobalComponent) gc: GlobalComponent) {
        super('pgBuyerNewListing', r, gc);

        let _response: BaseResponse;
        (new ProductService(this.dataservice, this.route, this.myResourceCategory)).getListingProducts().subscribe(
            (data) => { _response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response && _response.data) {
                    this.propertyTypes = _response.data.map((pt) => Utils.castTo(pt, new propertyType()) );
                }
            }
        );

        this.newOffer.propertyProductID = appEnvironment.defaultPropertyProductID;
        // Server does not allow to use Agent Account to BUY/SELL for personal properties.
        // DISABLE/FORCE Change.
        this.isAgent = this.gc.hasRole(rolePosition.reAgent);

        this.gc.nowBusy.subscribe((busy: boolean) => {
            if (busy === true)
                $('#app-buyer-nl-createOffer').addClass('hide');
            else
                $('#app-buyer-nl-createOffer').removeClass('hide');
        });
    }

    get sellerComplete() { return (!Utils.isNullOrEmpty(this.newOffer.firstName) || !Utils.isNullOrEmpty(this.newOffer.lastName)) && !Utils.isNullOrEmpty(this.newOffer.email); }
    get saveReady(): boolean {
        return this.newOffer.propertyProductID > 0 && (this.ucListingAddress && this.ucListingAddress.IsComplete)
            && this.newOffer.askingPrice > 0 && this.newOffer.offeredPrice > 0 && this.sellerComplete && (this.newOffer.legalTermsVersionID > 0 ? this.tncAgreed : true);
    }

    ngAfterViewInit() {
        if (this.ucListingAddress) {
            this.ucListingAddress.addressChanged.subscribe((address: any) => {
                this.loadTerms(1000);
                if (address && address.address && address.address.IsPresent === true && address.address.IsComplete === true)
                    this.newOffer.address = address.address;
                else if (address && address.IsPresent === true && address.IsComplete === true)
                    this.newOffer.address = address;
            });
        }
    }


    propertyTypeChanged() {
        this.loadTerms();

        let _response: BaseResponse;
        (new ProductService(this.dataservice, this.route, this.myResourceCategory)).getProductDetails(this.newOffer.propertyProductID).subscribe(
            (data) => { _response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response && _response.data) {
                    if (_response.data.commission) this.newOffer.commission = _response.data.commission;
                }
            }
        );
    }

    createOffer() {
        this.submitted = true;
        this.gc.setBusy(true);
        this.newOffer.address = this.ucListingAddress.Address;

        // Server does not allow to use Agent Account to BUY/SELL for personal properties.
        // DISABLE/FORCE Change.
        this.newOffer.sellerIsAgent = this.gc.hasRole(rolePosition.reAgent);

        let _response: BaseResponse;
        (new OfferService(this.dataservice, this.route, this.myResourceCategory)).createOfferForNewListing(this.newOffer).subscribe(
            (data) => { _response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                this.gc.setBusy(false);
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error)) {
                        this.newOffer = new NewOfferListing();
                        this.showError('Failed Creating you offer. Please try again.' + _response.error);
                    } else if (_response.data) {
                        if (typeof _response.data === 'number' && _response.data > 0) {
                            this.GoToURLWithSession(AppURLs.offer, handover.offerID, _response.data.toString());
                        } else {
                            this.newOffer = new NewOfferListing();
                            this.showError('Failed Creating you offer. Please try again.');
                        }
                    }
            }
        );

    }

    private async loadTerms(iDelayedFor: number = 0) {
        this.legalContent = '';

        if (iDelayedFor > 0)
            await delay(iDelayedFor);

        let responseData: BaseResponse;

        let _isoCountryCode = this.ucListingAddress ? this.ucListingAddress.Address.ISOCountryCode : 0;
        if (_isoCountryCode <= 0) _isoCountryCode = appEnvironment.defaultISOCountryCode;

        (new ProductService(this.dataservice, this.route, this.myResourceCategory)).getLegalTerm(this.newOffer.propertyProductID, Role.Buyer, _isoCountryCode, this.ucListingAddress.Address.StateID).subscribe(
            (data) => { responseData = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (responseData && responseData.data) {
                    this.legalContent = responseData.data.content;
                    if (!Utils.isNullOrEmpty(this.legalContent))
                        this.newOffer.legalTermsVersionID = responseData.data.versionID;
                    else {
                        this.newOffer.legalTermsVersionID = -1;
                    }
                }
            }
        );
    }
}
