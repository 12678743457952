import { Component, Input, Output, EventEmitter, OnChanges, OnInit, ViewChild, AfterViewInit, forwardRef , Inject, SimpleChanges} from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NativeDateAdapter, VERSION } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatInputModule, MatInput } from '@angular/material/input';
import { Utils } from './utils';

export class AppDateAdapter extends NativeDateAdapter {

    private _minValue = moment('0001-01-01T00:00:00').toDate();
    format(date: Date, displayFormat: any): string {
        if (moment(date).isSame(this._minValue))
            return '';
        else {
            // if (displayFormat === 'DD/MM/YYYY') {
            //  const day = date.getDate();
            //  const month = date.getMonth() + 1;
            //  const year = date.getFullYear();
            //  return `${day}-${month}-${year}`;
            // }
            // else if (displayFormat === 'MM/DD/YYYY') {
            //  const day = date.getDate();
            //  const month = date.getMonth() + 1;
            //  const year = date.getFullYear();
            //  return `${month}-${day}-${year}`;
            // }
            // else {
            return moment(date).format(displayFormat.toString());
            //  return date.toDateString();
            // }
        }
    }
}

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const NONUS_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const US_FORMATS = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function providerDateFormat() {
    let _configurations = sessionStorage.getItem('env');
    let _format = '';
    if (_configurations) {
        _configurations = JSON.parse(_configurations);
        if (_configurations) {
            if (_configurations['defaultDateFormat'] && _configurations['defaultDateFormat'].length > 0) {
                _format = _configurations['defaultDateFormat'];
            }
        }
    }
    const _sessionDetails = JSON.parse(sessionStorage.getItem('sessionDetails'));
    if (_sessionDetails != null) {
        if (_sessionDetails.dateFormat != null)
            _format = _sessionDetails.dateFormat;
    }
    if (_format && _format.toLowerCase().startsWith('dd')) {
        return NONUS_FORMATS;
    } else
        return US_FORMATS;
}

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    providers: [DatePipe,
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
        , { provide: MAT_DATE_FORMATS, useFactory: providerDateFormat }
        // { provide: DateAdapter, useClass: AppDateAdapter },
        // {
        //  provide: MAT_DATE_LOCALE, useFactory: () => {
        //    let _user: Security.LISAUser = new Security.LISAUser(<Security.ILISAUser>JSON.parse(sessionStorage.getItem('sessionDetails')));
        //    return _user.countryLocale;
        //  }
        // },

        // {
        //    provide: MAT_DATE_FORMATS, useFactory: () => {
        //        let _format = environment.defaultDateFormat;
        //        let _sessionDetails = JSON.parse(sessionStorage.getItem('sessionDetails'));
        //        if (_sessionDetails != null) {
        //            if (_sessionDetails.dateFormat != null)
        //                _format = _sessionDetails.dateFormat;
        //        }
        //        if (_format && _format.toLowerCase().startsWith('dd')) {
        //            return NONUS_FORMATS;
        //        }
        //        else
        //            return US_FORMATS;
        //    }

        // },
    ]
})
export class DatePickerComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {

    version = VERSION;
    dateForm: UntypedFormGroup;
    isUSFormat: boolean = true;
    @Input() placeholderHelp: string = '';
    required = false;
    @Input() cssClass: string = '';
    @Input() style: string = '120px';
    @Input() styles: any = {};
    @Input() defaultToday: boolean = false;

    @Input() offset: number = 0;
    @Output() valueChanged: EventEmitter<Date> = new EventEmitter<Date>();

    @Input() placeholder: string = '';
    @Input() id: string = 'ngDatePicker';
    @Input() minDate: Date = this.Utils.dateTimeMinValue;
    @Input() maxDate: Date = this.Utils.dateTimeMaxValue;
    @Input() disabled: boolean = false;
    @Input() disabledEmptyText: string = '';

    @ViewChild('dtInput') txtInput: MatInput;

    // For Use in Template
    _value: Date = this.Utils.dateTimeMinValue;
    constructor(private dateAdapter: DateAdapter<Date>, public datepipe: DatePipe
        , @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder, router: Router, @Inject(GlobalComponent) gc: GlobalComponent
        ) {
        super('', null, gc);
        this.dateAdapter.setLocale(this.getUsersLocale());
    }
    @Input() @Output() get value(): Date { return this._value; }
    set value(v: Date) {
        this.setValue(v);
    }

    ngOnInit() { this.InitToday(); }
    ngAfterViewInit() {
        this.dateAdapter.setLocale(this.getUsersLocale());
        this.setValue(this._value);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
    }

    onDateChange(event: MatDatepickerInputEvent<Date>) {
        this.setValue(event.value);
        this.valueChanged.emit(this._value);
    }

    clear() { this.setValue(this.Utils.dateTimeMinValue); }

    private InitToday() {
        if (this.defaultToday === true) {
            this.setValue(new Date());
        } else if (this.offset != null && this.offset !== 0) {
            this.setValue(moment().add(this.offset, 'days').toDate());
        }
    }

    private setValue(v) {
        const _prevValue = this._value;

        if (v != null) {

            let _v = null;
            if (v.toString().indexOf('T') >= 0)
                _v = moment(v);
            else
                _v = moment(v, this.dateFormat);
            if (!_v.isValid() || _v.isSame(this.Utils.dateTimeMinValue)) {
                this._value = this.Utils.dateTimeMinValue;
            } else {
                this._value = _v.toDate();
            }

            if (this.minDate != null)
                if (this._value && moment(this.value).isBefore(this.minDate)) {
                    setTimeout(() => {
                        this._value = _prevValue; this.clearIfMin();
                    }, 100);
                    return;
                }

            if (this.maxDate != null)
                if (this._value && moment(this.value).isAfter(this.maxDate)) {
                    setTimeout(() => {
                        this._value = _prevValue;
                        this.clearIfMin();
                    }, 100);
                    return;
                }
        } else {
            this._value = this.Utils.dateTimeMinValue;
        }

        this.clearIfMin();

    }

    private clearIfMin() {
        if (moment(this.value).isSame(this.Utils.dateTimeMinValue)) {
            this._value = null;
            if (this.txtInput) {
                this.txtInput.value = '';
            }
            if (this.disabled === true) {
                this.txtInput.value = this.disabledEmptyText;
            }
        }

    }
}
