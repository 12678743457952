<mat-card *ngIf="documents && documents.length > 0">
    <mat-card-title *ngIf="showTitle && allowAdd">
        <span *ngIf="showTitle">{{Title}}</span>
    </mat-card-title>
    <mat-progress-bar mode="buffer" *ngIf="componentBusy === true"></mat-progress-bar>
    <ng-template [ngIf]="(this.documents && this.documents.length > 0)" [ngIfElse]="noDocs">
        <div class="section {{cssClass}}" *ngFor="let doc of documents  | paginate: { itemsPerPage: docsOnPage , currentPage: docsPageNumber }; ">
            <div class="app-flex-container app-2col no-padd" >
                <div *ngIf="doc.canRead">
                    <label class="caption auto">Name/Description >>> &nbsp;</label>
                    <app-view-document-link [documentID]="doc.id" [label]="doc.name"></app-view-document-link>
                </div>
                <div>
                    <span title="{{doc.readers}}" *ngIf="doc.readers | isNotNullOrEmpty">Reader(s)</span> 
                    <span title="{{doc.editors}}" *ngIf="doc.editors | isNotNullOrEmpty">Editor(s)</span> 
                    <span title="{{doc.owners}}" *ngIf="doc.owners | isNotNullOrEmpty">Owner(s)</span>
                    <a (click)="setPermissions(doc.id)" *ngIf="doc.isOwner === true" title="Change/Set Permissions"><i class="material-icons">people</i></a>
                </div>
            </div>
            <div *ngIf="doc.isOwner">
                <div><span><label class="bold">{{'System.CurrentStatus' | translateTag}}</label> - {{doc.statusName}}&nbsp;</span></div>
                <app-next-status-actions [actions]="doc.nextSteps" (itemSelected)="changeStatus($event)"></app-next-status-actions>
            </div>
        </div>
        <div style="text-align:center" *ngIf="documents && documents.length > 0 && documents.length > docsOnPage ">
            <hr />
            <pagination-controls (pageChange)="docsPageNumber = $event"></pagination-controls>
        </div>
    </ng-template>
    <ng-template #noDocs>
        <h4>{{noDocumentMessage}}</h4>
    </ng-template>
</mat-card>
<mat-card *ngIf="allowAdd && canAdd && entityPartners && entityPartners.length > 0">
    <hr style="background-color:blue"/>
    <mat-card-title>{{lblNewDocumentTitle}}</mat-card-title>
    <div class="app-flex-container app-2col">
        <mat-form-field>
            <mat-label class="placeholder">Document Name</mat-label>
            <input matInput appDisableAuStofill [(ngModel)]="newDocumentName" maxlength="100" />
        </mat-form-field>
        <mat-checkbox [(ngModel)]="parentNotification" *ngIf="notifyParent">{{notifyParentLabel}}</mat-checkbox>
    </div>
    <div class="input-group" style="width:75%">
        <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupFileAddon01">Upload<i class="fa fa-question-circle-o warning-color" title="you can submit/upload a new document/Report here"></i></span>
        </div>
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="inputGroupFile01"
                   aria-describedby="inputGroupFileAddon01"
                   #newDoc (change)="fileUploaded($event)" accept=".pdf,application/pdf">
            <label class="custom-file-label" for="inputGroupFile01">{{this.doc ? this.doc.name + ' [Change' : '[Choose'}} file]</label>
        </div>
    </div>
    <mat-progress-bar mode="buffer" *ngIf="addingDocument"></mat-progress-bar>
    <button class="btn green inline-block" (click)="addDoc()" *ngIf="readyToAdd && !addingDocument">Add</button>
</mat-card>
