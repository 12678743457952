import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[hideMeOnMyPage]'
})
export class HideMeOnMyPageDirective {
    @Input() myPage: string = '';
    constructor(el: ElementRef) {
        if (this.myPage == null) this.myPage = '';
        if (typeof this.myPage === 'string') {
            if (window.location.href === this.myPage.toLowerCase())
                el.nativeElement.style.display = 'none';
        }
    }
}
