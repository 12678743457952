<div *ngIf="showHeader">
    <h2 class="header">View/Edit Listing</h2>
    <mat-form-field *ngIf="listings && listings.length > 0">
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<mat-card>
    <div class="app-flex-container app-3col" *ngIf="currentListing">
        <mat-form-field>
            <mat-label class="placeholder">Property Type<i class="fa fa-question-circle-o warning-color" title="Property Type"></i></mat-label>
            <mat-select [(ngModel)]="currentListing.propertyTypeID" [disabled]="true">
                <mat-option *ngFor="let pt of propertyTypes" [value]="pt.id">{{pt.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <app-datepicker #txtExpiryDate id="txtExpiryDate" [value]="currentListing.expiryDate" [placeholder]="'Expiry Date'" [disabled]="true"
                        [disabledEmptyText]="'N/A'"></app-datepicker>
        <mat-checkbox [(ngModel)]="currentListing.acceptingOffers" [disabled]="this.currentListing.editableProperties['acceptingOffers'] != null" (change)="onListingChange()">Currently accepting Offers</mat-checkbox>
    </div>
    <mat-form-field *ngIf="currentListing">
        <mat-label class="placeholder">Property Description (in a sentence or two) <i class="fa fa-question-circle-o warning-color" title="Property Description/Summary"></i></mat-label>
        <textarea matInput appDisableAutofill maxlength="1000" [(ngModel)]="currentListing.description" 
                  rows="3" [disabled]="this.currentListing.editableProperties['description'] != null" 
                  (change)="onListingChange()"></textarea>
    </mat-form-field>
    <mat-expansion-panel [expanded]="currentListing.address == null || !currentListing.address.IsComplete">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Address
            </mat-panel-title>
            <mat-panel-description style="display:initial" *ngIf="currentListing">
                <label class="float-right">{{currentListing.address.FullStreetAddress}}</label>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="section">
            <app-base-address #propertyAddress (addressChanged)="addressChanged($event)" [canChangeAddressType]="false" [showOwnerShip]="false" [required]="false" [disabled]="this.currentListing.editableProperties['address'] != null"></app-base-address>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Pricing
            </mat-panel-title>
            <mat-panel-description style="display:initial" *ngIf="currentListing">
                <label class="float-right">{{FormatNumberToCurrency(currentListing.currentPrice)}}</label>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="section">
            <div class="app-flex-container app-2col section" *ngIf="currentListing">
                <mat-form-field>
                    <mat-label class="placeholder">Current Price<i class="fa fa-question-circle-o warning-color" title="Current Selling Price"></i></mat-label>
                    <input matInput appDisableAutofill type="number" class="numeric" [(ngModel)]="currentListing.currentPrice" (keydown)="utils.numOnlyLimitingLength($event, 15, false)" (change)="onListingChange()" [disabled]="this.currentListing.editableProperties['currentPrice'] != null" selectOnFocus />
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="placeholder">Minimum Deposit<i class="fa fa-question-circle-o warning-color" title="Minimum Deposit required by Seller when submitting Offer"></i></mat-label>
                    <input matInput appDisableAutofill type="number" class="numeric" [(ngModel)]="currentListing.minimumDeposit" (keydown)="utils.numOnlyLimitingLength($event, 12, false)" [disabled]="this.currentListing.editableProperties['minimumDeposit'] != null"
                           (change)="onListingChange()" selectOnFocus />
                </mat-form-field>
            </div>
            <div *ngIf="!currentListing.sellerHasAgent">
                <span class="bold">
                    At {{myBRAND}}, You decide the selling price of your property, what you want it to be. To decide on fair value,
                    <a (click)="showValue();" class="link bold"> click here </a>. Get list of properties for sale in your neighborhood, <a (click)="showComps();" class="link bold"> click here </a>.
                </span>
                <br /><br />
            </div>
            <div *ngIf="currentListing" class="app-flex-container app-2col">
                <mat-form-field class="first">
                    <mat-label class="placeholder">Credit offered to Buyers on Closing<i class="fa fa-question-circle-o warning-color" title="Credit offered to Buyers on Closing"></i></mat-label>
                    <input matInput appDisableAutofill type="number" class="numeric" [(ngModel)]="currentListing.creditOfferedBySeller" (keydown)="utils.numOnlyLimitingLength($event, 12, false)"
                           (change)="onListingChange()" [disabled]="this.currentListing.editableProperties['creditOfferedBySeller'] != null" selectOnFocus />
                </mat-form-field>
                <mat-form-field class="first">
                    <mat-label class="placeholder">Credit offered to Buyers on Closing (if Agent involved)<i class="fa fa-question-circle-o warning-color" title="Credit offered to Buyers on Closing, when buyer is working with an Agent."></i></mat-label>
                    <input matInput appDisableAutofill type="number" class="numeric" [(ngModel)]="currentListing.creditOfferedBySellerWithAgent" (keydown)="utils.numOnlyLimitingLength($event, 12, false)"
                           (change)="onListingChange()" [disabled]="this.currentListing.editableProperties['creditOfferedBySellerWithAgent'] != null" selectOnFocus />
                </mat-form-field>
            </div>
            <div>
                <span class="bold">You can motivate prospective buyers, compete with other Seller's in your neighborhood, and Offer some money to lower Buyer's closing costs.</span>
            </div>
            <div>
                <mat-form-field class="first">
                    <mat-label class="placeholder">Buyer's Agent Commission<i class="fa fa-question-circle-o warning-color" title="Buyer's Agent's Commission, if Buyer is represented by an Agent"></i></mat-label>
                    <input matInput appDisableAutofill type="number" class="numeric" [(ngModel)]="currentListing.buyersAgentCommission" (keydown)="utils.numOnlyLimitingLength($event, 12, false)" [disabled]="this.currentListing.editableProperties['buyersAgentCommission'] != null"
                           (change)="onListingChange()" selectOnFocus />
                </mat-form-field>
                <div>
                    <span class="bold">Some buyer's might already be working with an agent, To motivate Buyer's agent you can offer some commission.</span>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <h3>Occupancy</h3>
    <mat-checkbox [(ngModel)]="currentListing.occupied" [disabled]="this.currentListing.editableProperties['occupied'] != null" (change)="onListingChange()">Currently occupied</mat-checkbox>
    <mat-checkbox [(ngModel)]="currentListing.occupiedByTenants" [disabled]="this.currentListing.editableProperties['occupiedByTenants'] != null" (change)="onListingChange()">Currently occupied by Tenants</mat-checkbox>
    <!--<br />
    <mat-checkbox [(ngModel)]="currentListing.updateFacebook"  [disabled]="this.currentListing.editableProperties['updateFacebook'] != null" (change)="onListingChange()">Update Facebook</mat-checkbox>-->
</mat-card>