import { RecordTimeStamp, } from './base.model';
import { nextAction } from './appCommon.models';
import { ServiceType } from './services/product.model';
import { ToBePaidBy, ServiceWorkOrderStatus, Role } from './enums.component';
import { Address } from './address.model';
import { Utils } from '../common/utils';

export class WorkOrderSaveModel extends RecordTimeStamp {
    public id: number = 0;
    public cost: number = 0;
    public promisedCompletionDate: Date = Utils._minDate;
    public providerReference: string = '';
    public operatorID: number = 0;

    constructor() { super(); }
}

export class WorkOrder extends WorkOrderSaveModel {

    public static actionSaveCommand: string = 'save';
    public static actionAddDependency: string = 'addDependency';
    public static actionAddContingency: string = 'addContingency';
    public static actionAddDemand: string = 'addDemand';
    public static actionAddCredit: string = 'addCredit';
    public static actionServiceVisit: string = ServiceWorkOrderStatus.NewServiceVisit.toString();
    public static actionAddService: string = 'addService';
    public static actionViewDoc: string = 'viewDoc';
    public static actionSubmitReview: string = 'submitReview';
    public static actionRepairs: string = 'repairs';

    public listingID: number = 0;
    public serviceID: number = 0;
    public escrowID: number = 0;
    public typeID: number = 0;
    public type: ServiceType = new ServiceType();
    public propertyTypeID: number = 0;
    public priceBasis: number = 0;

    public _address: Address = new Address();

    public serviceProviderID: number = 0;
    public serviceProvider: string = '';
    public operatorName: string = '';
    public closingDate: string = ''; // Has string value with other message
    public amountPaid: number = 0;
    public amountDemanded: number = 0;
    public amountDue: number = 0;
    public buyers: string = '';
    public sellers: string = '';
    public myRole: Role = Role.NotSet;
    public toBePaidBy: ToBePaidBy = ToBePaidBy.NotSet;

    public toBePaidByPIDs: number[] = new Array<number>();

    public currentStatus: ServiceWorkOrderStatus = ServiceWorkOrderStatus.None;
    public currentStatusDate: Date = Utils._minDate;
    public statusName: string = '';

    public offerID: number = 0;
    public escrowOfficerID: number = 0;
    public canSendMessageToEscrow: boolean = false;
    public isCurrentUserFromEscrow: boolean = false;
    public nextSteps: nextAction[] = new Array<nextAction>();
    public terms: any = [];
    public dependencies: any = [];
    public canUpdate: boolean = false;
    public canViewPurchaseAgreement: boolean = false;
    public canAddContingency: boolean = false;
    public canDemandFromEscrow: boolean = false;
    public canOrderAdditionalService: boolean = false;
    public canSubmitReview: boolean = false;
    public canAddCredit: boolean = false;
    public canAddDependency: boolean = false;
    public canAssumeLiability: boolean = false;
    public canChangeServiceProvider: boolean = false;
    public canCreateRepairWorkOrder: boolean = false;
    public canRemoveDependency: boolean = false;
    public canRequestAccess: boolean = false;
    public canRequestForQuote: boolean = false;
    public canSendMessageToSP: boolean = false;
    public canSubmitReport: boolean = false;
    public repairsNotified: boolean = false;
    public canAcceptTerms: boolean = false;
    public canOrderNewPrivateWO: boolean = false;

    // Just for UI Grid
    public expanded: boolean = false;

    public documents: any[] = [];
    public bids: number[] = [];
    public rfqs: number[] = [];

    public options: string = '';

    constructor() { super(); }

    get address(): Address { return this._address; }
    set address(v: Address) { this._address = (new Address()).castToMe(v); }

    public get emailSubject(): string { return 'Message regarding ' + this.typeDescription + ' for ' + this.address.FullAddress; }
    public get typeDescription() { return this.type.description; }
    public get paidBy(): string {
        return ToBePaidBy[this.toBePaidBy];
    }
}

export const includeFirstItems = [4, 5, 9];  // Assigned, WIP, Accepted
export const excludeFirstItems = [0, 1, 2, 3, 6, 13];  // None, Created, Revoked, Declined
export const includeNextItems = [];
export const excludeNextItems = [0, 1, 2, 3, 4, 5, 6, 9, 13];  // None, Created, Assigned, WIP, Revoked, Accepted, Declined
export const includeClosedItems = [10, 12]; // Closed, Work Completed
