import { RecordTimeStamp } from '../base.model';
import { Utils } from '../../common/utils';

export class InsuranceEntity extends RecordTimeStamp {
    private _insuranceTypeID: number = 0;
    private _policyAmount: number = 0;
    private _expiryDate: Date = Utils._minDate;
    private _issuingCompany: string = ''; private _issuingCompanyPhone: string = '';
    private _policyID: string = ''; private _insuranceType: string = '';

    constructor() {
        super();
        this.id = this._insuranceTypeID = this._policyAmount = 0;
        this._issuingCompany = this._issuingCompanyPhone = this._policyID = '';
        this._expiryDate = Utils._minDate;
    }

    public get ServiceProviderInsuranceID(): number { return this.id; }
    public set ServiceProviderInsuranceID(v: number) { if (v == null) v = 0; this.id = v; }

    public get InsuranceTypeID(): number { return this._insuranceTypeID; }
    public set InsuranceTypeID(v: number) { if (v == null) v = 0; this._insuranceTypeID = v; }

    public get InsuranceType(): string { return this._insuranceType; }
    public set InsuranceType(v: string) { if (v == null) v = ''; this._insuranceType = v; }

    public get PolicyAmount(): number { return this._policyAmount; }
    public set PolicyAmount(v: number) { if (v == null) v = 0; this._policyAmount = v; }

    public get IssuingCompany(): string { return this._issuingCompany; }
    public set IssuingCompany(v: string) { if (v == null) v = ''; this._issuingCompany = v; }

    public get IssuingCompanyPhone(): string { return this._issuingCompanyPhone; }
    public set IssuingCompanyPhone(v: string) { if (v == null) v = ''; this._issuingCompanyPhone = v; }

    public get PolicyID(): string { return this._policyID; }
    public set PolicyID(v: string) { if (v == null) v = ''; this._policyID = v; }

    public get ExpiryDate(): Date { return this._expiryDate; }
    public set ExpiryDate(v: Date) { if (v == null) v = Utils._minDate; this._expiryDate = v; }

    public IsComplete(): boolean {
        return this.InsuranceTypeID > 0 && !Utils.isNullOrEmpty(this.IssuingCompany) && !Utils.isNullOrEmpty(this.PolicyID) && this.PolicyAmount > 0;
    }

    toJSON() {
        return {
            ServiceProviderInsuranceID: this.id,
            InsuranceTypeID: this._insuranceTypeID,
            PolicyID: this._policyID,
            PolicyAmount: this._policyAmount,
            IssuingCompany: this._issuingCompany,
            IssuingCompanyPhone: this._issuingCompanyPhone,
            ExpiryDate : this._expiryDate
        };
    }
}
