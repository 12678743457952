import { Component, ViewChild, Inject, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { item, GeographicEntityLevel, lien, LienType } from '../../models';

import { ListingService } from '../listing.service';

import { DatePickerComponent } from '../../common/datepicker.component';

import { ServiceProviderProfileComponent } from '../../sp/profile.component';
import { BaseDialog } from '../../base.dialog';
import { appEnvironment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-add-seller-lien-dialog',
    templateUrl: './addSellerLien.dialog.html'
})
export class AddSellerLienDialog extends BaseDialog implements OnInit, AfterViewInit, OnDestroy {

    LienType: typeof LienType = LienType;

    @ViewChild('spProfile') spProfile: ServiceProviderProfileComponent;
    @ViewChild('txtLienDate') txtLienDate: DatePickerComponent;
    entityID: number;
    geographicLevel: any = [];

    newLien: lien = new lien();
    lienType: LienType = LienType.Loan;
    listingID: number = 0;

    private _sps: Subscription;

    constructor(dataservice: DataService, r: Router, @Inject(GlobalComponent) gc: GlobalComponent
        , dialogRef: MatDialogRef<AddSellerLienDialog>, @Inject(MAT_DIALOG_DATA) data: any) {
        super('addSellerDialog', dataservice, r, dialogRef, data);

    }

    get saveReady() {
        return this.newLien.amount > 0 && (this.spProfile.serviceProvider.ServiceProviderID > 0 || !Utils.isNullOrEmpty(this.spProfile.serviceProvider.Name))
            && !Utils.isNullOrEmpty(this.newLien.referenceNumber);
    }

    ngOnDestroy() {
        if (this._sps != null) this._sps.unsubscribe();
    }
    ngOnInit() {
        if (this.data) {
            if (this.data.id) this.listingID = this.data.id;
            if (this.data.header) this.dialogHeader = this.data.header;
        }

        this.geographicLevel.push(new item(GeographicEntityLevel.Country, 'Country/Federal'));
        this.geographicLevel.push(new item(GeographicEntityLevel.State, 'State'));
        this.geographicLevel.push(new item(GeographicEntityLevel.City, 'City'));
        this.geographicLevel.push(new item(GeographicEntityLevel.County, 'County'));

        if (this.listingID === 0)
            setTimeout(() => { this.showError('Invalid or missing Listing ID'); }, appEnvironment.delayedNGInit);
    }

    ngAfterViewInit() {
        if (this._sps == null && this.spProfile != null) {
            this._sps = this.spProfile.profileSaved.subscribe((id) => {
                this.saveLien(id);
            });
        }
    }

    lienTypeChanged() {
        console.log(this.lienType);
    }

    onSubmit() {

        this.busy = true;

        if (this.spProfile.serviceProvider.id > 0) {
            this.saveLien(this.spProfile.serviceProvider.id);
        } else {
            // SAVE Entity before Saving Lien, Wait for Save Event to respond.
            if (this.spProfile && this.spProfile.serviceProvider.id === 0) {
                if (this._sps == null) {
                    this._sps = this.spProfile.profileSaved.subscribe((id) => {
                        this.saveLien(id);
                    });
                }
                const _v = this.spProfile.saveProfile();
                if (_v === false)
                    this.busy = false;
            }
        }
    }

    private saveLien(id: number) {

        if (id > 0) {
            this.newLien.lenderID = id;
            this.newLien.lienDate = this.txtLienDate.value;
            this.newLien.type = this.lienType;

            let _response;
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).addSellerLien(this.listingID, this.newLien).subscribe(
                (data) => _response = data
                , (error) => { this.busy = false; this.onApiError(error); }
                , () => {
                    this.busy = false;
                    if (_response != null) {
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.error = _response.error;
                        else {
                            if (_response.data)
                                this.close(_response.data);
                            else
                                this.close(null);
                        }
                    }
                }
            );
        } else {
            this.error = 'Failed to save/get Lender information';
        }
    }
}
