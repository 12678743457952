import { Component, OnInit, Input, Output , ViewChild, ChangeDetectorRef, AfterViewChecked, Inject, OnDestroy} from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';

import { downloadableContent, BaseResponse, Role } from '../models';

import { ListingService } from '../listing/listing.service';
import { HTMLDialog, MessageDialog } from '../common/dialogs';
import { OfferService } from '../offer/offer.service';
import { ListingBaseComponent } from './listing.base.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../common/utils';
@Component({
    selector: 'app-listing-offers',
    templateUrl: './listingOffers.component.html'
})
export class ListingOffersComponent extends ListingBaseComponent implements OnInit, OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;
    offerListings: any = [];
    offers: any = [];
    private _lcs: Subscription;
    constructor(ds: DataService, rs: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog
        , private currentRoute: ActivatedRoute) {
        super('listingOffers', ds, rs, g);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.getOffers();
        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngOnInit() {

        let _id = 0;
        if (this.currentRoute.snapshot.queryParams && this.currentRoute.snapshot.queryParams.l)
            _id = parseInt(this.currentRoute.snapshot.queryParams.l.toString());

        if (_id === 0) {
            _id = parseInt(sessionStorage.getItem(this.handover.listingID));
            if (!isNaN(_id) && _id > 0)
                super.setListingID(_id);
        }

        this.getOffers();

        const _me = this;
        this.getMyListingsForSale(this.dataservice, (listings) => {
            this.offerListings = listings;
            if (this.offerListings && this.offerListings.length > 0) {
                let _f = null;
                if (_me.listingID > 0) // Check if curreent listing id is not in properties for sale list
                    _f = this.offerListings.filter((l) => l.id === this.listingID);
                if (_me.listingID > 0 &&  _f == null)
                    super.setListingID(0);
            }
        });
    }

    setListingID() {
        super.setListingID(this.listingID);
        this.getOffers();
    }

    getOffers() {
        if (this.listingID === 0) {
            this.initMe();
        }

        if (this.listingID === 0 || this.currentListing && this.currentListing.id === this.listingID) {
            if (this.listingID === 0)
                this.offers = [];
            return;
        }

        this.offers = [];
        this.componentBusy = true;
        let _response: BaseResponse;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).offerDetails(this.listingID, Role.Seller).subscribe(
            (data) => { this.componentBusy = false; _response = data; }
            , (error) => { this.componentBusy = true; this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (_response.data) {
                        this.offers = _response.data.offers.sort((a, b) => moment(a.lastUpdateDate).isAfter(b.lastUpdateDate) ? 1 : -1); // Descending  -- For Ascending  -1 : 1
                    }
            }
        );
    }

    sendMsgToOtherParty(sRoleName: string, sRecepients: string, sAddress: string) {
        const dialogRef = this.dialog.open(MessageDialog, {
            data: {
                recepients: sRecepients
                , subject: Utils.isNullOrEmpty(sAddress) ? '' : ('Offer for ' + sAddress)
                , heading: 'Send Message to ' + sRoleName
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.showMessage('Your message has been sent to ' + sRoleName);
            }
        });
    }

    showOfferMessage(iOfferID: number) {
        let _response;
        (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getOfferMessage(iOfferID).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (!Utils.isNullOrEmpty(_response.data)) {
                        this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                    } else
                        this.showMessage('Offer is ready for submission');
            }
        );
    }
    showPurchaseAgreement(iOfferID: number) {
        let _response;
        (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getPurchaseAgreement(iOfferID).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                    }
            }
        );
    }

    showProofOfFunds(iOfferID: number) {
        let _response;
        (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getProofOfFunds(iOfferID).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        const _doc: downloadableContent = _response.data;
                        this.OpenDownloadableDoc(_doc.content, 'proofOfFunds', _doc.mime);
                    }
            }
        );

    }

    changeStatus(action$, iOfferID: number) {
        this.gc.setBusy(true);
        let _actionResponse: BaseResponse;
        const _id = parseInt(action$.argument);
        (new OfferService(this.dataservice, this.route, this.myResourceCategory)).changeStatus(_id, action$.name).subscribe(
            (data) => { _actionResponse = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                this.gc.setBusy(false);
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                        this.showMessage(_actionResponse.message);

                        if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                            this.gotoURL(_actionResponse.redirectURL);
                        else {
                            this.offers = cloneDeep(this.offers.filter(wo => { return (wo.id !== _id); }));

                            // Update other offers Status, force user to refresh list to get updated values.
                            this.offers.each((o) => {
                                o.statusName = 'Unknown - Get offers for this Listing again, by selecting different property or leaving the page and re-entering';
                                o.currentStatus = 0;
                                o.nextSteps = [];
                            });

                            if (_actionResponse.data)
                                this.offers.splice(0, 0, _actionResponse.data);

                            this.offers = [...this.offers];
                        }
                    }
                }
            }
        );
    }
}
