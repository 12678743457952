import { RecordTimeStamp } from '../base.model';
import { Role, DocumentStatus } from '../enums.component';
import { item } from '../appCommon.models';
import { Address } from '../address.model';
import * as moment from 'moment';
import { Utils } from '../../common/utils';

export class lien extends RecordTimeStamp {

    public id: number = 0;
    public listingID: number = 0;
    public escrowID: number = 0;
    public isLender: boolean = false;
    public isApplicant: boolean = false;

    public applicantNames: string = '';
    public applicantIDs: number[] = [];

    public entityLevel: number = 0;
    public lienDate: Date = Utils._minDate;

    public amount: number = 0;
    public approvedAmount: number = 0;
    public propertyID: number = 0;
    public address: Address = new Address();

    public downPayment: number = 0;
    public downPaymentSource: FundsSourceType = FundsSourceType.Other;
    public requiredDownPayment: number = 0;

    public referenceNumber: string = '';
    public lenderID: number = 0;
    public lenderName: string = '';

    public type: LienType = LienType.NotSet;
    public fundingDate: Date = Utils._minDate;

    public statusName: string = '';
    public currentStatus: LienStatus = LienStatus.Notset;
    public currentStatusDate: Date = Utils._minDate;

    public nextSteps: any = [];
    public canAcceptFundingConditions: boolean = false;

    public processorPID: number = 0;
    public name: string = '';

    public isApplicationIncomplete: boolean = false;
    public canEdit: boolean = false;
    public canSubmit: boolean = false;
    public submitConfirmationMessage: string = '';
    public canPreApprove: boolean = false;
    public canApprove: boolean = false;

    public canSubmitToEscrow: boolean = false;
    public canRemoveFromEscrow: boolean = false;

    public approvalConfirmationMessage: string = '';
    public certifiedByCurrentApplicant: boolean = false;

    public applicationType: LoanApplicationLoanType = LoanApplicationLoanType.NotSet;
    public propertyUse: LoanPropertyUse = LoanPropertyUse.PrimaryResidence;

    public duration: number = 0;
    public approvedDuration: number = 0;
    public priority: LienPriorityType = LienPriorityType.First;

    public canChangeLoanDuration: boolean = false;
    public canLockRate: boolean = false;
    public canRequestRateLock: boolean = false;
    public lockRequestSent: boolean = false;
    public canAgreeToRateLock: boolean = false;
    public rateLocked: boolean = false;
    public wasRateEverLocked: boolean = false;
    public rateLockDuration: number = 0;
    public rateLockDetails: string = '';
    public canSendFundingNotification: boolean = false;
    public readyForFunding: boolean = false;

    public loanMessage: string = '';

    public escrowNumber: string = '';
    public escrowCompany: string = '';
    public escrowContact: string = '';
    public escrowContactID: number = 0;

    public applicationChangedAfterLastSubmission: boolean = false;
    public staff: any = [];

    public supportDocuments: any = [];
    public applicants: any = [];
    public employment: any = [];
    public incomes: any = [];
    public assets: any = [];
    public expenses: expense[] = [];
    public liabilities: any = [];
    public declarations: any = [];
    public governmentInfo: any = [];
    public certifications: any = [];
    public fundingContigencies: any = [];

    constructor() {
        super();
    }

    public get description(): string {
        if (this.address && !Utils.isNullOrEmpty(this.address.FullAddress))
            return this.address.FullAddress;
        else
            return 'Loan Application - ' + this.id;
    }
    public lienNumber(): string { return this.referenceNumber }
}

export enum lienDocumentType {
    Disclosure = 1,
    SupportDocument = 2,
    EscrowInstructions = 3,
    TnC = 4
}

export enum LoanSupportDocumentStatus {
    None = DocumentStatus.None,
    Requested = DocumentStatus.Created,
    Submitted = DocumentStatus.Submitted,
    Accepted = DocumentStatus.Accepted,
    Rejected = DocumentStatus.Rejected,
    Revoked = DocumentStatus.Revoked
}

export class supportDocument {
    document: any = {};
    instructions: any = {};
    type: lienDocumentType = lienDocumentType.Disclosure;
    status: LoanSupportDocumentStatus = LoanSupportDocumentStatus.None;
}

export enum LienType {
    NotSet = 0,
    Judgement = 1,
    Loan = 2,
    Tax = 3
}
export enum FinancingStatus {
    Unknown = 0,
    PreApproved = 3,
    Approved = 4
}
export enum LienStatus {
    Notset = FinancingStatus.Unknown,
    Created = 1,
    Submitted = 2,
    PreApproved = FinancingStatus.PreApproved,
    Approved = FinancingStatus.Approved,
    Revoked = 5,
    Declined = 6,
    Funded = 7,
    LienAttached = 8,
    PaidOff = 9,
    PseudoRateLocked = -1,
    PseudoLinkToEscrow = -2,
    PseudoRemovedFromEscrow = -3,
    ResetConditionsAcceptance = -9,
    AcceptAllConditions = -10,
    LockRequested = -11
}

export enum LoanApplicationLoanType {
    NotSet = 0,
    VeteranAdministration = 1,
    FederalHousingAuthority = 2,
    Conventional = 3,
    USDA = 4
}

export enum LoanPropertyUse {
    PrimaryResidence = 1,
    SecondaryResidence = 2,
    Investment = 3
}

export enum LienPriorityType {
    First = 1,
    Second = 2,
    Third = 3,
    Fourth = 4
}


export class employment extends RecordTimeStamp {
    id: number = 0;
    pid: number = 0;
    title: string = '';
    name: string = '';
    employerName: string = '';
    address: Address = new Address();
    phoneNumber: string = '';
    monthlyIncome: number = 0;
    startDate: Date = Utils._minDate;
    endDate: Date = Utils._minDate;

    selfEmployed: boolean = false;
    canRemove: boolean = false;
    constructor() {
        super();
    }
    get durationInMonths(): number {
        if (this.startDate && this.endDate) {
            if (moment(this.startDate).isBefore(this.endDate))
                return moment(this.endDate).diff(moment(this.startDate), 'months');
            else if (moment(this.startDate).isBefore(new Date()))
                return moment(new Date()).diff(moment(this.startDate), 'months');
        } else if (this.startDate) {
            if (moment(this.startDate).isBefore(new Date()))
                return moment(new Date()).diff(moment(this.startDate), 'months');
        }
        return 0;
    }
}

export class loanAsset extends RecordTimeStamp {
    id: number = 0;
    pid: number = 0;
    name: string = '';
    description: string = '';
    typeID: number = 0;
    type: string = '';
    value: number = 0;
    accountNumber: string = '';
    constructor() { super();}
}

export class fundingContingency extends RecordTimeStamp {
    id: number = 0;
    description: string = '';
    status: string = '';
    canRevoke: boolean = false;
    canFulfill: boolean = false;
    canVerify: boolean = false;
    canViewInstructions: boolean = false;
    canViewFulfillment: boolean = false;
    isOptional: boolean = false;
    requiredBeforeFunding: boolean = false;
    constructor() { super(); }
}

export enum contingencyMode { add = 1, fulfill = 2 }

export class income extends RecordTimeStamp {
    id: number = 0;
    pid: number = 0;
    sourceID: number = 0;
    source: string = '';
    amount: number = 0;
    constructor() { super();}
}
export class expense extends RecordTimeStamp {
    id: number = 0;
    pid: number = 0;
    name: string = '';
    typeID: number = 0;
    type: string = '';
    monthlyAmount: number = 0;
    constructor() { super(); }
}
export class liability extends RecordTimeStamp {
    id: number = 0;
    pid: number = 0;
    typeID: number = 0;
    type: string = '';
    monthlyPayment: number = 0;
    dueDate: Date = Utils._minDate;
    tobePaidOff: boolean = false;
    payOffInstructions: string = '';
    owedTo: string = '';
    balance: number = 0;
    accountNumber: string = '';
    constructor() { super(); }
}
export enum FundsSourceType {
    CommunityNonprofit,
    Employer,
    FederalAgency,
    LocalAgency,
    Other,
    Relative,
    ReligiousNonprofit,
    StateAgency,
    Unmarried,
    Partner
}
