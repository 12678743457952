<div *ngIf="showHeader">
	<mat-form-field *ngIf="listings && listings.length > 0">
		<mat-label class="placeholder">Properties</mat-label>
		<mat-select [(ngModel)]="listingID">
			<mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
		</mat-select>
	</mat-form-field>
	<div *ngIf="currentEscrow && currentEscrow.id > 0">
		Closing Date - {{currentEscrow.closingDate}}
		<a *ngIf="currentEscrow.isValid" (click)="showCIC()">Compliance Items</a>
		<a *ngIf="currentEscrow.isValid" title='Purchase Agreement' (click)="showPA()"><span class='fa-stack'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-list-alt fa-stack-1x fa-inverse'></i></span></a>
	</div>
</div>
<div *ngIf="showHeader">
	<mat-form-field *ngIf="escrows && escrows.length > 0">
		<mat-label class="placeholder">Escrow</mat-label>
		<mat-select [(ngModel)]="escrowID">
			<mat-option *ngFor="let l of escrows" [value]="l.id">{{l.address}}</mat-option>
		</mat-select>
	</mat-form-field>
</div>
<div *ngIf="currentEscrow && currentEscrow.id > 0">
	<mat-card>
		<label class="caption auto">Amount available for Disbursement</label>
		<label class="data-value auto" style="margin-left:10px">{{FormatNumberToCurrency(currentEscrow.amountAvailableForDisbursement)}}</label>
		<a (click)="addDisbursement()" *ngIf="currentEscrow.canDisburseFunds" class="float-right" title="Record new disbursement, This function does NOT transfer the money, you qare simply recording that money is or will be disbursed."><i class="material-icons">add_circle</i></a>
	</mat-card>
	<ng-template [ngIf]="(this.disbursements && this.disbursements.length > 0)" [ngIfElse]="noDisbursements">
		<hr />
		<div *ngFor="let disbursement of disbursements  | paginate: { itemsPerPage: disbursementOnPage , currentPage: disbursementPageNumber }; let idx = index " class="section" style="background-color: {{idx%2 ? 'lightgrey': ''}};padding:20px;">
			<div>
				<label class="caption">Description</label>
				<label class="data-value">{{disbursement.disburseToName}}</label>
			</div>
			<div class="app-flex-container app-2col">
				<div>
					<label class="caption">Disburse To</label>
					<label class="data-value">{{disbursement.disburseToName}}</label>
				</div>
				<div>
					<label class="caption">Amount</label>
					<label class="data-value">{{FormatNumberToCurrency(disbursement.amount)}}</label>
				</div>
			</div>
			<div *ngIf="disbursement.details | isNotNullOrEmpty">
				<label class="caption">Details</label>
				<label class="data-value">{{disbursement.details}}</label>
			</div>
			<div *ngIf="disbursement.notes | isNotNullOrEmpty">
				<label class="caption">Notes</label>
				<label class="data-value">{{disbursement.notes}}</label>
			</div>
		</div>
		<div style="text-align:center" *ngIf="disbursements && disbursements.length > 0 && disbursements.length > disbursementOnPage ">
			<pagination-controls (pageChange)="disbursementPageNumber = $event"></pagination-controls>
		</div>
	</ng-template>
	<ng-template #noDisbursements>
		<h3 *ngIf="this.componentBusy === false" style="margin-top:20px">No funds has been disbursed by Escrow.</h3>
	</ng-template>
</div>