<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" (click)="close(null)" title="Close"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>

    <div class="section">
        <strong>Please provide details of the partner to whom you want to assign the ownership. If you provide a email address which is not registered on {{this.myBRAND}} paltform. An account will be created and Person will be sent an email to sign in and accept your request.</strong>
        <hr />
    </div>

    <div class="app-flex-container app-2col">
        <mat-form-field class="first" appearance="outline">
            <mat-label class="placeholder">First Name<i class="fa fa-question-circle-o warning-color" title="First Name"></i></mat-label>
            <input matInput appDisableAutofill maxlength="100" [(ngModel)]="partner.firstName" />
        </mat-form-field>
        <mat-form-field class="first" appearance="outline">
            <mat-label class="placeholder">Last Name<i class="fa fa-question-circle-o warning-color" title="Last Name"></i></mat-label>
            <input matInput appDisableAutofill maxlength="100" [(ngModel)]="partner.lastName" />
        </mat-form-field>
    </div>
    <mat-form-field appearance="outline" class="full">
        <mat-label class="placeholder">Email Addresse<i class="fa fa-question-circle-o warning-color" title="Email address"></i></mat-label>
        <input matInput appDisableAutofill maxlength="100" [(ngModel)]="partner.email" required />
    </mat-form-field>

    <div class="section">
        <strong>You can assign part of Ownership from maximum available {{maxOwnership}}%</strong>
        <hr />
    </div>
    <mat-form-field class="medium">
        <input matInput appDisableAutofill type="number" [(ngModel)]="partner.ownershipStake" maxlength="6" class="numeric" (keydown)="utils.numOnlyLimitingLength($event,3)" customMin="0" customMax="{{maxOwnership}}" #ownershipAssignment />
    </mat-form-field>
</div>
<div mat-dialog-footer>
    <mat-error>{{this.error}}</mat-error>
    <mat-progress-bar *ngIf="saving" mode="buffer"></mat-progress-bar>
    <input type="submit" class="btn" value="Add Partner" *ngIf="saveReady() && !saving" (click)="addPartner()">
    <input type="submit" class="btn btn-secondary float-right" value="Add Agent" *ngIf="false && saveAgentReady() && !saving" (click)="addAgent()">
</div>
