<div mat-dialog-title>
	<h4>{{dialogHeader}}</h4>
	<a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
	<mat-form-field class="first" *ngIf="escrowID > 0">
		<mat-label class="placeholder">Credit To</mat-label>
		<mat-select [(ngModel)]="newDeposit.creditTo" (selectionChange)="setSaveReady()">
			<mat-option class="auto" *ngFor="let l of depositRoles" [value]="l.id">{{l.name}}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field *ngIf="escrowID > 0">
		<mat-label class="placeholder">Exclusively For</mat-label>
		<mat-select [(ngModel)]="exclusivelyFor" (selectionChange)="setSaveReady()">
			<mat-option class="auto" *ngFor="let l of roles" [value]="l.id">{{l.name}}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field>
		<mat-label class="placeholder">Amount</mat-label>
		<input matInput  type="number" appDisableAutofill [(ngModel)]="newDeposit.amount" (keydown)="utils.numOnlyLimitingLength($event, 12, false)"
			   (change)="setSaveReady()" />
	</mat-form-field>
	<hr />
	<mat-progress-bar mode="buffer" *ngIf="this.componentBusy === true"></mat-progress-bar>
	<input type="submit" class="btn" value="Submit" *ngIf="saveReady && this.componentBusy === false" (click)="onSubmit()">
	<div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to add new Deposit</div>
</div>
