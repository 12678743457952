export enum ListingStatus {
    None = 0,
    Created = 1,
    Listed = 2,
    SalePending = 3,
    ClosingEscrow = 4,
    RecordedAndClosed = 5,
    Expired = 7,
    Revoked = 8,
    SystemHold = 9,
    Cancelled = 10,
    CancelledPurchaseAgreement = 11,
    StartAcceptOffer = -1,
    StopAcceptOffer = -2
}

export enum ListingViewingStatus {
    None = 0,
    Requested = 1,
    Confirmed = 2,
    Visited = 3,
    Cancelled = 4,
    Declined = 5,
    Closed = 6
}

export enum GeographicEntityLevel {
    Global = 0,
    Country = 1,
    State = 2,
    County = 3,
    City = 4
}

export enum ServiceSelectionType {
    ServiceWithNoOptions = 0,
    NoServiceOneOptionOnly = 1,
    ServiceWithMultipleOptions = 2,
    ServiceWithOneOption = 3,
    NoServiceWithMultipleOptions = 4
}

export enum PaymentOption {
    NotSet = 0,
    Prepaid = 1,
    PostServiceDelivery = 2,
    OnCloseOfEscrow = 3
}

export enum ServiceProviderDetailType {
    NotSet = 0,
    ProductDetails = 1,
    TermsAndConditions = 2,
    Disclosure = 3,
    UsageInstructions = 4,
    Forms = 5
}

export enum ServiceWorkOrderStatus {
    None = 0,
    Created = 1,
    AssignedOrAwarded = 4,
    WorkUnderProgress = 5,
    Revoked = 6,
    Cancelled = 7,
    Disputed = 8,
    WorkOrderAccepted = 9,
    WorkCompleted = 10,
    WaitingForDependency = 11,
    Closed = 12,
    DeclinedAssignment = 13,
    WorkResultsAccepted = 14,
    WorkResultRejected = 16,
    Suspended = 15,
    SubmitReport = -1,
    OpenForBidding = -2,
    ClosedForBidding = -3,
    PaidByAcceptance = -4,
    PaidByDecline = -5,
    SubmitReview = -6,
    NewServiceVisit = -7
}

export enum Role {
    NotSet = 0,
    Buyer = 1,
    Seller = 2,
    BuyersAgent = 3,
    SellersAgent = 4,
    ProviderGeneral = 5,
    TradeInspector = 6,
    TitleAgent = 8,
    Underwriter = 9,
    LenderAgent = 10,
    EscrowOfficer = 11,
    EscrowAgent = 12,
    RealEstateAgent = 13,
    IAMOREA = 14,
    Broker = 15
}

export enum ToBePaidBy {
    NotSet = 0,
    Buyer = Role.Buyer,
    Seller = Role.Seller,
    'Split In Half' = 100,
    'Person/Role Ordering' = 101
}

export enum TaxWithHoldingType {
    NotSet = 0,
    PropertyTypeSale = 1,
    ServicesSale = 2,
    PropertySaleWithHolding = 3,
    Judgement = 4
}

export enum DocumentStatus {
    None = 0,
    Created = 1,
    Submitted = 2,
    Revoked = 3,
    ReadPendingAcceptance = 4,
    Accepted = 5,
    Rejected = 6,
    Closed = 7,
    Assigned = 8,
    Cancelled = 9,
    CompletePendingSubmission = 10
}
export enum DocumentCategory {
    NotSet = 0,
    PropertyDescription = 1,
    ClosingStatement = 2,
    MortgageLoanApplication = 3,
    InsuranceApplication = 4,
    TitleInsuranceRequest = 5,
    PromissoryNote = 6,
    TitleWarranty = 7,
    MortgageLoan = 8,
    InsurancePolicy = 9,
    HomeWarranty = 10,
    Offer = 11,
    Binder = 12,
    CounterOffer = 13,
    Addendum = 14,
    Contingency = 15,
    ListingAgreement = 16,
    EscrowAgreement = 17,
    SellerAgencyAgreement = 18,
    BuyerAgencyAgreement = 19,
    PayoffStatement = 20,
    Deed = 21,
    Disclosure = 22,
    TitleRecords = 23,
    TitleEvidence = 24,
    AbstractOfTitle = 25,
    CreditFile = 26,
    SellersNetSheet = 27,
    SurveyReport = 28,
    ServiceReport = 29,
    UniformAppraisalReport = 30,
    TaxDocument = 31,
    CMA = 32,
    Unknown = 33,
    Other = 34,
    PurchaseAgreement = 35,
    ProofOfFunds = 36,
    NoticeToPerformDeposit = 37,
    NoticeToPerformLoan = 38,
    NoticeToPerformContingencies = 39,
    NoticeToPerformDisclosures = 40
}
export enum DocumentEntityType {
    Missing = 0,
    Listing = 1,
    OrderDetail = 2,
    ServiceOrder = 3,
    ListingWorkOrder = 4,
    ListingOffer = 5,
    ListingInspectionAgreement = 6,
    Disclosure = 7,
    AccountAgreement = 8,
    LoanApplication = 9,
    Escrow = 10
}
export enum OfferStatus {
    None = 0,
    Created = 1,
    Submitted = 2,
    Declined = 3,
    Countered = 4,
    Accepted = 5,
    Revoked = 6,
    Closed = 7,
    Recalled = 8,
    Cancelled = 10
}
export enum OfferServiceStatus {
    NotSet = 0,
    Create = 1,
    Assign = 2,
    Accept = 3,
    Decline = 4,
    Revoke = 5
}

export enum BookmarkType {
    All = 0,
    Listing = 1,
    ServiceProvider = 2
}

export enum UserProfileGroup {
    Unknown = 0, Buyer = 1, Seller = 2, Provider = 3
}

export enum MessageName {
    listingRefreshed = 'ListingRefreshed',
    offerRefreshed = 'OfferRefreshed',
    serviceProviderRefreshed = 'ServiceProviderRefreshed',
    newInternalMessage = 'NewInternalMessage'
}

export enum assetStatus {
    Created = 0, Saved = 1, Publish = 2, Retired = 3
}

export enum AssetType { UnAssigned = 0, Page = 1, Component = 2, HelpContent = 3 }

export enum MessageNotificationType {
    None = 0,
    Error = 1,
    Warning = 2,
    Message = 3,
    ClearMessage = 4
}

export enum ProductType { NotSet = 0, ProductOffering = 1, Listing = 2, AdditionalServicesForListing = 3 }
