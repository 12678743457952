<div class="mainPanel">
    <h2 class="pageHeader">Licenses</h2>
    <p>
        Please provide all licenses relevant to your line of business. Information you provide (or do not provide) here will be used by buyer(s) and seller(s) to make informed decision.
        <a class="float-right large btnTip" title="Add additional Licenses" (click)="addLicense()" *ngIf="!loading && allowAdd"><i class="fa fa-plus-circle"></i></a>
    </p>
    <div class="section">
        <ng-template [ngIf]="(this.licenses && this.licenses.length > 0)" [ngIfElse]="noLicenses">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let license of licenses  | paginate: { itemsPerPage: itemsPerPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{utils.isNullOrEmpty(license.LicenseType) ? '[Pending Selection]' : license.LicenseType}}</mat-panel-title>
                        <mat-panel-description style="display:initial">{{license.IssuingAuthority}}</mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="section">
                        <div *ngIf="license.id === 0">
                            <label class="caption">Type</label>
                            <select *ngIf="license.id === 0" class="app" (change)="updateValue($event.target.value, 'LicenseType', i)" [value]="license.LicenseTypeID">
                                <option *ngFor="let st of allLicenseTypes" [value]="st.id" [selected]="st.id==license.LicenseTypeID">
                                    {{st.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label class="caption">Issued By</label>
                            <input autofocus (change)="updateValue($event.target.value, 'IssuingAuthority', i)" type="text" [(ngModel)]="license.IssuingAuthority" class="app" maxlength="50" />
                        </div>
                        <div>
                            <label class="caption">License #</label>
                            <input autofocus (change)="updateValue($event.target.value, 'LicenseNumber', i)" type="text" [(ngModel)]="license.LicenseNumber" maxlength="20" class="app medium" />
                        </div>
                        <div>
                            <label class="caption">Expiry Date</label>
                            <app-datepicker #txtExpiryDate id="txtLicExpiryDate" [disabled]="!allowEdit" [value]="license.ExpiryDate" (valueChanged)="updateValue($event, 'ExpiryDate', i)" [cssClass]="'normal'"></app-datepicker>
                        </div>
                    </div>
                    <mat-action-row *ngIf="licenses.length > 1">
                        <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && licenses.length > 1">Previous</button>
                        <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < licenses.length && licenses.length > 1">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
            <div style="text-align:center" *ngIf="this.licenses.length > itemsPerPage">
                <hr />
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </ng-template>
        <ng-template #noLicenses>
            <mat-card class="section" *ngIf="!loading && (licenses == null || licenses.length === 0)">
                You have not provided any License information.
            </mat-card>
        </ng-template>
    </div>
</div>