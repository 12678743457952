<div mat-dialog-title>
    <a class="close" title="Close" (click)="close()"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <div class="agent" style="overflow:hidden">
        <div class="form-group"><img src="{{agent.imageURL}}" alt="Agent" width="90" height="120" /></div>
        <div class="section form-group"><h2>{{agent.name}}</h2></div>
        <div class="section form-group"><h4>{{agent.company}}</h4></div>
        <div class="form-group" *ngIf="(agent?.phone ?? '') !== ''"><i class="fa fa-phone"></i>&nbsp;{{agent.phone}}</div>
        <div class="form-group" *ngIf="(agent?.address ?? '') != ''"><i class="fa fa-building"></i>&nbsp;{{agent.address}}</div>
    </div>
</div>