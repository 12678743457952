import { RecordTimeStamp } from './base.model';

export enum EntityType { EmailOrMessage = 1, Legal = 2 }
export enum ParameterDataType { numeric = 0, bit = 1, varchar = 2, datetime = 3, money = 4 }

export class Merge extends RecordTimeStamp {
    public id: number = 0;
    public name: string = '';

    public version: string = '';
    public type: EntityType = EntityType.EmailOrMessage;
    public forMessageOnly: boolean = false;
    public isMergeOnly: boolean = false;
    public fromEmailAddress: string = '';
    public fromEmailName: string = '';
    public replyToEmailAddress: string = '';
    public bccEmailAddress: string = '';

    public emailAddressColumn: string = '';
    public languagePreferenceColumn: string = '';

    public subject: string = '';
    public subjectColumn: string = '';

    public startDate: Date;
    public endDate: Date;

    public emailTemplate: string = '';
    public emailStyles: string = '';
    public emailTemplateColumn: string = '';
    public messageTemplate: string = '';
    public messageStyles: string = '';
    public messageTemplateColumn: string = '';

    public query: string = '';
    public queryParsed: boolean = true;
    public connectionString: string = '';

    public logDuration: number = 0;
    public sendIndividually: boolean = false;
    public sendAsAttachment: boolean = false;
    public attachmentExtension: string = '';
    public asHTML: boolean = false;

    public campaignReferenceID: number = 0;

    public preExecuteTrigger: string = '';
    public postExecuteTrigger: string = '';
    public preRecordTrigger: string = '';
    public postRecordTrigger: string = '';
    public ignoreTriggerFailure: boolean = true;

    public subSQLs: SubSQL[];
    public parameters: Parameter[];
    public translations: Translation[];
    public logMapping: LogMap[];
    public attachments: Attachment[];

    constructor() {
        super();
        this.type = EntityType.EmailOrMessage;
        this.subSQLs = new Array<SubSQL>();
        this.parameters = new Array<Parameter>();
        this.translations = new Array<Translation>();
        this.logMapping = new Array<LogMap>();
        this.attachments = new Array<Attachment>();
    }
}

export class SubSQL extends RecordTimeStamp {
    public name: string = '';
    public query: string = '';
    public queryParsed: boolean = true;
    public template: string = '';
    public rowSeperator: string = '';
    constructor() { super(); }
}
export class Parameter extends RecordTimeStamp {
    public name: string = '';
    public type: ParameterDataType = ParameterDataType.numeric;
    public designValue: string = '';
    public required: boolean = false;
    constructor() { super(); }
}
export class Translation extends RecordTimeStamp {
    public isoLanguageCode: string = '';
    public subject: string = '';
    public emailTemplate: string = '';
    public messageTemplate: string = '';
    constructor() { super(); }
}
export class LogMap extends RecordTimeStamp {
    public columnName: string = '';
    public mapColumnName: string = '';
    constructor() { super(); }
}
export class Attachment extends RecordTimeStamp {
    public url: string = '';
    public domain: string = '';
    public userName: string = '';
    public password: string = '';
    constructor() { super(); }
}
