<div class="text-center white-text mx-5 wow fadeIn">
    <h1 class="mb-4" style="color:white">
      <strong>IAMOREA</strong>
    </h1>

    <p>
      <strong>Empowering you to Buy and Sell homes</strong>
    </p>

    <p class="mb-4 d-none d-md-block">
      <strong>The platform for buying and selling your homes, and say I Am My Own Real Estate Agent</strong>
    </p>

    <a  [routerLink]="'help'" class="btn btn-outline-white btn-lg">Find out why IAMOREA is for you</a>
  </div>