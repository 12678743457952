<div class="appContent">
    <h2 class="pageHeader">
        My Listings
        <a class="btn btn-primary float-right" (click)="gotoRoute(appURLs.sellerNewListing)">New Listing</a>
        <mat-form-field class="medium float-right" style="font-size:14px; margin-top:-15px" *ngIf="listings != null && listings.length > 0">
            <mat-label class="placeholder">Filter....</mat-label>
            <input matInput autofocus type="text" [(ngModel)]="addressFilter" (change)="filterListings()" maxlength="50" />
        </mat-form-field>
    </h2>
    <div *ngFor="let listing of filteredListings | paginate: { itemsPerPage: itemsOnPage , currentPage: pageNumber }; let i = index"
         class="app-flex-container app-2col-edit_B {{i%2 === 0 ? '' : 'altListing'}}">
        <div>
            <span style="padding-left:10px" title="Last Changed Date - {{utils.DisplayDateTime(listing.lastUpdateDate)}}">
                # {{listing.id}}
            </span>
            <div style="text-align: center;padding: 20px;">
                <img class="listing link info" (click)="gotoListing(listing.id)" [src]="utils.isNullOrEmpty(listing.mainPhoto) ? 'assets/images/home.svg' : listing.mainPhoto">
            </div>
        </div>
        <div class="small-padding {{listing.unsolicited === true ? 'unsolicited' : ''}}">
            <a class="link" (click)="gotoListing(listing.id)">{{listing.address.StreetName + (listing.address.State ? ', ' + listing.address.State : '')}}</a>
            <div *ngIf="listing.description | isNotNullOrEmpty" class="section">
                {{listing.description}}
            </div>
            <hr />
            <label class="caption" title="Current Asking Price">Price</label><label class="caption auto">{{this.FormatNumberToCurrency(listing.currentPrice)}}</label>
            <div>
                <span style="display:block" *ngIf="listing.offerCount && listing.offerCount > 0">
                    <label class="caption"><a (click)="GoToURLWithSession(appURLs.listingOffers, this.handover.listingID, listing.id)" class="link">Offers</a></label>
                    <label class="caption auto"><a (click)="GoToURLWithSession(appURLs.listingOffers, this.handover.listingID, listing.id)" class="link">{{listing.offerCount}}</a></label>
                </span>
                <div><label class="caption" title="{{'System.CurrentStatus' | translateTag}}">Status</label><label class="caption auto">{{listing.statusName}}<span *ngIf="listing.currentStatusDate"><i class="fa fa-calendar" title="{{utils.DisplayDateTime(listing.currentStatusDate)}}"></i></span></label></div>
                <hr /><app-next-status-actions [actions]="listing.nextSteps" (itemSelected)="changeStatus(listing.id, $event)" [labelCSS]="'hide'"></app-next-status-actions>
            </div>
        </div>
    </div>
    <div style="text-align:center" *ngIf="filteredListings && filteredListings.length > 0 && filteredListings.length > itemsOnPage">
        <hr />
        <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
    </div>
    <span *ngIf="(listings == null || listings.length == 0) && this.componentBusy === false">
        You do not have any property listed for sale. Click <a class="btn" (click)="gotoRoute(appURLs.sellerNewListing)">here</a> to list a property for free.
    </span>
    <mat-progress-bar *ngIf="this.componentBusy === true" mode="indeterminate"></mat-progress-bar>
</div>
