import { BaseComponent } from './base.component';
import { GlobalComponent } from './global.component';

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from './util/APICaller.component';
import { Router } from '@angular/router';
import { Inject, OnDestroy, Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from './common/utils';

@Injectable()
export abstract class BaseDialog extends BaseComponent implements OnDestroy {

    onCloseMessage: string = '';
    onCloseResourceMessage: string = '';
    error: string = '';
    dialogHeader: string = '';
    busy: boolean = false;

    protected readonly utils = Utils;

    private _ls: Subscription;

    constructor(@Inject(String) sModule: string, public dataservice: DataService, router: Router
        , public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(sModule, router, null);

        if (this.data)
            if (this.data.message && !Utils.isNullOrEmpty(this.data.message)) this.onCloseMessage = this.data.message;

        // Close all open dialogs on LOGOUT
        this._ls = this.gc.loginChanged.subscribe((v) => {
            if (v == null || v.id == null)
                this.close(null);
        });

    }

    ngOnDestroy() {
        if (this._ls) this._ls.unsubscribe();
    }

    close(data) {
        if (!Utils.isNullOrEmpty(this.onCloseResourceMessage) && data != null)
            this.showResourceMessage(this.onCloseResourceMessage);
        else if (!Utils.isNullOrEmpty(this.onCloseMessage) && data != null)
            this.showMessage(this.onCloseMessage);
        this.dialogRef.close(data);
    }

    protected cancelElementEvent($event) {
        if ($event) {
            if ($event.stopPropagation)
                $event.stopPropagation();
            if ($event.preventDefault)
                $event.preventDefault();
        }
    }

    protected castToMe(oObject: any) {
        return Utils.castTo(oObject, this);
    }
}
