<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" (click)="close(null)" title="Close"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-form-field class="first">
        <mat-label class="placeholder">File Description <i class="fa fa-question-circle warning-color" title="Please describe about the contents of the file you are uploading."></i></mat-label>
        <input matInput appDisableAutofill [(ngModel)]="newDocumentName" maxlength="100" />
    </mat-form-field>
    <div class="input-group">
        <div class="input-group-prepend" *ngIf="false">
            <span class="input-group-text" id="inputGroupFileAddon01">Select<i class="fa fa-question-circle-o warning-color" title="you can upload a document here"></i></span>
        </div>
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="inputGroupFile01"
                   aria-describedby="inputGroupFileAddon01"
                   #newDoc (change)="fileUploaded($event)" accept=".pdf,application/pdf">
            <label class="custom-file-label" for="inputGroupFile01">{{this.doc.content == null ? '[Choose' : ( this.doc.name + ' [Change' ) }} file]</label>
        </div>
    </div>
    <div class="section"  *ngIf="readyToAdd">
        <hr/>
        <button class="button green" (click)="addDoc()">Submit</button>
    </div>
</div>