import { Router, Route } from '@angular/router';
import { BaseService } from '../common/service/base.service';

import { DataService } from '../util/APICaller.component';
import { escrowDeposit, downloadableContent, Role } from '../models';

import { EscrowDemandStatus } from '../models';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EscrowService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    getEscrow(id: number, listingID: number = 0, bFullObject: boolean = false) {
        const data = { id, listingID, fullObject: bFullObject };
        if (id > 0 || listingID > 0)
            return this.callEscrow(data);
        else
            return null;
    }
    setToClose(id: number) {
        const data = { id };
        if (id > 0)
            return this.ds.APIPost('/escrow/setToClose', data, this.requestingModule);
        else
            return null;
    }
    getClosingStatus(id: number) {
        const data = { id };
        if (id > 0)
            return this.ds.APIPost('/escrow/closingStatus', data, this.requestingModule);
        else
            return null;
    }
    showPreCloseMessage(id: number) {
        const data = { id };
        if (id > 0)
            return this.ds.APIPost('/escrow/getPreClosingMessage', data, this.requestingModule);
        else
            return null;
    }
    getPurchaseAgreement(id: number) {
        const data = { offerID: id };
        if (id > 0)
            return this.ds.APIPost('/escrow/purchaseAgreement', data, this.requestingModule);
        else
            return null;
    }
    getMyLiensForEscrow(id: number) {
        const data = { id };
        if (id > 0)
            return this.ds.APIPost('/escrow/myLiensForEscrow', data, this.requestingModule);
        else
            return null;
    }
    getStatement(id: number, forRole: Role = Role.NotSet, forSPID: number = 0) {
        const data = { id, includeStatements: true, includeHeader: false, forRole, forSPID };
        if (id > 0)
            return this.callEscrow(data);
        else
            return null;
    }
    //#region Demands
    getDemands(id: number) {
        const data = { id, includeDemands: true, includeHeader: false };
        if (id > 0)
            return this.callEscrow(data);
        else
            return null;
    }
    addDemand(values) {
        const data = values;
        return this.ds.APIPost('/escrow/addDemand', data, this.requestingModule);
    }
    payDemand(values) {
        const data = values;
        return this.ds.APIPost('/escrow/payDemand', data, this.requestingModule);
    }
    changeDemandStatus(iEscrowID: number, iDemandID: number, enNewStatus: EscrowDemandStatus) {
        const data = { id: iEscrowID, entityID: iDemandID, newStatus: enNewStatus };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/demand/changeStatus', data, this.requestingModule);
        else
            return null;
    }
    //#endregion

    //#region Contingency
    getContingencies(id: number) {
        const data = { id, includeContingencies: true, includeHeader: false };
        if (id > 0)
            return this.callEscrow(data);
        else
            return null;
    }
    addContingency(values) {
        const data = values;
        return this.ds.APIPost('/escrow/addContingency', data, this.requestingModule);
    }
    changeContingencyStatus(iEscrowID: number, iContingencyID: number, enNewStatus: EscrowDemandStatus) {
        const data = { id: iEscrowID, entityID: iContingencyID, newStatus: enNewStatus };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/contingency/changeStatus', data, this.requestingModule);
        else
            return null;
    }
    sendNoticeToPerformContingency(iEscrowID: number, iContingencyID: number) {
        const data = { id: iEscrowID, entityID: iContingencyID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/contingecy/sendNoticeToPerform', data, this.requestingModule);
        else
            return null;
    }
    contingencyInstructions(iEscrowID: number, iContingencyID: number) {
        const data = { id: iEscrowID, entityID: iContingencyID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/contingency/instructions', data, this.requestingModule);
        else
            return null;
    }
    contingencyFulfilmentDocument(iEscrowID: number, iContingencyID: number) {
        const data = { id: iEscrowID, entityID: iContingencyID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/contingency/fulfillmentDocument', data, this.requestingModule);
        else
            return null;
    }
    uploadContingencyFulfilmentDocument(iEscrowID: number, iContingencyID: number, document: downloadableContent) {
        const data = { id: iEscrowID, entityID: iContingencyID, document };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/contingency/uploadFulfillmentDocument', data, this.requestingModule);
        else
            return null;
    }
    //#endregion

    //#region Disclosures
    sendNoticeToPerformDisclosures(iEscrowID: number) {
        const data = { id: iEscrowID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/disclosure/sendNoticeToPerform', data, this.requestingModule);
        else
            return null;
    }
    acceptDisclosures(iEscrowID: number) {
        const data = { id: iEscrowID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/disclosure/accept', data, this.requestingModule);
        else
            return null;
    }
    revokeDisclosureAcceptance(iEscrowID: number) {
        const data = { id: iEscrowID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/disclosure/revokeAcceptance', data, this.requestingModule);
        else
            return null;
    }
    disclosureDocument(iEscrowID: number, iDisclosureID: number) {
        const data = { id: iEscrowID, entityID : iDisclosureID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/disclosure/document', data, this.requestingModule);
        else
            return null;
    }
    //#endregion

    //#region Taxes
    getTaxes(id: number) {
        const data = { id, includeTaxes: true, includeHeader: false };
        if (id > 0)
            return this.callEscrow(data);
        else
            return null;
    }
    addTax(escrowID: number, values: any) {
        values['id'] = escrowID;
        const data = values;
        if (escrowID > 0)
            return this.ds.APIPost('/escrow/addTax', data, this.requestingModule);
        else
            return null;
    }
    removeTax(iEscrowID: number, taxID: number) {
        const data = { id: iEscrowID, applicableTaxID: taxID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/removeTax', data, this.requestingModule);
        else
            return null;
    }
    //#endregion

    //#region Deposits
    getLenderInstructions(id) {
        const data = { id, includeLenderInstructions: true };
        if (id > 0)
            return this.ds.APIPost('/escrow/lenderInstructions', data, this.requestingModule);
        else
            return null;
    }
    getDeposits(id: number) {
        const data = { id, includeDeposits: true, includeHeader: false };
        if (id > 0)
            return this.callEscrow(data);
        else
            return null;
    }
    addDeposit(iEscrowID: number, values: any) {
        const data = { id: iEscrowID, deposit: values };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/addDeposit', data, this.requestingModule);
        else
            return null;
    }
    verifyDeposit(iEscrowID: number, deposit: escrowDeposit) {
        const data = { id: iEscrowID, deposit };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/verifyDeposit', data, this.requestingModule);
        else
            return null;
    }
    cancelDeposit(iEscrowID: number, depositID: number) {
        const data = { id: iEscrowID, deposit: { id: depositID } };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/cancelDeposit', data, this.requestingModule);
        else
            return null;
    }
    getDepositInstructions(id: number) {
        const data = { id };
        if (id > 0)
            return this.ds.APIPost('/escrow/depositInstructions', data, this.requestingModule);
        else
            return null;
    }
    //#endregion

    //#region Disbursement
    getDisbursements(id: number) {
        const data = { id, includeDisbursements: true, includeHeader: false };
        if (id > 0)
            return this.callEscrow(data);
        else
            return null;
    }
    newDisbursement(iEscrowID: number, values: any) {
        const data = { id: iEscrowID, disbursement: values };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/newDisbursement', data, this.requestingModule);
        else
            return null;
    }
    //#endregion

    //#region Power of Attorney, Death Certificate
    approvePowerOfAttorney(iEscrowID: number, roleID: number) {
        const data = { id: iEscrowID, entityID: roleID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/verifyPOA', data, this.requestingModule);
        else
            return null;
    }
    declinePowerOfAttorney(iEscrowID: number, roleID: number) {
        const data = { id: iEscrowID, entityID: roleID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/declinePOA', data, this.requestingModule);
        else
            return null;
    }
    deathCertificate(iEscrowID: number, roleID: number) {
        const data = { id: iEscrowID, entityID: roleID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/deathCertificate', data, this.requestingModule);
        else
            return null;
    }
    verifyDeathCertificate(iEscrowID: number, roleID: number) {
        const data = { id: iEscrowID, entityID: roleID };
        if (iEscrowID > 0)
            return this.ds.APIPost('/escrow/verifyDeathCertificate', data, this.requestingModule);
        else
            return null;
    }
    //#endregion
    getWorkOrders(escrowID: number = 0) {
        const data = { id: escrowID };
        return this.ds.APIPost('/escrow/workOrders', data, this.requestingModule);
    }

    //#region private
    private callEscrow(request: any) {
        return this.ds.APIPost('/escrow/escrow', request, this.requestingModule);
    }
    //#endregion
}
