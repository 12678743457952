import { Router, Route } from '@angular/router';
import { BaseService } from './base.service';

import { DataService } from '../../util/APICaller.component';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    convertAmountToWords(amount: number) {
        const data = { amount };
        return this.ds.APIPost('/util/convertAmountToWords', data, this.requestingModule);
    }

    convertHTMLtoPDF(html: string, bPasswordProtect: boolean = false) {
        const data = { html, passwordProtect: bPasswordProtect };
        return this.ds.APIPost('/util/convertHTMLtoPDF', data, this.requestingModule);
    }

    getBrainTreeClientToken() {
        return this.ds.APIPreLoginPost('/braintree/getclienttoken', null, this.requestingModule);
    }
}
