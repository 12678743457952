import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input , Output, OnDestroy} from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { downloadableContent, DisclosureDataType, listingDisclosure, BaseResponse, DocumentEntityType, DocumentCategory } from '../../models';

import { ListingService } from '../../listing/listing.service';
import { EscrowService } from '../../escrow/escrow.service';

import { ListingBaseComponent } from '../../listing/listing.base.component';
import { NewDisclosureDialog } from './dialogs/newDisclosureRequest.dialog';
import { GlobalComponent } from '../../global.component';
import { UploadFileDialog } from '../../common/dialogs';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../utils';
@Component({
    selector: 'app-listing-disclosures',
    templateUrl: './listingDisclosures.component.html'
})
export class ListingDisclosureComponent extends ListingBaseComponent implements OnDestroy {

    @ViewChild('disclosureTable') table;
    @Input() public showHeader: boolean = true;
    @Input() @Output() readonly: boolean = false;

    disclosureDataType: typeof DisclosureDataType = DisclosureDataType;

    allCount: number = 0;
    pendingCount: number = 0;
    fulfilledCount: number = 0;
    anyRequiredPending: boolean = false;
    toggleFilter: number = 0;
    pageNumber: number = 1;
    disclosures: listingDisclosure[] = [];
    bindingDisclosures: boolean = false;

    canUpdateComponent: boolean = false;
    private _lcs: Subscription;
    constructor(ds: DataService, rs: Router, private dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingDisclosure', ds, rs, gc);
        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindDisclosures();
        });
    }

    get canRequestNewDisclosure() {
        return this.currentListing && (this.currentListing.isProspectiveBuyer || (this.currentOffer && this.currentOffer.isBuyer && this.currentListing.acceptingOffers));
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    updateView() {
        this.pageNumber = 1;
        if (this.currentListing && this.currentListing.disclosures != null && this.currentListing.disclosures.length > 0) {

            this.canUpdateComponent = this.currentListing.isEditable('disclosures');
            if (this.canUpdateComponent === false)
                this.readonly = true;

            const _filter = parseInt(this.toggleFilter.toString());

            this.disclosures = this.currentListing.disclosures.map((c) => Utils.castTo(c, new listingDisclosure()));
            this.allCount = this.disclosures.length;

            if (this.readonly)
                this.disclosures = [...this.disclosures.filter((d) => (d ? d.required === true : false ) || (d ? d.pending === true : false))];
            else {
                if (_filter  === 1)
                    this.disclosures = this.disclosures.filter((f) => { if (f) return f.pending === true; else return false; });
                else if (_filter  === 2)
                    this.disclosures = this.disclosures.filter((f) => { if (f) return f.pending === false; else return false; });
                else
                    this.disclosures = [...this.disclosures];
            }

            this.setCounts();
        }
    }

    bindDisclosures(oDisclosures: listingDisclosure[] = null) {

        if (this.listingID === 0 || this.bindingDisclosures === true ) return;

        if (oDisclosures) {
            this.disclosures = oDisclosures;
        }

        if (this.currentListing && this.currentListing.disclosures != null && this.currentListing.disclosures.length > 0) {
            this.updateView();
            return;
        }

        this.bindingDisclosures = true;
        this.componentBusy = true;
        let _response;
        const _subDisc = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getDisclosures(this.listingID);
        if (_subDisc) {
            _subDisc.subscribe(
                (data) => { _response = data; }
                , (error) => { this.bindingDisclosures = this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.bindingDisclosures  = this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else if (_response.data) {

                            const _listingPresent = this.currentListing && this.currentListing.id > 0;
                            if (this.noParent && !_listingPresent)
                                this.setCurrentListing(_response.data);

                            this.currentListing.disclosures = _response.data.disclosures;

                            this.updateView();
                            this.setCounts();
                        }
                });
        }
    }

    createServiceOrder() {

        if (this.listingID === 0) return;

        this.gc.setComponentBusy(true);
        let _response;
        const _subCrDisc = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).createDisclsoureServiceOrder(this.listingID);
        if (_subCrDisc) {
            _subCrDisc.subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => {
                    this.gc.setComponentBusy(false);
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.currentListing.canCreateDisclosureServiceOrder = false;
                            this.showMessage('A Service Order has been created and is PENDING assignment to a Service Provider. You can <a routerLink="/seller/workorders">find a Service Provider</a> and assign the Work Order to a Service Provider of your choice.');
                        }
                }
            );
        }
    }

    sendNotice() {
        if (this.currentEscrow == null || this.currentEscrow.id === 0) {
            this.showMessage('Escrow not open. After offer Submission and Acceptance, escrow will be opened and you send notice to Sellers to Submit disclosures.');
            return;
        }

        // Send Notice to Sellers to Perform and Deliver Disclosures
        let _response;
        const _sub = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).sendNoticeToPerformDisclosures(this.currentEscrow.id);
        if (_sub) {
            _sub.subscribe(
                (data) => _response = data
                , (error) => {
                    this.onApiError(error);
                }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.currentListing.canSendDisclosureNotice = false;
                            this.showMessage('Notice has been sent to responsible parties.');
                        }
                }
            );
        }
    }
    acceptDisclosures() {

        if (this.currentEscrow == null || this.currentEscrow.id === 0) {
            this.showMessage('Escrow not open. After offer Submission and Acceptance, escrow will be opened and you will be required to Accept All Disclosures');
            return;
        }

        // Buyer to Accept Disclosures
        let _response;
        const _sub = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).acceptDisclosures(this.currentEscrow.id);
        if (_sub) {
            _sub.subscribe(
                (data) => _response = data
                , (error) => {
                    this.onApiError(error);
                }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.currentListing.canSignDisclosures = false;
                            this.showMessage('Your acceptance to disclosures has been recorded.');
                        }
                }
            );
        }
    }
    reveokeAcceptance() {

        if (this.currentEscrow == null || this.currentEscrow.id === 0) return;

        let _response;
        const _sub = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).revokeDisclosureAcceptance(this.currentEscrow.id);
        if (_sub) {
            _sub.subscribe(
                (data) => _response = data
                , (error) => {
                    this.onApiError(error);
                }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.currentListing.canRevokeAcceptance = false;
                            this.showMessage('Your acceptance to the Disclosures has been revoked.');
                        }
                }
            );
        }
    }
    requestNewDisclosure() {
        const dialogRef = this.dialog.open(NewDisclosureDialog, {
            data: {
                currentListing: this.currentListing
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.showMessage('Disclosures added successfully.');
                this.disclosures.splice(0, 0, result);
                this.disclosures = [...this.disclosures];
                this.setCounts();
            }
        });
    }

    addDocument(oDisclosure: listingDisclosure) {
        const dialogRef = this.dialog.open(UploadFileDialog, {
            data: {
                id: oDisclosure.id
                , type: DocumentEntityType.Disclosure
                , category: DocumentCategory.Disclosure
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onDocumentSubmitted(oDisclosure, result);
            }
        });
    }

    removeDoc(iDisclosureID: number, iDocumentID: number) {
        if (this.listingID === 0) return;
        let _response;
        const _sub = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).removeDisclosureDocument(this.listingID, iDocumentID);
        if (_sub) {
            _sub.subscribe(
                (data) => { _response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            const _disc = this.disclosures.find((d) => d.id === iDisclosureID);
                            if (_disc) {
                                _disc.documents = _disc.documents.filter((d) => d.id !== iDocumentID);
                            }
                            this.showMessage('Disclosure document has been removed');
                        }
                }
            );
        }
    }

    viewDoc(iDisclosureID: number, iDocumentID: number) {
        if (this.listingID === 0 || iDisclosureID === 0 || iDocumentID === 0) return;
        let _response: BaseResponse;
        const _sub = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).disclosureDocument(this.listingID, iDisclosureID, iDocumentID);
        if (_sub) {
            _sub.subscribe(
                (data) => { _response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else if (_response.data) {
                            const _doc: downloadableContent = _response.data;
                            this.OpenDownloadableDoc(_doc.content, _doc.name, _doc.mime);
                        }
                }
            );
        }
    }

    responseChanged(iDisclosureID: number = 0, oValue: any = null) {
        if (this.listingID === 0 || iDisclosureID === 0 || oValue === null) return;
        let _response: BaseResponse;
        const _sub = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).changeDisclosureValue(this.listingID, iDisclosureID, oValue);
        if (_sub) {
            this.componentBusy = true;
            _sub.subscribe(
                (data) => { _response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {

                            const _d = this.disclosures.find((d) => { return d.id === iDisclosureID; });

                            if (_d) _d.pending = _response.data != null ? _response.data === false : false;
                            this.currentListing.disclosures = this.disclosures;

                            this.setCounts();
                        }
                }
            );
        }
    }

    private setCounts() {
        const _disc = this.disclosures;
        if (_disc  && _disc.length > 0) {

            this.anyRequiredPending = _disc.find((d) => d.required && d.pending) != null;

            let _v = _disc.filter((f) => { if (f) return f.pending === true ; else return false; });
            this.pendingCount = _v ? _v.length : 0;

            _v = _disc.filter((f) => { if (f) return f.pending === false; else return false; });
            this.fulfilledCount = _v ? _v.length : 0;

        } else {
            this.allCount = this.pendingCount = this.fulfilledCount = 0;
        }
    }

    private onDocumentSubmitted(oDisclosure: listingDisclosure, oDoc: downloadableContent) {
        let _response;
        const _s = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).uploadDisclosureDocument(this.listingID, oDisclosure.id, oDoc);
        if (_s) {
            _s.subscribe((r) => _response = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response) {
                        if (!Utils.isNullOrEmpty(_response.error)) {
                            this.showWarning('Failed uploading disclosure document. Retry in few minutes, if the process fails, contact Customer Support.');
                        } else if (_response.data) {
                            oDisclosure.documents.push(_response.data);
                            this.showMessage('Document added successfully');
                        }
                    }
                }
            );
        }
    }
}
