<div *ngIf="showHeader">
    <mat-form-field *ngIf="listings && listings.length > 0">
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="currentEscrow && currentEscrow.id > 0">
        Closing Date - {{currentEscrow.closingDate}}
        <a *ngIf="currentEscrow.isValid" (click)="showCIC()">Compliance Items</a>
        <a *ngIf="currentEscrow.isValid" title='Purchase Agreement' (click)="showPA()"><span class='fa-stack'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-list-alt fa-stack-1x fa-inverse'></i></span></a>
    </div>
</div>
<div *ngIf="showHeader">
    <mat-form-field *ngIf="escrows && escrows.length > 0">
        <mat-select [(ngModel)]="escrowID">
            <mat-label class="placeholder">Escrow</mat-label>
            <mat-option *ngFor="let l of escrows" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div>
    <mat-form-field *ngIf="escrows && escrows.myWorkOrders && escrows.myWorkOrders.length > 1">
        <mat-select [(ngModel)]="workOrderID">
            <mat-label class="placeholder">My Work Orders</mat-label>
            <mat-option *ngFor="let l of escrows.myWorkOrders" [value]="l.id">{{l.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div>
    <mat-progress-bar mode="buffer" *ngIf="this.componentBusy === true"></mat-progress-bar>
    <mat-card *ngIf="currentEscrow && currentEscrow.canDemand && workOrderID > 0">
        <button matButton (click)="addDemandOrCredit(false)" title="New Demand"><i class="fa fa-plus"></i> Demand</button>
        <button matButton (click)="addDemandOrCredit(true)" title="New Credit" class="float-right warning"><i class="fa fa-plus"></i> Credit</button>
    </mat-card>
    <ng-template [ngIf]="(this.demands && this.demands.length > 0)" [ngIfElse]="noDemands">
        <hr/>
        <div *ngFor="let demand of demands  | paginate: { itemsPerPage: demandsOnPage , currentPage: demandsPageNumber }; let idx = index " class="section" style="background-color: {{idx%2 ? 'lightgrey': ''}};padding:20px;">
            <div>
                <label class="caption">Description</label>
                <label class="data-value auto">{{utils.isNullOrEmpty(demand.details) ? "[None Provided]" : demand.details}}</label>
            </div>
            <div class="app-flex-container app-2col">
                <div>
                    <label class="caption">To Be Paid By</label>
                    <label class="data-value auto">{{demand.payorDescription}}</label>
                </div>
                <div *ngIf="!utils.isNullOrEmpty(demand.demandingEntity)">
                    <label class="caption">Demanded By</label>
                    <label class="data-value auto">{{demand.demandingEntity}}</label>
                </div>
            </div>
            <div class="app-flex-container app-3col">
                <div>
                    <label class="caption">Amount</label>
                    <label class="data-value">{{FormatNumberToCurrency(demand.amount)}}</label>
                </div>
                <div *ngIf="demand.currentStatus !== 5">
                    <label class="caption">Approved</label>
                    <label class="data-value auto">{{demand.amountApprovedByPayor !== 0 ? FormatNumberToCurrency(demand.amountApprovedByPayor) : "[Pending]"}}</label>
                </div>
                <div *ngIf="demand.currentStatus !== 5">
                    <label class="caption">{{'Paid/Disbursed' | ellipsis:20}}</label>
                    <label class="data-value auto">{{demand.amountPaid !== 0 ? FormatNumberToCurrency(demand.amountPaid) : "[Pending]"}}</label>
                </div>
            </div>
            <mat-action-row style="display:initial">
                <hr/>
                <div><label class="caption">{{'System.CurrentStatus' | translateTag}}</label> <label class="data-value auto">{{demand.statusName}}</label></div>
                <br />
                <app-next-status-actions [actions]="demand.nextSteps" (itemSelected)="changeStatus($event)" [hideNoActionLabel]="true"></app-next-status-actions>
            </mat-action-row>
        </div>
        <div style="text-align:center" *ngIf="demands && demands.length > 0">
            <hr />
            <pagination-controls (pageChange)="demandsPageNumber = $event"></pagination-controls>
        </div>
    </ng-template>
    <ng-template #noDemands>
        <h3 *ngIf="this.componentBusy === false">No demands has been submitted by Escrow or a Service Provider.</h3>
    </ng-template>
</div>