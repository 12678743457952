<div *ngIf="showHeader">
	<mat-form-field *ngIf="listings && listings.length > 0">
		<mat-label class="placeholder">Properties</mat-label>
		<mat-select [(ngModel)]="listingID">
			<mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
		</mat-select>
	</mat-form-field>
	<div *ngIf="currentEscrow && currentEscrow.id > 0">
		<label class="caption large">Closing Date</label>{{utils.DisplayDate(currentEscrow.closingDate)}}
		<a *ngIf="currentEscrow.isValid" (click)="showCIC()">Compliance Items</a>
		<a *ngIf="currentEscrow.isValid" title='Purchase Agreement' (click)="showPA()"><span class='fa-stack'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-list-alt fa-stack-1x fa-inverse'></i></span></a>
	</div>
</div>
<div *ngIf="showHeader">
	<mat-form-field *ngIf="escrows && escrows.length > 0">
		<mat-label class="placeholder">Escrow(s)</mat-label>
		<mat-select [(ngModel)]="escrowID">
			<mat-option *ngFor="let l of escrows" [value]="l.id">{{l.address}}</mat-option>
		</mat-select>
	</mat-form-field>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="componentBusy === true"></mat-progress-bar>
<div *ngIf="currentEscrow && currentEscrow.id > 0">
	<mat-card class="section">
		<h2 class="pageHeader" *ngIf="showHeader">Statement</h2>
		<div class="app-flex-container app-2col-edit section bold" *ngIf="statement && statement.length > 0">
			<div>&nbsp;</div>
			<div>
				<div class="app-flex-container app-2col">
					<div>Credit(s)</div>
					<div>Debit(s)</div>
				</div>
			</div>
		</div>
		<div class="app-flex-container app-2col-edit {{i%2 === 0 ? 'alt' : ''}}" *ngFor="let row of statement;let i = index">
			<div style="padding:10px"><strong>{{row.Description}}</strong></div>
			<div>
				<div class="app-flex-container app-2col">
					<div class="float-right"> {{row.Amount > 0 ? FormatNumberToCurrency(row.Amount) : ""}}</div>
					<div class="float-right">{{row.Amount < 0 ? FormatNumberToCurrency(Math.abs(row.Amount)) : ""}}</div>
				</div>
			</div>
		</div>
		<hr />
		<div class="app-flex-container app-2col-edit section bold" *ngIf="statement && statement.length > 0">
			<div class="float-right bold">Total(s)</div>
			<div>
				<div class="app-flex-container app-2col">
					<div>{{FormatNumberToCurrency(totalCredits)}}</div>
					<div>{{FormatNumberToCurrency(totalDebits)}}</div>
				</div>
			</div>
		</div>
		<div class="section" *ngIf="componentBusy === false">
			<hr />
			<span *ngIf="hasEarnings">Money you will receive at closing >>>> <span class="bold">{{earnings}}</span></span>
			<span *ngIf="hasPayable" class="warning"> Money you owe and should depposit in Escrow, prior to closing >>>> <span class="bold">{{payable}}</span></span>
			<span *ngIf="hasEarnings === false && hasPayable === false ">You will not receive OR owe any money prior to close.</span>
		</div>
	</mat-card>
</div>
