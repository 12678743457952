import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, Output, OnDestroy } from '@angular/core';

import { DataService } from '../util/APICaller.component';

import { Router, ActivatedRoute } from '@angular/router';

import { StatusDisplayItem } from '../models';

import { ListingService } from './listing.service';
import { OfferService } from '../offer/offer.service';
import { EscrowService } from '../escrow/escrow.service';
import { ListingBaseComponent } from './listing.base.component';
import { GlobalComponent } from '../global.component';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-propert-closing-status',
    templateUrl: './closingStatus.component.html'
})
export class ListingClosingStatusComponent extends ListingBaseComponent  {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    offerID: number = 0;
    escrowID: number = 0;
    showCompletedItems: boolean = false;
    allCarItems: StatusDisplayItem[] = [];
    statusData: any;
    carItems: StatusDisplayItem[] = [];

    constructor(dataservice: DataService, r: Router, gc: GlobalComponent, private currentRoute: ActivatedRoute) {
        super('pgPropertyClosingStatus', dataservice, r, gc);

        // TODO - Query Params to set values
        // Hide Header if Query Params are set
        // else this.NoParent = true;

        let _escrowID = 0;
        let _offerID = 0;
        let _listingID = 0;

        if (this.currentRoute.snapshot.queryParams && this.currentRoute.snapshot.queryParams.e)
            _escrowID = parseInt(this.currentRoute.snapshot.queryParams.e.toString());
        else if (this.currentRoute.snapshot.queryParams && this.currentRoute.snapshot.queryParams.o)
            _offerID = parseInt(this.currentRoute.snapshot.queryParams.o.toString());
        else if (this.currentRoute.snapshot.queryParams && this.currentRoute.snapshot.queryParams.l)
            _listingID =  parseInt(this.currentRoute.snapshot.queryParams.l.toString());

        if (_escrowID === 0) {
            const _id = parseInt(sessionStorage.getItem(this.handover.escrowID));
            if (!isNaN(_id) && _id > 0)
                _escrowID = _id;
        }

        if (_listingID === 0) {
            const _id = parseInt(sessionStorage.getItem(this.handover.listingID));
            if (!isNaN(_id) && _id > 0)
                _listingID = _id;
        }

        if (_offerID === 0) {
            const _id = parseInt(sessionStorage.getItem(this.handover.offerID));
            if (!isNaN(_id) && _id > 0)
                _offerID = _id;
        }

        this.setEscrowID(_escrowID);
        this.setOfferID(_offerID);
        this.setListingID(_listingID);

        sessionStorage.removeItem(this.handover.offerID); sessionStorage.removeItem(this.handover.escrowID); sessionStorage.removeItem(this.handover.listingID);
        let _response;

        this.allCarItems = new Array<StatusDisplayItem>();

        if (this.escrowID && this.escrowID > 0) {
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getClosingStatus(this.escrowID);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response) {
                            if (_response.data) {
                                this.statusData = _response.data;
                                if (this.statusData.statusItems)
                                    this.allCarItems = this.statusData.statusItems.map((c) => { return Utils.castTo(c, new StatusDisplayItem(null)); });
                                this.setCARItems();
                            }
                        }
                    });
            }
        } else if (this.offerID && this.offerID > 0) {
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getClosingStatus(this.offerID).subscribe((r) => _response = r
                , (error) => {this.onApiError(error); }
                , () => {
                    if (_response) {
                        if (_response.data) {
                            this.statusData = _response.data;
                            if (this.statusData.statusItems)
                                this.allCarItems = this.statusData.statusItems.map((c) => { return Utils.castTo(c, new StatusDisplayItem(null)); });
                            this.setCARItems();
                        }
                    }
                });
        } else if (this.listingID && this.listingID > 0) {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getClosingStatus(this.listingID).subscribe((r) => _response = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response) {
                        if (_response.data) {
                            this.statusData = _response.data;
                            if (this.statusData.statusItems)
                                this.allCarItems = this.statusData.statusItems.map((c) => { return Utils.castTo(c, new StatusDisplayItem(null)); });
                            this.setCARItems();
                        }
                    }
                });
        }
    }

    details() { this.gotoListing(this.listingID); }

    private setOfferID(v: number) {
        if (v == null || typeof v !== 'number' || this.isNaN(v) || v < 0) v = 0;
        this.offerID = v;
        this.gc.setOfferID(v);
    }

    private setEscrowID(v: number) {
        if (v == null || typeof v !== 'number' || this.isNaN(v) || v < 0 ) v = 0;
        this.offerID = v;
        this.gc.setEscrowID(v);
    }

    private setCARItems() {
        if (this.allCarItems != null && Array.isArray(this.allCarItems))
            this.carItems = this.allCarItems.filter(ci => { if (this.showCompletedItems) return true; else return (!ci.HideByDefault || !(!ci.BoolValueFalse && ci.DataTypeBool)); });
        else
            this.carItems = [];
    }
}
