import { BaseService } from '../common/service/base.service';
import { DataService } from '../util/APICaller.component';
import { Role, assessment, downloadableContent, ListingViewingStatus, ListingSearchRequest, ToBePaidBy, ListingStatus } from '../models';
import { Router } from '@angular/router';
import { Partner } from '../common/dialogs';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ListingService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) private sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    getMyListingsAs(oRole: Role) {
        const data = { role: oRole };
        return this.ds.APIPost('/listing/myListings', data, this.requestingModule);
    }

    getMyListingsForSale(bSimpleList: boolean = true) {
        const data = { forSimpleList: bSimpleList };
        return this.ds.APIPost('/listing/getMyListingsForSale', data, this.requestingModule);
    }

    getListing(id: number, bFullObject: boolean = false, bForEdit: boolean = false) {
        if (id > 0) {
            const data = { id, fullObject: bFullObject, forEdit: bForEdit, includeFullHeader : true };
            return this.callListing(data);
        }
    }

    getListings(oCriteria: ListingSearchRequest, bLooggedIn: boolean = false) {
        const data = oCriteria;
        return this.ds.APIPreLoginPost('/listings/search', data, this.requestingModule);
    }

    saveListing(oListing: any) {
        if (oListing && oListing.id > 0) {
            const data = oListing;
            return this.ds.APIPost('/listing/saveListing', data, this.requestingModule);
        }
    }

    publish(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/publish', data, this.requestingModule);
        }
    }

    clearEdit() {
        return this.ds.APIPost('/listing/clearEdit', {}, this.requestingModule);
    }

    changeStatus(id: number, enNewStatus: ListingStatus, showEdit: boolean = false) {
        if (id > 0) {
            const data = { id, newStatus: enNewStatus, showEdit};
            return this.ds.APIPost('/listing/changeStatus', data, this.requestingModule);
        }
    }

    revoke(id: number, roleID: number) {
        if (id > 0) {
            const data = { id, roleID };
            return this.ds.APIPost('/listing/revoke', data, this.requestingModule);
        }
    }

    offerCompare(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/offerCompare', data, this.requestingModule);
        }
    }

    offerDetails(id: number, role: Role = null) {
        if (id > 0) {
            const data = { id };
            if (role != null)
                data['role'] = role;
            return this.ds.APIPost('/listing/offerDetails', data, this.requestingModule);
        }
    }

    getAssessments(id: number) {
        if (id > 0) {
            const data = { id, includeAssessments: true };
            return this.callListing(data);
        }
    }
    getCharacteristics(id: number) {
        if (id > 0) {
            const data = { id, includeCharacteristics: true };
            return this.callListing(data, 120);
        }
    }
    getServices(id: number) {
        if (id > 0) {
            const data = { id, includeServices: true };
            return this.callListing(data);
        }
    }

    getWorkOrders(id: number, role: Role = Role.NotSet) {
        if (id > 0) {
            const data = { id, role };
            return this.ds.APIPost('/listing/workorders', data, this.requestingModule);
        }
    }

    createWorkOrder(id: number, serviceTypeID: number, offerID: number = 0, escrowID: number = 0, role: Role = Role.NotSet) {
        if (id > 0) {
            const data = { id, role, serviceTypeID, offerID, escrowID };
            return this.ds.APIPost('/listing/createNewWorkOrder', data, this.requestingModule);
        }
    }

    removeService(id: number, serviceID: number) {
        if (id > 0) {
            const data = { id, serviceID };
            return this.ds.APIPost('/listing/removeService', data, this.requestingModule);
        }
    }

    getClosingStatus(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/closingStatus', data, this.requestingModule);
        }
    }

    addAssessment(id: number, oData: assessment) {
        if (id > 0) {
            const data = { id, data: oData };
            return this.ds.APIPost('/listing/addAssessment', data, this.requestingModule);
        }
    }
    removeAssessment(id: number, iAssessmentID: number) {
        if (id > 0) {
            const data = { id, itemID: iAssessmentID };
            return this.ds.APIPost('/listing/removeAssessment', data, this.requestingModule);
        }
    }

    getTaxesAndFees(id: number) {
        if (id > 0) {
            const data = { id, includeTaxesAndFees: true };
            return this.callListing(data);
        }
    }

    changeAssesmentPaidBy(id: number, iAssesmentID: number, paidBy: ToBePaidBy) {
        if (id > 0) {
            const data = { id, assesmentID: iAssesmentID, paidBy };
            return this.callListing(data);
        }
    }

    getLiens(id: number) {
        if (id > 0) {
            const data = { id, includeLiens: true };
            return this.callListing(data);
        }
    }

    addSellerLien(id: number, oData: any) {
        if (id > 0) {
            const data = { id, lien: oData };
            return this.ds.APIPost('/listing/addSellerLien', data, this.requestingModule);
        }
    }

    removeLien(id: number, iLienID: number) {
        if (id > 0) {
            const data = { id, itemID: iLienID };
            return this.ds.APIPost('/listing/removeLien', data, this.requestingModule);
        }
    }

    getEscrows(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/escrows', data, this.requestingModule);
        }
    }

    //#region Disclosures
    getDisclosures(id: number) {
        if (id > 0) {
            const data = { id, includeDisclosures: true };
            return this.callListing(data, 120);
        }
    }

    createDisclsoureServiceOrder(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/createDisclosureServiceOrder', data, this.requestingModule);
        }
    }

    addDisclosure(id: number, description: string, response: string, doc: downloadableContent) {
        if (id > 0) {
            const data = { id, description, response, doc };
            return this.ds.APIPost('/listing/addDisclosure', data, this.requestingModule);
        }
    }

    disclosureDocument(id: number, iDisclosureID: number, iDocumentID: number) {
        if (id > 0) {
            const data = { id, entityID : iDisclosureID, documentID : iDocumentID };
            return this.ds.APIPost('/listing/disclosure/document', data, this.requestingModule);
        }
    }

    removeDisclosureDocument(id: number, iDocumentID: number) {
        if (id > 0) {
            const data = { id, entityID : iDocumentID };
            return this.ds.APIPost('/listing/disclosure/removeDocument', data, this.requestingModule);
        }
    }

    uploadDisclosureDocument(id: number, iDisclosureID: number, document: downloadableContent) {
        if (id > 0) {
            const data = { id, entityID: iDisclosureID, document };
            return this.ds.APIPost('/listing/disclosure/uploadDocument', data, this.requestingModule);
        }
    }

    changeDisclosureValue(id: number, iDisclosureID: number, oValue: any) {
        if (id > 0) {
            const data = { id, entityID : iDisclosureID, value: oValue };
            return this.ds.APIPost('/listing/disclosure/changeValue', data, this.requestingModule);
        }
    }
    //#endregion
    //#region Roles
    getRoles(id: number) {
        if (id > 0) {
            const data = { id, includeRoles: true };
            return this.callListing(data);
        }
    }
    addPartner(id: number, part: Partner) {
        if (id > 0) {
            part['id'] = id;
            const data = part;
            return this.ds.APIPost('/listing/addPartner', data, this.requestingModule);
        }
    }
    acceptPartnership(id: number, roleID: number, ownership: number) {
        if (id > 0) {
            const data = { id, roleID, ownership };
            return this.ds.APIPost('/listing/acceptPartnership', data, this.requestingModule);
        }
    }
    updatePartners(id, oPartners) {
        if (id > 0) {
            const _partners = [];
            if (oPartners && oPartners.length > 0) {
                oPartners.forEach((p) => {
                    _partners.push({ pid: p.pid, firstName: p.firstName, middleName: p.middleName, lastname: p.lastName });
                });
            }
            const data = { id, partners: _partners };
            return this.ds.APIPost('/listing/updatePartners', data, this.requestingModule);
        }
    }
    removePerson(id: number, roleID: number) {
        if (id > 0) {
            const data = { id, roleID };
            return this.ds.APIPost('/listing/removePerson', data, this.requestingModule);
        }
    }
    representCompany(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/representCompany', data, this.requestingModule);
        }
    }
    agentTerms(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/agentTerms', data, this.requestingModule);
        }
    }
    acceptAgentTerms(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/acceptAgentTerms', data, this.requestingModule);
        }
    }
    assumePowerOfAttorney(id: number, roleID: number, pidWithPOA: number, document: downloadableContent) {
        if (id > 0) {
            const data = { id, roleID, pidWithPOA, document };
            return this.ds.APIPost('/listing/assumePOA', data, this.requestingModule);
        }
    }
    givePowerOfAttorney(id: number, roleID: number, pidWithPOA: number, document: downloadableContent) {
        if (id > 0) {
            const data = { id, roleID, pidWithPOA, document };
            return this.ds.APIPost('/listing/givePOA', data, this.requestingModule);
        }
    }
    verifyPowerOfAttorney(id: number, roleID: number) {
        if (id > 0) {
            const data = { id, roleID };
            return this.ds.APIPost('/listing/verifyPOA', data, this.requestingModule);
        }
    }
    getPowerOfAttorney(id: number, roleID: number) {
        if (id > 0) {
            const data = { id, roleID };
            return this.ds.APIPost('/listing/getPOA', data, this.requestingModule);
        }
    }
    revokePowerOfAttorney(id: number, roleID: number) {
        if (id > 0) {
            const data = { id, roleID };
            return this.ds.APIPost('/listing/revokePOA', data, this.requestingModule);
        }
    }
    //#endregion
    //#region Editing
    getEstimatedValue(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/getEstimatedValue', data, this.requestingModule);
        }
    }
    getComparativeProperties(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/getComparativeProperties', data, this.requestingModule);
        }
    }
    preparePropertyDataValues(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/preparePropertyDataValues', data, this.requestingModule);
        }
    }
    setMultiplePropertyDataRecord(id: number, iConfigurationID: number, iValue: number, pendingUpdates: any) {
        if (id > 0) {
            const data = { id, configurationID: iConfigurationID, value: iValue, pendingUpdates };
            return this.ds.APIPost('/listing/setMultiplePropertyDataRecord', data, this.requestingModule);
        }
    }
    savePropertyDataUpdates(id: number, pendingUpdates: any) {
        if (id > 0) {
            const data = { id, pendingUpdates };
            return this.ds.APIPost('/listing/savePropertyDataUpdates', data, this.requestingModule);
        }
    }
    getMedia(id: number) {
        if (id > 0) {
            const data = { id, includeMedia: true };
            return this.callListing(data);
        }
    }
    addMedia(data) {
        return this.ds.APIPost('/listing/addMedia', data, this.requestingModule);
    }
    removeMedia(id: number, iMediaID: number) {
        if (id > 0) {
            const data = { id, itemID: iMediaID };
            return this.ds.APIPost('/listing/removeMedia', data, this.requestingModule);
        }
    }
    setDefaultMedia(id: number, iMediaID: number) {
        if (id > 0) {
            const data = { id, itemID: iMediaID };
            return this.ds.APIPost('/listing/setDefaultMedia', data, this.requestingModule);
        }
    }
    sendListingAgentNotice(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/sendListingAgentNotice', data, this.requestingModule);
        }
    }

    getSellerAgentDisclosure(id: number, disclosureID: number) {
        if (id > 0) {
            const data = { id, dislosureID: disclosureID };
            return this.ds.APIPost('/listing/getSellerAgentDisclosure', data, this.requestingModule);
        }
    }
    acceptSellerAgentDisclosure(id: number, disclosureID: number) {
        if (id > 0) {
            const data = { id, dislosureID: disclosureID };
            return this.ds.APIPost('/listing/acceptSellerAgentDisclosure', data, this.requestingModule);
        }
    }
    getBuyerAgentDisclosure(id: number, disclosureID: number) {
        if (id > 0) {
            const data = { id, dislosureID: disclosureID };
            return this.ds.APIPost('/listing/getBuyerAgentDisclosure', data, this.requestingModule);
        }
    }
    acceptBuyerAgentDisclosure(id: number, disclosureID: number) {
        if (id > 0) {
            const data = { id, dislosureID: disclosureID };
            return this.ds.APIPost('/listing/acceptBuyerAgentDisclosure', data, this.requestingModule);
        }
    }
    //#endregion
    //#region Viewings
    getViewings(id: number) {
        if (id > 0) {
            const data = { id, includeViewings: true };
            return this.callListing(data);
        }
    }
    changeViewing(id: number, visitDate: Date, duration: number) {
        if (id > 0) {
            const data = { id, startDate: visitDate, duration };
            return this.ds.APIPost('/listing/changeViewing', data, this.requestingModule);
        }
    }
    confirmViewing(id: number, visitDate: Date, duration: number) {
        if (id > 0) {
            const data = { id, startDate: visitDate, duration, newStatus: ListingViewingStatus.Confirmed };
            return this.ds.APIPost('/listing/changeViewingStatus', data, this.requestingModule);
        }
    }
    changeViewingStatus(id: number, enNewStatus: ListingViewingStatus) {
        if (id > 0) {
            const data = { id, newStatus: enNewStatus };
            return this.ds.APIPost('/listing/changeViewingStatus', data, this.requestingModule);
        }
    }
    addViewing(id: number, visitDate: Date, duration: number, isOpenHouse: boolean = false) {
        if (id > 0) {
            const data = { id, startDate: visitDate, duration };
            return this.ds.APIPost(isOpenHouse ? '/listing/addOpenHouse' : '/listing/addViewing', data, this.requestingModule);
        }
    }
    //#endregion
    //#region Search Listing Actions
    addToAlerts(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/addToAlerts', data, this.requestingModule);
        }
    }
    addToFavorites(id: number, oCustomer: string = '') {
        if (id > 0) {
            const data = { id, customer: oCustomer };
            return this.ds.APIPost('/listing/addToFavorites', data, this.requestingModule);
        }
    }
    removeBookmark(id) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/removeBookmark', data, this.requestingModule);
        }
    }
    addCustomerForBookmarks(oCustomer: string) {
        const data = { customer: oCustomer };
        return this.ds.APIPost('/listing/addToFavorites', data, this.requestingModule);
    }
    prepareCustomerList(sEmail: string) {
        const data = { email: sEmail };
        return this.ds.APIPost('/listing/addToFavorites', data, this.requestingModule);
    }
    removeFromFavorites(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/removeFromFavorites', data, this.requestingModule);
        }
    }
    sendHouseList(id: number) {
        if (id > 0) {
            const data = { id };
            return this.ds.APIPost('/listing/sendHouseList', data, this.requestingModule);
        }
    }
    createOffer(oData: any) {
        if (oData) {
            return this.ds.APIPost('/listing/createOffer', oData, this.requestingModule);
        }
    }
    sendMessageToSeller(id: number, oMessage: string) {
        if (id > 0) {
            const data = { id, message: oMessage };
            return this.ds.APIPost('/listing/sendMessageToSeller', data, this.requestingModule);
        }
    }
    //#endregion

    private callListing(data: any, timeoutinSecs: number = -1) {
        // TODO - Track if the call is already in progress
        return this.ds.APIPost('/listing/listing', data, this.requestingModule, timeoutinSecs);
    }

}
