import { Pipe, PipeTransform } from '@angular/core';
import { GlobalComponent } from '../../global.component';

@Pipe({ name: 'translateTag' })
export class TranslateTagPipe implements PipeTransform {

    constructor(private gc: GlobalComponent) { }

    transform(sTag: string, args?: string[]): string {
        const _s = this.gc.geti18nTag(sTag);
        return this.fomatTag(_s, args);
    }

    private fomatTag(source: string, repArr: any =  null): string {
        if (repArr != null && Array.isArray(repArr)) {
            // tslint:disable-next-line: prefer-for-of
            for (let _i = 0; _i < repArr.length; _i++) {
                source = source.split('{' + _i.toString() + '}').join(repArr[_i]);
            }
        }
        return source;
    }
}
