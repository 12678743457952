import { RecordTimeStamp } from '../base.model';
import * as moment from 'moment';
import { Utils } from '../../common/utils';

export class CertificationEntity extends RecordTimeStamp {

    // tslint:disable-next-line: variable-name
    VerifiedByName: string = '';
    // tslint:disable-next-line: variable-name
    CertificateHolder: string = '';

    private _peopleCertificationID: number;
    private _pid: number;
    private _serviceTypeID: number;
    private _verifiedBy: number;
    private _certifyingEntity: string = '';
    private _certificateID: string = '';
    private _serviceType: string = '';
    private _expiryDate: Date;
    private _certificateIssueDate: Date;
    private _verificationDate: Date;
    private _verified: boolean;
    public get CertificationID(): number {
        return this._peopleCertificationID;
    }
    public set CertificationID(value: number) {
        this.id = this._peopleCertificationID = value;
    }
    public get PID(): number {
        return this._pid;
    }
    public set PID(value: number) {
        if (this._pid !== value)
            this.isDirty = true;
        this._pid = value;
    }
    public get ServiceTypeID(): number {
        return this._serviceTypeID;
    }
    public set ServiceTypeID(value: number) {
        if (this._serviceTypeID !== value)
            this.isDirty = true;
        this._serviceTypeID = value;
    }
    public get ServiceType(): string {
        return this._serviceType;
    }
    public set ServiceType(value: string) {
        this._serviceType = value;
    }
    public get CertifyingEntity(): string {
        return this._certifyingEntity;
    }
    public set CertifyingEntity(value: string) {
        if (this._certifyingEntity !== value)
            this.isDirty = true;
        this._certifyingEntity = value;
    }
    public get CertificateID(): string {
        return this._certificateID;
    }
    public set CertificateID(value: string) {
        if (this._certificateID !== value)
            this.isDirty = true;
        this._certificateID = value;
    }
    public get CertificateIssueDate(): Date {
        return this._certificateIssueDate;
    }
    public set CertificateIssueDate(value: Date) {
        if (this._certificateIssueDate !== value)
            this.isDirty = true;
        this._certificateIssueDate = value;
    }
    public get ExpiryDate(): Date {
        return this._expiryDate;
    }
    public set ExpiryDate(value: Date) {
        if (this._expiryDate !== value)
            this.isDirty = true;
        this._expiryDate = value;
        this._verified = false;
        this._verifiedBy = 0;
    }
    public get Expired(): boolean {
        if (this._expiryDate)
            return moment(this._expiryDate).isBefore(new Date());
        else
            return true;
    }
    public get Verified(): boolean {
        return this._verified;
    }
    public set Verified(value: boolean) {
        if (this._verified !== value)
            this.isDirty = true;
        this._verified = value;
    }
    public get VerifiedBy(): number {
        return this._verifiedBy;
    }
    public set VerifiedBy(value: number) {
        if (this._verifiedBy !== value)
            this.isDirty = true;
        this._verifiedBy = value;
    }

    public get VerificationDate(): Date {
        return this._verificationDate;
    }
    public set VerificationDate(value: Date) {
        if (this._verificationDate !== value)
            this.isDirty = true;
        this._verificationDate = value;
    }

    public IsComplete(): boolean {
        return this.ServiceTypeID > 0 && !Utils.isNullOrEmpty(this.CertifyingEntity) && moment(this.CertificateIssueDate).isAfter(Utils._minDate);
    }
}
