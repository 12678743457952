import { CurrencyPipe } from '@angular/common';
import { Router, NavigationBehaviorOptions } from '@angular/router';
declare var $: any;
import * as moment from 'moment';
import { appEnvironment } from '../environments/environment';
import { Role, Session, DialogData, Profile, MessageNotificationType, User } from './models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './common/dialogs/confirmationDialog.component';
import { ComponentType } from '@angular/cdk/portal';
import { AngularEditorConfig } from './htmlEditor/config';
import { handover, AppURLs } from './models/constants';
import { GlobalComponent } from './global.component';
import { ListingService } from './listing/listing.service';
import { DataService } from './util/APICaller.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { clearSession } from './common/sessionService';
import { Utils } from './common/utils';

export abstract class BaseComponent {

    dateFormat: string = '';
    defaultStaffRoleID: number = 0;
    phoneMask: any[] = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    myBRAND: string = (appEnvironment.appBRAND as string);

    dateMask = {
        guide: true,
        showMask: true,
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
    };

    braintreeClientTokenEndPoint = appEnvironment.apiEndPoint + '/pre/braintree/getclienttoken';
    servicePurchaseEndPoint = appEnvironment.apiEndPoint + '/pre/braintree/createpurchase';

    runningForProd: boolean = false;
    baseTable: any;
    timeout: any;
    componentBusy: boolean = false; // Different from GLOBAL, which shows common busy indicator, this can/should be used locally in the component

    appURLs = AppURLs;
    handover = handover;

    // tslint:disable-next-line: ban-types
    alert: Function = alert;
    // tslint:disable-next-line: ban-types
    isNaN: Function = Number.isNaN;
    Role: typeof Role = Role;
    Math = Math;

    user: Session = new Session();
    userdetails: User = new User();
    loggedIn: boolean = false;

    tags: any = [];
    translation: any = [];
    globalTags: any = [];

    pageNumber: number = 1;
    itemsPerPage: number = 10;

    //#region NGX Data Table
    messages: any = { emptyMessage : ''};
    binding: boolean;
    expanded: any = {};
    headerHeight: number = 50;
    footerHeight: number = 50;
    footerHidden: number = 0;
    rowHeight: number = 50;
    numberOfRows: number = 10;
    //#endregion

    maxHeight: number = 0;

    readonly today = Utils.today;
    readonly tomorrow = Utils.tomorrow;

    public appBRAND: string = appEnvironment.appBRAND;

    accordionStep: number = 0;
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '25rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        uploadUrl: 'v1/images',
        fonts: [
            { name: 'Arial', class: 'arial' }
            , { name: 'Calibri', class: 'calibri' }
            , { name: 'Comic Sans MS', class: 'comic-sans-ms' }
            , { name: 'Times New Roman', class: 'times-new-roman' }
        ]
    };

    protected myResourceCategory: string = '';
    protected ls: Subscription;
    protected ss: any;
    protected _ss: Subscription;

    protected utils = Utils ; // for Templates
    protected Utils: Utils = new Utils(); // for TS

    constructor(protected sResourceCategory: string, protected route: Router, public gc: GlobalComponent = null) {
        this.myBRAND = appEnvironment.appBRAND;
        this.defaultStaffRoleID = appEnvironment.defaultStaffRoleID;
        this.myResourceCategory = sResourceCategory;

        if (this.gc == null) {
            this.gc = new GlobalComponent();
            this.gc.restoreuser();
        }

        this.restoreUser();

        this.runningForProd = appEnvironment.production;

        this.ls = this.gc.loginChanged.subscribe(() => {
            this.restoreUser();
        });

        this.maxHeight = window.innerHeight - 400;
    }

    public setuser(v: Session) {
        this.gc.setuser(v);
        this.restoreUser();
    }

    onApiError(e) {

        if (e && (e.status === '400' || e.status === '401' || e.status === '403' || e.status === '404' || e.status === 401 || e.status === 403 || e.status === 404)) { // || e.status === '0' ||

            // Session and other clearing handled in GLOBAL Component onERROR
            clearSession();

            this.GotoHome();
            return;
        }

        if (!Utils.isNullOrEmpty(e)) {
            let _code = e;
            if (this.translation[_code] && this.translation[_code].v)
                this.showError(this.translation[_code].v);
            else {
                if (_code.startsWith && !_code.startsWith('err') && !_code.startsWith('msg'))
                    _code = 'err' + _code;

                if (typeof _code === 'string') {
                    if (this.globalTags[_code] && this.globalTags[_code].v)
                        this.showError(this.globalTags[_code].v);
                    else
                        this.showError(_code);
                }
            }
        }
    }

    GetCurrencyPipe() {
        // let _currencyCode = 'USD'; //this.SessionISOCurrencyCode();
        // if (_currencyCode != '')
        //    return new CurrencyPipe(_currencyCode);
        // else
        return new CurrencyPipe(appEnvironment.defaultISOLanguageCode); // default it to $
    }

    public FormatNumberToCurrency(amount: number, currency?: string): string {
        if (amount == null) amount = 0;
        const _currencyPipe = this.GetCurrencyPipe();
        let _currencyCode = currency;
        if (Utils.isNullOrEmpty(_currencyCode)) _currencyCode = appEnvironment.defaultISOCurrencyCode;
        if (Utils.isNullOrEmpty(_currencyCode)) _currencyCode = 'USD';

        const _user = this.gc.user;
        let locale = Utils.isNullOrEmpty(_user.isoLanguageCode) ? appEnvironment.defaultISOLanguageCode : _user.isoLanguageCode;

        if (locale == null) locale = 'en-us';

        if (amount >= 0)
            return _currencyPipe.transform(amount, _currencyCode, 'symbol', null, locale);
        else
            return '(' + _currencyPipe.transform(amount, _currencyCode, 'symbol', null, locale).replace('-', '') + ')';
    }

    selectAllContent($event) {
        $event.target.select();
    }

    OpenDownloadableDoc(data: any, fileName: string, mimeName: string, newWindow: boolean = false) {
        let byteArray;
        if (data) {

            if (data.content === undefined)
                byteArray = data;
            else if (data.content != null)
                byteArray = data.content;
            else
                return;

            if ((byteArray as string).toLowerCase().startsWith('http')) {
                // Open in new Window
                this.gotoURL(byteArray);
                return;
            }

            if (Utils.isNullOrEmpty(fileName)) fileName = 'document';

            let _mime: string = mimeName;
            if (Utils.isNullOrEmpty(_mime)) _mime = 'application/text';
            if (!_mime.toLowerCase().startsWith('application/')) _mime = 'application/' + _mime;
            if (!_mime.endsWith(';')) _mime += ';';

            const _data = byteArray.split(',');

            let b64Data: any;
            if (_data.length > 1) {
                b64Data = _data[1];
                _mime = _data[0];
            } else
                b64Data = _data[0];

            // var contentFile = new Blob([byteArray], { type: mimeName.toLowerCase()});
            const contentFile = Utils.b64toBlob(b64Data, _mime);

            const nav = (window.navigator as any);

            if (nav && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(contentFile);
            }

            if (newWindow) {
                const fileURL = URL.createObjectURL(contentFile);
                window.open(fileURL);
            } else {

                let _extn = '';
                let _extns = _mime.split(';');
                if (_extns.length > 0) {
                    _extns = _extns[0].split('/');
                    if (_extns.length > 1)
                        _extn = _extns[1];
                    else
                        _extn = _extns[0];
                }

                const contentUrl = URL.createObjectURL(contentFile);
                const link = document.createElement('a');
                link.href = contentUrl;
                link.download = fileName + (Utils.isNullOrEmpty(_extn) ? '' : ('.' + _extn));
                link.click();
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(contentUrl);
                }, 100);
            }
        }
    }


    queryStringToJSON(qs) {
        qs = qs || location.search.slice(1);

        const pairs = qs.split('&');
        const result = {};
        pairs.forEach((p) => {
            const pair = p.split('=');
            const key = pair[0];
            const value = decodeURIComponent(pair[1] || '');

            if (result[key]) {
                if (Object.prototype.toString.call(result[key]) === '[object Array]') {
                    result[key].push(value);
                } else {
                    result[key] = [result[key], value];
                }
            } else {
                result[key] = value;
            }
        });

        return JSON.parse(JSON.stringify(result));
    }

    GoToURLWithSession(url: string, name: string, value: any) {

        if (Utils.isNullOrEmpty(url)) return;

        try {
            if (value) {
                if (typeof value === 'object')
                    value = JSON.parse(value);
                else
                    value = value.toString();
            } else
                value = '';
        } catch (e) { value = ''; }

        if (!Utils.isNullOrEmpty(name))
            sessionStorage.setItem(name, value);

        this.gotoURL(url.toLowerCase());
    }
    EncryptQueryString(v) { return v; }

    gotoRoute(sRoute: string, sParams: any = null, overrideStealth: boolean = false, qsToSessionStorage: boolean = false) {
        if (!this.gc.stealthMode || overrideStealth === true) {
            if (!Utils.isNullOrEmpty(sRoute)) {
                if (sRoute.startsWith('http')) {
                    window.open(sRoute, '_blank'); // this.route.navigateByUrl(sRoute);
                } else if (!this.route.url.toLowerCase().endsWith(sRoute.toString().toLowerCase())) {
                    const _q = sRoute.split('?');

                    if (_q.length > 1 && !Utils.isNullOrEmpty(_q[1])) {
                        if (qsToSessionStorage) {
                            for (const _p of _q[1].split('&')) {
                                const _v = _p.split('=');
                                if (_v.length === 2) {
                                    sessionStorage.setItem(_v[0], _v[1]);
                                }
                            }
                        } else
                            sParams = this.queryStringToJSON(_q[1]);
                    }

                    _q[0] = ((_q[0] as string).startsWith('/') ? '' : '/') + _q[0];

                    console.log([_q[0]]);

                    if (sParams)
                        this.route.navigate([_q[0]], { queryParams: sParams });
                    else
                        this.route.navigate([_q[0]]);
                }
            }
            this.gc.setBusy(false);
        } else
            this.showMessage('We are working diligently to bring you a great new platform!!!');
    }

    gotoListing(iListingID: number) {
        if (iListingID && iListingID > 0) {
            this.GoToURLWithSession(AppURLs.listing, handover.listingID, iListingID.toString());
        }
    }

    gotoURL(sRoute: string, qsToSessionStorage: boolean = false) {
        this.gotoRoute(sRoute, null, false, qsToSessionStorage);
    }

    gotoLogin() {
        this.gotoRoute(AppURLs.login);
    }

    gotoAssetPage(iAssetID: number) {
        if (!this.gc.stealthMode && iAssetID > 0) {
            sessionStorage.setItem('ad', JSON.stringify(JSON.parse('{ "showAsAssetPage" : true, "startingCategory" : { "id" : ' + iAssetID.toString() + '}}')));
            this.route.navigate([AppURLs.assetPage]);
        } else
            this.showMessage('We are working diligently to deliver a great new platform!!!');
    }

    GotoHome() {
        if (this.route) this.route.navigate(['/']);
    }

    GotoWelcome() {
        if (this.route) this.route.navigate([AppURLs.accountWelcome]);
    }

    toArrayBuffer(oBinary) {
        if (oBinary) {
            const arraybuffer = new ArrayBuffer(oBinary.length);
            const bytearray = new Uint8Array(arraybuffer);
            for (let i = 0; i < oBinary.length; i++) {
                bytearray[i] = oBinary.charCodeAt(i);
            }
            return bytearray.buffer;
        } else
            return null;
    }

    /* swimlane grid */
    onPage(event) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            // console.log('paged!', event);
        }, 100);
    }
    onDetailToggle(event) {
        // console.log('Detail Toggled', event);
    }

    toggleExpandRow(row) {
        if (this.baseTable)
            this.baseTable.rowDetail.toggleExpandRow(row);
    }
    /* end swimlane grid */

    public showDialog(dialogBox: MatDialog, dialogData: DialogData, dialogBack?: any, isModal: boolean = true) {
        dialogBox.open(ConfirmationDialogComponent, { disableClose: isModal, data: dialogData }).
            afterClosed().subscribe((result: any) => {
                if (dialogBack !== undefined) {
                    dialogBack(result);
                }
            });
    }

    public openDialog<T>(dialogBox: MatDialog, component: ComponentType<T>, data: any, dialogBack?: any, isModal: boolean = true) {
        dialogBox.open(component, { disableClose: isModal, maxHeight: '500px', data: {} }).
            afterClosed().subscribe((result: any) => {
                if (dialogBack !== undefined) {
                    dialogBack(result);
                }
            });
    }

    isSavePending() {
        const _c = document.getElementById('pageContextSave');
        if (_c != null) {
            const _a = _c.getAttribute('class');
            if (_a != null && _a.indexOf('hide') < 0)
                return true;
        }
        return false;
    }

    showSave() {
        this.gc.saveVisibility.emit(true);
        if (this.ss == null) {
            this.ss = this.gc.saveClicked;
        }
    }

    hideSave() {
        this.gc.saveVisibility.emit(false);
    }

    showError(msg: string) {
        if (!Utils.isNullOrEmpty(msg)) {
            if (this.gc)
                this.gc.onMessageNotify.emit({ message: msg, messageType: MessageNotificationType.Error, data : null });
            else
                alert(msg);
        }
    }

    showResourceError(msg: string) {
        if (!Utils.isNullOrEmpty(msg)) {
            if (this.gc)
                this.gc.onMessageNotify.emit({ message: { key: msg }, messageType: MessageNotificationType.Error, data: null });
            else
                alert(msg);
        }
    }

    showMessage(msg: string) {
        if (!Utils.isNullOrEmpty(msg)) {
            if (this.gc)
                this.gc.onMessageNotify.emit({ message: msg, messageType: MessageNotificationType.Message, data: null });
            else
                alert(msg);
        }
    }

    showResourceMessage(msg: string) {
        if (!Utils.isNullOrEmpty(msg)) {
            if (this.gc)
                this.gc.onMessageNotify.emit({ message: { key: msg }, messageType: MessageNotificationType.Message, data: null });
            else
                alert(msg);
        }
    }

    showWarning(msg: string) {
        if (!Utils.isNullOrEmpty(msg)) {
            if (this.gc)
                this.gc.onMessageNotify.emit({ message: msg, messageType: MessageNotificationType.Warning, data: null });
            else
                alert(msg);
        }
    }

    showResourceWarning(msg: string) {
        if (!Utils.isNullOrEmpty(msg)) {
            if (this.gc)
                this.gc.onMessageNotify.emit({ message: { key: msg }, messageType: MessageNotificationType.Warning, data: null });
            else
                alert(msg);
        }
    }

    showSuccessMessage() { this.showMessage('Action completed successfully.'); }
    hideMessage() { this.gc.onMessageNotify.emit({ message: '', messageType: MessageNotificationType.None, data: null }); }
    hideError() { this.gc.onMessageNotify.emit({ message: '', messageType: MessageNotificationType.None, data: null }); }
    clearMessage() { this.gc.onMessageNotify.emit({ message: '', messageType: MessageNotificationType.ClearMessage, data: null }); }

    clearDirty(oObject) {
        try {
            oObject['isDirty'] = false;
        } catch (e) { }
    }

    setDirty(oObject) {
        try {
            oObject['isDirty'] = true;
        } catch (e) { }
    }

    getInitials(sName: string) {
        if (sName) {
            const _s = sName.split(' ');
            if (_s.length === 1)
                return _s[0].substr(0, 1);
            else
                return _s[0].substr(0, 1) + _s[_s.length - 1].substr(0, 1);
        } else
            return '?';
    }

    sendIM(sMessage: any) {
        try {
            if (this.gc.hubConnection) {
                this.gc.hubConnection.start().done(() => {
                    const _user = this.gc.user;
                    const _o: any = {};
                    _o['senderID'] = this.gc.hubConnectionID;
                    _o['userID'] = (_user && _user.details) ?  _user.details.PID : 0;
                    if (typeof sMessage === 'object')
                        _o['message'] = JSON.stringify(sMessage);
                    else
                        _o['message'] = sMessage.toString();

                    let _sessionID: string = '';
                    if (_user && _user.sessionID)
                        _sessionID = _user.sessionID;

                    const _id: any = {};
                    _id['senderID'] = this.gc.hubConnectionID;
                    _id['sessionID'] = _sessionID;

                    // IMPORTANT Message to be sent as string
                    try {
                        $.connection.centralHub.server.send(JSON.stringify(_id), JSON.stringify(_o));
                    } catch (e) { }
                });
            }
        } catch (e) { }
    }

    getUsersLocale(defaultValue: string = 'en-us'): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
        if (Utils.isNullOrEmpty(lang))
            return 'en-us';
        else
            return lang;
    }

    accordionSetStep(index: number) {
        this.accordionStep = index;
    }

    accordionNextStep() {
        this.accordionStep++;
    }

    accordionPrevStep() {
        this.accordionStep--;
    }

    geti18nTag(sTag: string = '') {
        if (this.gc)
            return this.gc.geti18nTag(sTag);
        else
            return '';
    }

    protected isValidMediaExtension(sURL: string) {
        let _found = false;
        if (!Utils.isNullOrEmpty(sURL)) {
            sURL = sURL.toLowerCase();
            const _typeArr = Utils.fileExtension.split(',');
            _typeArr.forEach((ex) => {
                if (_found !== true)
                    _found = sURL.endsWith('.' + ex);
            });
        }
        return _found;
    }

    protected castToMe(oObject: any) {
        return Utils.castTo(oObject, this);
    }

    protected getProfileGroup() {
        let _p: any = localStorage.getItem('pr');
        if (_p == null || _p.toString().trim() === '')
            _p = new Profile();
        else {
            try {
                _p = JSON.parse(_p) as Profile;
            } catch (e) {
                _p = new Profile();
            }
        }

        return _p.pg;
    }

    protected isRouteGuarded(sPath: string = '', oConfig: any = null, guardName: string = 'AuthGuard') {
        const guardTypeArray = [
            'canActivate',
            'canActivateChild'
        ];

        const _currentURL = sPath === '' ? this.route.url.substr(1) : sPath;
        const _path = _currentURL.split('/');
        const _startingConfig = oConfig ? oConfig : this.route.config;

        const currentRouteConfig = _startingConfig.find((f) => f.path.toString().toLowerCase() === _path[0].toLowerCase());

        let foundGuard = false;

        if (currentRouteConfig) {
            if (_path.length === 1) {
                for (const guardType of guardTypeArray) {
                    if (!foundGuard) {
                        if (currentRouteConfig[guardType]) {
                            for (const guard of currentRouteConfig[guardType]) {
                                if (guard.name === guardName || guard.name === 'SystemGuard' ) {
                                    foundGuard = true;
                                    break;
                                }
                            }
                        } else if (_path.length > 1) {
                            return this.isRouteGuarded(_path[1], currentRouteConfig._loadedRoutes);
                        }
                    } else {
                        break;
                    }
                }
            } else if (_path.length > 1 && currentRouteConfig._loadedRoutes!= null ) {
                return this.isRouteGuarded(_path[1], currentRouteConfig._loadedRoutes);
            }
        }
        return foundGuard;
    }

    protected getMyListingsForSale(dataservice: DataService, delegate: any = null) {
        if (this.gc.myListings && this.gc.myListings.length > 0) {
            if (delegate)
                delegate(this.gc.myListings);
            else
                return  this.gc.myListings;
        } else {
            let _response;
            (new ListingService(dataservice, this.route, this.myResourceCategory)).getMyListingsForSale().subscribe(
                (data) => { _response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.gc.myListings = null;
                        else {
                            this.gc.myListings = _response.data;
                            if (delegate)
                                delegate(this.gc.myListings);
                        }
                }
            );
        }
    }

    // Cancel Element Event
    protected cancelElementEvent($event) {
        if ($event) {
            if ($event.stopPropagation)
                $event.stopPropagation();
            if ($event.preventDefault)
                $event.preventDefault();
        }
    }

    protected restoreUser() {
        this.user = this.gc.user;
        this.loggedIn = this.gc.loggedIn;
        if (this.loggedIn) {
            if (this.user != null && this.user.details != null)
                this.userdetails = this.user.details;
            else
                this.userdetails = new User();

        } else {
            this.userdetails = new User();
        }
    }

    // Call from ngDestroy of Components
    protected destoryLoginSubscription() {
        if (this.ls)
            this.ls.unsubscribe();
    }

    protected fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o = o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0)
}
