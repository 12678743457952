import { Component, ViewChild, Inject } from '@angular/core';
import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAction } from '@murbanczyk-fp/ngx-gallery';
import { BaseDialog } from '../../base.dialog';
import { listing, ListingSearchResponse } from '../../models';

@Component({
    selector: 'app-listing-media-dialog'
    , styleUrls: ['../edit/listingMedia.component.css']
    , template: `
<div mat-dialog-title>
    <h4 class="nobackground">{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
        <div *ngIf="galleryImages && galleryImages.length > 0">
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
        </div>
        <div class="app-flex-container app-2col-listingMediaDialog">
            <div>
                <a *ngIf="listing.canSubmitOffer && !listing.sold" class="btn btn-primary" (click)="submitOffer(listing.id, false)">Submit Offer</a>
            </div>
            <div>
                <a class="app float-right" matTooltip="{{listing.bookmarked === true ? 'Remove this property from my favorites' : 'Add this property to my favorites' }}" (click)='toggleFav(listing.id, listing.bookmarked, listing.address)' *ngIf="!listing.sold && !listing.isSeller"><span class="fa-stack"><i class="fa {{listing.bookmarked ? 'fa-heart  green' : 'fa-heart-o'}} fa-stack-1x"></i><i *ngIf="listing.bookmarked" class="fa fa-ban fa-stack-2x text-danger"></i></span></a>
                <a class="app float-right" matTooltip="Send visit request to seller" (click)="sendVisitRequest(listing.id)" *ngIf="!listing.sold"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-eye fa-stack-1x fa-inverse"></i></span></a>
                <a class="app float-right" matTooltip="Send message to Seller(s)" *ngIf="!listing.sold && listing.hasSellers" (click)='composeMessageToSellers(listing.streetAddress, listing.sellerPIDs)'><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-comment fa-stack-1x fa-inverse"></i></span></a>
            </div>
        </div>
</div>
`
})
export class ListingMediaDialog extends BaseDialog {

    listing: ListingSearchResponse;
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    galleryAction: NgxGalleryAction[];

    constructor(dialogRef: MatDialogRef<ListingMediaDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('listingMediaDialog', null, null, dialogRef, data);

        this.galleryOptions = [
            {
                image: true, width: '100%', height: '400px', previewFullscreen: true, arrowPrevIcon: 'fa fa-arrow-circle-o-left green', arrowNextIcon: 'fa fa-arrow-circle-o-right green'
                , imageActions: this.galleryAction, thumbnailsPercent: 20
            }
            , { breakpoint: 500, width: '100%' }
        ];

        if (data) {
            this.listing = data;
            this.galleryImages = this.listing.media.map((m) => {
                const _m = new NgxGalleryImage({
                    description: m.description
                    , url: m.url
                    , label: m.title
                    , small: m.url
                    , medium: m.url
                    , big: m.url
                });
                return _m;
            });
        }

        if (this.listing == null || this.listing.id === 0 || this.listing.media == null || this.listing.media.length === 0) {
            setTimeout(() => { this.close(null) }, 1000);
        }
    }
}
