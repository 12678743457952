import { Directive, ElementRef } from '@angular/core';
import { GlobalComponent } from '../../global.component';


@Directive({
    selector: '[dirtyWatch]',
    host: { "(input)": 'onChange($event)' }
})
export class DirtyWatchDirective {
    constructor(
        private _element: ElementRef,
        private global: GlobalComponent) {
    }

    onChange(e: any) {
        if (this.global.onModelChanges)
            this.global.onModelChanges.emit(e);
    }
}

@Directive({
    selector: '[dirtyWatchSelect]',
    host: { "(selectionChange)": 'onChange($event)' }
})
export class DirtyWatchSelectDirective {

    constructor(
        private _element: ElementRef,
        private global: GlobalComponent) {
    }

    onChange(e: any) {
        if (this.global.onModelChanges)
            this.global.onModelChanges.emit(e);
    }
}
