<div class="appContent">
	<h2 class="pageHeader">Work Orders {{lblHeader}}{{isLender === true ? "/ Loans" : ""}}</h2>
	<mat-progress-bar mode="indeterminate" *ngIf="this.componentBusy === true || bindingListings === true"></mat-progress-bar>
	<mat-card *ngIf="spView === true && bindingListings === false" class="section">
		<mat-card-content>
			<div class="app-flex-container app-2col">
				<ng-template [ngIf]="(this.listings && this.listings.length > 0)" [ngIfElse]="noSPListings">
					<mat-form-field class="auto">
						<mat-label class="placeholder">Property <i class="fa fa-question-circle-o warning-color"></i></mat-label>
						<mat-select [(ngModel)]="selectedListingID" (selectionChange)="listingSPChanged()" [disabled]="this.componentBusy === true || this.listings.length == 1">
							<mat-option *ngFor="let o of listings" [value]="o.id" class="auto">{{o.name == null ? ( 'Listing # ' + o.id) : o.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-template>
				<ng-template #noSPListings>
					<span *ngIf="this.componentBusy === false">No properties for selected criteria.</span>
				</ng-template>
				<div>
					<mat-checkbox [(ngModel)]="includeClosed" class="float-right" (change)="listingIDs = []; getSPWorkOrderListings(true)" [disabled]="includeClosed">Include Closed Work Orders&nbsp;&nbsp;</mat-checkbox>
					<mat-checkbox [(ngModel)]="allOperators" class="float-right" *ngIf="this.user.details && this.user.details.IsAdministrator === true"
								  [disabled]="allOperators" (change)="listingIDs = []; getSPWorkOrderListings(true)">For All Operators&nbsp;&nbsp;</mat-checkbox>
				</div>
			</div>
			<div class="app-flex-container app-3col">
				<mat-form-field *ngIf="operators != null && operators.length > 1" class="normal">
					<mat-label class="placeholder">Staff <i class="fa fa-question-circle-o warning-color" title="Filter for selected Staff."></i></mat-label>
					<mat-select multiple [(ngModel)]="selectedStaffIDs" (selectionChange)="bindWorkOrders(true)">
						<mat-option *ngFor="let o of operators" [value]="o.id">{{o.name }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field *ngIf="serviceTypes != null && serviceTypes.length > 1" class="normal">
					<mat-label class="placeholder">Services <i class="fa fa-question-circle-o warning-color" title="Filter for selected services."></i></mat-label>
					<mat-select multiple [(ngModel)]="selectedServicesIDs" (selectionChange)="bindWorkOrders(true)">
						<mat-option *ngFor="let o of serviceTypes" [value]="o.id">{{o.name }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field *ngIf="statuses != null && statuses.length > 1" class="normal">
					<mat-label class="placeholder">Status <i class="fa fa-question-circle-o warning-color" title="Filter for selected status."></i></mat-label>
					<mat-select multiple [(ngModel)]="selectedStatuses" (selectionChange)="bindWorkOrders(true)">
						<mat-option *ngFor="let o of statuses" [value]="o.id">{{o.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card *ngIf="spView === false  && bindingListings === false && showListings === true">
		<ng-template [ngIf]="this.listings && this.listings.length > 0" [ngIfElse]="noListings">
			<div class="section">
				<mat-form-field class="first full">
					<mat-select [(ngModel)]="listingID" (selectionChange)="listingChanged()" [disabled]="this.componentBusy === true || this.listings.length == 1">
						<mat-option *ngFor="let o of listings" [value]="o.id" class="auto">{{o.name == null ? 'Listing # ' + o.id : o.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</ng-template>
		<ng-template #noListings>
			<span *ngIf="this.componentBusy === false">Currently there are no properties in processing.</span>
		</ng-template>
	</mat-card>
	<mat-drawer-container class="app-sidenav-container" autosize style="min-height : 300px;background:transparent;margin-top:1em">
		<mat-drawer #drawer class="app-sidenav" mode="side">
			<h3>Available Services <i class="fa fa-arrow-circle-o-right float-right" title="close" (click)="drawer.close()"></i></h3>
			<a *ngFor="let st of availableServiceTypes; let i = index" class="block link help-help {{i%2 === 0 ? 'alt' : ''}}" [dndDraggable]="st" [dndEffectAllowed]="'all'">
				{{st.name}}
			</a>
		</mat-drawer>
		<div class="app-sidenav-content" dndDropzone (dndDrop)="addWorkOrder($event)" style="min-height : 300px">
			<div *ngIf="!drawer.opened && this.listingID > 0 && this.availableServiceTypes.length > 0">
				<button type="button" mat-button (click)="drawer.toggle()"><i class="fa fa-arrow-circle-o-left"></i> &nbsp;View additional Work Order type(s) to create New Work Order</button>
				<a (click)="showPurchaseAgreement(this.offerForSelectedListing)" class="float-right" title="View Purchase Agreement"><i class="material-icons">line_style</i></a>
			</div>
			<div class="section">
				<ng-template [ngIf]="(this.workOrders && this.workOrders.length > 0)" [ngIfElse]="noWorkOrders">
					<mat-accordion multi>
						<mat-expansion-panel *ngFor="let workOrder of workOrders  | paginate: { itemsPerPage: 10 , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<span title="{{workOrder.id}}">
										{{(listingID === 0 && workOrder.address) ? workOrder.address.FullStreetAddress : ''}}&nbsp; {{workOrder.type == null || utils.isNullOrEmpty(workOrder.type.description) ? '' : ('[' + workOrder.type.description + ']')}}
										<i class="fa fa-asterisk" title="{{workOrder.options}}" *ngIf="utils.isNullOrEmpty(workOrder.options) === false"></i>
									</span>
								</mat-panel-title>
								<mat-panel-description style="display:initial;flex-grow:1">
									<a (click)="changeSP(workOrder, $event)" class="link float-right" *ngIf="workOrder.canChangeServiceProvider" title="{{workOrder.serviceProviderID > 0 ? 'Change Service Provider' : 'Pick a Service Provider' }}">{{workOrder.serviceProviderID == 0? 'Select' : 'Change'}}<i class='fa {{workOrder.serviceProviderID == 0 ? "fa-search" : "fa-wrench"}}'></i></a>
									<i class="fa fa-comment-o float-right" title="Send Message to Provider" *ngIf="workOrder.canSendMessageToSP" (click)="sendMsgtoSP(workOrder.operatorID)"></i>
									<span class="float-right">{{spView === true ? (workOrder.escrowID > 0 ? 'Escrow # ' + workOrder.escrowID : '' ) : workOrder.serviceProvider}} &nbsp;</span>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div class="section">
								<div *ngIf="forRole != null">
									<a class="link" *ngIf="workOrder.canChangeServiceProvider && workOrder.bids != null && workOrder.bids.length > 0" (click)="showBidsAndRFQs(workOrder.id, false)" title="Click to review bids Submitted by various Service Providers">
										<label matBadge="{{workOrder.bids.length}}" matBadgePosition="after" matBadgeColor="accent" title="# of Bids" matBadgeOverlap="false">Bids</label>
									</a>
									<a class="link" *ngIf="workOrder.canChangeServiceProvider && workOrder.rfqs != null && workOrder.rfqs.length > 0" (click)="showBidsAndRFQs(workOrder.id, true)" title="Click to review Request for quotes sent to various Service Providers">
										<label matBadge="{{workOrder.rfqs.length}}" matBadgePosition="after" matBadgeColor="accent" title="# of Requests for Quote"  matBadgeOverlap="false">RFQs</label>
									</a>
								</div>
								<div>
									<label class="caption">Paid By </label><label class="data-value auto" title="Buyers - {{workOrder.buyers}}, Seller - {{workOrder.sellers}}">{{workOrder.paidBy}}</label>
									<i class="fa fa-xing-square" title="Assume Liability" *ngIf="workOrder.canAssumeLiability" (click)="assumeLiability(workOrder)"></i>
									<a (click)="sendMessageToRequestor(workOrder.id)" *ngIf="user && user.details && user.details.CompanyID == workOrder.serviceProviderID" title="Send Message to people who requested  and who are paying for this service"><i class="fa fa-comment-o"></i></a>
								</div>
								<div>
									<label class="caption">Cost<sup>*</sup><i class="fa fa-question-circle-o warning-color" title="* Estimated"></i></label>
									<label class="data-value auto">
										<span title="Cost to be Charged" *ngIf="workOrder.canUpdate === false" style="padding-right:10px">{{workOrder.cost}}</span>
										<mat-form-field *ngIf="workOrder.canUpdate === true" class="normal">
											<mat-label class="placeholder">Amount<i class="fa fa-question-circle-o warning-color" title="Total amount to be charged for this Work Order"></i></mat-label>
											<input matInput appDisableAutofill [(ngModel)]="workOrder.cost" (change)="updateWorkOrderValue($event.target.value, 'cost', i, 'decimal')" type="number" (keydown)="utils.numOnlyLimitingLength($event, 10, false)" />
										</mat-form-field>
										<span *ngIf="workOrder.amountPaid > 0" title="Amount Paid" class="green-background" style="padding:0 10px">{{(utils.isNullOrEmpty(workOrder.cost) ? '' : ' / ') + FormatNumberToCurrency(workOrder.amountPaid)}}</span>
										<span *ngIf="workOrder.amountDemanded > 0 " title="Amount Demanded" class="warning-background" style="padding:0 10px">{{(utils.isNullOrEmpty(workOrder.cost) && workOrder.amountPaid == 0 ? '' : ' / ') +  FormatNumberToCurrency(workOrder.amountDemanded)}}</span>
									</label>
									<i class="fa fa-credit-card" title="Pay Now" (click)="payBalance(workOrder)" *ngIf="canPayBalance"></i>
								</div>
								<div *ngIf="listingID > 0">
									<label class="caption">Closing Date</label><label class="data-value auto">{{utils.DisplayDate(workOrder.closingDate, 'Not Scheduled by Escrow')}}</label>
									<a (click)="showPurchaseAgreement(workOrder.offerID)" *ngIf="workOrder.canViewPurchaseAgreement" class="float-right" title="View Purchase Agreement"><i class="material-icons">line_style</i></a>
									<a (click)="sendMessageToEscrow(workOrder.id)" *ngIf="workOrder.canSendMessageToEscrow" class="float-right" title="Send Message to Escrow"><i class="material-icons">speaker_notes</i></a>
								</div>
								<div class="app-flex-container app-3col section">
									<mat-form-field *ngIf="this.operators && this.operators.length > 0">
										<mat-label class="placeholder">Service Provider Staff Assigned</mat-label>
										<mat-select [(ngModel)]="workOrder.operatorID" (selectionChange)="updateWorkOrderValue($event.value, 'operatorID', i)" [disabled]="!workOrder.canUpdate" style="width:Calc(100% - 24 px)">
											<mat-option *ngFor="let o of operators" [value]="o.id">{{o.name.split('[')[0]}}</mat-option>
										</mat-select>
										<i *ngIf="workOrder.canSendMessageToSP" class="fa fa-comment-o" title="Send Message to Staff Assigned by Service Provider" (click)="sendMsgtoSP(workOrder.operatorID)"></i>
									</mat-form-field>
									<div *ngIf="(this.operators ?? []).length === 0 && utils.isNullOrEmpty(workOrder.operatorName) === false">
										<label class="caption">Staff Assigned</label><label class="data-value auto">{{workOrder.operatorName}}</label>
									</div>
									<mat-form-field>
										<mat-label class="placeholder">Reference # <i class="fa fa-question-circle-o warning-color" title="Reference number assigned by the Provider, to this Work Order."></i></mat-label>
										<input matInput appDisableAutofill [(ngModel)]="workOrder.providerReference" (change)="updateWorkOrderValue($event.target.value, 'providerReference', i)" maxlength="20" [disabled]="!workOrder.canUpdate" />
									</mat-form-field>
									<app-datepicker id="{{'dtComp' + i}}" [value]="workOrder.promisedCompletionDate" [placeholder]="'Completion Date'" (valueChanged)="updateWorkOrderValue($event, 'promisedCompletionDate', i, 'date')" 
													[disabled]="!workOrder.canUpdate" [minDate]="today"></app-datepicker>
								</div>
								<div class="app-flex-container app-2col" *ngIf="workOrder.terms != null && workOrder.terms.length > 0">
									<mat-form-field>
										<mat-label class="placeholder">Terms and Conditions <i class="fa fa-question-circle-o warning-color" title="View Accepted OR Pending Terms and Conditions."></i></mat-label>
										<mat-select>
											<mat-option *ngFor="let o of workOrder.terms" [value]="o.id">{{o.name}} <i class="fa fa-eye float-right" (click)="viewTerms(workOrder.id, o.id)"></i></mat-option>
										</mat-select>
									</mat-form-field>
									<button mat-button *ngIf="canAcceptTerms" (click)="acceptTerms(workOrder.id)">Accept All Pending Terms and Conditions</button>
								</div>
							</div>
							<mat-action-row style="display:initial">
								<div>
									<label class="caption">{{'System.CurrentStatus' | translateTag}}</label> <label class="data-value auto">{{workOrder.statusName ? workOrder.statusName : statusName(workOrder.currentStatus) }}<span *ngIf="workOrder.currentStatusDate"><i class="fa fa-calendar" title="{{utils.DisplayDateTime(workOrder.currentStatusDate)}}"></i></span></label>
									&nbsp;<i class="fa fa-exclamation-triangle warning" *ngIf="workOrder.repairsNotified" (click)="showRepairDetails(workOrder.id)" title="Repairs/Issues reported, click to show details"></i>
									&nbsp;<i class="fa fa-external-link" *ngIf="workOrder.canOrderNewPrivateWO" (click)="createNewPrivate(workOrder.id)" title="Create New Work for similiar additional work to be performed, you can assign to same or new service provider"></i>
								</div>
								<br />
								<app-next-status-actions [actions]="workOrder.nextSteps" (itemSelected)="changeStatus($event)"></app-next-status-actions>
								<div *ngIf="workOrder.dependencies != null && workOrder.dependencies.length > 0"><br /></div>
								<mat-form-field *ngIf="workOrder.dependencies != null && workOrder.dependencies.length > 0" class="first normal">
									<mat-label class="placeholder">Dependencies <i class="fa fa-question-circle-o warning-color" title="This work order is dependent upon these other Work Orders"></i></mat-label>
									<mat-select>
										<mat-option *ngFor="let o of workOrder.dependencies" [value]="o.id">{{o.name}}<a *ngIf="o.id > 0" (click)="removeDependency(workOrder.id, o.id)" title="Cancel Dependency"><i class="fa fa-times-circle-o danger-color"></i></a></mat-option>
									</mat-select>
								</mat-form-field>
								<div *ngIf="workOrders && workOrders.length > 1"><hr /></div>
								<app-document-action [documents]="workOrder.documents" [cssClass]="'auto'" [placeholder]="'Work Reports/Disclosures etc.'"></app-document-action>
								<button class="float-right" mat-button color="primary" (click)="accordionNextStep()" *ngIf="workOrders.length > 1 && accordionStep < workOrders.length && workOrders.length > 1">Next</button>
								<button class="float-right" mat-button color="warn" (click)="accordionPrevStep()" *ngIf="workOrders.length > 1 && accordionStep > 0 && workOrders.length > 1">Previous</button>
							</mat-action-row>
						</mat-expansion-panel>
					</mat-accordion>
					<div style="text-align:center">
						<hr />
						<pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
					</div>
				</ng-template>
				<ng-template #noWorkOrders>
					<h3 *ngIf="workOrders != null && this.componentBusy === false">There are no Work Orders for your current selection.</h3>
				</ng-template>
			</div>
		</div>
	</mat-drawer-container>
</div>
