import { Component, Inject, ViewContainerRef, ViewChild, AfterViewInit, NgModule, Compiler, Injector, NgModuleRef } from '@angular/core';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from './security/signin.component';
import { returnRoute } from './models';

import { buyerStart, sellerStart, spStart, AppURLs, handover } from './models/constants';
import { Utils } from './common/utils';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent {

    buyer = buyerStart;
    seller = sellerStart;
    sp = spStart;

    appURLs = AppURLs;
    handover = handover;

    constructor(private route: Router, @Inject(GlobalComponent) private gc: GlobalComponent
        , private dialog: MatDialog)
    {

    }

    GoToURLWithSession(url, name, value) {
        sessionStorage.setItem(name, value);
        this.gotoURL(url);
    }

    gotoRoute(sRoute: string, sParams: any = null) {
        if (!this.gc.stealthMode) {
            if (!Utils.isNullOrEmpty(sRoute)) {
                if (sRoute.startsWith('http'))
                    this.route.navigateByUrl(sRoute);
                else if (!this.route.url.toLowerCase().endsWith(sRoute.toString().toLowerCase())) {
                    const _q = sRoute.split('?');

                    if (_q.length > 1 && !Utils.isNullOrEmpty(_q[1])) {
                        sParams = JSON.parse('{' + _q[1].replace('&', ',').replace('=', ':') + '}');
                    }

                    if (sParams)
                        this.route.navigate([sRoute], { queryParams: sParams });
                    else
                        this.route.navigate([sRoute]);
                }
            }
        } else
            alert('We are working diligently to bring great new platform!!!');
    }

    gotoAssetPage(iAssetID: number) {
        this.route.navigate([AppURLs.assetPage, { showAsAssetPage: true, startingCategory: { id: iAssetID } }]);
    }

    gotoURL(sRoute: string) {
        this.gotoRoute(sRoute);
    }

    start(sURL: string) {
        if (!this.gc.stealthMode) {
            if (this.gc.loggedIn) {
                this.gotoURL(sURL);
            } else {
                const dialogRef = this.dialog.open(SignInComponent, {
                    data: { returnRoute: sURL, css: '', inPopup: true }
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((e) => {
                    if (e != null) {
                        const _retRoute: returnRoute = e as returnRoute;
                        if (_retRoute != null && !Utils.isNullOrEmpty(_retRoute.routePath))
                            this.gotoRoute(_retRoute.routePath, _retRoute.queryParams);
                    }
                });
            }
        } else
            alert('We are working diligently to bring great new platform!!!');
    }
}

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home.carousel.content.component.html'
})
export class HomeCarouselContentComponent {
}
