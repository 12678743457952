import { Component, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef, Inject} from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HTMLDialog } from '../common/dialogs';
import { ListingService } from './listing.service';
import { OfferService } from '../offer/offer.service';
import { listing, BaseResponse } from '../models';
import { handover, AppURLs } from '../models/constants';
import * as moment from 'moment';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-listings',
    templateUrl: './listings.component.html'
})
export class ListingsComponent extends BaseComponent implements OnInit, AfterViewChecked {

    @ViewChild('listingsTable') table: any;
    filteredListings: listing[] = [];
    addressFilter: string = '';

    itemsOnPage: number = 10;
    pageNumber: number = 1;

    public listings: listing[] = [];
    constructor(private ds: DataService, private rs: Router, private dialog: MatDialog, @Inject(GlobalComponent) gc: GlobalComponent, private cdref: ChangeDetectorRef) {
        super('pgSellerListings', rs, gc);
    }

    ngOnInit() {
        if (this.table) {
            this.baseTable = this.table;
            this.messages = { emptyMessage: 'No Listings.', };
        }
        this.bindData();
    }

    ngAfterViewChecked() {
        if (this.table && this.table.rowDetail) {
            this.table.rowDetail.expandAllRows();
            this.cdref.detectChanges();
        }
    }

    showDetails(id: number) {
        if (id > 0) {
            localStorage.setItem(handover.listingID, id.toString());
            this.gotoRoute(AppURLs.listing, null);
        }
    }

    showPurchaseAgreement(id: number) {
        let _response;
        (new OfferService(this.ds, this.route, this.myResourceCategory)).getPurchaseAgreement(id).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                    }
            }
        );
    }

    changeStatus(listingID, action$) {
        this.gc.setBusy(true);
        let _actionResponse: BaseResponse;
        (new ListingService(this.ds, this.rs, this.myResourceCategory)).changeStatus(listingID, action$.name, true).subscribe(
            (r) => { this.gc.setBusy(false); _actionResponse = r; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {

                        if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                            this.gotoURL(_actionResponse.redirectURL);
                        else {
                            if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';
                            this.showMessage(_actionResponse.message);
                            if (_actionResponse.data) {
                                this.updateListingList(_actionResponse.data, listingID);
                            }
                        }
                    }
                }
            }
        );
    }

    filterListings() {
        if (this.addressFilter != null && this.addressFilter.trim() !== '') {
            const _f = this.addressFilter.replace(/\s\s+/g, ' ').toLowerCase();
            this.filteredListings = [...this.listings.filter((l) => {
                if ((l as any).addressClean)
                    return (l as any).addressClean.includes(_f);
            })];
        } else
            this.filteredListings = [...this.listings];
    }

    private updateListingList(oListing, iListing: number = 0) {
        if (oListing.id && oListing.id > 0 && this.listings.find((wo) => wo.id === oListing.id) == null) {
            this.listings = this.listings.filter((l) => { return l.id !== oListing.id; });
            if (this.listings && this.listings.length > 0)
                this.listings.splice(0, 0, oListing);
            else
                this.listings.push(oListing);
        } else {
            if (oListing.id && oListing.id > 0)
                iListing = oListing.id;
            if (iListing && iListing > 0) {
                const _listing = this.listings.find((wo) => wo.id === iListing);
                if (_listing != null) {
                    _listing.UpdateAfterChangeStatus(oListing);
                }
            }
        }

        this.listings = [...this.listings];
    }

    private bindData() {
        this.listings = []; this.addressFilter = '';
        let _response;
        this.componentBusy = true;
        (new ListingService(this.ds, this.route, this.myResourceCategory)).getMyListingsForSale(false).subscribe(
            (data) => { _response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (_response.data) {
                        this.listings = _response.data.map((c) => {
                            const _l: listing = Utils.castTo(c, new listing());
                            if (_l.address)
                                _l['addressClean'] = (_l.address?.FullAddressLower ?? '').replace(/\s\s+/g, ' ').toLowerCase();
                            else
                                _l['addressClean'] = '';
                            return _l;
                        });
                        this.listings = this.listings.sort((a, b) => moment(a.lastUpdateDate).isAfter(b.lastUpdateDate) ? 1 : -1); // Descending  -- For Ascending  -1 : 1
                        this.filteredListings = [...this.listings];

                        this.gc.myListings = this.listings;
                    }
            }
        );
    }
}
