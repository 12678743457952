import { Component, ViewChild, OnInit, Input, AfterViewInit, Inject, Output, OnDestroy } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { entityRoles, Role } from '../models';

import { ListingService } from './listing.service';

import { ListingBaseComponent } from './listing.base.component';
import { EntityRoleComponent } from '../common/entityRoles.component';
import { GlobalComponent } from '../global.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../common/utils';
@Component({
    selector: 'app-listing-roles',
    templateUrl: './listingRoles.component.html'
})
export class ListingRoleComponent extends ListingBaseComponent implements AfterViewInit, OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;
    @ViewChild('listingRoles') listingRoles: EntityRoleComponent;

    @Input() forRole: Role = Role.NotSet;
    @Input() allowAddPersonnel: boolean = false;
    private _lcs: Subscription;
    // private _ocs: Subscription;
    constructor(ds: DataService, rs: Router, private dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingRole', ds, rs, gc);

        if (this._lcs != null) this._lcs.unsubscribe();

        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindRoles();
        });

    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        // if (this._ocs != null) this._ocs.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngAfterViewInit() {
        if (this.listingRoles)
            this.listingRoles.forRole = this.forRole;
    }

    bindRoles() {

        if (this.currentListing == null || this.currentListing.roles == null || this.currentListing.roles.length === 0) {
            let _response;
            const _s = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getRoles(this.listingID);
            if (_s) {
                _s.subscribe(
                    (data) => { _response = data; }
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response)
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.showError(_response.error);
                            else if (_response.data) {

                                const _listingPresent = this.currentListing && this.currentListing.id > 0;
                                if (this.noParent && !_listingPresent)
                                    this.setCurrentListing(_response.data);

                                if (_response.data.roles)
                                    this.currentListing.roles = _response.data.roles;

                                this.setValues();
                            }
                    }
                );
            }
        } else
            this.setValues();
    }

    private setValues() {
        if (this.listingRoles && this.listingRoles.entityID !== this.currentListing.id) {
            this.listingRoles.entityID = this.currentListing.id;
            this.listingRoles.maxAvailablePartnership = this.currentListing.maxAvailablePartnership;
            this.listingRoles.canAddPersonnel = this.currentListing.isSeller;
            this.listingRoles.allowAddPersonnel = this.currentListing.isSeller;
            this.listingRoles.myRole = this.currentListing.myRole;
            this.listingRoles.escrowID = this.currentListing.currentEscrowID;
            this.listingRoles.powerOfAttornies = this.currentListing.powerOfAttornies;
            this.listingRoles.roles = this.currentListing.roles.filter((r) => { return r.roleID === this.forRole; }).map((c) => { return (new entityRoles()).castToMe(c); });

            this.listingRoles.readonly = !this.currentListing.isEditable('roles');
        }
    }
}
