import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { GlobalComponent } from '../global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as cloneDeep from 'lodash/cloneDeep';
import { escrow, escrowDemand, PaymentUIData, Payment3DSInformation, BaseResponse } from '../models';

import { EscrowService } from './escrow.service';

import { EscrowBaseComponent } from './escrow.base.component';
import { AddDemandDialog } from './dialog/addDemand.dialog';
import { BrainTreeDialog } from '../common/shared/brainTreeDropIn.dialog';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-escrow-demand',
    templateUrl: './escrowDemands.component.html'
})
export class EscrowDemandComponent extends EscrowBaseComponent implements OnInit {

    @ViewChild('demandsTable') table;
    demands: escrowDemand[] = new Array<escrowDemand>();
    workOrderID: number = 0;

    demandsOnPage: number = 10;
    demandsPageNumber: number = 1;

    bindingDemandForID: number = 0;

    constructor(ds: DataService, r: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('escrowDemand', ds, r, g);

        this.gc.roleChanged.subscribe((re) => {
            this.initMe();
            this.bindDemands();
        });
        this.gc.escrowChanged.subscribe((re) => {
            this.initMe();
            this.bindDemands();
        });
    }

    ngOnInit() { }

    bindDemands() {

        const _id = this.currentEscrow ? this.currentEscrow.id : 0;
        if (_id === 0) {
            this.initMe();
        }

        if (this.escrowID === 0 || this.currentEscrow == null || (this.bindingDemandForID && this.bindingDemandForID === this.escrowID)) return; // Other Component us setting this value in GC, escrowChange will trigger this again.
        if (this.currentEscrow.id !== this.escrowID || this.demands == null || (this.currentEscrow.id === this.escrowID && this.currentEscrow.demands == null)) {
            this.workOrderID = 0;
            this.bindingDemandForID = this.escrowID;
            let _response;
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getDemands(this.escrowID);
            if (_es != null) {
                this.componentBusy = true;
                _es.subscribe((r) => _response = r
                    , (error) => { this.componentBusy = false;}
                    , () => {
                        this.bindingDemandForID = 0;
                        this.componentBusy = false;
                        if (_response && _response.data) {
                            this.setCurrentEscrow(Utils.castTo(_response.data, new escrow()));
                            this.demands = [..._response.data.demands.map((c) => Utils.castTo(c, new escrowDemand()))];
                            if (this.currentEscrow && this.currentEscrow.myWorkOrders && this.currentEscrow.myWorkOrders.length === 1)
                                this.workOrderID = this.currentEscrow.myWorkOrders[0].id;
                        }
                    });
            }
        } else if (this.currentEscrow.id === this.escrowID && this.currentEscrow.demands) {
            this.demands = [...this.currentEscrow.demands.map((c) => Utils.castTo(c, new escrowDemand()) )];
            if (this.currentEscrow && this.currentEscrow.myWorkOrders && this.currentEscrow.myWorkOrders.length === 1)
                this.workOrderID = this.currentEscrow.myWorkOrders[0].id;
        }
    }

    clear() {
        this.setCurrentEscrow(null);
        this.demands = [];
    }

    addDemandOrCredit(bAddCredit: boolean) {
        // Show Dialog
        const dialogRef = this.dialog.open(AddDemandDialog, {
            data: {
                id: this.workOrderID ?? 0
                , escrowID: this.escrowID ?? 0
                , addCredit : bAddCredit ?? false
                , showOpenDemands : false
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.showMessage('Demand/Credit information sent to Escrow and Payor for acceptance.');
                this.demands = result.map((c) => Utils.castTo(c, new escrowDemand()));
            }
        });
    }

    changeStatus(action$) {
        let _actionResponse;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).changeDemandStatus(this.escrowID, parseInt(action$.argument), action$.name);
        if (_es != null) {
            _es.subscribe((r) => _actionResponse = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_actionResponse) {
                        if (!Utils.isNullOrEmpty(_actionResponse.error))
                            this.showError(_actionResponse.error);
                        else {
                            if (Utils.isNullOrEmpty(_actionResponse.message))
                                _actionResponse.message = 'Action executed successfully.';

                            this.showMessage(_actionResponse.message);

                            if (_actionResponse.data) {
                                if (!Utils.isNullOrEmpty(_actionResponse.data.redirectURL)) {
                                    this.gotoURL(_actionResponse.data.redirectURL);
                                } else {
                                    const _demand = this.demands.find((d) => d.id === parseInt(action$.argument));
                                    if (_demand != null) {
                                        _demand.UpdateAfterChangeStatus(_actionResponse.data);
                                    }
                                }
                            }
                        }
                    }
                }
            );
        }
    }

    payNow(amount: number) {

        const _ccData: PaymentUIData = new PaymentUIData();

        _ccData.payment3DSInfo = new Payment3DSInformation();
        _ccData.payment3DSInfo.amount = amount;
        _ccData.payment3DSInfo.billingAddress.givenName = this.user.details.FirstName;
        _ccData.payment3DSInfo.billingAddress.surname = this.user.details.LastName;

        _ccData.currency = 'USD';
        _ccData.amount = amount;
        _ccData.header = 'Pay to Escrow for Property [' + this.currentEscrow.address + ']';
        _ccData.title = this.myBRAND + ' is collecting ' + this.FormatNumberToCurrency(amount) + ' on your request to pay to Escrow on your behalf '
            + '. I understand and agree to charge ' + this.FormatNumberToCurrency(amount) + ' on the Credit Card.';

        const _df = this.dialog.open(BrainTreeDialog, { data: _ccData });
        _df.disableClose = true;
        _df.afterClosed().subscribe((result) => {
            this.onPaymentEntered(result);
        });

    }

    private onPaymentEntered(payment: PaymentUIData) {
        if (payment && payment.paymentNonce) {

            const _data = { id: this.escrowID, workOrderID: this.workOrderID, payment: payment };
            let _response: BaseResponse;
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).payDemand(_data);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { }
                    , () => {
                        if (_response && _response.data) {
                            this.showMessage('Payment information sent to Escrow');
                        }
                    });
            }
        }
    }
}
