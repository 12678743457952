<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card *ngIf="canPostReview === true && this.workOrderID > 0">
        <h4>Post New Review</h4>
        <hr />
        <star-rating [value]="rating" [totalstars]="5" [readonly]="false" (rate)="onRate($event)" [checkedcolor]="'gold'" [uncheckedcolor]="'grey'" [size]="'24px'"></star-rating>
        <span class="note danger" *ngIf="rating === 0">Please select a rating, minimum rating is 1.</span>
        <hr />
        <mat-form-field>
            <textarea matInput appDisableAutofill maxlength=2000 placeholder="Comments" [(ngModel)]="comments"></textarea>
        </mat-form-field>
        <mat-error *ngIf="!utils.isNullOrEmpty(error)">Failed to save review. {{this.error}}</mat-error>
        <button color="primary" title="Add your review" (click)="submitReview()" *ngIf="rating > 0">Submit</button>
        <hr />
        <p class="note">
            Please add meaningful reviews, which are helpful to other users and helps service provider improve their services.<br /> Use of foul language is violation of IAMOREA Terms of Use.
        </p>
    </mat-card>
    <div *ngIf="reviews && reviews.length > 0">
        <hr />
        <mat-card *ngFor="let a of reviews | paginate: { itemsPerPage: 10 , currentPage: reviewPageNumber }; " class="section">
            <span>
                <star-rating [value]="a.rating" [totalstars]="5" [readonly]="true" [checkedcolor]="'gold'" [uncheckedcolor]="'grey'" [size]="'24px'" style="display:inline-block"></star-rating>
                &nbsp;&nbsp;<i class="fa fa-male float-right" title="Reviewed Submitted By - {{a.reviewedBy}}" style="font-size:24px"></i>
                <button class="float-right" *ngIf="a.canRevokeReview === true" matButton (click)="revokeReview(a)" title="Revoke review. You will not be allowed to resubmit review about this work order.">Revoke</button>
            </span>
            <div>
                <div class="inline-block" style="min-width:400px; margin:20px 0">{{a.review}}</div>
                <div class="inline-block" style="min-width:400px; margin:20px 0" *ngIf="!utils.isNullOrEmpty(a.rebuttal)">
                    <label class="caption" title="Response/Rebuttal by Service Provider">Response</label><label class="data-value auto">{{a.rebuttal}}</label>
                </div>
                <div *ngIf="a.canPostRebuttal === true">
                    <hr />
                    <p class="note">
                        Please add meaningful response, you will only have one opportunity to respond to this review.<br /> Use of foul language is violation of IAMOREA Terms of Use.
                    </p>
                    <mat-form-field><textarea matInput appDisableAutofill maxlength=2000 placeholder="Response to Customer's review" [(ngModel)]="a.rebuttal"></textarea></mat-form-field>
                    <button matButton (click)="submitRebuttal(a)" title="Submit your response to the review.">Respond to Review</button>
                </div>
            </div>
        </mat-card>
        <div style="text-align:center">
            <hr />
            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
        </div>
    </div>
    <h4 *ngIf="this.serviceProviderID === 0 && this.workOrderID === 0">
        <span *ngIf="(this.reviews == null || this.reviews.length === 0)">
            No reviews have ben submitted.
        </span>
    </h4>
</div>
