import { Component, OnInit, Input, Output, Inject } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { handover } from '../models/constants';
import { ListingService } from '../listing/listing.service';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-listing-offer-compare',
    templateUrl: './offerCompare.component.html'
})
export class ListingOfferCompareComponent extends BaseComponent implements OnInit {

    currentListing: any = [];
    offers: any = [];

    @Input() @Output() listingID: number = 0;

    constructor(private ds: DataService, rs: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('pgListingOfferCompare', rs, g);

        const _id = parseInt(sessionStorage.getItem(handover.listingID));
        if (!isNaN(_id) && _id > 0) {
            this.listingID = _id;
        }
    }

    ngOnInit() {

        if (this.listingID <= 0) return;

        let _response;
        (new ListingService(this.ds, this.route, this.myResourceCategory)).offerCompare(this.listingID).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.currentListing = _response.data;
                        this.offers = this.currentListing.comparison;
                    }
            }
        );

    }
}