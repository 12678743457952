import { Component, Inject } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { item } from '../../models/appCommon.models';
import { WorkOrderService} from '../workorder.service';
import { selectTag } from '../../common/appCommon.module';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-add-dependency-dialog',
    templateUrl: './addDependency.dialog.html'
})
export class AddDependencyDialog extends BaseDialog {

    dialogHeader: string = 'Dependency';
    workOrderID: number = 0;
    existingDependencies: any = [];
    otherServices: any = [];

    openDependencyID: number = 0;

    selectedWorkOrderIDs: any = [];
    dependencyDescription: string = '';
    private isDirty: boolean = false;

    constructor(dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent
        , public dialogRef: MatDialogRef<AddDependencyDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addDependencyDialog', dataservice, r, dialogRef, data);

        if (data) {

            if (data.id) this.workOrderID = data.id;

            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Dependency for ' + this.data.header;
            }

            let _response: any;
            (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).prepareToAddDependencies(this.workOrderID).subscribe(
                (_data) => _response = _data
                , (error) => { this.close(null); this.onApiError(error); }
                , () => {
                    if (_response && _response.data) {
                        if (_response.data.existingDependencies)
                            this.existingDependencies = _response.data.existingDependencies;
                        if (_response.data.otherServices) {
                            this.otherServices = _response.data.otherServices;
                            if (this.otherServices.length > 1)
                                this.otherServices.splice(0, 0, new item(0, selectTag));
                        }
                    }
                }
            );
        }
    }

    close(data) {
        if (data == null && this.isDirty)
            this.dialogRef.close({ id: this.workOrderID, dependencies: this.existingDependencies });
        else
            this.dialogRef.close(data);
    }

    removeDependency(id: number) {
        let _response: any;
        this.componentBusy = true;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).removeDependency(this.workOrderID, id).subscribe(
            (data) => _response = data
            , (error) => { this.close(null); this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response) {
                    if (Utils.isNullOrEmpty(_response.error)) {
                        this.existingDependencies = _response.data.dependencies;
                        this.isDirty = true;
                        this.openDependencyID = 0;
                    } else
                        this.showError(_response.error);
                }
            }
        );
    }

    addDependencies() {
        let _response: any;
        this.componentBusy = true;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).addDependencies(this.workOrderID, this.selectedWorkOrderIDs, this.dependencyDescription).subscribe(
            (data) => _response = data
            , (error) => { this.close(null); this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response) {
                    if (Utils.isNullOrEmpty(_response.error)) {
                        this.showMessage('Dependencies Added.');
                        this.close(_response.data);
                    } else
                        this.showError(_response.error);
                }
            }
        );
    }
}
