import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PersonService } from '../../account/person.service';
import { BaseResponse } from '../../models';
import { BaseDialog } from '../../base.dialog';
import { appEnvironment } from '../../../environments/environment';
import { Utils } from '../utils';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message.dialog.html'
})
export class MessageDialog extends BaseDialog implements OnInit {

    form: UntypedFormGroup;
    messageID: number = 0;
    replyToAll: boolean = false;
    recepients: number[] = [];
    subject: string = '';
    message: string = '';
    header: string = 'Send Message';

    constructor(dataservice: DataService, private r: Router, public dialogRef: MatDialogRef<MessageDialog>
        , @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('messageDialog', dataservice, r, dialogRef, data);

        this.form = fb.group({
            subject: ['', [Validators.required]]
            , response: ['', [Validators.required]]
        });
    }


    ngOnInit() {

        if (this.data) {
            if (this.data.messageID) this.messageID = this.data.messageID;
            if (this.data.recepients) this.recepients = this.data.recepients;
            if (this.data.subject) this.subject = this.data.subject;
            if (this.data.heading) this.header = this.data.heading;
        }

        let _r: number[] = [];
        if (this.recepients  != null)
            if (!Array.isArray(this.recepients)) {
                _r = (this.recepients as string).split(',').map(Number);
            } else
                _r = this.recepients ;

        const _me = this;
        if (this.messageID === 0 && (_r == null || !Array.isArray(_r) || (Array.isArray(_r) && _r.length === 0)))  {
            this.error = 'Recepients data missing.';
            setTimeout(() => { _me.close(null); }, appEnvironment.delayedNGInit);
        }

        this.recepients = _r;
    }

    close(oResponse?: any) { this.dialogRef.close(oResponse); }
    onClosed(value: boolean) { this.close(); }

    onSubmit() {
        let messageResponse: BaseResponse;

        if (Utils.isNullOrEmpty(this.message)) {
            this.error = 'Message required.';
            return;
        }

        if (this.messageID == null || this.messageID <= 0) {
            if (Utils.isNullOrEmpty(this.subject)) {
                this.error = 'Subject is required. Please enter a subject, so that receipient(s) can get your attention.';
                return;
            }

            (new PersonService(this.dataservice, this.r, this.myResourceCategory)).sendMessage(this.user.details.PID, this.recepients, this.subject, this.message).subscribe(
                (data) => messageResponse = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (messageResponse != null) {
                        if (Utils.isNullOrEmpty(messageResponse.error)) {
                            this.close('Your message has been sent.');
                        } else {
                            this.error = messageResponse.error;
                            this.close(messageResponse);
                        }
                    }
                }
            );
        } else {
            (new PersonService(this.dataservice, this.r, this.myResourceCategory)).replyToMessage(this.messageID, this.message, this.replyToAll).subscribe(
                (data) => messageResponse = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (messageResponse != null) {
                        if (Utils.isNullOrEmpty(messageResponse.error)) {
                            this.showMessage('Your response has been sent.');
                            this.close(messageResponse.data);
                        } else {
                            this.error = messageResponse.error;
                            this.close(messageResponse);
                        }
                    }
                }
            );
        }
    }
}
