<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <mat-select [(ngModel)]="selectedPerson">
                <mat-option *ngFor="let p of people" [value]="p.id">{{p.Name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-card class="small-padding">
            <mat-card-title>Permissions</mat-card-title>
            <mat-card-content>
                <mat-checkbox [(ngModel)]="isReader">Can Read/View</mat-checkbox>
                <mat-checkbox [(ngModel)]="isEditor">Can Edit</mat-checkbox>
                <mat-checkbox [(ngModel)]="isOwner">Additional Owner</mat-checkbox>
            </mat-card-content>
        </mat-card>
        <button mat-raised-button (click)="onSubmit()" *ngIf="saveReady">Apply Permissions</button>
    </form>
    <div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to apply new Permission</div>
</div>
