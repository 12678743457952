<mat-tab-group *ngIf="currentListing">
    <mat-tab label="Assessments">
        <div>
            <i class="fa fa-plus-circle fa-2x float-right green" title="Add new assessment." (click)="addNewAssessment()" *ngIf="this.canAddAssessment"></i>
        </div>
        <br /><br />
        <ng-template [ngIf]="(this.assessments && this.assessments.length > 0)" [ngIfElse]="noAssessments">
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let assessment of assessments; let i = index" [expanded]="accordionStep === i" (opened)="accordionSetStep(i)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{assessment.description}}</mat-panel-title>
                        <mat-panel-description style="display:initial">
                            <i class="fa fa-trash danger float-right" *ngIf="assessment.canEdit && assessment.id > 0" (click)="removeAssessment(assessment.id)" title="Remove assessment"></i>
                            <span class="float-right" style="padding-right:20px">{{FormatNumberToCurrency(assessment.amount)}}</span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="section">
                        <label>Expiry Date : </label>&nbsp;{{utils.DisplayDate(assessment.expiryDate)}}
                    </div>
                    <mat-action-row *ngIf="assessments.length > 1">
                        <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && assessments.length > 1">Previous</button>
                        <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < assessments.length && assessments.length > 1">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
        <ng-template #noAssessments>
            <h3>You have not provided any Assessment details.</h3>
        </ng-template>
    </mat-tab>
    <mat-tab label="Taxes">
        <app-listing-taxes-and-fees></app-listing-taxes-and-fees>
    </mat-tab>
</mat-tab-group>
