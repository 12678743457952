import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class Utils {

    static readonly dateFormat = 'MM/DD/YYYY';
    static readonly fileExtension: string = 'jpeg,jpg,png';
    static readonly _minDate = moment('0001-01-01T00:00:00').toDate();
    static readonly _maxDate = moment('9999-12-31T23:59:59').toDate();

    static offsetMinutesFromServer: number = 0;
    readonly dateTimeMinValue: Date = moment('0001-01-01T00:00:00').toDate();
    readonly dateTimeMaxValue: Date = moment('9999-12-31T23:59:59').toDate();

    static get today(): Date {
        const _dt = new Date();
        _dt.setHours(0, 0, 0, 0);
        return _dt;
    }
    static get tomorrow(): Date {
        const _dt = new Date();
        return moment(_dt.setHours(0, 0, 0, 0)).add(24, 'hours').toDate();
    }


    static isNumber(num) { return !isNaN(num); }

    static isNullOrEmpty(value: string): boolean {
        return (value == null || value.toString().trim() === '');
    }

    // Display Date and Time in Local Format and Adjust for Local TimeZone
    static DisplayLocalDateTime(value: any): string {
        try {
            if (value == null) return ' ';
            let _dt;

            if (Object.prototype.toString.call(value) === '[object Date]')
                _dt = moment(value.toISOString());
            else {
                if (value.toString().indexOf('/') === -1) {
                    _dt = moment(value);
                } else
                    _dt = moment(value.split('.')[0], this.dateFormat + ' hh:mm:ss');
            }

            if (!_dt.isValid() || this.IsMinDate(value))
                return ' ';
            else {
                _dt.add(this.offsetMinutesFromServer, 'minutes');
                const _f = _dt.format(this.dateFormat + ' hh:mm A').toString();
                return _f;
            }
        } catch (e) { return value; }
    }
    // Display Date in Local Format
    static DisplayDate(value: any): string {
        try {
            if (value == null) return ' ';
            let _dt;
            if (Object.prototype.toString.call(value) === '[object Date]')
                _dt = moment(value.toISOString());
            else {

                if (value.toString().indexOf('/') === -1)
                    if (value.toString().indexOf('T') >= 0)
                        _dt = moment(value.toString().split('T')[0]);
                    else
                        _dt = moment(value);
                else
                    _dt = moment(value.toString().split('.')[0], this.dateFormat);
            }

            if (!_dt.isValid() || this.IsMinDate(value))
                return ' ';
            else {
                return _dt.format(this.dateFormat);
            }
        } catch (e) { return value; }
    }
    // Display Date and Time in Local Format
    static DisplayTime(value: any): string {
        try {
            if (value == null) return ' ';
            let _dt;

            if (Object.prototype.toString.call(value) === '[object Date]')
                _dt = moment(value.toISOString());
            else {
                if (value.toString().indexOf('/') === -1) {
                    _dt = moment(value);
                } else
                    _dt = moment(value.split('.')[0], this.dateFormat + ' hh:mm:ss');
            }

            if (!_dt.isValid() || this.IsMinDate(value))
                return ' ';
            else {
                return _dt.format('HH:mm A');
            }
        } catch (e) { return value; }
    }
    // Display Date and Time in Local Format
    static DisplayDateTime(value: any): string {
        try {
            if (value == null) return ' ';
            let _dt;

            if (Object.prototype.toString.call(value) === '[object Date]')
                _dt = moment(value.toISOString());
            else {

                if (value.toString().indexOf('/') === -1)
                    if (value.toString().indexOf('Z') >= 0)
                        _dt = moment(value.toString().split('Z')[0]);
                    else
                        _dt = moment(value);
                else
                    _dt = moment(value.toString().split('.')[0], this.dateFormat);
            }

            if (!_dt.isValid() || this.IsMinDate(value))
                return ' ';
            else {
                return _dt.format(this.dateFormat  + ' HH:mm A');
            }
        } catch (e) { return value; }
    }
    // Return Date and Adjust for Local TimeZone
    static ConvertToLocalDate(value: any): Date {
        try {
            if (value == null) return Utils._minDate;
            let _dt;

            if (Object.prototype.toString.call(value) === '[object Date]')
                _dt = moment(value);
            else {

                if (value.toString().indexOf('/') === -1)
                    if (value.toString().indexOf('T') >= 0)
                        _dt = moment(value.toString().split('T')[0]);
                    else
                        _dt = moment(value);

                else
                    _dt = moment(value.toString().split('.')[0], this.dateFormat);
            }

            if (!_dt.isValid() || this.IsMinDate(value))
                return Utils._minDate;
            else {
                _dt.add(this.offsetMinutesFromServer, 'minutes');
                return _dt.toDate();
            }

        } catch (e) { return Utils._minDate; }
    }
    // Return Date and Time and Adjust for Local TimeZone
    static ConvertToLocalDateTime(value: any): Date {
        try {
            if (value == null) return Utils._minDate;
            let _dt;
            if (value.toString().indexOf('/') === -1)
                _dt = moment(value);
            else
                _dt = moment(value, this.dateFormat);

            if (!_dt.isValid() || this.IsMinDate(value))
                return Utils._minDate;
            else {
                _dt.add(this.offsetMinutesFromServer, 'minutes');
                return _dt;
            }
        } catch (e) { return Utils._minDate; }
    }
    static IsMinDate(value: string): boolean {

        let _dt;

        if (value == null) return true;

        if (value.toString().indexOf('/') === -1)
            _dt = moment(value);
        else
            _dt = moment(value, this.dateFormat);

        return !_dt.isValid() || !_dt.isAfter(Utils._minDate);
    }
    //#endregion
    static EncryptQueryString(v) { return v; }

    static TrimToLength(sValue: string, iTrimLength: number): string {
        if (Utils.isNullOrEmpty(sValue))
            return '';
        return sValue.length <= iTrimLength ? sValue : (sValue.substr(0, iTrimLength - (iTrimLength > 10 ? 5 : 0)) + '....');
    }

    static numOnlyLimitingLength(event: any, limit: number = 0, isFloat: boolean = true): boolean {
        const _key = event.key;
        return (
            ((_key >= '0' && _key <= '9') || (isFloat && _key === '.'))
            && (limit >= 1 ? (event.target.value.length + 1) <= limit : true)
        )
            || (_key === 'ArrowLeft' || _key === 'ArrowRight' || _key === 'Delete' || _key === 'Tab' || _key === 'Backspace');
    }

    static toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    static calculateMortgagePayment(iLoanAmount: number, iInterestRate: number = 5, iLoanTermMonths: number = 30) {
        let payment: number = 0;
        const _monthsPerYear: number = 12;
        if (iLoanTermMonths > 0) {
            if (iInterestRate !== 0) {
                const rate: number = (iInterestRate / _monthsPerYear) / 100.0;
                const factor: number = (rate + (rate / (Math.pow((rate) + 1, (iLoanTermMonths)) - 1)));
                payment = (iLoanAmount * factor);
            } else payment = (iLoanAmount / iLoanTermMonths);
        }
        return parseFloat(payment.toFixed(2)); // Round
    }

    static castTo(fromObject: any, toObject: any): any {
        let _isDirty: boolean = false;
        if (fromObject != null) {
            if (fromObject.isDirty)
                _isDirty = fromObject.isDirty;

            const _sourceKeys = Object.keys(fromObject);
            toObject = toObject ?? {};

            if (fromObject['IsReadOnly'])
                toObject['IsReadOnly'] = fromObject['IsReadOnly'];

            _sourceKeys.forEach((_key) => {
                const _prop: PropertyDescriptor = this.getPropertyDescriptor(toObject, _key);
                if ((_prop == null && toObject[_key]) || (_prop != null && (_prop.writable || _prop.set != null))) {
                    toObject[_key] = fromObject[_key];
                }
            });
            if (fromObject.isDirty)
                toObject['IsDirty'] = _isDirty;
            else
                toObject['IsDirty'] = false;
            return toObject ?? {};
        } else
            return toObject ?? {};
    }

    static toJSON(oObject: any) {
        const proto = Object.getPrototypeOf(oObject);
        const jsonObj: any = Object.assign({}, oObject);

        Object.entries(Object.getOwnPropertyDescriptors(proto))
            .filter(([key, descriptor]) => typeof descriptor.get === 'function')
            .map(([key, descriptor]) => {
                if (descriptor && key[0] !== '_') {
                    try {
                        const val = (oObject as any)[key];
                        jsonObj[key] = val;
                    } catch (error) {
                        console.error(`Error calling getter ${key}`, error);
                    }
                }
            });

        return jsonObj;
    }

    static stringOfEnum(myEnum: any, value: number) {
        for (const k in myEnum) if (myEnum[k] === value) return k;
        return null;
    }

    static getPropertyDescriptor(obj: any, prop: string): PropertyDescriptor {
        let desc;
        do {
            desc = Object.getOwnPropertyDescriptor(obj, prop);
            // tslint:disable-next-line: no-conditional-assignment
        } while (!desc && (obj = Object.getPrototypeOf(obj)));
        return desc;
    }

    static  isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    static b64toBlob(b64Data, contentType: string = '', sliceSize: number = 512) {

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    static intFromBytes(x) {
        let val = 0;
        for (let i = 0; i < x.length; ++i) {
            val += x[i];
            if (i < x.length - 1) {
                val = val << 8;
            }
        }
        return val;
    }

    static getInt64Bytes(x) {
        const bytes = [];
        let i = 8;
        do {
            bytes[--i] = x & (255);
            x = x >> 8;
        } while (i);
        return bytes;
    }

}

export enum rolePosition {
    sysadmin = 0, admin = 1, remoteSystem = 2, reAgent = 3, escrowStaff = 4, lenderStaff = 5
}
