import { Component, Input, OnInit, Inject } from '@angular/core';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-asset-link',
    templateUrl: './asset.link.component.html'
})
export class AssetLinkComponent extends BaseComponent {
    @Input() assetID: any = 0;
    @Input() css: string = 'link';
    @Input() style: string = '';
    @Input() label: string = '';
    // gotoAssetPage - defined in Base Component
    constructor(rs: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('', rs, g);
        if (this.isNaN(parseInt(this.assetID)))
            this.assetID = 0;
        else
            this.assetID = parseInt(this.assetID);
    }
}
