import { Input, Component, Inject } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';

import { Router } from '@angular/router';
import { listing, escrow, Offer } from '../models';

import { EscrowService } from './escrow.service';
import { ListingService } from '../listing/listing.service';

@Component({template: ''})
export abstract class EscrowBaseComponent extends BaseComponent {

    @Input() noParent: boolean = true;
    @Input() showHeader: boolean = true;
    @Input() bindEscrowsOnSetListingWithNoParent: boolean = false;

    listings: any = []; escrows: any = [];

    escrowID: number = 0;
    offerID: number = 0;
    listingID: number = 0;

    currentEscrow: escrow = new escrow();
    currentListing: listing = new listing();
    currentOffer: Offer = new Offer();

    constructor(@Inject(String) private sModule: string, public dataservice: DataService, r: Router, gc: GlobalComponent) {
        super(sModule, r, gc);
        // this.bindListings();
    }

    setEscrowID(v: number) {
        this.escrowID = v;
        this.gc.setEscrowID(v);
    }

    setCurrentEscrow(v: escrow) {
        if (v == null) v = new escrow();
        this.currentEscrow = v;
        this.setEscrowID(v.id);
        if (!this.noParent) {

            if (this.escrows == null)
                this.escrows = [];

            if (this.escrows.length === 0 || this.escrows.find(e => { return e.id === v.id; }) == null)
                this.escrows.push(v);
            else
                this.escrows.forEach(e => { if (e.id === v.id) { e = v; } });

            this.escrows = this.escrows.filter((e) => { return e.id !== 0; });

            this.gc.setCurrentEscrow(v);
        }
    }

    public setListingID(v: number) {
        this.listingID = v;
        this.gc.setListingID(v);
    }

    public setOfferID(v: number) {
        this.offerID = v;
        this.gc.offerID = v;
    }

    protected initMe() {
        this.listingID = this.gc.listingID;
        this.offerID = this.gc.offerID;
        this.escrowID = this.gc.escrowID;
        this.currentListing = this.gc.currentListing;
        this.currentOffer = this.gc.currentOffer;
        this.currentEscrow = this.gc.currentEscrow;
    }

    protected bindEscrows() {
        let _response = null;
        if (this.listingID > 0) {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getEscrows(this.listingID).subscribe((r) => _response = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && _response.data) {
                        this.escrows = _response.data;
                        if (this.escrows.length > 0 && ((this.escrowID === 0) || (this.escrowID > 0 && this.escrows.find(e => { return e.id === this.escrowID; }) == null))) {
                            this.setEscrowID(this.escrows[0].id);
                        }
                    }
                });
        } else if (this.escrowID > 0 && this.escrows.find(_e => { return _e.id === this.escrowID; }) == null) {
            // IF escrow ID is set but escrows List does not have current escrow ID
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getEscrow(this.escrowID);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response && _response.data) {
                            this.setCurrentEscrow(_response.data);
                            this.escrows.push({ id: _response.data.id, address: _response.data.address });
                        }
                    });
            }
        }
    }

    private bindListings() {
        let _response = null;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getMyListingsAs(this.gc.role).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response && _response.data) {
                    this.listings = _response.data;
                    // DO NOT SET Listing DEFAULT LISTING
                }
            });
    }
}
