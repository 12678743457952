import { Injectable, Inject, NgZone } from '@angular/core';
import { appEnvironment } from '../../environments/environment';
import { Router } from '@angular/router';
// tslint:disable-next-line: import-blacklist
import { Observable, of, empty, throwError, EMPTY } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { GlobalComponent } from '../global.component';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { defaultTimeout } from '../models';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class DataService {

    private readonly apiVersionID: string = 'ver';
    private _apiEndPoint: string;
    private timeoutInMilliSeconds: number = 0;
    constructor(private http: HttpClient, @Inject(GlobalComponent) private g) {
        this._apiEndPoint = this.endPoint();
    }

    public getIPAddress() {
        this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
            sessionStorage.setItem('myip', res.ip);
        }, (error) => {
            sessionStorage.removeItem('myip');
        });
    }

    APIPreLoginPost(url: string, urldata: any, sModule?: string, timeoutInSeconds: number = 60, forceThisVErsion: string = null) {
        if (url == null || url === 'null') return;

        const _sID = this.getSessionID();

        if (!url.toLowerCase().startsWith('http:'))
            url = (appEnvironment.apiEndPoint ?? '') + ((appEnvironment.apiEndPoint ?? '').endsWith('/') ? '' : '/') + 'pre' + ((url ?? '').startsWith('/') ? '' : '/') + url;

        if (urldata == null)
            urldata = {
                imID: this.g.hubConnectionID
            };
        else
            urldata['imID'] = this.g.hubConnectionID;

        if (timeoutInSeconds == null && timeoutInSeconds < 0) {
            timeoutInSeconds = 60;
            this.timeoutInMilliSeconds = timeoutInSeconds * 1000;
        }

        const _bDate = new Date();
        const _sbDate = this.ConvertDateToString(_bDate) + ' ' + _bDate.getHours() + ':' + _bDate.getMinutes() + ':' + _bDate.getSeconds() + '.' + _bDate.getMilliseconds();

        const data = {
            criteria: (urldata == null ? '' : urldata),
            sessionID: _sID,
            browserDate: _sbDate,
            applicationID: appEnvironment.applicationID,
            module: sModule
            , imID: this.g ? this.g.hubConnectionID : ''
        };

        return this.http.post(url, data
            , { headers: this.getHeaders(true), observe: 'response', responseType: 'json', withCredentials: false })
            .pipe(
                map((res: any) => this.extractData(res, true)),
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    APIPost(url: string, jsonData: any, sModule?: string, timeoutInSeconds: number = 60, forceThisVersion: string = null) {
        if (url == null || url === 'null') return;

        const _sID = this.getSessionID();

        if (timeoutInSeconds == null || timeoutInSeconds < 0) {
            timeoutInSeconds = 60;
            this.timeoutInMilliSeconds = timeoutInSeconds * 1000;
        }

        if (jsonData == null)
            jsonData = {
                imID: this.g.hubConnectionID
            };
        else
            jsonData['imID'] = this.g.hubConnectionID;

        if (sModule == null) sModule = '';

        const _bDate = new Date();
        const _sbDate = this.ConvertDateToString(_bDate) + ' ' + _bDate.getHours() + ':' + _bDate.getMinutes() + ':' + _bDate.getSeconds() + '.' + _bDate.getMilliseconds();

        const data = {
            criteria: (jsonData == null ? '' : jsonData),
            sessionID: _sID,
            browserDate: _sbDate,
            applicationID: appEnvironment.applicationID,
            module: sModule
            , imID: this.g ? this.g.hubConnectionID : ''
        };

        if (!url.toLowerCase().startsWith('http:') && _sID != null && _sID.trim() !== '' )
            url = (appEnvironment.apiEndPoint ?? '') + ((appEnvironment.apiEndPoint ?? '').endsWith('/') || (url ?? '').startsWith('/') ? '' : '/') + url;
        else if (!url.toLowerCase().startsWith('http:'))
            url = (appEnvironment.apiEndPoint ?? '') + ((appEnvironment.apiEndPoint ?? '').endsWith('/') ? '' : '/') + 'pre' + ((url ?? '').startsWith('/') ? '' : '/') + url;

        if (_sID != null && _sID.toString().trim() !== '') {
            return this.http.post(url, data,
                { headers: this.getHeaders(false, forceThisVersion), observe: 'response', responseType: 'json', withCredentials: false })
                .pipe(
                    map((res: any) => this.extractData(res)),
                    catchError((error: HttpErrorResponse) => this.handleError(error))
                );
        } else {
            return EMPTY;
        }
    }

    APIHtmlToPdfPost(url: string, jsonData: any, sModule?: string, forceThisVersion: string = null) {
        if (url == null || url === 'null') return;

        if (sModule == null) sModule = '';
        const _sID = this.getSessionID();

        const data = {
            criteria: (jsonData == null ? '' : jsonData),
            sessionID: _sID,
            applicationID: appEnvironment.applicationID,
            module: sModule
        };

        if (!url.toLowerCase().startsWith('http:'))
            url = this._apiEndPoint + url;

        return this.http.post(url, data,
            {
                headers: this.gePdfRequestHeaders(false, forceThisVersion), observe: 'response', responseType: 'arraybuffer', withCredentials: false
            })
            .pipe(
                map((res: any) => this.extractData(res)),
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    sort(columnName) { }

    upload(entityName: string, id: number, file: File, sModule: string = ''): Observable<object> {
        const formData: FormData = new FormData();
        formData.append('File', file, file.name);
        // return this.http.post(environment.apiEndPoint + '/upload/file', formData);

        if (sModule == null) sModule = '';
        const _sID = this.getSessionID();

        const data = {
            criteria: { entity: entityName, id: id, file : file },
            sessionID: _sID,
            applicationID: appEnvironment.applicationID,
            module: sModule
        };
        // , observe: 'response', responseType: 'arraybuffer'
        const _url = this._apiEndPoint + '/' + entityName + '/file/upload';
        return this.http.post(_url, data,
            {
                headers: this.gePdfRequestHeaders(false), withCredentials: false
            })
            .pipe(
                map((res: any) => this.extractData(res))
            );

    }

    // #region Private
    private getUsersLocale(defaultValue: string): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
        return lang;
    }
    private ConvertDateToString(value: any): string {
        if (moment(value).isValid()) {
            const _dt = moment(value).toDate();
            const stringValue = (_dt.getMonth() + 1) + '/' + _dt.getDate() + '/' + _dt.getFullYear();
            return stringValue;
        } else
            return '';
    }
    private getHeaders(isPreSession: boolean = false, forceThisVersion: string = null): HttpHeaders {

        if (this.timeoutInMilliSeconds == null || this.timeoutInMilliSeconds < 0)
            this.timeoutInMilliSeconds = defaultTimeout;

        let _headers: HttpHeaders = null;
        // Others are Set by HTTP Interceptor
        if (!isPreSession)
            _headers = (new HttpHeaders()).append('AppToken', appEnvironment.applicationID + ':'
                + this.getSessionID()).append(this.apiVersionID, forceThisVersion ?? appEnvironment.apiVersion);
        else
            _headers = (new HttpHeaders()).append('AuthIgnore', 'true')
                .append(this.apiVersionID, forceThisVersion ?? appEnvironment.apiVersion);

        if (appEnvironment.production && this.timeoutInMilliSeconds > 0) {
            _headers = _headers.append('timeout', this.timeoutInMilliSeconds.toString());
        }

        return _headers;
    }
    private gePdfRequestHeaders(isPreSession: boolean, forceThisVersion: string = null): HttpHeaders {

        if (this.timeoutInMilliSeconds == null || this.timeoutInMilliSeconds < 0)
            this.timeoutInMilliSeconds = defaultTimeout;

        let _headers: HttpHeaders = this.getHeaders(isPreSession, forceThisVersion);

        _headers = _headers.append('Accept', 'application/pdf');

        return _headers;
    }

    private extractData(res: any, bSaveSessionID: boolean = false): any {
        const body = res.body ? res.body : res._body;

        if (body != null && body['buildVersion'] != null && body['buildVersion'])
            sessionStorage.setItem('lastServerBuild', body['buildVersion'] + '');

        if (bSaveSessionID === true && body != null && body['sessionID'] != null && body['sessionID']) {
            const _t = body['sessionID'];
            const _s = this.g.user;
            if (_s != null && (_s.sessionID == null || _s.sessionID.toString().trim() === '' || _s !== _t)) {
                _s.sessionID = _t;
                this.g.user = _s;
            }
        }

        return body || {};
    }

    private getPropertyDescriptor(obj: any, prop: string): PropertyDescriptor {
        let desc;
        do {
            desc = Object.getOwnPropertyDescriptor(obj, prop);
            // tslint:disable-next-line: no-conditional-assignment
        } while (!desc && (obj = Object.getPrototypeOf(obj)));
        return desc;
    }

    private endPoint() {
        let _ep: string = '';
        if (document) {
            const _domain = document.location.hostname.toLowerCase();
            if (_domain === 'localhost' || _domain == null || _domain === '')
                _ep = appEnvironment.fallBackAPIEndPoint;
            else {
                _ep = _domain.replace(appEnvironment.applicationBaseName, '');
                if (!_ep.startsWith('http'))
                    _ep = 'https://' + _ep;

                const _i = _ep.indexOf('.');
                if (_i >= 0)
                    _ep = _ep.substring(0, _i) + appEnvironment.apiDomain;

                _ep += '';
            }
        }
        return _ep;
    }

    private getSessionID(): string {
        let _sessionID = this.g.user?.sessionID;
        if (_sessionID == null || _sessionID.trim() === '')
            _sessionID = '';

        return _sessionID;
    }

    private handleError<T>(result?: T) {
        this.g.onError.emit(result);
        return throwError(result);
        // return (error: any): Observable<T> => {
        //    //return of(result as T);
        //    // Let the app keep running by returning an empty result.
        //    return empty();
        // };
    }

    // #endregion
}

@Injectable({
    providedIn: 'root'
})
export class SseService {
    constructor(private _zone: NgZone) { }
    getServerSentEvent(url: string): Observable<any> {
        return new Observable(observer => {
            const eventSource = this.getEventSource(url);
            eventSource.onmessage = event => {
                this._zone.run(() => {
                    observer.next(event);
                });
            };
            eventSource.onerror = error => {
                this._zone.run(() => {
                    observer.error(error);
                });
            };
        });
    }
    private getEventSource(url: string): EventSource {
        return new EventSource(url);
    }
}
