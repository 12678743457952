import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ResizeService {
    private resizeSubject: Subject<Window>;

    constructor(private eventManager: EventManager) {
        this.resizeSubject = new Subject();
        this.eventManager.addEventListener(document.body, 'resize', this.onResize.bind(this));
    }

    get onResize$(): Observable<Window> {
        return this.resizeSubject.asObservable();
    }

    private onResize(event: UIEvent) {
        this.resizeSubject.next(event.target as Window);
    }
}
