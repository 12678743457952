import { RecordTimeStamp, } from '../base.model';
import { item } from '../appCommon.models';
import { Address } from '../address.model';
import { PaymentOption, ServiceProviderDetailType, ServiceWorkOrderStatus, Role } from '../enums.component';
import { Utils } from '../../common/utils';

export enum BidType {
    NotSet = 0,
    OfferRFQ = 1,
    ListingService = 2,
    ServiceWorkOrder = 3
}
export enum BidRFQStatus {
    None = 0,
    Created = 1,
    Submitted = 2,
    Received = 3,
    Awarded = 4,
    DidNotGetAward = -5,
    Closed = 6,
    AwardAcknowledged = 7,
    Cancelled = 8,
    Declined = 9,
    Responded = 10,
    Revoked = 11,
    PendingAward = 12
}

export class ServiceProviderEntity extends RecordTimeStamp {

    public canEdit: boolean = false;
    public nameLower: string;

    private _serviceProviderID: number = 0;
    private _name: string = ''; private _federalTaxID: string = ''; private _phone: string = ''; private  _email: string = '';
    private _logoURL: string = '';
    private _webSite: string = '';
    private _taxEntityType: number = 0;
    private _initialRating: number;
    private _addreses: Address[] = [];

    constructor() {
        super();
    }

    public get ServiceProviderID(): number {
        return this._serviceProviderID;
    }
    public set ServiceProviderID(value: number) {
        this.id = this._serviceProviderID = value;
    }
    public get Name(): string {
        return this._name;
    }
    public set Name(value: string) {
        if (this._name !== value)
            this.isDirty = true;
        this._name = value;
    }

    public get LogoURL(): string {
        return this._logoURL;
    }
    public set LogoURL(value: string) {
        if (this._logoURL !== value)
            this.isDirty = true;
        this._logoURL = value;
    }
    public get TaxEntity(): number {
        return this._taxEntityType;
    }
    public set TaxEntity(value: number) {
        if (this._taxEntityType !== value)
            this.isDirty = true;
        this._taxEntityType = value;
    }
    public get FederalTaxID(): string {
        return this._federalTaxID;
    }
    public set FederalTaxID(value: string) {
        if (this._federalTaxID !== value)
            this.isDirty = true;
        this._federalTaxID = value;
    }
    public get Phone(): string {
        return this._phone;
    }
    public set Phone(value: string) {
        if (this._phone !== value)
            this.isDirty = true;
        this._phone = value;
    }

    public get Email(): string {
        return this._email;
    }
    public set Email(value: string) {
        if (this._email !== value)
            this.isDirty = true;
        this._email = value;
    }
    public get Addresses(): Address[] {
        return this._addreses;
    }
    public set Addresses(value: Address[]) {
        if (value)
            this._addreses = value.map((a) => Utils.castTo(a, new Address()));
        else
            this._addreses = new Array<Address>();
    }
    public get InitialRating(): number {
        return this._initialRating;
    }
    public set InitialRating(v: number) {
        this._initialRating = v;
    }

    public get WebSite(): string {
        return this._webSite;
    }
    public set WebSite(value: string) {
        if (this._webSite !== value)
            this.isDirty = true;
        this._webSite = value;
    }
}

export class SPSearchResult extends item {
    lat: number  = 0;
    lng: number = 0;
    address: string = '';
    distance: number  = 0;
    numberOfReviews: number  = 0;
    averageRating: number = 0;
    staff: any[] = new Array<any>();
    licenses: any[] = new Array<any>();
    insurances: any[] = new Array<any>();
    paymentOption: number = 0;
    administrators: any[] = new Array<any>();
    cost: number = 0;
    offerings: any[] = new Array<any>();
    ordersFulfilledLastYear: number = 0;
}

export class SPSelectionModel extends RecordTimeStamp {
    serviceProviderID: number = 0;
    serviceTypeID: number = 0;
    optionIDs: any = [];
    currentPrice: number = 0;
    estimated: boolean = false;
    finance: any = {}; // Payment OR PaymentUIData (for BrainTree);
    constructor() { super(); }

}

export class SPOfferedService extends item {
    public willChargeOnlyIfCloses: boolean;
    public manageDetailVersions: boolean;
    public paymentOption: PaymentOption;
    public applicableStates: item[];
    constructor() {
        super();
        this.willChargeOnlyIfCloses = false;
        this.paymentOption = PaymentOption.NotSet;
        this.applicableStates = new Array<item>();
    }
}

export class SPProductDetail extends RecordTimeStamp {

    private _offeringID: number;
    private _productID: number;
    private _optionID: number;
    private _detailTypeID: ServiceProviderDetailType;
    private _serviceProviderID: number;
    private _roleIDs: Role[] = new Array<Role>();
    private _role: string;
    private _deliverAtWorkOrderStatus: ServiceWorkOrderStatus;
    private _startDate: Date;
    private _acceptanceRequired: boolean = false;
    private _subject: string;
    private _endDate: Date;

    constructor() {
        super();
        this.id = this.serviceProviderID = this.offeringID = this.productID = this.optionID = 0;
        this.subject = this.role = '';
        this.startDate = this.endDate = Utils._minDate;
        this.detailTypeID = ServiceProviderDetailType.NotSet;
        this.deliverAtWorkOrderStatus = ServiceWorkOrderStatus.None;
    }

    public get serviceProviderID(): number { return this._serviceProviderID; }
    public set serviceProviderID(v: number) { this.id = this._serviceProviderID = v; }

    public get offeringID(): number { return this._offeringID; }
    public set offeringID(v: number) { this._offeringID = v; }


    public get productID(): number { return this._productID; }
    public set productID(v: number) { this._productID = v; }


    public get optionID(): number { return this._optionID; }
    public set optionID(v: number) { this._optionID = v; }


    public get detailTypeID(): ServiceProviderDetailType { return this._detailTypeID; }
    public set detailTypeID(v: ServiceProviderDetailType) { this._detailTypeID = v; this.isDirty = true; }


    public get roleIDs(): Role[] { return this._roleIDs; }
    public set roleIDs(v: Role[]) { this._roleIDs = v; this.isDirty = true; }


    public get role(): string { return this._role; }
    public set role(v: string) { this._role = v; }


    public get deliverAtWorkOrderStatus(): ServiceWorkOrderStatus { return this._deliverAtWorkOrderStatus; }
    public set deliverAtWorkOrderStatus(v: ServiceWorkOrderStatus) { this._deliverAtWorkOrderStatus = v; this.isDirty = true; }

    public get acceptanceRequired(): boolean { return this._acceptanceRequired; }
    public set acceptanceRequired(v: boolean) { this._acceptanceRequired = v; this.isDirty = true; }

    get startDate(): Date { return this._startDate; }
    set startDate(v: Date) { this._startDate = v; this.isDirty = true; }

    get endDate(): Date { return this._endDate; }
    set endDate(v: Date) { this._endDate = v; this.isDirty = true; }

    public get subject(): string { return this._subject; }
    public set subject(v: string) { this._subject = v; this.isDirty = true; }
}

export class SPServiceDetailContentRequestModel {
    public id: number;
    public serviceProviderID: number;
    public serviceTypeID: number;
    public optionID: number;

    public content : string;
}

export class SPDetailType extends item{
    public manageVersions: boolean;
    public typeID: ServiceProviderDetailType = ServiceProviderDetailType.NotSet;
    constructor(){
        super();
        this.manageVersions = false;
        this.id = this.typeID  = 0;
        this.name = '';
    }
}

export class SPBid extends RecordTimeStamp {
    public id: number = 0;
    public workOrderID: number = 0;
    public options: item[] = new Array<item>();
    public price: number = 0;
    public fixedPrice: number = 0;
    public percentagePrice: number = 0;
    public minimumPrice: number = 0;
    public completeInDays: number = 0;
    public completeBy: Date = Utils._minDate;
    public expiresOn: Date = Utils._minDate;
    public bidType: BidType = BidType.NotSet;
    public isEstimated: boolean = false;
    public nextSteps: object = [];
    public type: any = {}; // type and serviceType returned from 2 different APIs
    public serviceType: any = {}; // type and serviceType returned from 2 different APIs
    public address: any = {};
    public currentStatus: BidRFQStatus = BidRFQStatus.None;
    public statusDescription: string = '';
    public isOpen: boolean = false;
    public otherNumberOfBids: number = 0;
    public hasResponded: boolean = false;
    public canUpdate: boolean = false;
    public canUpdateFixedPrice: boolean = true;
    public canUpdatePercentagePrice: boolean = true;
    public serviceProvider: string = '';
    constructor() {
        super();
    }
}

export class SPVisit extends RecordTimeStamp {
    public id: number = 0;
    public workOrderID: number = 0;
    public approved: boolean = false;
    public canChange: boolean = false;
    public visitDate: Date = Utils._minDate;
    public vistingPerson: string = '';
    public address: string = '';
    public serviceTypeID: number = 0;
    public serviceType: string = '';
    public notes: string = '';
    public responseNotes: string = '';
    public nextSteps: any = [];
    constructor() { super(); }
}
