<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <div *ngIf="workOrders && workOrders.length > 1">
        <mat-form-field class="first">
            <mat-label class="placeholder">Work Orders<i class="fa fa-question-circle-o warning-color" title="My Work Orders"></i></mat-label>
            <mat-select [(ngModel)]="workOrderID">
                <mat-option *ngFor="let p of workOrders" [value]="p.id">{{p.type.Description + ' @ ' + p.address.FullStreetAddress}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="showCurrentVisits && upcomingVisits && upcomingVisits.length > 0">
        <mat-form-field class="first">
            <mat-label class="placeholder">Upcoming Visits<i class="fa fa-question-circle-o warning-color" title="Upcoming Visits for all Staff memebers"></i></mat-label>
            <mat-select [(ngModel)]="selectedVisitID">
                <mat-option *ngFor="let p of upcomingVisits" [value]="p.id">{{p.name}}<a *ngIf="p.id > 0 && p.canChange && !updating" (click)="removeVisit(p.id)" title="Cancel Visit"><i class="fa fa-times-circle-o danger-color"></i></a></mat-option>
            </mat-select>
        </mat-form-field>
        <hr />
    </div>
    <div class="app-flex-container app-2col">
        <app-datepicker #txtVisitDate id="txtVisitDate" [value]="visitDate" [placeholder]="'Visit Date'" [minDate]="today" [maxDate]="maxDate" (valueChanged)="visitDateChanged($event)" [cssClass]="'first'"></app-datepicker>
        <div>
            <mat-form-field class="first medium">
                <input matInput appDisableAutofill placeholder="Time of Visit" readonly [(ngModel)]="visitTime" class="noborder"
                       (change)="setReadyForSubmit()">
            </mat-form-field>
            <!--<ngx-material-timepicker-toggle [for]="with5Gap"></ngx-material-timepicker-toggle>
            <ngx-material-timepicker #with5Gap [minutesGap]="15" (max)="'06:00 pm'" (min)="'07:00 am'" (value)="visitTime"></ngx-material-timepicker>-->
        </div>
    </div>
    <mat-form-field>
        <mat-select [(ngModel)]="duration" placeholder="Duration of visit" (selectionChange)="setReadyForSubmit()">
            <mat-option value=15>15 Minutes</mat-option>
            <mat-option value=30>30 Minutes</mat-option>
            <mat-option value=60>One Hour</mat-option>
            <mat-option value=120>2 Hour</mat-option>
            <mat-option value=180>3 Hour</mat-option>
            <mat-option value=240>4 Hour</mat-option>
            <mat-option value=480>Entire Day</mat-option>
        </mat-select>
    </mat-form-field>
    <ng-template [ngIf]="(this.staff && this.staff.length > 0)" [ngIfElse]="noStaff">
        <mat-form-field>
            <mat-label class="placeholder">Staff<i class="fa fa-question-circle-o warning-color" title="Select the staff who will be visting"></i></mat-label>
            <mat-select [(ngModel)]="selectedStaffID" [disabled]="this.staff.length <= 1" (selectionChange)="setReadyForSubmit()">
                <mat-option *ngFor="let p of staff" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>
    <ng-template #noStaff>
        <h4 class="danger" *ngIf="componentBusy === false">{{'Workorder.Visit.NoStaff' | translateTag}}</h4>
    </ng-template>
    <mat-form-field required>
        <mat-label class="placeholder">Reason<i class="fa fa-question-circle-o warning-color" title="Select the reason of the visit"></i></mat-label>
        <input matInput appDisableAutofill type=text maxlength="200" [(ngModel)]="reason" (change)="setReadyForSubmit()" />
    </mat-form-field>
    <hr />
    <mat-error *ngIf="(error ?? '') !== ''">Failed. {{this.errror}}</mat-error>
    <mat-progress-bar mode="buffer" *ngIf="componentBusy === true"></mat-progress-bar>
    <button color="primary" (click)="addVisit()" *ngIf="this.componentBusy === false && readyForSubmit">Submit</button>
</div>
