<div class="mainPanel">
    <h2 class="pageHeader">Staff</h2>
    <mat-card class="row big">
        <mat-form-field *ngIf="!adding && isAdmin" style="width:Calc(100% - 24px)">
            <input matInput appDisableAutofill placeholder="Add New Staff's Email Address" maxlength="100" type="text" [(ngModel)]="emailAddress" emailvalidator requiredvalidator [required]="true" 
                   autofocus autoFocus selectOnFocus #em="ngModel" />
            <a (click)="addStaff()" *ngIf="!adding && utils.isNullOrEmpty(emailAddress) === false" class="float-right"><i class="fa fa-plus-circle green" title="Add Staff"></i></a>
            <mat-error [hidden]="em.valid || em.pristine">Email is required and format should be <i>john&#64;doe.com</i>.</mat-error>
        </mat-form-field>
    </mat-card>
    <div class="section" *ngIf="staff && staff.length > 0">
        <mat-card *ngFor="let s of staff" class="row">
            <div class="row">
                <div class="col-lg-2">
                    <figure>
                        <div style="margin:10px" title="{{s.name + ' [' + s.pid + ']' }}">
                            {{s.pid != user.details.PID ? (s.name | ellipsis:20): 'Me'}}
                            <sup (click)="removeStaff(s.pid)" title="Remove Staff" *ngIf="s.pid != this.user.details.PID && isAdmin"><i class="fa fa-times-circle-o danger"></i></sup>
                        </div>
                        <img class="profile" src="{{utils.isNullOrEmpty(s.image) ? '/assets/images/avatar.svg' : s.image}}">
                    </figure>
                </div>
                <div class="col-lg-10" dndDropzone (dndDrop)="onAddRole(s, $event)">
                    <span *ngFor="let r of s.roles;  let i = index" class="link help-help {{i%2 === 0 ? 'alt' : ''}}">
                        {{r.name}}
                        <sup (click)="removeRole(s, r.id)" title="Remove Role" *ngIf="r.id != this.defaultStaffRoleID && isAdmin"><i class="fa fa-times-circle-o danger"></i></sup>
                    </span>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="section warning" *ngIf="staff == null || staff.length == 0">
        <h4>You have not setup a company and no staff members has been associated with the company. You can do so from <a (click)="gotoRoute(appURLs.serviceProviderProfile)">Company Profile page.</a></h4>
    </div>
    <mat-card *ngIf="staff && staff.length > 0 && roles && roles.length > 0 && isAdmin" class="section">
        <mat-card-title>Available Roles</mat-card-title>
        <a *ngFor="let r of roles;  let i = index" class="link help-help {{i%2 === 0 ? 'alt' : ''}} grabbable" id="avlRole{{r.id}}"
           [dndDraggable]="r" [dndEffectAllowed]="'all'" [dndDisableIf]="!isAdmin">
            {{r.name}}
        </a>
    </mat-card>
</div>