import { RecordTimeStamp } from './base.model';
import { Role } from './enums.component';
import { Utils } from '../common/utils';

export enum LegalTermType {
    NotSet = 0,
    GeneralAccount = 1,
    TermsOfUse = 2,
    PrivacyPolicy = 3,
    Product = 4,
    CriticalServiceMissing = 5,
    OfferSubmission = 6,
    NewLoan = 7,
    SPSelection = 8,
    OfferDocument = 9
}

export class SignUp {
    firstName: string;
    lastName: string;
    emailAddress: string;
    confirmEmailAddress: string;
    dne: boolean = false;
    isAboveSeventeen: boolean = false;
    tncAgreed: boolean = false;
    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.emailAddress = '';
        this.confirmEmailAddress = '';
    }
}

export class LegalTerm extends RecordTimeStamp {
    id: number = 0;
    name: string = '';
    type: LegalTermType = LegalTermType.NotSet;
    productID: number = 0;
    role: Role = Role.NotSet;
    versions: LegalTermVersion[] = [];
    constructor() {
        super();
    }
}

export class LegalTermVersion extends RecordTimeStamp {
    id: number = 0;
    startDate: Date;
    endDate: Date;
    constructor() {
        super();
        this.startDate = this.endDate = Utils._minDate;
    }
}
