<div class="appContent" *ngIf="currentListing">
    <h2 class="pageHeader">Offer Comparison</h2>

    <mat-card>
        <label class="caption">Property Address</label><label class="data-value auto">{{currentListing.address}}</label>
        <br /><label class="caption">Listing Price</label><label class="data-value auto">{{currentListing.price}}</label>
    </mat-card>
    <mat-card *ngIf="offers && offers.length > 0" class="section">
        <table class="listing-table">
            <thead>
                <tr>
                    <th></th>
                    <th *ngFor="let v of offers[0].values;let i = index" style="padding:15px">
                        <a (click)="GoToURLWithSession(appURLs.offer, handover.offerID, v.id)">Offer #{{v.id}}<br />{{v.buyers}}</a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let o of offers;let r = index" class="{{r%2 === 0 ? 'alternate' : ''}}">
                    <td style="width: 150px;padding: 5px;font-weight:bolder">{{o.property.PropertyDescription}}</td>
                    <td *ngFor="let v of o.values; let i = index"  style="padding:5px 15px; border-left:1px solid black">{{v.value}}</td>
                </tr>
            </tbody>
        </table>
    </mat-card>
</div>