import { BaseService } from './base.service';
import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CodeService extends BaseService {

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule, ds);
    }

    getCodesByCategoryName(sCategoryName: string) {
        const data = { categoryName: sCategoryName };
        return this._dataService.APIPost('/code/codesByCategoryName', data, this.requestingModule);
    }

    getCodesByCategoryID(sCategoryID: number) {
        const data = { categoryID: sCategoryID };
        return this._dataService.APIPost('/code/codesByCategoryID', data, this.requestingModule);
    }

    getServiceTypes(): Observable<any> {
        return this.getCacheData('/code/serviceTypes');
    }

    getAdditionalOrderableServices(iServiceTypeID: number) {
        const data = { ID: iServiceTypeID };
        return this._dataService.APIPost('/code/additionalOrderableServices', data, this.requestingModule);
    }

    getSecurityRoles() {
        const data = null;
        return this._dataService.APIPost('/code/securityRoles', data, this.requestingModule);
    }

    getRoles() {
        return this.getCacheData('/code/roles');
    }

    getTaxEntities() {
        return this.getCacheData('/code/taxEntities');
    }

    getWorkOrderStatuses() {
        return this.getCacheData('/code/workOrderStatuses');
    }

    getLicenseTypes() {
        return this.getCacheData('/code/licenseTypes');
    }

    getInsuranceTypes() {
        return this.getCacheData('/code/insuranceTypes');
    }

    getPropertyTypes() {
        return this.getCacheData('/code/propertyTypes', null, false);
    }

    getServiceOptions(iServiceTypeID: number) {
        const data = { ID: iServiceTypeID };
        return this._dataService.APIPost('/code/serviceTypeOptions', data, this.requestingModule);
    }
}
