import { Component, ViewChild, Input , Output, EventEmitter, Inject} from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { GlobalComponent } from '../../global.component';

import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as cloneDeep from 'lodash/cloneDeep';
import { item, downloadableContent, DocumentCategory, DocumentEntityType } from '../../models';
import { DocumentService } from './document.service';
import { DocumentPermissionsDialog } from './dialog/permissions.dialog';
import { gzip } from 'zlib';
import { Utils } from '../utils';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html'
})
export class DocumentsComponent extends BaseComponent {

    DocumentEntityType: typeof DocumentEntityType = DocumentEntityType;

    @ViewChild('documentsTable') table;
    @Input() entityCategory: DocumentCategory = DocumentCategory.NotSet;
    @Input() entityPartners: item[] = new Array<item>();
    @Input() canAdd: boolean = false; // Has Permissions
    @Input() showNoDocumentMessage: boolean = false;
    @Input() noDocumentMessage: string = 'No Documents are available for selected Item.';
    @Input() @Output() documents: any = [];
    @Input() cssClass: string = '';

    addingDocument: boolean = false;

    docsOnPage: number = 20;
    docsPageNumber: number = 1;

    @Input() title: string = 'Document(s)';
    @Input() showTitle: boolean = true;
    @Input() allowAdd: boolean = false; // Component is is ReadOnly mode and no additional additions allowed

    @Output() public documentAdded: EventEmitter<object> = new EventEmitter<object>();
    @Output() public parentNotify: EventEmitter<boolean> = new EventEmitter<boolean>();
    lblNewDocumentTitle: string = 'New Document';
    assigntoSPID: number = 0;
    @Input() @Output() notifyParent: boolean = false;
    @Input() @Output() notifyParentLabel: string = 'Notify Parent';

    newDocumentName: string = '';
    fileExtension: any = ['pdf', 'txt'];
    @ViewChild('newDoc') newDoc: any;
    doc: downloadableContent = null;

    private _parentNotification: boolean = false;

    private _entityTypeKeyID: number = 0;
    private _entityType: DocumentEntityType = DocumentEntityType.Missing;
    private _dataservice: DataService;
    private _router: Router;
    constructor(ds: DataService, rs: Router, private dialog: MatDialog, @Inject(GlobalComponent) g: GlobalComponent) {
        super('documents', rs, g);
        this._dataservice = ds;
        this._router = rs;
    }

    @Output() @Input() get entityType(): DocumentEntityType { return this._entityType; }
    set entityType(v: DocumentEntityType) {
        const _changed: boolean = this._entityTypeKeyID !== v;
        this._entityType = v;
        if (_changed) {
            this.bindDocuments();
        }
    }

    @Output() @Input() get entityTypeKeyID(): number { return this._entityTypeKeyID; }
    set entityTypeKeyID(v: number) {
        const _changed: boolean = this._entityTypeKeyID !== v;
        this._entityTypeKeyID = v;
        if (v === 0) this.documents = [];
        if (_changed) {
            this.bindDocuments();
        }
    }

    @Input() set newDocumentTitle(v: string) { this.lblNewDocumentTitle = v; }
    get readyToAdd() { return this.doc != null && this.doc.content != null; }

    get parentNotification(): boolean { return this._parentNotification; }
    set parentNotification(v: boolean) {
        const _change = this._parentNotification !== v;
        this._parentNotification = v;
        if (_change) {
            this.parentNotify.emit(v);
        }
    }

    bindDocuments() {
        this.documents = [];
        if (this.entityType === DocumentEntityType.Missing || this.entityTypeKeyID === 0) {
            return;
        }
        this.componentBusy = true;
        let _response;
        (new DocumentService(this._dataservice, this._router, this.myResourceCategory)).getDocuments(this.entityType, this.entityTypeKeyID).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response && _response.data) {
                    this.documents = _response.data;
                }
            });
    }

    clear() {
        this.documents = [];
    }

    fileUploaded($event): void {
        this.fileHandler($event);
        this.newDoc.nativeElement.value = '';
    }

    fileHandler($event): void {
        const text = [];
        const _file = $event.srcElement.files;

        if (_file) {
            const _fName = _file[0].name;
            if (_fName.lastIndexOf('.') > 0) {
                const _fExtension = _fName.substring(_fName.lastIndexOf('.') + 1);
                if (this.fileExtension.indexOf(_fExtension) < 0) {
                    this.showMessage('Invalid file uploaded');
                    return;
                }
                this.doc = new downloadableContent();
                this.doc.name = _fName.substring(0, _fName.lastIndexOf('.'));
                if (_file[0].type)
                    this.doc.mime = _file[0].type;
            } else {
                this.showMessage('Invalid file uploaded');
                return;
            }
        }

        const input = $event.target;
        const reader = new FileReader();
        reader.readAsDataURL(input.files[0]);

        reader.onloadend = (data) => {
            this.doc.content = reader.result;
        };
        reader.onerror = () => {
            alert('Unable to read ' + input.files[0]);
        };
    }

    addDoc() {

        if (this.doc == null)
            this.doc = new downloadableContent();

        this.doc.name = this.newDocumentName;
        this.doc['entityType'] = this.entityType;
        this.doc['entityID'] = this.entityTypeKeyID;
        this.doc['category'] = this.entityCategory;
        this.doc['spID'] = this.assigntoSPID;
        if (this.entityPartners != null) {
            const _ids: number[] = [];
            this.entityPartners.forEach((p) => {
                if (p.id > 0) { _ids.push(p.id); }
            });
            if (_ids.length > 0)
                this.doc['allowReadTo'] = _ids;
        }
        let _response;
        this.addingDocument = true;
        (new DocumentService(this._dataservice, this._router, this.myResourceCategory)).addDocument(this.doc).subscribe((r) => _response = r
            , (error) => { this.addingDocument = false; this.onApiError(error); }
            , () => {
                this.addingDocument = false;
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error)) {
                        this.showError(_response.error);
                        this.doc = new downloadableContent();
                    } else if (_response.data) {
                        this.documents.push(_response.data);
                        this.showMessage('Document added successfully');
                        this.documentAdded.emit(_response.data);
                        this.doc = new downloadableContent();
                        this.newDocumentName = '';
                    }
                }
            }
        );
    }

    setPermissions(id: number) {
        const dialogRef = this.dialog.open(DocumentPermissionsDialog, {
            data: {
                people: this.entityPartners
                , id: id
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.showMessage('Permissions changed.');
            }
        });
    }

    changeStatus(action$) {
        let _actionResponse;
        (new DocumentService(this._dataservice, this._router, this.myResourceCategory)).changeStatus(parseInt(action$.argument), action$.name).subscribe((r) => _actionResponse = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                        this.showMessage(_actionResponse.message);

                        if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                            this.gotoURL(_actionResponse.redirectURL);
                        else {
                            this.documents = cloneDeep(this.documents.filter(wo => { return (wo.id !== parseInt(action$.argument)); })); // Update Work Order on Client
                            if (_actionResponse.data)
                                this.documents.splice(0, 0, _actionResponse.data);
                        }
                    }
                }
            }
        );
    }

    onDetailToggle(event$) {

    }
}
