<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card *ngIf="options && options.length > 0">
        <mat-form-field>
            <mat-select [(ngModel)]="selectedOptions" multiple placeholder="Option(s)" [disabled]="componentBusy === true">
                <mat-option *ngFor="let o of options" [value]="o.id">{{o.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select [(ngModel)]="selectedOptions" placeholder="Option(s)" [disabled]="componentBusy === true">
                <mat-option *ngFor="let o of options" [value]="o.id">{{o.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card>
    <mat-card>
        <div class="app-flex-container app-2col">
            <mat-form-field>
                <input matInput appDisableAutofill  type="number" class="numeric" maxlength="10" placeholder="Fixed Price" [(ngModel)]="newBid.fixedPrice" 
                       (keydown)="utils.numOnlyLimitingLength($event, 10)"  [disabled]="componentBusy === true && !newBid.canUpdateFixedPrice"/>
            </mat-form-field>
            <mat-form-field >
                <input matInput appDisableAutofill  type="number" class="numeric" maxlength="6" placeholder="% price based on Sale Price" [(ngModel)]="newBid.percentagePrice"
                       (keydown)="utils.numOnlyLimitingLength($event, 6)"  [disabled]="componentBusy === true && !newBid.canUpdatePercentagePrice"/>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <input matInput appDisableAutofill  type="number" class="numeric" maxlength="10" placeholder="Minimum charge" [(ngModel)]="newBid.minimumPrice" 
                       (keydown)="utils.numOnlyLimitingLength($event, 10)"  [disabled]="componentBusy === true"/>
            </mat-form-field>
        </div>
        <div class="app-flex-container app-2col">
            <mat-form-field>
                <input matInput appDisableAutofill  type="number" class="numeric" maxlength="3" placeholder="Complete in days, from approval" [(ngModel)]="newBid.completeInDays" 
                       (keydown)="utils.numOnlyLimitingLength($event, 3, false)"  [disabled]="componentBusy === true"/>
            </mat-form-field>
            <app-datepicker #txtCompleteBy id="txtCompleteBy" [value]="newBid.completeBy" [placeholder]="'Complete By'" (valueChanged)="completeBy($event)" 
                            [disabled]="componentBusy === true" [minDate]="today"></app-datepicker>
        </div>
        <div>
            <app-datepicker #txtExpiresOn id="txtExpiresOn" [value]="newBid.expiresOn" [placeholder]="'Expires On'" (valueChanged)="expiresOn($event)" 
                            [disabled]="componentBusy === true" [minDate]="tomorrow"></app-datepicker>
        </div>
    </mat-card>
    <mat-progress-bar *ngIf="componentBusy === true" mode="buffer"></mat-progress-bar>
    <mat-card *ngIf="saveReady && componentBusy === false" class="section">
        <button matButton (click)="saveBid(true)" title="Submit Bid">Submit</button>
        <a (click)="saveBid()" class="link float-right" title="Save to complete and submit at a later date">Save</a>
    </mat-card>
    <mat-error *ngIf="(error ?? '') != ''">Failed - {{error}}</mat-error>
</div>