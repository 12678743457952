import { Component, ChangeDetectorRef, ViewChild, Inject, AfterViewChecked, AfterViewInit} from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { item } from '../models/appCommon.models';
import { SPBid, WorkOrder } from '../models';

import { PersonService } from '../account/person.service';
import { WorkOrderService } from '../workorders/workorder.service';
import { ServiceProviderService } from './serviceprovider.service';
import { NewBidDialog } from './dialogs/newBid.dialog';
import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { MatTabGroup } from '@angular/material/tabs';
import { appEnvironment } from '../../environments/environment';
import { Utils, rolePosition } from '../common/utils';

@Component({
    selector: 'app-service-provider-bids',
    templateUrl: './serviceProviderBids.component.html'
})
export class ServiceProviderBidsComponent extends BaseComponent implements AfterViewInit {

    serviceTypes: any = [];
    selectedServiceTypes: any = [];
    serviceTypeID: number = 0;
    startDate: Date = moment(this.today).add(-30, 'days').toDate() ;
    endDate: Date;
    myBids: SPBid[] = [];
    possibleWork: WorkOrder[] = [];
    canSearch: boolean = false;

    searched: boolean = false;
    selectedIndex: number = 0;

    @ViewChild('bidTabs', { static: false }) bidTabs: MatTabGroup;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) g: GlobalComponent
        , private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('pgCompanyBids', r, g);

        this.componentBusy = true;
        let _response;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getOfferedServices().subscribe((_r) => _response = _r
            , (error) => { this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (_response.data) {
                        const _data = _response.data.filter((st) => st.searchForBidInStates != null && st.searchForBidInStates.length > 0 );
                        if (_data != null)
                            this.serviceTypes = _data.map((b) => Utils.castTo(b, new item()));

                        this.canSearch = this.gc.hasRole(rolePosition.admin) && this.serviceTypes && this.serviceTypes.length > 0;

                        if (!this.canSearch) {
                            this.bindBids();
                        }
                    }
            });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.canSearch === true && this.bidTabs) {
                const tabCount = this.bidTabs._tabs.length;
                this.bidTabs.selectedIndex = (this.bidTabs.selectedIndex + 1) % tabCount;
                this.cdref.detectChanges();
            }
        }, appEnvironment.delayedNGInit);
    }

    searchForWork() {
        const _types: number[] = [];
        for (const i of this.selectedServiceTypes)
            _types.push(i);

        this.possibleWork = [];
        let _response;
        this.searched = false;
        this.componentBusy = true;
        (new PersonService(this.dataservice, this.r, this.myResourceCategory)).getPossibleWork(_types).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                this.searched = true;
                this.componentBusy = false;
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (_response.data)
                        this.possibleWork = _response.data.map((b) => (new SPBid()).castToMe(b));
                }

            });
    }

    groupChanged($event) {
        if ($event.index === 1 && (this.myBids == null || this.myBids.length === 0))
            this.bindBids();
    }

    createBid($event: any, workOrder: WorkOrder) {

        this.cancelElementEvent($event);
        const workOrderID = workOrder.id;
        let _response;
        this.componentBusy = true;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).createBid(workOrderID).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (_response.data) {
                        _response.data['address'] = workOrder.address;
                        this.myBids.splice(0, 0, Utils.castTo(_response.data, new SPBid()));
                    }
                }

            });

    }

    update($event, iBidID) {

        this.cancelElementEvent($event);

        const dialogRef = this.dialog.open(NewBidDialog, {
            data: {
                bid: this.myBids.find((b) => b.id === iBidID )
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.myBids = cloneDeep(this.myBids.filter((wo) => (wo.id !== iBidID))); // Update Work Order on Client
                if (result)
                    this.myBids.splice(0, 0, Utils.castTo(result, new SPBid()));
            }
        });
    }

    changeStatus(action$) {

        let _actionResponse;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).changeBidStatus(parseInt(action$.argument), action$.name).subscribe((r) => _actionResponse = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                        this.showMessage(_actionResponse.message);

                        if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                            this.gotoURL(_actionResponse.redirectURL);
                        else {
                            this.myBids = cloneDeep(this.myBids.filter((wo) => (wo.id !== parseInt(action$.argument)))); // Update Work Order on Client
                            if (_actionResponse.data)
                                this.myBids.splice(0, 0, Utils.castTo(_actionResponse.data, new SPBid()));
                        }
                    }
                }
            }
        );
    }

    private bindBids() {
        const _types: number[] = [];
        for (const i of this.selectedServiceTypes)
            _types.push(i);

        this.myBids = [];
        let _response;
        this.componentBusy = true;
        (new PersonService(this.dataservice, this.r, this.myResourceCategory)).getBids(_types).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (_response.data)
                        this.myBids = _response.data.map((b) => (new SPBid()).castToMe(b));
                }

            });
    }

}
