import { Component, Inject, OnInit, Input, HostListener } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';

import { Router, Route, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { returnRoute } from '../models/appCommon.models';
import { Utils, rolePosition } from '../common/utils';
import { BaseResponse, Session, User, Address } from '../models';
import { appEnvironment } from '../../environments/environment';
import { PersonService } from '../account/person.service';
import { clearSession } from '../common/sessionService';
import { ScriptService } from '../common/scriptService';

declare const grecaptcha: any;

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html'
})

export class SignInComponent extends BaseComponent implements OnInit {

    uid: string = '';
    pwd: string = ''; newPWD: string = ''; reEnterPWD: string = ''; pid: number = 0;
    forcePasswordChanging: boolean;

    lblErrorMessage: string = ''; lblMessage: string = '';
    css: string = 'appContent';
    inPopup: boolean = false;
    showPwd: boolean = false;

    resettingPassword: boolean = false;
    busy: boolean = false;
    @Input() isFromURL: boolean = false;

    private _returnRoute: returnRoute = new returnRoute();
    private _captchaFailed: boolean = false;
    constructor(private dataservice: DataService, r: Router, @Inject(GlobalComponent) private g: GlobalComponent
        , public dialogRef: MatDialogRef<SignInComponent>, @Inject(MAT_DIALOG_DATA) public data: any
        , private currentRoute: ActivatedRoute) { // , private socialAuthService: AuthService) {
        super('pgLogin', r, g);

        this.gc.clearAppSession();
        this.restoreUser();

        ScriptService.loadExternalScript(appEnvironment.reCaptchaScript, 'www.gstatic.com/recaptcha/');

        if (this.data) {
            if (this.data.returnRoute != null) if (this._returnRoute != null) this._returnRoute.routePath = this.data.returnRoute;
            if (this.data.routeParams != null) if (this._returnRoute != null) this._returnRoute.queryParams = this.data.routeParams;
            if (this.data.css != null) this.css = this.data.css;
            if (this.data.inPopup != null) this.inPopup = this.data.inPopup;
        }

        if (this.data && this.data.returnRoute == null) {
            if (currentRoute && currentRoute.snapshot.params.returnUrl != null) {
                if (this._returnRoute === null) this._returnRoute = new returnRoute();
                this._returnRoute.routePath = currentRoute.snapshot.params.returnUrl;
            }
            if (currentRoute && currentRoute.snapshot.queryParams.returnUrl != null) {
                if (this._returnRoute === null) this._returnRoute = new returnRoute();
                this._returnRoute.routePath = currentRoute.snapshot.queryParams.returnUrl;
            }
            // if (this.data.routeParams) if (this._returnRoute != null) this._returnRoute.queryParams = this.data.routeParams;
            this.isFromURL = this.inPopup === false;
        }

        if (this.isFromURL === true && window.location.href.toLowerCase().endsWith('forgotpassword')) {
            this.forgotPassword();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const _r = this.proceed();
            // if (_r != null && _r === false) {
            //    let element = event.srcElement. nextElementSibling; // get the sibling element
            //    if (element === null)  // check if its null
            //        return;
            //    else
            //        element.focus();   // focus if not null
            // }
        } else if (event.key === 'Escape') {
            this.close();
        } else if (event.key === 'Paste') {
            event.preventDefault();
        }
    }

    toggleShowPwd() {
        this.showPwd = !this.showPwd;
    }

    cancel() {
        this.g.setBusy(false);
        this.resettingPassword = false;
        clearSession();
        if (!this.forcePasswordChanging)
            this.close();
        else
            this.forcePasswordChanging = false;
    }

    close() {
        if (this.isFromURL) {
            if (this._returnRoute && !Utils.isNullOrEmpty(this._returnRoute.routePath))
                this.gotoRoute(this._returnRoute.routePath);
            else
                this.GotoHome();

            if (this.dialogRef && this.dialogRef.close)
                this.dialogRef.close();
        } else if (this.dialogRef.close)
            this.dialogRef.close(this._returnRoute);
        else
            this.GotoHome();
    }

    ngOnInit() {
        this.uid = this.pwd = '';
    }

    proceed() {
        if (this.resettingPassword) {
            this.resetPassword();
        } else {
            if (GlobalComponent._cv === false && grecaptcha != null) {
                // User Login can happen in parallel - If CAPTCHA fails clear the User
                let _response: BaseResponse;
                grecaptcha.ready(() => {
                    grecaptcha.execute(appEnvironment.googleReCaptcha, { action: 'submit' }).then((token) => {
                        (new PersonService(this.dataservice, this.route, this.myResourceCategory)).verifyRecaptcha(token).subscribe(
                            (data) => _response = data,
                            (error) => {
                                this.showResourceError('System.CaptchaFailed');
                                this.cancel();
                            },
                            () => { GlobalComponent._cv = true; });
                    });
                });
            }
            // Verify User in Parrallel.
            this.onVerify_Proceed();
        }
    }

    gotoRegister() {
        this.gotoURL('/register');
        if (this.inPopup)
            this.dialogRef.close();
    }

    forgotPassword() {
        this.clearMessages();
        this.resettingPassword = true;
    }

    resetPassword() {

        if (Utils.isNullOrEmpty(this.uid)) {
            this._showError('Security.Login.ForPasswordChanged');
            return;
        }

        this.busy = true;
        this.lblErrorMessage = '';
        let responseData: any;
        const endpoint = '/user/forgotpassword';
        let requestData;
        requestData = { email: this.uid };

        this.dataservice.APIPreLoginPost(endpoint, requestData).subscribe(
            (data) => responseData = data,
            (error) => { this.lblErrorMessage = 'Invalid email address'; this.busy = false; this.onApiError(error); },
            () => {
                this.busy = false;
                if (responseData != null)
                    if (!Utils.isNullOrEmpty(responseData.error))
                        this._showError('Security.Login.BadEmail');
                    else if (responseData.data != null) {
                        this.resettingPassword = false;
                        this.pwd = this.newPWD = this.reEnterPWD = '';
                        this._showMessage('Security.Login.PasswordSent');
                        setTimeout(() => { this.clearMessages();  }, appEnvironment.autoSaveTime);
                        return;
                    }
            }
        );
    }

    public socialSignIn(socialPlatform: string) {
        let socialPlatformProvider;
        //if (socialPlatform === 'facebook') {
        //    socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        //} else if (socialPlatform === 'google') {
        //    socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
        //}

        // this.socialAuthService.signIn(socialPlatformProvider).then(
        //    (userData) => {
        //        console.log(socialPlatform + ' sign in data : ', userData);
        //        // Now sign-in with userData
        //        // ...

        //    }
        // );
    }

    clearMessages() {
        if (this.inPopup)
            this.lblErrorMessage = this.lblMessage = '';
        else
            this.clearMessage();
    }

    private _showMessage(msg: string) {
        if (this.inPopup)
            this.lblMessage = this.geti18nTag(msg);
        else
            this.showMessage(this.geti18nTag(msg));
    }

    private _showError(msg: string, msg1: string = '') {
        if (this.inPopup)
            this.lblErrorMessage = this.geti18nTag(msg) + ' ' + msg1;
        else
            this.showError(this.geti18nTag(msg) + ' ' + msg1);
    }

    private onVerify_Proceed(): boolean {
        const _me = this;
        this.lblErrorMessage = '';
        if (Utils.isNullOrEmpty(this.uid) || Utils.isNullOrEmpty(this.pwd)) return false;
        this.busy = true;

        if (!this.inPopup) { this.gc.setBusy(true); }

        this.gc.clearAppSession();

        let responseData: any;
        let endpoint = '/user/signin';
        let requestData;

        if (_me.forcePasswordChanging === true) {
            requestData = { pid: this.pid, tempPassword: this.pwd, newPassword: this.newPWD };
            endpoint = '/user/updatePassword';
        } else
            requestData = { email: this.uid, pwd: this.pwd, profileGroup: this.getProfileGroup(), url: document.URL };

        this.dataservice.APIPreLoginPost(endpoint, requestData).subscribe(
            (_data) => { responseData = _data; },
            (error) => {
                this.onApiError(error);
                // HTTP Error
                if (_me.forcePasswordChanging === false) {
                        this._showError('Security.Login.Failed', (responseData && responseData.error) ? responseData.error : '');
                } else
                    _me._showError('Security.Login.FailedChangePassword');

                _me.busy = false;
                if (_me.inPopup === false) {
                    _me.gc.setBusy(false);
                }
            },
            () => {
                _me.busy = false;
                if (_me.inPopup === false) {
                    _me.gc.setBusy(false);
                }

                if (this._captchaFailed) {
                    this.cancel();
                    return;
                }

                Utils.offsetMinutesFromServer = 0;

                if (responseData != null) {
                    if (responseData != null && !Utils.isNullOrEmpty(responseData.error) || responseData.data === null) {
                        _me._showError('Security.Login.Failed', (responseData && responseData.error) ? responseData.error : '');
                        _me.uid = this.pwd = '';
                    } else if (responseData != null && responseData.data != null) {
                        console.clear(); this.clearMessages();
                        _me.pid = responseData?.data?.details?.PID ?? 0; // Used if Required to Force Change Password
                        if ((responseData?.data?.details?.ForcePasswordChange ?? false) === true) {
                            // Force User to Change Password
                            if (responseData != null && responseData.status != null && responseData.status === true && responseData.data != null) {
                                // Success
                                _me._showMessage('Secutity.Login.PasswordChanged');
                                _me.setuser((new Session()).castToMe(responseData.data));
                                if (this.user.forcePasswordChange === false) {
                                    _me.close();
                                    _me.g.loginChanged.emit(responseData.data.sessionID);
                                } else
                                    _me.forcePasswordChanging = responseData?.data?.details?.ForcePasswordChange ?? false;
                            } else {
                                // Failure
                                _me._showError('Security.Login.FailedChangePassword');
                                _me.newPWD = this.reEnterPWD = '';
                            }
                        } else {
                            if (responseData.status === true) {
 
                                _me.setuser((new Session()).castToMe(responseData.data));

                                if (responseData?.data?.details) {
                                    _me.user.details = (new User()).castToMe(responseData.data.details);
                                    Utils.offsetMinutesFromServer = _me.user.offsetMinutesFromServer;
                                    if (responseData.data.details.PrimaryAddress) {
                                        _me.user.details.PrimaryAddress = (new Address()).castToMe(responseData.data.details.PrimaryAddress);
                                    }
                                }

                                if ((_me?.forcePasswordChanging ?? false) === false) {
                                    // Logedin.
                                    _me.close();
                                    const _ia: boolean = this.g.hasRole(rolePosition.sysadmin);
                                    _me.g.loginChanged.emit({ id: responseData.data.sessionID, showMessage: true, ia: _ia });
                                } else {
                                    // Force Password Changed - Login again.
                                    _me.user = new Session(); // null;
                                }
                                return;
                            }
                            _me._showError('Security.Login.Failed');
                            _me.pwd = '';
                        }
                    }
                }
            }
        );
    }
}
