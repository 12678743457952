import { RecordTimeStamp } from '../base.model';
import { Payment } from '../appCommon.models';
import { ProductType } from '../enums.component';
import { Role, GeographicEntityLevel, ServiceSelectionType } from '../enums.component';

export class ProductEntity extends RecordTimeStamp {

    id: number = 0;

    description: string = '';
    helpText: string = '';
    todaysPrice: number = 0;
    commission: number = 0;
    options: OptionEntity[] = [];
    flagsHTML: string = '';
    expiryDateOffset: number = 0;
    confirmationEmailTypeID: number = 0;
    productTypeID: number = 0; // for Service Type
    productType: ProductType = ProductType.NotSet;

    // client side only
    rates: ProductRateEntity[] = [];
    //

    constructor() {
        super();
        this.id = this.todaysPrice = this.commission = 0;
        this.description = this.helpText = '';
        this.options = new Array<OptionEntity>();
    }
}

export class OptionEntity extends RecordTimeStamp {
    productOptionID: number = 0;
    description: string = '' ;
    helpText: string = '';
    todaysPrice: number = 0;

    constructor() {
        super();
        this.productOptionID = this.todaysPrice = 0;
        this.description = this.helpText = '';
    }
}

export class ProductRateEntity extends RecordTimeStamp {
    id: number = 0;
    effectiveStartDate: Date = null;
    effectiveEndDate: Date = null;
    price: number = 0;
    commisionPercentage: number = 0;
    commissionAmount: number = 0;
    commissionBaseMinimum: number = 0;
    commissionBaseMaximum: number = 0;
    constructor() {
        super();
    }
}

export class ServiceType extends RecordTimeStamp {
    id: number = 0;
    code: string = '';
    description: string = '';
    ciritical: boolean = false;
    certificationRequired: boolean = false;
    certificationAuthorityType: GeographicEntityLevel = GeographicEntityLevel.Global;
    licenseRequired: boolean = false;
    licenseAuthorityType: GeographicEntityLevel = GeographicEntityLevel.Global;
    bondRequired: boolean = false;
    insuranceRequired: boolean = false;

    repairsNotedUponSubmit: boolean = false;
    repairServiceTypeIDs: number[] = [];

    canViewPurchaseAgreement: boolean = false;
    notifyEscrowOnCompletion: boolean = false;
    notifyLenderOnCompletion: boolean = false;
    deliveredOnClosing: boolean = false;

    canOrderNewOnPrivateRequests: boolean = false;
    canAddContigencies: boolean = false;
    canDemandFromEscrow: boolean = false;
    availableDemandIDs: number[] = [];

    reportSubmittedOnCompletion: boolean = false;
    pricingBasedOnCreditScore: boolean = false;
    approvalPriorToSubmit: boolean = false;
    canExemptSellerBeExempt: boolean = false;

    role: Role = Role.NotSet;
    orderableByRoles: Role[] = [];
    additionalOrderableServiceIDs: number[] = [];

    privateToRequestor: boolean = false;
    manageDetailVersions: boolean = false;
    onStatusToStatus: { fromStatus: number, toStatus: number }[] = [];

    serviceTypeSelection: ServiceSelectionType = ServiceSelectionType.ServiceWithNoOptions;
    options: ServiceTypeOption[] = [];
}

export class ServiceTypeOption {
    public id: number = 0;
    public serviceTypeID: number = 0;
    public name: string = '';
    public description: string = '';
    public attribute1: string = '';
    public attribute2: string = '';
    public attribute3: string = '';
    public attribute4: string = '';
    public attribute5: string = '';
}

export class OrderOption {
    optionID: number;
    price: number;
}

export class Order {

    id: number;
    productID: number;
    stateID: number;
    termsVersionID: number;
    serviceProviderID: number; // Only IAMOREA Admin can use this value, Server will ignore otherwise.
    cost: number;
    payment: Payment;
    options: OrderOption[];

    constructor() {
        this.productID = this.stateID = this.termsVersionID = this.id = this.cost = 0;
        this.payment = new Payment();
        this.options = new Array<OrderOption>();
    }

    get amount(): number { return this.cost + this.optionsCost; }
    get termsAgreed() { return this.termsVersionID !== 0; }
    get productSelected(): boolean { return this.productID > 0 && this.stateID > 0; }
    get paymentComplete() {
        return this.payment && this.payment.IsComplete;
    }
    get optionsCost() {
        if (this.options && this.options.length > 0) {
            let _cost: number = 0;
            for (const o of this.options)
                _cost += o.price;
            return _cost;
        } else
            return 0;
    }
}
