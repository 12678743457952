import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../util/APICaller.component';
import { GlobalComponent } from 'src/app/global.component';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { CodeService } from '../../common/service/code.service';
import { WorkOrderService } from '../../workorders/workorder.service';

import { ServiceProviderSearchComponent } from '../../sp/searchServiceProvider.component';
import { Role } from '../../models/enums.component';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';


@Component({
    selector: 'app-add-additional-service-dialog',
    templateUrl: './addAdditionalService.dialog.html'
})
export class AddAdditionalServiceDialog extends BaseDialog implements AfterViewInit {

    @ViewChild('searchSP') searchSP: ServiceProviderSearchComponent;
    workOrderID: number = 0; serviceID: number = 0;

    allowToPickSP: boolean = false;
    serviceTypes: any = [];
    forServiceTypeID: number = 0;
    selectedServiceTypeID: number = 0;
    roles: any = []; paidBy: Role = Role.NotSet;
    geographicArea: any;
    dialogHeader: string = 'Add Additional Service';

    constructor(dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent
        , public dialogRef: MatDialogRef<AddAdditionalServiceDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('dlgAddAdditionalService', dataservice, r, dialogRef, data);

        if (this.data) {
            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Add Additional Service for ' + this.data.header;
            }

            if (data.priceBasis && this.searchSP) this.searchSP.priceBasis = data.priceBasis;
            if (data.serviceTypeID) this.forServiceTypeID = data.serviceTypeID;
            if (data.allowToPickSP) this.allowToPickSP = data.allowToPickSP;
            if (data.propertyTypeID && this.searchSP) this.searchSP.propertyTypeID = data.propertyTypeID;
            if (data.referenceID) {
                if (this.searchSP) this.searchSP.referenceID = data.referenceID;
                this.workOrderID = data.referenceID;
            }
            if (data.myRole && this.searchSP) this.searchSP.myRole = data.myRole;
            if (data.geographicArea) this.geographicArea = data.geographicArea;
            if (data.geographicArea && this.searchSP) this.searchSP.geographicArea = data.geographicArea;

            let _prResponse;
            (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).getAdditionalOrderableServices(this.workOrderID).subscribe((_data) => { _prResponse = _data; }
                , (error) => { this.onApiError(error); }, () => {
                    if (_prResponse && _prResponse.data) {
                        this.serviceTypes = _prResponse.data;
                        if (this.serviceTypes.length > 0) {
                            this.selectedServiceTypeID = this.forServiceTypeID === 0 ? this.serviceTypes[0].id : this.forServiceTypeID;
                            if (this.searchSP) this.searchSP.selectedServiceTypeID = this.selectedServiceTypeID;
                        }
                    }
                });

            let _roleResponse;
            (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getRoles().subscribe((_data) => { _roleResponse = _data; }, (error) => { this.onApiError(error); }, () => {
                if (_roleResponse && _roleResponse.data) {
                    this.roles = _roleResponse.data;
                    if (this.roles.length > 0) {
                        this.paidBy = this.roles[0].id;
                        if (this.searchSP) this.searchSP.myRole = this.paidBy;
                    }
                }
            });
        }
    }

    ngAfterViewInit() {
        if (this.searchSP) this.searchSP.selectPrivateOnly = false;
    }

    close(data) { this.dialogRef.close(data); }
    onServiceTypeSelected() {
        if (this.searchSP) this.searchSP.selectedServiceTypeID = this.selectedServiceTypeID;
    }
    onRoleChanged() {
        if (this.searchSP) this.searchSP.myRole = this.paidBy;
    }

    onServiceProviderSelected(selectedData) {
        let _selection: any = selectedData;
        if (selectedData == null) {
            _selection = {
                serviceProviderID: 0
                , serviceTypeID: this.selectedServiceTypeID
                , options: []
                , currentPrice: null
            };
        }
        _selection['paidBy'] = this.paidBy;
        _selection['id'] = this.workOrderID;

        let _serviceResponse;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).addAdditionalService(_selection).subscribe((data) => { _serviceResponse = data; }, (error) => { this.onApiError(error); }, () => {
            if (_serviceResponse) {
                if (!Utils.isNullOrEmpty(_serviceResponse.error))
                    this.showError(_serviceResponse.error);
                else {
                    this.close(null);
                    const _service = this.serviceTypes.find(st => { return st.id === this.selectedServiceTypeID; });
                    if (_service)
                        this.showMessage(_service.name + ' has been successfully added' + (this.geographicArea ? ' for ' + this.geographicArea.StreetName : '') + '.');
                }
            }
        });

    }

}
