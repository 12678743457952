import { Router, Route } from '@angular/router';
import { BaseService } from '../common/service/base.service';

import { DataService } from '../util/APICaller.component';
import { OfferStatus, OfferServiceModel, LegalTermType, Role, downloadableContent } from '../models';
import { LegalService } from '../common/service';
import { AppURLs } from '../models/constants';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OfferService extends BaseService {

    private static offerInterceptor: any;

    private ds: DataService;
    private r: Router;
    private sModule: string;
    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
        this.r = r;
        this.sModule = sModule;
    }

    getMyOffers(iListingID: number = 0, forLoans: boolean = false, first: number = -1, skip: number = -1, onlyOpenOffers: boolean = true) {
        const data = { id: iListingID, forLoans, first , skip, onlyOpenOffers};
        return this.ds.APIPost('/offer/myOffers', data, this.requestingModule, skip > 0 ? 300 : 120);
    }
    getOffersForListing(iListingID: number = 0) {
        const data = { id: iListingID };
        return this.ds.APIPost('/offer/offersForListing', data, this.requestingModule);
    }
    getMyOfferListings(forLoans: boolean = false) {
        const data = { forLoans };
        return this.ds.APIPost('/offer/myOfferListings', data, this.requestingModule);
    }
    clearEdit() {
        return this.ds.APIPost('/offer/clearEdit', {}, this.requestingModule);
    }
    getOffer(id: number, bFullLoad: boolean = false) {
        if (id && id > 0) {
            const data = { id, fullLoad: bFullLoad };
            return this.ds.APIPost(AppURLs.offer, data, this.requestingModule);
        } else
            return null;
    }
    createOfferForNewListing(oOffer: any) {
        const data = oOffer;
        return this.ds.APIPost('/offer/createOfferForNewListing', data, this.requestingModule);
    }
    saveOffer(oOffer: any) {
        const data = oOffer;
        return this.ds.APIPost('/offer/saveOffer', data, this.requestingModule);
    }
    submitOffer(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/submitOffer', data, this.requestingModule);
    }

    onCancelPurchase(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/onCancelPurchase', data, this.requestingModule);
    }

    cancelPurchase(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/cancelPurchase', data, this.requestingModule);
    }

    getServices(id: number) {
        if (id && id > 0) {
            const data = { id, includeServices: true };
            return this.ds.APIPost(AppURLs.offer, data, this.requestingModule);
        } else
            return null;
    }
    getDisclosures(id: number) {
        if (id && id > 0) {
            const data = { id, includeDisclosures: true };
            return this.ds.APIPost(AppURLs.offer, data, this.requestingModule);
        } else
            return null;
    }
    getContingencies(id: number) {
        if (id && id > 0) {
            const data = { id, includeContingencies: true };
            return this.ds.APIPost(AppURLs.offer, data, this.requestingModule);
        } else
            return null;
    }
    getComplianceItems(id: number) {
        if (id && id > 0) {
            const data = { id, includeComplianceItems: true };
            return this.ds.APIPost(AppURLs.offer, data, this.requestingModule);
        } else
            return null;
    }

    getLegalTerm(productID: number, myRole: Role, isoCountryCode: number) {
        const _type: LegalTermType = LegalTermType.OfferSubmission;
        return (new LegalService(this.ds, this.r, this.sModule)).getLegalTerm(_type, productID, myRole, isoCountryCode, 0, 0);
    }

    resendOfferEmail(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/resendOfferEmail', data, this.requestingModule);
    }
    sendNoticeToSubmitOffer(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/sendNoticeToSubmitOffer', data, this.requestingModule);
    }
    getCARDataFields() {
        return this.ds.APIPost('/offer/CARDataFields', null, this.requestingModule);
    }

    getClosingStatus(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/closingStatus', data, this.requestingModule);
    }
    getTaxesAndFees(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/taxesAndFees', data, this.requestingModule);
    }

    getOfferMessage(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/offerMessage', data, this.requestingModule);
    }

    getPurchaseAgreement(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/purchaseAgreement', data, this.requestingModule);
    }
    getComplianceItemNegotiations(id: number) {
        const data = { id, includeComplianceItems: true };
        return this.ds.APIPost(AppURLs.offer, data, this.requestingModule);
    }

    completeComplianceItem(id: number, complianceItemID: number) {
        const data = { id, complianceItemID };
        return this.ds.APIPost('/offer/completeComplianceItem', data, this.requestingModule);
    }
    verifyComplianceItem(id: number, complianceItemID: number) {
        const data = { id, complianceItemID };
        return this.ds.APIPost('/offer/verifyComplianceItem', data, this.requestingModule);
    }
    saveComplianceItems(id: number, items: any) {
        const data = { id, items };
        return this.ds.APIPost('/offer/saveComplianceItems', data, this.requestingModule);
    }

    getBuyerAgentDisclosure(id: number, disclosureID: number) {
        const data = { id, dislosureID: disclosureID };
        return this.ds.APIPost('/offer/getBuyerAgentDisclosure', data, this.requestingModule);
    }
    acceptBuyerAgentDisclosure(id: number, disclosureID: number) {
        const data = { id, dislosureID: disclosureID };
        return this.ds.APIPost('/offer/acceptBuyerAgentDisclosure', data, this.requestingModule);
    }
    changeStatus(iOfferID: number, enNewStatus: OfferStatus) {
        const data = { id: iOfferID, newStatus: enNewStatus };
        return this.ds.APIPost('/offer/changeStatus', data, this.requestingModule);
    }

    getOfferableServices(iOfferID: number) {
        const data = { id: iOfferID };
        return this.ds.APIPost('/offer/offerableServices', data, this.requestingModule);
    }
    addService(oServiceDetails: OfferServiceModel) {
        const data = oServiceDetails;
        return this.ds.APIPost('/offer/addService', data, this.requestingModule);
    }
    removeService(iOfferID: number, iServiceTypeID: number) {
        const data = { id: iOfferID, typeID: iServiceTypeID };
        return this.ds.APIPost('/offer/removeService', data, this.requestingModule);
    }
    changeServiceOption(iOfferID: number, iServiceTypeID: number, optionIDs: number[]) {
        const data = { id: iOfferID, typeID: iServiceTypeID, optionIDs};
        return this.ds.APIPost('/offer/changeServiceOption', data, this.requestingModule);
    }
    removeAgent(iOfferID: number) {
        const data = { id: iOfferID };
        return this.ds.APIPost('/offer/removeAgent', data, this.requestingModule);
    }
    agentAcceptWorkOrder(iOfferID: number) {
        const data = { id: iOfferID };
        return this.ds.APIPost('/offer/agentAcceptWorkOrder', data, this.requestingModule);
    }
    changeServiceStatus(iOfferID: number, iServiceID: number, enNewStatus) {
        const data = { id: iServiceID, entityID: iOfferID, newStatus: enNewStatus };
        return this.ds.APIPost('/offer/service/changeStatus', data, this.requestingModule);
    }
    changeServiceRFQStatus(iOfferID: number, iRFQID: number, enNewStatus) {
        const data = { id: iRFQID, entityID: iOfferID, newStatus: enNewStatus };
        return this.ds.APIPost('/offer/service/rfq/changeStatus', data, this.requestingModule);
    }

    //#region Roles
    getRoles(iOfferID: number) {
        const data = { id: iOfferID, includeRoles: true };
        return this.ds.APIPost(AppURLs.offer, data, this.requestingModule);
    }
    addPartner(iOfferID: number, oPartner: any) {
        oPartner['id'] = iOfferID;
        oPartner['role'] = Role.Buyer;
        const data = oPartner;
        return this.ds.APIPost('/offer/addPartner', data, this.requestingModule);
    }
    updatePartners(iOfferID, oPartners) {
        const _partners: any[] = [];
        if (oPartners && oPartners.length > 0) {
            oPartners.forEach((p) => {
                _partners.push({ pid: p.pid, firstName: p.firstname, middleName: p.middleName, lastname: p.lastName });
            });
        }
        const data = { id: iOfferID, partners: _partners };
        return this.ds.APIPost('/offer/updatePartners', data, this.requestingModule);
    }

    acceptPartnership(iOfferID: number, roleID: number, ownership: number) {
        const data = { id: iOfferID, roleID, ownership};
        return this.ds.APIPost('/offer/acceptPartnership', data, this.requestingModule);
    }
    removePerson(iOfferID: number, roleID: number) {
        const data = { id: iOfferID, roleID };
        return this.ds.APIPost('/offer/removePerson', data, this.requestingModule);
    }
    representCompany(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/representCompany', data, this.requestingModule);
    }
    agentTerms(iOfferID: number) {
        const data = { id: iOfferID };
        return this.ds.APIPost('/offer/agentTerms', data, this.requestingModule);
    }
    acceptAgentTerms(iOfferID: number) {
        const data = { id: iOfferID };
        return this.ds.APIPost('/offer/acceptAgentTerms', data, this.requestingModule);
    }
    assumePowerOfAttorney(iOfferID: number, roleID: number, pidWithPOA: number, document: downloadableContent) {
        const data = { id: iOfferID, roleID, pidWithPOA, document };
        return this.ds.APIPost('/offer/assumePOA', data, this.requestingModule);
    }
    givePowerOfAttorney(iOfferID: number, roleID: number, pidWithPOA: number, document: downloadableContent) {
        const data = { id: iOfferID, roleID, pidWithPOA, document };
        return this.ds.APIPost('/offer/givePOA', data, this.requestingModule);
    }
    verifyPowerOfAttorney(iOfferID: number, roleID: number) {
        const data = { id: iOfferID, roleID };
        return this.ds.APIPost('/offer/verifyPOA', data, this.requestingModule);
    }
    getPowerOfAttorney(iOfferID: number, roleID: number) {
        const data = { id: iOfferID, roleID };
        return this.ds.APIPost('/offer/getPOA', data, this.requestingModule);
    }
    revokePowerOfAttorney(iOfferID: number, roleID: number) {
        const data = { id: iOfferID, roleID };
        return this.ds.APIPost('/offer/revokePOA', data, this.requestingModule);
    }
    //#endregion
    //#region contingencies
    availableContingencies(iPropertyTypeID) {
        const data = { id: iPropertyTypeID };
        return this.ds.APIPost('/offer/availableContingencies', data, this.requestingModule);
    }
    addContingency(iOfferID: number, iContingencyID: number, dAmount: number) {
        const data = { id: iOfferID, contingencyConfigurationID: iContingencyID, amount: dAmount };
        return this.ds.APIPost('/offer/addContingency', data, this.requestingModule);
    }
    updateContingency(iOfferID: number, iContingencyID: number, dAmount: number) {
        const data = { id: iOfferID, contingencyConfigurationID: iContingencyID, amount: dAmount };
        return this.ds.APIPost('/offer/updateContingency', data, this.requestingModule);
    }
    removeContingency(iOfferID: number, iContingencyID: number) {
        const data = { id: iOfferID, contingencyID: iContingencyID };
        return this.ds.APIPost('/offer/removeContingency', data, this.requestingModule);
    }
    //#endregion
    //#region Proof Of Funds
    uploadProofOfFunds(iLienD: number) {
        const data = { id: iLienD };
        return this.ds.APIPost('/offer/uploadProofOfFunds', data, this.requestingModule);
    }
    getProofOfFunds(id: number) {
        const data = { id };
        return this.ds.APIPost('/offer/proofOfFunds', data, this.requestingModule);
    }
    addProofOfFunds(id: number, document: downloadableContent) {
        const data = { id, document };
        return this.ds.APIPost('/offer/addProofOfFunds', data, this.requestingModule);
    }
    uploadPOF(id: number, content: any) {
        return this.ds.upload('offer', id, content, this.requestingModule);
    }
    //#endregion
}
