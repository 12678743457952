import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appComponentSetHeight]',
})
export class SetHeightDirective implements OnInit {

    @Input() minHeight: string;
    @Input() maxHeight: string;
    @Input() overFlowHidden: boolean;

    constructor(private el: ElementRef) { }
    ngOnInit() {
        const element = this.el.nativeElement;
        let _height = window.innerHeight - 400;
        let _minHeight: number;
        let _maxHeight: number;

        if (this.minHeight != null && !isNaN(parseInt(this.minHeight)))
            _minHeight = parseInt(this.minHeight);

        if (this.maxHeight != null && !isNaN(parseInt(this.maxHeight)))
            _maxHeight = parseInt(this.maxHeight);

        if (_minHeight != null && _minHeight > 0)
            if (_height < _minHeight)
                _height = _minHeight;

        if (_maxHeight != null && _maxHeight > 0) {

            if (_minHeight != null && _minHeight > 0)
                if (_minHeight > _maxHeight)
                    _maxHeight = _minHeight;

            if (_height > _maxHeight)
                _height = _maxHeight;
        }
        element.style.height = (_height) + 'px';

        if (this.overFlowHidden == null || this.overFlowHidden === false)
            element.style.overflowY = 'auto';

        element.style.overflowX = 'hidden';
    }

}
