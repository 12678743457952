import { Router, Route } from '@angular/router';
import { BaseService } from './base.service';

import { DataService } from '../../util/APICaller.component';
import { Order } from '../../models/services/product.model';
import { LegalService } from './legal.service';
import { LegalTermType } from '../../../app/models';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ProductService extends BaseService {
    constructor(ds: DataService, r: Router, @Inject(String) sModule: string) {
        super(r, sModule, ds);
    }

    getProducts(bIncludeInactive: boolean = false) {
        const data = { includeInactive: bIncludeInactive };
        return this._dataService.APIPost('/product/products', data, this.requestingModule);
    }

    getServiceProducts() {
        return this.getCacheData('/product/serviceProducts');
    }

    getListingProducts() {
        return this.getCacheData('/product/listingProducts');
    }

    getProductDetails(iProductID: number, sellingPrice: number = 0) {
        const data = { id: iProductID, value : sellingPrice};
        return this._dataService.APIPost('/product/details', data, this.requestingModule);
    }

    getProductRates(iProductID: number, iForLastXYears: number = 5) {
        const data = { id: iProductID, forLastXYears: iForLastXYears };
        return this._dataService.APIPost('/product/rates', data, this.requestingModule);
    }

    getServiceTypeDetails(iServiceTypeID: number = 0) {
        const data = { id: iServiceTypeID };
        return this._dataService.APIPost('/product/service/details', data, this.requestingModule);
    }

    getLegalTerm(iProductID: number, iRoleID: number, iISOCountryCode: number, iStateiD?: number, iCountyID?: number, iCityID?: number) {
        return (new LegalService(this._dataService, this.router)).getLegalTerm(LegalTermType.Product, iProductID, iRoleID, iISOCountryCode, iStateiD, iCountyID, iCityID);
    }

    submitServiceOrder(oOrder: Order) {
        const data = oOrder;
        return this._dataService.APIPost('/product/servicePurchase', data, this.requestingModule);
    }

    submitApprovedServiceOrder(oOrder: Order) {
        const data = oOrder;
        return this._dataService.APIPost('/product/submitApprovedServiceOrder', data, this.requestingModule);
    }

    ccGateway() {
        return this.getCacheData('/user/ccGatewayAccount');
    }
}
