import { Component, ViewChild, OnInit, AfterViewInit, Inject, Input, Output, OnDestroy, AfterViewChecked } from '@angular/core';

import { DataService } from '../util/APICaller.component';

import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ListingService } from './listing.service';

import { ListingBaseComponent } from './listing.base.component';
import {
    ListingRoleComponent, ListingTaxesAndFeesComponent
} from './';

import { ListingDisclosureComponent } from '../common/listing/listingDisclosures.component';
import { ListingGeneralComponent, ListingCharacteristicsComponent, ListingMediaComponent, ListingPersonalPropertyComponent, ListingLiensComponent, ListingAssessmentComponent } from './edit';

import { HTMLDialog } from '../common/dialogs/showHTML.dialog';
import { EscrowService } from '../escrow/escrow.service';
import { BaseResponse, Role } from '../models';
import { GlobalComponent } from '../global.component';
import * as moment from 'moment';
import { handover } from '../models/constants';
import { NextActionComponent } from '../common';
import { Subscription } from 'rxjs/internal/Subscription';
import { ResizeService } from '../common/service';
import { Utils } from '../common/utils';
@Component({
    selector: 'app-listing-edit',
    templateUrl: './listingEdit.component.html'
})
export class ListingEditComponent extends ListingBaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    role: Role = Role.Seller;

    Role: typeof Role = Role;
    iStepperIndex: number = 0;
    sellerAgentPID: number = 0;
    sellerAgentName: string = '';
    sellerAgentAED: Date = this.Utils.dateTimeMinValue;
    listingAgreementExpired: boolean = false;

    @ViewChild('statusActions') statusActions: NextActionComponent;
    @ViewChild('general') general: ListingGeneralComponent;
    @ViewChild('characteristitcs') characteristitcs: ListingCharacteristicsComponent;
    @ViewChild('assessments') assessments: ListingAssessmentComponent;
    @ViewChild('disclosures') disclosures: ListingDisclosureComponent;
    @ViewChild('media') media: ListingMediaComponent;
    @ViewChild('sellers') sellers: ListingRoleComponent;
    @ViewChild('liens') liens: ListingLiensComponent;
    @ViewChild('personalProperty') personalProperty: ListingPersonalPropertyComponent;

    private resizeSubscription: Subscription;
    private _searchingListingID: number = 0;

    constructor(dataservice: DataService, r: Router, private dialog: MatDialog, @Inject(GlobalComponent) gc: GlobalComponent
        , private currentRoute: ActivatedRoute, private resizeService: ResizeService) {
        super('pgListingEdit', dataservice, r, gc);

        this.setCurrentListing(null);
    }

    ngOnDestroy() {
        this.exitingEdit();

        // CLear Server Cache of Edit  Listing in Progress.
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).clearEdit().subscribe(() => { });

        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
        this.destoryLoginSubscription();
    }

    ngOnInit() {

        if (this.sellers) this.sellers.forRole = Role.Seller;

        const _id = parseInt(sessionStorage.getItem(handover.listingID));
        if (!isNaN(_id) && _id > 0) {
            this.setListing(_id);
            this.bindListings(false, _id);
        } else
            this.bindListings(true);

        this.resizeSubscription = this.resizeService.onResize$
            .subscribe((size) => {
                this.resize();
            });
    }

    ngAfterViewInit() {
        if (this.currentRoute.snapshot.queryParams && this.currentRoute.snapshot.queryParams.i)
            this.iStepperIndex = parseInt(this.currentRoute.snapshot.queryParams.i.toString());
        this.resize();
    }

    setListing(id: number) {

        super.setCurrentListing(null);

        if (id != null && id > 0 && (this.currentListing == null || this.currentListing.id !== id)) {

            if (this._searchingListingID > 0 && this._searchingListingID === id) return;

            let _response;
            this.gc.setBusy(true);
            this._searchingListingID = id;
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getListing(id, true, true).subscribe(
                (data) => { _response = data; }
                , (error) => { this._searchingListingID = 0; this.onApiError(error); }
                , () => {
                    this.gc.setBusy(false);
                    this._searchingListingID = 0;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            super.setCurrentListing(_response.data, true);
                            this.initComponent();
                        }
                }
            );
        } else
            this.initComponent();
    }

    publish() {
        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).publish(this.listingID).subscribe(
            (data) => _response = data
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else
                        this.showMessage('Success !!!');
            }
        );
    }

    sendNotice() {
        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).sendListingAgentNotice(this.listingID).subscribe(
            (data) => _response = data
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else
                        this.showError(this.geti18nTag('Listing:NoticeSent'));
            }
        );
    }

    viewAgentDisclosure(id: number, accepted: boolean) {
        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getSellerAgentDisclosure(this.listingID, id).subscribe(
            (data) => _response = data
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        const dialogRef = this.dialog.open(HTMLDialog, {
                            data: {
                                html: _response.data
                                , showConfirm : !accepted
                            }
                        });
                        dialogRef.afterClosed().subscribe((v) => {
                            if (v != null && v === true) {
                                (new ListingService(this.dataservice, this.route, this.myResourceCategory)).acceptSellerAgentDisclosure(this.listingID, id).subscribe(
                                    (data) => _response = data
                                    , (error) => { this.onApiError(error); }
                                    , () => {
                                        if (_response)
                                            if (!Utils.isNullOrEmpty(_response.error))
                                                this.showError(_response.error);
                                            else
                                                this.showMessage('Disclosure Accepted !!!');
                                    }
                                );
                            }
                        });
                    }
            }
        );
    }

    showPreCloseMessage() {
        let _response;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).showPreCloseMessage(this.currentListing.currentEscrowID);
        if (_es != null) {
            _es.subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                        }
                }
            );
        }
    }

    listingChangeStatus($event) {
        this.changeStatus($event, (data) => {
            if (this.statusActions && data.nextSteps) {
                this.statusActions.actions = data.nextSteps;
            }
            this.showMessage('Status changed successfully.');
        });
    }

    private bindListings(bFetchIfMissing: boolean = true, iListingID: number = 0) {
        if (this.gc.myListings != null && this.gc.myListings.length > 0) {
            this.listings = this.gc.myListings;
            if (this.listings.length > 0 && ((iListingID === 0) || (iListingID > 0 && this.listings.find((e) => e.id === iListingID) == null))) {
                this.setListing(this.listings[0].id);
            }
        }

        if (bFetchIfMissing === true) {
            this.componentBusy = true;
            let _response = null;
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getMyListingsAs(this.role).subscribe((r) => _response = r
                , (error) => { this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.componentBusy = false;
                    if (_response && _response.data) {
                        this.listings = _response.data;
                        if (this.listings.length > 0 && ((this.listingID === 0) || (this.listingID > 0 && this.listings.find((e) => e.id === this.listingID) == null))) {
                            this.setListing(this.listings[0].id);
                        }
                    }
                });
        }
    }

    private initComponent() {
        if (this.currentListing) {
            if (this.currentListing.address) this.currentListing.address.isDirty = false;
            if (this.currentListing.roles) {
                const _agent = this.currentListing.roles.find((r) => r.roleID === Role.SellersAgent);
                if (_agent) {
                    this.sellerAgentPID = _agent.pid;
                    this.sellerAgentName = _agent.firstName + ' ' + _agent.lastName + ' [' + _agent.company + ']';
                    this.listingAgreementExpired = !moment(_agent.agentAgreementExpiryDate).isSame(this.Utils.dateTimeMinValue) && moment(_agent.agentAgreementExpiryDate).isBefore(new Date());
                } else {
                    this.sellerAgentAED = this.Utils.dateTimeMinValue;
                    this.sellerAgentPID = 0;
                    this.listingAgreementExpired = false;
                }
            }
            if (this.statusActions && this.currentListing.nextSteps) {
                this.statusActions.actions = this.currentListing.nextSteps;
            }
        }
    }

    private resize() {
        const _divRouter = document.getElementById('divRouter');
        const _divEditListing = document.getElementById('divEditListing');
        const _divEditTabs = document.getElementById('divEditTabs');
        if (_divRouter) {
            let _maxHeight = parseInt(_divRouter.style.maxHeight.replace('px', ''));
            let _height = parseInt(_divRouter.style.height.replace('px', ''));

            if (this.isNaN(_maxHeight))
                _maxHeight = _divRouter.offsetHeight;

            if (this.isNaN(_height))
                _height = _divRouter.offsetHeight;

            if (_height < _maxHeight)
                _height = _maxHeight;

            if (_divEditListing)
                _divEditListing.setAttribute('style', 'height:' + (_height - 195) + 'px');
            if (_divEditTabs) {
                _divEditTabs.setAttribute('style', 'max-height:' + (_height - 200) + 'px;overflow:auto;');
            }
        }
    }
}
