import { Component, Inject, OnInit, AfterViewInit,AfterViewChecked,  ViewEncapsulation, Input, Output, OnDestroy } from '@angular/core';
import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import { BaseResponse } from './../models/base.model';
import { item } from '../models/appCommon.models';
import { appEnvironment } from '../../environments/environment';
import { ServiceWorkOrderStatus, Role } from '../models/enums.component';
import { WorkOrderService } from '../workorders/workorder.service';
import { WorkOrder, WorkOrderSaveModel } from '../models/workOrder.model';
import { AddAdditionalServiceDialog, AddContingencyDialog, AddDependencyDialog, CreateRepairWorkOrderDialog, BidsDialog } from './dialogs';
import { AddDemandDialog } from '../escrow/dialog/addDemand.dialog';
import { HTMLDialog, MessageDialog } from '../common/dialogs';
import { AddServiceVisitDialog, SearchSPDialog, AddReviewsDialog } from '../sp/dialogs';
import { OfferService } from '../offer/offer.service';
import { ListingService } from '../listing/listing.service';
import { SPSelectionModel, PaymentUIData, Payment3DSInformation } from '../models';
import { excludeFirstItems, excludeNextItems, includeClosedItems, includeFirstItems, includeNextItems } from '../models/workOrder.model';
import { BrainTreeDialog } from '../common/shared/brainTreeDropIn.dialog';
import { ServiceProviderService } from '../sp/serviceprovider.service';
import { DndDropEvent } from 'ngx-drag-drop';
import { CodeService } from '../common/service';
import { selectProperty } from '../common/appCommon.module';
import { StreamService } from '../common/service/base.service';
import { Utils, rolePosition } from '../common/utils';


@Component({
    selector: 'app-work-orders',
    templateUrl: './workorders.component.html'
    , styleUrls: ['../common/sidenav.css']
    , encapsulation: ViewEncapsulation.None
})
export class ServiceProviderWorkOrdersComponent extends BaseComponent implements OnInit {

    @Input() @Output() listingID: number = 0;
    @Input() showListings: boolean = true;
    serviceTypes: item[] = new Array<item>();
    operators: item[] = new Array<item>();
    statuses: item[] = new Array<item>();

    selectedStaffIDs: number[] = [];
    selectedServicesIDs: number[] = [];
    selectedStatuses: number[] = [];
    Role: typeof Role = Role;
    forRole: Role = null;
    woEditing: any = [];

    listings: any = []; listingIDs: number[] = [];
    bindingListings: boolean = true;

    selectedListingID: number = 0;
    availableServiceTypes: any[] = [];
    allServiceTypes: any[] = [];
    allOperators: boolean = false;

    allWorkOrders: WorkOrder[] = [];
    autoSavingWorkOrders: number;
    workOrders: WorkOrder[];

    pageNumber: number = 1;
    itemsOnPage: number = 20;

    spView: boolean = false;
    includeClosed: boolean = false;
    offerForSelectedListing: number = 0;
    escrowForSelectedListing: number = 0;

    converter = require('number-to-words');

    lblHeader: string = '';
    isWorkOrderDirty: boolean = false;

    isLender: boolean = false;

    private _detailsTO: any;
    private _paramID: number = 0;

    constructor(private dataservice: DataService, private r: Router, private dialog: MatDialog, @Inject(GlobalComponent) g: GlobalComponent
        , private currentRoute: ActivatedRoute, private _streamService: StreamService) {
        super('spWorkOrders', r, g);
        this.isLender = this.gc.hasRole(rolePosition.lenderStaff);
    }

    ngOnInit() {

        if (this.currentRoute.snapshot.queryParams && this.currentRoute.snapshot.queryParams.l)
            this._paramID = parseInt(this.currentRoute.snapshot.queryParams.l.toString());

        this._streamService.returnAsObservable().subscribe(data => {
            console.log(data);
        });

        if (this._paramID === 0) {
            const _id = parseInt(sessionStorage.getItem(this.handover.listingID));
            if (!isNaN(_id) && _id > 0)
                this._paramID = _id;
        }

        this.gc.setBusy(true);
        let _response: BaseResponse;
        if (this.route.url.indexOf('/buyer/') >= 0) {
            this.forRole = Role.Buyer;
            this.lblHeader = ' for Properties I am Buying';
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getMyOfferListings(false).subscribe(
                (data) => { this.gc.setBusy(false); _response = data; }
                , (error) => { this.gc.setBusy(false); this.onApiError(error); }
                , () => {
                    this.gc.setBusy(false);
                    this.bindingListings = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else if (_response.data) {
                            if (Array.isArray(_response.data) && _response.data.length > 0) {
                                _response.data.forEach((l) => this.listings.push({ id: l.id, name: l.address }));

                                if (this.listings.length > 1 && this.listings.find((l) => l.id === 0) == null)
                                    this.listings.splice(0, 0, { id: 0, name: selectProperty });
                                else
                                    this._paramID = this.listings[0].id;
                                if (this._paramID > 0 && this.listings.find((l) => l.id === this._paramID) != null)
                                    this.listingChanged(this._paramID);
                            }
                        }
                }
            );

        } else if (this.route.url.indexOf('/seller/') >= 0) {
            this.forRole = Role.Seller;
            this.lblHeader = ' for Properties I am Selling';
            this.getMyListingsForSale(this.dataservice, (_data) => {
                this.gc.setBusy(false);
                this.bindingListings = false;
                if (Array.isArray(_data) && _data.length > 0) {
                    _data.forEach((l) => this.listings.push({ id: l.id, name: l.address }));

                    if (this.listings && this.listings.length > 0) {
                        if (this.listings.length > 1 && this.listings.find((l) => l.id === 0) == null)
                            this.listings.splice(0, 0, { id: 0, name: selectProperty });
                        else
                            this._paramID = this.listings[0].id;
                        if (this._paramID > 0 && this.listings.find((l) => l.id === this._paramID) != null)
                            this.listingChanged(this._paramID);
                    }
                }
            } );
        } else if (this.listingID === 0) {
            this.lblHeader = ' for Properties I am Servicing';
            if (this.forRole == null) {
                this.spView = true;
                this.getSPWorkOrderListings();
            } else {
                this.gc.setBusy(false);
                if (this._paramID > 0)
                    this.listingChanged(this._paramID);
                sessionStorage.setItem(this.handover.listingID, '0');
            }
        }
    }

    listingChanged(id: number = 0) {
        this.allWorkOrders = new Array<WorkOrder>();
        this.flagWorkOrderDirty();

        if (id && id > 0)
            this.listingID = id;

        this.escrowForSelectedListing = this.offerForSelectedListing = 0;

        this.prepareWorkOrders();
        if (this.listingID > 0) {
            this.componentBusy = true;
            let _woResponse;
            (new ListingService(this.dataservice, this.r, this.myResourceCategory)).getWorkOrders(this.listingID, this.forRole).subscribe((r) => _woResponse = r
                , (error) => { this.componentBusy = false; this.onApiError(error); }
                , () => {
                    if (_woResponse && _woResponse.data) {
                        if (_woResponse.data.wos && _woResponse.data.wos.length > 0) {
                            this.allWorkOrders = _woResponse.data.wos.map((wo) => (new WorkOrder()).castToMe(wo));
                            this.flagWorkOrderDirty();
                        }
                        if (_woResponse.data.offerID && _woResponse.data.offerID > 0)
                            this.offerForSelectedListing = _woResponse.data.offerID;
                        if (_woResponse.data.escrowID && _woResponse.data.escrowID > 0)
                            this.escrowForSelectedListing = _woResponse.data.escrowID;
                    }
                    this.prepareWorkOrders();
                    this.initAvailableServices();
                });
        }
    }

    listingSPChanged() {

        // When Listings is Single Select
        this.listingIDs = [];
        if (this.selectedListingID > 0)
            this.listingIDs.push(this.selectedListingID);
        // Comment lines above when listings is multiple select

        if (this.listingIDs && this.listingIDs.length > 0) {
            this.allWorkOrders = [];
            this.flagWorkOrderDirty();
            this.getSPWorkOrders();
            // this.getSPWorkOrdersStream();
        }
    }

    addWorkOrder(event: DndDropEvent) {

        let serviceTypeID = 0;
        if (event && event.data && event.data.id)
            serviceTypeID = event.data.id;

        if (serviceTypeID > 0 && this.listingID > 0 && (this.offerForSelectedListing > 0 || this.escrowForSelectedListing > 0)) {
            this.componentBusy = true;
            let _woResponse;
            (new ListingService(this.dataservice, this.r, this.myResourceCategory))
                    .createWorkOrder(this.listingID, serviceTypeID, this.offerForSelectedListing, this.escrowForSelectedListing, this.forRole).subscribe((r) => _woResponse = r
                , (error) => { this.componentBusy = false; this.onApiError(error); }
                , () => {
                    if (_woResponse) {
                        if (!Utils.isNullOrEmpty(_woResponse.error)) {
                            this.showError(_woResponse.error);
                        } else if (_woResponse.data) {
                            if (this.allWorkOrders == null) {
                                this.allWorkOrders = [];
                                this.flagWorkOrderDirty();
                            }
                            if (this.allWorkOrders.length > 1)
                                this.allWorkOrders.splice(0, 0, (new WorkOrder()).castToMe(_woResponse.data));
                            else
                                this.allWorkOrders.push((new WorkOrder()).castToMe(_woResponse.data));

                            this.prepareWorkOrders();

                            this.initAvailableServices();

                        }
                        this.componentBusy = false;
                    } else
                        this.componentBusy = false;
                });
        }
    }

    statusName(id) {
        const _d = this.statuses.find((s) => s.id === id );
        if (_d)
            return _d.name.split('[')[0];
        else
            return '';
    }

    bindWorkOrders(refresh: boolean = false) {

        let _wo = new Array<WorkOrder>();

        if (this.listingID === 0 && this.allWorkOrders)
            _wo = cloneDeep(this.allWorkOrders.filter((wo) => {
                return ((this.selectedStaffIDs == null || this.selectedStaffIDs.length <= 0) ? true : this.selectedStaffIDs.indexOf(wo.operatorID) >= 0)
                    && ((this.selectedStatuses == null || this.selectedStatuses.length <= 0) ? true : this.selectedStatuses.indexOf(wo.currentStatus) >= 0)
                    && ((this.selectedServicesIDs == null || this.selectedServicesIDs.length <= 0) ? true : this.selectedServicesIDs.indexOf(wo.typeID) >= 0);
            }).map((o) => ({ ...o }))).map((o) => (new WorkOrder()).castToMe(o));
        else if (this.allWorkOrders)
            _wo = cloneDeep(this.allWorkOrders.map((o) => ({ ...o }))).map((o) => (new WorkOrder()).castToMe(o));

        if (this.spView) {
            if (this.listingIDs && this.listingIDs.length > 0)
                this.workOrders = _wo.filter((w) => this.listingIDs.includes(w.listingID));
        } else {
            if (this.listings && this.listings.length > 0 )
                this.workOrders = _wo.filter((w) => this.listings.find((l) => l.id === w.listingID));
        }

        this.componentBusy = false;
    }

    getWorkOrder(index: number, workOrderID: number) {
        this.accordionSetStep(index);
        let _wo = this.allWorkOrders.find((wo) => wo.id === workOrderID);
        if (workOrderID > 0 && (_wo.serviceID == null || _wo.serviceID === 0)) {
            this.componentBusy = true;
            let _response;
            (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).getWorkOrder(workOrderID).subscribe((r) => _response = r
                , (error) => { this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.componentBusy = false;
                    if (_response && _response.data)
                        if (!Utils.isNullOrEmpty(_response.error) && _response.data.id === workOrderID) {
                            _wo = (new WorkOrder()).castToMe(_response.data);
                            this.bindWorkOrders();
                        }
                });
        }
    }

    // User Changed Status
    changeStatus(action$) {

        if (this.allWorkOrders == null) return;

        const _selectedWorkOrder = this.allWorkOrders.find((wo) => wo.id === parseInt(action$.argument));

        if (_selectedWorkOrder == null) {
            this.showError('Invalid Action. Please log out and retry again. Is the situation persists, please contact Tech Support.');
            return;
        }

        if (this.isSavePending()) {
            this.showWarning('Work Order has changed, please save the changes or wait for \"auto save\" to save all changes.');
            return;
        }

        if (action$.name === WorkOrder.actionAddService) {

            const dialogRef = this.dialog.open(AddAdditionalServiceDialog, {
                // height: '200px',
                data: {
                    serviceTypeID: _selectedWorkOrder.typeID
                    , propertyTypeID: _selectedWorkOrder.propertyTypeID
                    , priceBasis: _selectedWorkOrder.priceBasis
                    , allowToPickSP: _selectedWorkOrder.isCurrentUserFromEscrow
                    , geographicArea: _selectedWorkOrder.address
                    , referenceID: _selectedWorkOrder.id
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    // Refresh work orders if Required.
                }
            });

            // Search SP to add additional Service
        } else if (action$.name === WorkOrder.actionAddDemand || action$.name === WorkOrder.actionAddCredit) {

            const dialogRef = this.dialog.open(AddDemandDialog, {
                minWidth: '70vw', maxWidth: '90vw'
                , data: {
                    id: _selectedWorkOrder.id
                    , escrowID: _selectedWorkOrder.escrowID
                    , addCredit: action$.name === WorkOrder.actionAddCredit
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.showMessage('Demand/Credit information sent to Escrow');
                    const _wo = this.allWorkOrders.find((wo) => wo.id === result.id);
                    if (_wo)
                        _wo.amountDemanded += result.amountPaid;
                    this.prepareWorkOrders(true);
                }
            });
        } else if (action$.name === WorkOrder.actionSubmitReview) {
            this.dialog.open(AddReviewsDialog, {
                minWidth: '70vw', maxWidth: '90vw'
                , data: {
                    workOrderID: _selectedWorkOrder.id
                    , canPostReview: _selectedWorkOrder.canSubmitReview
                }
            });
        } else if (action$.name === WorkOrder.actionAddContingency) {
            const dialogRef = this.dialog.open(AddContingencyDialog, {
                minWidth: '70vw', maxWidth: '90vw'
                ,data: {
                    workOrderID: _selectedWorkOrder.id
                    , escrowID: _selectedWorkOrder.escrowID
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.showMessage('Contingency information sent to Escrow');
                }
            });
        } else if (action$.name === WorkOrder.actionAddDependency || action$.name === ServiceWorkOrderStatus.WaitingForDependency) {
            const dialogRef = this.dialog.open(AddDependencyDialog, {
                minWidth: '70vw', maxWidth: '90vw'
                , data: {
                    id: _selectedWorkOrder.id
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result != null && result.dependencies != null) {
                    this.showMessage('Dependency information sent to Escrow');
                    const _wo = this.allWorkOrders.find((wo) => wo.id === result.id);
                    _wo.dependencies = result.dependencies;
                    this.prepareWorkOrders(true);
                }
            });
        } else if (action$.name === WorkOrder.actionServiceVisit) {
            const dialogRef = this.dialog.open(AddServiceVisitDialog, {
                minWidth: '70vw', maxWidth: '90vw'
                , data: {
                    id: _selectedWorkOrder.id
                    , workOrders: [_selectedWorkOrder]
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result != null) {
                    this.showMessage('Service Visit request sent to Seller(s)');
                }
            });
        } else {

            if (this.componentBusy === true) {
                this.showWarning('Please wait for system to complete previous actions.');
                return;
            }

            this.componentBusy = true;
            let _actionResponse: BaseResponse;
            (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).changeStatus(parseInt(action$.argument), action$.name).subscribe((r) => _actionResponse = r
                , (error) => {
                    this.componentBusy = false;
                    if (error && error.name && (error.name as string).toLowerCase().indexOf('timeout') > 0) {
                        this.showMessage('Your request is taking longer than expected. Refresh this page after few minutes, to get upto date information !!!!');
                    } else
                        this.onApiError(error);
                }
                , () => {
                    this.componentBusy = false;
                    if (_actionResponse) {
                        if (!Utils.isNullOrEmpty(_actionResponse.error))
                            this.showError(_actionResponse.error);
                        else {
                            if (Utils.isNullOrEmpty(_actionResponse.message))
                                _actionResponse.message = 'Action executed successfully.';

                            this.showMessage(_actionResponse.message);

                            if (_actionResponse.data) {
                                if (!Utils.isNullOrEmpty(_actionResponse.data.redirectURL)) {
                                    this.gc.currentWorkOrder = _selectedWorkOrder;
                                    this.gotoURL(_actionResponse.data.redirectURL);
                                } else {
                                    _actionResponse.data['canChangeServiceProvider'] = false; // Disable Change SP until next Page Load.
                                    const _wo = this.allWorkOrders.find((wo) => wo.id === parseInt(action$.argument));
                                    if (_wo != null) {
                                        _wo.UpdateAfterChangeStatus(_actionResponse.data);
                                        if (_actionResponse.data.canUpdate)
                                            _wo.canUpdate = _actionResponse.data.canUpdate;
                                        this.updateWOList(cloneDeep(_wo));
                                    }
                                }
                            }
                        }
                    }
                }
            );
        }
    }

    removeDependency(iWorkOrderID: number, iDependencyID: number) {
        let _response: any;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).removeDependency(iWorkOrderID, iDependencyID).subscribe(
            (data) => _response = data
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response) {
                    if (Utils.isNullOrEmpty(_response.error)) {
                        this.allWorkOrders = cloneDeep(this.allWorkOrders.filter((wo) => wo.id !== iWorkOrderID)); // Update Work Order on Client
                        if (_response.data)
                            this.allWorkOrders.splice(0, 0, (new WorkOrder()).castToMe(_response.data));

                        this.flagWorkOrderDirty();

                        this.bindWorkOrders(true);
                    } else
                        this.showError(_response.error);
                }
            }
        );
    }

    sendMessageToEscrow(workOrderID) {
        if (workOrderID === 0) return;
        const _wo = this.workOrders.find((wo) => wo.id === workOrderID);
        if (_wo) {
            // Show Popup to Send Message to Escrow
            const dialogRef = this.dialog.open(MessageDialog, {
                // height: '200px',
                data: {
                    recepients: _wo.escrowOfficerID
                    , subject: _wo.emailSubject
                    , heading: 'Send Message to Escrow'
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.showMessage('Your message has been sent to Escrow.');
                }
            });
        }
    }

    sendMessageToRequestor(workOrderID) {
        if (workOrderID === 0) return;
        const _wo = this.workOrders.find((wo) => wo.id === workOrderID);
        if (_wo) {
            // Show Popup to Send Message to Escrow
            const dialogRef = this.dialog.open(MessageDialog, {
                // height: '200px',
                data: {
                    recepients: _wo.toBePaidByPIDs
                    , subject: _wo.emailSubject
                    , heading: 'Send Message to Work Order Requestor(s)'
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.showMessage('Your message has been sent to Work Order Requestor(s).');
                }
            });
        }
    }

    showPurchaseAgreement(offerID) {
        if (offerID > 0) {
            let _response;
            (new OfferService(this.dataservice, this.r, this.myResourceCategory)).getPurchaseAgreement(offerID).subscribe((r) => _response = r
                , (error) => { }
                , () => {
                    if (_response && _response.data) {
                        this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                    }
                });
        } else {
            this.showWarning('This Work Order is not linked to any Offer or Purchase Agreement');
        }
    }

    viewTerms(iWorkOrderID: number, iTermID: number) {
        if (iWorkOrderID === 0 || iTermID === 0) return;
        let _response;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).getTermsDetail(iWorkOrderID, iTermID).subscribe((r) => _response = r
            , (error) => { }
            , () => {
                if (_response && _response.data) {
                    this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                }
            });
    }

    acceptTerms(workOrderID) {
        if (workOrderID === 0) return;
        let _response;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).acceptWorkOrderTerms(workOrderID).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else
                        this.showError('Your Acceptance has been recorded and notifications have been sent to all relevant parties.');
            });
    }

    triggerWorkOrderSave() {

        this.showSave();

        if (this._detailsTO && this.autoSavingWorkOrders <= 0)
            clearTimeout(this._detailsTO);
        if (this.autoSavingWorkOrders < 0)
            this.autoSavingWorkOrders = 0;

        this._detailsTO = setTimeout(() => {
            this.hideSave();
            this.saveWorkOrders(this.workOrders);
        }, appEnvironment.autoSaveTime);

        if (this.ss != null && this.ss.subscribe && this._ss == null) {
            this._ss = this.ss.subscribe(() => {
                clearTimeout(this._detailsTO);
                this.saveWorkOrders(this.workOrders);
                this._ss = null;
            });
        }

        this.woEditing = [];
    }

    updateWorkOrderValue(value, cell, rowIndex, dataType: string = '') {
        this.woEditing[rowIndex + '-' + cell] = false;
        if (dataType === 'number')
            this.workOrders[rowIndex][cell] = parseInt(value);
        else if (dataType === 'decimal')
            this.workOrders[rowIndex][cell] = parseFloat(value);
        else if (dataType === 'date')
            if (moment(value).isValid())
                this.workOrders[rowIndex][cell] = moment(value).toDate();
            else
                return;
        else
            this.workOrders[rowIndex][cell] = value;
        this.workOrders[rowIndex]['isDirty'] = true;
        this.triggerWorkOrderSave();
    }

    showRepairDetails(id) {
        const _wo = this.workOrders.find((wo) => wo.id === id );
        if (_wo) {
            const dialogRef = this.dialog.open(CreateRepairWorkOrderDialog, {
                minWidth: '70vw', maxWidth: '90vw'
                , data: { id : id }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => { });
        }
    }

    sendMsgtoSP(iPID: number) {
        if (iPID === 0) return;
        const _recepients: any = [];
        _recepients.push(iPID);
        const dialogRef = this.dialog.open(MessageDialog, {
            data: {
                recepients: _recepients
                , subject: 'Please update me'
                , heading: 'Message'
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.showMessage('Message sent, Service Provider will respond if required.');
            }
        });
    }

    assumeLiability(iWorkOrderID: number) {
        let _actionResponse: BaseResponse;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).assumeLiability(iWorkOrderID).subscribe((r) => _actionResponse = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (!Utils.isNullOrEmpty(_actionResponse.message))
                            this.showMessage(_actionResponse.message);
                        else
                            this.showMessage('Liability assumption changed.');
                        this.updateWOList(_actionResponse.data);
                    }
                }
            }
        );
    }

    payBalance(oWorkOrder: WorkOrder) {
        if (oWorkOrder.amountDue > 0) {

            const _ccData: PaymentUIData = new PaymentUIData();

            _ccData.payment3DSInfo = new Payment3DSInformation();
            _ccData.payment3DSInfo.amount = oWorkOrder.amountDue;
            _ccData.payment3DSInfo.billingAddress.givenName = this.user.details.FirstName;
            _ccData.payment3DSInfo.billingAddress.surname = this.user.details.LastName;
            _ccData.referenceID = oWorkOrder.id;

            _ccData.currency = 'USD';
            _ccData.amount = oWorkOrder.amountDue;
            _ccData.header = 'Pay for [' + oWorkOrder.typeDescription + '] to ' + oWorkOrder.serviceProvider; // 'Submit Payment and Assign Work Order to ' + this.selectedServiceProviderName;
            _ccData.title = this.myBRAND + ' is collecting ' + this.FormatNumberToCurrency(oWorkOrder.amountDue) + ' on Behalf of [' + oWorkOrder.serviceProvider
                + ']. I understand and agree to charge ' + this.FormatNumberToCurrency(oWorkOrder.amountDue) + ' on the Credit Card.';

            const _df = this.dialog.open(BrainTreeDialog, { data: _ccData });
            _df.disableClose = true;
            _df.afterClosed().subscribe((result) => {
                this.onPaymentEntered(oWorkOrder.id, result);
            });

        }
    }

    changeSP(oWorkOrder: WorkOrder, $event) {

        this.cancelElementEvent($event);

        if (this.isSavePending()) {
            this.showWarning('Listing data has changed, please save the changes or wait for \"auto save\" to save all changes.');
            return;
        }

        const _wo = this.allWorkOrders.find((w) => w.id === oWorkOrder.id);
        if (_wo) {
            const dialogRef = this.dialog.open(SearchSPDialog, {
                minWidth: '70vw', maxWidth: '90vw'
                , maxHeight: '95vw'
                , data: {
                    propertyTypeID: _wo.propertyTypeID
                    , referenceID: _wo.id
                    , priceBasis: _wo.priceBasis
                    , myRole: _wo.myRole
                    , showStaff: true
                    , geographicArea: _wo.address
                    , selectPrivateOnly: false
                    , collectPaymentIfRequired: true
                    , forServiceTypeID: _wo.typeID
                    , loadingSP: true
                    , allowToPickSP : true
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result != null) {
                    let _actionResponse: BaseResponse;

                    const _spSelection: SPSelectionModel = (new SPSelectionModel()).castToMe(result);
                    const _data = {
                        id: oWorkOrder.id, serviceProviderID: _spSelection.serviceProviderID, price: _spSelection.currentPrice, payment: _spSelection.finance
                        , estimared: _spSelection.estimated, optionIDs: _spSelection.optionIDs, toBePaidBy: _wo.toBePaidBy
                        , serviceID: _wo.serviceID, serviceTypeID: _spSelection.serviceTypeID
                    };
                    this.gc.setBusy(true);
                    (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).changeSP(_data).subscribe((r) => _actionResponse = r
                        , (error) => {
                            this.gc.setBusy(false);
                            this.onApiError(error);
                        }
                        , () => {
                            this.gc.setBusy(false);
                            if (_actionResponse) {
                                if (!Utils.isNullOrEmpty(_actionResponse.error))
                                    this.showError(_actionResponse.error);
                                else {
                                    if (!Utils.isNullOrEmpty(_actionResponse.message))
                                        this.showMessage(_actionResponse.message);
                                    else
                                        this.showMessage('Service Provider Assigned.');
                                    this.updateWOList(_actionResponse.data, _wo.id, _wo.serviceID, _spSelection.serviceTypeID);
                                }
                            }
                        }
                    );
                }
            });
        }
    }

    createNewPrivate(iWorkOrderID: number) {
        this.gc.setBusy(true);
        let _actionResponse: BaseResponse;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).createNewPrivateWorkOrder(iWorkOrderID).subscribe((r) => _actionResponse = r
            , (error) => {
                this.gc.setBusy(false);
                this.onApiError(error);
            }
            , () => {
                this.gc.setBusy(false);
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (!Utils.isNullOrEmpty(_actionResponse.message))
                            this.showMessage(_actionResponse.message);
                        else
                            this.showMessage('New Work Order has been created.');

                        this.replaceWOList(iWorkOrderID,  _actionResponse.data);
                    }
                }
            }
        );
    }

    showBidsAndRFQs(workOrderID: number, forRFQs: boolean = false) {
        this.dialog.open(BidsDialog, {
            minWidth: '70vw', maxWidth: '90vw'
            , data: {
                id: workOrderID
                , forRFQs: forRFQs
                , header: forRFQs === true ? 'Request for Quotes' : 'Bids'
            }
        });
    }

    getSPWorkOrderListings(bFirstCall: boolean = true) {
        let _woResponse;
        this.componentBusy = true;
        // Priortize Assign, Accepted and WO in Progress.
        const _data = { allOperators: this.allOperators };
        if (bFirstCall) {
            this.bindingListings = true;
            this.gc.myWOListings = [];
            this.listings = [];
            _data['includeStatuses'] = includeFirstItems;
            _data['excludeStatuses'] = excludeFirstItems;
        } else {
            if (this.listings == null) this.listings = [];
            _data['includeStatuses'] = this.includeClosed ? includeClosedItems : includeNextItems;
            _data['excludeStatuses'] = excludeNextItems;
        }

        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getWorkOrderProperties(_data).subscribe((r) => _woResponse = r
            , (error) => {
                this.componentBusy = false;
                this.gc.setBusy(false); this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                this.gc.setBusy(false);
                if (_woResponse) {
                    if (bFirstCall) {
                        if (_woResponse.data)
                            _woResponse.data.forEach((l) => this.listings.push({ id: l.id, name: l.address.StreetName + ', ' + l.address.City + ' - ' + l.address.State })); // .map((wo) => (new listing()).castToMe(wo));
                        this.getSPWorkOrderListings(false);
                    } else {
                        this.bindingListings = false;
                        if (_woResponse.data)
                            _woResponse.data.forEach((l) => this.listings.push({ id: l.id, name: l.address.StreetName + ', ' + l.address.City + ' - ' + l.address.State }));
                        if (this.listings && this.listings.length === 1)
                            this.selectedListingID = this.listings[0].id;
                        else if (this.listings && this.listings.length > 0 && this.selectedListingID > 0 && this.listings.find((l) => l.id === this.selectedListingID) == null)
                            this.selectedListingID = this.listings[0].id;

                        if (this.selectedListingID > 0)
                            this.listingSPChanged();
                    }

                    if (this.listings.length > 1 && this.listings[0].id !== 0)
                        this.listings.splice(0, 0, { id: 0, name: '-- Select a Property --' });

                    this.gc.myWOListings = this.listings;
                }
            });
    }

    private getSPWorkOrdersStream() {
        // ?data=' + encodeURI(JSON.stringify(_data))
        const _data = { sessionID: this.gc.user.sessionID, data: { listingIDs: this.listingIDs, fullModel: (this.listingIDs && this.listingIDs.length > 0), allOperators: this.allOperators } };

        // this._streamService
        //    .getServerSentEvent('https://localhost:44350/api/stream/serviceprovider/workOrders?oParams=' + encodeURI(JSON.stringify(_data)), this.gc.user.sessionID)
        //    .subscribe(data => {
        //        console.log(data);
        //    });

        this._streamService.GetExchangeData('https://localhost:44350/api/stream/serviceprovider/workOrders?oParams=' + encodeURI(JSON.stringify(_data)));
    }

    private getSPWorkOrders(bFirstCall: boolean = true) {
        let _woResponse;
        this.componentBusy = true;
        // Priortize Assign, Accepted and WO in Progress.
        const _data = { listingIDs: this.listingIDs, fullModel: (this.listingIDs && this.listingIDs.length > 0), allOperators: this.allOperators,  };
        if (bFirstCall) {
            this.allWorkOrders = [];
            _data['includeStatuses'] = includeFirstItems;
            _data['excludeStatuses'] = excludeFirstItems;
        } else {
            if (this.allWorkOrders == null) this.allWorkOrders = [];
            _data['includeStatuses'] = this.includeClosed ? includeClosedItems : includeNextItems;
            _data['excludeStatuses'] = excludeNextItems;
        }

        this.flagWorkOrderDirty();

        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getWorkOrders(_data).subscribe((r) => _woResponse = r
            , (error) => {
                this.componentBusy = false;
                this.gc.setBusy(false); this.onApiError(error);
            }
            , () => {
                this.componentBusy = false;
                this.gc.setBusy(false);
                if (_woResponse) {
                    if (bFirstCall) {
                        if (_woResponse.data)
                            this.allWorkOrders = _woResponse.data.map((wo) => (new WorkOrder()).castToMe(wo));
                        this.getSPWorkOrders(false);
                    } else {
                        if (_woResponse.data)
                            this.allWorkOrders = [...this.allWorkOrders.concat(_woResponse.data.map((wo) => (new WorkOrder()).castToMe(wo)))];
                        this.prepareWorkOrders();
                    }
                    this.flagWorkOrderDirty();
                } else {
                    this.prepareWorkOrders();
                }
            });
    }

    private prepareWorkOrders(refresh: boolean = false) {

        this.serviceTypes = new Array<item>();
        this.operators = new Array<item>();
        this.statuses = new Array<item>();

        if (this.allWorkOrders) {
            this.allWorkOrders.forEach((wo) => {
                if (this.listings.find((x) => x.id === wo.listingID) == null) this.listings.push((new item()).castToMe({
                    id: wo.listingID, name: wo.address.StreetName + ', ' + wo.address.City + ' - ' + wo.address.State
                }));
            });
        }

        if ((this.listings && this.listings.length > 0) || (this.listingIDs && this.listingIDs.length > 0))
            this.bindWorkOrders(refresh);

        if (this.forRole !== Role.Buyer && this.forRole !== Role.Seller)
            setTimeout(() => { this.buildFilters(); }, 10000);
    }

    private buildFilters() {

        if (this.listings == null || this.listings.length === 0) {
            this.serviceTypes = new Array<item>();
            this.operators = new Array<item>();
            this.statuses = new Array<item>();

            if (this.allWorkOrders) {
                this.allWorkOrders.forEach((wo) => {
                    if (this.serviceTypes.find((x) => x.id === wo.typeID) == null) this.serviceTypes.push((new item()).castToMe({
                        id: wo.type.id, name: wo.type.description + ' [' + this.allWorkOrders.filter((w) => w.typeID === wo.typeID).length + ']'
                    }));
                });

                this.operators.push(new item(0, 'Un Assigned' + ' [' + this.allWorkOrders.filter((w) => w.currentStatus === ServiceWorkOrderStatus.None).length + ']'));
                this.allWorkOrders.forEach((wo) => {
                    if (this.operators.find((x) => x.id === wo.operatorID) == null) this.operators.push((new item()).castToMe({
                        id: wo.operatorID, name: wo.operatorName + ' [' + this.allWorkOrders.filter((w) => w.operatorID === wo.operatorID).length + ']'
                    }));
                });

                this.allWorkOrders.forEach((wo) => {
                    if (this.statuses.find((x) => x.id === wo.currentStatus) == null) this.statuses.push((new item()).castToMe({
                        id: wo.currentStatus, name: wo.statusName + ' [' + this.allWorkOrders.filter((w) => w.currentStatus === wo.currentStatus).length + ']'
                    }));
                });
            }
        }
    }

    private onPaymentEntered(iWorkOrderID: number , payment: PaymentUIData) {
        if (payment && payment.paymentNonce) {

            const _o: SPSelectionModel = new SPSelectionModel();

            _o.currentPrice = payment.amount != null ? payment.amount : 0;
            _o.estimated = false;
            _o.finance = payment;

            const _data = { id: iWorkOrderID, payment: payment };
            let _response;
            (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).payNow(_data).subscribe((r) => _response = r
                , (error) => { }
                , () => {
                    if (_response && _response.data) {
                        if (!Utils.isNullOrEmpty(_response.message))
                            this.showMessage(_response.message);
                        else
                            this.showMessage('Payment Received');
                        this.updateWOList(_response.data);
                    } else if (!Utils.isNullOrEmpty(_response.error)) {
                        this.showMessage(_response.error);
                    }
                });
        }
    }

    private replaceWOList(iWorkOrderID: number, oData: any) {
        this.allWorkOrders = cloneDeep(this.allWorkOrders.filter((wo) => wo.id !== iWorkOrderID)); // Update Work Order on Client
        if (oData && oData.id && oData.id > 0 && this.allWorkOrders) {
            this.allWorkOrders.splice(0, 0, (new WorkOrder()).castToMe(oData));
        }
        this.flagWorkOrderDirty();
        this.prepareWorkOrders(true);
    }

    private updateWOList(oData: any, iWorkOrderID: number = 0, iServiceID: number = 0 , iServiceTypeID: number = 0) {

        if (iWorkOrderID == null || iWorkOrderID === 0)
            iWorkOrderID = oData.id;

        if (iWorkOrderID > 0)
            this.allWorkOrders = cloneDeep(this.allWorkOrders.filter((wo) => wo.id !== iWorkOrderID)); // Update Work Order on Client
        else if (iServiceID > 0 && iServiceTypeID > 0)
            this.allWorkOrders = cloneDeep(this.allWorkOrders.filter((wo) => wo.serviceID !== iServiceID && wo.typeID !== iServiceTypeID)); // Update Work Order on Client

        if (oData && oData.id && oData.id > 0 && this.allWorkOrders) {
            if (this.allWorkOrders == null)
                this.allWorkOrders = [];
            if (this.allWorkOrders.length === 0)
                this.allWorkOrders.push((new WorkOrder()).castToMe(oData));
            else
                this.allWorkOrders.splice(0, 0, (new WorkOrder()).castToMe(oData));
            this.prepareWorkOrders(true);
        }

        this.flagWorkOrderDirty();
    }

    private saveWorkOrders(workOrders) {

        const _me = this;

        let _itemsToUpdate: number = 0;

        if (workOrders != null)
            _itemsToUpdate = workOrders.filter((c) => c.id === 0 || c.isDirty === true).length;

        if (_itemsToUpdate > 0) {

            const _readyToSave = workOrders.filter((c) => c.id === 0 || c.isDirty === true);
            if (_readyToSave == null || _readyToSave.length === 0) return;

            // To Save Data for Objects with Properies having GET/SET
            const _saveData: WorkOrderSaveModel[] = _readyToSave.map((wo) => (new WorkOrderSaveModel()).castToMe(wo));

            _me.gc.setBusy(true);

            // Call Save API
            let saveResponseData;
            _me.autoSavingWorkOrders++;
            (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).saveWorkOrdersforSP(_saveData).subscribe(
                (r) => { this.gc.setBusy(false); saveResponseData = r; }
                , (error) => { _me.autoSavingWorkOrders--; this.onApiError(error); }
                , () => {

                    _me.hideSave();

                    _me.autoSavingWorkOrders--;
                    _me.woEditing = [];

                    const _woIDs: number[] = new Array<number>();
                    _readyToSave.forEach((wo) => {
                        if (_woIDs.indexOf(wo.id) < 0) _woIDs.push(wo.id);
                    });

                    if (saveResponseData && saveResponseData.data) {
                        _me.allWorkOrders = cloneDeep(_me.allWorkOrders.filter((allwo) => {
                            return _woIDs.indexOf(allwo.id) < 0;
                        })); // Update Work Orders on Client
                        for (const wo of saveResponseData.data)
                            _me.allWorkOrders.push((new WorkOrder()).castToMe(wo));
                        _me.prepareWorkOrders(true);
                    }
                });
        }
    }

    private initAvailableServices() {

        if (this.allServiceTypes == null || this.allServiceTypes.length === 0) {
            let _response;
            (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getServiceTypes().subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && _response.data) {
                        this.allServiceTypes = _response.data;
                        this.setAvailableServices();
                    }
                }
            );
        } else
            this.setAvailableServices();
    }

    private setAvailableServices() {
        if (this.workOrders) {
            const _ids = this.workOrders.map((wo) => wo.typeID);
            if (this.escrowForSelectedListing > 0 || this.offerForSelectedListing > 0)
                this.availableServiceTypes = this.allServiceTypes.filter((st) => _ids.find((as) => as === st.id) == null);
            else
                this.availableServiceTypes = this.allServiceTypes.filter((st) => st.privateToRequestor === true && _ids.find((as) => as === st.id) == null);

            this.availableServiceTypes = [...this.availableServiceTypes.filter((s) => s.id !== appEnvironment.escrowServiceTypeID)];
        } else if (this.escrowForSelectedListing > 0 || this.offerForSelectedListing > 0)
            this.availableServiceTypes = this.allServiceTypes;
        else {
            this.availableServiceTypes = this.allServiceTypes.filter((st) => st.privateToRequestor === true);
        }
    }

    private flagWorkOrderDirty() {
        if (this.allWorkOrders && Array.isArray(this.allWorkOrders) && this.allWorkOrders.length > 0)
            this.isWorkOrderDirty = this.allWorkOrders.find((wo) => wo.isDirty) != null;
        else
            this.isWorkOrderDirty = false;
    }

}
