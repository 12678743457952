import { Component, OnInit, Input, Output, ViewChild, AfterViewChecked, ChangeDetectorRef, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { BaseResponse, asset, treeNode, AssetType, assetStatus, TagModel, item, AssetMin } from '../models';
declare var $: any;
import { AssetService } from '../common/service';
import { ConfirmationDialogComponent } from '../common/dialogs';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatDrawer } from '@angular/material/sidenav';
import { AngularEditorComponent } from '../htmlEditor/angular-editor.component';
import { SystemService } from '../admin/system/system.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { DndDropEvent } from 'ngx-drag-drop';
import { appEnvironment } from '../../environments/environment';
import { Subscription } from 'rxjs/internal/Subscription';
import { navIA } from '../models/constants';
import * as cloneDeep from 'lodash/cloneDeep';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '../common/utils';

interface IFlatNode {
    id: number;
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'app-help'
    , templateUrl: './help.component.html'
    , styleUrls: ['./help.component.css']
})
export class HelpComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    targets: any = [];
    target: string = '';
    source: string = '';
    publishing: boolean = false;

    showMyFavoritesOnly: boolean = false;
    loadedBookmarks: boolean = false;

    pageTitle: string = '';
    tagsOnPage: number = 20;
    tagsPageNumber: number = 1;

    canEdit: boolean = false;
    startingCategory: any = {};
    assets: any = [];
    selectedAsset: asset = new asset();
    tags: TagModel[] = [];
    treeControl = new FlatTreeControl<IFlatNode>((node) => node.level, (node) => node.expandable);
    treeLoading: boolean = false;
    currentSelectedGroup: any = {};

    managingAssets: boolean = false;
    assetTypes = AssetType;
    assetType: AssetType = AssetType.HelpContent;

    showAsAssetPage: boolean = false;
    assetStatuses = assetStatus;
    assetName: string = '';

    draggable = {
        // note that data is handled with JSON.stringify/JSON.parse
        // only set simple data or POJO's as methods will be lost
        effectAllowed: 'all',
        disable: false,
        handle: false
    };

    helpDirty: boolean = false;
    showKeyword: boolean = false;

    savingEditor: boolean = false;
    @ViewChild('helpContentEditor') helpContentEditor: AngularEditorComponent;
    @ViewChild('contentEditor') contentEditor: AngularEditorComponent;

    private _to: any = null;
    private _tagTo: any = null;
    private _assetData: any = null;
    private _ls: Subscription;
    constructor(private ds: DataService, private rs: Router, private cdref: ChangeDetectorRef, @Inject(GlobalComponent) gc: GlobalComponent
        , breakpointObserver: BreakpointObserver, private ar: ActivatedRoute, private dialog: MatDialog
    ) {
        super('pgHelp', rs, gc);

        this.managingAssets = (this.route.url.toLowerCase().indexOf('/asset') >= 0);

        if (this.managingAssets === true) {
            this.canEdit = document.getElementById(navIA) != null;
            breakpointObserver.observe([
                Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe((result) => {
                    if (result.matches && this.canEdit) {
                        this.setEditorHeight();
                    }
                });

            this._ls = this.gc.loginChanged.subscribe((v) => {
                if (v == null)
                    this.canEdit = false;
                else
                    this.canEdit = document.getElementById(navIA) != null;

                this.editorConfig.editable = this.canEdit === true && this.managingAssets === true;
            });

            this.editorConfig.editable = this.canEdit && this.managingAssets === true;
        }

        const _type = sessionStorage.getItem('assetType');
        if (_type != null && !this.isNaN(parseInt(_type)))
            this.assetType = parseInt(_type);
    }

    _transformer = (node: treeNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level : level,
            id: node.id,
            parentID: node.parentID
        };
    }

    // tslint:disable-next-line: member-ordering
    treeFlattener = new MatTreeFlattener(this._transformer, (node) => node.level, (node) => node.expandable, (node) => node.children);
    // tslint:disable-next-line: member-ordering
    helpData = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: IFlatNode) => node.expandable;

    ngOnDestroy() {
        if (this._ls) this._ls.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngOnInit() {

        const _ad = sessionStorage.getItem('ad');

        if (!Utils.isNullOrEmpty(_ad) && Utils.isJsonString(_ad)) {
            const _c: any = JSON.parse(_ad);
            if (_c.showAsAssetPage != null) this.showAsAssetPage = _c.showAsAssetPage;
            if (_c.startingCategory != null) this.startingCategory = _c.startingCategory;
            sessionStorage.removeItem('ad');
        } else {
            const _c = sessionStorage.getItem('cat');
            if (_c == null || !Utils.isJsonString(_c))
                this.startingCategory = { id: 0, name: '' };
            else
                this.startingCategory = JSON.parse(_c);
        }

        if (this.showAsAssetPage) this.canEdit = false;
        if (this.showAsAssetPage && this.startingCategory && this.startingCategory.id > 0) {
            this.itemSelected({ id: this.startingCategory.id });
        } else if (!this.showAsAssetPage) {

            this.componentBusy = true;
            this.treeLoading = true;

            this.initPage();

            if (this.canEdit) {
                let _response;
                (new SystemService(this.ds, this.route, this.myResourceCategory)).getPublishingTargets().subscribe(
                    (data) => { _response = data; }
                    , (error) => { this.targets = []; }
                    , () => {
                        if (_response && _response.data) {
                            this.source = _response.data.source;
                            this.targets = _response.data.targets;
                            if (this.targets && this.targets.length > 1)
                                this.targets.splice(0, 0, '-- Select Target --');

                            if (this.targets && this.targets.length > 0)
                                this.target = this.targets[0];
                        }
                    });
            }
        }
    }
    ngAfterViewInit() {
        this.setEditorHeight();
    }

    itemSelected(node, isGroup: boolean = false) {

        if (this.helpDirty === false || isGroup === true) {
            this.changeSelectedAsset(node, isGroup);
        } else {
            const _data = {
                IsConfirmation: true
                , Message: 'Are you sure you want to continue to different item? You will loose your changes.'
                , Title: 'Confirm'
                , IsMessageAsHTML: true
            };
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: _data
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result && result === false) {
                    this.changeSelectedAsset(node, isGroup);
                }
            });
        }
    }

    saveEditorAndClose() {
        this.saveEditorChanges(true);
    }
    discardEditorChanges() {
        this.selectedAsset.helpContent = '';
    }

    saveEditorChanges(bClose: boolean = false) {
        const _itemRequest = {
            id: this.selectedAsset.id
            , content: (this.contentEditor == null || this.contentEditor.html == null) ? '' : (this.contentEditor.html as string).replace('<p><br></p>', '<br>')
            , helpContent: this.helpContentEditor.html == null ? '' : (this.helpContentEditor.html as string).replace('<p><br></p>', '<br>')
        };

        let _detailService;
        this.gc.setComponentBusy(true);
        (new AssetService(this.ds, this.rs, this.myResourceCategory)).saveHelpContent(_itemRequest).subscribe(
            (data) => { _detailService = data; }
            , (error) => { this.gc.setComponentBusy(false); this.onApiError(error); }, () => {
                this.helpDirty = false;
                this.gc.setComponentBusy(false);
                if (_detailService) {
                    if (!Utils.isNullOrEmpty(_detailService.error))
                        this.showError(_detailService.error);
                    // else if (bClose)
                    //     this.discardEditorChanges();
                }
            });
    }

    addAsset() {
        this.selectedAsset = new asset();
        this.selectedAsset.name = this.assetName;
        this.selectedAsset.type = this.assetType;
        if (this.currentSelectedGroup && this.currentSelectedGroup.id)
            this.selectedAsset.helpCategoryID = this.currentSelectedGroup.id;
        this.save();
        this.assetName = '';
    }

    publish() {
        if (!Utils.isNullOrEmpty(this.target) && this.selectedAsset && this.selectedAsset.id > 0) {
            this.publishing = true;
            let _response: BaseResponse;
            (new SystemService(this.ds, this.route, this.myResourceCategory)).publish(this.target, this.selectedAsset.id).subscribe(
                (data) => { _response = data; this.publishing = false; }
                , (error) => { this.showError(error); }
                , () => {
                    if (_response && Utils.isNullOrEmpty(_response.error)) {
                        if (this.targets && this.targets.length > 0)
                            this.target = this.targets[0];
                    }
                });
        }
    }

    onDropToPrev(event: DndDropEvent) {
        if (event && event.data && event.data.id) {
            const _e = event.data;
            if (this.selectedAsset.previous == null) this.selectedAsset.previous = [];
            const _i = this.selectedAsset.previous.find((i) => i.id === _e.id);
            if (_i == null) {
                this.selectedAsset.previous.push(({ id: _e.id, name: _e.name, title: _e.name } as AssetMin));
                this.setAssetDirty();
            }
        }
    }

    onDropToNext(event: DndDropEvent) {
        if (event && event.data && event.data.id) {
            const _e = event.data;
            if (this.selectedAsset.next == null) this.selectedAsset.next = [];
            const _i = this.selectedAsset.next.find((i) => i.id === _e.id);
            if (_i == null) {
                this.selectedAsset.next.push(({ id: _e.id, name: _e.name, title : _e.name } as AssetMin));
                this.setAssetDirty();
            }
        }
    }

    onParentChange(event: DndDropEvent, parent: any) {
        if (this.managingAssets === true && event && event.data && event.data.id) {
            const _e = event.data;
            if (parent != null && parent.id > 0) {
                let _detailService;
                const _request = { id: _e.id, currentCategoryID : _e.parentID, newCategoryID: parent.id };
                (new AssetService(this.ds, this.rs, this.myResourceCategory)).changeHelpCategory(_request).subscribe(
                    (data) => { _detailService = data; }
                    , (error) => { this.gc.setComponentBusy(false); this.onApiError(error); }
                    , () => {
                        this.gc.setComponentBusy(false);
                        if (_detailService) {
                            if (!Utils.isNullOrEmpty(_detailService.error))
                                this.showError(_detailService.error);
                            else {
                                this.moveNode(event.data, parent.id);
                                // Move Items in Tree
                            }
                        }
                    });
            }
        }
    }

    removeNode(node: any, $event: any) {
        this.cancelElementEvent($event);
        if (this.managingAssets === true && node != null) {
            if (node.id === 0) {
                this.moveNode(node, 0);
            }
            if (node.parentID === 0) {
                const _v = confirm('Are you sure you want to delete this entry?');
                if (_v === false)
                    return;
            }
            let _detailService;
            const _request = { id: node.id, currentCategoryID: node.parentID, newCategoryID: 0 };
            (new AssetService(this.ds, this.rs, this.myResourceCategory)).changeHelpCategory(_request).subscribe(
                (data) => { _detailService = data; }
                , (error) => { this.gc.setComponentBusy(false); this.onApiError(error); }
                , () => {
                    this.gc.setComponentBusy(false);
                    if (_detailService) {
                        if (!Utils.isNullOrEmpty(_detailService.error))
                            this.showError(_detailService.error);
                        else {
                            this.moveNode(node, 0);
                            // Move Items in Tree
                        }
                    }
                });
        }
    }

    removePreviousTopic(id) {
        if (id != null && id > 0 && this.selectedAsset.previous) {
            this.selectedAsset.previous = this.selectedAsset.previous.filter((i) => i.id !== id);
            this.setAssetDirty();
        }
    }

    removeNextTopic(id) {
        if (id != null && id > 0 && this.selectedAsset.next) {
            this.selectedAsset.next = this.selectedAsset.next.filter((i) => i.id !== id);
            this.setAssetDirty();
        }
    }

    setHelpDirty() {
        if (this.selectedAsset && this.canEdit) {
            this.helpDirty = true;
        }
    }

    setAssetDirty() {
        if (this.selectedAsset && this.canEdit) {
            if ((this.contentEditor == null || this.contentEditor.modeVisual) && this.helpContentEditor.modeVisual) {
                this.selectedAsset.isDirty = true;
                $('#divAssetEditor').addClass('polkadots');
                this.triggerSave();
            }
        }
    }

    onAssetTypeChange() {
        this.setAssetByType();

        // Reset Editing Asset only when New asset if being editied
        if (this.managingAssets === false || this.selectedAsset?.id === 0)
            this.selectedAsset = null;
    }

    setTagDirty(tag: any) {
        tag.active = tag.isDirty = true;
        $('#divAssetEditor').addClass('polkadots');
        this.triggerTagSave();
    }

    addTag() {
        if (this.tags == null)
            this.tags = [];

        const _t = new TagModel();
        _t.isDirty = true;

        if (this.tags.length === 0)
            this.tags.push(_t);
        else if (!Utils.isNullOrEmpty(this.tags[0].tagCode)) {
            this.tags.splice(0, 0, _t);
        }
    }

    removeTag(tag: any) {
        tag['active'] = false;
        tag.isDirty = true;
        this.triggerTagSave();
    }

    refreshAssetsCache() {
        let _service;
        this.componentBusy = true;
        (new AssetService(this.ds, this.rs, this.myResourceCategory)).refreshTagsCache().subscribe(
            (data) => { _service = data; }
            , (error) => { this.componentBusy = false; }
            , () => {
                this.componentBusy = false;
                this.savingEditor = false;
                if (_service) {
                    if (Utils.isNullOrEmpty(_service.error)) {
                        this.gc.showSnackBar('Assets and Tags Cache Refreshed');
                    }
                }
            });
    }

    private triggerSave() {
        this.showSave();
        if (this._to == null) {
            this._to = setTimeout(() => {
                if (this._ss)
                    this._ss.unsubscribe();
                this.ss = null;
                this._to = null;
                this.hideSave();
                this.save();
            }, appEnvironment.autoSaveTime);
        }

        if (this.ss != null && this.ss.subscribe) {
            if (this._ss == null) {
                this._ss = this.ss.subscribe(() => {
                    clearTimeout(this._to);
                    this.save();
                    this._ss = null;
                });
            }
        }
    }

    private save() {

        if (this.selectedAsset == null) return;

        if (this.contentEditor != null) this.selectedAsset.content = this.contentEditor.html;
        if (this.helpContentEditor) this.selectedAsset.helpContent = this.helpContentEditor.html;

        let _detailService;
        this.componentBusy = true;
        this.savingEditor = true;
        clearTimeout(this._to);

        if (this.selectedAsset.keywords != null && typeof this.selectedAsset.keywords === 'string') {
            this.selectedAsset.otherTitles = (this.selectedAsset.keywords as string).split(',');
        } else
            this.selectedAsset.otherTitles = [];

        const _asset = cloneDeep(this.selectedAsset);
        if (this.helpDirty === false) {
            // Remove Large HTML Content for Optimization to post
            delete _asset.content;
            delete _asset.helpContent;
            // Remove Large HTML Content for Optomization to post
        }

        this.hideSave();
        (new AssetService(this.ds, this.rs, this.myResourceCategory)).saveAsset(_asset).subscribe(
            (data) => { _detailService = data; }
            , (error) => {
                this.componentBusy = false;
                this.savingEditor = false; this.onApiError(error);
            }, () => {
                this.helpDirty = this.savingEditor = false;
                this.componentBusy = false;
                if (_detailService) {
                    if (!Utils.isNullOrEmpty(_detailService.error)) {
                        this.triggerSave();
                        this.showError(_detailService.error);
                    } else {

                        if (_detailService.data && _detailService.data > 0 && this.currentSelectedGroup) {
                            this.moveNode({ id: _detailService.data, name: this.assetName }, this.currentSelectedGroup.id);
                        }

                        this.selectedAsset = null;
                        this.showMessage('Asset informtion saved.');
                        $('#divAssetEditor').removeClass('polkadots');

                    }
                }
            });
    }

    private triggerTagSave() {
        if (this._tagTo == null) {
            this._tagTo = setTimeout(() => {
                this.tagSave();
            }, appEnvironment.autoSaveTime);
        }
    }

    private tagSave() {

        if (this.selectedAsset == null || this.tags == null) return;

        if (this.tags != null) {
            const _tagsDirty = this.tags.filter((t) => t.isDirty === true);
            if (_tagsDirty) {
                let _tagService: BaseResponse;
                this.gc.setBusy(true);
                clearTimeout(this._tagTo);
                this._tagTo = null;
                (new AssetService(this.ds, this.rs, this.myResourceCategory)).saveTags(this.selectedAsset.id, _tagsDirty).subscribe(
                    (data) => { _tagService = data; }
                    , (error) => {
                        this.gc.setComponentBusy(false);
                        this.savingEditor = false; this.onApiError(error);
                    }, () => {
                        this.gc.setBusy(false);
                        if (_tagService) {
                            if (!Utils.isNullOrEmpty(_tagService.error)) {
                                this.triggerTagSave();
                                this.showError(_tagService.error);
                            } else {
                                $('#divAssetEditor').removeClass('polkadots');
                                this.tags.forEach((t) => t.isDirty = false);
                            }
                        }
                    });
            }
        }
    }

    private initPage() {

        if (this.helpData == null || this.helpData.data == null || this.helpData.data.length === 0) {

            let response: BaseResponse;
            let _sub: any;

            if (!this.managingAssets === true) {
                _sub = (new AssetService(this.ds, this.rs, this.myResourceCategory)).getHelpTree();
            } else {
                _sub = (new AssetService(this.ds, this.rs, this.myResourceCategory)).assetTree(this.gc.loggedIn);
            }

            if (_sub != null) {
                _sub.subscribe(
                    (data) => { this.treeLoading = false; response = data; },
                    (error) => { this.treeLoading = false; },
                    () => {
                        this.componentBusy = false;
                        if (response && response.data) {
                            this.bindTree(response.data);
                        }
                    }
                );
            }
        }
    }

    private bindTree(oData: any) {
        this._assetData = oData;
        if (this.managingAssets === true) {
            this.setAssetByType();
        } else {
            this.helpData.data = this._assetData;
        }
        this.cdref.detectChanges();

        if (this.startingCategory) {
            if (!Utils.isNullOrEmpty(this.startingCategory.name)) {
                const _n = this.treeControl.dataNodes.find((n) => n.expandable === true && n.name.toLowerCase() === this.startingCategory.name);
                if (_n) {
                    this.treeControl.expand(_n);
                }
            }
            if (this.startingCategory.id && this.startingCategory.id > 0) {
                let _n = this._assetData.data?.find((n) => n.children.find((c) => c.id === this.startingCategory.id) != null);
                if (_n != null) {
                    _n = _n.children.find((c) => c.id === this.startingCategory.id);
                    this.itemSelected(_n);
                }
            }
            this.startingCategory = {};
        }
    }

    private moveNode(node: any, toCategoryID: number = 0) {
        if (node && node.id > 0 && toCategoryID >= 0) {
            const _currentParent = this._assetData.find((_p) => _p.id === node.parentID);

            let _me = node;
            if (_currentParent != null)
                _me = cloneDeep(_currentParent.children.find((_m) => _m.id === node.id));

            const _newParent = this._assetData.find((_p) => _p.id === toCategoryID);
            if (_currentParent != null) {
                if (_currentParent.children != null && _currentParent.children.length > 0)
                    _currentParent.children = [..._currentParent.children.filter((cp) => cp.id !== node.id)];
            }

            if (toCategoryID > 0 && _newParent != null) {
                if (_newParent.children == null)
                    _newParent.children = [];
                _newParent.children.push(_me);
            }

            this.bindTree(this._assetData);
        }
    }

    private setAssetByType() {
        sessionStorage.setItem('assetType', this.assetType.toString());
        const _data = [];
        if (this._assetData && Array.isArray(this._assetData)) {
            this._assetData.forEach((a) => {
                if (a.children && Array.isArray(a.children)) {
                    const _c = a.children.filter((c) => c.type === this.assetType);
                    if (_c && _c.length > 0) {
                        _data.push({ id: a.id, name: a.name, tip : a.tip == null ? '' : a.tip, children: _c });
                    }
                }
            });
        }
        this.helpData.data = _data;
    }

    private setEditorHeight() {
        const _aa = $('#divAssetEditor');
        if (_aa) {
            _aa.css('max-height', (window.innerHeight - 310).toString() + 'px');
            _aa.css('overflow-y', 'auto');
            _aa.css('overflow-x', 'hidden!important');
        }
    }

    private changeSelectedAsset(node, isGroup: boolean = false) {
        $('#divAssetEditor').removeClass('polkadots');
        this.helpDirty = false;

        this.selectedAsset = new asset();
        let id = 0;
        if (node.id) {
            id = node.id;
            this.showKeyword = !(node['showingResultsGrid'] === true);
        } else {
            id = node;
        }

        if (isGroup)
            this.currentSelectedGroup = node;
        else {
            this.currentSelectedGroup = null;

            if (!this.managingAssets)
                this.componentBusy = true;

            let _detResponse: BaseResponse;
            (new AssetService(this.ds, this.rs, this.myResourceCategory)).getAsset(id, this.canEdit).subscribe(
                (data) => { _detResponse = data; },
                (error) => { this.selectedAsset = null; },
                () => {
                    if (!this.managingAssets)
                        this.componentBusy = false;
                    if (_detResponse && _detResponse.data) {
                        this.selectedAsset = _detResponse.data;
                        if (_detResponse.data['otherTitles'] && Array.isArray(_detResponse.data['otherTitles']))
                            this.selectedAsset.keywords = _detResponse.data.otherTitles.join(',');
                        else
                            this.selectedAsset.keywords = '';
                        this.selectedAsset.isDirty = false;
                    }
                }
            );

            if (this.managingAssets) {
                let _tagsResponse: BaseResponse;
                (new AssetService(this.ds, this.rs, this.myResourceCategory)).getTags(id).subscribe(
                    (data) => { _tagsResponse = data; },
                    (error) => { this.tags = []; },
                    () => {
                        this.componentBusy = false;
                        if (_tagsResponse && _tagsResponse.data) {
                            if (Array.isArray(_tagsResponse.data) && _tagsResponse.data.length > 0)
                                this.tags = _tagsResponse.data.map((c) => Utils.castTo(c, new TagModel())).sort((a, b) => a.tagCode > b.tagCode ? 1 : -1);
                            else
                                this.tags = [];
                        }
                    }
                );
            }
        }
    }
}
