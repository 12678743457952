import { HostListener, Directive } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[hrefToRouterLink]'
})
export class HrefToRouterLinkDirective {
    constructor(private router: Router) {
    }
    @HostListener('click', ['$event'])
    onClick(event) {
        const clickedElement = event.target;
        if (this.canUseRouterLink(clickedElement)) {

            this.cancelClick(event);

            if (clickedElement.hash && clickedElement.hash.startsWith('#'))
                this.scroll(clickedElement.hash);
            else {
                let _url = (clickedElement.href as string).toLowerCase();
                const _s = _url.indexOf('localhost') ? document.location.host : (_url.indexOf('iamorea.') ? document.location.host : _url);
                const _i = _url.indexOf(_s);
                if (_i >= 0)
                    _url = _url.substring(_i + _s.length, _url.length);

                // To use router.navigate(), we remove hostname
                if (_url.startsWith('http')) {
                    this.router.navigateByUrl(_url);
                } else
                    this.router.navigate([_url]);
            }
        }
    }

    // Identify whether element can be treated as routerLink
    private canUseRouterLink(element): boolean {

        // We can't routerLink to external sites.
        // Using a class on local links is a good way
        // to identify links we want to use routerLink on
        // Continuing the social media example, we'll use .tag
        const localAnchorClass = 'tag';

        // If element has an href and our .tag class
        return !!element.href; //&& element.classList.contains(localAnchorClass);
    }
    // Stop the browser's default behavior (navigation)
    private cancelClick(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    // Href will look like: "blog.kevynjaremko.com/some-slug"
    // Output will look like: "/some-slug"
    private getLocalUrl(href: string): string {
        if (href.startsWith('http'))
            return href;
        else {
            const domain = window.location.host;
            return href.substr(href.indexOf(domain) + domain.length + 1);
        }
    }

    private scroll(id) {
        let el = document.querySelector(id);
        if (id.startsWith('#'))
            el = document.querySelector(id);
        else
            el = document.getElementById(id);

        if (el)
            el.scrollIntoView();
    }
}
