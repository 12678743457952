<mat-card>
    <mat-card-title>New Media</mat-card-title>
    <div class="app-flex-container app-2col">
        <mat-form-field>
            <mat-label class="placeholder">Title</mat-label>
            <input matInput [(ngModel)]="media.title" maxlength="100"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label class="placeholder">Description</mat-label>
            <input matInput [(ngModel)]="media.description" maxlength="1000"/>
        </mat-form-field>
    </div>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="media.type">
        <mat-button-toggle value="url">Web Address Outside {{myBRAND}} (Preferred)</mat-button-toggle>
        <mat-button-toggle value="upload">Upload to {{myBRAND}}</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-card>
            <div class="inline-block">
                <div ng2FileDrop *ngIf="media.type == 'upload'"
                     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                     (fileOver)="fileOverBase($event)"
                     [uploader]="uploader"
                     class="well my-drop-zone">
                    {{media && media.content ? ('[' + media.content.name + '] ') : ''}} Drap and drop your file here, allowed file type {{fileExtension}}, MAX allowed 5MB.
                </div>
                <input *ngIf="media.type == 'upload'" type="file" ng2FileSelect [uploader]="uploader" #mediaFile class="btn btn-primary" (change)="fileUploaded($event)" />
                <mat-form-field *ngIf="media.type != 'upload'">
                    <mat-label class="placeholder">Web Address</mat-label>
                    <textarea matInput [(ngModel)]="media.url" maxlength="1000" class="large"></textarea>
                    <mat-error *ngIf="(utils.isNullOrEmpty(this.media.url) && this.media.content.content == null)">Media details required.</mat-error>
                </mat-form-field>
            </div>
        <button matButton (click)="saveMedia()" *ngIf="componentBusy === false">Add</button>
    </mat-card>
</mat-card>