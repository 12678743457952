import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { GlobalComponent } from '../../global.component';

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { item } from '../../models';
import { WorkOrderService } from '../../workorders/workorder.service';
import { EscrowService } from '../escrow.service';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-add-demand-dialog',
    templateUrl: './addDemand.dialog.html'
})
export class AddDemandDialog extends BaseDialog {



    workOrderID: number = 0;
    escrowID: number = 0;
    availableDemands: any = [];
    openDemands: any = null;
    demandToRoles: any = [];
    demandFromServices: any = []; // TODO -- to be Implmented on API
    demandAmount: number = 0;
    showOpenDemands: boolean = true;
    addCredit: boolean = false;

    openDemandID: number = 0;

    dialogHeader: string = '';

    selectedDemandTo: number = 0;
    selectedDemandFrom: number = 0;
    selectedDemand: string = '';

    processing: boolean = false;

    constructor(ds: DataService, r: Router, dialogRef: MatDialogRef<AddDemandDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addDemandDialog', ds, r, dialogRef, data);

        if (data) {

            if (data.id) this.workOrderID = data.id;
            if (data.escrowID) this.escrowID = data.escrowID;

            this.addCredit = data.addCredit ? data.addCredit : false;
            if (data.showOpenDemands)
                this.showOpenDemands = data.showOpenDemands;

            if (this.addCredit) this.showOpenDemands = false;

            if (!Utils.isNullOrEmpty(this.data.header))
                this.dialogHeader = (this.addCredit ? ' for ' : ' for ') + this.data.header;

            let _response: any;
            (new WorkOrderService(this.dataservice, this.route, this.myResourceCategory)).prepareAddDemand(this.workOrderID).subscribe(
                (_data) => _response = _data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && _response.data) {
                        if (_response.data.openDemands) this.openDemands = _response.data.openDemands;
                        if (_response.data.availableDemands && !this.addCredit) this.availableDemands = _response.data.availableDemands;
                        if (_response.data.demandFromServices) this.demandFromServices = _response.data.demandFromServices;
                        if (_response.data.demandToRoles) this.demandToRoles = _response.data.demandToRoles;

                        if (this.demandFromServices.length === 1)
                            this.selectedDemandFrom = this.demandFromServices[0].id;
                        else if (this.demandFromServices.length > 1)
                            this.demandFromServices.splice(0, 0, new item(0, '-- Select demanded by or paid to -- '));

                        if (this.demandToRoles.length === 1)
                            this.selectedDemandTo = this.demandToRoles[0].id;
                        else if (this.demandToRoles.length > 1)
                            this.demandToRoles.splice(0, 0, new item(0, '-- Select paid by -- '));
                    } else {
                        this.openDemands = [];
                    }
                }
            );
        }

        setTimeout(() => {
            if (this.workOrderID === 0 || this.escrowID === 0) {
                this.dialogRef.close(null); this.showError('Invalid Demand, work order and/or escrow information missing');
            }
        }, 1000);
    }

    get readyToSubmit() {
        if (!this.addCredit) {
            return this.escrowID > 0 && this.workOrderID > 0 && !this.processing && this.selectedDemandFrom > 0 && this.selectedDemandTo > 0 && this.demandAmount > 0;
        } else {
            return this.escrowID > 0 && this.workOrderID > 0 && !this.processing && this.selectedDemandFrom > 0 && this.selectedDemandTo > 0 && Math.abs(this.demandAmount) > 0;
        }
    }

    onAddButtonClicked(value) {
        this.availableDemands.splice(0, 0, new item(0, value));
    }

    close(data) {
        this.dialogRef.close(data);
    }

    cancelDemand() {
        // DO NOT ALLOW CANCEL DEMAND, user should add credit.
    }

    addDemand() {
        const _data = {
            id: this.escrowID
            , workOrderID: this.workOrderID
            , amount: ((this.addCredit) ? -1 : 1) * Math.abs(this.demandAmount)
            , demandToRole: this.selectedDemandTo
            , demandingService: this.selectedDemandFrom
            , reason: this.selectedDemand
        };


        this.componentBusy = true;
        let _response: any;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).addDemand(_data);
        if (_es != null) {
            _es.subscribe(
                (data) => _response = data
                , (error) => {
                    this.close(null);
                    this.onApiError(error);
                }
                , () => {
                    this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.error = _response.error;
                        else
                            this.close(_response.data ? _response.data : true);
                });
        }
    }
}
