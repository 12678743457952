<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
        Please upload a document to indicate that contingency has been fulfilled, for e.g. loan approval or funding document from lender to indicate loan is approved or funded.
        <p>You may contact service provider you hired to provide you with such documentation.</p>
    </mat-card>
    <mat-card>
        <div class="app-flex-container app-2col">
            <mat-form-field>
                <mat-label class="placeholder">Contingency Detail</mat-label>
                <input matInput [(ngModel)]="media.title" disabled />
            </mat-form-field>
            <button matButton (click)="fulfilDoc.click()">Upload</button>
        </div>
        <input type="file" ng2FileSelect [uploader]="uploader" #fulfilDoc class="btn btn-primary" (change)="fileUploaded($event)" style="display:none" />
    </mat-card>
    <hr />
    <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
    <button mat-raised-button (click)="uploadDocument()" *ngIf="saveReady">Submit</button>
</div>
