<ng-template [ngIf]="showAsAssetPage === false" [ngIfElse]="asAsset">
    <div class="appContent">
        <h2 class="pageHeader" *ngIf="!managingAssets">
            How To
            <a class="link CTA small" (click)="gotoURL('help/glossary')">Real Estate Terminology</a>
            <span class="separator">|</span>
        </h2>
        <h2 class="pageHeader" *ngIf="managingAssets">
            <a (click)="refreshAssetsCache()">Manage Assets</a>
            <mat-form-field class="large float-right" style="font-size:medium;margin-top:-15px" *ngIf="currentSelectedGroup != null && currentSelectedGroup.id > 0" appearance="outline">
				<mat-label>New Asset for {{currentSelectedGroup.name}}</mat-label>
                <input matInput [(ngModel)]="this.assetName" style="width:Calc(100% - 24px)" maxlength="100" />
                <a (click)="addAsset()" title="Add new asset" *ngIf="!utils.isNullOrEmpty(assetName)"><i class="fa fa-plus-circle"></i></a>
            </mat-form-field>
        </h2>
        <div class="app-flex container flex-direction">
            <div class="app-flex divFirst big hideScrollBar vertical-rightborder">
                <mat-progress-bar mode="buffer" *ngIf="treeLoading"></mat-progress-bar>
                <mat-form-field class="full" *ngIf="managingAssets" appearance="outline">
                    <mat-label>Asset Type<i class="fa fa-question-circle-o warning-color" title="Asset Types"></i></mat-label>
                    <mat-select [(ngModel)]="assetType" (selectionChange)="onAssetTypeChange()">
                        <mat-option *ngFor="let at of assetTypes | keys" [value]="at.key" class="auto">{{at.value.addSpaceProperCase(at.value) }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-template [ngIf]="(this.gc && this.gc.mobileScreen === true)" [ngIfElse]="bigScreen">
                    <!-- TODO user ngx-treeview sample https://leovo2708.github.io/ngx-treeview/#/components -->
                </ng-template>
                <ng-template #bigScreen>
                    <mat-tree [dataSource]="helpData" [treeControl]="treeControl" *ngIf="!treeLoading">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                            <!-- use a disabled button to provide padding for tree leaf -->
                            <button mat-icon-button *ngIf="node.children && node.children.length > 0"></button>
                            <a class="link ellipsis {{node.id && selectedAsset && node.id == selectedAsset.id  ? 'selected' : ''}} grabbable" (click)="itemSelected(node, node.expandable)"
                               title="{{node.name + (managingAssets ? (' [' + node.id + ']') : '') }}" id="nodeID{{node.id}}"
                               [dndDraggable]="node" [dndEffectAllowed]="draggable.effectAllowed" [dndDisableIf]="!canEdit || node.children">
                                {{node.name}}
                                <i class="fa fa-times danger" *ngIf="managingAssets === true" (click)="removeNode(node, $event)"></i>
                            </a>
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding dndDropzone (dndDrop)="onParentChange($event, node)" (click)="itemSelected(node, true)">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            {{node.name}}
                        </mat-tree-node>
                    </mat-tree>
                </ng-template>
            </div>
            <div class="app-flex divNext withBig" id="divAssetEditor">
                <div class="first">
                    <app-help-search *ngIf="!managingAssets" (itemSelected)="itemSelected($event)" [values]="assets" [placeholder]="'Start search with your words here...'" [id]="'appHelp'" [allowNew]="true"
                                     [itemClass]="'full first'"></app-help-search>
                </div>
                <div *ngIf="this.selectedAsset != null && this.selectedAsset.id > 0" class="section">
                    <button *ngIf="!utils.isNullOrEmpty(this.selectedAsset.url) && !managingAssets" matButton color="primary" (click)="gotoURL(this.selectedAsset.url)" class="float-right" [disabled]="this.selectedAsset.securedByLogin && this.gc.loggedIn">Go To</button>
                    <h2 class="pageHeader" *ngIf="this.selectedAsset && this.selectedAsset.id > 0 && !canEdit">
                        {{this.selectedAsset.title}}
                    </h2>
                    <h2 class="pageHeader" *ngIf="canEdit && managingAssets">Asset Details</h2>
                    <div class="section app-flex-container app-2col" *ngIf="canEdit && this.assetType !== this.assetTypes.UnAssigned">
                        <mat-form-field appearance="outline">
                            <mat-label>Title</mat-label>
                            <input matInput [(ngModel)]="this.selectedAsset.title" placeholder="Title" (change)="setAssetDirty()" />
                        </mat-form-field>
                        <div>
                            <mat-checkbox [(ngModel)]="this.selectedAsset.hideinHelpTree" *ngIf="managingAssets" (change)="setAssetDirty()">Hide in Help Tree&nbsp;</mat-checkbox>
                            <mat-checkbox [(ngModel)]="this.selectedAsset.isStatic" *ngIf="managingAssets && this.assetType === this.assetTypes.Page" (change)="setAssetDirty()">Is Content Static</mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="showKeyword === true && canEdit">
                        <mat-form-field appearance="outline">
                            <mat-label>Keywords</mat-label>
                            <input matInput [(ngModel)]="this.selectedAsset.keywords" placeholder="Key Words  for Search" (change)="setAssetDirty()" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="canEdit && managingAssets && this.assetType === this.assetTypes.Page" class="app-flex-container app-3col">
                        <mat-checkbox [(ngModel)]="this.selectedAsset.secured" (change)="setAssetDirty()">Secured [Always on HTTPS]</mat-checkbox>
                        <mat-checkbox [(ngModel)]="this.selectedAsset.securedByLogin" (change)="setAssetDirty()">Secured [Only after Login]</mat-checkbox>
                        <mat-form-field appearance="outline">
                            <mat-label>URL</mat-label>
                            <input matInput [(ngModel)]="this.selectedAsset.url" placeholder="URL/Route" (change)="setAssetDirty()" maxlength="200" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="canEdit && managingAssets" class="app-flex-container app-3col">
                        <mat-checkbox *ngIf="this.assetType === this.assetTypes.Page" [(ngModel)]="this.selectedAsset.adEnabled" (change)="setAssetDirty()">Ad Enabled</mat-checkbox>
                        <mat-form-field *ngIf="this.selectedAsset.adEnabled === true" appearance="outline">
                            <mat-label>Ad Rating Level</mat-label>
                            <input matInput [(ngModel)]="this.selectedAsset.rateLevel" placeholder="Ad Rate Level" (change)="setAssetDirty()" maxlength="3" />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Status</mat-label>
                            <mat-label class="placeholder">Status<i class="fa fa-question-circle-o warning-color" title="Asset Status Types"></i></mat-label>
                            <mat-select [(ngModel)]="this.selectedAsset.status" (selectionChange)="setAssetDirty()">
                                <mat-option *ngFor="let at of assetStatuses | keys" [value]="at.key" class="auto">{{at.value.addSpaceProperCase(at.value) }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div style="padding:10px 0px" *ngIf="canEdit">
                        <mat-progress-bar mode="buffer" *ngIf="this.gc.componentBusy"></mat-progress-bar>
                        <div class="app-flex-container app-2col" style="max-width:250px" *ngIf="false">
                            <mat-form-field appearance="outline">
                                <mat-label>Source</mat-label>
                                <input matInput disabled [(ngModel)]="source" placeholder="Source" />
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Target</mat-label>
                                <mat-select aria-placeholder="Target(s)" placeholder="Target(s)" [(ngModel)]="target">
                                    <mat-option class="auto" *ngFor="let t of targets" [value]="t">{{t}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <mat-chip-set *ngIf="this.componentBusy === false && managingAssets">
                            <!-- *ngIf="!utils.isNullOrEmpty(source) && !utils.isNullOrEmpty(target) && !target.startsWith('--') && source != target" -->
                            <mat-chip (click)="publish()" secondary *ngIf="false">Publish Help Content</mat-chip>
                            <mat-chip (click)="saveEditorChanges()" *ngIf="helpDirty" primary>Save HTML/HELP Content</mat-chip>
                            <mat-chip (click)="itemSelected(this.selectedAsset.id)" *ngIf="helpDirty" class="float-right">Discard Changes</mat-chip>
                        </mat-chip-set>
                    </div>
                    <mat-tab-group *ngIf="canEdit" dynamicHeight>
                        <mat-tab label="Content" *ngIf="managingAssets && this.assetType !== this.assetTypes.UnAssigned && this.selectedAsset.isStatic === true">
                            <angular-editor id="contentEditor" name="contentEditor" #contentEditor [(ngModel)]="this.selectedAsset.content" (config)="editorConfig" (blur)="setHelpDirty()"></angular-editor>
                        </mat-tab>
                        <mat-tab label="Help Content" *ngIf="this.assetType !== this.assetTypes.UnAssigned">
                            <angular-editor id="helpContentEditor" name="helpContentEditor" #helpContentEditor [(ngModel)]="this.selectedAsset.helpContent" (config)="editorConfig" (blur)="setHelpDirty()"></angular-editor>
                        </mat-tab>
                        <mat-tab label="Tags" *ngIf="managingAssets && this.selectedAsset != null && this.selectedAsset.id > 0">
                            <ng-template mat-tab-label>
                                Tags&nbsp;<i class="fa fa-plus-circle green" (click)="addTag()" title="Add New Tag"></i>
                            </ng-template>
                            <ng-template [ngIf]="(this.tags && this.tags.length > 0)" [ngIfElse]="noTags">
                                <mat-card *ngFor="let tag of tags  | paginate: { itemsPerPage: tagsOnPage , currentPage: tagsPageNumber }; " style="padding:2px!important;margin-left:10px">
                                    <div class="app-flex-container app-2col-edit_B">
                                        <div>
                                            <input (blur)="setTagDirty(tag)" type="text" [(ngModel)]="tag.tagCode" class="app float-right" maxlength="100" placeholder="Tag Code"
                                                   [disabled]="(tag.id > 0  && tag.tagCode !== '') || (tag.id === 0 && tag.isDirty === false)" />
                                        </div>
                                        <div>
                                            <a class="fa fa-trash danger float-right" (click)="removeTag(tag)" *ngIf="tag.id > 0" style="margin-top:10px"></a>
                                            <input (blur)="setTagDirty(tag)" type="text" [(ngModel)]="tag.tagValue" class="app float-right" maxlength="2000" placeholder="Tag Value"
                                                   [disabled]="tag.id === 0 && tag.isDirty === false" (keydown.Space)="$event.stopPropagation();" style="width:Calc(100% - 24px)" />
                                        </div>
                                    </div>
                                </mat-card>
                                <div style="text-align:center" *ngIf="tags && tags.length > 0">
                                    <hr />
                                    <pagination-controls (pageChange)="tagsPageNumber = $event"></pagination-controls>
                                </div>
                            </ng-template>
                            <ng-template #noTags>
                                <h3>You have not defined any Tags.</h3>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                    <div *ngIf="!canEdit" class="large-padding">
                        <div hrefToRouterLink [innerHTML]="this.selectedAsset.helpContent | safeHtml"></div>
                    </div>
                    <div>
                        <section dndDropzone (dndDrop)="onDropToPrev($event)" *ngIf="assetType === assetTypes.Page">
                            <div *ngIf="canEdit || (this.selectedAsset && this.selectedAsset.previous && this.selectedAsset.previous.length > 0)" class="full">
                                <hr />
                                <label class="large bold" *ngIf="managingAssets === true">Previous Topic(s) : [Drag and Drop Items from Tree to Add]</label>
                                <label class="large bold" *ngIf="managingAssets === false && this.selectedAsset.previous.length > 0">Step(s) from where you could have reached here</label>
                                <br />
                                <span class="link help-help {{i%2 === 0 ? 'alt' : ''}}" *ngFor="let t of this.selectedAsset.previous; let i = index" (click)="itemSelected(t)" title="{{t.title}}">
                                    {{t.title}}
                                    <sup *ngIf="canEdit"><i class="fa fa-times danger" (click)="removePreviousTopic(t.id)"></i></sup>
                                </span>
                            </div>
                        </section>
                        <section dndDropzone (dndDrop)="onDropToNext($event)" *ngIf="assetType === assetTypes.Page || assetType === assetTypes.HelpContent">
                            <div *ngIf="canEdit || (this.selectedAsset && this.selectedAsset.next && this.selectedAsset.next.length > 0)" class="full">
                                <hr />
                                <label class="large bold" *ngIf="managingAssets === true">Related/Next Topic(s) : [Drag and Drop Items from Tree to Add]</label>
                                <label class="large bold" *ngIf="managingAssets === false && this.selectedAsset.next.length > 0 ">Related Topics/Next Step Recommendation(s)</label>
                                <br />
                                <span class="link help-help {{i%2 === 0 ? 'alt' : ''}}" *ngFor="let t of this.selectedAsset.next; let i = index" (click)="itemSelected(t)" title="{{t.title}}">
                                    {{t.title}}
                                    <sup *ngIf="canEdit"><i class="fa fa-times danger" (click)="removeNextTopic(t.id)"></i></sup>
                                </span>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #asAsset>
    <div class="appContent">
        <h2 class="pageHeader" *ngIf="this.selectedAsset && this.selectedAsset.id > 0">
            {{this.selectedAsset.title}}
        </h2>
        <div *ngIf="this.selectedAsset && this.selectedAsset.type === assetTypes.Page">
            <div hrefToRouterLink [innerHTML]="this.selectedAsset.content | safeHtml"></div>
        </div>
        <div *ngIf="this.selectedAsset && this.selectedAsset.type !== assetTypes.Page">
            <div hrefToRouterLink [innerHTML]="this.selectedAsset.helpContent | safeHtml"></div>
        </div>
        <div>
            <section>
                <div *ngIf="(this.selectedAsset && this.selectedAsset.previous && this.selectedAsset.previous.length > 0)" class="full">
                    <hr />
                    <label class="large bold">Something you have already might have completed : </label><br />
                    <span class="link help-help {{i%2 === 0 ? 'alt' : ''}}" *ngFor="let t of this.selectedAsset.previous; let i = index" (click)="itemSelected(t)" title="Click to review - {{t.title}}">
                        {{t.title}}
                    </span>
                </div>
            </section>
            <section>
                <div *ngIf="(this.selectedAsset && this.selectedAsset.next && this.selectedAsset.next.length > 0)" class="full">
                    <hr />
                    <label class="large bold">Things you might be interested in or need to know : </label><br />
                    <span class="link help-help {{i%2 === 0 ? 'alt' : ''}}" *ngFor="let t of this.selectedAsset.next; let i = index" (click)="itemSelected(t)" title="Click to review - {{t.title}}">
                        {{t.title}}
                    </span>
                </div>
            </section>
        </div>
    </div>
</ng-template>