import { Router, Route } from '@angular/router';
import { BaseService } from '../../common/service/base.service';

import { DataService } from '../../util/APICaller.component';
import { Code, CodeTranslation, CodeAttribute } from '../../models/admin/code.model';
import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SystemService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    refreshCache(options: any) {
        const data = options;
        return this.ds.APIPost('/admin/system/refreshCache', data, this.requestingModule);
    }

    getPublishingTargets() {
        const data = null;
        return this.ds.APIPost('/admin/system/publishingTargets', data, this.requestingModule);
    }

    publish(target: string, id: number = 0) {
        const data = { id , target};
        return this.ds.APIPost('/admin/system/publish', data, this.requestingModule);
    }

    //#region

    // Get State Categories
    getStateMachineCategories() {
        return this.ds.APIPost('/admin/system/stateMachineCategories', {}, this.requestingModule);
    }

    // Get States for a Category
    getStateMachineStates(categoryName: string ) {
        const data = { categoryName };
        return this.ds.APIPost('/admin/system/stateMachineStates', data, this.requestingModule);
    }

    // Get State Details, including Steps
    getStateMachineState(categoryName: string, id: number) {
        const data = { categoryName, id };
        return this.ds.APIPost('/admin/system/stateMachineState', data, this.requestingModule);
    }

    saveStateMachineState(oData: any) {
        return this.ds.APIPost('/admin/system/stateMachine/save', oData, this.requestingModule);
    }

    // addStep(id: number, newStepID: number) {
    //    const data = { id: id, newStepID: newStepID };
    //    return this.ds.APIPost('/admin/system/stateMachineAddStep', data, this.requestingModule);
    // }

    // removeStep(id: number, stepID: number) {
    //    const data = { id: id, stepID: stepID };
    //    return this.ds.APIPost('/admin/system/stateMachineRemoveStep', data, this.requestingModule);
    // }

    //#endregion

    getLanguages() {
        return this.ds.APIPost('/languages', {}, this.requestingModule);
    }

    //#region Codes
    getCodeCategories(iParentID: number = 0) {
        const _data = { id: iParentID };
        if (iParentID  == null || iParentID <= 0)
            return this.ds.APIPost('/code/categories', {}, this.requestingModule);
        else
            return this.ds.APIPost('/admin/code/parentAndItsSiblings', _data, this.requestingModule);
    }

    getCodes(id: number = 0) {
        return this.ds.APIPost('/admin/code/codes', { id }, this.requestingModule);
    }

    getCode(id: number = 0, category: string = '', code: string = '') {
        return this.ds.APIPost('/admin/code/code', { id, category, code }, this.requestingModule);
    }
    saveCode(code: Code) {
        return this.ds.APIPost('/admin/code/save', code, this.requestingModule);
    }
    saveCodeTranslation(code: CodeTranslation) {
        return this.ds.APIPost('/admin/code/translation/save', code, this.requestingModule);
    }
    saveCodeAttribute(code: CodeAttribute) {
        return this.ds.APIPost('/admin/code/attribute/save', code, this.requestingModule);
    }
    //#endregion
}
