<app-listing-taxes-and-fees *ngIf="!complianceItemsOnly" #listingTFE></app-listing-taxes-and-fees>
<mat-card>
    <ng-template [ngIf]="(this.complianceItems && this.complianceItems.length > 0)" [ngIfElse]="noComplianceItems">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let compliance of complianceItems; let i = index" hideToggle [expanded]="false" class="{{i%2 === 0 ? 'alt' : ''}}">
                <mat-expansion-panel-header>
                    <mat-panel-title appClickStopPropagation><span>{{compliance.description}}</span></mat-panel-title>
                    <mat-panel-description appClickStopPropagation style="display:initial;flex-grow:1">
                        <span *ngIf="readonly">{{compliance.paidBy}}</span>
                        <mat-form-field *ngIf="!readonly" class="normal float-right" style="padding-top:10px">
                            <mat-select appClickStopPropagation [(ngModel)]="compliance.paidBy" (selectionChange)="onComplianceChange(compliance.id, $event.value)" [disabled]="!currentOffer.canSubmit || saving">
                                <mat-option *ngFor="let item of toBePaidBy | keys" [value]="item.key">{{item.key == 0 ? 'Select Paid By' : item.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-action-row *ngIf="readOnly">
                    <a *ngIf='compliance.canComplete && !compliance.completed' (click)="complete(compliance.id)">Complete <i class="fa fa-check"></i></a><span *ngIf='compliance.completed'>Complete/Accepted &nbsp;</span>
                    <a *ngIf='compliance.canVerify && compliance.completed && !compliance.verified' (click)="verify(compliance.id)">Verify<i class="fa fa-check"></i></a><span *ngIf='compliance.verified'>Verified/Acknowledged &nbsp;</span>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
    <ng-template #noComplianceItems>
        <h3>
            There are no local Compliance Items required or has not been Setup on {{this.myBRAND}}.
        </h3>
    </ng-template>
</mat-card>
