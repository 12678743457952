import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { GlobalComponent } from '../global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as cloneDeep from 'lodash/cloneDeep';
import { escrow, escrowContingency, downloadableContent } from '../models';

import { EscrowService } from './escrow.service';

import { EscrowBaseComponent } from './escrow.base.component';
import { UploadContingencyDocumentDialog } from './dialog/uploadContingencyDocument.dialog';
import { Utils } from '../common/utils';


@Component({
    selector: 'app-escrow-contingency',
    templateUrl: './escrowContingencies.component.html'
})
export class EscrowContingencyComponent extends EscrowBaseComponent {

    @ViewChild('contingencyTable') table;
    contingencies: escrowContingency[] = null;

    contingenciesOnPage: number = 10;
    contingenciesPageNumber: number = 1;

    constructor(ds: DataService, r: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('escrowContingency', ds, r, g);

        this.gc.roleChanged.subscribe((_r) => {
            this.initMe();
            this.bindContingencies();
        });
        this.gc.escrowChanged.subscribe((_r) => {
            this.initMe();
            this.bindContingencies();
        });
    }

    bindContingencies() {

        const _id = this.currentEscrow ? this.currentEscrow.id : 0;
        if (_id === 0) {
            this.initMe();
        }

        if (this.currentEscrow.id !== this.escrowID || this.contingencies == null || (this.currentEscrow.id === this.escrowID && this.currentEscrow.contingencies == null)) {

            let _response;
            const _s = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getContingencies(this.escrowID);
            if (_s) {
                this.componentBusy = true;
                _s.subscribe((r) => _response = r
                    , (error) => { this.componentBusy = false; }
                    , () => {
                        this.componentBusy = false;
                        if (_response && _response.data) {
                            this.setCurrentEscrow(Utils.castTo(_response.data, new escrow()));
                            this.contingencies = _response.data.contingencies.map((c) => { return Utils.castTo(c, new escrowContingency()); });
                        }
                    });
            }
        } else if (this.currentEscrow.id === this.escrowID && this.currentEscrow.contingencies)
            this.contingencies = this.currentEscrow.contingencies.map((c) => { return Utils.castTo(c, new escrowContingency()); });
    }

    changeStatus(action$) {
        let _actionResponse;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).changeContingencyStatus(this.escrowID, parseInt(action$.argument), action$.name);
        if (_es != null) {
            _es.subscribe((r) => _actionResponse = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_actionResponse) {
                        if (!Utils.isNullOrEmpty(_actionResponse.error))
                            this.showError(_actionResponse.error);
                        else {
                            if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                            this.showMessage(_actionResponse.message);

                            if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                                this.gotoURL(_actionResponse.redirectURL);
                            else {
                                const _contingency = this.contingencies.find((d) => d.id === parseInt(action$.argument));
                                if (_contingency != null) {
                                    _contingency.UpdateAfterChangeStatus(_actionResponse.data);
                                }
                            }
                        }
                    }
                }
            );
        }
    }

    sendNotice(id: number) {
        let _actionResponse;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).sendNoticeToPerformContingency(this.escrowID, id);
        if (_es != null) {
            _es.subscribe((r) => _actionResponse = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_actionResponse) {
                        if (!Utils.isNullOrEmpty(_actionResponse.error))
                            this.showError(_actionResponse.error);
                        else {
                            if (Utils.isNullOrEmpty(_actionResponse.message))
                                _actionResponse.message = 'Notice sent to responsible parties.';
                            this.showMessage(_actionResponse.message);
                        }
                    }
                }
            );
        }
    }

    showInstructions(id: number) {
        let _actionResponse;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).contingencyInstructions(this.escrowID, id);
        if (_es != null) {
            _es.subscribe((r) => _actionResponse = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_actionResponse) {
                        if (!Utils.isNullOrEmpty(_actionResponse.error))
                            this.showError(_actionResponse.error);
                        else {
                            const _doc: downloadableContent = _actionResponse.data;
                            this.OpenDownloadableDoc(_doc.content, _doc.name, _doc.mime);
                        }
                    }
                }
            );
        }
    }

    showFulfilledDoc(id: number) {
        let _actionResponse;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).contingencyFulfilmentDocument(this.escrowID, id);
        if (_es != null) {
            _es.subscribe((r) => _actionResponse = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_actionResponse) {
                        if (!Utils.isNullOrEmpty(_actionResponse.error))
                            this.showError(_actionResponse.error);
                        else {
                            const _doc: downloadableContent = _actionResponse.data;
                            this.OpenDownloadableDoc(_doc.content, _doc.name, _doc.mime);
                        }
                    }
                }
            );
        }
    }

    uploadContigencyDoc(id: number) {
        const dialogRef = this.dialog.open(UploadContingencyDocumentDialog, {
            data: {
                id: id
                , escrowID: this.escrowID
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.contingencies = result.map((c) => { return Utils.castTo(c, new escrowContingency()); });
                this.showMessage('Document sent to Escrow');
            }
        });
    }
}
