import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, Output, OnDestroy } from '@angular/core';

import { DataService } from '../util/APICaller.component';

import { Router } from '@angular/router';

import { item, TaxFeeNegotiation, ToBePaidBy, assessment } from '../models';

import { ListingService } from './listing.service';
import { OfferService } from '../offer/offer.service';

import { ListingBaseComponent } from './listing.base.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddAssessmentDialog } from './dialogs';
import { GlobalComponent } from '../global.component';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-listing-taxes-and-fees',
    templateUrl: './taxesAndFees.component.html'
})
export class ListingTaxesAndFeesComponent extends ListingBaseComponent implements OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    taxesAndFees: any = [];
    toBePaidBy = ToBePaidBy;
    step: number = 0;
    offerID: number = 0;
    buyerOrSeller: boolean = false;

    private _lcs: Subscription;
    constructor(ds: DataService, rs: Router, private dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingTaxesAndFees', ds, rs, gc);
        this.messages = {
            emptyMessage: 'Currently no information is available or no taxes details were provided by seller or escrow has not provide the information yet.',
        };

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindTaxes();
        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    bindTaxes() {
        if (this.listingID === 0) return;
        let _response;
        this.taxesAndFees = [];
        this.buyerOrSeller = false;
        if (this.offerID > 0) {
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getTaxesAndFees(this.listingID).subscribe((r) => _response = r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && _response.data) {
                        this.buyerOrSeller = _response.data.isBuyerOrSeller;
                        this.taxesAndFees = _response.data.fees.map((c) => (new TaxFeeNegotiation()).castToMe(c) );
                    }
                });
        } else {

            if (this.currentListing && this.currentListing.id > 0 && this.currentListing.id === this.listingID) {
                this.readonly = !this.currentListing.isEditable('fees');
                if (this.currentListing.fees)
                    this.taxesAndFees = this.currentListing.fees.map((c) => (new TaxFeeNegotiation()).castToMe(c));
                else
                    this.taxesAndFees = [];
            } else {
                (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getTaxesAndFees(this.listingID).subscribe((r) => _response = r
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response && _response.data) {
                            this.buyerOrSeller = _response.data.isBuyerOrSeller;

                            const _listingPresent = this.currentListing && this.currentListing.id > 0;
                            if (this.noParent && !_listingPresent)
                                this.setCurrentListing(_response.data);
                            else if (_response.data.fees)
                                this.currentListing.fees = _response.data.fees;

                            this.taxesAndFees = _response.data.fees.map((c) => (new TaxFeeNegotiation()).castToMe(c));
                        }
                    });
            }
        }
    }

    addAssessment() {
        const dialogRef = this.dialog.open(AddAssessmentDialog, {
            data: {
                id: this.listingID
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.taxesAndFees.push((new TaxFeeNegotiation()).castToMe(result));
                this.taxesAndFees = [...this.taxesAndFees];
                this.currentListing.fees.push((new TaxFeeNegotiation()).castToMe(result));
            }
        });
    }

    changePaidBy(iAssesmentID, paidBy: ToBePaidBy) {
        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).changeAssesmentPaidBy(this.listingID, iAssesmentID, paidBy).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response && _response.data) {
                    this.taxesAndFees.forEach((a) => { if (a.id === iAssesmentID) { a.paidBy = paidBy; } });
                    this.currentListing.fees.forEach((a) => { if (a.id === iAssesmentID) { a.paidBy = paidBy; } });
                }
            });
    }
}
