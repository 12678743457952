import { Component, OnInit, AfterViewInit, Inject} from '@angular/core';
import { DataService } from './util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { AssetService } from './common/service';
import { asset } from './models';
import { Router } from '@angular/router';

import * as cloneDeep from 'lodash/cloneDeep';
import { navIA } from './models/constants';
import { Utils } from './common/utils';

@Component({
    selector: 'app-static-content'
    , templateUrl: './static.app.content.component.html'
})
export class StaticAppComponent extends BaseComponent implements AfterViewInit {

    content: string = '';
    editMode: boolean = false;
    asset: asset;

    lblPageTitle: string = 'Page Title';

    canEdit: boolean = false;
    private assetID: number = 0;

    constructor(private ds: DataService, r: Router, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('appContentEditor', r, gc);
        this.canEdit = document.getElementById(navIA) != null;
    }

    ngAfterViewInit() {
        if (this.assetID !== this.gc.currentPageAssetID) {
            let _result: any;
            (new AssetService(this.ds, this.route, this.myResourceCategory)).getAssetContent(this.gc.currentPageAssetID, true).subscribe((data) => { _result = data; }, (error) => { }
                , () => {
                    if (_result.data != null)
                        if (this.canEdit) {
                            this.asset = (new asset()).castToMe(_result.data);
                            this.content = cloneDeep(this.asset.content);
                        } else
                            this.content = _result.data.content ?? _result.data;
                    if (Utils.isNullOrEmpty(this.content.trim())) {
                        if (this.gc.loggedIn)
                            this.GotoWelcome();
                        else
                            this.GotoHome();
                    }
                });
            this.assetID = this.gc.currentPageAssetID;
        }
    }

    publish() {
        if (this.canEdit) {
            // Server will validate again.
            let _response;
            (new AssetService(this.ds, this.route, this.myResourceCategory)).publishAssetContent(this.assetID).subscribe(
                (data) => { _response = data; }
                , (error) => { }
                , () => {
                    this.editMode = false;
                    if (_response && Utils.isNullOrEmpty(_response.error))
                        this.content = cloneDeep(this.asset.content);
                }
            );
        } else
            this.showError('Unauthorized Access');
    }

    save() {
        if (this.canEdit) {
            // Server will validate again.
            let _response;
            (new AssetService(this.ds, this.route, this.myResourceCategory)).saveAssetContent(this.assetID, this.asset.title, this.asset.content).subscribe(
                (data) => { _response = data; }
                , (error) => { }
                , () => {
                    this.editMode = false;
                    if (_response && Utils.isNullOrEmpty(_response.error))
                        this.content = cloneDeep(this.asset.content);
                }
            );
        } else
            this.showError('Unauthorized Access');
    }

    discard() {
        this.editMode = false;
        this.asset.content = cloneDeep(this.content);
    }

}
