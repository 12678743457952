import { Component, ViewChild, OnInit, AfterViewInit, Inject, Input, Output, OnDestroy } from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as $ from 'jquery';

import { HTMLDialog } from '../../common/dialogs';
import { ListingService } from '../listing.service';

import { ListingBaseComponent } from '../listing.base.component';
import { CodeService } from 'src/app/common/service';
import { GlobalComponent } from '../../global.component';
import { AddressComponent } from '../../common';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../common/utils';
import { Address } from '../../models';
@Component({
    selector: 'app-listing-edit-general',
    templateUrl: './listingGeneral.component.html'
})
export class ListingGeneralComponent extends ListingBaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    @ViewChild('propertyAddress', { static: true }) propertyAddress: AddressComponent;
    propertyTypes: any = [];
    private _lcs: Subscription;
    constructor(dataservice: DataService, r: Router, private dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingGeneral', dataservice, r, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindData();
        });
    }

    ngOnInit() {
        let response;
        (new CodeService(this.dataservice, this.route, this.myResourceCategory)).getPropertyTypes().subscribe(
            (data) => response = data,
            (error) => { this.onApiError(error); },
            () => {
                if (response && response.data) {
                    this.propertyTypes = response.data;
                }
            }
        );
    }

    ngAfterViewInit() {
        if (this.propertyAddress) {
            this.bindData();
            this.propertyAddress.addressChanged.subscribe((value) => {
                this.addressChanged(value);
            });
        }
    }
    ngOnDestroy() {
        this.exitingEdit();
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    expiryDateChanged(value) {
        if (value && moment(value).isValid) {
                this.currentListing.expiryDate = moment(value).toDate();
        }
        this.currentListing.expiryDate = this.Utils.dateTimeMinValue;
        this.onListingChange();
    }

    // Show Listing Value
    showValue() {
        let response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getEstimatedValue(this.currentListing.id).subscribe(
            (data) => response = data,
            (error) => { this.onApiError(error); },
            () => {
                if (response && response.data) {
                    const _html = 'Estimated Value of your property is ' + this.FormatNumberToCurrency(response.data) +
                        '<br/><br/><div class=\'float-right\'><a href=\'//www.zillow.com\'><img src=\'/assets/images/thirdParty/Zillowlogo_200x50.gif\' alt=\'zillow logo\'/></a></div>';
                   this.dialog.open(HTMLDialog, { data: { html: _html } });
                }
            }
        );
    }

    // Show Comparative Listings
    showComps() {
        let response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getComparativeProperties(this.currentListing.id).subscribe(
            (data) => response = data,
            (error) => { this.onApiError(error); },
            () => {
                if (response && response.data) {
                    let _p = JSON.parse(response.data);
                    if (_p) {
                        _p = _p.map((x) => { return { Address: x.Address, Value: x.Value }; });
                        const _t = this.makeTable(_p); // $.makeTable(JSON.parse(response.data));
                        // $(_t).html()
                        const _html = '<div style=\'max-height:600px;overflow:auto\'>' + _t +
                            '</div><br/><br/><div class=\'float-right\'><a href=\'//www.zillow.com\'><img src=\'/assets/images/thirdParty/Zillowlogo_200x50.gif\' alt=\'zillow logo\'/></a></div>';
                        this.dialog.open(HTMLDialog, { data: { html: _html } });
                        return;
                    }
                }
                this.showMessage('Unable to provide requested details, for the property address, at this time. Please verify your address and try later.');
            }
        );
    }

    addressChanged(value) {
        if (this.currentListing) {

            if (value && value.address)
                this.currentListing.address = Utils.castTo(value.address, new Address());
            else if (value)
                this.currentListing.address = Utils.castTo(value, new Address());

            this.onListingChange();
        }
    }

    private bindData() {
        if (this.propertyAddress && this.currentListing && this.currentListing.id > 0) {
            this.propertyAddress.Address = this.currentListing.address;
        }
    }

    private makeTable(data) {
        // $.getJSON(data, function (data) {
        let tblBody = ''; let tblHeader = '';
        let oddEven = false;
        const _header = data[0];
        $.each(_header, (k, v) => {
            tblHeader += '<th>' + k + '</th>';
        });
        tblHeader = '<thead>' + tblHeader + '</thead>';

        $.each(data, function () {
            let tblRow = '';
            $.each(this, (k, v) => {
                tblRow += '<td>' + v + '</td>';
            });
            tblBody += '<tr class=\'' + (oddEven ? 'odd' : 'even') + '\'>' + tblRow + '</tr>';
            oddEven = !oddEven;
        });
        return '<table>' + tblHeader + '<tbody>' + tblBody + '</tbody></table>';
        // });
    }
}
