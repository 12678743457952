<div mat-dialog-title>
	<h4>{{dialogHeader}}</h4>
	<a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
	<mat-progress-bar mode="buffer" *ngIf="componentBusy === true"></mat-progress-bar>
	<div class="section">
		<ng-template [ngIf]="(this.bids && this.bids.length > 0)" [ngIfElse]="noBids">
			<div>
				<label class="caption">Property Address</label><label class="data-value auto">{{this.propertyAddress ? ( this.propertyAddress.StreetName ): '' }}</label>
				<br />
				<label class="caption">Service Type</label><label class="data-value auto">{{this.serviceType.description}}</label>
			</div>
			<mat-accordion>
				<mat-expansion-panel *ngFor="let bid of bids  | paginate: { itemsPerPage: itemsPerPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<span [ngClass]="{'danger' : !bid.isOpen}" title="{{(bid.isOpen === true ? '' : 'Bidding Closed') + '[' + bid.id + ']'}}">{{bid.serviceProvider ? bid.serviceProvider : ''}}</span>
						</mat-panel-title>
						<mat-panel-description style="display:initial">
							{{this.FormatNumberToCurrency(bid.price)}}<span *ngIf="bid.isEstimated"> *</span>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="section">
						<mat-form-field *ngIf="bid.options && bid.options.length > 0">
							<mat-select placeholder="Selected Options">
								<mat-option *ngFor="let o of bid.options" [value]="o.id">{{o.name}}</mat-option>
							</mat-select>
						</mat-form-field>
						<div class="section">
							<label class="caption">Expires</label><label class="data-value">{{this.DisplayDate(bid.expiresOn, 'Not Provided')}}</label>
						</div>
						<div>
							<div><label class="caption bold">{{'System.CurrentStatus' | translateTag}}</label><label class="data-value">{{bid.statusDescription}}</label></div>
							<app-next-status-actions [actions]="bid.nextSteps" (itemSelected)="changeStatus($event)"></app-next-status-actions>
						</div>
					</div>
					<mat-action-row *ngIf="bids.length > 1">
						<button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && bids.length > 1">Previous</button>
						<button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < bids.length && bids.length > 1">Next</button>
					</mat-action-row>
				</mat-expansion-panel>
			</mat-accordion>
			<div style="text-align:center" *ngIf="bids.length > itemsPerPage">
				<hr />
				<pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
			</div>
		</ng-template>
		<ng-template #noBids>
			<mat-card class="section" *ngIf="componentBusy === false && (bids == null || bids.length === 0) ">
				There are no {{forRFQs ? 'requests for quote' : 'bids'}} for selected Work Order
			</mat-card>
		</ng-template>
	</div>
	<mat-error *ngIf="(error ?? '') != ''">Failed changing status [{{error}}]</mat-error>
</div>