import {
    AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Optional, OnDestroy
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appInputMaxLength]'
})
export class InputMaxLengthDirective implements OnInit, AfterViewInit, OnDestroy {

    @Input() appInputMaxLength: number = 0;
    @Input() appInputShowCount: boolean = false;
    @Input() ngModel: NgModel;
    private div: HTMLDivElement | undefined;
    private destroyed$ = new Subject();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    @HostListener('input', ['$event']) onChange(event) {
        if (!this.ngModel) {
            this.update(event.target.value.length);
        }
    }

    ngOnInit() {
        this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this.appInputMaxLength.toString());
        if (this.ngModel != null && this.ngModel.valueChanges != null) {
            this.ngModel.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
                if (value !== undefined)
                    this.update(value?.length);
            });
        }
    }

    ngAfterViewInit() {
        if (this.appInputShowCount) {
            this.div = this.renderer.createElement('span');
            if (this.div != null) {
                this.div.classList.add('count');
                this.div.classList.add('float-right');
                const _el = (this.el.nativeElement as HTMLElement);
                const _w = _el.style.getPropertyValue('width');
                if (_w != null && _w.trim() !== '') {
                    _el.style.removeProperty('width');
                    if (_w.trim().endsWith('%'))
                        _el.style.setProperty('width', 'Calc(' + _w.trim() + ' - 45px)');
                    else if (_w.trim().endsWith('px'))
                        _el.style.setProperty('width', (parseInt(_w.trim().replace('px', '')) - 45) + 'px)');
                } else {
                    _el.style.setProperty('width', 'Calc(100% - 45px)');
                }
                this.renderer.insertBefore(this.el.nativeElement.parentNode, this.div, this.el.nativeElement.nextSibling);
                this.update(this.el.nativeElement.value.length);
            }
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        if (this.div) {
            this.div.remove();
        }
    }

    private update(length: number) {
        if (this.div != null && this.appInputShowCount === true)
            this.renderer.setProperty(this.div, 'innerText', `${length}/${this.appInputMaxLength}`);
    }
}
