import { Component, Inject } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { escrowTax, Role } from '../../models';
import { EscrowService } from '../escrow.service';

import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-new-escrow-tax-dialog',
    templateUrl: './newEscrowTax.dialog.html'
})
export class NewEscrowTaxDialog extends BaseDialog {
    dialogHeader: string = 'Add Additional Tax';
    newTax: escrowTax = new escrowTax();
    escrowID: number = 0;
    roles: any = [];
    saving: boolean = false;
    error: string = '';
    constructor(dataservice: DataService, r: Router, public dialogRef: MatDialogRef<NewEscrowTaxDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('newEscrowTaxDialog', dataservice, r, dialogRef, data);

        if (this.data) {
            if (this.data.id) this.escrowID = this.data.id;
            if (this.data.roles) this.roles = this.data.roles;
            if (this.data.header) this.dialogHeader = this.data.header;
        }
    }

    get saveReady() {
        return this.newTax.amount > 0 && !Utils.isNullOrEmpty(this.newTax.taxAuthority) && (this.newTax.paidBy !== Role.NotSet || this.newTax.paidByServiceProviderID > 0);
    }

    close(data) {
        this.dialogRef.close(data);
    }

    roleChanged(value: string) {
        if (value) {
            const _values = value.split(':');
            if (_values.length > 1 && parseInt(_values[0]) > 0) {
                this.newTax.paidBy = parseInt(_values[0]);
                this.newTax.paidByServiceProviderID = 0;
            } else {
                this.newTax.paidBy = Role.NotSet;
                this.newTax.paidByServiceProviderID = parseInt(_values[1]);
            }
        } else
            this.newTax.paidBy = Role.NotSet;
    }

    onSubmit() {
        this.saving = true;
        let _response;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).addTax(this.escrowID, this.newTax);
        if (_es != null) {
            _es.subscribe((r) => _response = r
                , (error) => { this.saving = false; }
                , () => {
                    this.saving = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.error = _response.error;
                        else if (_response.data)
                            this.close(_response.data);
                        else
                            this.close(null);
                });
        }
    }
}
