import { Component, ChangeDetectorRef, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import * as moment from 'moment';

import { item } from '../../models/appCommon.models';
import { CodeService } from '../../common/service/code.service';
import { SPProductDetail } from '../../models/providers/serviceProvider.model';
import { ServiceProviderService } from '../serviceprovider.service';
import { appEnvironment } from '../../../environments/environment';
import { DatePickerComponent } from '../../common/datepicker.component';
import { Utils } from '../../common/utils';


@Component({
    selector: 'app-add-detail-item-dialog',
    templateUrl: './addDetailItem.dialog.html'
})
export class AddDetailItemDialog extends BaseComponent implements AfterViewInit {

    manageVersions: boolean = false;
    allowSubject: boolean = false;
    versionSubject: string = null;
    form: UntypedFormGroup;
    error: string;
    public detailItem: SPProductDetail = new SPProductDetail();
    dialogHeader: string = 'Product Rate';

    roles: any = [];
    workOrderStatuses: any = [];
    saving: boolean = false;
    isLoanProduct: boolean = false;
    @ViewChild('txtStartDate') startDate: DatePickerComponent;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent)  gc: GlobalComponent, private dialog: MatDialog
        , public dialogRef: MatDialogRef<AddDetailItemDialog>, fb: UntypedFormBuilder, private cdref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addDetailItemDialog', r);

        this.form = fb.group({ response: ['', [Validators.required]] });

        if (this.data) {
            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Detail Item for ' + this.data.header;
            }
            this.detailItem = Utils.castTo(this.data, new SPProductDetail());
            if (this.data.versionSubject) this.versionSubject = this.data.versionSubject;
            if (this.data.roles)
                this.roles = this.data.roles;
            else {
                let _roleResponse;
                (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getRoles().subscribe((_data) => { _roleResponse = _data; }, (error) => { this.onApiError(error); }, () => {
                    if (_roleResponse && _roleResponse.data) {
                        this.roles = _roleResponse.data;
                        this.roles.splice(0, 0, new item(0, '-- Select/Change --'));
                    }
                });
            }
            if (this.data.workOrderStatuses)
                this.workOrderStatuses = this.data.workOrderStatuses;
            else {

                let _woSTResponse;
                (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getWorkOrderStatuses().subscribe((_data) => { _woSTResponse = _data; }, (error) => { this.onApiError(error); }, () => {
                    if (_woSTResponse && _woSTResponse.data) {
                        this.workOrderStatuses = _woSTResponse.data;
                        this.workOrderStatuses.splice(0, 0, new item(0, '-- Select/Change --'));
                    }
                });
            }
            this.isLoanProduct = this.detailItem.productID === appEnvironment.lenderServiceTypeID;
            if (data.manageVersions) this.manageVersions = data.manageVersions;
            if (data.allowSubject) this.allowSubject = data.allowSubject;
        }

    }

    get isValid(): boolean {
        if (this.startDate)
            return moment(this.startDate.value).isSameOrAfter(this.today);
        else
            return true;
    }

    ngAfterViewInit() {
        if (this.startDate) this.startDate.minDate = this.startDate.value = this.detailItem.startDate;
        this.cdref.detectChanges();
    }

    startDateChanged(value) {
        if (value && moment(value).isValid)
            this.detailItem.startDate = value;
    }

    close(oResponse?: any) { this.dialogRef.close(oResponse); }
    onClosed(value: boolean) {
        this.close();
    }

    onSubmit() {

        if (this.startDate) {
            this.detailItem.startDate = this.startDate.value;
            this.detailItem.startDate.setHours(0, 0, 0, 0); // Trim Time
        }

        if (this.versionSubject && Utils.isNullOrEmpty(this.detailItem.subject))
            this.detailItem.subject = this.versionSubject;

        this.detailItem['serviceProviderID'] = this.user.details.CompanyID;
        this.saving = true;
        let response: any;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).addDetailItem(this.detailItem).subscribe(
            (data) => response = data
            , (error) => { this.saving = false; this.close(null); this.onApiError(error); }
            , () => {
                this.saving = false;
                if (response) {
                    if (Utils.isNullOrEmpty(response.error)) {
                        if (response.data)
                            this.close(response.data);
                        else
                            this.close(this.detailItem);
                    } else
                        this.error = response.error;
                }
            }
        );
    }
}
