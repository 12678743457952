import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DocumentService } from './documents/document.service';

import { HTMLDialog } from '../common/dialogs/showHTML.dialog';
import { Utils } from './utils';

@Component({
    selector: 'app-view-document-link',
    template: `
<a (click)='viewDoc()' title='{{title}}' *ngIf='documentID > 0' class='link {{class}}'><span *ngIf='showLabel'>{{label}}</span><i class='fa fa-eye' *ngIf='showIcon'></i></a>
<mat-progress-spinner mode="buffer" color="primary" diameter="12" style="display:inline-block" *ngIf="this.componentBusy === true"></mat-progress-spinner>
`
})
// tslint:disable-next-line: component-class-suffix
export class ViewDocumentLink extends BaseComponent implements OnInit {
    @Input() label: string = 'view';
    @Input() title: string = '';
    @Input() class: string = '';
    @Input() showIcon: boolean = false;
    @Input() showLabel: boolean = true;

    @Input() documentID: number = 0;
    constructor(private ds: DataService, private rs: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog) {
        super('viewDocumentLink', rs, g);
    }

    ngOnInit() { }

    viewDoc() {
        if (this.componentBusy === true) return;
        let _response;
        this.componentBusy = true;
        (new DocumentService(this.ds, this.rs)).viewDocument(this.documentID).subscribe(
            (data) => _response = data
            , (error) => {
                this.componentBusy = false;
                this.onApiError(error);
            }
            , () => {
                this.componentBusy = false;
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        if (_response.data) {
                            if (_response.data.isUploadedContent)
                                this.OpenDownloadableDoc(_response.data, Utils.isNullOrEmpty(this.label) ? 'document' : this.label.replace(' ' , ''), 'pdf');
                            else {
                                const dialogRef = this.dialog.open(HTMLDialog, {data: {html: _response.data.content}});
                                dialogRef.disableClose = true;
                            }
                        }
                    }
            }
        );
    }
}
