import { Component, Inject, ViewChild, ViewChildren, QueryList, EventEmitter, OnInit, Output, Input, ViewContainerRef, AfterViewInit, OnChanges } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';

import { item } from '../models/appCommon.models';
import { Address, AddressType } from '../models/address.model';
import { ServiceProviderEntity } from '../models/providers/serviceProvider.model';

import { ServiceProviderService } from './serviceprovider.service';
import { CodeService } from '../common/service/code.service';

import { AddressComponent } from '../common/address.component';
import { AutoCompleteComponent } from '../common';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatInput} from '@angular/material/input';
import { appEnvironment } from '../../environments/environment';
import { MatSelect} from '@angular/material/select';
import { navIA } from '../models/constants';
import { Utils, rolePosition } from '../common/utils';

@Component({
    selector: 'app-service-provider-profile',
    templateUrl: './profile.component.html'
})
export class ServiceProviderProfileComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {

    selectedAddressIndex: number = null;
    serviceProvider: ServiceProviderEntity = null;
    companies: item[] = [];
    taxEntities: item[] = [];
    css: string = '';
    saving: boolean = false;
    isAddressIncomplete: boolean = false;
    isCMDirty: boolean = false;
    canAddNewAddress: boolean = false;
    editable: boolean = false;

    @ViewChildren(AddressComponent) addressList: QueryList<AddressComponent>;
    @ViewChild('autoCompleteSPName') autoCompleteSPName: AutoCompleteComponent;
    @ViewChildren(MatInput) inputs: QueryList<MatInput>;
    @ViewChildren(MatSelect) selects: QueryList<MatSelect>;

    @Output() profileSaved: EventEmitter<number> = new EventEmitter<number>();
    @Input() @Output() editingMyCompany: boolean = true;
    @Input() @Output() allowNew: boolean = false;
    @Input() allowUpdate: boolean = false;
    @Input() @Output() captureTax: boolean = true;
    @Input() @Output() captureWebInfo: boolean = true;
    @Input() @Output() allowSave: boolean = true;
    @Input() @Output() allowMultipleAddresses: boolean = true;
    @Input() @Output() showHeader: boolean = true;
    @Input() @Output() forLookup: boolean = false;

    private _textChangeSub: Subscription = null;
    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('pgCompanyProfile', r, g);

        let taxEntitiesResponse: any;
        (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getTaxEntities().subscribe(
            (data) => { taxEntitiesResponse = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (taxEntitiesResponse && taxEntitiesResponse.data)
                    this.taxEntities = taxEntitiesResponse.data.map(c => { return Utils.castTo(c, new item()); });
            });
    }

    ngOnChanges() {
        this.isCMDirty =  this.serviceProvider && (this.serviceProvider.isDirty || this.serviceProvider.Addresses.find((a) => a.isDirty) != null);
    }

    ngOnInit() {

        if (this.forLookup === true)
            this.LoadCompanies();

        if (this.showHeader) this.css = 'appContent';
        if (this.editingMyCompany) {
            this.bindSP(this.user.details.CompanyID);
        } else {
            this.newAddress();
        }
    }

    ngAfterViewInit() {
        this.setAutoCompleteSP();
        setTimeout(() => { this.setEditable(); }, appEnvironment.delayedNGInit);
    }

    compareIds(o1: any, o2: any): boolean {
        return o1 && o2 && o1 === o2;
    }

    newAddress() {
        if (this.serviceProvider == null) this.serviceProvider = new ServiceProviderEntity();
        this.setCanAddNewAddress();
        this.selectedAddressIndex = this.serviceProvider.Addresses.length - 1;
        this.setEditable();
    }

    onAddressTabChanged($event) {
        if ($event)
            this.selectedAddressIndex = $event.index;
        else
            this.selectedAddressIndex = this.serviceProvider.Addresses.length;
    }

    onAddressAdded(oAddress) {
        if (oAddress && this.addressList) {
            if (oAddress.AddressType && oAddress.AddressType === AddressType.NotSet)
                oAddress.AddressType = AddressType.Physical;
            this.addressList.reset([...this.addressList.toArray(), oAddress]);
        }
    }

    undelete(index) {
        this.serviceProvider.Addresses[index].Activate();
    }

    removeAddress(index) {
        if (this.serviceProvider.Addresses[index].EntityAddressID == null || this.serviceProvider.Addresses[index].EntityAddressID === 0)
            if (index === 0 && this.serviceProvider.Addresses.length === 1)
                this.serviceProvider.Addresses[index] = new Address();
            else
                this.serviceProvider.Addresses.splice(index, 1);
        else
            this.serviceProvider.Addresses[index].DeActivate();

        this.isCMDirty = true;
    }

    onAddressChanged($event) {
        const _i = $event.index;
        if (_i != null) {
            if (this.serviceProvider.Addresses.length > _i) {
                this.isCMDirty = this.serviceProvider.Addresses[_i].isDirty = true;
                const _lbl = document.getElementById('mat-tab-label-1-' + _i);
                if (_lbl != null) {
                    const _lblDiv = _lbl.getElementsByClassName('mat-tab-label-content');

                    // tslint:disable-next-line: prefer-for-of
                    for (let i = 0; i < _lblDiv.length; i++)
                        _lblDiv[i].classList.add('danger');
                }
            }
        }
    }

    saveProfile(bAddtoList: boolean = false) {

        this.gc.setComponentBusy(true);
        if (this.addressList != null) {
            this.addressList.forEach((_ac) => {
                if (_ac.Address && _ac.index != null) {
                    if (!_ac.Address.IsComplete) {
                        this.isAddressIncomplete = true;
                        this.gc.setComponentBusy(false);
                        return;
                    }
                    this.serviceProvider.Addresses[_ac.index] = _ac.Address;
                }
            });
        }

        if (Utils.isNullOrEmpty(this.serviceProvider.Name) || (this.editingMyCompany && Utils.isNullOrEmpty(this.serviceProvider.FederalTaxID))
            || Utils.isNullOrEmpty(this.serviceProvider.Email) || Utils.isNullOrEmpty(this.serviceProvider.Phone)) {
            this.showWarning('One or more required values are missing.');
            return false;
        }

        if (this.serviceProvider.Addresses) {
            this.serviceProvider.Addresses.forEach((a) => {
                if (a.AddressType === AddressType.NotSet)
                    a.AddressType = AddressType.Physical;
            });
        }

        this.gc.setBusy(true);
        this.saving = true;
        this.gc.setComponentBusy(false);

        let response: any;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).save(this.serviceProvider).subscribe(
            (data) => { response = data; }
            , (error) => { this.saving = false; this.onApiError(error);  }
            , () => {
                if (response) {
                    if (!Utils.isNullOrEmpty(response.error)) {
                        this.showError(response.error);
                    } else if (response.data) {
                        this.profileSaved.emit(response.data.id);
                        this.isCMDirty = false;
                        if (this.user.details.CompanyID === 0) {
                            const _u = this.user;
                            this.serviceProvider.id = _u.details.CompanyID = response.data.id;
                            this.serviceProvider.Addresses = response.data.Addresses;
                            this.setuser(_u);
                        }

                        if (this.allowSave === true) {
                            this.showMessage('Saved');
                        }
                        this.serviceProvider.Addresses = this.serviceProvider.Addresses.filter((a) => a.active);

                        if (bAddtoList) {
                            if (this.companies == null) this.companies = [];
                            this.companies.push(response.data);
                            if (this.companies.length > 1) {
                                this.companies = this.companies.sort((a, b) => a.name > b.name ? 1 : -1);
                            }
                        }
                    }
                }
                this.gc.setBusy(false);
                this.saving = false;
            }
        );

        return true;
    }

    companySelected(id) {
        if (id > 0)
            this.bindSP(id);
    }

    clear() {
        if (this.autoCompleteSPName) this.autoCompleteSPName.clear();
        this.serviceProvider = this.editingMyCompany ? null : (new ServiceProviderEntity());
        this.newAddress();
        this.editable = false;
    }

    bindSP(id: number) {
        if (id == null || id === 0) {
            this.clear(); this.newAddress(); return;
        }
        if (this.serviceProvider && this.serviceProvider.ServiceProviderID === id) return;
        this.gc.setBusy(true);
        let detailsResponse: any;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getDetails(id).subscribe(
            (data) => { detailsResponse = data; }
            , (error) => { this.gc.setBusy(false); this.onApiError(error); }
            , () => {
                this.gc.setBusy(false);
                if (detailsResponse && detailsResponse.data) {
                    this.serviceProvider = Utils.castTo(detailsResponse.data, new ServiceProviderEntity());
                    if (this.serviceProvider.Addresses == null)
                        this.serviceProvider.Addresses = new Array<Address>();
                    if (this.serviceProvider.Addresses.length === 0)
                        this.serviceProvider.Addresses.push(new Address());
                    this.serviceProvider.isDirty = false;
                    this.serviceProvider.Addresses.forEach((a) => { a.isDirty = false; });
                }
                this.setCanAddNewAddress();
                this.setEditable();
            });
    }

    // Admin Add SP Calls this
    private LoadCompanies() {
        let response: any;
        if (this.autoCompleteSPName) this.autoCompleteSPName.loading = true;
        const _data = {quickList: true};
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getServiceProviders(_data).subscribe(
            (data) => { response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (response && response.data) {
                    this.companies = response.data.map((c) => Utils.castTo(c, new item())).sort((a, b) => a.name > b.name ? 1 : -1); // Descending  -- For Ascending  -1 : 1;
                }
                if (this.autoCompleteSPName) this.autoCompleteSPName.loading = false;
            });
    }

    private setAutoCompleteSP() {
        if (this.forLookup && this.autoCompleteSPName) {
            this.autoCompleteSPName.allowNew = this.allowNew;
            if (this.allowNew) {
                if (this._textChangeSub != null) this._textChangeSub.unsubscribe();
                this._textChangeSub = this.autoCompleteSPName.onTextChanged.subscribe((value) => {
                    this.serviceProvider.Name = value;
                });
            }
        }
    }

    private setCanAddNewAddress() {
        if (this.serviceProvider) {
            if (this.serviceProvider.Addresses == null)
                this.serviceProvider.Addresses = new Array<Address>();
            if (this.serviceProvider.Addresses.length === 0)
                this.serviceProvider.Addresses.push(new Address());
            this.canAddNewAddress = this.allowMultipleAddresses && !this.serviceProvider.IsReadOnly;
        } else
            this.canAddNewAddress = false;
    }

    private setEditable() {
        this.editable = this.serviceProvider && (this.serviceProvider.ServiceProviderID === 0
            || (this.serviceProvider.ServiceProviderID > 0 && (this.userdetails && (document.getElementById(navIA) != null
            || (this.userdetails.CompanyID > 0 && this.userdetails.CompanyID === this.serviceProvider.ServiceProviderID && this.gc.hasRole(rolePosition.admin))))));

        if (this.editable) {
            if (this.inputs) {
                for (const i of this.inputs.toArray()) {
                    if (i.ngControl && i.ngControl.control && i.ngControl.control.touched === false) {
                        i.ngControl.control.markAsTouched();
                    }
                }
            }
            if (this.selects) {
                for (const i of this.selects.toArray()) {
                    if (i.ngControl && i.ngControl.control && i.ngControl.control.touched === false) {
                        i.ngControl.control.markAsTouched();
                    }
                }
            }
        }
    }
}
