import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';

import { item } from '../../models/appCommon.models';
import { WorkOrderService } from '../../workorders/workorder.service';
import { DatePickerComponent } from '../../common/datepicker.component';
import { WorkOrder } from 'src/app/models';

import { PersonService } from '../../account/person.service';
import { selectTag } from '../../common/appCommon.module';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-add-service-visit-dialog',
    templateUrl: './addServiceVisit.dialog.html'
})
export class AddServiceVisitDialog extends BaseDialog {

    upcomingVisits: any = [];
    staff: any = [];

    showCurrentVisits: boolean = false;
    selectedVisitID: number = 0;
    selectedStaffID: number = null;

    visitDate: Date = this.today;
    visitTime: any = moment().hour(8).minute(0);
    duration: number = 15;
    reason: string = '';
    listingID: number = 0;

    maxDate: Date = moment(this.today).add(30, 'days').toDate();
    @ViewChild('txtVisitDate') txtVisitDate: DatePickerComponent;

    readyForSubmit: boolean = false;
    dialogHeader: string = 'Visit';
    workOrders: WorkOrder[] = [];
    private _workOrderID: number = 0;

    constructor(dataservice: DataService, private r: Router
        , public dialogRef: MatDialogRef<AddServiceVisitDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addServiceVisitDialog', dataservice, r, dialogRef, data);

        if (data) {

            if (data.showCurrentVisits) this.showCurrentVisits = data.showCurrentVisits;
            if (data.workOrders) this.workOrders = data.workOrders;
            if (data.id) this.workOrderID = data.id;

            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Visit for ' + this.data.header;
            }

            if (this.workOrderID === 0) {
                if (this.workOrders == null || this.workOrders.length === 0) {
                    let _woResponse;
                    this.binding = true;
                    (new PersonService(this.dataservice, this.r, this.myResourceCategory)).getWorkOrders(this.listingID).subscribe((d) => _woResponse = d
                        , (error) => { this.onApiError(error); }
                        , () => {
                            if (_woResponse && _woResponse.data) {
                                this.workOrders = _woResponse.data.map(wo => { return Utils.castTo(wo, new WorkOrder()); });
                            }

                            if (this.workOrders.length > 0)
                                this.workOrderID = this.workOrders[0].id;
                            else
                                setTimeout(() => { this.close(null); }, 500);
                        });
                } else {
                    if (this.workOrders.length > 0)
                        this.workOrderID = this.workOrders[0].id;
                    else
                        setTimeout(() => { this.close(null); }, 500);
                }
            }
        }
    }


    get workOrderID(): number { return this._workOrderID; }
    set workOrderID(v: number) {
        this._workOrderID = v;
        this.prepareVisit();
        if (this.workOrders && this.workOrders.find(wo => { return wo.id === v; }) != null)
            this.dialogHeader = 'Visit for ' + this.workOrders.find(wo => { return wo.id === v; }).address.FullStreetAddress;
        else
            this.dialogHeader = 'Visit';
    }

    setReadyForSubmit() {
        this.readyForSubmit = this.selectedStaffID > 0 && this.duration > 0 && !Utils.isNullOrEmpty(this.reason) && !moment(this.visitDate).isSame(this.Utils.dateTimeMinValue);
    }

    close(data) {
        this.dialogRef.close(data);
    }

    visitDateChanged($value) {
        if ($value)
            this.visitDate = $value;
        else
            this.visitDate = this.today;

        this.setReadyForSubmit();
    }

    addVisit() {
        this.visitDate = this.txtVisitDate.value;

        if (this.visitTime && typeof this.visitTime === 'string') {
            this.visitDate.setHours(0, 0, 0, 0);

            let _v = this.visitTime.split(' ');

            const _pm: boolean = (_v.length > 1 && _v[1] && _v[1].toString().toLowerCase() === 'pm');
            _v = _v[0].split(':');

            let _hr = parseInt(_v[0]);
            let _min = 0;
            if (_v.length > 1) _min = parseInt(_v[1]);

            if (_hr < 12 && _pm) _hr += 12;

            this.visitDate = moment(this.visitDate).add(_hr, 'hours').add(_min, 'minutes').toDate();
        } else if (this.visitTime && moment(this.visitTime).isValid()) {
            const _dt = moment(this.visitTime).toDate();
            this.visitDate = moment(this.visitDate).add(_dt.getHours(), 'hours').add(_dt.getMinutes(), 'minutes').toDate();
        }

        let _response: any;
        this.componentBusy = true;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).addVisit(this.workOrderID, this.visitDate, this.duration, this.selectedStaffID, this.reason).subscribe(
            (data) => _response = data
            , (error) => { this.close(null); this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response) {
                    if (Utils.isNullOrEmpty(_response.error)) {
                        this.showMessage('Visit request sent.');
                        this.close(_response.data);
                    } else
                        this.error = _response.error;
                }
            }
        );
    }


    removeVisit(iVisitID: number) {
        let _response: any;
        this.componentBusy = true;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).removeVisit(this.workOrderID, iVisitID).subscribe(
            (data) => _response = data
            , (error) => { this.close(null); this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response) {
                    if (Utils.isNullOrEmpty(_response.error)) {
                        this.upcomingVisits = this.upcomingVisits.filter(uv => { return uv.id !== iVisitID; });
                    } else
                        this.error = _response.error;
                }
            }
        );
    }

    private prepareVisit() {

        if (this.workOrderID === 0) {
            this.staff = this.upcomingVisits = [];
            this.selectedStaffID = 0;
            return;
        }
        this.componentBusy = true;
        let _response: any;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).prepareToAddVisit(this.workOrderID, this.showCurrentVisits).subscribe(
            (data) => _response = data
            , (error) => { this.close(null); this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response && _response.data) {
                    if (_response.data.upcomingVisits)
                        this.upcomingVisits = _response.data.upcomingVisits;
                    if (_response.data.staff) {
                        this.staff = _response.data.staff;
                        if (this.staff.length > 1)
                            this.staff.splice(0, 0, new item(0, selectTag));
                        else if (this.staff.length === 1)
                            this.selectedStaffID = this.staff[0].id;
                    }
                }
            }
        );

    }
}
