<div *ngIf="showHeader">
    <mat-form-field *ngIf="listings && listings.length > 0">
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="currentEscrow && currentEscrow.id > 0">
        Closing Date - {{this.DisplayDate(currentEscrow.closingDate)}}
    </div>
</div>
<div *ngIf="showHeader">
    <mat-form-field *ngIf="escrows && escrows.length > 0">
        <mat-select [(ngModel)]="escrowID">
            <mat-label class="placeholder">Escrow</mat-label>
            <mat-option *ngFor="let l of escrows" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="listings == null || listings.length == 0">
    <p *ngIf="role == 1">You do not have any active properties for Sale </p>
    <p *ngIf="role == 2">You do not have any active properties in Escrow</p>
</div>
<div>
    <mat-progress-bar mode="buffer" *ngIf="this.componentBusy === true"></mat-progress-bar>
    <ng-template [ngIf]="(this.contingencies && this.contingencies.length > 0)" [ngIfElse]="noContingencies">
        <hr />
        <div *ngFor="let contingency of contingencies  | paginate: { itemsPerPage: contingenciesOnPage , currentPage: contingenciesPageNumber }; let idx = index " class="section" style="background-color: {{idx%2 ? 'lightgrey': ''}};padding:20px;">
            <div>
                <label class="caption">Description</label>
                <label class="data-value auto">{{contingency.description}}</label>
            </div>
            <div>
                <label class="caption">Fulfilled By</label>
                <label class="data-value">{{contingency.fulfilledBy}}</label>
            </div>
            <div>
                <label class="caption">Instructions</label>
                <label class="data-value">
                    <a (click)="showInstructions(contingency.id)" *ngIf="contingency.hasContingencyDocument && contingency.canUploadFulfillmentDocument" ToolTip="Fulfillment Instructions"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-list-alt fa-stack-1x fa-inverse"></i></span></a>
                    <a (click)="showFulfilledDoc(contingency.id)" *ngIf="contingency.hasFulfillmentDocument && contingency.canVerify" ToolTip="Fulfillment Details"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-edit fa-stack-1x fa-inverse"></i></span></a>
                    <a (click)="uploadContigencyDoc(contingency.id)" *ngIf="currentEscrow != null && currentEscrow.isOpen&& !contingency.hasFulfillmentDocument && contingency.canUploadFulfillmentDocument" ToolTip="Upload Fulfillment document, see instructions"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-upload fa-stack-1x fa-inverse"></i></span></a>
                    <a (click)="sendNotice(contingency.id)" *ngIf="contingency.canSendNotice" title="Contingency has not been fulfilled - Send notice to perform" class="float-right"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-bullhorn fa-stack-1x fa-inverse"></i></span></a>
                </label>
            </div>

            <mat-action-row style="display:initial">
                <hr />
                <div><label class="bold">{{'System.CurrentStatus' | translateTag}}</label> - {{contingency.statusDescription}}&nbsp;</div>
                <br />
                <app-next-status-actions [actions]="contingency.nextSteps" (itemSelected)="changeStatus($event)" [hideNoActionLabel]="true"></app-next-status-actions>
            </mat-action-row>
        </div>
        <div style="text-align:center" *ngIf="contingencies && contingencies.length > 0">
            <hr />
            <pagination-controls (pageChange)="contingenciesPageNumber = $event"></pagination-controls>
        </div>
    </ng-template>
    <ng-template #noContingencies>
        <h3 *ngIf="this.componentBusy === false">Currently there are no applicable contingencies.</h3>
    </ng-template>
</div>