<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
        <div class="form">
            <mat-form-field>
                <input matInput appDisableAutofill  maxlength=100 placeholder="Authority" [(ngModel)]="newTax.taxAuthority" required />
                <mat-error *ngIf="(newTax.taxAuthority ?? '') !== ''">Please enter Tax Authority Name</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput appDisableAutofill  maxlength=10 placeholder="Amount" [(ngModel)]="newTax.amount" required type="number" (keydown)="utils.numOnlyLimitingLength($event,10)" class="numeric" />
                <mat-error *ngIf="newTax.amount <= 0">Please enter an amount</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="escrowID > 0" class="full">
                <mat-label class="placeholder">Taxes paid by Role</mat-label>
                <mat-select required (selectionChange)="roleChanged($event.value)">
                    <mat-option *ngFor="let l of roles" [value]="l.id" class="auto">{{l.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="newTax.role == 0">Please select role of the person liable for the tax.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <textarea matInput appDisableAutofill maxlength=100 placeholder="Notes/Description" [(ngModel)]="newTax.notes" ></textarea>
            </mat-form-field>
            <br />
            <input type="submit" class="btn" value="Submit" *ngIf="saveReady && !saving" (click)="onSubmit()">
        </div>
    </mat-card>
    <div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to add new Tax Item</div>
</div>
