import { Component, ViewChild, Input, Output, ElementRef, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';

import { UntypedFormControl } from '@angular/forms';
import { item } from '../models/appCommon.models';

import { Observable } from 'rxjs/internal/Observable';
import { startWith, map } from 'rxjs/operators';
import { Utils } from './utils';
declare var $: any;

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html'
})

export class AutoCompleteComponent extends BaseComponent implements AfterViewInit {
    @Input() countryID: number = 0;
    @Input() stateID: number = 0;
    @Input() rowLimit: number = 5;
    @Input() itemClass: string = '';
    @Input() type: string = '';
    @Input() id: string = '';
    @Output() onItemSelected: EventEmitter<number> = new EventEmitter<number>();
    @Output() onTextChanged: EventEmitter<string> = new EventEmitter<string>();
    @Input() disabled: boolean = false;
    @Input() placeholder: string = '';
    @Input() required: boolean = false;
    @Input() allowNew: boolean = false;
    isNewValue: boolean = true;
    itemCtrl: UntypedFormControl;
    filteredItems: Observable<item[]>;

    private _names: item[] = new Array<item>();
    private _loading: boolean = false;
    private _selectedID: number = 0;

    constructor(r: Router, gc: GlobalComponent) {
        super('', r, gc);

        this.itemCtrl = new UntypedFormControl();
        this.filteredItems = this.itemCtrl.valueChanges
            .pipe(
                startWith(''),
                map((i) => i ? this.filterStates(i.toString()) : (this.values ? this.values.slice() : null))
            );
    }

    public get loading(): boolean { return this._loading; }
    public set loading(value: boolean) { this._loading = value; }

    @Input() get value(): number { return this._selectedID; }
    set value(v: number) {
        this._selectedID = v;

        let _i: any ;
        let _t: string = '';

        if (this._names != null)
            _i = this._names.find((i) => { return i.id === v; });

        if (_i) _t = _i.name;

        this.itemCtrl.setValue(_t, { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
    }

    @Input() @Output() set values(v: item[]) {
        if (v == null) v = new Array<item>();
        this._names = v;
        if (v != null && v.find((i) => { return i.id === this.value; }) != null )
            this.itemCtrl.setValue(v.find((i) => { return i.id === this.value; }).name, { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
        else
            this.itemCtrl.setValue('', { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
    }
    get values(): item[] { return this._names; }

    public get text(): string {
        if (this.itemCtrl.value == null) {
            this.isNewValue = false;
            return '';
        } else {
            this.isNewValue = this._selectedID <= 0;
            return this.itemCtrl.value;
        }
    }

    public set text(v: string) {
        // Used only when Value and Text are available and the AutoComplete List build is in progress
        this.itemCtrl.setValue(v, { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
        $('#myID' + this.id).val(v);
    }


    filterStates(name: string): item[] {
        if (name && this._names) {
            return this._names.filter((i) =>
                i.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
        } else
            return new Array<item>();
    }

    ngAfterViewInit() {
        const _v = $('#myID' + this.id);

        if (this.disabled) _v.attr('disabled', 'disabled');

        let _p = null;
        if (_v != null && _v.length > 0)
            _p = _v[0].parentElement;
        else if (_v != null)
            _p = _v.parentElement;

        if (!Utils.isNullOrEmpty(this.itemClass) && _v)
            _v.addClass(this.itemClass);
    }

    itemSelected(e, i, id) {
        if (id == null) return;

        this.value = id;
        this.onItemSelected.emit(id);
    }

    onValueChange() {
        if (this.value === 0 && ((this.allowNew === false && !Utils.isNullOrEmpty(this.text)) || Utils.isNullOrEmpty(this.text)))
            this.clear();
        else {

            const _t = this.text.toLowerCase();
            const _v: any = this._names.find((n) => n.name.toLowerCase().startsWith(_t));
            if (_v) {
                if (_v.length > 0)
                    this.value = _v[0].id;
                else if (_v.id)
                    this.value = _v.id;
            }

            if (this.value > 0)
                this.onItemSelected.emit(this.value);
            else
                this.onTextChanged.emit(this.text);
        }
    }

    clear() {
        $('#myID' + this.id).val('');
        this.itemSelected(null, 0, 0);
        this.onTextChanged.emit('');
    }
}
