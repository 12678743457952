import { Pipe, PipeTransform } from '@angular/core';
import { navIA } from '../../models/constants';

@Pipe({ name: 'code' })
export class TagCodePipe implements PipeTransform {

    private isCategoryVisible: boolean = false;

    constructor() {
        const sessionDetail = JSON.parse(sessionStorage.getItem('sessionUser'));
        if (sessionDetail) {
            this.isCategoryVisible = sessionDetail.details && document.getElementById(navIA) != null;
        }
    }

    transform(tags: any, code: string): string {
        if (tags != null) {
            const tagDetail: any = tags[code];
            if (tagDetail !== undefined || tagDetail != null) {
                return tagDetail.v ? tagDetail.v : tagDetail;
            }
        }

        return code + (tags['resourceCategory'] && this.isCategoryVisible ? ' - ' + tags['resourceCategory'] : '');
    }
}
