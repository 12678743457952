<session-expiration-alert #sessionAlert *ngIf="loggedIn === true" [startTimer]="true" [alertAt]="timeoutWarning"></session-expiration-alert>
<header id="">
	<nav class="mb-4 navbar navbar-expand-lg navbar-dark bg-unique" id="mainNav">
		<a class="navbar-brand" [routerLink]="'/'"><img src="/assets/images/logo.png" style="height:40px;width:120px" /></a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-3" aria-controls="navbarSupportedContent-3" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent-3" *ngIf="gc.stealthMode === false">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item" *ngIf="(loggedIn ?? false ) === false">
					<a class="nav-link notLoggedIn" (click)="navTo(appURLs.findHome, true)">{{'Global.findHome' | translateTag }}</a>
				</li>
				<li class="nav-item" *ngIf="(loggedIn ?? false ) === false">
					<a class="nav-link notLoggedIn" (click)="startWithSignUp(appURLs.sellerNewListing)">{{'Global.listHome' | translateTag }}</a>
				</li>
				<li class="nav-item dropdown" *ngIf="loggedIn === true">
					<a class="nav-link dropdown-toggle" id="buyerMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Buying
					</a>
					<div class="dropdown-menu dropdown-menu-right dropdown-unique" aria-labelledby="buyerMenuLink-3">
						<a class="dropdown-item active" (click)="navTo(appURLs.findHome, true)"><i class="fa fa-home fa-lg"></i>{{ 'Global.findHome' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="GoToURLWithSession(appURLs.loans, handover.loanOption, '1')"><i class="fa fa-film fa-2x"></i>{{ 'Navigation.MyLoans' | translateTag }}<i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="newLoan()">
							<i class="fa fa-newspaper-o fa-2x"></i>{{'Navigation.NewLoanApp' | translateTag }}<i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="navTo('offer/myoffers')"><i class="fa fa-qrcode fa-2x"></i>{{ 'Navigation.Offers' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i>
						</a>
						<a class="dropdown-item" (click)="navTo('buyer/workorders')"><i class="material-icons">toc</i>{{ 'Navigation.WorkOrders' | translateTag }}  <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('buyer/escrow')"><i class="material-icons">toll</i>{{ 'Navigation.Escrows' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('agent/search')" *ngIf="false"><i class="material-icons">supervised_user_circle</i>{{ 'Navigation.FindAgent' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('company/search')"><i class="material-icons">supervisor_account</i>{{ 'Navigation.FindProfessionals' | translateTag }}</a>
						<div class="dropdown-divider" *ngIf="notAtHelpURL"></div>
						<a class="dropdown-item" (click)="GoToURLWithSession(appURLs.help, handover.helpCategory, buyer)" *ngIf="notAtHelpURL"><i class="material-icons">directions_run</i>{{ 'Navigation.GetStarted' | translateTag }}</a>
					</div>
				</li>
				<li class="nav-item dropdown" *ngIf="loggedIn === true">
					<a class="nav-link dropdown-toggle" id="sellerMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Selling
					</a>
					<div class="dropdown-menu dropdown-menu-right dropdown-unique" aria-labelledby="sellerMenuLink-3">
						<a class="dropdown-item active" (click)="navTo(appURLs.sellerNewListing)">{{ 'Navigation.SellerNewListing' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('listing/listings')"><i class="material-icons">assignment</i>{{ 'Navigation.SellerListings' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="navTo('listing/viewing')"><i class="fa fa-handshake-o fa-2x"></i>{{ 'Navigation.SellerVisitor' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('listing/listingoffers')"><i class="fa fa-qrcode fa-2x"></i>{{ 'Navigation.Offers' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('seller/workorders')"><i class="material-icons">toc</i>{{ 'Navigation.WorkOrders' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('seller/escrow')"><i class="material-icons">toll</i>{{ 'Navigation.Escrows' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('agent/search')" *ngIf="false"><i class="material-icons">supervised_user_circle</i>{{ 'Navigation.FindAgent' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('company/search')"><i class="material-icons">supervisor_account</i>{{ 'Navigation.FindProfessionals' | translateTag }}</a>
						<div class="dropdown-divider" *ngIf="notAtHelpURL"></div>
						<a class="dropdown-item" (click)="GoToURLWithSession(appURLs.help, handover.helpCategory, seller)" *ngIf="notAtHelpURL"><i class="material-icons">directions_run</i>{{ 'Navigation.GetStarted' | translateTag }}</a>
					</div>
				</li>
				<li class="nav-item" *ngIf="loggedIn === true && this.userdetails && this.userdetails.CompanyID == 0">
					<a class="nav-link notLoggedIn" (click)="navTo('company/profile')" title="Are you a professional? Register to provide services to property Buyers and Sellers on IAMOREA platform.">{{ 'Navigation.SPRegister' | translateTag }}</a>
				</li>
				<li class="nav-item dropdown" *ngIf="loggedIn === true && this.userdetails && this.userdetails.CompanyID > 0">
					<a class="nav-link dropdown-toggle" id="professionalMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Professionals
					</a>
					<div class="dropdown-menu dropdown-menu-right dropdown-unique" aria-labelledby="professionalMenuLink-3">
						<a class="dropdown-item" (click)="navTo('workorders')"><i class="material-icons">toc</i>{{ 'Navigation.WorkOrders' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="GoToURLWithSession(appURLs.loans, handover.loanOption, '2')" *ngIf="isLender"><i class="fa fa-film fa-2x"></i>{{ 'Navigation.SPLoans' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="navTo('company/bids')"><i class="material-icons">vertical_split</i>{{ 'Navigation.SPBids' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="navTo('company/visits')"><i class="material-icons">contact_mail</i>{{ 'Navigation.SPServiceVisits' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item active" (click)="navTo(appURLs.serviceProviderRenew)"><i class="material-icons">shopping_cart</i>{{ 'Navigation.SPNewService' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="navTo('provider/pricelist')"><i class="material-icons">settings_brightness</i>{{ 'Navigation.SPServicesOffered' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('company/licenses')"><i class="material-icons">ballot</i>{{ 'Navigation.SPLicenses' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="navTo('company/insurances')"><i class="material-icons">ballot</i>{{ 'Navigation.SPInsurances' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="navTo('company/profile')" *ngIf="this.userdetails != null && this.userdetails.CompanyID > 0"><i class="material-icons">domain</i>{{ 'Navigation.SPCompanyProfile' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<a class="dropdown-item" (click)="navTo('company/staff')" *ngIf="this.userdetails != null && this.userdetails.CompanyID > 0"><i class="material-icons">people</i>{{ 'Navigation.Staff' | translateTag }} <i class='fa fa-lock danger' *ngIf="(loggedIn ?? false ) === false" title="Log In to Continue."></i></a>
						<div class="dropdown-divider" *ngIf="notAtHelpURL"></div>
						<a class="dropdown-item" (click)="GoToURLWithSession(appURLs.help, handover.helpCategory, sp)" *ngIf="notAtHelpURL"><i class="material-icons">directions_run</i>{{ 'Navigation.GetStarted' | translateTag }}</a>
					</div>
				</li>
			</ul>
			<ul class="navbar-nav ml-auto nav-flex-icons">
				<li class="nav-item" *ngIf="(loggedIn ?? false ) === false">
					<a class="nav-link notLoggedIn" (click)="signup()" id="appRegister">{{ 'Navigation.Register' | translateTag }}</a>
				</li>
				<li class="nav-item" *ngIf="(loggedIn ?? false ) === false && !isLoginPage">
					<a class="nav-link notLoggedIn" (click)="signin()" id="appSignIn">{{ 'Navigation.SignIn' | translateTag }}</a>
				</li>
				<li class="nav-item">
					<a (click)="navTo(appURLs.help, true)" class="nav-link"><i class="fa fa-question-circle-o fa-2x"></i></a>
				</li>
				<li class="nav-item dropdown" *ngIf="canIA === true" id="navIA">
					<a class="nav-link dropdown-toggle" id="adminMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fa fa-gears fa-2x" title="Administration"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-right dropdown-unique" aria-labelledby="adminMenuLink">
						<mat-checkbox [(ngModel)]="editContent" (change)="changeContentEdit()" style="padding-left:10px">{{ 'Navigation.EditContent' | translateTag }}</mat-checkbox>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('system/refresh')">{{ 'Navigation.RefreshCache' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('system/publish')">{{ 'Navigation.PublishContent' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('campaign')">{{ 'Navigation.Campaign' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('admin/legal')">{{ 'Navigation.LegalTerms' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('admin/product')">{{ 'Navigation.Products' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('admin/setupsp')">{{ 'Navigation.SetupNewSP' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('account/verifycertifications')">{{ 'Navigation.VerifyCert' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('admin/assets')">{{ 'Navigation.Assets' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('system/statemachine')">{{ 'Navigation.StateMachine' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('system/codes')">{{ 'Navigation.Codes' | translateTag }}</a>
					</div>
				</li>
				<li class="nav-item dropdown" *ngIf="loggedIn === true">
					<button mat-mini-fab color="primary" title="{{this.userdetails.FirstName + ' ' + this.userdetails.LastName + ' [' + this.userdetails.PID + ']'}}"
							class="nav-link dropdown-toggle" id="userMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						{{getInitials(this.userdetails.FirstName + ' ' + this.userdetails.LastName)}}
					</button>
					<div class="dropdown-menu dropdown-menu-right dropdown-unique" aria-labelledby="userMenuLink">
						<a class="dropdown-item" (click)="navTo('account/welcome')"><i class="material-icons">bubble_chart</i>{{ 'Navigation.Summary' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('account/profile')"><i class="material-icons">person_pin</i>{{ 'Navigation.Profile' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('account/changepassword')"><i class="material-icons">transfer_within_a_station</i>{{ 'Navigation.ChangePassword' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('account/messages')" *ngIf="this.userdetails">
							<i class="material-icons">mail</i>
							<span *ngIf="this.userdetails.MessagesSinceLastLogin > 0" matBadge="{{this.userdetails.MessagesSinceLastLogin}}" matBadgeOverlap="false" matBadgeColor="accent" title="Number or Messages since Last Login">{{ 'Navigation.MessageBox' | translateTag }}</span>
							<span *ngIf="this.userdetails.MessagesSinceLastLogin <= 0">{{ 'Navigation.MessageBox' | translateTag }}</span>
						</a>
						<a class="dropdown-item" (click)="navTo('account/appointments')"><i class="material-icons">calendar_today</i>{{ 'Navigation.Appointments' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('account/certifications')"><i class="material-icons">ballot</i>{{ 'Navigation.Certifications' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('company/profile')"><i class="material-icons">domain</i>{{ 'Navigation.CompanyProfile' | translateTag }}</a>
						<a class="dropdown-item" (click)="navTo('company/staff')"><i class="material-icons">people</i>{{ 'Navigation.Staff' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="navTo('account/invite')"><i class="material-icons green">settings_input_antenna</i>{{ 'Navigation.Invite' | translateTag }}</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="signOut()"><i class="material-icons danger">exit_to_app</i>{{ 'Navigation.Logout' | translateTag }}</a>
					</div>
				</li>
			</ul>
		</div>
	</nav>
</header>
