import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-find-home-lookup',
    template: ` <a [routerLink]="'/home/find'" class='{{class}}'>{{label}}</a>`
})
export class ListingLookupComponent {
    @Input() label: string = 'Find Home';
    @Input() class: string = '';
}
