import { Component, Input, Output, OnDestroy, Directive, Inject, Injectable } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';

import { listing, Role, escrow, BaseResponse, Offer } from '../models';
import { appEnvironment } from 'src/environments/environment';
import { ListingService } from './listing.service';
import { Utils } from '../common/utils';

export enum ListingEditSteps {
    General = 0,
    Tax = 1,
    Characteristics = 2,
    Disclosures = 3,
    Media = 4,
    Sellers = 5,
    Liens = 6,
    PersonalProperty = 7,
    WorkOrders = 8,
    Documents = 9,
    Offers = 10,
    Insure = 11
}

@Injectable()
export abstract class ListingBaseComponent extends BaseComponent {

    @Input() @Output() noParent: boolean = true;
    ListingEditSteps: typeof ListingEditSteps = ListingEditSteps;

    saving: boolean = false;

    currentListing: listing = new listing();
    currentOffer: Offer = new Offer();
    currentEscrow: escrow = new escrow();
    listingID: number = 0;

    listings: any = [];
    role: Role = Role.NotSet;
    protected escrows: any = [];

    private _to: any;

    constructor(@Inject(String) sModule: string, public dataservice: DataService, private r: Router, public gc: GlobalComponent) {
        super(sModule, r, gc);
    }

    setListingID(v: number) {
        if (v == null || typeof v !== 'number')
            v = 0;
        this.listingID = v;
        // this.gc.setListingID(v);
    }

    setRole(v: Role) {
        if (v == null)
            v = Role.NotSet;
        this.role = v;
        this.gc.setRole(v);
    }

    setCurrentListing(v: listing, bForceRefresh: boolean = false) {
        let _changed: boolean = false;
        if (v == null) {
            v = new listing();
            _changed = this.currentListing.id !== v.id;
            this.currentListing = v;
        } else {
            _changed = this.currentListing.id !== v.id;
            this.currentListing = Utils.castTo(v, new listing());
        }

        this.listingID = this.currentListing.id;

        if (this.noParent) {
            if (v)
                this.gc.setCurrentListing(this.currentListing, bForceRefresh);
            else
                this.gc.setCurrentListing(null);
        }
    }

    setCurrentOffer(v: Offer) {
        if (v == null) {
            v = new Offer();
            this.currentOffer = v;
        } else
            this.currentOffer = Utils.castTo(v, new Offer());

        if (this.noParent) {
            if (v)
                this.gc.setCurrentOffer(this.currentOffer);
            else
                this.gc.setCurrentOffer(null);
        }
    }

    setCurrentEscrow(v: escrow) {
        if (v == null) {
            v = new escrow();
            this.currentEscrow = v;
        } else
            this.currentEscrow = Utils.castTo(v, new escrow());

        if (this.noParent) {
            if (v)
                this.gc.setCurrentEscrow(this.currentEscrow);
            else
                this.gc.setCurrentEscrow(null);
        }
    }

    // get myService(): ListingService {
    //    if (this.gc.listingService == null) {
    //        this.gc.listingService = new ListingService(this.dataservice, this.r, this.sResourceCategory);
    //    }
    //    return this.gc.listingService;
    // }

    exitingEdit() {
        if (this._to) clearTimeout(this._to);
    }

    onListingChange() {
        const _listing = this.currentListing;

        if (_listing == null) return;
        if (_listing.setDirty) this.setDirty(_listing);
        if (_listing.isDirty == null) _listing['isDirty'] = true;
        if (_listing.isDirty === false) _listing.isDirty = true;

        if (_listing.isDirty != null && _listing.isDirty === true) {
            this.triggerSave();
        }
    }

    changeStatus(action$, delegate) {

        if (this.isSavePending()) {
            this.showWarning('Listing data has changed, please save the changes or wait for \"auto save\" to save all changes.');
            return;
        }

        this.gc.setBusy(true);
        let _actionResponse: BaseResponse;
        (new ListingService(this.dataservice, this.r, this.myResourceCategory)).changeStatus(this.listingID, action$.name).subscribe((r) => _actionResponse = r
            , (error) => { this.onApiError(error); }
            , () => {
                this.gc.setBusy(false);
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else if (_actionResponse.data) {
                        if (this.currentListing)
                            this.setCurrentListing(_actionResponse.data);
                        if (delegate == null) {
                            if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                                this.gotoURL(_actionResponse.redirectURL);
                            else {
                                if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';
                                this.showMessage(_actionResponse.message);

                                if (_actionResponse.data) {
                                    if (_actionResponse.data.id && _actionResponse.data.id > 0) {
                                        // When New/Updated Listing object is returned.
                                        this.setCurrentListing(_actionResponse.data);
                                    } else {
                                        this.currentListing.UpdateAfterChangeStatus(_actionResponse.data);
                                    }
                                }
                            }
                        } else {
                            if (delegate != null)
                                delegate(_actionResponse.data);
                        }
                    }
                }
            }
        );
    }

    protected initMe() {
        this.listingID = this.gc.listingID;
        this.role = this.gc.role;
        this.currentListing = this.gc.currentListing;
        this.currentOffer = this.gc.currentOffer;
        this.currentEscrow = this.gc.currentEscrow;
    }

    private triggerSave() {
        this.showSave();
        if (this._to != null && !this.saving)
            clearTimeout(this._to);

        this._to = setTimeout(() => {
            this.hideSave();
            this.save();
        }, appEnvironment.autoSaveTime);

        if (this.ss != null && this.ss.subscribe && this._ss == null) {
            this._ss = this.ss.subscribe(() => {
                clearTimeout(this._to);
                this.save();
                this._ss = null;
            });
        }
    }

    private save() {
        const _me = this;

        if (_me.saving === true) {
            window.setTimeout(() => { _me.save(); }, 100); /* this checks the flag every 100 milliseconds*/
            return;
        }

        _me.gc.setBusy(true);
        _me.saving = true;

        let _response: BaseResponse;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).saveListing(this.currentListing).subscribe(
            (data) => { _response = data; }
            , (error) => {
                _me.gc.setBusy(false);
                _me.saving = false; this.onApiError(error);
            }
            , () => {
                _me.gc.setBusy(false);
                _me.saving = false;

                // KEEP LOCAL Listing Copy -- IGNORE Server returned values.
                if (!Utils.isNullOrEmpty(_response?.error)) {
                    this.showError(_response.error);
                } else {
                    _me.hideSave();

                    // Sent to Save -- NOT DIRTY ANY MORE
                    _me.currentListing.isDirty = false;
                    _me.currentListing.clearDirty();
                    clearTimeout(_me._to);

                    _me.setCurrentListing(_me.currentListing);
                    //

                    this.gc.showSnackBar('Changes Saved !!!!');
                }
            });
    }
}
