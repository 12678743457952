import { Component, ViewChild, Input, Output, Inject, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';

import { UntypedFormControl } from '@angular/forms';
import { searchItem } from '../models/appCommon.models';

declare const grecaptcha: any;
import * as _ from 'lodash';
// tslint:disable-next-line: import-blacklist
import { Observable, observable, of } from 'rxjs';
import {
    startWith,
    map,
    debounceTime,
    mergeMapTo,
    mergeMap,
    switchMap,
    catchError
} from 'rxjs/operators';
import { DataService } from '../util/APICaller.component';
import { AssetService } from '../common/service';
import { BaseResponse } from '../models';
import { appEnvironment } from '../../environments/environment';
import { PersonService } from '../account/person.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ScriptService } from '../common/scriptService';
import { Utils } from '../common/utils';
declare var $: any;

@Component({
    selector: 'app-help-search',
    styleUrls: ['./searchHelp.component.css'],
    templateUrl: './searchHelp.component.html'
})

export class SearchHelpComponent extends BaseComponent implements AfterViewInit {

    @Input() countryID: number = 0;
    @Input() stateID: number = 0;
    @Input() rowLimit: number = 5;
    @Input() itemClass: string = '';
    @Input() type: string = '';
    @Input() id: string = '';
    @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() textChanged: EventEmitter<string> = new EventEmitter<string>();
    @Input() disabled: boolean = false;
    @Input() placeholder: string = '';
    @Input() required: boolean = false;
    @Input() allowNew: boolean = false;

    @Input() showResultsGrid: boolean = true;

    pageNumber: number = 1;

    itemCtrl: UntypedFormControl;
    filteredItems: Observable<searchItem[]>;
    resultItems: searchItem[];
    loading: boolean = false;

    @ViewChild('resultPanel') resultPanel: MatExpansionPanel;

    private _names: searchItem[] = new Array<searchItem>();
    

    private _selectedID: number = 0;

    constructor(private ds: DataService, private rs: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('', rs, g);

        const _s = ScriptService.loadExternalScript(appEnvironment.reCaptchaScript, 'www.gstatic.com/recaptcha/');
        if (_s) {
            _s.then(() => {
                this.verifyHuman();
            });
        }

        this.itemCtrl = new UntypedFormControl();
        this.filteredItems = this.itemCtrl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(300),
                switchMap((value) => {
                    if (value !== '') {
                        // lookup from github
                        return this.lookup(value);
                    } else {
                        // if no value is pressent, return null
                        return of(null);
                    }
                })
            );
    }

    @Input() get value(): number { return this._selectedID; }
    set value(v: number) {
        this._selectedID = v;

        let _i = searchItem;
        let _t: string = '';

        if (this._names != null)
            _i = _.find(this._names, { id: v });

        if (_i) _t = _i.name;

        this.itemCtrl.setValue(_t, { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
    }

    @Input() @Output() public set values(v: searchItem[]) {
        if (v == null) v = new Array<searchItem>();
        this._names = v;
        if (v != null && _.find(v, { id: this.value }))
            this.itemCtrl.setValue(_.find(v, { id: this.value }).name, { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
        else
            this.itemCtrl.setValue('', { onlySelf: true, emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: true });
    }
    public get values(): searchItem[] { return this._names; }
    public get text(): string { if (this.itemCtrl.value == null) return ''; else return this.itemCtrl.value; }

    filterStates(name: string): searchItem[] {
        if (name && this._names) {
            return this._names.filter((i) =>
                i.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
        } else
            return new Array<searchItem>();
    }

    lookup(value: string): Observable<searchItem> {
        this.loading = true;
        return (new AssetService(this.ds, this.rs, this.myResourceCategory)).search(value.toLowerCase()).pipe(
            // map the item property of the results as our return object
            map((results) => results.data),
            // catch errors
            catchError((e) => {
                this.loading = false;
                return of(null);
            })
        );
    }

    ngAfterViewInit() {
        const _v = $('#myID' + this.id);

        if (this.disabled) _v.attr('disabled', 'disabled');

        let _p = null;
        if (_v != null && _v.length > 0)
            _p = _v[0].parentElement;
        else if (_v != null)
            _p = _v.parentElement;

        if (!Utils.isNullOrEmpty(this.itemClass) && _v)
            _v.addClass(this.itemClass);
    }

    onItemSelected(oItem: searchItem) {
        if (oItem == null) return;

        if (this.resultPanel != null)
            this.resultPanel.close();

        if (oItem.id) this.value = oItem.id;
        // if (oItem.name) {
        //    this.itemCtrl.setValue(oItem.name);
        // }

        if (this.itemSelected) {
            if (this.showResultsGrid === true) {
                oItem['showingResultsGrid'] = this.showResultsGrid;
                this.itemSelected.emit(oItem);
            }
        } else {
            // When Search Text Box is Plugged outside Help Page
            sessionStorage.setItem('ad', JSON.stringify({ id: oItem.id }));
            this.gotoURL(this.appURLs.help);
        }
    }

    onValueChange() {
        if (this.showResultsGrid === true) {
            this.resultItems = [];
            this.loading = true;
            (new AssetService(this.ds, this.rs, this.myResourceCategory)).search(this.text.toLowerCase()).subscribe((r) => {
                this.loading = false;
                if (r.data) {
                    this.resultItems = r.data;
                }
            });
        } else {
            if (this.value === 0 && ((this.allowNew === false && !Utils.isNullOrEmpty(this.text)) || Utils.isNullOrEmpty(this.text)))
                this.Clear();
            else
                this.textChanged.emit(this.text);
        }
    }

    Clear() {
        $('#myID' + this.id).val('');
        this.onItemSelected(null);
        this.textChanged.emit('');
    }

    private verifyHuman() {
        let _response: BaseResponse;
        if (GlobalComponent._cv === false && grecaptcha != null) {
            grecaptcha.ready(() => {
                grecaptcha.execute(appEnvironment.googleReCaptcha, { action: 'submit' }).then((token) => {
                    (new PersonService(this.ds, this.route, this.myResourceCategory)).verifyRecaptcha(token).subscribe(
                        (data) => _response = data,
                        (error) => { this.disabled = true; this.showResourceError('System.CaptchaFailed'); },
                        () => { GlobalComponent._cv = true; });
                });
            });
        }
    }
}
