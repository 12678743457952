import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { spProductRateItem } from '../../models/providers/spPricing.model';
import { ServiceProviderService } from '../serviceprovider.service';
import { Utils } from '../../common/utils';


@Component({
    selector: 'app-add-service-item-breakup',
    templateUrl: './addServiceItem.dialog.html'
})
export class AddServiceItemDialog extends BaseComponent {

    dialogHeader: string = 'Service Rate Schedule';

    saving: boolean = false;
    isLoanProduct: boolean = false;
    form: UntypedFormGroup;
    error: string;
    rateItem: spProductRateItem = new spProductRateItem();

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent, private dialog: MatDialog
        , public dialogRef: MatDialogRef<AddServiceItemDialog>, fb: UntypedFormBuilder, private cdref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addServiceRateDialog', r);

        this.form = fb.group({ response: ['', [Validators.required]] });

        if (this.data) {
            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Service Rate Schedule for ' + this.data.header;
            }
            this.rateItem = Utils.castTo(this.data, new spProductRateItem());
        }

        if (this.rateItem.id  == null || this.rateItem.id === 0)
            this.rateItem.fixedPrice = this.rateItem.percentagePrice = null;
    }

    get isValid(): boolean {
        let _valPresent: boolean = false;
        _valPresent = (this.rateItem.fixedPrice != null && this.rateItem.fixedPrice > 0) || (this.rateItem.percentagePrice != null && this.rateItem.percentagePrice > 0)
        if (this.rateItem.id > 0)
            return _valPresent;
        else
            return _valPresent && !Utils.isNullOrEmpty(this.rateItem.name);
    }


    onFixedPriceChanged() {
        if (this.rateItem.fixedPrice > 0) {
            this.rateItem.percentagePrice = null;
        }
    }
    onPercentagePriceChanged() {
        if (this.rateItem.percentagePrice > 0) {
            this.rateItem.fixedPrice = null;
        }
    }

    close(oResponse?: any) { this.dialogRef.close(oResponse); }
    onClosed(value: boolean) {
        this.close();
    }

    onSubmit() {
        this.rateItem['serviceProviderID'] = this.user.details.CompanyID;
        this.saving = true;
        let response: any;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).addProductRateItem(this.rateItem).subscribe(
            (data) => { response = data; }
            , (error) => { this.saving = false; this.close(null); this.onApiError(error); }
            , () => {
                this.saving = false;
                if (response) {
                    if (Utils.isNullOrEmpty(response.error)) {
                        this.showMessage('Service Rate Item saved successfully');
                        if (response.data)
                            this.rateItem.id = parseInt(response.data as string);
                        this.close(this.rateItem);
                    } else
                        this.error = response.error;
                }
            }
        );
    }
}
