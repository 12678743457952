<div class="appContent">
    <h2>Create Offer for New Property</h2>
    <mat-card>
        <mat-form-field>
            <mat-label class="placeholder">Property Types <i class="fa fa-question-circle-o warning-color" title="Select a property type"></i></mat-label>
            <mat-select [(ngModel)]="this.newOffer.propertyProductID" (selectionChange)="propertyTypeChanged()">
                <mat-option *ngFor="let pt of propertyTypes" [value]="pt.id" class="auto"><span [innerHTML]="pt.icon"></span> {{pt.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && this.newOffer.propertyProductID == 0">Please select property Type to proceed.</mat-error>
        </mat-form-field>
        <mat-card>
            <mat-card-title>Address</mat-card-title>
            <br />
            <app-base-address [showOwnerShip]="false" #listingAddress [canChangeAddressType]="false"></app-base-address>
            <div *ngIf="!listingAddress.IsComplete && submitted" class="danger">Address is incomplete</div>
        </mat-card>
        <mat-card>
            <mat-card-title>Seller</mat-card-title><br/>
            <div class="app-flex-container app-2col">
                <mat-form-field>
                    <mat-label class="placeholder">First Name<i class="fa fa-question-circle-o warning-color" title="First Name of the Seller"></i></mat-label>
                    <input matInput appDisableAutofill maxlength="50" [(ngModel)]="this.newOffer.firstName" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="placeholder">Last Name<i class="fa fa-question-circle-o warning-color" title="Last Name of the Seller"></i></mat-label>
                    <input matInput appDisableAutofill maxlength="50" [(ngModel)]="this.newOffer.lastName" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label class="placeholder">Email<i class="fa fa-question-circle-o warning-color" title="Email for Seller"></i></mat-label>
                    <input matInput appDisableAutofill maxlength="100" [(ngModel)]="this.newOffer.email" />
                </mat-form-field>
            </div>
            <div>
                <mat-checkbox [(ngModel)]="this.newOffer.sellerIsAgent">This is Seller's agent information</mat-checkbox>
            </div>
            <div *ngIf="submitted && !sellerComplete" class="danger">Please complete Seller's information.</div>
        </mat-card>
        <mat-card>
            <mat-card-title>Price</mat-card-title><br />
            <div class="app-flex-container app-2col">
                <mat-form-field>
                    <mat-label class="placeholder">Asking Price <i class="fa fa-question-circle-o warning-color" title="Price Seller is asking"></i></mat-label>
                    <input matInput appDisableAutofill type="number" (keydown)="utils.numOnlyLimitingLength($event, 11, false)" [(ngModel)]="this.newOffer.askingPrice" class="numeric" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="placeholder">Price Offered <i class="fa fa-question-circle-o warning-color" title="Price being offered to the Seller"></i></mat-label>
                    <input matInput appDisableAutofill type="number" (keydown)="utils.numOnlyLimitingLength($event, 11, false)" [(ngModel)]="this.newOffer.offeredPrice" class="numeric" />
                </mat-form-field>
            </div>
            <div *ngIf="submitted && (newOffer.askingPrice <= 0 || newOffer.offeredPrice <= 0)" class="danger">Invalid pricing.</div>
        </mat-card>
        <mat-card *ngIf="isAgent === true">
            <div class="app-flex-container app-2col">
                <mat-checkbox [(ngModel)]="this.newOffer.sellerIsAgent" [disabled]="true">I am an Agent and listing this property for a Buyer I am Representing.<i class="fa fa-question-circle-o warning-color" title="You can add Buyers's information after creating Offer. If you want to BUY home for yourself? Create/Use a different account."></i></mat-checkbox>
                <mat-form-field>
                    <mat-label class="placeholder">Referral Fee <i class="fa fa-question-circle-o warning-color" title="Referral Fee I will expect at COE."></i></mat-label>
                    <input matInput appDisableAutofill type="number" (keydown)="utils.numOnlyLimitingLength($event, 10, false)" [(ngModel)]="this.newOffer.buyerAgentsCommission" />
                </mat-form-field>
            </div>
        </mat-card>
        <mat-card *ngIf="newOffer.legalTermsVersionID > 0">
            <div hrefToRouterLink [innerHTML]="legalContent | safeHtml" style="max-height:400px;overflow-y:auto"></div>
            <hr />
            <mat-checkbox [(ngModel)]="tncAgreed">I have reviewed and agree to the Terms and Conditions.</mat-checkbox>
        </mat-card>
        <hr />
        <button matButton id="app-buyer-nl-createOffer" (click)="createOffer()" *ngIf="saveReady">Create Offer</button>
    </mat-card>
</div>