import { Router, Route } from '@angular/router';
import { BaseService } from '../common/service/base.service';

import { DataService } from '../util/APICaller.component';
import { ServiceProviderEntity, SPServiceDetailContentRequestModel } from '../models/providers/serviceProvider.model';

import { serviceItemRequestModel, serviceRateRequestModel } from '../models/providers/spPricing.model';
import { Role } from '../models/enums.component';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ServiceProviderService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    getServiceProviders(criteria: any) {
        const data = criteria;
        return this.ds.APIPost('/serviceprovider/search', data, this.requestingModule);
    }

    save(oServiceProvider: ServiceProviderEntity) {
        const _data = JSON.parse(JSON.stringify(oServiceProvider));
        return this.ds.APIPost('/serviceprovider/save', _data, this.requestingModule);
    }

    getDetails(id: number) {
        const data = { serviceProviderID: id };
        return this.ds.APIPost('/serviceprovider/details', data, this.requestingModule);
    }
    getDetailTypes() {
        const data = null;
        return this.ds.APIPost('/serviceprovider/getDetailTypes', data, this.requestingModule);
    }
    //#region Offerings
    getOfferedServices(id: number = 0) {
        const data = { serviceProviderID: id };
        return this.ds.APIPost('/serviceprovider/getOfferedServices', data, this.requestingModule);
    }
    getLoanType(id: number) {
        const data = { id };
        return this.ds.APIPost('/product/loanType', data, this.requestingModule);
    }
    getTermsAndConditions(iServiceProviderID: number, iServiceTypeID: number, iOptionID: number, enRole: Role) {
        const _options = [];
        _options.push(iOptionID);
        const data = { serviceProviderID: iServiceProviderID, serviceTypeID: iServiceTypeID, optionIDs: _options, role: enRole };
        return this.ds.APIPost('/serviceprovider/getTermsAndConditions', data, this.requestingModule);
    }
    getTnCAndDisclosures(iServiceProviderID: number, iServiceTypeID: number, iOptionID: number, enRole: Role) {
        const _options = [];
        _options.push(iOptionID);
        const data = { serviceProviderID: iServiceProviderID, serviceTypeID: iServiceTypeID, optionID: _options, role: enRole };
        return this.ds.APIPost('/serviceprovider/getTnCAndDisclosures', data, this.requestingModule);
    }

    getOfferingExpiryDate(iProductID: number, iStateID: number = 0) {
        const data = { serviceTypeID: iProductID, stateID: iStateID };
        return this.ds.APIPost('/serviceprovider/offeringExpiryDate', data, this.requestingModule);
    }
    //#endregion
    //#region Staff
    getAgentDetail(iPID: number = 0) {
        const data = { ID: iPID };
        return this.ds.APIPost('/serviceprovider/agentDetail', data, this.requestingModule);
    }
    getStaff(iServiceProviderID?: number) {
        const data = { serviceProviderID: iServiceProviderID };
        return this.ds.APIPost('/serviceprovider/staff', data, this.requestingModule);
    }
    removeStaff(iPID: number) {
        const data = { pid: iPID };
        return this.ds.APIPost('/serviceprovider/staff/remove', data, this.requestingModule);
    }
    addStaff(sEmailAddress: string) {
        const data = { emailAddress: sEmailAddress };
        return this.ds.APIPost('/serviceprovider/staff/add', data, this.requestingModule);
    }
    removeStaffRole(iPID: number, iRoleID: number) {
        const data = { pid: iPID, roleID: iRoleID };
        return this.ds.APIPost('/serviceprovider/staff/removeRole', data, this.requestingModule);
    }
    addStaffRole(iPID: number, iRoleID: number) {
        const data = { pid: iPID, roleID: iRoleID };
        return this.ds.APIPost('/serviceprovider/staff/addRole', data, this.requestingModule);
    }
    //#endregion
    //#region Insurances
    getInsurances(iID?: number) {
        const data = { id: iID };
        return this.ds.APIPost('/serviceprovider/insurances', data, this.requestingModule);
    }
    saveInsurances(insurances: any) {
        const data = insurances;
        return this.ds.APIPost('/serviceprovider/saveInsurances', data, this.requestingModule);
    }
    //#endregion
    //#region Licenses
    getLicenses(iID?: number) {
        const data = { id: iID };
        return this.ds.APIPost('/serviceprovider/licenses', data, this.requestingModule);
    }
    saveLicenses(licenses: any) {
        const data = licenses;
        return this.ds.APIPost('/serviceprovider/saveLicenses', data, this.requestingModule);
    }
    //#endregion
    //#region Pricing
    updateOfferPaymentOption(iServiceProviderID: number, iServiceTypeID: number, iPaymentOption: number, bWillChargeOnlyIfCloses: boolean) {
        const data = { serviceProviderID: iServiceProviderID, serviceTypeID: iServiceTypeID, paymentOption: iPaymentOption, willChargeOnlyIfCloses: bWillChargeOnlyIfCloses };
        return this.ds.APIPost('/serviceprovider/updateOfferPaymentOption', data, this.requestingModule);
    }
    addProductRate(oNewRate: any) {
        const data = oNewRate;
        return this.ds.APIPost('/serviceprovider/addProductRate', data, this.requestingModule);
    }
    addProductRateItem(oNewRateItem: any) {
        const data = oNewRateItem;
        return this.ds.APIPost('/serviceprovider/addProductRateItem', data, this.requestingModule);
    }
    savePriceItems(priceItems: any) {
        const data = priceItems;
        return this.ds.APIPost('/serviceprovider/savePriceItems', data, this.requestingModule);
    }
    getServiceItems(oRequest: serviceItemRequestModel) {
        const data = oRequest;
        return this.ds.APIPost('/serviceprovider/serviceOfferingItems', data, this.requestingModule);
    }
    getServiceOfferingDetailContent(oRequest: SPServiceDetailContentRequestModel) {
        const data = oRequest;
        return this.ds.APIPost('/serviceprovider/serviceOfferingDetailContent', data, this.requestingModule);
    }
    saveDetailContent(oRequest: SPServiceDetailContentRequestModel) {
        const data = oRequest;
        return this.ds.APIPost('/serviceprovider/serviceOfferingSaveDetailContent', data, this.requestingModule);
    }
    getServiceDetails(oRequest: serviceItemRequestModel) {
        const data = oRequest;
        return this.ds.APIPost('/serviceprovider/serviceOfferingDetails', data, this.requestingModule);
    }
    saveDetailItems(oRequest: any) {
        const data = oRequest;
        return this.ds.APIPost('/serviceprovider/saveDetailItems', data, this.requestingModule);
    }
    addDetailItem(oRequest: any) {
        const data = oRequest;
        return this.ds.APIPost('/serviceprovider/addDetailItem', data, this.requestingModule);
    }
    getServiceRateSheet(oRequest: serviceRateRequestModel) {
        const data = oRequest;
        return this.ds.APIPost('/serviceprovider/serviceOfferingRateSheet', data, this.requestingModule);
    }
    //#endregion
    getBids(dtStartDate: Date, dtEndDate: Date, iServiceTypeID: number = 0) {
        const data = { startDate: dtStartDate, endDate: dtEndDate, serviceTypeID: iServiceTypeID };
        return this.ds.APIPost('/serviceprovider/getBids', data, this.requestingModule);
    }
    visits(iServicePrpoviderID: number, iListingIDs: number[], iServiceTypeIDs: number[], dtStartDate: Date, dtEndDate: Date, forServiceProvider: boolean = true, fromServiceProvider: boolean = true) {
        const data = { id: iServicePrpoviderID, listingIDs: iListingIDs, serviceTypeIDs: iServiceTypeIDs, startDate: dtStartDate, endDate: dtEndDate, forServiceProvider, fromServiceProvider };
        return this.ds.APIPost('/serviceprovider/visits', data, this.requestingModule);
    }
    getMyLoans(showClosed: boolean = false, bForAllLoanOfficer: boolean = false) {
        const data = { includeHeader: false, getClosed: showClosed, forAllLoanOfficers: bForAllLoanOfficer };
        return this.ds.APIPost('/serviceprovider/loans', data, this.requestingModule);
    }
    getWorkOrders(data: any) {
        /* same as /user/workOrders */
        return this.ds.APIPost('/serviceprovider/workOrders', data, this.requestingModule);
    }
    getWorkOrderProperties(data: any) {
        /* same as /user/workOrders */
        return this.ds.APIPost('/serviceprovider/workOrderProperties', data, this.requestingModule);
    }
    getEscrowForMyWorkOrders(data: any) {
        /* same as /user/workOrders */
        return this.ds.APIPost('/serviceprovider/escrowForMyWorkOrders', data, this.requestingModule);
    }
    //#region Review
    getReviews(iServiceProviderID: number = 0, iWorkOrderID: number = 0, iServiceTypeID: number = 0) {
        const data = { serviceProviderID: iServiceProviderID, workOrderID: iWorkOrderID, serviceTypeID: iServiceTypeID };
        return this.ds.APIPost('/serviceprovider/getReviews', data, this.requestingModule);
    }
    submitReview(iWorkOrderID: number, iRating: number = 0, sComments: string = '') {
        const data = { id: iWorkOrderID, rating: iRating, comments: sComments };
        return this.ds.APIPost('/workOrder/submitReview', data, this.requestingModule);
    }
    submitRebuttal(iReviewID: number = 0, sRebuttal: string = '') {
        const data = { id: iReviewID, comments: sRebuttal };
        return this.ds.APIPost('/workOrder/submitRebuttal', data, this.requestingModule);
    }
    revokeReview(iReviewID: number = 0) {
        const data = { id: iReviewID };
        return this.ds.APIPost('/workOrder/revokeReview', data, this.requestingModule);
    }
    //#endregion
}
