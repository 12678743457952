import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';

import { InsuranceEntity } from '../models/providers/insurance.model';
import { CodeService } from '../common/service/code.service';
import { ServiceProviderService } from './serviceprovider.service';

import { item } from '../models/appCommon.models';
import { trigger } from '@angular/animations';
import { appEnvironment } from '../../environments/environment';
import { navIA } from '../models/constants';
import { Utils, rolePosition } from '../common/utils';

@Component({
    selector: 'app-provider-insurance',
    templateUrl: './insurances.component.html'
})
export class InsuranceComponent extends BaseComponent implements OnInit {

    loading: boolean = true;
    reorderable: boolean = true;
    swapColumns: boolean = false;
    allInsuranceTypes: item[] = [];
    insuranceTypes: item[] = [];
    messages = {
        // Message to show when array is presented
        // but contains no values
        emptyMessage: 'You have not provided any Insurance details.',

        // Footer total message
        // totalMessage: 'total'
    };

    allowAdd: boolean = false;
    allowEdit: boolean = false;
    editing = {};

    public insurances: InsuranceEntity[] = [];
    columns = [{ prop: 'InsuranceType', name: 'Insurance Type' }, { prop: 'IssuingCompany', name: 'Issued By', sortable: false }, { prop: 'PolicyID', name: 'Policy ID', sortable: false }
        , { prop: 'PolicyAmount', name: 'Policy Amount', sortable: false }, { prop: 'IssuingCompanyPhone', name: 'Company Phone', sortable: false }, { prop: 'ExpiryDate', name: 'Expiry Date', sortable: false }];
    saving: number = 0;
    private _to: any;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) private g: GlobalComponent, private cdref: ChangeDetectorRef) {
        super('pgCompanyInsurances', r, g);

        this.allowEdit = document.getElementById(navIA) != null || this.gc.hasRole(rolePosition.admin);
        this.setAllowAdd();
    }

    ngOnInit() {
        let userResponseData;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getInsurances().subscribe((r) => userResponseData = r
            , (error) => { this.loading = false; this.insurances = new Array<InsuranceEntity>(); this.onApiError(error); }
            , () => {
                this.loading = false;

                // To use IsDirty and other Client Side Properties, have to USE Cast ME
                if (userResponseData.data)
                    this.insurances = userResponseData.data.map((c) => { return Utils.castTo(c, new InsuranceEntity()); });

                const existingTypes: number[] = this.insurances.map((c) => c.InsuranceTypeID);

                let stResponseData;
                (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getInsuranceTypes().subscribe((r) => stResponseData = r
                    , (error) => { this.insuranceTypes = new Array<item>(); this.onApiError(error); }
                    , () => {
                        this.allInsuranceTypes = stResponseData.data;
                        this.insuranceTypes = this.allInsuranceTypes.filter((c) => { return !existingTypes.includes(c.id); });

                        this.insurances.forEach((l) => { l.InsuranceType = this.allInsuranceTypes.find((lt) => l.InsuranceTypeID === lt.id).name; });

                    });
            });
    }

    edit(cell, editable) {
        if (this.allowEdit)
            this.editing[cell] = editable;
    }

    updateValue(value, cell, rowIndex) {
        this.editing[rowIndex + '-' + cell] = false;

        if (cell === 'InsuranceType') {
            let _st: number = 0;
            _st = parseInt(value);
            if (isNaN(_st)) _st = 0;
            this.insurances[rowIndex].InsuranceTypeID = _st;
            this.insurances[rowIndex].InsuranceType = this.allInsuranceTypes.find((c) => { return c.id === _st; }).name;
            const existingTypes: number[] = this.insurances.map((c) => { return c.InsuranceTypeID; });
            this.insuranceTypes = this.allInsuranceTypes.filter((c) => { return !existingTypes.includes(c.id); });
        } else {
            this.insurances[rowIndex][cell] = value;
        }

        this.insurances = [...this.insurances];
        this.setAllowAdd();
        this.triggerSave();
    }

    addInsurance() {
        this.loading = true;

        const _o = new InsuranceEntity();
        _o.isDirty = true;

        const existingTypes: number[] = this.insurances.map((c) => { return c.InsuranceTypeID; });
        const _t = this.allInsuranceTypes.filter((c) => { return !existingTypes.includes(c.id); });
        if (_t) {
            _o.InsuranceTypeID = _t[0].id;
            _o.InsuranceType = _t[0].name;
        }

        this.insurances.push(_o);
        this.insurances = [...this.insurances];
        this.editing[(this.insurances.length - 1).toString() + '-InsuranceType'] = true;
        this.editing[(this.insurances.length - 1).toString() + '-IssuingCompany'] = true;
        this.editing[(this.insurances.length - 1).toString() + '-PolicyID'] = true;
        this.editing[(this.insurances.length - 1).toString() + '-PolicyAmount'] = true;
        this.editing[(this.insurances.length - 1).toString() + '-IssuingCompanyPhone'] = true;
        this.editing[(this.insurances.length - 1).toString() + '-ExpiryDate'] = true;
        this.loading = false;
        this.setAllowAdd();

        this.cdref.detectChanges();
    }

    private triggerSave() {

        if (this._to && this.saving <= 0) clearTimeout(this._to);
        if (this.saving < 0) this.saving = 0;

        this._to = setTimeout(() => { this.save(this.insurances); }, appEnvironment.autoSaveTime);
    }

    private save(items: InsuranceEntity[]) {
        let _itemsToUpdate: number = 0;

        if (items != null)
            _itemsToUpdate = items.filter((c) => { return c.ServiceProviderInsuranceID === 0 || c.isDirty; }).length;

        if (_itemsToUpdate > 0) {
            const _readyToSave = items.filter((c) => { return (c.id === 0 || c.isDirty) && c.IsComplete(); });
            if (_readyToSave == null || _readyToSave.length === 0) return;

            // To Save Data for Objects with Properies having GET/SET, have to use toJSON() and JSON.parse
            const _saveData: object[] = _readyToSave.map(x => { return x; });
            const _data = { serviceProviderID: this.user.details.CompanyID, insurances: _saveData };

            // Call Save API
            let saveResponseData;
            this.saving++;
            (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).saveInsurances(_data).subscribe((r) => saveResponseData = r
                , (error) => { this.saving--; items = new Array<InsuranceEntity>(); this.onApiError(error); }
                , () => {
                    this.saving--;
                    this.editing = [];

                    if (saveResponseData.data)
                        items = saveResponseData.data.map((c) => { return Utils.castTo(c, new InsuranceEntity()); });

                    if (items)
                        this.insurances = [...items];
                    else
                        this.insurances = new Array<InsuranceEntity>();

                    this.setAllowAdd();
                });
        }
    }

    private setAllowAdd() {
        // Find any item with Service Type == 0
        this.allowAdd = this.allowEdit && this.insurances != null
                && (this.insurances.filter((c) => { return c.ServiceProviderInsuranceID && c.ServiceProviderInsuranceID != null && c.ServiceProviderInsuranceID === 0; }).length === 0);
        }

}
