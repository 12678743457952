<div *ngIf="showHeader">
    <mat-form-field *ngIf="listings && listings.length > 0">
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<mat-card *ngIf="currentListing">
    <mat-form-field>
        <mat-label class="placeholder">Personal Property to be transferred part of Sale<i class="fa fa-question-circle-o warning-color" title="Personal Property included as part of the sale and will be transferred/handed over to Buyer"></i></mat-label>
        <textarea matInput appDisableAutofill [(ngModel)]="currentListing.includedProperty" maxlength="10000" (change)="onListingChange()"
                  [disabled]="this.currentListing.editableProperties['includedProperty'] != null" ></textarea>
    </mat-form-field>
    <mat-form-field>
        <mat-label class="placeholder">Personal Property NOT to be transferred part of Sale<i class="fa fa-question-circle-o warning-color" title="Personal Property excluded and will NOT be transferred and be taken away by the Sellers"></i></mat-label>
        <textarea matInput appDisableAutofill [(ngModel)]="currentListing.excludedProperty" maxlength="10000" (change)="onListingChange()"
                  [disabled]="this.currentListing.editableProperties['excludedProperty'] != null"></textarea>
    </mat-form-field>
</mat-card>