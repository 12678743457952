import { RecordTimeStamp } from '../base.model';
import * as moment from 'moment';

export class PhoneNumber extends RecordTimeStamp {

    public smsCapable: boolean = false;
    public sendMeSMS: boolean = false;
    public sendMeMarketingSMS: boolean = false;
    public ConsentedToSMSOn: Date;
    public ConsentedToMarketingSMSOn: Date;

    // #region Private Variables
    private _phoneNumberID: number; private _pid: number; private _baseTimeToCallID: number; private _carrierID:number;
    private _phoneNumber: string = ''; private _extension: string = '';
    private _bestTimeToCall: string = '';
    private _phoneNumberType: PhoneNumberType = PhoneNumberType.NotSet;
    private _callable: boolean; private _doNotCall: boolean; private _isPrimary: boolean; private _canLeaveMessage: boolean;
    // #endregion

    public get PhoneNumberID(): number {
        return this._phoneNumberID;
    }
    public set PhoneNumberID(value: number) {
        this._phoneNumberID = value;
    }
    public get PID(): number {
        return this._pid;
    }
    public set PID(value: number) {
        if (this._pid !== value)
            this.isDirty = true;
        this._pid = value;
    }
    public get PhoneNumber(): string {
        return this._phoneNumber;
    }
    public set PhoneNumber(value: string) {
        if (this._phoneNumber !== value)
            this.isDirty = true;
        this._phoneNumber = value;
    }
    public get Extension(): string {
        return this._extension;
    }
    public set Extension(value: string) {
        if (this._extension !== value)
            this.isDirty = true;
        this._extension = value;
    }
    public get BestTimeToCallID(): number {
        return this._baseTimeToCallID;
    }
    public set BestTimeToCallID(value: number) {
        if (this._baseTimeToCallID !== value)
            this.isDirty = true;
        this._baseTimeToCallID = value;
    }
    public get PhoneNumberType(): PhoneNumberType {
        return this._phoneNumberType;
    }
    public set PhoneNumberType(value: PhoneNumberType) {
        if (this._phoneNumberType !== value)
            this.isDirty = true;
        this._phoneNumberType = value;
    }
    public get BestTimeToCall(): string {
        return this._bestTimeToCall;
    }
    public set BestTimeToCall(value: string) {
        this._bestTimeToCall = value;
    }
    public get Callable(): boolean {
        return this._callable;
    }
    public set Callable(value: boolean) {
        if (this._callable !== value)
            this.isDirty = true;
        this._callable = value;
    }
    public get DoNotCall(): boolean {
        return this._doNotCall;
    }
    public set DoNotCall(value: boolean) {
        if (this._doNotCall !== value)
            this.isDirty = true;
        this._doNotCall = value;
    }
    public get CanLeaveMessage(): boolean {
        return this._canLeaveMessage;
    }
    public set CanLeaveMessage(value: boolean) {
        if (this._canLeaveMessage !== value)
            this.isDirty = true;
        this._canLeaveMessage = value;
    }
    public get IsPrimary(): boolean {
        return this._isPrimary;
    }
    public set IsPrimary(value: boolean) {
        if (this._isPrimary !== value)
            this.isDirty = true;
        this._isPrimary = value;
    }

    public set CarrierID(value: number) {
        if (this._carrierID !== value)
            this.isDirty = true;
        this._carrierID = value;
    }
    public get CarrierID(): number {
        return this._carrierID;
    }
}

export enum PhoneNumberType {
    NotSet = 0,
    Home = 1,
    Work = 2,
    Cell = 3,
    MessageService = 4
}
