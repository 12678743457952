<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <div *ngIf="existingDependencies && existingDependencies.length > 0">
        <mat-form-field class="">
            <mat-label class="placeholder">Existing Dependencies<i class="fa fa-question-circle-o warning-color" title="Currently open dependencies."></i></mat-label>
            <mat-select [(ngModel)]="openDependencyID">
                <mat-option *ngFor="let p of existingDependencies" [value]="p.id">{{p.name}}<a *ngIf="p.id > 0 && componentBusy === false" (click)="removeDependency(p.id)" title="Cancel Dependency"><i class="fa fa-times-circle-o danger-color"></i></a></mat-option>
            </mat-select>
        </mat-form-field>

        <hr />
    </div>
    <mat-form-field>
        <mat-label class="placeholder">Dependent for completion on completion of<i class="fa fa-question-circle-o warning-color" title="Select the Work Order on which this work order is dependent"></i></mat-label>
        <mat-select [(ngModel)]="selectedWorkOrderIDs" multiple>
            <mat-option *ngFor="let p of otherServices" [value]="p.id">{{p.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field required>
        <mat-label class="placeholder">Description<i class="fa fa-question-circle-o warning-color" title="Describe the nature of dependency"></i></mat-label>
        <input matInput appDisableAutofill  type=text maxlength="200" [(ngModel)]="dependencyDescription" />
    </mat-form-field>
    <hr />
    <mat-progress-bar mode="buffer" *ngIf="componentBusy === true"></mat-progress-bar>
    <button mat-raised-button (click)="addDependencies()" *ngIf="componentBusy === false">Submit</button>
</div>
