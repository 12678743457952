<div class="app-flex-container app-3col">
    <mat-form-field>
        <mat-label class="placeholder">State(s)</mat-label>
        <mat-select (selectionChange)="stateChanged(event$)" *ngIf="!multiSelectAllowed && !loadingStates" >
            <mat-option *ngFor="let st of states" [value]="st.id">{{st.name}}</mat-option>
        </mat-select>
        <mat-select [(ngModel)]="stateIDs" (selectionChange)="statesChanged(event$)" multiple *ngIf="multiSelectAllowed && !loadingStates">
            <mat-option *ngFor="let st of states" [value]="st.id">{{st.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label class="placeholder">Counties</mat-label>
        <mat-select (selectionChange)="countyChanged(event$)" *ngIf="!multiSelectAllowed && !loadingCounties">
            <mat-option *ngFor="let ct of counties" [value]="ct.id">{{ct.name}}</mat-option>
        </mat-select>
        <mat-select [(ngModel)]="countyIDs" (selectionChange)="countiesChanged(event$)" multiple *ngIf="multiSelectAllowed && !loadingCounties">
            <mat-option *ngFor="let ct of counties" [value]="ct.id">{{ct.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label class="placeholder">Cities</mat-label>
        <mat-select (selectionChange)="cityChanged(event$)" *ngIf="!multiSelectAllowed && !loadingCounties">
            <mat-option *ngFor="let ci of cities" [value]="ci.id">{{ci.name}}</mat-option>
        </mat-select>
        <mat-select [(ngModel)]="cityIDs" (selectionChange)="citiesChanged(event$)" multiple *ngIf="multiSelectAllowed && !loadingCities">
            <mat-option *ngFor="let ci of cities" [value]="ci.id">{{ci.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>