<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-form-field class="first full" appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput appDisableAutofill maxlength=100 [(ngModel)]="newAssessment.description" />
    </mat-form-field>
    <mat-form-field class="full" appearance="outline">
        <mat-label>Amount</mat-label>
        <input matInput appDisableAutofill maxlength=10 [(ngModel)]="newAssessment.amount" (keydown)="utils.numOnlyLimitingLength($event, 12, true)" type="number" class="numeric" />
    </mat-form-field>
    <app-datepicker #txtExpiryDate id="txtExpiryDate" [placeholder]="'Expiry Date'" [minDate]="tomorrow"></app-datepicker>
    <hr />
    <button matButton color="accent" *ngIf="saveReady && !saving" (click)="onSubmit()">Save</button>
    <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
    <div class="alert alert-danger" *ngIf="(error ?? '') !== ''" title="{{error}}">Failed to add new Assessment</div>
</div>
