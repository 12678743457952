import { RecordTimeStamp } from '../base.model'
import { Role } from '../enums.component'
import { item } from '../appCommon.models'
import { Utils } from '../../common/utils';

export class escrow extends RecordTimeStamp {
    public id: number = 0;
    public address: string = '';

    public listingID: number = 0;
    public offerID: number = 0;

    public buyers: string = '';
    public sellers: string = '';
    public purchasePrice: number = 0;
    public creditsFromSeller: number = 0;
    public deposit: number = 0;
    public isValid: boolean = false;
    public isOpen: boolean = false;
    public readyToClose: boolean = false;
    public closingDate: Date = Utils._minDate;

    public myRole: Role = Role.NotSet;
    public canDemand: boolean = false;
    public canDisburseFunds: boolean = false;
    public companyName: string = '';

    public amountAvailableForDisbursement: number = 0;
    public myWorkOrders: item[] = [];

    public balanceDue: number = 0;
    public lender: string = '';
    public loanTerms: string = '';

    public financedAmount: number = 0;
    public downPayment: number = 0;
    public propertyTaxPaid: number = 0;
    public buyersTaxLiability: number = 0;
    public sellersTaxLiability: number = 0;

    public statusStatement: string = '';
    public settlementRoles: any = [];

    public isSeller: boolean = false;
    public isBuyer: boolean = false;
    public isEscrowStaff: boolean = false;
    public isPartOfEscrow: boolean = false;
    public canAddPersonnel: boolean = false;

    public statements: any = [];
    public taxes: any = [];
    public deposits: any = [];
    public demands: any = [];
    public disbursements: any = [];
    public contingencies: any = [];
    public roles: any = [];

    constructor() {
        super();
    }
}

export class escrowTax extends RecordTimeStamp {
    public id: number = 0;
    public taxAuthority: string = '';
    public amount: number = 0;
    public paidBy: Role = Role.NotSet;
    public paidByDescription: string = '';
    public notes: string = '';
    public paidByServiceProviderID: number = 0;
    constructor() {
        super();
    }
}

export class escrowContingency extends RecordTimeStamp {
    public id: number = 0;
    public description: string = '';
    public statusDescription: string = '';
    public fulfilledBy: Role = Role.NotSet;
    public nextSteps: Array<any> = new Array<any>();
    public canSendNotice: boolean = false;
    public hasContingencyDocument: boolean = false;
    public canUploadFulfillmentDocument: boolean = false;
    public hasFulfillmentDocument: boolean = false;
    public canVerify: boolean = false;
    constructor() {
        super();
    }
}

export class escrowDeposit extends RecordTimeStamp {
    public id: number = 0;
    public creditToDescription: string = '';
    public creditTo: Role = Role.NotSet;
    public amount: number = 0;
    public amountVerified: number = 0;

    public notes: string = '';
    public depositedBy: number = 0;
    public exclusivelyForCompanyID: number = 0;
    public exclusivelyForWorkOrderID: number = 0;
    public canVerify: boolean = false;
    public canCancel: boolean = false;
    constructor() {
        super();
    }
    public verified(): boolean { return this.amountVerified && this.amountVerified > 0; }
}

export class escrowDisbursement extends RecordTimeStamp {
    public id: number = 0;
    public disburseTo: Role = Role.NotSet;
    public debitTo: Role = Role.NotSet;
    public companyID: number = 0;
    public disburseToName: string = '';
    public details: string = '';
    public notes: string = '';
    public type: DisbursementInstrumentType = DisbursementInstrumentType.Check ;
    public disbursed: boolean = false;
    public amount: number = 0;
    constructor() {
        super();
    }
}

export class escrowDemand extends RecordTimeStamp {
    public id: number = 0;
    public details: string = '';
    public payorDescription: string = '';
    public demandingEntity: string = '';
    public amount: number = 0;
    public amountApprovedByPayor: number = 0;
    public amountPaid: number = 0;

    public currentStatus: EscrowDemandStatus = EscrowDemandStatus.NotSet;
    public currentStatusDate: Date = Utils._minDate;
    public statusName: string = '';

    public demandDate: Date = Utils._minDate;
    public nextSteps: any[] = [];
    constructor() {
        super();
    }
}
export enum ContingencyStatus {
    NotSet = 0,
    Created = 1,
    Submitted = 2,
    Fulfilled = 3,
    Verified = 4,
    Revoked = 5,
    ReFulfill = 6,
    Cancelled = 7
}
export enum EscrowStatus {
    NotSet = 0,
    Open = 1,
    Closed = 2,
    Cancelled = 3,
    SuspendedToClose = 4,
    Closing = 5,
    Created = 6,
}
export enum EscrowDemandStatus {
    NotSet = 0,
    Demanded = 1,
    Approved = 2,
    Disbursed = 3,
    Declined = 4,
    Cancelled = 5
}

export enum DisbursementInstrumentType {
    Check = 1,
    ElectronicFundTransfer = 2
}
