import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-progressbar',
    template: `
    <div class="progress" >
        <div class="progress-bar {{status}}" [ngClass]="{'active':animate}" role = "progressbar" attr.aria-valuenpw="{{value}}"aria-valuemin="0" aria-valuemax="100" style = "width:{{value}}%" >
            <span [ngClass]="{'sr-only' : !showLabel}" > {{value}} % Complete < /span>
        < /div>
    < /div>`
})
export class ProgressBarComponent {
    @Input() value: number;
    @Input() showLabel: boolean;
    @Input() animate: boolean;
    @Input() status: progressBarStatus = progressBarStatus.default;
}

@Component({
    selector: 'app-svg-guage',
    template: `
  <div class="svgGuage single-chart" style="width:{{svgSize}};display:inline-block;">
    <svg viewBox="0 0 36 36" class="svgGuage circular-chart {{color}}">
      <path class="svgGuage circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path class="svgGuage circle"
        attr.stroke-dasharray="{{value}}, 100"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" class="svgGuage percentage">{{value}}%</text>
    </svg>
  </div>
`
})
export class SVGGuageComponent implements OnInit {
    @Input() value: number;
    @Input() svgSize: string = '48px!important';
    color: string = '';
    constructor() {
        this.color = 'complete';
    }
    ngOnInit() {
        if (this.value == null || this.value <= 0)
            this.value = 1;

        if (this.value >= 75)
            this.color = 'complete';
        else if (this.value >= 50)
            this.color = 'green';
        else if (this.value >= 25)
            this.color = 'warning';
        else if (this.value > 0)
            this.color = 'danger';
        else
            this.color = 'grey';
    }
}

export enum progressBarStatus {
    default = '', info = 'progress-bar-info', success = 'progress-bar-success', danger = 'progress-bar-danger', warning = 'progress-bar-warning'
}
