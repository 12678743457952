import { Component, Input, OnInit, Inject } from '@angular/core';
import { DataService } from '../util/APICaller.component';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';
import { AssetService } from './service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-asset-page',
    templateUrl: './asset.pagedirect.component.html'
})
export class AssetPageComponent extends BaseComponent {
    @Input() assetID: any = 0 ;
    @Input() css: string = 'link';
    @Input() label: string = '';

    constructor(private ds: DataService, rs: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('', rs, g);
        if (this.isNaN(parseInt(this.assetID)))
            this.assetID = 0;
        else
            this.assetID = parseInt(this.assetID);
    }

    gotoAssetRoute(iAssetID: number) {
        let _response;
        if (iAssetID > 0) {
            (new AssetService(this.ds, this.route, this.myResourceCategory)).getAssetRoute(iAssetID).subscribe(
                (data) => { _response = data; }
                , (error) => { }
                , () => {
                    if (_response && _response.data)
                        this.gotoRoute(_response.data.toString());
                });
        }
    }
}
