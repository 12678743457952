import { GlobalComponent } from '../../global.component';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

export class TagLoadService {
    constructor() { }

    init(sModule: string = '', gc?: GlobalComponent, httpClient?: HttpClient) {
        const forModule = !(sModule == null || sModule.toString().trim() === '');

        if (gc != null) {
            gc.langTags = gc?.langTags ?? {};

            if (!forModule || gc.langTags[sModule] == null) {

                let _language = gc.currentLang;
                if (_language == null || _language.trim() === '')
                    _language = 'en';

                const _url = '/assets/i18n/' + _language + (forModule ? '.' + sModule.toLowerCase() : '') + '.json';
                let _data;
                httpClient?.get(_url).subscribe({
                    next: (data) => { _data = data; }
                    , error: (error) => { }
                    , complete: () => {
                        if (_data) {
                            if (forModule)
                                gc.langTags[sModule] = _data[sModule];
                            else
                                gc.langTags = _data;
                        }
                    }
                });
            }
        }
    }
}
