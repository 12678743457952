<div *ngIf="showHeader && listings && listings.length > 0">
    <mat-form-field>
        <mat-label class="placeholder">Properties</mat-label>
        <mat-select [(ngModel)]="listingID">
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="galleryImages && galleryImages.length > 0" style="min-height:200px;">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
</div>
<div *ngIf="!disableAdd">
    <hr/>
    <app-addmedia #addMedia></app-addmedia>
</div>
