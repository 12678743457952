import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalComponent } from '../global.component';
import { Subscription } from 'rxjs/internal/Subscription';
declare var $: any;

@Component({
    selector: 'app-msg-box',
    templateUrl: './messageBox.component.html'
})
export class MessageBoxComponent implements OnInit, OnDestroy {

    private _ms: Subscription;
    private _es: Subscription;

    constructor(@Inject(GlobalComponent) private global: GlobalComponent) { }

    ngOnInit() {
        this._ms = this.global.onMessageNotify.subscribe((o: string) => this.showBox(o, 'lightgreen'));
        this._es = this.global.onError.subscribe((o: string) => this.showBox(o, 'orange'));
    }
    ngOnDestroy() {
        if (this._ms != null) this._ms.unsubscribe();
        if (this._es != null) this._es.unsubscribe();
    }

    close() { $('#mbDiv').animate({ width: 'auto' }).fadeOut(2500).addClass('hidden'); }

    private showBox(value: string, color: string) {
        if (value == null || value === '') {
            this.close(); return;
        }

        $('#mbDivMsg').html(value);
        if ($('#mbDiv')) {

            $('#mbDiv').removeClass('hidden').css('background-color', color).fadeIn(1500).animate({ width: '100%' }, { queue: false, duration: 1500 }).animate({ fontSize: '24px' }, 1500);
            setTimeout(() => {
                $('#mbDiv').animate({ width: 'auto' }).fadeOut(2500);
            }, 10000);
        } else
            alert(value);
    }

}
