<mat-card *ngIf="showHeader && (listings ?? []).length > 0">
    <mat-form-field>
        <mat-label class="placeholder">Properties<i class="fa fa-question-circle-o warning-color" title="Properties for Sale"></i></mat-label>
        <mat-select [(ngModel)]="listingID">
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-card>
<mat-card class="first" *ngIf="(listings ?? []).length == 0">
    <h1 *ngIf="forRole == 2">Currently there are no properties in processing.</h1>
    <h1 *ngIf="forRole == 1">You have not submitted any offer or there are NO offers in processing.</h1>
</mat-card>
<app-work-orders #workOrders *ngIf="listingID > 0" [showListings]="false"></app-work-orders>