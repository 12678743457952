import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit, Output, Input } from '@angular/core';
import { DataService } from '../../util/APICaller.component';
import { BaseDialog } from '../../base.dialog';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Role, downloadableContent, DocumentCategory, DocumentEntityType  } from 'src/app/models';
import { Utils } from '../utils';

@Component({
    selector: 'app-upload-file-dialog',
    templateUrl: './uploadFile.dialog.html'
})
export class UploadFileDialog extends BaseDialog implements OnInit {

    @ViewChild('newDoc') newDoc: any;
    doc: downloadableContent = new downloadableContent();

    newDocumentName: string = '';
    fileExtension: any = ['pdf', 'txt'];
    readyToAdd: boolean = false;

    private entityType: DocumentEntityType;
    private entityCategory: DocumentCategory = DocumentCategory.NotSet;
    private entityTypeKeyID: number;

    constructor(dataservice: DataService, r: Router, public dialogRef: MatDialogRef<UploadFileDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addFileDialog', dataservice, r, dialogRef, data);

        if (data) {
            if (data.type) this.entityType = data.type;
            if (data.category) this.entityCategory = data.category;
            if (data.id) this.entityTypeKeyID = data.id;
        }

        this.dialogHeader = 'File Upload';
    }

    ngOnInit() { }

    fileUploaded($event): void {
        this.fileHandler($event);
        if (this.newDoc) this.newDoc.nativeElement.value = '';
    }

    addDoc() {
        if (this.doc == null) return;

        this.doc['entityType'] = this.entityType;
        this.doc['entityID'] = this.entityTypeKeyID;
        this.doc['category'] = this.entityCategory;

        this.doc['description'] = this.newDocumentName;

        this.close(this.doc);
    }

    fileHandler($event): void {
        const text = [];
        const _file = $event.srcElement.files;

        if (_file) {
            const _fName = _file[0].name;
            if (_fName.lastIndexOf('.') > 0) {
                const _fExtension = _fName.substring(_fName.lastIndexOf('.') + 1);
                if (this.fileExtension.indexOf(_fExtension) < 0) {
                    this.showMessage('Invalid file uploaded');
                    return;
                }
                if (this.doc == null) this.doc = new downloadableContent();
                this.doc.name = _fName.substring(0, _fName.lastIndexOf('.'));
                if (_file[0].type)
                    this.doc.mime = _file[0].type;
            } else {
                this.showMessage('Invalid file uploaded');
                return;
            }
        }

        const input = $event.target;
        const reader = new FileReader();
        reader.readAsDataURL(input.files[0]);

        reader.onloadend = (data) => {
            this.doc.content = reader.result;
            this.readyToAdd = !Utils.isNullOrEmpty(this.newDocumentName);
        };

        reader.onerror = () => {
            alert('Unable to read ' + input.files[0]);
        };
    }
}
