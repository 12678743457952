import { Component, Inject, Input} from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router} from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { WorkOrderService } from '../../workorders/workorder.service';
import { SPBid, BidType, item, BidRFQStatus } from 'src/app/models';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-new-bid-dialog',
    templateUrl: './newBid.dialog.html'
})
export class NewBidDialog extends BaseDialog {

    @Input() dialogHeader: string = 'New Bid';
    options: any = [];
    selectedOptions: number[] = [];
    newBid: SPBid = new SPBid();
    workOrderID: number = 0;
    constructor(dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent, private dialog: MatDialog
        , public dialogRef: MatDialogRef<NewBidDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addServiceRateDialog', dataservice, r, dialogRef, data);

        if (this.data) {
            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'New Bid for ' + this.data.header;
            }

            if (this.data.bid)
                this.newBid = Utils.castTo(this.data.bid, new SPBid());
            else {
                this.newBid = new SPBid();
                if (this.data.bidType)
                    this.newBid.bidType = this.data.bidType;
                else
                    this.newBid.bidType = BidType.ServiceWorkOrder;
            }

            if (this.newBid.id === 0) {
                if (this.data.workOrderID) this.workOrderID = this.data.workOrderID;
            } else if (this.newBid.workOrderID > 0)
                this.workOrderID = this.newBid.workOrderID ;

            if (this.workOrderID === 0)
                setTimeout(() => { this.close(null); }, 500);
        }
    }

    get saveReady() {
        return (this.newBid.fixedPrice > 0 || this.newBid.percentagePrice) && (moment(this.newBid.completeBy).isAfter(this.today) || this.newBid.completeInDays > 0);
    }

    close(data) { this.dialogRef.close(data); }

    expiresOn(value) { this.newBid.expiresOn = value; }
    completeBy(value) { if (value) this.newBid.completeBy = value; }

    saveBid(bSubmit: boolean = false) {
        const _options = [];
        for (const o of this.selectedOptions)
            _options.push(new item(o, ''));
        this.newBid.options = _options;

        if (bSubmit === true)
            this.newBid['newStatus'] = BidRFQStatus.Submitted;

        let _response;
        this.componentBusy = true;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).saveBid(this.workOrderID, this.newBid).subscribe((r) => _response = r
            , (error) => { this.componentBusy = false; this.onApiError(error); }
            , () => {
                this.componentBusy = false;
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.error = _response.error;
                    else if (_response.data)
                        this.close(_response.data);
            });
    }

}
