<div class="{{css}}" *ngIf="serviceProvider">
    <h2 class="pageHeader" *ngIf="showHeader">Company Profile</h2>
    <mat-card *ngIf="forLookup" style="margin-bottom:10px">
        <app-autocomplete #autoCompleteSPName [required]="true" (onItemSelected)="companySelected($event)"
                          #acCompany id="acCompany" [values]="companies" [placeholder]="'Name'" (itemClass)="'normal'" [value]="serviceProvider.ServiceProviderID"></app-autocomplete>
    </mat-card>
    <div id="spInputDiv">
        <mat-card  style="margin-bottom:10px">
            <mat-form-field *ngIf="!forLookup" class="withMatError">
                <input matInput appDisableAutofill placeholder="Name" required requiredvalidator maxlength="100" [(ngModel)]="serviceProvider.Name" (ngModelChange)="isCMDirty = true" #spname />
                <mat-error [hidden]="spname.valid || spname.pristine">Service Provider Name is required.</mat-error>
            </mat-form-field>
            <div class="app-flex-container app-2col" *ngIf="captureTax && serviceProvider">
                <mat-form-field>
                    <mat-select [(ngModel)]="serviceProvider.TaxEntity" (ngModelChange)="isCMDirty = true" placeholder="Tax Entity" [compareWith]="compareIds" required
                                [disabled]="serviceProvider.IsReadOnly" #spTaxT>
                        <mat-option *ngFor="let te of taxEntities" [value]="te.id" class="auto">{{te.name}}</mat-option>
                    </mat-select>
                    <mat-error [hidden]="spTaxT.valid || spTaxT.pristine">Tax Type is required, please provide a valid phone number. Company account may be deactivated without valid and verifiable Tax ID.</mat-error>
                </mat-form-field>
                <mat-form-field class="withMatError">
                    <input matInput appDisableAutofill required requiredvalidator placeholder="Federal Tax ID" maxlength="20" [(ngModel)]="serviceProvider.FederalTaxID" [disabled]="serviceProvider.IsReadOnly" (ngModelChange)="isCMDirty = true" #spTaxID />
                    <mat-error [hidden]="spTaxID.valid || spTaxID.pristine">TAX ID is required, please provide a valid Tax ID. {{editingMyCompany ? 'Company account may be deactivated without valid and verifiable Tax ID.' : '' }}</mat-error>
                </mat-form-field>
            </div>
            <div class="app-flex-container app-2col" *ngIf="serviceProvider">
                <mat-form-field class="withMatError">
                    <input matInput appDisableAutofill required requiredvalidator emailvalidator placeholder="Email Address" maxlength="100" [(ngModel)]="serviceProvider.Email" [disabled]="serviceProvider.IsReadOnly" #em="ngModel" (ngModelChange)="isCMDirty = true" #spemail/>
                    <mat-error [hidden]="spemail.valid || spemail.pristine">Email is required and format should be <i>john&#64;doe.com</i>. {{editingMyCompany ? 'Company account may be deactivated without valid and verifiable email address.' : ''}}</mat-error>
                </mat-form-field>
                <mat-form-field class="withMatError">
                    <input matInput appDisableAutofill required requiredvalidator placeholder="Primary Phone #" maxlength="20" [(ngModel)]="serviceProvider.Phone" [textMask]="{mask: phoneMask}" [disabled]="serviceProvider.IsReadOnly" (ngModelChange)="isCMDirty = true" #spphone />
                    <mat-error [hidden]="spphone.valid || spphone.pristine">Phone number is required, please provide a valid phone number. {{editingMyCompany ? 'Company account may be deactivated without valid and verifiable phone number.' : ''}}</mat-error>
                </mat-form-field>
            </div>
            <div class="app-flex-container app-2col" *ngIf="captureWebInfo && serviceProvider">
                <mat-form-field>
                    <input matInput appDisableAutofill placeholder="Web Site" maxlength="100" [(ngModel)]="serviceProvider.WebSite"  [disabled]="serviceProvider.IsReadOnly" (ngModelChange)="isCMDirty = true"/>
                </mat-form-field>
                <mat-form-field>
                    <input matInput appDisableAutofill placeholder="Logo URL" maxlength="100" [(ngModel)]="serviceProvider.LogoURL"  [disabled]="serviceProvider.IsReadOnly" (ngModelChange)="isCMDirty = true"/>
                </mat-form-field>
            </div>
        </mat-card>
        <mat-card style="margin-bottom:10px">
            <h3 class="pageHeader">Address <span (click)="newAddress()" title="New Address" *ngIf="canAddNewAddress"><i class="fa fa-plus-circle undo"></i></span></h3>
            <mat-tab-group (selectedTabChange)="this.selectedAddressIndex = $event.index" [selectedIndex]="selectedAddressIndex">
                <mat-tab *ngFor="let address of serviceProvider.Addresses;let i = index" [attr.data-index]="i">
                    <ng-template mat-tab-label>
                        <label [ngClass]="{'danger':address.Active == false}">{{'(' + (i + 1) + ') ' + (address.EntityAddressID == 0 ? 'New' : address.AddressTypeName())
                        }}</label>
                        <a *ngIf="address.Active == true && editable" (click)="removeAddress(i)" title="Remove"><i class="fa fa-times-circle warning"></i></a>
                        <a *ngIf="address.Active == false && editable" (click)="undelete(i)"><i class="fa fa-refresh undo" title="Undelete/Recover"></i></a>
                        </ng-template>
                        <div class="alert alert-warning" *ngIf="address.id > 0 && address.Active == false && editable">Address will not be saved.</div>
                        <app-base-address [showOwnerShip]="false" id="spA{{i}}" [canChangeAddressType]="address !=null && address.EntityAddressID == 0" [Address]="address"
                                          (addressAdded)="onAddressAdded($event)" (addressChanged)="onAddressChanged($event)" [index]="i"
                                          [disabled]="serviceProvider.IsReadOnly"></app-base-address>
                        </mat-tab>
                        </mat-tab-group>
                        <div class="alert alert-danger" *ngIf="isCMDirty && isAddressIncomplete">One or more Address is Incomplete</div>
</mat-card>
        <mat-card *ngIf="isCMDirty && showSave && !saving && !serviceProvider.IsReadOnly">
            <a class="btn btn-primary" (click)="saveProfile()">Save</a>
        </mat-card>
    </div>
</div>