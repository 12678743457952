import { MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

export enum handover {
    offerID = 'o'
    , listingID = 'l'
    , renewListingID = 'rl'
    , lienID = 'li'
    , loanOption = 'lo'
    , escrowID = 'e'
    , helpCategory = 'cat'
}

export const buyerStart: any = JSON.stringify({ id: 5243, name: 'buyer' });
export const sellerStart: any = JSON.stringify({ id: 5241, name: 'seller' });
export const spStart: any = JSON.stringify({ id: 4047, name: 'professionals' });

export const appSnackBarDisplay = { duration: 6000, panelClass: ['mat-toolbar', 'mat-primary'] };
export const appSnackBarDisplayTop = { duration: 6000, panelClass: ['mat-toolbar', 'mat-primary'], verticalPosition: ('top' as MatSnackBarVerticalPosition)  };
export const appSnackBarWarningTop = { duration: 6000, panelClass: ['mat-toolbar', 'mat-warning'], verticalPosition: ('top' as MatSnackBarVerticalPosition) };

export const navIA: string = 'navIA'; // NAV IA Element ID

export const myActiveOffers: string = 'myActiveOffers';
export const myActiveLoans: string = 'myActiveLoans';

export enum AppURLs {
    aboutUs = '/about',
    accountWelcome = '/account/welcome',
    agentSearch = '/agent/search',
    assetPage = '/p',
    contactUs = '/contactus',
    findHome = '/home/find',
    help = '/help',
    listing = '/listing/listing',
    listingCompare = '/listing/compare',
    listingDetail = '/listing/detail',
    listingOffers = '/listing/listingoffers',
    listingStatus = '/listing/status',
    listingViewing = '/listing/viewing',
    loan = '/loan/loan',
    loans = '/loan/loans',
    login = '/login',
    offer = '/offer/offer',
    register = '/register',
    serviceProviderSearch = '/company/search',
    serviceProviderProfile = '/company/profile',
    serviceProviderPriceList = '/provider/pricelist',
    serviceProviderRenew = 'register/service',
    sellerNewListing = '/seller/newlisting',
    sellerWorkOrders = '/seller/workorders',
    termsOfUse = '/termsofuse',
    privacyPolicy = '/privacypolicy',
    unsubscribe = '/unsubscribe'
}
