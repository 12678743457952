<div class="appContent" *ngIf="editMode === false">
    <h2 class="pageHeader" *ngIf="canEdit === true"><a (click)="editMode=!editMode" class="button">Edit</a></h2>
    <div hrefToRouterLink [innerHTML]="content | safeHtml"> </div>
</div>
<div style="padding:10px 0px" *ngIf="editMode">
    <mat-progress-bar mode="buffer" *ngIf="savingEditor"></mat-progress-bar>
    <mat-chip-set *ngIf="!savingEditor">
        <mat-chip (click)="publish()">Publish</mat-chip>
        <mat-chip (click)="save()">Save</mat-chip>
        <mat-chip (click)="discard()">Discard Changes</mat-chip>
    </mat-chip-set>
</div>
<h2 class="pageHeader" *ngIf="editMode">
    <mat-form-field class="first">
        <mat-label class="placeholder">{{pageTitle}}</mat-label>
        <input matInput [(ngModel)]="this.asset.title" maxlength="200" />
    </mat-form-field>
</h2>
<angular-editor *ngIf="canEdit === true && editMode === true" #contentEditor [(ngModel)]="this.asset.content" (config)="editorConfig"></angular-editor>