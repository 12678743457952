import { Address } from './address.model';
import { RecordTimeStamp } from './base.model';
import { BookmarkType } from './enums.component';

export class Session extends RecordTimeStamp{
    public sessionID: string = '';
    public name: string = '';
    public offsetMinutesFromServer: number = 0;
    public details: User = new User();
    public forcePasswordChange: boolean = false;
    public isoLanguageCode: string = '';
    constructor() { super(); }
}

export class User extends RecordTimeStamp {
    public PID: number = 0;
    public FirstName: string = '';
    public LastName: string = '';
    public MiddleName: string = '';
    public Name: string = "";
    public EmailAddress: string = '';

    // public IsAdministrator: boolean = false;
    public Bookmarks: Bookmark[] = [];
    public PrimaryAddress: Address = new Address();
    public CompanyID: number = 0;
    // public IsAgent: boolean = false;
    // public IsLenderStaff: boolean = false;
    // public IsEscrowStaff: boolean = false;
    public MessagesSinceLastLogin: number = 0;
    public roles: number = 0;
    constructor() {
        super();
        this.Bookmarks = new Array<Bookmark>();
        this.PrimaryAddress = new Address();
        this.FirstName = this.MiddleName = this.LastName = '';
    }

}

export class Bookmark extends RecordTimeStamp {
    public id: number = 0;

    public EntityID: number = 0;
    public Type: BookmarkType = BookmarkType.All;
    public EntityName: string = '';

    constructor() { super(); this.EntityName = ''; }

    public get name(): string { return this.EntityName; }
    public set name(v: string) { this.EntityName = v; }
    public get address(): string { return this.EntityName; }
    public set address(v: string) { this.EntityName = v; }
    public get listingID(): number { return this.EntityID; }
    public set listingID(v: number) { this.EntityID = v; }

}
