<div *ngIf="showHeader">
    <mat-form-field *ngIf="listings && listings.length > 0">
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="currentEscrow && currentEscrow.id > 0">
        Closing Date - {{currentEscrow.closingDate}}
        <a *ngIf="currentEscrow.isValid" (click)="showCIC()">Compliance Items</a>
        <a *ngIf="currentEscrow.isValid" title='Purchase Agreement' (click)="showPA()"><span class='fa-stack'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-list-alt fa-stack-1x fa-inverse'></i></span></a>
    </div>
</div>
<div *ngIf="showHeader">
    <mat-form-field *ngIf="escrows && escrows.length > 0">
        <mat-select [(ngModel)]="escrowID">
            <mat-label class="placeholder">Escrow</mat-label>
            <mat-option *ngFor="let l of escrows" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div>
	<a (click)="newDeposit()" title="" class="btn float-right" *ngIf="currentEscrow && currentEscrow.isOpen === true && (currentEscrow.isBuyer === true || currentEscrow.isSeller === true || currentEscrow.isEscrowStaff === true)">New Deposit</a> <app-escrow-deposit-instructions [escrowID]="escrowID"></app-escrow-deposit-instructions>
	<ng-template [ngIf]="(this.deposits && this.deposits.length > 0)" [ngIfElse]="noDeposits">
		<hr />
		<div *ngFor="let deposit of deposits  | paginate: { itemsPerPage: depositsOnPage , currentPage: depositsPageNumber }; let idx = index " class="section" style="background-color: {{idx%2 ? 'lightgrey': ''}};padding:20px;">
			<div class="app-flex-container app-2col">
				<div>
					<label class="caption">Credited To</label>
					<label class="data-value auto">{{deposit.creditToDescription}}</label>
				</div>
				<div>
					<label class="caption">Credited To</label>
					<label class="data-value auto">{{deposit.creditToDescription}}</label>
				</div>
				<div class="app-flex-container app-2col">
					<div>
						<label class="caption">Amount</label>
						<label class="data-value auto">{{deposit.amount > 0 ? FormatNumberToCurrency(deposit.amount) : ""}}</label>
						<a (click)="cancel(deposit.id)" title="Cancel" *ngIf="deposit.canCancel === true"><i class="fa fa-times-circle danger"></i></a>
					</div>
					<div>
						<span *ngIf="deposit.verified === true" class="green">
							<label class="caption">Verified Amount</label><label class="data-value auto">{{FormatNumberToCurrency(deposit.amountVerified)}}</label>
						</span>
						<mat-form-field *ngIf="deposit.verified === false && deposit.canVerify === true">
							<mat-label class="placeholder">Verified Amount</mat-label>
							<input matInput appDisableAutofill type="number" class="small number" maxlength="10" [(ngModel)]="deposit.amountVerified" style="width:calc(100% - 30px)" />
							<a (click)="verifyDeposit(deposit)" title="Verify" class="float-right"><i class="fa fa-thumbs-up green"></i></a>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div style="text-align:center" *ngIf="deposits && deposits.length > 0">
			<hr />
			<pagination-controls (pageChange)="depositsPageNumber = $event"></pagination-controls>
		</div>
	</ng-template>
    <ng-template #noDeposits>
        <h3 *ngIf="this.componentBusy === false">No deposits has been submitted.</h3>
    </ng-template>
</div>