import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

import { DataService } from '../../../util/APICaller.component';
import { GlobalComponent } from '../../../global.component';

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { item } from '../../../models';
import { DocumentService } from '../document.service';
import { BaseDialog } from '../../../base.dialog';
import { Utils } from '../../utils';

@Component({
    selector: 'app-document-permissions',
    templateUrl: './permissions.dialog.html'
})
export class DocumentPermissionsDialog extends BaseDialog implements OnInit {

    dialogHeader: string = 'Add/Change Document Permission(s)';
    form: UntypedFormGroup;

    users: item[] = [];

    selectedUser: number = 0;
    documentID: number = 0;
    saving: boolean = false;
    isReader: boolean = false;
    isEditor: boolean = false;
    isOwner: boolean = false;

    selectedPerson: number = 0;
    error: string = '';

    private _people: item[] = [];

    constructor(dataservice: DataService, private r: Router, @Inject(GlobalComponent)  gc: GlobalComponent, private dialog: MatDialog
        , public dialogRef: MatDialogRef<DocumentPermissionsDialog>, fb: UntypedFormBuilder, private cdref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('documentPermissions', dataservice, r, dialogRef, data);
    }

    get people() { if (this._people == null) this._people = new Array<item>(); return this._people; }
    set people(v: item[]) { if (v == null) v = new Array<item>(); this._people = v; }

    get saveReady() { return this.selectedUser > 0 && (this.isEditor || this.isOwner || this.isReader); }

    ngOnInit() {
        if (this.data) {
            if (this.data.id) this.documentID = this.data.id;
            if (this.data.pid) this.selectedPerson = this.data.pid;
            if (this.data.header) this.dialogHeader = this.data.header;
        }

        if (this.documentID === 0 || this.people.length === 0) {
            setTimeout(() => {
                this.close(null);
            }, 1000);
        }

        // Remove Self from the List.
        this.people = this.people.filter(p => { return p.id !== this.user.details.PID; });
        // Set First Item as selected item
        if (this.people.length > 0) this.selectedPerson = this.people[0].id;
        // if passed PID is missing in Select Person List
        if (this.people.find(p => { return p.id === this.selectedPerson; }) == null) this.selectedPerson = 0;
    }


    onSubmit() {
        let _response;
        (new DocumentService(this.dataservice, this.r, this.myResourceCategory)).changePermission(this.documentID, this.selectedPerson, this.isReader, this.isEditor, this.isOwner).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.close({ canRead: this.isReader, canEdit: this.isEditor, isOwner: this.isOwner });
                    else
                        this.error = 'Failed setting/changing permissions';
                }
            });
    }
}
