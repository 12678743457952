<div class="appContent" id="divEditListing">
    <h2 class="pageHeader" *ngIf="currentListing && currentListing.id > 0">
        View/Edit Listing
        <span *ngIf="(listings ?? []).length <= 1 && currentListing?.address?.StreetName ?? '' !== ''" class="small noborder" title="#{{currentListing.id}}">
            [{{currentListing.address.StreetName + (currentListing.address.City ? ', ' + currentListing.address.City : '') + (currentListing.address.State ? ', ' + currentListing.address.State : '')}}]
        </span>
    </h2>
    <mat-progress-bar mode="buffer" *ngIf="this.componentBusy"></mat-progress-bar>
    <mat-card *ngIf="(listings ?? []).length > 0" class="section">
        <mat-form-field>
            <mat-label class="placeholder">Properties&nbsp;<i class="fa fa-question-circle-o warning-color" title="Properties for Sale"></i></mat-label>
            <mat-select [(ngModel)]="listingID" (selectionChange)="setListing(listingID)">
                <mat-option class="auto" *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card>
    <div class="app-flex-container app-2col-edit" [ngClass]="{'hidden' : this.currentListing == null || this.currentListing.id <= 0}" id="divEditTabs">
        <mat-card>
            <mat-stepper [linear]="false" [selectedIndex]="iStepperIndex" orientation="horizontal">
                <mat-step [completed]="false">
                    <ng-template matStepLabel>General</ng-template>
                    <app-listing-edit-general #general [showHeader]="false"></app-listing-edit-general>
                </mat-step>
                <mat-step [completed]="false">
                    <ng-template matStepLabel>Characteristics</ng-template>
                    <app-listing-characteristitcs #characteristitcs [showHeader]="false" [showMedia]="false"></app-listing-characteristitcs>
                </mat-step>
                <mat-step [completed]="false">
                    <ng-template matStepLabel>Assessments/Taxes</ng-template>
                    <app-listing-assessment #assessments [showHeader]="false"></app-listing-assessment>
                </mat-step>
                <mat-step [completed]="false">
                    <ng-template matStepLabel>Disclosures</ng-template>
                    <app-listing-disclosures #disclosures [showHeader]="false"></app-listing-disclosures>
                </mat-step>
                <mat-step [completed]="false">
                    <ng-template matStepLabel>Photos/Media</ng-template>
                    <app-listing-media #media [showHeader]="false"></app-listing-media>
                </mat-step>
                <mat-step [completed]="false">
                    <ng-template matStepLabel>Sellers</ng-template>
                    <app-listing-roles #sellers [showHeader]="false" [forRole]="Role.Seller"></app-listing-roles>
                </mat-step>
                <mat-step [completed]="false">
                    <ng-template matStepLabel>Liens</ng-template>
                    <app-listing-liens #liens></app-listing-liens>
                </mat-step>
                <mat-step [completed]="false">
                    <ng-template matStepLabel>Personal Property</ng-template>
                    <app-listing-personal-property #personalProperty [showHeader]="false"></app-listing-personal-property>
                </mat-step>
            </mat-stepper>
        </mat-card>
        <div *ngIf="currentListing">
            <mat-card *ngIf="this.currentListing && this.currentListing.agentDisclosures && this.currentListing.agentDisclosures.length > 0">
                <div *ngFor="let disc of this.currentListing.agentDisclosures"><a (click)="viewAgentDisclosure(disc.id, disc.accepted)">{{disc.subject}}</a></div>
            </mat-card>
            <div class="form-group">
                <a (click)="GoToURLWithSession(appURLs.listingViewing, this.handover.listingID, this.currentListing.id)">Open House/Visitors</a>
            </div>
            <div class="form-group">
                <a (click)="GoToURLWithSession(appURLs.sellerWorkOrders, this.handover.listingID, this.currentListing.id)">Work Order(s)</a>
            </div>
            <div class="form-group">
                <a (click)="GoToURLWithSession(appURLs.listingOffers, this.handover.listingID, this.currentListing.id)">Offer(s)</a>
            </div>
            <div class="form-group">
                <a (click)="GoToURLWithSession(appURLs.listingStatus, this.currentListing.currentEscrowID > 0 ? this.handover.escrowID : this.handover.listingID,  this.currentListing.currentEscrowID > 0 ? this.currentListing.currentEscrowID : this.currentListing.id)">Status Details <i class="material-icons">playlist_add_check</i></a>
            </div>
            <a title="Escrow Status Details" *ngIf="currentListing.isEscrowOpen" (click)="showPreCloseMessage()"><span class="fa-stack"><i class="fa fa-circle fa-stack-2x"></i><i class="fa fa-list-alt fa-stack-1x fa-inverse"></i></span></a>
            <div style="display:none">TODO UpSell Insurance</div>
        </div>
    </div>
    <mat-card *ngIf="currentListing && currentListing.id > 0">
        <span *ngIf="this.sellerAgentPID > 0"><strong>Agent</strong> - {{sellerAgentName}}<i class="fa fa-exclamation-triangle danger" title="Listing Agreement Expired" *ngIf="listingAgreementExpired"></i></span>
        <i class="fa fa-comment green" (click)="sendNotice()" title="Send message to Seller(s) to Sign Listing Agreement" *ngIf="this.currentListing.canSendNoticeToSeller"></i>
        <div>
            <div *ngIf="currentListing.address.isDirty === true">
                <label class="caption auto warning">You have changed the address. Disclosures will be re-initialized. We recommend you review all other relevant information about your property.</label>
            </div>
            <div><label class="caption">{{'System.CurrentStatus' | translateTag}}</label> <label class="caption auto link" (click)="GoToURLWithSession(appURLs.listingOffers, this.handover.listingID, currentListing.id)">{{currentListing.statusName}}<span *ngIf="currentListing.currentStatusDate"><i class="fa fa-calendar" title="{{utils.DisplayDateTime(currentListing.currentStatusDate)}}"></i></span></label></div>
            <app-next-status-actions #statusActions [actions]="currentListing.nextSteps" (itemSelected)="listingChangeStatus($event)"></app-next-status-actions>
        </div>
    </mat-card>
</div>