import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, Output, OnDestroy } from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { item, lien, BaseResponse } from '../../models';

import { ListingService } from '../listing.service';
import { ListingBaseComponent } from '../listing.base.component';
import { AddSellerLienDialog } from '../dialogs/addSellerLien.dialog';
import { GlobalComponent } from '../../global.component';
import { MatAccordion } from '@angular/material/expansion';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../common/utils';
@Component({
    selector: 'app-listing-liens',
    templateUrl: './listingLiens.component.html'
})
export class ListingLiensComponent extends ListingBaseComponent implements OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    liens: lien[] = [];
    toBePaidBy: item[] = [];
    step: number = 0;

    canEditComponent: boolean = false;
    private _lcs: Subscription;
    constructor(ds: DataService, rs: Router, private dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingLien', ds, rs, gc);
        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindLiens();
        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    addNewLien() {
        const dialogRef = this.dialog.open(AddSellerLienDialog, {
            data: {
                id: this.currentListing.id
                , message : 'Lien recorded for the Listing'
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (this.liens) {
                    if (this.liens.length === 0)
                        this.liens.push(Utils.castTo(result, (new lien())));
                    else
                        this.liens.splice(0, 0, Utils.castTo(result, new lien()));
                } else {
                    this.liens = [];
                    this.liens.push(Utils.castTo(result, new lien()));
                }
                this.liens = [...this.liens];
            }
        });
    }

    removeLien($event, id) {
        this.cancelElementEvent($event);
        let _response: BaseResponse;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).removeLien(this.listingID, id).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (Utils.isNullOrEmpty(_response.error)) {
                        if (this.liens)
                            this.liens = [...this.liens.filter((_l) => _l.id !== id )];
                        else
                            this.liens = new Array<lien>();
                    } else {
                        this.showError(_response.error);
                    }
            });
    }

    bindLiens() {
        let _response: BaseResponse;
        this.liens = [];

        if (this.currentListing && this.currentListing.id > 0 && this.currentListing.id === this.listingID && this.currentListing.liens && this.currentListing.liens.length > 0) {
            this.liens = this.currentListing.liens.map((c) => Utils.castTo(c, new lien()));
            this.canEditComponent = this.currentListing.canEditLien;
        } else {
            const _s = (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getLiens(this.listingID);
            if (_s) {
                _s.subscribe((r) => _response = r
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response && _response.data) {
                            const _listingPresent = this.currentListing && this.currentListing.id > 0;
                            if (!_listingPresent) {
                                this.setCurrentListing(_response.data);
                                this.currentListing.liens = _response.data.liens;
                            } else
                                this.currentListing.liens = _response.data.liens;

                            this.canEditComponent = this.currentListing.canEditLien;

                            if (_response.data.liens)
                                this.liens = _response.data.liens.map((c) => Utils.castTo(c, new lien()));
                            else
                                this.liens = new Array<lien>();
                        }
                    });
            }
        }
    }
}
