import { Component, ChangeDetectorRef, ViewChild, Inject, Input } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { CodeService } from '../../common/service/code.service';
import { WorkOrderService } from '../../workorders/workorder.service';

import { ServiceProviderSearchComponent } from '../../sp/searchServiceProvider.component';
import { Role } from '../../models/enums.component';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-create-repair-workorder-dialog',
    templateUrl: './createRepairWorkOrder.dialog.html'
})
export class CreateRepairWorkOrderDialog extends BaseComponent {

    @ViewChild('searchSP') searchSP: ServiceProviderSearchComponent;
    @Input() allowToPickSP: boolean = false;
    Role: typeof Role = Role;

    workOrderID: number = 0;
    repairDetails: any;

    serviceTypes: any = [];
    requiredBeforeCOE: boolean = false;
    selectedServiceTypeID: number = 0;
    paidBy: Role = Role.Buyer;
    repairInstructions: string = '';

    error: string = '';
    dialogHeader: string = 'Repair Details';

    constructor(private dataservice: DataService, private r: Router, private dialog: MatDialog
        , public dialogRef: MatDialogRef<CreateRepairWorkOrderDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('dlgCreateRepairWorkOrder', r);

        if (this.data) {
            if (this.data.id) this.workOrderID = this.data.id;

            if (this.workOrderID > 0) {
                let _response;
                (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).getRepairDetails(this.workOrderID).subscribe((_r) => { _response = _r; }
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response && _response.data) {
                            this.repairDetails = _response.data;
                        }
                    });
            } else {
                setTimeout(() => { this.close(null); }, 100);
            }

            if (this.searchSP)
                this.searchSP.selectPrivateOnly = false;

        }
    }
    get saveReady(): boolean { return this.paidBy > 0 && this.selectedServiceTypeID > 0; }

    close(data) { this.dialogRef.close(data); }

    repairByChange(event$) {
        this.paidBy = event$.value;
    }

    createWO() {
        const data = { id: this.workOrderID, instructions: this.repairInstructions, serviceTypeID: this.selectedServiceTypeID, paidBy: this.paidBy, completeBeforeCOE: this.requiredBeforeCOE };
        let _response;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).createRepairWorkOrder(data).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.error = _response.error;
                    else
                        this.close(true);
                }
            });
    }
}
