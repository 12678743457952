<mat-progress-bar mode="inderminate" *ngIf="componentBusy === true"></mat-progress-bar>
<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            Main Section
        </mat-panel-title>
        <mat-panel-description>
            <input placeholder="Sub Query Template Version" maxlength="20" [(ngModel)]="merge.version" (change)="setMergeDirty()" title="Format OLD (Sub Query Template in Main Template) or New (Sub Query Template with Sub Query)" />
            <mat-checkbox [(ngModel)]="merge.forMessageOnly" title="For Messaging Only" class="float-right">Messaging Only</mat-checkbox>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
        <div class="app-flex-container app-2col">
            <mat-form-field class="first">
                <input matInput placeholder="From Email" maxlength="200" [(ngModel)]="merge.fromEmailAddress" (change)="setMergeDirty()" />
            </mat-form-field>
            <mat-form-field class="first">
                <input matInput placeholder="Name" maxlength="200" [(ngModel)]="merge.fromEmailName" (change)="setMergeDirty()" />
            </mat-form-field>
        </div>
        <div class="app-flex-container app-2col">
            <mat-form-field>
                <input matInput placeholder="Reply To" maxlength="200" [(ngModel)]="merge.replyToEmailAddress" (change)="setMergeDirty()" />
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="BCC" maxlength="200" [(ngModel)]="merge.bccEmailAddress" (change)="setMergeDirty()" />
            </mat-form-field>
        </div>
        <div class="app-flex-container app-2col">
            <mat-form-field>
                <input matInput placeholder="Subject" maxlength="200" [(ngModel)]="merge.subject" (change)="setMergeDirty()" />
            </mat-form-field>
            <mat-form-field>
                <mat-label class="placeholder">Subject Column</mat-label>
                <mat-select [(ngModel)]="merge.subjectColumn" (selectionChange)="setMergeDirty()">
                    <mat-option *ngFor="let column of columns" [value]="column" class="full">{{column}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="app-flex-container app-2col">
            <mat-form-field>
                <mat-label class="placeholder">Email Address Column</mat-label>
                <mat-select [(ngModel)]="merge.emailAddressColumn" (selectionChange)="setMergeDirty()">
                    <mat-option *ngFor="let column of columns" [value]="column" class="full">{{column}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label class="placeholder">Language Preference Column</mat-label>
                <mat-select [(ngModel)]="merge.languagePreferenceColumn" (selectionChange)="setMergeDirty()">
                    <mat-option *ngFor="let column of columns" [value]="column" class="full">{{column}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-expansion-panel>
<mat-tab-group mat-stretch-tabs>
    <mat-tab>
        <ng-template mat-tab-label>
            Template(s)&nbsp;&nbsp;<button (click)="execute()" *ngIf="canExecute" class="warning " title="Execute available on Saved Template and Required parameters present">Test Execute</button>
        </ng-template>
        <ng-template matTabContent>
            <div class="appContent">
                <mat-tab-group mat-stretch-tabs>
                    <mat-tab *ngIf="merge.forMessageOnly === false">
                        <ng-template mat-tab-label>Email&nbsp;&nbsp;<mat-checkbox [(ngModel)]="merge.asHTML" class="float-right" (change)="merge.isDirty=true;">HTML</mat-checkbox></ng-template>
                        <mat-tab-group>
                            <mat-tab label="Content">
                                <p class="note full">{{helpNote}}</p>
                                <angular-editor #emailContentEditor (config)="editConfig" [(ngModel)]="this.merge.emailTemplate" [id]="'emailContentEditor'" id="emailContentEditor" name="emailContentEditor" (blur)="setMergeDirty()"></angular-editor>
                            </mat-tab>
                            <mat-tab label="Styles">
                                <textarea matInput maxlength="10000" [(ngModel)]="merge.emailStyles" (selectionChange)="setMergeDirty()" style="min-height:200px;max-height:500px;overflow-y:auto"></textarea>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>Message&nbsp;&nbsp; <mat-checkbox [(ngModel)]="merge.asHTML" class="float-right" (change)="merge.isDirty=true;">HTML</mat-checkbox></ng-template>
                        <mat-tab-group>
                            <mat-tab label="Content">
                                <p class="note full">{{helpNote}}</p>
                                <angular-editor #messageContentEditor (config)="editConfig" [(ngModel)]="this.merge.messageTemplate" [id]="'messageContentEditor'" id="messageContentEditor" name="messageContentEditor" (blur)="setMergeDirty()"></angular-editor>
                            </mat-tab>
                            <mat-tab label="Styles">
                                <textarea matInput maxlength="10000" [(ngModel)]="merge.messageStyles" (selectionChange)="setMergeDirty()" style="min-height:200px;max-height:500px;overflow-y:auto"></textarea>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Translations
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    Pending Implementation
                </div>
            </mat-expansion-panel>
        </ng-template>
    </mat-tab>
    <mat-tab label="Query">
        <ng-template matTabContent>
            <mat-tab-group mat-stretch-tabs>
                <mat-tab label="">
                    <ng-template mat-tab-label>
                        <label [ngClass]="{'warning' : merge.queryParsed === false || (subQuery && (subQuery.isDirty || subQuery.queryParsed === false))}">Query</label>&nbsp;&nbsp;<button (click)="parseAndVerifyMain()" *ngIf="merge.queryParsed === false" class="warning ">Parse/Verify Query</button>
                    </ng-template>
                    <ng-template matTabContent>
                        <mat-form-field>
                            <input matInput [(ngModel)]="merge.connectionString" maxlength="500" class="full" (change)="merge.queryParsed = false;setMergeDirty()" placeholder="Connection String (Leave Empty to use Application Default Connection String)" />
                        </mat-form-field>
                        <hr />
                        Query : <br/>
                        <textarea matInput maxlength="10000" [(ngModel)]="merge.query" (change)="merge.queryParsed = false;setMergeDirty()" style="min-height:200px;max-height:500px;overflow-y:auto; width:99%" class="borderPanel"></textarea>
                    </ng-template>
                </mat-tab>
                <mat-tab >
                    <ng-template mat-tab-label>
                        <label>Parameter(s)&nbsp;&nbsp;<a (click)="addParameter()" title="Add New Parameter"><i class="fa fa-plus-circle  link actionItem"></i></a></label>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="merge.parameters" *ngIf="merge.parameters && merge.parameters.length > 0">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Name <span title="Will be removed on Save" class="danger">*</span> </th>
                                    <td mat-cell *matCellDef="let element;">
                                        <input type="text" maxlength="100" [(ngModel)]="element.name" class="full" (change)="checkDuplicateParameter(element)" [ngClass]="{'danger': element.active === false}" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef> Data Type </th>
                                    <td mat-cell *matCellDef="let element;">
                                        <select class="app" [(ngModel)]="element.type" (change)="triggerParameterSave(element)">
                                            <option *ngFor="let dt of dataTypes | keys" [value]="dt.key" [selected]="dt.key === element.type">{{dt.value}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="defaultValue">
                                    <th mat-header-cell *matHeaderCellDef> Value for Testing Query </th>
                                    <td mat-cell *matCellDef="let element;">
                                        <input type="text" maxlength="100" [(ngModel)]="element.defaultValue" class="full" (change)="triggerParameterSave(element, false)" /> &nbsp;
                                        <input type="checkbox" [(ngModel)]="element.required" (change)="triggerParameterSave(element,false)" title="Required at Design Time" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <i class="fa fa-trash danger  actionItem" (click)="removeParameter(element)" *ngIf="element.active == null || element.active === true"></i>
                                        <i class="fa fa-check  green actionItem" (click)="activateParameter(element)" *ngIf="element.active === false"></i>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="paramDisplayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: paramDisplayedColumns;" (click)="onParameterSelect(row)" [ngClass]="{'selectedRow': row.id === currentParameter.id }"></tr>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Sub Queries">
                    <ng-template matTabContent>
                        <div>
                            <mat-sidenav-container class="subQuery-container">
                                <mat-sidenav mode="side" opened>
                                    <h4>List of SubQueries <i class="fa fa-plus-square-o float-right green" (click)="addNewSubSQL()"></i></h4>
                                    <mat-nav-list>
                                        <a mat-list-item *ngFor="let q of merge.subSQLs" (click)="selectSubSQL(q)"> {{q.name}}</a>
                                    </mat-nav-list>
                                </mat-sidenav>
                                <mat-sidenav-content class="subQueryContent">
                                    <div class="app-flex-container app-2col-edit">
                                        <mat-form-field>
                                            <input matInput [disabled]="subQuery.id > 0" type="text" maxlength="50" placeholder="Sub Query Name" [(ngModel)]="subQuery.name" (change)="checkDuplicateQueryName()" />
                                        </mat-form-field>                                    
                                        <mat-form-field>
                                            <input matInput type="text" maxlength="20" placeholder="Row Seperator" [(ngModel)]="subQuery.rowSeperator" (blur)="setSubQueryDirty()"/>
                                        </mat-form-field>
                                    </div>
                                    <mat-form-field>
                                        <textarea matInput type="text" maxlength="5000" placeholder="SQL" [(ngModel)]="subQuery.query" (change)="subQuery.queryParsed = false" style="min-height:150px"></textarea>
                                    </mat-form-field>
                                    <hr />
                                    <angular-editor #subQueryContentEditor (config)="editConfig" [(ngModel)]="this.subQuery.template" (blur)="setSubQueryDirty()"></angular-editor>
                                </mat-sidenav-content>
                            </mat-sidenav-container>
                            <hr />
                            <button (click)="saveSubQuery()" *ngIf="(utils.isNullOrEmpty(subQuery?.name) === false && utils.isNullOrEmpty(subQuery?.query) === false && subQuery.queryParsed === false) || (subQuery.isDirty === true)" class="warning">Parse and Save Sub Query</button>
                            <button (click)="cancelSubQuery()" *ngIf="subQuery.isDirty === true" class="danger float-right">Cancel Sub Query Change</button>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-template>
    </mat-tab>
    <mat-tab label="Triggers">
        <ng-template matTabContent>
            <mat-checkbox [(ngModel)]="merge.ignoreTriggerFailure">Ignore Trigger Failure</mat-checkbox>
            <hr />
            <mat-tab-group mat-stretch-tabs>
                <mat-tab label="Pre Execute Trigger">
                    <textarea matInput maxlength="10000" [(ngModel)]="merge.preExecuteTrigger" (change)="setMergeDirty()" style="height:400px;overflow-y:auto"></textarea>
                </mat-tab>
                <mat-tab label="Post Execute Trigger">
                    <textarea matInput maxlength="10000" [(ngModel)]="merge.postExecuteTrigger" (change)="setMergeDirty()" style="height:400px;overflow-y:auto"></textarea>
                </mat-tab>
                <mat-tab label="Pre Record Execute Trigger">
                    <textarea matInput maxlength="10000" [(ngModel)]="merge.preRecordTrigger" (change)="setMergeDirty()" style="height:400px;overflow-y:auto"></textarea>
                </mat-tab>
                <mat-tab label="Post Record Execute Trigger">
                    <textarea matInput maxlength="10000" [(ngModel)]="merge.postRecordTrigger" (change)="setMergeDirty()" style="height:400px;overflow-y:auto"></textarea>
                </mat-tab>
            </mat-tab-group>
        </ng-template>
    </mat-tab>
    <mat-tab label="Log Mapping" *ngIf="merge.logMapping && merge.logMapping.length > 0">
        <ng-template matTabContent>
            <table mat-table [dataSource]="merge.logMapping">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name  </th>
                    <td mat-cell *matCellDef="let element;">
                        <input type="text" maxlength="100" [(ngModel)]="element.columnName" class="full" disabled />
                    </td>
                </ng-container>
                <ng-container matColumnDef="loggingValue">
                    <th mat-header-cell *matHeaderCellDef> Logging Column </th>
                    <td mat-cell *matCellDef="let element;">
                        <mat-form-field>
                            <mat-select [(ngModel)]="element.mapColumnName" (selectionChange)="triggerLoggingSave()">
                                <mat-option *ngFor="let column of columns" [value]="column" class="full">{{column}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="loggingDisplayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: loggingDisplayedColumns;"></tr>
            </table>
        </ng-template>
    </mat-tab>
    <mat-tab label="Attachment">
        <ng-template matTabContent>
            Pending Implementation
        </ng-template>
    </mat-tab>
</mat-tab-group>
<hr />
<button (click)="save()" *ngIf="merge.queryParsed === false || merge.isDirty" class="green ">Save</button>