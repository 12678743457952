import { Component, ViewChild, Inject, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ServiceProviderSearchComponent } from '../searchServiceProvider.component';
import { Role, Address } from 'src/app/models';

@Component({
    selector: 'app-search-provider-dialog',
    template: `
<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class='close' title='Close' (click)='close(null)'><i class='fa fa-times'></i></a>
</div>
<div mat-dialog-content class="appDialog">
    <app-search-providers #searchSP [inPopup]=true
        (providerSelected)='onProviderSelected($event)'
        [(collectPayment)]=collectPayment
        [(forServiceTypeID)]=forServiceTypeID
        [(selectButtonText)]=selectButtonText
        [(propertyTypeID)]=propertyTypeID
        [(priceBasis)]=priceBasis
        [(myRole)]=myRole
        [(geographicArea)]=geographicArea
        [(showStaff)]=showStaff
        [(selectPrivateOnly)]=selectPrivateOnly
        [(allowToPickSP)]=allowToPickSP
        [(canRequestForQuote)]=canRequestForQuote
        [(collectPaymentIfRequired)]=collectPaymentIfRequired
        [optionSelectionRequired]=optionSelectionRequired
        [(collectPayment)]=collectPayment [(hideServiceType)]=hideServiceType
        [(selectedServiceTypeID)]=forServiceTypeID>
        [loadingSP]=loadingSP">
    </app-search-providers>
</div>
`
})
export class SearchSPDialog {

    dialogHeader: string = 'Search Service Provider';
    @ViewChild('searchSP') searchSP: ServiceProviderSearchComponent;

    @Output() providerSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() @Input() selectButtonText: string = 'Select';
    @Input() @Output() propertyTypeID: number = 0;
    @Input() @Output() referenceID: number = 0;
    @Input() @Output() priceBasis: number = 0;
    @Input() @Output() myRole: Role = Role.NotSet;
    @Input() @Output() geographicArea: Address = new Address();
    @Input() @Output() selectPrivateOnly: boolean = false;
    @Input() @Output() showStaff: boolean = false;
    @Input() @Output() canRequestForQuote: boolean = false;
    @Input() @Output() collectPaymentIfRequired: boolean = true;
    @Input() @Output() collectPayment: boolean = false;
    @Input() @Output() forServiceTypeID: number = 0;
    @Input() @Output() hideServiceType: boolean = false;
    @Input() loadingSP: boolean = false;
    @Input() @Output() allowToPickSP: boolean = true;
    @Input() optionSelectionRequired: boolean = false;

    constructor( public dialogRef: MatDialogRef<SearchSPDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data) {
            if (data.selectButtonText) this.selectButtonText = data.selectButtonText;
            if (data.propertyTypeID) this.propertyTypeID = data.propertyTypeID;
            if (data.referenceID) this.referenceID = data.referenceID;
            if (data.priceBasis) this.priceBasis = data.priceBasis;
            if (data.myRole) this.myRole = data.myRole;
            if (data.geographicArea) this.geographicArea = data.geographicArea;
            if (data.showStaff) this.showStaff = data.showStaff;
            if (data.canRequestForQuote) this.canRequestForQuote = data.canRequestForQuote;
            if (data.collectPayment) this.collectPayment = data.collectPayment;
            if (data.forServiceTypeID) this.forServiceTypeID = data.forServiceTypeID;
            if (data.hideServiceType) this.hideServiceType = data.hideServiceType;
            if (data.allowToPickSP) this.allowToPickSP = data.allowToPickSP;
            if (data.optionSelectionRequired) this.optionSelectionRequired = data.optionSelectionRequired;

            setTimeout(() => {
            if (this.searchSP && this.forServiceTypeID > 0)
                    this.searchSP.onServiceTypeChanged(true);
            }, 3000);
        }
    }

    onProviderSelected($event) {
        this.close($event);
    }

    close(data) {
        this.dialogRef.close(data);
    }
}
