import { Component, ChangeDetectorRef, ViewChild, Input, AfterViewChecked, Inject } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { item } from '../models/appCommon.models';
import { SPVisit, WorkOrder } from '../models';

import { PersonService } from '../account/person.service';
import { WorkOrderService } from '../workorders/workorder.service';
import { ServiceProviderService } from './serviceprovider.service';
import { AddServiceVisitDialog } from './dialogs/addServiceVisit.dialog';
import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-service-provider-visits',
    templateUrl: './serviceVisits.component.html'
})
export class ServiceProviderVisitsComponent extends BaseComponent {
    canAddNew: boolean = false;
    saving: boolean = false;
    selectedListings: number[] = [];
    selectedServiceTypes: number[] = [];
    startDate: Date = moment(Utils.today).add(-30, 'days').toDate();
    endDate: Date = moment(Utils.today).add(0, 'days').toDate();
    visits: SPVisit[] = [];

    listings: any[] = [];
    serviceTypes: item[] = [];

    criteriaChanged: boolean = false;
    searched: boolean = false;

    monthAgo = moment(Utils.today).add(-30, 'days').toDate();

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) private g: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('pgCompanyVisits', r, g);
        if (this.user.details.CompanyID > 0) {
            let _response;
            (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getOfferedServices(this.user.details.CompanyID).subscribe((_r) => _response = _r
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (_response.data) {
                            this.serviceTypes = _response.data.map(b => { return Utils.castTo(b, new item()); });
                        }
                });

            this.getSPWorkOrders();
        }
    }

    startDateChanged(value) {
        if (value) this.startDate = value;
        else
            this.startDate = this.Utils.dateTimeMinValue;

        this.criteriaChanged = true;
    }

    endDateChanged(value) {
        if (value) this.startDate = value;
        else
            this.startDate = this.Utils.dateTimeMinValue;

        this.criteriaChanged = true;
    }

    stChanged() {
        this.criteriaChanged = true;
    }

    bindVisits() {

        // Wait for Service Types to Load
        if (this.serviceTypes == null || this.serviceTypes.length === 0 || this.listings == null || this.listings.length === 0) return;

        if (this.user.details.CompanyID > 0) {
            let _response;

            this.searched = true;
            this.gc.setComponentBusy(true);
            (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).visits(this.user.details.CompanyID, this.selectedListings, this.selectedServiceTypes, this.startDate, this.endDate).subscribe((r) => _response = r
                , (error) => { this.onApiError(error); }
                , () => {

                    this.gc.setComponentBusy(false);
                    this.criteriaChanged = false;

                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else if (_response.data) {
                            this.visits = _response.data.map(b => { return (new SPVisit()).castToMe(b); });
                        }
                });
        }
    }

    newVisit() {
        const dialogRef = this.dialog.open(AddServiceVisitDialog, {
            data: {
                workOrders: []
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.visits.splice(0, 0, Utils.castTo(result, new SPVisit()));
                this.visits = [...this.visits];
            }
        });
    }

    removeVisit(iWorkOrderID: number, iVisitID: number) {
        let _response: any;
        this.saving = true;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).removeVisit(iWorkOrderID, iVisitID).subscribe(
            (data) => _response = data
            , (error) => { this.onApiError(error); }
            , () => {
                this.saving = false;
                if (_response) {
                    if (Utils.isNullOrEmpty(_response.error)) {
                        this.visits = cloneDeep(this.visits.filter(uv => { return uv.id !== iVisitID; })); // Update Work Order on Client
                    } else
                        this.showError(_response.error);
                }
            }
        );
    }

    changeStatus(iWorkOrderID, action$) {

        let _actionResponse;
        (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).changeVisitStatus(iWorkOrderID, parseInt(action$.argument), action$.name).subscribe((r) => _actionResponse = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (Utils.isNullOrEmpty(_actionResponse.message)) _actionResponse.message = 'Action executed successfully.';

                        this.showMessage(_actionResponse.message);

                        if (!Utils.isNullOrEmpty(_actionResponse.redirectURL))
                            this.gotoURL(_actionResponse.redirectURL);
                        else {
                            this.visits = cloneDeep(this.visits.filter(wo => { return (wo.id !== parseInt(action$.argument)); })); // Update Work Order on Client
                            if (_actionResponse.data)
                                this.visits.splice(0, 0, Utils.castTo(_actionResponse.data, new SPVisit()));
                        }
                    }
                }
            }
        );
    }

    getSPWorkOrders(bFirstCall: boolean = true) {
        let _woResponse;
        this.componentBusy = true;
        // Priortize Assign, Accepted and WO in Progress.
        const _data = { allOperators: true };
        if (bFirstCall) {
            _data['includeStatuses'] = (bFirstCall ? [4, 5, 9] : []); // Assigned, WIP, Accepted
            _data['excludeStatuses'] = [0, 1, 2, 3, 6, 13]; // None, Created, Revoked, Declined
        } else {
            _data['includeStatuses'] = [];
            _data['excludeStatuses'] = [0, 1, 2, 3, 4, 5, 6, 9, 13]; // None, Created, Assigned, WIP, Revoked, Accepted, Declined
        }

        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getWorkOrderProperties(_data).subscribe((r) => _woResponse = r
            , (error) => {
                this.componentBusy = false;
                this.gc.setBusy(false); this.onApiError(error);
            }
            , () => {
                this.componentBusy = false;
                this.gc.setBusy(false);
                if (_woResponse && _woResponse.data) {
                    if (bFirstCall) {
                        this.listings = [];
                        _woResponse.data.forEach((l) => this.listings.push({ id: l.id, name: l.address.StreetName + ', ' + l.address.City + ' - ' + l.address.State })); // .map((wo) => (new listing()).castToMe(wo));
                        this.getSPWorkOrders(false);
                    } else {
                        _woResponse.data.forEach((l) => this.listings.push({ id: l.id, name: l.address.StreetName + ', ' + l.address.City + ' - ' + l.address.State }));
                    }
                }
            });
    }
}
