<div class="appContent">
    <h2 class="pageHeader">Security Declaration(s)</h2>
    <div class="appContent">
        <div class="page-content">
            <p>We at IAMOREA, value your online security. For this reason, we have implemented safeguards to protect the information you transmit to us over the Internet as described in this Security Statement. We collect and use the information you provide to us to respond to your needs, service your accounts and provide you with information about other services we provide. This statement applies to all users of IAMOREA.com whether the user is a customer of IAMOREA or not.</p>
            <p><strong>SSL Certificate</strong><br>When you engage with IAMOREA over the Internet for products offered , your web browser connects with the domain through SSL ("Secure Sockets Layer"). SSL is an industry-standard way to send personal information over the Internet. SSL ensures that all data passed between the web server and browser remains private and secure. We also protect your transactions when you enroll in our e-alerts, participate in one or more of our "Top-Ranks" surveys, or register for events. It's fast, safe, and ensures that your personal information will not be read by anyone else. Look for one or more of these indicators on your browser to ensure you have a secure connection:</p>
            <ul>
                <li>A security-alert window tells you that you are about to use a secure connection. Some browsers let you choose whether to display this window.</li>
                <li>An SSL icon shows when you are in a secure connection: A symbol of an "open padlock" changes to a "closed padlock" (available in most but not all browsers) in the address bar of your browser when you are in a secure connection.</li>
                <li>A symbol of a "broken key" is replaced by an unbroken key.</li>
                <li>The Web-site's address changes to one that begins with "https" (check for the "s" which designates a "secure" site).</li>
            </ul>
            <p>While IAMOREA takes reasonable steps to safeguard and to prevent unauthorized access to your private information, we cannot be responsible for the acts of those who gain unauthorized access, and we make no warranty, express, implied, or otherwise, that we will prevent unauthorized access to your private information. IN NO EVENT, SHALL IAMOREA OR ITS AFFILIATES BE LIABLE FOR ANY DAMAGES (WHETHER CONSEQUENTIAL, DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL OR OTHERWISE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, A THIRD PARTY'S UNAUTHORIZED ACCESS TO YOUR INFORMATION, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT, OR OTHER THEORIES OF LIABILITY, AND ALSO REGARDLESS OF WHETHER IAMOREA WAS GIVEN ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE.</p>
            <p><strong>Cookies and Web Beacons</strong><br>IAMOREA uses cookies. The use of cookies is a standard practice among Internet websites. A cookie is a text-only string of information that a website transfers to the cookie file of the browser on your computer's hard disk so that the website can remember who you are. Cookies will typically contain the name of the domain from which the cookie has come, the "lifetime" of the cookie, and a value, usually a randomly generated unique number. The cookie file uses a code to identify a particular user, but the user is not identified by personal information such as name or address. Cookies are safe, as they cannot be used to gather personal information about the user or to deliver viruses.</p>
            <p>Two types of cookies are used on this website – "session cookies", which are temporary cookies that remain in the cookie file of your browser until you leave the website, and "persistent cookies", which remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie). Most browsers have options that allow the visitor to control whether the browser will accept cookies, reject cookies, or notify the visitor each time a cookie is sent. You may elect to reject cookies by adjusting your browser's settings, but doing so will limit the range of features available to the visitor on our site and most other major websites that use cookies.</p>
            <p>Web beacons are used in conjunction with cookies to record the simple actions of the user opening the page that contains the beacon. When a user's browser requests information from a Website in this way, certain simple information can also be gathered, such as: the IP Address of your computer; the time the material was viewed; the type of browser that retrieved the image; and the existence of cookies previously set by that server. This is information that is available to any Web server you visit. Web beacons do not give any "extra" information away and do not contain any personal information. They are simply a convenient way of gathering the simplest of statistics and managing cookies. For more information about our policies for protecting your personal information see our Privacy Policy. IAMOREA does not sell, rent, trade or otherwise distribute information about you gathered through the we beacons with any third parties.</p>
            <p><strong>E-Mail Security</strong><br>Regular internet e-mail is never completely secure. When corresponding to IAMOREA via e-mail, you should provide information that is non-sensitive and non-confidential. Please do not furnish to us any information of a personal nature that you feel uncomfortable providing to us via e-mail. If you are concerned about the security of your communication, please transmit your correspondence to us using the postal service or telephone. IAMOREA is not responsible for the security of information we receive via e-mail.</p>
            <p><strong>Links to Other Sites</strong><br>IAMOREA may provide links to other Internet sites, as a convenience to the persons visiting this website. If you visit these sites via our website, you may be asked to provide nonpublic personal information. Please be aware that IAMOREA is not responsible for the privacy practices of those sites. We suggest that, if you visit other web sites through IAMOREA sites, you read the privacy policies of each site that you visit, as it may differ from ours. Our privacy policy applies solely to this website.</p>
            <p><strong>Disclaimer</strong><br>The information provided in this Security Statement should not be construed as giving business, legal or other advice, or warranting as fail proof, the security of information provided through IAMOREA website.</p>
        </div>

        <h2>
            How we protect your information<br>
        </h2>
        <p>Protecting your information that we collect from you is a top priority at IAMOREA. We protect your information in the following ways:</p>
        <ul class="indented-list">
            <li>
                <span class="indented-list">
                    <b>
                        Encryption<br>
                    </b>When you visit our website, we require the use of a secure browser with encryption. Using encryption protects the information by scrambling it as it is sent from your computer or device to IAMOREA and vice versa. It helps to keep your information secure and private.
                </span>
            </li>
            <li>
                <span class="indented-list">
                    <b>
                        System monitoring<br>
                    </b>We regularly review activity logs to identify potential problems. We have systems in place to automatically block and help prevent unauthorized access to your information from unknown or untrusted sources. We review our information collection, storage and processing practices, including physical security measures, to guard against unauthorized access to systems.
                </span>
            </li>
            <li>
                <span class="indented-list">
                    <b>
                        Patches<br>
                    </b>We update our systems frequently so that we are using current security technology.
                </span>
            </li>
            <li>
                <span class="indented-list">
                    <b>
                        Limited access<br>
                    </b>We restrict access to your personal information to IAMOREA associates who need to know that information in order to process your request or maintain our website. Those that have access to your information are subject to strict confidentiality obligations and may be
                </span> disciplined or terminated if they fail to meet these obligations.
            </li>
        </ul>
        <div class="section form-group">
            <hr />
            Management&#64;IAMOREA
        </div>
    </div>
</div>