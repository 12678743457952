import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';

import { Router} from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from './utils';

@Component({
    selector: 'app-next-status-actions',
    templateUrl: './nextAction.component.html'
})
export class NextActionComponent extends BaseComponent implements OnInit {

    @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

    @Input() @Output() hideNoActionLabel: boolean = false;
    @Input() @Output() noActionMessage: string = this.gc.geti18nTag('System.NoAvailableActions');
    @Input() @Output() actions: any = [];
    @Input() label: string = this.gc.geti18nTag('System.AvailableActions');
    @Input() labelCSS: string = '';

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent)  gc: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('ucNextActions', r, gc);
    }

    ngOnInit() { }

    execAction(action) {
        let _action: any;
        if (!Utils.isNullOrEmpty(action.actionURL)) {
            if (!Utils.isNullOrEmpty(action.actionArgument)) {
                if ((action.actionURL as string).includes('?')) {
                    action.actionURL += '&id=' + action.actionArgument;
                } else
                    action.actionURL += '?id=' + action.actionArgument;
            }
            this.gotoURL(action.actionURL, true);

        } else {
            if (action.id > 0)
                _action = this.actions.find((_a) => _a.id === action.id);
            else
                _action = this.actions.find((_a) => _a.actionName === action.actionName);

            if (_action) {
                const _r = { id: _action.id, name: _action['actionName'], argument: _action['actionArgument'] };
                    if (_action.needsConfirmation && !Utils.isNullOrEmpty(_action.confirmationMessage)) {
                        if (confirm(_action.confirmationMessage))
                            this.itemSelected.emit(_r);
                    } else if (_action.needsConfirmation) {
                        if (confirm(this.gc.geti18nTag('System.ConfirmToProceed')))
                            this.itemSelected.emit(_r);
                        else
                            this.itemSelected.emit(_r);
                    } else
                    this.itemSelected.emit(_r);
            } else
                this.itemSelected.emit({ id: '0', name: '', argument: action.actionArgument });
        }
    }
}
