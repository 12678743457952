import { BaseService } from '../../common/service/base.service';
import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DocumentService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    viewDocument(id: number) {
        const data = { id };
        return this.ds.APIPost('/document/view', data, this.requestingModule);
    }

    getDocuments(entityType: number, entityTypeKeyID: number) {
        const data = { entityType, entityTypeKeyID };
        return this.ds.APIPost('/document/documents', data, this.requestingModule);
    }

    changePermission(id: number, pid: number, canRead: boolean, canEdit: boolean, isOwner: boolean) {
        const data = { id, pid, canRead, canEdit, isOwner };
        return this.ds.APIPost('/document/changePermission', data, this.requestingModule);
    }

    changeStatus(iDocumentID: number, enNewStatus: number) {
        const data = { id: iDocumentID, newStatus: enNewStatus };
        return this.ds.APIPost('/document/changeStatus', data, this.requestingModule);
    }

    addDocument(oDoc: any) {
        const data = oDoc;
        return this.ds.APIPost('/document/add', data, this.requestingModule);
    }

    removeDocument(id: number) {
        const data = { id };
        return this.ds.APIPost('/document/remove', data, this.requestingModule);
    }

    saveHTMLDocument(id: number, sHTML: string) {
        const data = { id, htmlContent: sHTML };
        return this.ds.APIPost('/document/update', data, this.requestingModule);
    }
}
