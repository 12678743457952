import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, Output , EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import * as cloneDeep from 'lodash/cloneDeep';

import { Payment3DSInformation } from '../models';
import { item, Payment } from '../models/appCommon.models';

import { appEnvironment } from '../../environments/environment';
import { Role, ServiceSelectionType, PaymentOption } from '../models/enums.component';
import { Address } from '../models/address.model';
import { HTMLDialog } from '../common/dialogs/showHTML.dialog';
import { MessageDialog } from '../common/dialogs/message.dialog';
import { PersonService } from '../account/person.service';
import { CodeService } from '../common/service/code.service';
import { ServiceProviderService } from '../sp/serviceprovider.service';

import { AgentDialog } from '../common/dialogs/agentDetail.dialog';
import { ServiceProviderEntity, SPSearchResult, SPSelectionModel } from '../models/providers/serviceProvider.model';
import { PaymentUIData } from '../models';
import { BrainTreeDialog } from '../common/shared/brainTreeDropIn.dialog';
import { selectTag } from '../common/appCommon.module';
import { AddReviewsDialog } from './dialogs';
import { TagLoadService } from '../util/service/tagLoad';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-search-providers',
    templateUrl: './searchServiceProvider.component.html'
    , providers: [TagLoadService]
})
export class ServiceProviderSearchComponent extends BaseComponent implements OnInit, OnChanges {
    agentSearch: boolean = false;

    lblSelect: string = 'Select';

    @Output() paymentApproved: EventEmitter<any> = new EventEmitter<any>();
    @Output() providerSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() serviceTypeSelected: EventEmitter<any> = new EventEmitter<any>();
    //#region Properties

    @Input() inPopup: boolean = false;
    @Input() @Output() propertyTypeID: number = 0;
    @Input() @Output() referenceID: number = 0;
    @Input() @Output() priceBasis: number = 0;
    @Input() @Output() myRole: Role = Role.NotSet;
    @Input() @Output() geographicArea: Address = new Address();
    @Input() @Output() hideServiceType: boolean = false;
    @Input() @Output() hideAddress: boolean = false;
    @Input() @Output() excludeServiceTypeIDs: number[] = new Array<number>();
    @Input() @Output() forServiceTypeID: number = 0;
    @Input() @Output() selectPrivateOnly: boolean = true; // To Control Private ONLY Server Types
    @Input() @Output() showStaff: boolean = false;
    @Input() @Output() canRequestForQuote: boolean = false;
    @Input() @Output() collectPaymentIfRequired: boolean = true;
    @Input() loadingSP: boolean = false;
    @Input() @Output() collectPayment: boolean = false;
    @Input() allowToPickSP: boolean = true;
    @Input() selectButtonText: string;
    @Input() optionSelectionRequired: boolean = false;

    isLenderServiceType: boolean = false;
    allServiceTypes: any = []; serviceTypes: any = [];
    payment: Payment = new Payment();
    agreedToSPTnC: boolean = false;
    terms: string[] = new Array<string>();

    canChangeServiceType: boolean = false;

    selectedOptionID: number = 0;

    paymentOption = PaymentOption;
    selectAllowed: boolean = true; // For Use from Server Configs

    @ViewChild('options') matOptions: MatSelect;
    @ViewChild('spTable') table: any;

    @Input() @Output() options: any = []; selectedOptionIDs: number[] = new Array<number>();
    distance: number = 0;
    serviceProviders: any = []; selectedServiceProviderID: number = 0; selectedServiceProviderName: string = '';
    canHaveMultiples: boolean = false;

    pageNumber: number = 1;
    itemsOnPage: number = 5;

    // private _selectedOptionID: number = 0;
    private _selectedServiceTypeID: number = 0;

    constructor(private dataservice: DataService, private r: Router, private dialog: MatDialog
        , private cdref: ChangeDetectorRef, @Inject(GlobalComponent) g: GlobalComponent) {
        super('pgCompanySearch', r, g);

        if (this.loggedIn) {
            this.geographicArea = this.user.details.PrimaryAddress;
        }

        this.itemsOnPage = this.inPopup ? 5 : 10;
    }

     @Input() @Output() get selectedServiceTypeID(): number { return this._selectedServiceTypeID; }
     set selectedServiceTypeID(v: number) {
        const _changed: boolean = this._selectedServiceTypeID !== v;
        this._selectedServiceTypeID = v;
        this.options = new Array<item>();
        this.serviceProviders = [];
        if (_changed) {
            this.selectedOptionID = 0; this.selectedOptionIDs = [];
            this.onServiceTypeChanged(true);
        }
        this.isLenderServiceType = this._selectedServiceTypeID === appEnvironment.lenderServiceTypeID;
     }

    // get selectedOptionID(): number { if (this._selectedOptionID == null) this._selectedOptionID = 0; return this._selectedOptionID; }
    // set selectedOptionID(v: number) {
    //    if (v == null) v = 0;
    //    const _changed = this._selectedOptionID !== v;
    //    this._selectedOptionID = v;
    //    // if (_changed)
    //    //    this.bindResults();
    // }

    ngOnInit() {
        this.messages = {
            emptyMessage: 'Searching ......'
        };
        this.reset();

        setTimeout(() => {
            this.canChangeServiceType = this.forServiceTypeID === 0;
        }, appEnvironment.delayedNGInit);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes.hasOwnProperty('allowToPickSP')) {
                const _change = changes.allowToPickSP;
                const _divAllowToPickSP = document.getElementById('divAllowToPickSP');
                if (_divAllowToPickSP != null) {
                    if (_change.currentValue === true) {
                        _divAllowToPickSP.classList.add('first');
                        _divAllowToPickSP.classList.add('app-flex-container');
                        _divAllowToPickSP.classList.add('app-2col');
                    } else {
                        _divAllowToPickSP.classList.remove('first');
                        _divAllowToPickSP.classList.remove('app-flex-container');
                        _divAllowToPickSP.classList.remove('app-2col');
                    }
                }
            }
        }
    }

    reset() {
        let stResponseData;
        (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getServiceTypes().subscribe((r) => stResponseData = r
            , (error) => { this.allServiceTypes = []; this.onApiError(error); }
            , () => {
                if (this.forServiceTypeID == null || this.forServiceTypeID === 0) {
                    if (this.excludeServiceTypeIDs && this.excludeServiceTypeIDs.length > 0)
                        this.allServiceTypes = stResponseData.data.filter((st) => this.excludeServiceTypeIDs.indexOf(st.id) < 0);
                    else
                        this.allServiceTypes = stResponseData.data;
                } else {
                    this.allServiceTypes = stResponseData.data.filter((st) => st.id === this.forServiceTypeID);
                    this.selectedServiceTypeID = this.forServiceTypeID;
                }
                this.refreshServiceTypeList();
            });

        // If Searching For Agent then Force Agent Type ID and hide Changing Service Type
        if (this.r.url.indexOf('/agent/') >= 0) {
            this.agentSearch = true;
            this.selectedServiceTypeID = appEnvironment.realEstateAdvisorServiceTypeID;
            this.hideServiceType = true;
        }
    }

    //#endregion Properties

    refreshServiceTypeList() {
        let serviceTypes: any = [];
        if (this.myRole)
            serviceTypes = cloneDeep(this.allServiceTypes.filter((_st) => _st.orderableByRoles.length === 0 || _st.orderableByRoles.indexOf(this.myRole) >= 0));
        else
            serviceTypes = cloneDeep(this.allServiceTypes);

        this.serviceTypes = [...serviceTypes];

        if (this.forServiceTypeID > 0 && this.serviceTypes.find((st) => st.id === this.forServiceTypeID) != null)
            this.selectedServiceTypeID = this.forServiceTypeID;
        else {
            setTimeout(() => {
                if (this.serviceTypes?.length > 0 && this.selectedServiceTypeID === 0 && this.forServiceTypeID === 0)
                    this.selectedServiceTypeID = this.serviceTypes[0].id;
            }, appEnvironment.delayedNGInit);
        }
    }

    onServiceTypeChanged(bUserAction: boolean = true) {

        if (bUserAction === true) {
            this.selectedOptionIDs = [];
            this.selectedOptionID = this.selectedServiceProviderID = 0;
        }

        const _presentOpt = this.selectedOptionID;

        let mustHaveOption: boolean = false;

        if (this.forServiceTypeID > 0) {
            this.serviceTypes = cloneDeep(this.allServiceTypes.filter((st) => st.id === this.selectedServiceTypeID));
        }

        const _serviceType = this.allServiceTypes.find((st) => st.id === this.selectedServiceTypeID);

        if (_serviceType) {
            this.serviceTypeSelected.emit({ serviceType: _serviceType });

            if (this.selectPrivateOnly)
                this.selectAllowed = _serviceType.canOrderNewForPrivateRequests;
            else
                this.selectAllowed = true;
        }

        if (_serviceType && _serviceType.selectionType && _serviceType.selectionType !== ServiceSelectionType.ServiceWithNoOptions) {
            this.options = _serviceType.options;
            this.canHaveMultiples = _serviceType.selectionType === ServiceSelectionType.NoServiceWithMultipleOptions || _serviceType.selectionType === ServiceSelectionType.ServiceWithMultipleOptions;
            mustHaveOption = _serviceType.selectionType === ServiceSelectionType.NoServiceOneOptionOnly || _serviceType.selectionType === ServiceSelectionType.NoServiceWithMultipleOptions;
            if (!mustHaveOption)
                this.options.splice(0, 0, new item(0, selectTag));
            else {
                if (this.options && this.options.length > 0) {
                    this.selectedOptionID = this.options[0].id;
                }
            }
        } else
            this.options = [];

        let _triggerRefresh = false;

        if (this.selectedOptionIDs && this.selectedOptionIDs.length > 0) {
            this.selectedOptionID = this.selectedOptionIDs[0];
            if (_presentOpt == null || _presentOpt === 0)
                _triggerRefresh = true;
            else if (_presentOpt !== this.selectedOptionID)
                _triggerRefresh = true;
        } else
            this.selectedOptionID = 0;

        if (bUserAction === true || _triggerRefresh === true) {
            this.bindResults();
        }
    }

    onOptionChanged() {

        if (this.canHaveMultiples) {
            if (this.selectedOptionIDs && this.selectedOptionIDs.length > 0)
                this.selectedOptionID = this.selectedOptionIDs[this.selectedOptionIDs.length - 1];
            else
                this.selectedOptionID = 0;
        }
        this.bindResults();
    }

    renderPrice(sp: any) {
        if (this.selectedOptionID == null || this.selectedOptionID === 0)
            return sp.cost;
        else {
            const o = sp.offerings.find((_o) => _o.id === this.selectedServiceTypeID); // [0].options.find((_o) => _o.id = this.selectedOptionID);

            if (o && this.selectedOptionID > 0) {
                const _op = o.options.find((_o) => _o.id = this.selectedOptionID);
                if (_op)
                    return _op.price;
            }
            return sp.cost;
        }
    }

    viewTerms(id: number, name: string) {
        let response;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getTermsAndConditions(id, this.selectedServiceTypeID
            , this.canHaveMultiples && this.selectedOptionIDs.length > 0 ? this.selectedOptionIDs[0] : this.selectedOptionID, this.myRole).subscribe(
                (data) => { response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (response) {
                        if (response.data)
                            this.showHTML(response.data);
                        else
                            this.showHTML('[ ' + name + ' ] has not defined Terms and Conditions for the services they offered. Additional Terms and Conditions, policies and disclosures may be provided later during the work orders');
                    }
                });
    }

    spRowSelected(sp: ServiceProviderEntity, index: number) {
        this.accordionSetStep(index);
        if (sp)
            this.selectedServiceProviderID = sp.id;
        else
            this.selectedServiceProviderID = 0;
    }

    onServiceProviderSelection(event$) {

        if (this.selectedServiceProviderID === 0) return;

        if (this.optionSelectionRequired === true && this.selectedOptionID === 0) {
            this.showError('Please select a valid service option.');
            return;
        }

        let _options = this.selectedOptionIDs;
        if (_options == null) _options = [];
        if (_options.length === 0 && this.selectedOptionID > 0) _options.push(this.selectedOptionID);

        const _o: SPSelectionModel = new SPSelectionModel();
        _o.serviceProviderID = this.selectedServiceProviderID;
        _o.serviceTypeID = this.selectedServiceTypeID;
        _o.optionIDs = _options;

        const _hasTerms = this.terms != null && this.terms.length > 0;



        if (!this.collectPayment || this.isLenderServiceType) {
            if (this.providerSelected != null) {
                if (this.inPopup) {
                    _o.currentPrice = null;
                    this.providerSelected.emit(_o);
                } else {
                    this.createWorkOrder(_o);
                }
            }
        } else {
            if (_hasTerms && !this.agreedToSPTnC) {
                this.showError('TNC Pending. Please agree to Service Providers Terms and Conditions');
            } else {

                if (event$ && event$.cost)
                        this.payment.amount = parseFloat(event$.cost.toString().replace('$', ''));

                if (!this.isNaN(this.payment.amount) && this.payment.amount > 0) {
                    const _ccData: PaymentUIData = new PaymentUIData();

                    _ccData.payment3DSInfo = new Payment3DSInformation();
                    _ccData.payment3DSInfo.amount = this.payment.amount;
                    _ccData.payment3DSInfo.billingAddress.givenName = this.user.details.FirstName;
                    _ccData.payment3DSInfo.billingAddress.surname = this.user.details.LastName;

                    _ccData.currency = 'USD';
                    _ccData.amount = this.payment.amount;
                    _ccData.header = 'Submit Payment and Assign Work Order to ' + this.selectedServiceProviderName;
                    _ccData.title = this.myBRAND + ' is collecting ' + this.FormatNumberToCurrency(this.payment.amount) + ' on Behalf of [' + this.selectedServiceProviderName
                        + ']. I understand and agree to charge ' + this.FormatNumberToCurrency(this.payment.amount) + ' on the Credit Card.';

                    const _df = this.dialog.open(BrainTreeDialog, { data: _ccData });
                    _df.disableClose = true;
                    _df.afterClosed().subscribe((result) => {
                        this.onPaymentEntered(result);
                    });
                } else if (this.providerSelected != null) {
                    if (this.inPopup) {
                        _o.currentPrice = null;
                        this.providerSelected.emit(_o);
                    } else {
                        this.createWorkOrder(_o);
                    }
                }
            }
        }
    }

    onTnCAgreed() {
        if (!this.collectPayment)
            this.paymentApproved.emit(null);
    }

    onPaymentStatus($event) {
        const e = $event;

        // Call Service Purchase on Approval.
        if (e.Errors == null || e.Errors.length === 0) {
            const _r = { id: e.Target.Id, authCode: e.Target.ProcessorAuthorizationCode, amount: this.payment.amount };
            this.paymentApproved.emit(_r);
        }
    }

    sendMessageToAgent(iPID, sMessage) {
        const _recepients: any = [];
        _recepients.push(iPID);
        let messageResponse;
        (new PersonService(this.dataservice, this.r, this.myResourceCategory)).sendMessage(this.user.details.PID, _recepients, 'Contact Request', sMessage).subscribe(
            (data) => messageResponse = data
            , (error) => { this.onApiError(error); }
            , () => {
                if (messageResponse) {
                    if (Utils.isNullOrEmpty(messageResponse.error)) {
                        this.showMessage('Someone should contact you shortly.');
                    }
                }
            }
        );
    }

    sendMessageTo(recepients: number[]) {

        if (recepients == null || recepients.length === 0) return;

        const dialogRef = this.dialog.open(MessageDialog, {
            data: {
                recepients
                , subject: 'Please contact me'
                , heading: 'Message'
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.showMessage('Someone should contact you shortly.');
            }
        });
    }

    showAgentDetails(iPID) {
        this.dialog.open(AgentDialog, { data: { id: iPID } });
    }

    showHTML(html: string) {
        this.dialog.open(HTMLDialog, { data: { html } });
    }

    readReviews(iServciceProviderID) {
        this.dialog.open(AddReviewsDialog, {
            data: {
                serviceProviderID: iServciceProviderID
            }
        });
    }

    private onPaymentEntered(payment: PaymentUIData) {
        if (payment && payment.paymentNonce) {

            let _options = this.selectedOptionIDs;
            if (_options == null) _options = [];
            if (_options.length === 0 && this.selectedOptionID > 0) _options.push(this.selectedOptionID);

            const _o: SPSelectionModel = new SPSelectionModel();
            _o.serviceProviderID = this.selectedServiceProviderID;
            _o.serviceTypeID = this.selectedServiceTypeID;
            _o.optionIDs = _options;

            _o.currentPrice = payment.amount != null ? payment.amount : 0;
            _o.estimated = false;
            _o.finance = payment;

            if (this.inPopup)
                this.providerSelected.emit(_o);
            else
                this.createWorkOrder(_o);
        }
    }

    private bindResults() {

        if (this.selectedServiceTypeID <= 0 || (this.inPopup && !this.allowToPickSP) || this.loadingSP === true ) return;

        if (this.selectedOptionIDs == null)
            this.selectedOptionIDs = new Array<number>();

        if (!this.canHaveMultiples) {
            this.selectedOptionIDs = []; // Reset if can not have Multiples
            if (this.selectedOptionID > 0) this.selectedOptionIDs.push(this.selectedOptionID);
        }

        const _data = {
            serviceTypeID: this.selectedServiceTypeID
            , propertyTypeID: this.propertyTypeID === 0 ? appEnvironment.defaultPropertyTypeID : this.propertyTypeID
            , optionIDs: this.selectedOptionIDs
            , geographicArea: this.geographicArea
            , distance: this.distance
        };

        if (!this.inPopup)
            this.gc.setComponentBusy(true);

        this.loadingSP = true;
        let response;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getServiceProviders(_data).subscribe(
            (data) => { response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (!this.inPopup)
                    this.gc.setComponentBusy(false);

                this.loadingSP = false;
                this.messages = {
                    emptyMessage: 'There are no providers for the requested Criteria.' + (this.hideServiceType ? '' : ' Please change the criteria to find others.')
                };

                if (response && response.data) {
                    this.serviceProviders = response.data.map((c) => Utils.castTo(c, new SPSearchResult()));
                }

            });
    }

    private createWorkOrder(spSelection: SPSelectionModel) {

    }
}
