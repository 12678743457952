<div mat-dialog-title>
    <h4>{{title}}</h4>
    <a class="close"title="Close"  (click)="cancel($event)"><i class="fa fa-times-circle-o"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
        <mat-card-content>
            <div *ngIf="!isMessageAsHTML">{{message}}</div>
            <div *ngIf="isMessageAsHTML" hrefToRouterLink [innerHTML]="message | safeHtml"></div>
        </mat-card-content>
        <mat-card-actions>
            <div *ngIf="isConfirmation">
                <button mat-raised-button color="primary" class="button-margin" (click)="onYes()">{{lblYes}}</button>
                <button mat-raised-button color="accent" class="button-margin" (click)="onNo()">{{lblNo}}</button>
            </div>
            <div *ngIf="!isConfirmation">
                <button mat-raised-button color="primary" class="button-margin" (click)="onYes()">{{lblOk}}</button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>