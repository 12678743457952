<mat-card *ngIf="currentListing?.canRevokeAcceptance" class="section">
    <a (click)="revokeAcceptance()" title="Revoke your acceptance to Disclosures">Revoke Disclosure Acceptance</a>
</mat-card>
<mat-card *ngIf="currentListing != null && (currentOffer?.canSignDisclosures ?? false)" class="section">
    <span *ngIf="!anyRequiredPending"><span>I have read all the disclosures and verified it to my satisfaction.</span>&nbsp;<a (click)="acceptDisclosures()" class="link">I Accept Disclosure(s) </a> submitted by Seller.</span>
    <span *ngIf="anyRequiredPending" class="danger">One or more disclosures has NOT been prodived by Seller. Offers can be submitted but not ready for final ACCEPTANCE of Purchase Agreement. You may send a message to Sellers to perform the required Action(s).</span>
</mat-card>
<mat-progress-bar mode="indeterminate" *ngIf="this.componentBusy"></mat-progress-bar>
<mat-card class="section small" *ngIf="this.allCount > 0 && currentListing != null">
    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="toggleFilter" (change)="updateView()">
        <mat-button-toggle value=0><span matBadge="{{this.allCount}}" matBadgeOverlap="false">All</span></mat-button-toggle>
        <mat-button-toggle value=1 *ngIf="pendingCount > 0"><span matBadge="{{this.pendingCount}}" matBadgeOverlap="false" matBadgeColor="{{anyRequiredPending ? 'warn' : ''}}">Pending Fulfillment</span></mat-button-toggle>
        <mat-button-toggle value=2 *ngIf="fulfilledCount > 0"><span matBadge="{{this.fulfilledCount}}" matBadgeOverlap="false">Fulfilled</span></mat-button-toggle>
    </mat-button-toggle-group>

    <a class="btn btn-secondary float-right green" (click)="createServiceOrder()" title="Create Service Order and hire a professional to complete disclosures" *ngIf="currentListing.canCreateDisclosureServiceOrder">Need Help? Hire Service Provider</a>
    <a class="btn btn-secondary  float-right" (click)="requestNewDisclosure()" *ngIf="canRequestNewDisclosure"> {{currentListing.isSeller ? 'Add Disclosure' : 'Request Disclosure/Information'}}</a>
    <i class="fa fa-bullhorn fa-2x  float-right" (click)="sendNotice()" *ngIf="currentOffer && currentOffer.canSendDisclosureNotice" title="Send Notice to provide/complete Disclosures"></i>
    <span *ngIf="utils.isNullOrEmpty(currentListing?.disclosuresAcceptedBy) == false">
        <label class="caption auto">Disclosures has been accepted by {{currentListing.disclosuresAcceptedBy}}. Any changes to disclosures will revoke prior acceptances and will required all Buyers to re-accept. This will cause delay in Close of Escrow. </label>
    </span>
</mat-card>
<mat-card class="section" *ngIf="currentListing">
    <ng-template [ngIf]="(this.disclosures?.length > 0)" [ngIfElse]="noDisclosures">
        <mat-card class="section" *ngFor="let disclosure of disclosures | paginate: { itemsPerPage: 5 , currentPage: pageNumber }; let i = index">
            <div [ngClass]="{'warning-border' : (disclosure.canUpdate == null || disclosure.canUpdate == false) && utils.isNullOrEmpty(disclosure.readonlyValue)}">
                <label *ngIf="currentListing.isSeller && disclosure.required === true" class="danger nomargin bold" title="Required Prior to Close of Escrow">*&nbsp;</label>
                <label *ngIf="currentListing.isSeller && disclosure.requiredForListing === true" class="danger nomargin" title="Required to Complete prior to publishing the Listing">$&nbsp;</label>
                <span title="{{disclosure.helpText}}" class="bold">{{disclosure.disclosure}}</span>&nbsp;{{disclosure.serviceProvider}}
            </div>
            <mat-action-row style="flex:none;display:initial">
                <hr />
                <span *ngIf="readonly || disclosure.canUpdate == null || disclosure.canUpdate == false" [ngClass]="{'warning-focused' : disclosure.pending}" title="Seller's response to the disclosure" style="margin-left:30px">>>>> &nbsp; &nbsp;{{disclosure.readonlyValue}}</span>
                <div *ngIf="!readonly && (disclosure.canUpdate != null && disclosure.canUpdate === true)">
                    <input class="app auto" placeholder="Enter disclosure response here." appDisableAutofill maxlength="100" [(ngModel)]="disclosure.stringValue" *ngIf="disclosure.dataType === disclosureDataType.String" type="text" (change)="responseChanged(disclosure.id, $event.target.value)" />
                    <input class="app auto" placeholder="Enter disclosure response here." appDisableAutofill [(ngModel)]="disclosure.integerValue" *ngIf="disclosure.dataType == disclosureDataType.Integer" type="number" (change)="responseChanged(disclosure.id, $event.target.value)" />
                    <input class="app auto" placeholder="Enter disclosure response here." appDisableAutofill [(ngModel)]="disclosure.moneyValue" *ngIf="disclosure.dataType == disclosureDataType.Double" type="number" (change)="responseChanged(disclosure.id, $event.target.value)" />
                    <mat-checkbox *ngIf="disclosure.dataType == disclosureDataType.Boolean" [(ngModel)]="disclosure.booleanValue" (change)="responseChanged(disclosure.id, $event.checked)">Check if the response is YES</mat-checkbox>
                    <span *ngIf="disclosure.availableOptions && disclosure.availableOptions?.length > 0">
                        <mat-select [(ngModel)]="disclosure.selectedOption" *ngIf="!disclosure.multipleOptions" placeholder="Click here to select disclosure response here." (selectionChange)="responseChanged(disclosure.id, disclosure.selectedOption)" class="auto">
                            <mat-option *ngFor="let o of disclosure.availableOptions" [value]="o.id">{{o.name}}</mat-option>
                        </mat-select>
                        <mat-select [(ngModel)]="disclosure.selectedOptions" *ngIf="disclosure.multipleOptions" multiple placeholder="Click here to select disclosure response(s) here." (selectionChange)="responseChanged(disclosure.id, disclosure.selectedOptions)" class="auto">
                            <mat-option *ngFor="let o of disclosure.availableOptions" [value]="o.id">{{o.name}}</mat-option>
                        </mat-select>
                    </span>
                </div>
                <div style="margin-top:20px">
                    <label *ngIf="disclosure.dataType === disclosureDataType.Nothing && currentListing.isSeller === true && (disclosure.documents == null || disclosure.documents.length === 0) && disclosure.required === true" class="caption auto warning">Document(s) missing and are required to fulfill on request.</label>
                    <span *ngIf="disclosure.documents?.length > 0"><span >Document(s)</span> <a class="btn btn-primary float-right" (click)="addDocument(disclosure)" *ngIf="currentListing.isSeller">Add/Change Document (if any)</a></span>
                    <span *ngIf="disclosure.documents == null || disclosure.documents.length === 0"><a class="btn btn-primary float-right" style="margin-top:-10px" (click)="addDocument(disclosure)" *ngIf="currentListing.isSeller">Add/Change Document (if any)</a></span>
                    <a *ngFor="let doc of disclosure.documents; let i = index" class="link help-help {{i%2 === 0 ? 'alt' : ''}}">
                        <span (click)="viewDoc(disclosure.id, doc.id)" title="View/Download Document">{{doc.name}}</span>
                        <span *ngIf="canUpdateComponent">
                            <i class="fa fa-trash danger float-right" (click)="removeDoc(disclosure.id, doc.id)" title="Remove Document"></i>
                        </span>
                    </a>
                </div>
            </mat-action-row>
        </mat-card>
        <div style="text-align:center">
            <hr/>
            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
        </div>
    </ng-template>
    <ng-template #noDisclosures>
        <h3 *ngIf="this.componentBusy === false">No Disclosures has been provided or Requested.</h3>
		<h4 *ngIf="this.componentBusy === false && currentListing.address && !currentListing.address.IsComplete">Address has not been provided or Incomplete.</h4>
    </ng-template>
</mat-card>
