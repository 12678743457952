
export class PaymentUIData {

    resourceCategory: string | undefined;
    requestdata: any;
    //token: string;
    paymentNonce: string;
    //threeDSSecure: boolean = false;
    //preselectVaultedPaymentMethod: boolean = true;
    payment3DSInfo: Payment3DSInformation;
    currency: string = 'USD';
    amount: number = 0;

    header: string = '';
    title: string = '';

    referenceID: number = 0;

    constructor() { }
}

export class Payment3DSBillingAddress {

    givenName: string;
    surname: string;
    phoneNumber: string;
    streetAddress: string;
    extendedAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    countryCodeAlpha2: string;

    constructor() {
        this.givenName = '';
        this.surname = '';
        this.phoneNumber = '';
        this.streetAddress = '';
        this.extendedAddress = '';
        this.locality = '';
        this.region = '';
        this.postalCode = '';
        this.countryCodeAlpha2 = '';
    }
}

export class Payment3DSAdditionalInformation {
    workPhoneNumber: string;
    shippingGivenName: string;
    shippingSurname: string;
    shippingPhone: string;
    shippingAddress: Payment3DSBillingAddress = new Payment3DSBillingAddress();
}

export class Payment3DSInformation {
    amount: number;
    email: string;
    billingAddress: Payment3DSBillingAddress;
    additionalInformation: Payment3DSAdditionalInformation;

    constructor() {
        this.email = '';
        this.billingAddress = new Payment3DSBillingAddress();
        this.additionalInformation = new Payment3DSAdditionalInformation();
    }
}