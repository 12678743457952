import { AfterContentInit, Directive, ElementRef, Input, HostListener} from '@angular/core';

@Directive({
    selector: '[selectOnFocus]'
})
export class SelectOnFocusDirective {

    public constructor(private el: ElementRef) {
        if (this.el) {
            this.el.nativeElement.addEventListener('focus', this.onFocus);
        }
    }

    @HostListener('click', ['$event']) onClick($event) {
        $event.target.select();
    }

    onFocus() {
        if (this.el)
            this.el.nativeElement.select();
    }
}
