<div class="{{css}}" id="divSignUp">
	<h2 class="pageHeader">
		<span class="noborder" *ngIf="!loggedIn && (listingID == null || listingID <= 0)">Register/Create Account</span>
		<span class="noborder" *ngIf="!loggedIn && listingID != null && listingID > 0">{{heading}}</span>
		<span class="noborder" *ngIf="loggedIn">Invite a Friend or Family Member</span>
		<a class="float-right" *ngIf="!loggedIn && !inPopup" (click)="gotoSignin()" style="font-size:x-small;border-bottom:1px solid red">Already have account.</a>
		<span class="float-right noborder"> | </span>
	</h2>
	<mat-card>
		<div class="app-flex-container app-2col">
			<mat-form-field class="withMatError" appearance="outline">
				<mat-label>First Name</mat-label>
				<input matInput appDisableAutofill requiredvalidator [required]="true" maxlength="50" [(ngModel)]="account.firstName" #fn>
				<mat-error [hidden]="fn.valid || fn.pristine">First name is required</mat-error>
			</mat-form-field>
			<mat-form-field class="withMatError" appearance="outline">
				<mat-label>Last Name</mat-label>
				<input matInput appDisableAutofill requiredvalidator [required]="true" [(ngModel)]="account.lastName" maxlength="50" #ln>
				<mat-error [hidden]="ln.valid || ln.pristine">Last Name is required</mat-error>
			</mat-form-field>
		</div>
		<mat-form-field class="withMatError" appearance="outline">
			<mat-label>Email Address</mat-label>
			<input matInput appDisableAutofill emailvalidator requiredvalidator [required]="true" [(ngModel)]="account.emailAddress" maxlength="100" #em="ngModel">
			<mat-error [hidden]="em.valid || em.pristine">Email is required and format should be <i>john&#64;doe.com</i>.</mat-error>
		</mat-form-field>
		<div *ngIf="!loggedIn && (listingID == null || listingID <= 0)">
			<mat-checkbox [(ngModel)]="account.dne">
				<label>Do not send me any Marketing and Promotional Emails.</label>
			</mat-checkbox>
		</div>
		<div *ngIf="!loggedIn">
			<mat-checkbox [(ngModel)]="account.tncAgreed" required [ngClass]="{'danger' : !account.tncAgreed}">
				<label>
					I am at least 18 Years old and I Agree to  {{this.myBRAND}} <a (click)="openTerms()" class="link">Terms of Use</a> and <a (click)="openPolicy()" class="link">Privacy Policy</a>
				</label>
			</mat-checkbox>
		</div>
		<div *ngIf="listingID > 0 && !this.loggedIn">
			<hr />
			<mat-form-field appearance="outline">
				<textarea matInput appDisableAutofill requiredvalidator placeholder="Message to Seller" row="10" cols="50" class="small" [(ngModel)]="message" maxlength="5000" #msgToSeller></textarea>
				<mat-error [hidden]="msgToSeller.valid || msgToSeller.pristine">Enter a message to Seller(s).</mat-error>
			</mat-form-field>
			<hr />
		</div>
		<mat-error *ngIf="!loggedIn && !account.tncAgreed && submitted">Acceptance to legal terms and privacy policy is required.</mat-error>
		<mat-card-actions style="margin-bottom:30px">
			<div class="form-group">
				<a class="btn btn-primary" *ngIf="saveReady" (click)="onSubmit()">{{loggedIn ? 'Send Invitation' : ( listingID == null || listingID === 0 ?  'Register' : 'Send Message')}}</a>
				<a class='btn btn-secondary float-right' (click)="cancelRegister()" *ngIf="!loggedIn">Cancel</a>
			</div>
		</mat-card-actions>
	</mat-card>
</div>
