import { Component, ViewChild, OnInit, Input, Inject, Output, OnDestroy } from '@angular/core';

import { DataService } from '../util/APICaller.component';

import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Role, BaseResponse } from '../models';

import { ListingService } from './listing.service';

import { ListingBaseComponent } from './listing.base.component';
import { ServiceProviderWorkOrdersComponent } from '../workorders/workorders.component';

import { OfferService } from '../offer/offer.service';
import { GlobalComponent } from '../global.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../common/utils';
@Component({
    selector: 'app-listing-work-orders',
    templateUrl: './listingWorkOrders.component.html'
})
export class ListingWorkOrdersComponent extends ListingBaseComponent implements OnInit, OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    @ViewChild('workOrders') workOrders: ServiceProviderWorkOrdersComponent;
    @Input() forRole: Role = Role.NotSet;
    private _lcs: Subscription;
    constructor(ds: DataService, rs: Router, private dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('pgListingWorkOrders', ds, rs, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindWorkOrders();
        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngOnInit() {

        if (this.forRole === Role.NotSet) {
            // If Searching For Agent then Force Agent Type ID and hide Changing Service Type
            if (this.route.url.indexOf('/buyer/') >= 0) {
                this.forRole = Role.Buyer;
            } else if (this.route.url.indexOf('/seller/') >= 0) {
                this.forRole = Role.Seller;
            }
        }

        let _response: BaseResponse;
        if (this.forRole === Role.Seller) {
            this.getMyListingsForSale(this.dataservice, (listings) => {
                this.gc.setBusy(false);
                this.listings = listings;
                if ((this.listingID ?? 0) === 0)
                    this.bindWorkOrders();
            });
        } else {
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getMyOfferListings().subscribe(
                (data) => _response = data
                , (error) => {
                    this.onApiError(error);
                }
                , () => {
                    if (_response) {
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.listings = _response.data;
                            if ((this.listingID ?? 0) === 0)
                                this.bindWorkOrders();
                        }
                    }
                }
            );
        }
    }


    bindWorkOrders() {
        if (((this.listings ?? []).length > 0 && (this.listingID ?? 0) === 0 ) || (this.listingID ?? 0) > 0) {
            this.listingID = (this.listings ?? [])[0].id;
            if (this.workOrders != null)
                this.workOrders.listingID = this.listingID;
        }
    }
}
