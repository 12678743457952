import { GeographicEntityLevel } from '../enums.component';
import { AppBase, RecordTimeStamp } from '../base.model';
import { appEnvironment } from '../../../environments/environment';
import * as moment from 'moment';
import { Utils } from '../../common/utils';

export class spProductRate extends RecordTimeStamp {

    productID: number  = 0;
    optionID: number = 0;
    propertyTypeID: number = 0;

    geographicLevel: GeographicEntityLevel = GeographicEntityLevel.Global;
    geographicKeyID: number = 0 ;

    private _startDate: Date;
    private _endDate: Date;
    private _fixedPrice: number;
    private _percentagePrice: number;
    private _minPrice: number;
    private _maxPrice: number;
    private _adjustable: boolean;
    private _prePaymentPenaltyDuration: number;
    private _points: number;
    private _rateCap: number;
    private _fixedDuration: number;
    private _fixedDurationID: number;
    private _amortizationDuration: number;
    private _amortizationDurationID: number;

    constructor() {
        super();
        this.geographicLevel = GeographicEntityLevel.Global;
        this.geographicKeyID = this.productID = this.optionID = this.propertyTypeID = 0;
        this.adjustable = false;
        this.prePaymentPenaltyDuration = this.points = this.rateCap = this.fixedDuration = this.amortizationDuration = 0;
        this.startDate = this.endDate = Utils._minDate;
        this.id = this.productID = this.optionID = this.propertyTypeID = this.geographicKeyID = this.fixedPrice = this.percentagePrice = this.minPrice = this.maxPrice = 0;
    }

    get startDate(): Date { return this._startDate; }
    set startDate(v: Date) { this._startDate = v; this.isDirty = true; }

    get endDate(): Date { return this._endDate; }
    set endDate(v: Date) { this._endDate = v; this.isDirty = true; }

    get fixedPrice(): number { return this._fixedPrice; }
    set fixedPrice(v: number) { this._fixedPrice = v; this.isDirty = true; }

    get percentagePrice(): number { return this._percentagePrice; }
    set percentagePrice(v: number) { this._percentagePrice = v; this.isDirty = true; }

    get minPrice(): number { return this._minPrice; }
    set minPrice(v: number) { this._minPrice = v; this.isDirty = true; }

    get maxPrice(): number { return this._maxPrice; }
    set maxPrice(v: number) { this._maxPrice = v; this.isDirty = true;}

    get downPayment() :number { return this.minPrice; }
    set downPayment(v: number) { this.minPrice = v; }

    get adjustable(): boolean { return this._adjustable; }
    set adjustable(v: boolean) { this._adjustable = v; this.isDirty = true; }

    get prePaymentPenaltyDuration(): number { return this._prePaymentPenaltyDuration; }
    set prePaymentPenaltyDuration(v: number) { this._prePaymentPenaltyDuration = v; this.isDirty = true; }

    get points(): number { return this._points; }
    set points(v: number) { this._points = v; this.isDirty = true; }

    get rateCap(): number { return this._rateCap; }
    set rateCap(v: number) { this._rateCap = v; this.isDirty = true; }

    get fixedDuration(): number { return this._fixedDuration; }
    set fixedDuration(v: number) { this._fixedDuration = v; this.isDirty = true; }

    get fixedDurationID(): number { return this._fixedDurationID; }
    set fixedDurationID(v: number) { this._fixedDurationID = v; this.isDirty = true; }

    get amortizationDuration(): number { return this._amortizationDuration; }
    set amortizationDuration(v: number) { this._amortizationDuration = v; this.isDirty = true; }

    get amortizationDurationID(): number { return this._amortizationDurationID; }
    set amortizationDurationID(v: number) { this._amortizationDurationID = v; this.isDirty = true; }

    get canEdit(): boolean {
        return moment(this.startDate).isSameOrAfter(Utils.tomorrow);
    }
    get rateText(): string {
        if (this.productID !== appEnvironment.lenderServiceTypeID) {
            if (this.percentagePrice != null && this.percentagePrice <= 0 && this.fixedPrice != null && this.fixedPrice <= 0)
                return 'RFQ required.';
            else {
                if (this.percentagePrice != null && this.fixedPrice && this.fixedPrice === 0)
                    return this.percentagePrice.toString() + ' % ' + (this.minPrice == null ? '' : (' Min Price - ' + this.FormatNumberToCurrency(this.minPrice)))
                        + (this.maxPrice == null ? '' : ' - ' + (' Max Price - ' + this.FormatNumberToCurrency(this.maxPrice)));
                else if (this.fixedPrice != null)
                    return this.FormatNumberToCurrency(this.fixedPrice);
                else
                    return 'RFQ required.';
            }
        } else {
            return (this.downPayment ? ('Down Payment ' + this.downPayment) + ' %,' : '') + '  APR - ' + (this.percentagePrice.toString() + ' % ')
                + (' Amortized Over ' + this.amortizationDuration + ' yrs.') + (this.adjustable ? (' [ARM]' + this.fixedDuration.toString() + '/1, Rate Cap. ' + this.rateCap + ' %') : '')
                + (this.prePaymentPenaltyDuration > 0 ? (', Prepayment penalty duration- ' + this.FormatNumberToCurrency(this.prePaymentPenaltyDuration)) : '');
        }
    }

}

export class serviceItemRequestModel {
    public serviceProviderID: number;
    public serviceTypeID: number;
    public optionID: number;
}

export class serviceRateRequestModel extends serviceItemRequestModel {
    public propertyTypeID: number;
    public geopgraphicEntityID: number;
    public geographicLevel: number;
}

export class spProductRateItem extends RecordTimeStamp {
    offeringID: number;
    productID: number;
    optionID: number;
    private _percentagePrice: number;
    private _fixedPrice: number;
    private _name: string;

    constructor() {
        super();
        this.fixedPrice = this.percentagePrice = this.id = this.productID = this.optionID = this.offeringID = 0;
        this.name = '';
    }

    get fixedPrice(): number { return this._fixedPrice; }
    set fixedPrice(v: number) { this._fixedPrice = v; this.isDirty = true; }

    get percentagePrice(): number { return this._percentagePrice; }
    set percentagePrice(v: number) { this._percentagePrice = v; this.isDirty = true; }

    get name(): string { return this._name; }
    set name(v: string) { this._name = v; this.isDirty = true; }

    get rateText(): string {
        if (this.percentagePrice != null && this.percentagePrice <= 0 && this.fixedPrice != null && this.fixedPrice <= 0)
            return '';
        else {
            if (this.percentagePrice != null && this.fixedPrice && this.fixedPrice === 0)
                return this.percentagePrice.toString() + '% ';
            else if (this.fixedPrice != null)
                return this.FormatNumberToCurrency(this.fixedPrice);
            else
                return '';
        }
    }

}
