import { RecordTimeStamp } from '../base.model';
import { Utils } from '../../common/utils';

export class LicenseEntity extends RecordTimeStamp {
    private _licenseTypeID: number;
    private _expiryDate: Date;
    private _issuingAuthority: string;
    private _licenseNumber: string;
    private _licenseType: string = '';

    constructor() {
        super();
        this.id = this._licenseTypeID = 0;
        this._issuingAuthority = this._licenseNumber = this._licenseType = '';
        this._expiryDate = Utils._minDate;
    }

    public get ServiceProviderLicenseID(): number { return this.id; }
    public set ServiceProviderLicenseID(v: number) { if (v == null) v = 0; this.id = v; }

    public get LicenseTypeID(): number { return this._licenseTypeID; }
    public set LicenseTypeID(v: number) { if (v == null) v = 0; this._licenseTypeID = v; }

    public get LicenseType(): string { return this._licenseType; }
    public set LicenseType(v: string) { if (v == null) v = ''; this._licenseType = v; }

    public get IssuingAuthority(): string { return this._issuingAuthority; }
    public set IssuingAuthority(v: string) { if (v == null) v = ''; this._issuingAuthority = v; }

    public get LicenseNumber(): string { return this._licenseNumber; }
    public set LicenseNumber(v: string) { if (v == null) v = ''; this._licenseNumber = v; }

    public get ExpiryDate(): Date { return this._expiryDate; }
    public set ExpiryDate(v: Date) { if (v == null) v = Utils._minDate; this._expiryDate = v; }

    public IsComplete(): boolean {
        return this.LicenseTypeID > 0 && !Utils.isNullOrEmpty(this.IssuingAuthority);
    }

    toJSON() {
        return {
            ServiceProviderLicenseID: this.id,
            LicenseTypeID: this._licenseTypeID,
            LicenseNumber: this._licenseNumber,
            IssuingAuthority: this._issuingAuthority,
            ExpiryDate: this._expiryDate
        };
    }

}
