<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card *ngIf="detailItem.productID > 0">
        <mat-card *ngIf="manageVersions">
            <app-datepicker #txtStartDate id="txtStartDate" [placeholder]="'New Start Date'"></app-datepicker>
        </mat-card>
        <mat-card>
            <mat-form-field *ngIf="allowSubject">
                <mat-label class="placeholder">Subject/Title <i class="fa fa-question-circle-o warning-color" title="Enter the subject/title of the Item you are adding"></i></mat-label>
                <input matInput appDisableAutofill  [(ngModel)]="detailItem.subject" maxlength="100"/>
            </mat-form-field>
            <div class="app-flex-container app-2col">
                <mat-form-field>
                    <mat-label class="placeholder">Deliver To <i class="fa fa-question-circle-o warning-color" title="Select the roles to whom you wish to deliver this document"></i></mat-label>
                    <mat-select multiple [(ngModel)]="detailItem.roleIDs">
                        <mat-option *ngFor="let p of roles" [value]="p.id">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="placeholder">Work Order Status <i class="fa fa-question-circle-o warning-color" title="Select Work Order Status at which the document will be automatically delivered by system, if none selected, document will NOT BE AUTOMATICALLY delivered by system"></i></mat-label>
                    <mat-select [(ngModel)]="detailItem.deliverAtWorkOrderStatus">
                        <mat-option *ngFor="let p of workOrderStatuses" [value]="p.id">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-checkbox [(ngModel)]="detailItem.acceptanceRequired">Accetpance is required by the people of selected Roles(s) for processing of Work Order</mat-checkbox>
        </mat-card>
        <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
        <mat-card *ngIf="isValid && !saving">
            <button mat-raised-button (click)="onSubmit()">Save</button>
        </mat-card>
    </mat-card>
    <mat-card *ngIf="(detailItem.productID == 0) && detailItem.id == 0">
        Invalid or Missing Product and/or PropertyType ID.
    </mat-card>
</div>
