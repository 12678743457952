import {
    NG_VALIDATORS,
    Validator,
    AbstractControl
} from '@angular/forms';
import { Directive, Input } from '@angular/core';
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[requiredvalidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: RequiredValidator,
            multi: true
        }
    ]
})

export class RequiredValidator implements Validator {
    private _required: boolean = true;
    private _onChange!: () => void;

    @Input()
    get required(): boolean { return this._required; }

    set required(value: boolean) {
        this._required = value != null && `${value}` !== 'false';
        if (this._onChange) this._onChange();
    }

    validate(control: AbstractControl) {
        return this._required ? this.requiredValidator(control) : null;
    }

    requiredValidator(control: AbstractControl): any {
        const isValid = (control.value != null && control.value.toString().trim() !== '' && control.value !== 0);
        if (isValid) {
            return null;
        } else {
            return {
                required: true
            };
        }
    }

    registerOnValidatorChange(fn: () => void) { this._onChange = fn; }

}
