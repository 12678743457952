<mat-card *ngIf="serviceID > 0 && services && services.length > 0">
    <ngx-datatable class="material expandable" #listingServiceTable [loadingIndicator]="binding"
                   [headerHeight]="headerHeight" [footerHeight]="footerHeight" [rowHeight]="rowHeight"
                   [rows]="services" [columnMode]="'force'" [messages]="messages"
                   [limit]="numberOfRecords" (page)="onPage($event)">
        <ngx-datatable-row-detail [rowHeight]="'auto'" #rowDetail (toggle)="onDetailToggle($event)">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="ngxDataTableDetail">
                    <span><label class="bold">Service Provider</label> - {{row.serviceProvider}}</span>
                </div>
            </ng-template>
        </ngx-datatable-row-detail>
        <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template>Service</ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">{{row.description}}</ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template>Price</ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">{{row.cost.formatMoney()}}</ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template>Paid By</ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">{{row.toBePaidBy}}</ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template>Repairs</ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                {{row.requiredRepairsPaidBySeller ? ("Seller paying " + (row.maxRequiredRepairsPaidBySeller > 0 ? row.maxRequiredRepairsPaidBySeller.formatMoney() : "" )+ " for repairs") : ""}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false">
            <ng-template ngx-datatable-header-template></ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                <i class="fa fa-times-circle danger" (click)="removeService(row.id)" *ngIf="row.canDelete"></i>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template
                         let-rowCount="rowCount"
                         let-pageSize="pageSize"
                         let-selectedCount="selectedCount"
                         let-curPage="curPage"
                         let-offset="offset">
                <div class="ngxDataTableFooter">
                    Total Services: {{rowCount}} |
                    Services per Page: {{isNaN(pageSize) ? "" : pageSize}} |
                    Current Page #: {{isNaN(pageSize) ? "" : curPage}}
                </div>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</mat-card>