import { Injectable } from '@angular/core';

// cdk
import { Overlay} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner} from '@angular/material/progress-spinner';

// rxjs
import { Subject } from 'rxjs/internal/Subject';
import { scan, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UIBusyService {

    spin$: Subject<boolean> = new Subject();
    private spinnerTopRef = this.cdkSpinnerCreate();

    constructor( private overlay: Overlay) {

        this.spin$
            .asObservable()
            .pipe(
                map((val) => val ? 1 : -1),
                scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
            )
            .subscribe(
                (res) => {
                    if (res === 1) {
                        this.showSpinner();
                    } else if (res === 0) {
                        // tslint:disable-next-line: no-unused-expression
                        this.spinnerTopRef?.hasAttached() ? this.stopSpinner() : null;
                    }
                }
            );
    }

    showSpinner() {
        // console.log("attach");
        if (!this.spinnerTopRef?.hasAttached())
            this.spinnerTopRef?.attach(new ComponentPortal(MatSpinner));
    }

    stopSpinner() {
        // console.log("dispose");
        this.spinnerTopRef?.detach();
    }

    private cdkSpinnerCreate() {
        return this.overlay?.create({
            hasBackdrop: true,
            backdropClass: 'dark-backdrop',
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically()
        });
    }

}
