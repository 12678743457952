import { Router, Route } from '@angular/router';
import { BaseService } from './base.service';

import { DataService } from '../../util/APICaller.component';
import { LegalTerm } from '../../models';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LegalService extends BaseService {
    private ds: DataService;
    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    getLegalTerm(iType: number, iProductID: number, iRoleID: number, iISOCountryCode: number, iStateiD?: number, iCountyID?: number, iCityID?: number) {
        const data = { Type: iType, ProductID: iProductID, ISOCOuntryCode: iISOCountryCode, StateID: iStateiD, CountyID: iCountyID, CityID: iCityID, Role: iRoleID };
        return this.ds.APIPost('/legal/term', data, this.requestingModule);
    }

    getLegalTermDetail(iType: number, iProductID: number, iRoleID: number, iISOCountryCode: number, iStateiD?: number, iCountyID?: number, iCityID?: number) {
        const data = { Type: iType, ProductID: iProductID, ISOCOuntryCode: iISOCountryCode, StateID: iStateiD, CountyID: iCountyID, CityID: iCityID, Role: iRoleID };
        return this.ds.APIPost('/legal/termDetail', data, this.requestingModule);
    }

    getLegalTermByVersion(iVersionID: number) {
        const data = { VersionID: iVersionID };
        return this.ds.APIPost('/legal/term', data, this.requestingModule);
    }

    getTermsOfUse(iISOCountryCode?: number) {
        const data = { ISOCOuntryCode: iISOCountryCode };
        return this.ds.APIPreLoginPost('/legal/termsofuse', data, this.requestingModule);
    }

    getPrivacyPolicy(iISOCountryCode?: number) {
        const data = { ISOCOuntryCode: iISOCountryCode };
        return this.ds.APIPreLoginPost('/legal/privacypolicy', data, this.requestingModule);
    }

    save(iID: number, sName: string) {
        const data = { id: iID, name: sName };
        return this.ds.APIPost('/legal/save', data, this.requestingModule);
    }

    addNewVersion(oTerm: LegalTerm, dtStartDate: Date) {
        oTerm['startDate'] = dtStartDate;
        return this.ds.APIPost('/legal/addnewVersion', oTerm, this.requestingModule);
    }

}
