import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { GlobalComponent } from '../../global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { assessment, Role, BaseResponse } from '../../models';
import { ListingService } from '../../listing/listing.service';
import { OfferService } from '../../offer/offer.service';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../utils';

@Component({
    selector: 'app-agent-terms',
    templateUrl: './agentTerms.dialog.html'
})
export class AgentTermsDialog extends BaseDialog implements OnInit {

    agentRepresenting: Role = Role.NotSet;
    entityID: number = 0;
    showAcceptance: boolean = false;
    agentTermsHTML: string = '';
    agentName: string = '';
    brokerName: string = '';
    propertyAddress: string = '';
    dialogHeader: string = '';

    constructor(private ds: DataService, private r: Router, @Inject(GlobalComponent)  gc: GlobalComponent, private dialog: MatDialog
        , public dialogRef: MatDialogRef<AgentTermsDialog>, @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder, private cdref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('agentTermsDialog', ds, r, dialogRef, data);
    }

    ngOnInit() {
        if (this.data) {
            if (this.data.needAcceptance) this.showAcceptance = this.data.needAcceptance;
            if (this.data.id) this.entityID = this.data.id;
            if (this.data.header) this.dialogHeader = this.data.header;
            if (this.data.agentName) this.agentName = this.data.agentName;
            if (this.data.brokerName) this.brokerName = this.data.brokerName;
            if (this.data.propertyAddress) this.propertyAddress = this.data.propertyAddress;
            if (this.data.agentRepresenting) this.agentRepresenting = this.data.agentRepresenting;
        }

        const agentAcceptanceMessage: string = 'I Accept <b>' + this.agentName + (Utils.isNullOrEmpty(this.brokerName) ? '' : '[' + this.brokerName + ']') + '</b> as Agent for <u>' +
            (this.agentRepresenting === Role.Buyer ? 'Buyer(s)' : 'Seller(s)') + '</u> during transaction processing ' + (Utils.isNullOrEmpty(this.propertyAddress) ? '' : (' for the sale of ' + this.propertyAddress));

        if (this.entityID === 0) {
            setTimeout(() => {
                this.close(null);
            }, 1000);
        } else {
            let _response: BaseResponse;

            if (this.agentRepresenting === Role.Seller) {
                (new ListingService(this.dataservice, this.r, this.myResourceCategory)).agentTerms(this.entityID).subscribe(
                    (data) => _response = data
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response)
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.close(null);
                            else {
                                this.agentTermsHTML = Utils.isNullOrEmpty(_response.data) ? agentAcceptanceMessage : _response.data;
                            }
                    }
                );
            } else if (this.agentRepresenting === Role.Buyer) {
                (new OfferService(this.dataservice, this.r, this.myResourceCategory)).agentTerms(this.entityID).subscribe(
                    (data) => _response = data
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response)
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.close(null);
                            else {
                                this.agentTermsHTML = Utils.isNullOrEmpty(_response.data) ? agentAcceptanceMessage : _response.data;
                            }
                    }
                );
            } else {
                setTimeout(() => {
                    this.close(null);
                }, 1000);
            }
        }
    }

    close(data) {
        this.dialogRef.close(data);
    }

    acceptTerms() {
        let _response;
        if (this.agentRepresenting === Role.Seller) {
            (new ListingService(this.dataservice, this.r, this.myResourceCategory)).acceptAgentTerms(this.entityID).subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.close(true);
                        }
                }
            );
        } else if (this.agentRepresenting === Role.Buyer) {
            (new OfferService(this.dataservice, this.r, this.myResourceCategory)).acceptAgentTerms(this.entityID).subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.close(true);
                        }
                }
            );
        }
    }
}
