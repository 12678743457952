import { Router } from '@angular/router';
import { BaseService } from '../common/service/base.service';
import { DataService } from '../util/APICaller.component';
import { newListing, SignUp } from '../models';
import { GlobalComponent } from '../global.component';
import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PersonService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    signup(oNewAccount: SignUp) {
        if (oNewAccount['invited'] === true)
            return this.ds.APIPost('/user/invite', oNewAccount);
        else
            return this.ds.APIPreLoginPost('/user/register', oNewAccount);
    }

    verifyRecaptcha(sToken: string) {
        GlobalComponent._cv = false;
        const data = { token: sToken };
        return this.ds.APIPreLoginPost('/verifyRecaptcha', data);
    }

    unsubscribe(sEmail: string) {
        const data = { email: sEmail };
        return this.ds.APIPreLoginPost('/user/unsubscribe', data);
    }

    logout() {
        return this.ds.APIPost('/user/logout', null, this.requestingModule, null, '2.0');
    }

    restore(oData) {
        return this.ds.APIPreLoginPost('/user/restore', oData, this.requestingModule);
    }

    getCertifications(iPID: number, verifyPending: boolean = false) {
        const data = { pid: iPID };
        if (verifyPending === true)
            return this.ds.APIPost('/person/getCertificationPendingVerification', data, this.requestingModule);
        else
            return this.ds.APIPost('/person/getCertifications', data, this.requestingModule);
    }

    addCertification(id: number) {
        const data = { ServiceTypeID: id };
        return this.ds.APIPost('/person/addCertification', data, this.requestingModule);
    }

    verifyCertification(id: number) {
        const data = { id };
        return this.ds.APIPost('/person/verifyCertification', data, this.requestingModule);
    }

    saveCertifications(oCertifications: any) {
        const data = { certifications: oCertifications };
        return this.ds.APIPost('/person/saveCertifications', data, this.requestingModule);
    }

    getReceivedMessages() {
        const data = null;
        return this.ds.APIPost('/message/received', data, this.requestingModule);
    }

    markMessageRead(iMessageID: number) {
        const data = { id: iMessageID };
        return this.ds.APIPost('/message/read', data, this.requestingModule);
    }
    recall(iMessageID: number) {
        const data = { id: iMessageID };
        return this.ds.APIPost('/message/recall', data, this.requestingModule);
    }
    delete(iMessageID: number) {
        const data = { id: iMessageID };
        return this.ds.APIPost('/message/delete', data, this.requestingModule);
    }
    permanentDelete(iMessageID: number) {
        const data = { id: iMessageID };
        return this.ds.APIPost('/message/permanentDelete', data, this.requestingModule);
    }

    getSentMessages() {
        const data = null;
        return this.ds.APIPost('/message/sent', data, this.requestingModule);
    }

    replyToMessage(iMessageID: number, sResponse: string, bReplyToAll: boolean) {
        const data = { messageID: iMessageID, response: sResponse, replyToAll: bReplyToAll };
        return this.ds.APIPost('/message/reply', data, this.requestingModule);
    }

    sendMessage(iSender: number, iRecepients: number[], sSubject: string, sMessage: string) {
        const data = { sender: iSender, recepients: iRecepients, subject: sSubject, message: sMessage };
        return this.ds.APIPost('/message/send', data, this.requestingModule);
    }

    getAppointments() {
        const data = null;
        return this.ds.APIPost('/person/appointments', data, this.requestingModule);
    }

    getWorkOrders(iListingID: number = 0) {
        const _data = { listingIDs: [iListingID] };
        /* same as /serviceprovider/workOrders */
        return this.ds.APIPost('/serviceprovider/workOrders', _data, this.requestingModule);
    }

    getWorkOrdersInProgress() {
        const data = { serviceProviderID: 0 };
        return this.ds.APIPost('/user/workOrdersInProgress', data, this.requestingModule);
    }
    getMyLoans(iLoanID: number = 0) {
        const data = { id: iLoanID, includeHeader: false };
        return this.ds.APIPost('/user/myLoans', data, this.requestingModule);
    }
    getMyOffers() {
        return this.ds.APIPost('/user/myoffers', null, this.requestingModule);
    }

    getBids(types: number[] = []) {
        const data = { serviceTypeIDs : types};
        return this.ds.APIPost('/user/myBids', data, this.requestingModule);
    }

    getPossibleWork(iServiceTypeIDs: number[] = []) {
        const data = { serviceTypeIDs: iServiceTypeIDs };
        return this.ds.APIPost('/user/myPossibleWork', data, this.requestingModule);
    }

    buyNewListing(oListingOrder: newListing) {
        const data = oListingOrder;
        return this.ds.APIPost('/user/buyNewListing', data, this.requestingModule);
    }

    initRenewListing(id: number) {
        const data = { renewListingID: id };
        return this.ds.APIPost('/user/initRenewListing', data, this.requestingModule);
    }

    getFavoriteProperties() {
        return this.ds.APIPost('/user/bookmarkedProperties', null, this.requestingModule);
    }

    getMyCustomerBookmarks() {
        const data = null;
        return this.ds.APIPost('/user/myCustomerBookMarks', data, this.requestingModule);
    }
}
