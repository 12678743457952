import {
    HttpHandler, HttpProgressEvent, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpUserEvent
    , HttpRequest, HttpResponse, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

// https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L18
const XSSI_PREFIX = /^\)\]\}',?\n/;

import { timeout } from 'rxjs/operators';

import { defaultTimeout } from '../../models/appCommon.models';

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        if (req.body != null && req.body.criteria != null) {
            this.parseBodyToNormalizeDate(req.body.criteria);
        }

        const timeoutMS = Number(req.headers.get('timeout')) || defaultTimeout;
        req.headers.delete('timeout');

        // IMPORTANT  Un comment to Response Parsing
        //// convert to responseType of text to skip angular parsing
        // req = req.clone({
        //  responseType: 'text'
        // });
        // END - IMPORTANT  Un comment to Response Parsing

        // Only turn off caching for API calls to the server.
        if (req.url.indexOf('/api/') >= 0 && req.url.indexOf('/stream/') < 0) {
            const nextReq = req.clone({
                headers: req.headers.append('Cache-Control', 'private, no-cache, no-store, must-revalidate')
                    .append('Pragma', 'no-cache')
                    .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
                    .append('If-Modified-Since', '0')
                    .append('Content-Type', 'application/json; charset=utf-8')
                    .append('Accept', 'application/json')
            });

            return next.handle(nextReq).pipe(timeout(timeoutMS));

            // IMPORTANT  Un comment to Response Parsing
            //  .pipe(map(event => {
            //  // Pass through everything except for the final response.
            //  if (!(event instanceof HttpResponse)) {
            //    return event;
            //  }
            //  return this.processJsonResponse(event);
            // }));
            // END ) IMPORTANT  Un comment to Response Parsing


        } else if (req.url.indexOf('/api/') >= 0 && req.url.indexOf('/stream/') >= 0) {
            const nextReq = req.clone({
                headers: req.headers.append('Cache-Control', 'private, no-cache, no-store, must-revalidate')
                    .append('Pragma', 'no-cache')
                    .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
                    .append('If-Modified-Since', '0')
                    .append('Content-Type', 'text/event-stream')
                // .append('Accept', 'application/json')
            });

            return next.handle(nextReq).pipe(timeout(timeoutMS));
        } else {
            // Pass the request through unchanged.
            return next.handle(req).pipe(timeout(timeoutMS));
        }
    }

    private processJsonResponse(res: HttpResponse<string>): HttpResponse<any> {
        let body = res.body;
        if (typeof body === 'string') {
            const originalBody = body;
            body = body.replace(XSSI_PREFIX, '');
            try {
                body = body !== '' ? JSON.parse(body, (key: any, value: any) => this.reviveUtcDate(key, value)) : null;
            } catch (error) {
                // match https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L221
                throw new HttpErrorResponse({
                    error: { error : error, text: originalBody },
                    headers: res.headers,
                    status: res.status,
                    statusText: res.statusText,
                    url: res.url || undefined,
                });
            }
        }
        return res.clone({ body: body });
    }

    /**
     * Detect a date string and convert it to a date object.
     * @private
     * @param {*} key json property key.
     * @param {*} value json property value.
     * @returns {*} original value or the parsed date.
     * @memberof JsonInterceptor
     */
    private reviveUtcDate(key: any, value: any): any {
        if (typeof value !== 'string') {
            return value;
        }
        if (value === '0001-01-01T00:00:00') {
            return null;
        }
        const match = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
        if (!match) {
            return value;
        }
        return new Date(value);
    }

    private parseBodyToNormalizeDate(object: any) {


        if (!object || !(object instanceof Object)) {
            return;
        }

        if (object instanceof Array) {
            for (const item of object) {
                this.parseBodyToNormalizeDate(item);
            }
        }

        for (const key of Object.keys(object)) {const value = object[key];

            if (value instanceof Array) {
                for (const item of value) {
                    this.parseBodyToNormalizeDate(item);
                }
            }

            if (value instanceof Object) {
                this.parseBodyToNormalizeDate(value);
            }

            if ((typeof value === 'string' || value instanceof moment || value instanceof Date)
                && key.toLocaleLowerCase().endsWith('date') || key.toLocaleLowerCase().endsWith('time')) {
                object[key] = this.toInputDateTimeString(value);
            }

        }

    }

    private toInputDateTimeString(date): string {
        if (date != null && moment(date).isValid()) {
            const _dt = moment(date).utcOffset(0, false).toDate();
            const _d = (('000' + (_dt.getFullYear())).slice(-4) + '-'
                + ('0' + (_dt.getMonth() + 1)).slice(-2) + '-'
                + ('0' + (_dt.getDate())).slice(-2))
                + 'T' + _dt.toTimeString().slice(0, 8);
            console.log(_d);
            return _d;
        } else
            return null;
    }
}
