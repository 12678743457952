<div mat-dialog-title>
    <h4>{{dialogHeader}}</h4>
    <a class="close" title="Close" (click)="close(null)"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <div class="app-flex-container app-2col" *ngIf="serviceTypes.length > 0">
        <mat-form-field>
            <mat-label class="placeholder">Allowed Services <i class="fa fa-question-circle-o warning-color" title="Select available service which you want to Add"></i></mat-label>
            <mat-select [(ngModel)]="selectedServiceTypeID" (selectionChange)="onServiceTypeSelected()">
                <mat-option *ngFor="let p of serviceTypes" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label class="placeholder">Paid By <i class="fa fa-question-circle-o warning-color" title="Select the role paying for new service"></i></mat-label>
            <mat-select [(ngModel)]="paidBy" (selectionChange)="onRoleChanged()">
                <mat-option *ngFor="let p of roles" [value]="p.id">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
   <app-search-providers *ngIf="serviceTypes.length > 0 && allowToPickSP" (providerSelected)="onServiceProviderSelected($event)" [hideServiceType]="true" #searchSP [selectButtonText]="'Add This'" [collectPaymentIfRequired]="false" [inPopup]="true"></app-search-providers>
    <button mat-button *ngIf="!allowToPickSP && selectedServiceTypeID > 0" (click)="onServiceProviderSelected(null)">Add this Service</button>
    <span *ngIf="serviceTypes == null || serviceTypes.length == 0">No other Additional Services available, all authorized services has already been added.</span>
</div>
