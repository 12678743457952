import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Directive, Input } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';

import { CodeService } from '../common/service/code.service';
import { ServiceProviderService } from './serviceprovider.service';

import { item } from '../models/appCommon.models';

import { RecordTimeStamp } from '../models/base.model';
import { DndDropEvent } from 'ngx-drag-drop';
import { Utils, rolePosition } from '../common/utils';

export class StaffModel extends RecordTimeStamp {
    public pid: number = 0;
    public name: string = '';
    public image: string = '';
    public emailAddress: string = '';
    public roles: item[] = [];
    public newRoleID: number = 0;
}

@Component({
    selector: 'app-staff',
    templateUrl: './staff.component.html'
})
export class StaffComponent extends BaseComponent implements OnInit {

    loading: boolean = false; saving: boolean = false;
    adding: boolean = false;
    emailAddress: string = '';
    roleID: number = 0;
    staff: StaffModel[]; roles: any;
    columns = [{ prop: 'pid', name: 'PID', sortable: false }, { prop: 'name', name: 'Name' }, { prop: 'emailAddress', name: 'Email' }];
    messages = {
        // Message to show when array is presented
        // but contains no values
        emptyMessage: 'There are no staff members',

        // Footer total message
        // totalMessage: 'total'
    };

    isAdmin: boolean = false;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) g: GlobalComponent, private cdref: ChangeDetectorRef) {
        super('pgCompanyStaff', r, g);

        this.isAdmin = this.gc.hasRole(rolePosition.admin);
    }

    ngOnInit() {

        this.loading = true;
        let userResponseData: any;
        // Get Staff
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getStaff().subscribe(
            (data) => { userResponseData = data; }
            , (error) => { this.staff = new Array<StaffModel>(); this.loading = false; this.onApiError(error); }
            , () => {
                this.loading = false;
                if (userResponseData && userResponseData.data)
                    this.staff = userResponseData.data.map((c) => { return (new StaffModel()).castToMe(c); });
                else
                    this.staff = new Array<StaffModel>();
            });

        let stResponseData;
        (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getSecurityRoles().subscribe(
            (data) => { stResponseData = data; }
            , (error) => { this.roles = new Array<item>(); this.onApiError(error); }
            , () => {
                if (stResponseData.data)
                    this.roles = stResponseData.data;
                else
                    this.roles = new Array<item>();
            });
    }

    removeRole(staff, id) {
        this.gc.setComponentBusy(true);
        let userResponseData: any;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).removeStaffRole(staff.pid, id).subscribe(
            (data) => { userResponseData = data; }
            , (error) => { this.gc.setComponentBusy(false); this.onApiError(error); }
            , () => {
                this.gc.setComponentBusy(false);
                if (Utils.isNullOrEmpty(userResponseData?.error)) {
                    staff.roles = staff.roles.filter((_s) => { return _s.id !== id; });
                }
            });
    }

    onAddRole(staff, event: DndDropEvent) {
        if (event && event.data && event.data.id) {
            // Add Role using PID and roleID
            let userResponseData: any;

            const _roleID: number = event.data.id;

            // Add Role if Missing
            if (staff.roles.find((s) => s.id === _roleID) == null) {
                this.gc.setComponentBusy(true);
                (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).addStaffRole(staff.pid, _roleID).subscribe(
                    (data) => { userResponseData = data; }
                    , (error) => { this.gc.setComponentBusy(false); this.onApiError(error); }
                    , () => {
                        this.gc.setComponentBusy(false);
                        if (userResponseData && Utils.isNullOrEmpty(userResponseData.error) && userResponseData.data) {
                            staff.roles.push(event.data);
                        }
                    });
            }
        }
    }

    removeStaff(pid) {
        this.loading = true;
        let userResponseData: any;

        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).removeStaff(pid).subscribe(
            (data) => { userResponseData = data; }
            , (error) => { this.loading = false; this.onApiError(error); }
            , () => {
                this.loading = false;
                if (userResponseData && Utils.isNullOrEmpty(userResponseData.error))
                    this.staff = this.staff.filter(s => { return s.pid !== pid; });
            });

    }

    addStaff() {
        // Add using email address
        this.adding = true;
        let userResponseData: any;
        if (!Utils.isNullOrEmpty(this.emailAddress)) {
            (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).addStaff(this.emailAddress).subscribe(
                r => userResponseData = r
                , (error) => { this.loading = false; this.onApiError(error); }
                , () => {
                    this.adding = false;
                    if (userResponseData && Utils.isNullOrEmpty(userResponseData.error) && userResponseData.data) {
                        this.staff.splice(0, 0, (new StaffModel()).castToMe(userResponseData.data));
                        this.emailAddress = '';
                        this.staff = [...this.staff];

                        this.showMessage('Staff Member has been added. Notification has been sent to the person.');
                    }
                });
        }
    }
}
