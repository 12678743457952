import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { GlobalComponent } from '../global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { escrow, escrowDeposit } from '../models';

import { EscrowService } from './escrow.service';

import { EscrowBaseComponent } from './escrow.base.component';
import { NewEscrowDepositDialog } from './dialog';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-escrow-deposit',
    templateUrl: './escrowDeposit.component.html'
})
export class EscrowDepositComponent extends EscrowBaseComponent {

    depositsOnPage: number = 10;
    depositsPageNumber: number = 1;

    deposits: escrowDeposit[] = null;

    constructor(ds: DataService, r: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('escrowDeposit', ds, r, g);
        this.gc.roleChanged.subscribe((_r) => {
            this.initMe();
            this.bindDeposits();
        });
        this.gc.escrowChanged.subscribe((_r) => {
            this.initMe();
            this.bindDeposits();
        });
    }

    get totalDeposit(): number {
        let _total: number = 0;
        if (this.deposits)
            this.deposits.forEach(d => { _total += d.amount; });
        else
            return 0;
    }

    bindDeposits() {
        const _id = this.currentEscrow ? this.currentEscrow.id : 0;
        if (_id === 0) {
            this.initMe();
        }

        if (this.currentEscrow.id !== this.escrowID || this.deposits == null || (this.currentEscrow.id === this.escrowID && this.currentEscrow.deposits == null)) {
            this.deposits = new Array<escrowDeposit>();
            let _response;
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getDeposits(this.escrowID);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { }
                    , () => {
                        if (_response && _response.data) {
                            this.setCurrentEscrow(Utils.castTo(_response.data, new escrow()));
                            this.deposits = _response.data.deposits.map((c) => { return Utils.castTo(c, new escrowDeposit()); });
                        }
                    });
            }
        } else if (this.currentEscrow.id === this.escrowID && this.currentEscrow.deposits)
            this.deposits = this.currentEscrow.deposits;
    }

    newDeposit() {
        // Show Dialog to add Deposit
        const dialogRef = this.dialog.open(NewEscrowDepositDialog, {
            data: {
                id: this.escrowID
                , roles: this.currentEscrow.settlementRoles
                , myRole: this.currentEscrow.myRole
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (this.deposits == null) this.deposits = [];
                if (this.deposits.length === 0)
                    this.deposits.push(Utils.castTo(result, new escrowDeposit()));
                else
                    this.deposits.splice(0, 0, Utils.castTo(result, new escrowDeposit()));
                this.showResourceMessage('Escrow.NewDepositRecorded');
            }
        });
    }

    verifyDeposit(deposit: escrowDeposit) {
        if (deposit.id > 0 && deposit.amountVerified > 0) {
            let _response;
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).verifyDeposit(this.escrowID, deposit);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { }
                    , () => {
                        if (_response) {
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.showError(_response.error);
                            else {
                                this.showResourceMessage('Escrow.DepositVerified');
                                this.deposits.forEach((d) => {
                                    if (d.id === deposit.id) {
                                        d.canCancel = d.canVerify = false;
                                        d.amountVerified = deposit.amountVerified;
                                    }
                                });
                                this.deposits = [...this.deposits];
                            }
                        }
                    });
            }
        }
    }

    cancel(id: number) {
        if (id > 0) {
            let _response;
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).cancelDeposit(this.escrowID, id);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { }
                    , () => {
                        if (_response) {
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.showError(_response.error);
                            else {
                                this.showResourceMessage('Escrow.DepositRemoved');
                                this.deposits = this.deposits.filter((d) => d.id !== id);
                                this.deposits = [...this.deposits];
                            }
                        }
                    });
            }
        }
    }
}
