<div [ngClass]="{'appContent' : showMedia}">
    <h2 *ngIf="showMedia">Property <span class="small">{{currentListing.address.StreetName}}</span></h2>
    <mat-form-field *ngIf="showHeader && listings && listings.length > 0">
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-card *ngIf="showMedia && media && media.galleryImages && media.galleryImages.length > 0">
        <app-listing-media #media [showHeader]="false" [disableAdd]="true"></app-listing-media>
    </mat-card>
    <mat-card>
        <app-property-data-value #propertyDataValues></app-property-data-value>
    </mat-card>
</div>