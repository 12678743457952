import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { GlobalComponent } from '../../global.component';
import { Router, Route } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ServiceProviderService } from '../../sp/serviceprovider.service';

import { RecordTimeStamp } from '../../models/base.model';
import { Utils } from '../utils';

export class AgentDetail extends RecordTimeStamp {
    pid: number = 0;
    name: string = '';
    imageURL: string = '';
    address: string;
    phone: string = '';
    company: string = '';
    constructor() {
        super();
        this.imageURL = this.address = this.phone = this.company = this.name = '';
        this.pid = 0;
    }
}

@Component({
    selector: 'app-agent-dialog',
    templateUrl: './agentDetail.dialog.html'
})
export class AgentDialog extends BaseComponent {

    agent: AgentDetail = new AgentDetail();
    loading: boolean = false;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent
        , public dialogRef: MatDialogRef<AgentDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('agentDialog', r, gc);
        this.loading = true;
        if (this.data) {
            let userResponseData;

            if (data.id && data.id > 0) {
                (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getAgentDetail(data.id).subscribe((_r) => userResponseData = _r
                    , (error) => { this.onApiError(error); }
                    , () => {
                        this.loading = false;

                        if (userResponseData && userResponseData.data) {
                            this.agent = Utils.castTo(userResponseData.data, (new AgentDetail()));
                        }
                    }
                );
            }
        }
    }

    close() {
        this.dialogRef.close();
    }
}
