import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, AfterViewInit, Output, OnDestroy } from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';
import { ListingBaseComponent } from '../listing.base.component';
import { PropertyDataValueComponent } from '../controls/propertyDataValue.component';
import { GlobalComponent } from '../../global.component';
import { ListingMediaComponent } from './listingMedia.component';
import { ListingService } from '../listing.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-listing-characteristitcs',
    templateUrl: './listingCharacteristics.component.html'
})
export class ListingCharacteristicsComponent extends ListingBaseComponent implements OnInit, AfterViewInit, OnDestroy{

    @Input() showMedia: boolean = true;
    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    @ViewChild('propertyDataValues') propertyDataValues: PropertyDataValueComponent;
    @ViewChild('media') media: ListingMediaComponent;
    private _lcs: Subscription;
    constructor(dataservice: DataService, r: Router, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingCharacteristitcs', dataservice, r, gc);
        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindCharacteristics();
        });
    }

    ngOnInit() {
        if (this.showMedia) {
            const _id = parseInt(sessionStorage.getItem(this.handover.listingID));
            if (!isNaN(_id) && _id > 0) {
                this.setCharacteristics(_id);
            }
        }
    }

    ngAfterViewInit() {
        this.bindCharacteristics();
    }
    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    private bindCharacteristics() {
        // if (this.listingID > 0) {
        //    if (this.propertyDataValues) this.propertyDataValues.setListingID(this.listingID);
        //    if (this.media) this.media.setListingID(this.listingID);
        // }
        this.setCharacteristics(this.listingID);
    }

    private setCharacteristics(id: number) {

        if (id != null && id > 0 && (this.currentListing == null || this.currentListing.id !== id)) {
            let _response;
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getCharacteristics(id).subscribe(
                (data) => _response = data
                , (error) => {
                    this.onApiError(error);
                }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            const _listingPresent = this.currentListing && this.currentListing.id > 0;

                            if (this.noParent && !_listingPresent) {
                                this.setCurrentListing(_response.data);
                            }

                            if (_response.data.characteristics)
                                this.currentListing.characteristics = _response.data.characteristics;
                        }
                }
            );
        }
    }
}
