import { Component, OnInit, Inject, ViewChild, OnDestroy, EventEmitter} from '@angular/core';
declare var $: any;
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalComponent } from './global.component';
import { UIBusyService } from './util/service/ui.busy.service';
import { appEnvironment } from '../environments/environment';
import { AssetService } from './common/service';
import { StaticAppComponent } from './static.app.content.component';
import { DataService } from './util/APICaller.component';

import { approutes } from '../app.routing';
import { HomeComponent } from './home.component';
import { assetStatus, MessageNotificationType, BaseResponse, cookiePolicy, divCookiePolicy } from './models';
import { BreakpointObserver, Breakpoints  } from '@angular/cdk/layout';
import { MessageNotification } from './models/messageNotification.model';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatSnackBar } from '@angular/material/snack-bar';
import { appSnackBarDisplay, appSnackBarDisplayTop, AppURLs } from './models/constants';
import { HttpClient } from '@angular/common/http';

import * as defaultLangTags from '../assets/i18n/en.json';
import { TagLoadService } from './util/service/tagLoad';

import { Utils } from './common/utils';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
    , providers: [TagLoadService]
})
export class AppComponent implements OnInit, OnDestroy {

    pageTitle: string = ''; pageHelpContent: string = '';
    nextTopics: any[] = [];
    previousTopics: any[] = [];
    title = appEnvironment.appBRAND;
    isRTL: boolean;
    pageAssetID: number = 0;
    public readonly helpAssetLabel = '<i class="fa fa-superpowers" title="Get Help about current page"></i>';
    @ViewChild('helpDrawer') helpDrawer: MatDrawer;

    private defaultMetaData;
    private metaDataList;

    private showAppError: boolean = false;
    private _ls: Subscription;
    private _ms: Subscription;
    private _es: Subscription;
    private _bs: Subscription;
    private _evs: Subscription;

    private _save: Subscription;

    constructor(private r: Router, @Inject(GlobalComponent) private gc: GlobalComponent
        , private dataservice: DataService
        , private busyService: UIBusyService
        , breakpointObserver: BreakpointObserver
        , private _snackBar: MatSnackBar = null
        , private httpClient: HttpClient
        , private loadTagService: TagLoadService
        , private ar: ActivatedRoute
        , private titleService: Title
        , private metaService: Meta
    ) {

        const _l = localStorage.getItem('lang');
        if (_l == null || _l.toString().trim() === '') {
            localStorage.setItem('lang', 'en');
            this.gc.currentLang = 'en';
        } else {
            this.gc.currentLang = _l;
        }

        this.gc.restoreuser();
        this.gc.langTags = defaultLangTags;
        this.loadTagService.init('', this.gc, httpClient);
        this.setMetaData();

        // new WOW().init();

        // Set LTR/RTL for the App
        const rtlDetect = require('rtl-detect');
        this.isRTL = rtlDetect.isRtlLang(localStorage.getItem('isoLanguageCode'));
        // this.contentDirection = this._global.isRTL ? 'rtl' : '';
        this.loadStyle();
        //

        if (this.isRTL) $('#divDir').attr('dir', 'rtl');

        this._es = this.gc.onError.subscribe((httpError) => {
            if (httpError.status !== 0) {
                this.renderBusy(false);
                this.busyService.spin$.next(false);
                // Global Component Handling returned Status Code
            }
        });

        this._bs = this.gc.nowBusy.subscribe((value) => {
            if (value.busy === true) {
                if (value.modal === true)
                    this.busyService.spin$.next(true);
                else
                    this.renderBusy(true);
            } else {
                if (value.modal === true)
                    this.busyService.spin$.next(false);
                else
                    this.renderBusy(false);
            }
        });

        this._evs = this.r.events.pipe().subscribe((e) => {
            if (e instanceof NavigationStart) {
                const x = e.url.split('/');
                if (x && x.length > 2) {
                    const _mod = x[1];
                    this.loadTagService.init(Utils.toTitleCase(_mod), this.gc, httpClient);
                }
            } else  if (e instanceof NavigationEnd) {
                this.hideHelpButton();
                // API Call in NavBar will get and set the asset ID.
                this.autoHideHelp();
                this.hideSave();
                window.scrollTo(0, 0); // Scroll to Top
                // this.pageTitle = this.getPageTitle(this.r.routerState, this.r.routerState.root);
                this.gc.setBusy(false);
                resize(this.gc.mobileScreen);

                // this.ar.data.subscribe((data) => {
                //    if (data && data['meta']) {
                //        this.pageTitle = data['meta']['description'];
                //    }
                // });

                this.setMetaTags();
            }
        });

        breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small
        ]).subscribe((result) => {
            this.gc.mobileScreen = result.matches;
        });

        // Client IP to be displayed on UI.
        this.dataservice.getIPAddress();

        this.loadStaticRoutes();

        this._ms = this.gc.onMessageNotify.subscribe((o: any) => {
            if (o != null && typeof o === 'object') {
                if (o.message && o.message.key) {
                    this.OnMessageReceived({ message: this.gc.geti18nTag(o.message.key), messageType: o.messageType, data: o.data });
                } else {
                    this.OnMessageReceived(o);
                }
            } else if (o != null && o.toString().trim() !== '') {
                this.OnMessageReceived(o);
            }
        });

        this._ls = this.gc.loginChanged.subscribe((v) => {
            resize(this.gc.mobileScreen);
        });

         this._save = this.gc.saveVisibility.subscribe((v) => {
            if (v === true)
                this.showSave();
            else
                this.hideSave();
         });
    }

    ngOnDestroy() {
        if (this._ls) this._ls.unsubscribe();
        if (this._ms) this._ms.unsubscribe();
        if (this._es) this._es.unsubscribe();
        if (this._bs) this._bs.unsubscribe();
        if (this._evs) this._evs.unsubscribe();
        if (this._save) this._save.unsubscribe();
    }

    ngOnInit() {
        resize(this.gc.mobileScreen);

         if (appEnvironment.production) {
            if (location.protocol === 'http:') {
                window.location.href = location.href.replace('http', 'https');
            }
         }

    }

    getPageTitle(state, parent) {
        let data = '';
        if (parent && parent.snapshot.data && parent.snapshot.data.description) {
            data = parent.snapshot.data.description;
        }

        if (state && parent) {
            return this.getPageTitle(state, state.firstChild(parent));
        }
        return data;
    }

    closeMessage() {
        $('#appMsgDivError').html('');
        $('#appMsgDivMsg').html('');
        $('#appMsgDiv').animate({ width: 'auto' }).fadeOut(2500).addClass('hidden'); this.showAppError = false;
    }

    closeHelp() {
        this.helpDrawer.close();
        this.toggleHelpButton();
    }

    saveClicked() {
        this.gc.saveClicked.emit(true);
    }

    showHelp() {
        this.nextTopics = []; this.previousTopics = [];
        if (this.gc.currentPageAssetID > 0) {
            let _resp: BaseResponse;
            (new AssetService(this.dataservice, this.r, '')).getAssetHelpContent(this.gc.currentPageAssetID).subscribe((data) => { _resp = data; }, (error) => { },
                () => {
                    if (_resp != null)
                        if (_resp.data != null && _resp.data.content != null) {
                            this.pageHelpContent = _resp.data.content as string;
                            if (_resp.data.next)
                                this.nextTopics = _resp.data.next;
                            if (_resp.data.previous)
                                this.previousTopics = _resp.data.previous;
                            this.helpDrawer.open();
                            this.hideHelpButton();
                        } else {
                            this.show({ messageType: MessageNotificationType.Message, message: 'We are working on to provide you help about this page !!' } as MessageNotification);
                        }
                });
        }
    }

    public showSnackBar(sMessage: string = '', oData: any = null) {
        if (this._snackBar)
            this._snackBar.open(sMessage == null || sMessage.toString().trim() === '' ? 'Changes saved.' : sMessage, '', oData != null ? oData : appSnackBarDisplay);
    }

    gotoAssetPage(iAssetID: number) {
        if (!this.gc.stealthMode && iAssetID > 0) {
            sessionStorage.setItem('ad', JSON.stringify(JSON.parse('{ "showAsAssetPage" : true, "startingCategory" : { "id" : ' + iAssetID.toString() + '}}')));
            this.r.navigate([AppURLs.assetPage]);
        } else
            this.showSnackBar('We are working on to provide you help about this topic !!');
    }

    private showSave() {
        $('#pageContextSave').removeClass('hide');
    }

    private hideSave() {
        $('#pageContextSave').addClass('hide');
    }

    private autoHideHelp() {
        setTimeout(() => {
            this.toggleHelpButton();
            // this.autoHideHelp();
        }, appEnvironment.delayedNGInit);
    }

    private toggleHelpButton() {
        if (this.gc.currentPageAssetID > 0)
            $('#pageContextHelp').removeClass('hide');
        else if (!$('#pageContextHelp').hasClass('hide'))
            $('#pageContextHelp').addClass('hide');
    }

    private hideHelpButton() {
        $('#pageContextHelp').addClass('hide');
    }

    private OnMessageReceived(message: MessageNotification) {
        if (message.messageType === MessageNotificationType.ClearMessage) { this.closeMessage(); return; }
        if (message.messageType === MessageNotificationType.None || message.message === '' || message.message == null) { this.closeMessage(); return; }
        this.show(message);
    }

    private show(msg: MessageNotification) {
        this.showAppError = true;

        if (msg.message == null || msg.message.toString().trim() === '') return;

        if (msg.messageType === MessageNotificationType.Error) {
            $('#appMsgDivError').html(msg.message);
            $('#appDivError').css('display', 'block');
            if ($('#appMsgDivMsg').html().trim() === '')
                $('#appDivMsg').css('display', 'none');
        } else {
            this.showSnackBar(msg.message, msg.data ? msg.data : appSnackBarDisplayTop);
            return;
            //    $('#appMsgDivMsg').html(msg.Message);
            //    $('#appDivMsg').css('display', 'block');
            //    if ($('#appMsgDivError').html().trim() === '')
            //        $('#appDivError').css('display', 'none');
        }

        // logic to change color
        const _color = msg.messageType === MessageNotificationType.Error ? 'orange' : 'lightgreen' ;
        if ($('#appMsgDiv')) {
            if (msg.messageType === MessageNotificationType.Error) {
                $('#appMsgDivError').css('background-color', 'orange');
            } else {
                $('#appMsgDivMsg').css('background-color', 'lightgreen');
            }

            $('#appMsgDiv').removeClass('hidden').fadeIn(1500).animate({ width: '100%' }, { queue: false, duration: 1500 }).animate({ fontSize: '24px' }, 1500);

            if (msg.messageType !== MessageNotificationType.Error) {
                setTimeout(() => {
                    $('#appMsgDiv').animate({ width: 'auto' }).fadeOut(2500);
                    $('#appMsgDivError').html('');
                    $('#appMsgDivMsg').html('');
                }, 10000);
            }
        } else
            alert(msg.message);
    }

    private loadStaticRoutes() {
        let _assets: any;
        const _a = approutes;
        (new AssetService(this.dataservice, this.r, '')).getStaticAssets().subscribe((data) => { _assets = data; }, (error) => { },
            () => {
                let _addedRoute: boolean = false;
                if (_assets && _assets.data && Array.isArray(_assets.data) && _assets.data.length > 0) {
                    _assets.data.forEach((a) => {
                        if (a.url && !a.url.toString().startsWith('http') && a.status === assetStatus.Publish) {
                            if (a.url && a.url.trim() !== '' && approutes.find((v) => v.path === a.url) == null) {
                                _addedRoute = true;
                                approutes.push(
                                    {
                                        path: a.url
                                        , component: StaticAppComponent
                                        , data: {
                                            meta: {
                                                id: a.id
                                                , title: a.title
                                                , description: a.description
                                            }
                                        }
                                    }
                                );
                            }
                        }
                    });
                }
                approutes.push({
                    path: '**',
                    component: HomeComponent, data: {
                        meta: {
                            title: '',
                            description: ''
                        }
                    }
                });
                this.r.resetConfig(approutes);
            });
    }
    private loadStyle() {
        const _css = document.getElementById('dynamicRTLCSS');
        if (_css) {
            if (this.isRTL)
                _css.setAttribute('href', '/assets/css/bootstrap-rtl.css');
            else
                _css.removeAttribute('href');
        }
    }

    private renderBusy(value: boolean) {
        if (typeof value === 'boolean') {
            let _de = document.getElementById('divMainBusy');
            if (_de != null) {
                if (Array.isArray(_de)) _de = _de[0];

                if (value === true)
                    _de.style.display = '';
                else
                    _de.style.display = 'none';
            }
        }
    }

    private setMetaData(sLanguage: string = null) {

        if (sLanguage == null || sLanguage.trim() === '')
            sLanguage = this.gc.currentLang;
        if (sLanguage == null || sLanguage.trim() === '')
            sLanguage = 'en';

        if (this.defaultMetaData == null)
            this.defaultMetaData = {};

        if (this.defaultMetaData['title'] == null)
            this.defaultMetaData['title'] = 'IAMOREA';
        if (this.defaultMetaData['description'] == null)
            this.defaultMetaData['description'] = 'IAMOREA';

        const _metaurl = '/assets/i18n/' + sLanguage + '.route.meta.json';
        let _data;
        this.httpClient.get(_metaurl).subscribe(
            (data) => { _data = data; }
            , (error) => { }
            , () => {
                if (_data) {
                    this.metaDataList = _data;
                    if (this.metaDataList['default'] != null)
                        this.defaultMetaData = this.metaDataList['default'];
                }
            }
        );
    }

    private setMetaTags() {

        if (this.metaDataList == null) {
            setTimeout(() => { this.setMetaTags(); }, 500);
            return;
        }

        const _url = window.location.pathname.substring(1);
        const _splitPath = _url.toLowerCase().split('/');
        let _idx = 0;
        let _meta;
        let _metaO;
        while (true) {
            let _c = _splitPath[_idx];
            if (_c != null) {

                if (_idx === 0 && this.metaDataList[_c] != null && this.metaDataList[_c]['lookup'] != null) {
                    // TO Handle when routing file is common for differnt starting routes.
                    _c = this.metaDataList[_c]['lookup'];

                }
                if (_metaO == null)
                    _metaO = this.metaDataList[_c];
                else if (_metaO[_c] != null) {
                    _metaO = _metaO[_c];
                }
                if (_metaO != null && _metaO['title'] != null) {
                    _meta = _metaO;
                } else
                    break;
            } else
                break;
            _idx++;
        }

        if (_meta == null)
            _meta = this.defaultMetaData;

        const data = _meta;
        if (data != null) {
            if (data.title)
                this.titleService.setTitle(data.title);
            else if (data.meta?.title)
                this.titleService.setTitle(data.meta.title);

            if (data.descrption) {
                this.metaService.updateTag({ name: 'description', content: data.descrption });
            } else if (data.meta?.descrption) {
                this.metaService.updateTag({ name: 'description', content: data.meta.descrption });
            } else {
                // tslint:disable-next-line: quotemark
                this.metaService.removeTag("name='description'");
            }

            if (data.robots) {
                this.metaService.updateTag({ name: 'robots', content: data.robots });
            } else if (data.meta?.robots) {
                this.metaService.updateTag({ name: 'robots', content: data.meta.robots });
            } else {
                this.metaService.updateTag({ name: 'robots', content: 'follow,index' });
            }

            if (data.ogUrl) {
                this.metaService.updateTag({ property: 'og:url', content: data.ogUrl });
            } else if (data.meta?.ogUrl) {
                this.metaService.updateTag({ property: 'og:url', content: data.meta.ogUrl });
            } else {
                this.metaService.updateTag({ property: 'og:url', content: this.r.url });
            }

            if (data.ogTitle) {
                this.metaService.updateTag({ property: 'og:title', content: data.ogTitle });
            } else if (data.meta?.ogTitle) {
                this.metaService.updateTag({ property: 'og:title', content: data.meta.ogTitle });
            } else {
                // tslint:disable-next-line: quotemark
                this.metaService.removeTag("property='og:title'");
            }

            if (data.ogDescription) {
                this.metaService.updateTag({ property: 'og:description', content: data.ogDescription });
            } else if (data.meta?.ogDescription) {
                this.metaService.updateTag({ property: 'og:description', content: data.meta.ogDescription });
            } else {
                // tslint:disable-next-line: quotemark
                this.metaService.removeTag("property='og:description'");
            }

            if (data.ogImage) {
                this.metaService.updateTag({ property: 'og:image', content: data.ogImage });
            } else if (data.meta?.ogImage) {
                this.metaService.updateTag({ property: 'og:image', content: data.meta.ogImage });
            } else {
                // tslint:disable-next-line: quotemark
                this.metaService.removeTag("property='og:image'");
            }
        }
    }
}

let resizeTimer;
window.addEventListener('resize', () => {
    document.body.classList.add('resize-animation-stopper');
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
        document.body.classList.remove('resize-animation-stopper');
        resize();
    }, 400);
});

function resize(bMobile: boolean = false) {
    const _header = $('#mainNav');

    const _divRouter = document.getElementById('divRouter');

    // let _divMainBusy = document.getElementById('divMainBusy');
    // if (_divMainBusy != null)
    //    if (Array.isArray(_divMainBusy)) _divMainBusy = _divMainBusy[0];

    let _appMsgDiv = document.getElementById('appMsgDiv');
    if (_appMsgDiv != null)
        if (Array.isArray(_appMsgDiv)) _appMsgDiv = _appMsgDiv[0];
    if (_divRouter != null) {
        const _hH = _header.position().top + _header.innerHeight() ;

        // _divRouter.style.position = 'fixed';
        _divRouter.style.top = (_hH).toString()  + 'px';

        // if (_divMainBusy != null) {
        //    // _divMainBusy.style.position = 'relative';
        //    _divMainBusy.style.top = _hH.toString() + 'px';
        // }

        if (_appMsgDiv != null) {
           // _appMsgDiv.style.position = 'relative';
            _appMsgDiv.style.top = _hH.toString() + 'px';
        }

        resetFooter(bMobile);
    }
}

function resetFooter(bMobile: boolean = false) {

    const _cookiePolicy = document.getElementById(divCookiePolicy);
    const _footer = document.getElementById('appFooter');
    if (_footer != null) {
        if (bMobile || window.location.pathname === '' || window.location.pathname === '/') {
            _footer.style.removeProperty('position');
            _footer.style.removeProperty('top');
        } else {
            _footer.style.position = 'fixed';
            _footer.style.top = (window.innerHeight - 130) + 'px';
        }
        _footer.style.width = '100%';

        if (_cookiePolicy != null) {
            const _cp = localStorage.getItem(cookiePolicy);
            _cookiePolicy.style.top = (window.innerHeight - 140) + 'px';
            if (_cp === '1') {
                _cookiePolicy.style.display = 'none';
            } else
                _cookiePolicy.style.display = 'block';
        }

    }

    const _hh = window.innerHeight - 220;
    const _divsidenav = document.getElementById('sidenav');
    _divsidenav .style.height = (_hh - 30).toString() + 'px';

    const _appTop = document.getElementById('appTop');
    _appTop.style.height = _hh.toString() + 'px';

    const _divpageHelpContent = document.getElementById('pageHelpContent');
    _divpageHelpContent.style.maxHeight = (_hh - 60).toString() + 'px';
    _divpageHelpContent.style.height = (_hh - 20).toString() + 'px';

    const _divRouter = document.getElementById('divRouter');
    if (bMobile || window.location.pathname === '' || window.location.pathname === '/') {
        _divRouter.style.removeProperty('max-height');
        _divRouter.style.removeProperty('position');
        _divRouter.style.removeProperty('top');
        return;
    } else {
        _divRouter.style.maxHeight = (_hh).toString() + 'px';
        _divRouter.parentElement.style.maxHeight = (_hh).toString() + 'px';
    }
}

