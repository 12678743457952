import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { GlobalComponent } from '../global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as cloneDeep from 'lodash/cloneDeep';
import { escrow, escrowDisbursement } from '../models';

import { EscrowService } from './escrow.service';

import { EscrowBaseComponent } from './escrow.base.component';
import { NewDisbursementDialog } from './dialog/newDisbursement.dialog';
import { Utils } from '../common/utils';


@Component({
    selector: 'app-escrow-disbursement',
    templateUrl: './escrowDisbursements.component.html'
})
export class EscrowDisrbursementComponent extends EscrowBaseComponent {
    @ViewChild('disbursementsTable') table;
    disbursements: escrowDisbursement[] = null;
    workOrderID: number = 0;

    disbursementOnPage: number = 10;
    disbursementPageNumber: number = 1;

    constructor(ds: DataService, r: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog) {
        super('escrowDisbursement', ds, r, g);

        this.gc.roleChanged.subscribe((_r) => {
            this.initMe();
            this.bindDisbursements();
        });
        this.gc.escrowChanged.subscribe((_r) => {
            this.initMe();
            this.bindDisbursements();
        });
    }

    get totalDisbursement(): number {
        let _total: number = 0;
        if (this.disbursements)
            this.disbursements.forEach(d => { _total += d.amount; });
        else
            return 0;
    }

    bindDisbursements() {

        const _id = this.currentEscrow ? this.currentEscrow.id : 0;
        if (_id === 0) {
            this.initMe();
        }

        if (this.currentEscrow.id !== this.escrowID || this.disbursements == null || (this.currentEscrow.id === this.escrowID && this.currentEscrow.disbursements == null)) {
            this.disbursements = new Array<escrowDisbursement>();
            this.workOrderID = 0;
            let _response;
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getDisbursements(this.escrowID);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { }
                    , () => {
                        if (_response?.data != null) {
                            if (this.currentEscrow == null || this.currentEscrow.id !== this.escrowID)
                                this.setCurrentEscrow(Utils.castTo(_response.data, new escrow()));

                            if (_response.data.disbursements)
                                this.disbursements = _response.data.disbursements.map((c) => { return Utils.castTo(c, new escrowDisbursement()); });
                            if (this.currentEscrow && this.currentEscrow.myWorkOrders && this.currentEscrow.myWorkOrders.length === 1)
                                this.workOrderID = this.currentEscrow.myWorkOrders[0].id;
                        }
                    });
            }
        } else if (this.currentEscrow.id === this.escrowID && this.currentEscrow.disbursements)
            this.disbursements = this.currentEscrow.disbursements.map((c) => { return Utils.castTo(c, new escrowDisbursement()); });
    }

    addDisbursement() {
        // Show Dialog
        const dialogRef = this.dialog.open(NewDisbursementDialog, {
            data: {
                id: this.escrowID
                , amountAvailable: this.currentEscrow.amountAvailableForDisbursement
                , header: 'Disburse Funds'
                , roles: this.currentEscrow.settlementRoles
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.disbursements.splice(0, 0, Utils.castTo(result, new escrowDisbursement()));

                if (result.amount && result.amount > 0)
                    this.currentEscrow.amountAvailableForDisbursement = this.currentEscrow.amountAvailableForDisbursement - result.amount;

                this.showMessage('Disbursement information recorded, if the money has not been sent will be sent soon as per escrow agreement.');
            }
        });
    }

}
