import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input , Output, ViewContainerRef, ComponentFactoryResolver, NgZone, OnDestroy} from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListingService } from '../listing.service';

import { ListingBaseComponent } from '../listing.base.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { appEnvironment } from 'src/environments/environment';
import { BaseResponse } from 'src/app/models';
import { GlobalComponent } from '../../global.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../common/utils';
export class PropertyValue {
    configurationID: number;
    propertyID: number;
    counter: number;
    value: any;
}

@Component({
    selector: 'app-property-data-value',
    templateUrl: './propertyDataValue.component.html'
})
export class PropertyDataValueComponent extends ListingBaseComponent implements OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;
    @Input() @Output() compareListings: boolean = false;
    @Input() @Output() countNumber: number = 0;

    html: SafeHtml = '';
    propertyDirty: boolean = false;
    saving: boolean = false;

    private _pto: any;
    private _bindingValues: boolean = false;
    private pendingUpdates: PropertyValue[] = new Array<PropertyValue>();
    private busyPendingUpdates: PropertyValue[] = new Array<PropertyValue>();
    private _lcs: Subscription;
    constructor(dataservice: DataService, r: Router, private dialog: MatDialog, private sanitizer: DomSanitizer, @Inject(GlobalComponent) gc: GlobalComponent
        , private zone: NgZone) {
        super('propertyDataValue', dataservice, r, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindValues();
        });

        window['angularPropertyDataUpdateValue'] = {
            zone: this.zone
            , componentFn: (sValueControlID) => this.updateValue(sValueControlID)
            // ,component: this
        };

        window['angularPropertyDataRefresh'] = {
            zone: this.zone
            , componentFn: (sValueControlID) => this.refresh(sValueControlID)
            // ,component: this
        };

        window['angularPropertyShowRefresh'] = {
            zone: this.zone
            , componentFn: (sValueControlID) => this.showRefreshLink(sValueControlID)
            // ,component: this
        };
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    showRefreshLink(sControlID) {
        if (sControlID) {
            let _id = '';
            if (sControlID && sControlID.id)
                _id = sControlID.id.replace('txt', 'lnkRefresh');
            else
                _id = sControlID.replace('txt', 'lnkRefresh');

            let _lnk = document.getElementById(_id);
            if (_lnk) {
                _lnk.style.removeProperty('display');
                _id = _id.replace('lnkRefresh', 'lnkExpand');
                _lnk = document.getElementById(_id);
                if (_lnk) {
                    _lnk.style.setProperty('display', 'none');
                }
            }
        }
    }

    private bindValues() {
        if (this.listingID === 0 || this._bindingValues) return;

        let _response: BaseResponse;
        this._bindingValues = this.componentBusy = true;

        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).preparePropertyDataValues(this.listingID).subscribe(
            (data) => { this._bindingValues = this.componentBusy = false; _response = data; }
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (!Utils.isNullOrEmpty(_response.data)) {
                        this.html = _response.data.toString();
                        this.bindPropEvents();

                        this.readonly = this.currentListing == null || !this.currentListing.isEditable('characteristics');
                    }
            }
        );
    }

    private refresh(sValueControlID) {

        let _id = '';
        if (sValueControlID && sValueControlID.id)
            _id = sValueControlID.id;
        else
            _id = sValueControlID;

        const _ID = _id.split('_');
        let _configurationID = 0; let _propertyID: number = 0; let _counter: number = 0;
        if (_ID.length > 1)
            _configurationID = parseInt(_ID[1]);
        if (_ID.length > 2)
            _propertyID = parseInt(_ID[2]);
        if (_ID.length > 3)
            _counter = parseInt(_ID[3]);

        let _value = 0;

        if (!Utils.isNullOrEmpty(_id)) {
            const _ctrl = document.getElementById(_id.replace('lnkRefresh', 'txt'));
            if (_ctrl != null && this.isTextBox(_ctrl))
                _value = parseInt((_ctrl as HTMLInputElement).value);
        }

        if (isNaN(_value)) _value = 0;

        this.saving = this.pendingUpdates.length > 0;

        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).setMultiplePropertyDataRecord(this.currentListing.id, _configurationID, _value, this.pendingUpdates).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                this.saving = false;
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else if (!Utils.isNullOrEmpty(_response.data)) {
                        this.html = _response.data;
                        this.bindPropEvents();
                    }
            }
        );
    }

    private isListBox(element) {
        const tagName = element.tagName.toLowerCase();
        if (tagName === 'select') return true;
    }
    private isTextBox(element) {
        const tagName = element.tagName.toLowerCase();
        if (tagName === 'textarea') return true;
        if (tagName !== 'input') return false;
        const _att = element.getAttribute('type');
        const type = _att == null ? 'text' : _att.toLowerCase();
        // if any of these input types is not supported by a browser, it will behave as input type text.
        const inputTypes = ['text', 'password', 'number', 'email', 'tel', 'url', 'search', 'date', 'datetime', 'datetime-local', 'time', 'month', 'week'];
        return inputTypes.indexOf(type) >= 0;
    }

    private bindPropEvents() {
        setTimeout(() => {
            let _items = document.getElementsByClassName('app-prop-data');
            Array.prototype.forEach.call(_items, (_item) => {
                const _attr = document.createAttribute('onchange');
                _attr.value = 'updatePropertyDataValue(' + _item.id + ');';
                _item.setAttributeNode(_attr);
                this.enforceReadonly(_item);
            });

            _items = document.getElementsByClassName('app-prop-data-multiple');
            Array.prototype.forEach.call(_items, (_item) => {
                const _attr = document.createAttribute('onchange');
                _attr.value = 'showRefreshPropertyData(' + _item.id + ');';
                _item.setAttributeNode(_attr);
                this.enforceReadonly(_item);
            });

            _items = document.getElementsByClassName('app-prop-data-refresh');
            Array.prototype.forEach.call(_items, (_item) => {
                const _attr = document.createAttribute('onclick');
                _attr.value = 'refreshPropertyDataValue(' + _item.id + ');';
                _item.setAttributeNode(_attr);
                this.enforceReadonly(_item);
            });

            _items = document.getElementsByClassName('app-prop-data-int-value');
            Array.prototype.forEach.call(_items, (_item) => {
                const _attr = document.createAttribute('onkeydown');
                _attr.value = 'return isOnlyNumberKey(event);';
                _item.setAttributeNode(_attr);
                this.enforceReadonly(_item);
            });
        }, appEnvironment.delayedNGInit);
    }

    private enforceReadonly(oItem: any) {
        if (this.readonly && oItem) {
            const _attr = document.createAttribute('disabled');
            oItem.setAttributeNode(_attr);
        }
    }

    private updateValue(sValueControlID) {

        let _id = '';
        if (sValueControlID && sValueControlID.id)
            _id = sValueControlID.id;
        else
            _id = sValueControlID;

        const _ID = _id.split('_');
        let _configurationID = 0; let _propertyID: number = 0; let _counter: number = 0;
        if (_ID.length > 1)
            _configurationID = parseInt(_ID[1]);
        if (_ID.length > 2)
            _propertyID = parseInt(_ID[2]);
        if (_ID.length > 3)
            _counter = parseInt(_ID[3]);

        let _value: any;

        if (!Utils.isNullOrEmpty(_id)) {
            const _ctrl = document.getElementById(_id);
            if (_ctrl != null)
                if (this.isTextBox(_ctrl))
                    _value = (_ctrl as HTMLInputElement).value;
                else if (this.isListBox(_ctrl))
                    _value = (_ctrl as HTMLInputElement).value;
        }

        const _newValue = new PropertyValue();
        _newValue.configurationID = _configurationID;
        _newValue.propertyID = _propertyID;
        _newValue.counter = _counter;
        _newValue.value = _value;

        if (this.saving) {
            _value = this.busyPendingUpdates.find((u) => u.configurationID === _configurationID && u.propertyID === _propertyID && u.counter === _counter );
            if (_value)
                _value.value = _value;
            else {
                this.busyPendingUpdates.push(_newValue);
            }
        } else {
            _value = this.pendingUpdates.find((u) => u.configurationID === _configurationID && u.propertyID === _propertyID && u.counter === _counter );
            if (_value)
                _value.value = _value;
            else {
                this.pendingUpdates.push(_newValue);
            }
            this.onPropertyChange();
        }
    }

    private onPropertyChange() {
        if (this.propertyDirty === false) {
            this.propertyDirty = true;
            this.propertyTriggerSave();
        }
    }

    private propertyTriggerSave() {
        if (this._pto && !this.saving) clearTimeout(this._pto);
        this._pto = setTimeout(() => { this.savePropertyData(); }, appEnvironment.autoSaveTime);
    }

    private savePropertyData() {
        if (this.saving) { this.propertyTriggerSave(); return; }

        this.saving = true;

        // Merge the Arrays;
        if (this.busyPendingUpdates) {
            this.busyPendingUpdates.forEach((_v) => { this.pendingUpdates.push(_v); });
            this.busyPendingUpdates = new Array<PropertyValue>();
        }

        let _response;
        (new ListingService(this.dataservice, this.route, this.myResourceCategory)).savePropertyDataUpdates(this.currentListing.id, this.pendingUpdates).subscribe(
            (data) => _response = data
            , (error) => {
                this.saving = false;
                this.onApiError(error);
            }
            , () => {
                // Reset Pening Updates
                this.pendingUpdates = new Array<PropertyValue>();
                this.saving = false;
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error)) {
                        this.showError(_response.error);
                        this.propertyTriggerSave(); // If User Corrects the data , Set system to trigger Save again.
                    } else {
                        this.propertyDirty = false;
                        clearTimeout(this._pto);
                    }
            }
        );
    }
}
