import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'blankOnNullOrEmpty' })
export class BlankOnNullOrEmptyPipe implements PipeTransform {
    constructor() { }
    transform(value: string, alternate: string) {
        if (value && typeof value === 'string' && value.trim() !== '')
            return value.trim();
        else if (alternate && typeof alternate === 'string' && alternate.trim() !== '')
            return alternate;
        else
            return '';
    }
}

@Pipe({ name: 'isNullOrEmpty' })
export class IsNullOrEmptyPipe implements PipeTransform {
    constructor() { }
    transform(value: string) {
        if (value && typeof value === 'string' && value.trim() !== '')
            return false;
        else
            return true;
    }
}

@Pipe({ name: 'isNotNullOrEmpty' })
export class IsNotNullOrEmptyPipe implements PipeTransform {
    constructor() { }
    transform(value: string) {
        if (value && typeof value === 'string' && value.trim() !== '')
            return true;
        else
            return false;
    }
}
