import { Component, Input, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';
import { EditHTMLDialog } from './dialogs';
import { AssetService } from './service';
import { Router } from '@angular/router';
import { navIA } from '../models/constants';
import { Utils } from './utils';

@Component({
    selector: 'app-page-asset',
    template: `
    <div>
    <div *ngIf='this.canEditContent' style='background-color: aliceblue;border: 1px solid slategrey;height: 15px;' title='Edit Content, Asset ID - {{assetID}}'><i class='fa fa-edit float-right link' (click)='editAssetContent()'></i></div>
    <div hrefToRouterLink [innerHTML]='assetContent | safeHtml' id='asset{{assetID}}'></div>
    </div>
`
})

export class AssetContentComponent extends BaseComponent implements OnInit {

    // TARGETTED for retirement - Use STATIC APP COMPONENT

    @Input() assetID: number = 0;
    assetContent: string = '';
    canEditContent: boolean = false;

    constructor(private ds: DataService, private rs: Router, private dialog: MatDialog, @Inject(GlobalComponent) g: GlobalComponent) { super('', rs, g); }

    ngOnInit() {
        this.getContent();
        this.canEditContent = document.getElementById(navIA) != null && this.gc.editContent();
    }

    editAssetContent() {
        const dialogRef = this.dialog.open(EditHTMLDialog, {
            data: {
                html: this.assetContent
                , canEdit: true
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onSaveDoc(result);
            }
        });
    }

    private getContent() {
        let _response;
        if (this.assetID > 0) {
            (new AssetService(this.ds, this.rs, this.myResourceCategory)).getAssetContent(this.assetID).subscribe(
                (data) => { _response = data; }
                , (error) => {  }
                , () => {
                    if (_response && _response.data)
                        this.assetContent = _response.data.content ?? _response.data.toString();
                });
        } else
            this.assetContent = '';
    }

    private onSaveDoc(sContent: string) {
        let _response;
        (new AssetService(this.ds, this.rs, this.myResourceCategory)).saveAssetContent(this.assetID, '', sContent).subscribe(
            (data) => { _response = data; }
            , (error) => { }
            , () => {
                if (_response && Utils.isNullOrEmpty(_response.error))
                    this.assetContent = sContent;
            }
        );
    }

}
