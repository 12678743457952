import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, OnDestroy } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { listing, Role, assessment, escrow } from '../models';

import { EscrowService } from './escrow.service';
import { ListingService } from '../listing/listing.service';
import { UtilService } from '../common/service';
import { HTMLDialog } from '../common/dialogs/showHTML.dialog';
import { ComplianceItemDialog } from '../offer/dialogs/complianceItem.dialog';

import { EscrowBaseComponent } from './escrow.base.component';
import { Subscription } from 'rxjs/internal/Subscription';
@Component({
    selector: 'app-escrow-statement',
    templateUrl: './escrowStatement.component.html'
})
export class EscrowStatementComponent extends EscrowBaseComponent implements OnDestroy {

    earnings: string = ''; hasEarnings: boolean = false;
    payable: string = ''; hasPayable: boolean = false;
    buyerLiens: any = [];
    selectedLoanID: number = 0;
    fullStatement: any = []; statement: any = [];
    totalCredits: number = 0;
    totalDebits: number = 0;

    @ViewChild('statementTable') table;
    @Input() showHeader: boolean = false;

    private _displayingEID: number = 0;
    private _spID: number = 0;
    private _lsc: Subscription;
    private _esc: Subscription;
    private _rsc: Subscription;
    private busyBindingNewStatement: boolean = false;

    constructor(ds: DataService, r: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('escrowStatement', ds, r, g);

        if (this._esc != null) this._esc.unsubscribe();
        if (this._rsc != null) this._rsc.unsubscribe();
        if (this._lsc != null) this._lsc.unsubscribe();

        this._rsc = g.roleChanged.subscribe((_r) => {
            this.initMe();
            this.bindStatement(_r);
        });
        this._esc = g.escrowChanged.subscribe((_r) => {
            this.initMe();
            this.fullStatement = this.statement = [];
            this.totalDebits = this.totalCredits = 0;
            this.buyerLiens = [];
            this.selectedLoanID = 0;
            this.bindStatement();
        });
        // this.gc.listingChanged.subscribe((_r) => {
        //    const _e = this.currentEscrow;
        //    if ( _e != null && _r !== _e.id)
        //        this.bindStatement();
        // });
    }


    public get serviceproviderID(): number { return this._spID; }
    public set serviceproviderID(v: number) {
        const _changed = this._spID !== v;
        this._spID = v;
        if (_changed) {
            this.bindGrid();
        }
    }

    ngOnDestroy() {
        if (this._esc != null) this._esc.unsubscribe();
        if (this._rsc != null) this._rsc.unsubscribe();
        if (this._lsc != null) this._lsc.unsubscribe();
        this.destoryLoginSubscription();
    }

    addLoanToEscrow() { }
    showPA() {
        let _response;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getPurchaseAgreement(this._displayingEID);
        if (_es != null) {
            _es.subscribe((r) => _response = r
                , (error) => { }
                , () => {
                    if (_response && _response.data) {
                        this.dialog.open(HTMLDialog, {
                            data: {
                                html: _response.data
                            }
                        });
                    }
                });
        }
    }

    showCIC() {
        // Show Dialog
        const dialogRef = this.dialog.open(ComplianceItemDialog, {
            data: {
                id: this.currentEscrow.offerID
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => { });
    }

    clear() {
        this.fullStatement = null;
        this.setCurrentEscrow(null);
    }

    bindGrid() {

        this.totalCredits = this.totalDebits = 0;

        if (this.gc.role === Role.Seller || this.gc.role === Role.Buyer)
            this.statement = this.fullStatement.filter(s => { return s.ForRole === this.gc.role; });
        else if (this.serviceproviderID > 0)
            this.statement = this.fullStatement.filter(s => { return s.ForRole === Role.NotSet && s.ServiceProviderID === this.serviceproviderID; });

        this.statement.forEach(a => { if ((a.ForRole === this.gc.role || this.serviceproviderID === a.ServiceProviderID) && a.Amount > 0) this.totalCredits += a.Amount; });
        this.statement.forEach(a => { if ((a.ForRole === this.gc.role || this.serviceproviderID === a.ServiceProviderID)  && a.Amount < 0) this.totalDebits += a.Amount; });

        this.hasPayable = this.hasEarnings = false;
        this.earnings = this.payable = '';

        let _response;
        if (this.totalCredits - Math.abs(this.totalDebits) > 0) {

            (new UtilService(this.dataservice, this.route, this.myResourceCategory)).convertAmountToWords(Math.abs(this.totalCredits - Math.abs(this.totalDebits))).subscribe((r) => _response = r
                , (error) => { this.earnings = this.FormatNumberToCurrency(Math.abs(this.totalCredits - Math.abs(this.totalDebits))); }
                , () => {
                    this.earnings = this.FormatNumberToCurrency(Math.abs(this.totalCredits - Math.abs(this.totalDebits))) + (_response && _response.data ? ' (' + _response.data + ')' : '');
                });

            this.hasEarnings = true;
        } else if (this.totalCredits - Math.abs(this.totalDebits) < 0) {
            (new UtilService(this.dataservice, this.route, this.myResourceCategory)).convertAmountToWords(Math.abs(this.totalCredits - Math.abs(this.totalDebits))).subscribe((r) => _response = r
                , (error) => { this.earnings = this.FormatNumberToCurrency(Math.abs(this.totalCredits - Math.abs(this.totalDebits))); }
                , () => {
                    this.payable = this.FormatNumberToCurrency(Math.abs(this.totalCredits - Math.abs(this.totalDebits))) + (_response && _response.data ? ' (' + _response.data + ')' : '');
                });

            this.hasPayable = true;
        }
    }

    bindStatement(forRole: Role = Role.NotSet) {

        if (this.escrowID > 0 && this.busyBindingNewStatement === false) {

            // Statement for Escrow already exists
            if (this.fullStatement != null && this.fullStatement.length > 0) {
                this.bindGrid();
                return;
            }

            const _id = this.currentEscrow ? this.currentEscrow.id : 0;
            if (_id === 0) {
                this.initMe();
            }

            let _statementResponse;
            if (this.currentEscrow == null || this._displayingEID === 0 || (this._displayingEID > 0 && this.currentEscrow.id !== this._displayingEID) || this.fullStatement == null || this.fullStatement.length === 0) {
                if (this.escrowID > 0 && this.busyBindingNewStatement === false) {
                    const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getStatement(this.escrowID, forRole, this.serviceproviderID);
                    if (_es != null) {
                        this.busyBindingNewStatement = true;
                        this.componentBusy = true;
                        _es.subscribe((r) => { _statementResponse = r; }
                            , (error) => { this.componentBusy = false; this.busyBindingNewStatement = false; }
                            , () => {
                                this.componentBusy = false;
                                this.busyBindingNewStatement = false;
                                if (_statementResponse && _statementResponse.data) {
                                    this._displayingEID = this.escrowID;
                                    this.fullStatement = _statementResponse.data.statements;
                                    this.bindGrid();
                                }
                            });
                    }
                }
            } else {
                this.busyBindingNewStatement = this.componentBusy = false;
                this.bindGrid();
            }

            if (this.gc.role === Role.Buyer && this.busyBindingNewStatement === true && this.escrowID > 0) {
                let _lienResponse;
                const _els = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getMyLiensForEscrow(this.escrowID);
                if (_els != null) {
                    this.componentBusy = true;
                    _els.subscribe((r) => _lienResponse = r
                        , (error) => { this.componentBusy = false; }
                        , () => {
                            this.componentBusy = false;
                            if (_lienResponse && _lienResponse.data) {
                                this.buyerLiens = _lienResponse.data;
                            }
                        }
                    );
                }
            }
        } else {
            this.busyBindingNewStatement = false;
            this.componentBusy = false;
        }
    }
}
