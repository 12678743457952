import { Component, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';

import { EscrowService } from './escrow.service';
import { HTMLDialog } from '../common/dialogs/showHTML.dialog';

@Component({
    selector: 'app-escrow-deposit-instructions'
    , template: `<a (click)='showInstructions()' title='Escrow Deposit Instructions' *ngIf='escrowID > 0'><i class='fa fa-ticket' *ngIf='showIcon'></i><span *ngIf='showLabel'>{{label}}</span></a>`
})
export class EscrowDepositInstructionsComponent extends BaseComponent {

    @Input() escrowID: number = 0;
    @Input() showLabel: boolean = true;
    @Input() showIcon: boolean = false;
    @Input() label: string = 'Deposit Instructions';

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent, private dialog: MatDialog) {
        super('', r, gc);
    }

    showInstructions() {
        if (this.escrowID == null || this.escrowID === 0) return '';
        let _response ;
        const _es = (new EscrowService(this.dataservice, this.r, this.myResourceCategory)).getDepositInstructions(this.escrowID);
        if (_es != null) {
            _es.subscribe((r) => _response = r
                , (error) => { }
                , () => {
                    if (_response && _response.data) {
                        this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                    }
                });
        }
    }
}
