import { Component, Inject, Input, Output, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router } from '@angular/router';

import { DataService } from '../../util/APICaller.component';
import { ListingBaseComponent } from '../listing.base.component';
import { GlobalComponent } from '../../global.component';

@Component({
    selector: 'app-listing-personal-property',
    templateUrl: './listingPersonalProperty.component.html'
})
export class ListingPersonalPropertyComponent extends ListingBaseComponent implements OnDestroy {

    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;

    private _lcs: Subscription;

    constructor(dataservice: DataService, r: Router, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('listingPersonalProperty', dataservice, r, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.exitingEdit();
        this.destoryLoginSubscription();
    }
}
