import { ToBePaidBy, OfferServiceStatus, Role, OfferStatus } from '../../enums.component';
import { RecordTimeStamp } from '../../base.model';
import { Address } from '../../address.model';
import { Utils } from '../../../common/utils';

export class Offer extends RecordTimeStamp {
    public id: number = 0;
    public listingID: number = 0;
    public escrowID: number = 0;
    public powerAttornies: any = [];
    public canEdit: boolean = false;
    public productID: number = 0;
    public propertyTypeID: number = 0;
    public myRole: Role = Role.NotSet;
    public _address: Address = new Address();
    actionThreshold: number = 0;
    public isCounterOffer: boolean = false;
    public countered: boolean = false;
    expiryDate: Date = Utils._minDate;
    closingOffset: number = 0;

    alertOnHigherOffer: boolean = false;

    public currentAskingPrice: number = 0;
    public deposit: number = 0;
    public askingDeposit: number = 0;

    public downPayment: number = 0;
    public financeAmount: number = 0;
    public previousOfferedPrice: number = 0;
    public offeredPrice: number = 0;
    public isFinal: boolean = false;

    public otherOffersLowestPrice: number = 0;

    public hasProofOfFunds: boolean = false;
    public contingentOnLoanFunding: boolean = false;
    public contingencyRemovedInDays: number = 0;

    public defaultLiabilityCeiling: number = 0;
    public minimumDamagesAmount: number = 0;

    public maxAvailablePartnership: number = 0;

    public agentName: string = '';
    public brokerName: string = '';

    public lastUpdatDate: Date = Utils._minDate;

    public buyers: string = '';
    public buyerPIDs: number[] = [];
    public isBuyer: boolean = false;
    public sellers: string = '';
    public sellerPIDs: number[] = [];
    public isSeller: boolean = false;
    public otherParty: string = '';
    public otherPartyPIDs: number[] = [];

    public sellerPaidServices: string = '';
    public existingServices: string = '';

    public buyerHasAgent: boolean = false;
    public isAgent: boolean = false;
    public buyerAgentCommission: number = 0;

    public purchaseAgreementExists: boolean = false;

    public ownershipType: TitleType = TitleType['Not Set'];

    public propertyOccupied: boolean = false;
    public vacateOnDelivery: boolean = false;
    public tenantsToVacate: boolean = false;

    public nextSteps: any = [];
    public currentStatus: OfferStatus = OfferStatus.None;
    public currentStatusDate: Date = Utils._minDate;
    public statusName: string  = '';

    public includedPersonalProperty: string = '';
    public excludedPersonalProperty: string = '';

    pendingAcceptanceBy: any = [];
    public isAcceptingOffers: boolean = false;
    public showNotAcceptingOffers: boolean = false;

    agentDisclosures: any = [];
    isEscrowOpen: boolean = false;

    canSubmit: boolean = false;
    canAddContingency: boolean = false;
    canAcceptAgentWorkOrder: boolean = false;
    agentAcceptedWorkOrder: boolean = false;
    canSignDisclosures: boolean = false;
    canSendDisclosureNotice: boolean = false;

    public roles: OfferRole[] = [];
    public services: OfferServiceModel[] = [];
    public contingencies: any[] = [];
    public complianceItems: any[] = [];

    criticalServicePresent: boolean = false;
    criticalServicePresentWithSP: boolean = false;
    escrowServiceAddedBySeller: boolean = false;

    public editableProperties: string[] = [];

    constructor() { super(); }

    public get offerVariance(): string {
        return '';
    }

    get address(): Address { return this._address; }
    set address(v: Address) { this._address.castToMe(v); }

    public get propertyAddress(): string { if (this.address) return this.address.FullStreetAddress; else return ''; }
}

export enum TitleType {
    'Not Set' = 0,
    'Joint Tenant' = 1,
    'Tenancy In Common' = 2,
    'Community Property' = 3,
    'Sole Proprietership' = 4
}

export class OfferServiceModel extends RecordTimeStamp {
    public id: number = 0;
    public description: string = '';
    public typeID: number = 0;
    public estimatedCost: number = 0;
    public paidBy: ToBePaidBy = ToBePaidBy.NotSet;
    public maxPaidByOfferor: number = 0;
    public currentStatus: OfferServiceStatus = OfferServiceStatus.NotSet;
    public statusName: string = '';
    public canDelete: boolean = false;
    public serviceProviderID: number = 0;
    public serviceProvider: string = '';
    public canEdit: boolean = false;
    public canChangeSP: boolean = false;
    public sellerPayingForRepairs: boolean = false;
    public maxRepairsPaidBySeller: number = 0;
    public options: string = '';
    public optionIDs: number[] = [];

    public nextSteps: any[] = [];
    public availableOptions: any[] = [];
    public mustSelectOption: boolean = false;
    public selectMultipleOptions: boolean = false;
    public optionMissing: boolean = false;

    constructor() {
        super();
    }

    get optionID(): number {
        if (this.optionIDs && this.optionIDs.length > 0)
            return this.optionIDs[0];
        else
            return 0;
    }
    set optionID(v: number) {
        if (this.optionIDs == null)
            this.optionIDs = [];
        if (this.optionIDs.length === 0)
            this.optionIDs.push(0);
        this.optionIDs[0] = (v);
    }

    get serviceTypeID(): number { return this.typeID; }
    set serviceTypeID(v: number) { this.typeID = v; }
}

// export class OfferServiceModel extends RecordTimeStamp {
//    public id: number = 0;
//    public serviceTypeID: number = 0;
//    public serviceProviderID: number = 0;
//    public options: number[] = [];
//    public paidBy: ToBePaidBy = ToBePaidBy.NotSet;
//    public repairsPaidBySeller: boolean = false;
//    public repairsAmount: number = 0;
//    public maxPaidByOfferor: number = 0;
//    constructor() {
//        super();
//        this.options = new Array<number>();
//    }
// }

export class OfferRole extends RecordTimeStamp {
    public id: number = 0;
    public pid: number = 0;
    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';
    public contactNumber: string = '';
    public identityComplete: boolean = false;
    public entityRoleID: number = 0;
    public roleID: Role = Role.NotSet;
    public roleName: string = '';
    public isREAgent: boolean = false;
    public agentAgreementExpiryDate: Date = Utils._minDate;
    public hasValidCertification: boolean = false;
    public poaPresent: boolean = false;
    public canDeclinePOA: boolean = false;
    public canRevokePOA: boolean = false;
    public canVerifyPOA: boolean = false;
    public canGivePOA: boolean = false;
    public canUploadPOA: boolean = false;
    public company: string = '';
    public ownershipStake: number = 0;
    public canAcceptAgentTerms: boolean = false;
    public canUploadAgentTerms: boolean = false;
    public hasAcceptedAgentTerms: boolean = false;
    public canRepresentCompany: boolean = false;
    public canDelete: boolean = false;
    public canAcceptPartnerShip: boolean = false;
    public isDCPresent: boolean = false;
    public canVerifyDC: boolean = false;
}

export class OfferProperties extends RecordTimeStamp {
    public id: number = 0;
    public address: Address = new Address();
    public askingPrice: number = 0;
    constructor() {
        super();
    }
}
