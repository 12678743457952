<div class="{{css}}" id="divSignIn">
	<h2 class="pageHeader" *ngIf="!inPopup || (!busy && inPopup)">
		<span class="noborder" *ngIf="!this.resettingPassword && !forcePasswordChanging">{{'Security.Login.Header' | translateTag}}</span>
		<span class="noborder" *ngIf="!this.resettingPassword && forcePasswordChanging">{{'Security.Login.ChangePassword' | translateTag}}</span>
		<span class="noborder" *ngIf="this.resettingPassword">{{'Security.Login.ForgotPassword' | translateTag}}</span>
		<a class="float-right" (click)="gotoRegister()" style="font-size:x-small;border-bottom:1px solid red">{{'Security.Login.NoAccount' | translateTag}}</a>
		<span class="float-right noborder"> | </span>
	</h2>
	<br/>
	<mat-spinner *ngIf="busy && inPopup" [diameter]="100"></mat-spinner>
	<div *ngIf="!inPopup || (!busy && inPopup)">
		<div *ngIf="forcePasswordChanging">{{'Security.Login.PickNewPassword' | translateTag}}<hr /></div>
		<mat-form-field *ngIf="!forcePasswordChanging" class="withMatError" appearance="outline">
			<mat-label>{{'Security.Login.EmailAddress' | translateTag}}</mat-label>
			<input matInput appDisableAutofill placeholder="" maxlength="100" type="text" [(ngModel)]="uid" required autofocus autoFocus
				   emailvalidator requiredvalidator [required]="true" #em="ngModel" (ngModelChange)="clearMessages()" />
			<mat-error [hidden]="em.valid || em.pristine">{{'Security.Login.EmailValidation' | translateTag}}</mat-error>
		</mat-form-field>
		<div *ngIf="!resettingPassword">
			<br />
			<mat-form-field class="withMatError" appearance="outline">
				<mat-label>{{(this.user.forcePasswordChange ? 'Security.Login.TempPassword' : 'Security.Login.Password') | translateTag}}</mat-label>
				<input matInput appDisableAutofill requiredvalidator placeholder="{{(this.user.forcePasswordChange ? 'Security.Login.TempPassword' : 'Security.Login.Password') | translateTag}}"
					   maxlength="50" type="{{showPwd ? 'text' : 'password'}}" [(ngModel)]="pwd" required my-password (ngModelChange)="clearMessages()"
					   style="width: Calc(100% - 24px)" #cpwd name="cpwd" />
				<i class="fa {{showPwd ? 'fa-eye-slash' : 'fa-eye'}}" (click)="toggleShowPwd()"></i>
				<mat-error [hidden]="cpwd.valid || cpwd.pristine">{{'Security.Login.PasswordRequired' | translateTag}}</mat-error>
			</mat-form-field>
		</div>
		<div *ngIf="forcePasswordChanging && !resettingPassword">
			<mat-form-field class="withMatError" appearance="outline">
				<mat-label>{{'Security.Login.NewPassword' | translateTag}}</mat-label>
				<input matInput appDisableAutofill requiredvalidator passwordvalidator placeholder="{{'Security.Login.NewPassword' | translateTag}}" maxlength="50" type="{{showPwd ? 'text' : 'password'}}"
					   [(ngModel)]="newPWD" required (ngModelChange)="clearMessages()"
					   style="width: Calc(100% - 24px)" #newpwd name="newpwd" />
				<i class="fa {{showPwd ? 'fa-eye-slash' : 'fa-eye'}}" (click)="toggleShowPwd()"></i>
				<mat-error [hidden]="newpwd.valid || newpwd.pristine">{{'Security.Login.PasswordValidation' | translateTag}}</mat-error>
			</mat-form-field>
			<p>We recommend using online password generation, use your favorite search engine and lookup "online password generator using my words"</p>
			<br />
			<mat-form-field class="withMatError" appearance="outline">
				<mat-label>{{'Security.Login.PasswordReEnter' | translateTag}}</mat-label>
				<input matInput appDisableAutofill requiredvalidator placeholder="{{'Security.Login.PasswordReEnter' | translateTag}}" maxlength="50" type="{{showPwd ? 'text' : 'password'}}"
					   [(ngModel)]="reEnterPWD" required (ngModelChange)="clearMessages()"
					   style="width: Calc(100% - 24px)" #repwd name="repwd" validateEqual="newpwd" />
				<i class="fa {{showPwd ? 'fa-eye-slash' : 'fa-eye'}}" (click)="toggleShowPwd()"></i>
				<mat-error [hidden]="repwd.valid || repwd.pristine">{{'Security.Login.ConfirmPasswordValidation' | translateTag}}</mat-error>
			</mat-form-field>
		</div>
		<div class="message danger-background" *ngIf="!(lblErrorMessage | isNullOrEmpty)" hrefToRouterLink [innerHTML]="lblErrorMessage | safeHtml"></div>
		<div class="message warning-background" *ngIf="!(lblMessage | isNullOrEmpty)" hrefToRouterLink [innerHTML]="lblMessage | safeHtml"></div>
		<div *ngIf="!busy" style="{{inPopup === false ? 'padding-bottom:10px' : ''}}">
			<a [ngClass]="{'full' : inPopup, 'big' : !inPopup}" class="btn btn-primary nomargin"
			   *ngIf="(resettingPassword && !(uid | isNullOrEmpty)) || (!resettingPassword &&  ((!forcePasswordChanging && !(uid | isNullOrEmpty) && !(pwd | isNullOrEmpty)) || (forcePasswordChanging && !(pwd | isNullOrEmpty)&& !(newPWD | isNullOrEmpty)&& !(reEnterPWD | isNullOrEmpty) && newPWD === reEnterPWD)))"
			   (click)="proceed()">{{'System.Continue' | translateTag}}</a>
			<hr />
			<a class="btn btn-secondary nomargin" (click)="cancel()" *ngIf="inPopup">{{'System.Cancel' | translateTag}}</a>
			<a class="float-right link " style="{{inPopup ? 'margin-left:200px' : ''}}" (click)="forgotPassword()" *ngIf="!forcePasswordChanging && !resettingPassword">{{'Security.Login.ForgotPassword' | translateTag}}</a>
			<!-- Social Login
			<hr />
			<button (click)="socialSignIn('facebook')" [disabled]="busy">Sign in with Facebook</button>&nbsp;&nbsp;
			<button (click)="socialSignIn('google')" [disabled]="busy">Sign in with Google</button>
					  -->
		</div>
	</div>
</div>