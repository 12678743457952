import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, AfterViewInit, Output, OnDestroy } from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryAction, INgxGalleryImage } from '@murbanczyk-fp/ngx-gallery';

import { ListingService } from '../listing.service';

import { ListingBaseComponent } from '../listing.base.component';
import { AddMediaComponent } from '../controls/addMedia.component';
import { GlobalComponent } from '../../global.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../common/utils';
@Component({
    selector: 'app-listing-media'
    , styleUrls: ['./listingMedia.component.css']
    , templateUrl: './listingMedia.component.html'
})
export class ListingMediaComponent extends ListingBaseComponent implements OnInit, AfterViewInit, OnDestroy {

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    galleryAction: NgxGalleryAction[];
    @Input() disableAdd: boolean = false;
    @ViewChild('addMedia') addMedia: AddMediaComponent;
    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;
    private _lcs: Subscription;
    constructor(dataservice: DataService, r: Router, @Inject(GlobalComponent) gc: GlobalComponent) {
        super('listingMedia', dataservice, r, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindMedia();
        });
    }

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngOnInit() {

            this.removeMedia = this.removeMedia.bind(this);
            this.setDefaultMedia = this.setDefaultMedia.bind(this);

            this.galleryAction = [{
                icon: 'fa fa-times-circle danger',
                titleText: 'Remove this Media Item',
                onClick: this.removeMedia
            } as NgxGalleryAction,
            {
                icon: 'fa fa-home green',
                titleText: 'Set as Default/Primary',
                onClick: this.setDefaultMedia
            } as NgxGalleryAction
            ];

        this.galleryOptions = [
            {
                image: true, width: '100%', height: '400px', previewFullscreen: true, arrowPrevIcon: 'fa fa-arrow-circle-o-left green', arrowNextIcon: 'fa fa-arrow-circle-o-right green'
                , imageActions: this.galleryAction, thumbnailsPercent : 20
            }
            , { breakpoint: 500, width: '100%' }
        ];

         this.galleryImages = new Array<NgxGalleryImage>();
    }

    ngAfterViewInit() {
        if (this.addMedia) {
            this.addMedia.mediaChanged.subscribe((data) => {
                if (data) {
                    if (Array.isArray(data)) {
                        this.setImageValues(data);
                    } else if (data.url) {
                         this.galleryImages.push({
                            description: data.description
                            , url: data.url
                            , label: data.title
                            , small: data.url
                            , medium: data.url
                         });
                    }
                    this.galleryImages = [...this.galleryImages];
                }
            });
        }
    }

    setDefaultMedia($event, iIndex: number) {
        if (this.currentListing.media && this.currentListing.media.length > iIndex) {
            const _mediaID = this.currentListing.media[iIndex].id;
            this.gc.setBusy(true);
            let _response;
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).setDefaultMedia(this.currentListing.id, _mediaID).subscribe(
                (data) => { this.gc.setBusy(false); _response = data; }
                , (error) => { this.gc.setBusy(false); this.onApiError(error); }
                , () => {
                    this.gc.setBusy(false);
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else
                            this.showMessage('Current Media item set to be the primary image and will be displayed as first image in results to Propspective Buyers..');
                });
        }
    }

    removeMedia($event, iIndex: number) {
        if (this.currentListing.media && this.currentListing.media.length > iIndex) {
            const _mediaID = this.currentListing.media[iIndex].id;
            this.gc.setBusy(true);
            let _response;
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).removeMedia(this.currentListing.id, _mediaID).subscribe(
                (data) => { _response = data; }
                , (error) => { this.gc.setBusy(false); this.onApiError(error); }
                , () => {
                    this.gc.setBusy(false);
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.showMessage('Media item removed');
                            this.currentListing.media.forEach((item, index) => {
                                if (index === iIndex) this.currentListing.media.splice(index, 1);
                            });
                            this.setImageValues(this.currentListing.media);
                        }
                }
            );
        }
    }

    bindMedia() {

        if (this.listingID === 0) return;
        if (this.addMedia) this.addMedia.listingID = this.listingID;

        if (this.currentListing && !this.currentListing.isSeller) {
            if (this.galleryOptions && this.galleryOptions.length > 0 && this.galleryOptions[0].imageActions) {
                this.galleryOptions[0].imageActions = [];
                this.galleryOptions = [...this.galleryOptions];
            }
        }

        if (this.currentListing && this.currentListing.id > 0 && this.currentListing.id === this.listingID)
            this.setImageValues(this.currentListing.media);
        else {
            let _response;
            this.gc.setBusy(true);
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getMedia(this.listingID).subscribe(
                (data) => { _response = data; }
                , (error) => { this.gc.setBusy(false); this.onApiError(error); }
                , () => {
                    this.gc.setBusy(false);
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else if (_response.data) {
                            this.setImageValues(_response.data.media);
                        }
                }
            );
        }
    }

    private setImageValues(data) {
        if (data == null || data.length === 0) return;
        if (Array.isArray(data)) {
            this.galleryImages = data.map((m) => {
                const _m = {
                    description: m.description
                    , url : m.url
                    , label : m.title
                    , small : m.url
                    , medium : m.url
                    , big : m.url
                };
                return _m;
            });
        }
    }
}
