import { Router } from '@angular/router';
import { BaseService } from '../../common/service/base.service';

import { DataService } from '../../util/APICaller.component';
import { Merge, SubSQL } from '../../models';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MergeService extends BaseService {

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule, ds);
    }

    merges(includeInActive: boolean = false) {
        const data = { };
        return this._dataService.APIPost('/admin/merge/merges', data, this.requestingModule);
    }
    merge(iMergeID: number) {
        const data = { id: iMergeID };
        return this._dataService.APIPost('/admin/merge/merge', data, this.requestingModule);
    }
    getMerge(iReferenceID: number, iReferenceType: number) {
        const data = { id: iReferenceID, type: iReferenceType };
        return this._dataService.APIPost('/admin/merge/mergeForReferenceID', data, this.requestingModule);
    }
    getColumns(iMergeID: number) {
        const data = { id: iMergeID };
        return this._dataService.APIPost('/admin/merge/columns', data, this.requestingModule);
    }
    getAvailableLogging() {
        return this.getCacheData('/admin/merge/availableLogging');
    }

    save(oMerge: Merge) {
        const data = oMerge;
        return this._dataService.APIPost('/admin/merge/save', data, this.requestingModule);
    }
    execute(oMerge: Merge) {
        const data = oMerge;
        return this._dataService.APIPost('/admin/merge/execute', data, this.requestingModule);
    }
    parseAndVerifyMain(oMerge: Merge) {
        const data = oMerge;
        return this._dataService.APIPost('/admin/merge/parseAndVerifyMain', data, this.requestingModule);
    }
    saveParameters(iMergeID: number, oParameters: any) {
        const data = { id: iMergeID, parameters: oParameters };
        return this._dataService.APIPost('/admin/merge/parameter/save', data, this.requestingModule);
    }
    saveAttachments(iMergeID: number, oAttachments: any) {
        const data = { id: iMergeID, attachments : oAttachments };
        return this._dataService.APIPost('/admin/merge/attachments/save', data, this.requestingModule);
    }
    saveSubQuery(iMergeID: number, oSubQuery: SubSQL) {
        const data = { id: iMergeID, name: oSubQuery.name, query: oSubQuery.query, template: oSubQuery.template, rowSeperator: oSubQuery.rowSeperator };
        return this._dataService.APIPost('/admin/merge/subSQL/save', data, this.requestingModule);
    }
    saveTranslations(iMergeID: number, oTranslations: any) {
        const data = { id: iMergeID, translations: oTranslations };
        return this._dataService.APIPost('/admin/merge/translation/save', data, this.requestingModule);
    }
    saveMapLog(iMergeID: number, oMapLogs: any) {
        const data = { id: iMergeID, logMaps: oMapLogs };
        return this._dataService.APIPost('/admin/merge/logMap/save', data, this.requestingModule);
    }
}
