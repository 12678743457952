<div class="{{inPopup ? '' : 'appContent'}}">
    <h2 class="pageHeader" *ngIf="!inPopup">
        Find {{agentSearch ? 'Real Estate Agents' :  'Service Providers'}}
        <span *ngIf="utils.isNullOrEmpty(geographicArea?.FullStreetAddress) === false && hideAddress === false" class="inline no-padd noborder">&nbsp;near&nbsp;{{geographicArea.FullStreetAddress}}</span>
    </h2>
    <div id="divAllowToPickSP" class="section">
        <mat-card [ngClass]="{'hidden' : hideServiceType}">
            <div class="first app-flex-container app-2col">
                <mat-form-field *ngIf="serviceTypes != null && serviceTypes.length > 0">
                    <mat-label class="placeholder">Service Type<i class="fa fa-question-circle-o warning-color" title="Select Service Type"></i></mat-label>
                    <mat-select [(ngModel)]="selectedServiceTypeID" [disabled]="serviceTypes.length == 1 || canChangeSeviceType">
                        <mat-option *ngFor="let p of serviceTypes" [value]="p.id" class="auto">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="canHaveMultiples && options != null && options.length > 0">
                    <mat-label class="placeholder">Option(s) <i class="fa fa-question-circle-o warning-color" title="Select one or more Service Option"></i></mat-label>
                    <mat-select multiple [(ngModel)]="selectedOptionIDs" (selectionChange)="onOptionChanged()" [disabled]="options.length == 1">
                        <mat-option *ngFor="let p of options" [value]="p.id"  class="auto">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!canHaveMultiples && options != null && options.length > 0">
                    <mat-label class="placeholder">Option(s) <i class="fa fa-question-circle-o warning-color" title="Select a service option"></i></mat-label>
                    <mat-select [(ngModel)]="selectedOptionID" [disabled]="options.length == 1" (selectionChange)="onOptionChanged()">
                        <mat-option *ngFor="let p of options" [value]="p.id"  class="auto">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card>
        <mat-card *ngIf="allowToPickSP" [ngClass]="{'hidden' : true}">
            Map Placeholder
        </mat-card>
    </div>
    <mat-card class="narrow section" *ngIf="isLenderServiceType">{{'Provider.loanRateStatement' | translateTag}}</mat-card>
    <div *ngIf="allowToPickSP" class="section">
        <ng-template [ngIf]="(this.serviceProviders && this.serviceProviders.length > 0)" [ngIfElse]="noSPS">
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let sp of serviceProviders | paginate: { itemsPerPage: itemsOnPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="spRowSelected(sp, i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{sp.name}}
                            <span *ngIf="sp.numberOfReviews > 0">
                                <star-rating [value]="sp.averageRating" [totalstars]="5" [readonly]="true" [checkedcolor]="'gold'" [uncheckedcolor]="'grey'" [size]="'24px'" style="display:inline-block"></star-rating>
                                <a (click)="readReviews(sp.id)">{{'Provider.readReviews' | translateTag}}</a>
                            </span>
                            <i class="fa fa-star-o" title="{{'Provider.noRating' | translateTag}}"></i>
                        </mat-panel-title>
                        <mat-panel-description style="display:initial">
                            <span class="float-right">{{sp.cost}}</span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="section">
                        <div *ngIf="sp.offerings && sp.offerings[0]?.options.length > 0" class="section">
                            <mat-form-field >
                                <mat-label class="placeholder">Option Cost <i class="fa fa-question-circle-o warning-color" title="Pricing for Options"></i></mat-label>
                                <mat-select [(ngModel)]="selectedOptionID">
                                    <mat-option *ngFor="let o of sp.offerings[0]?.options" [value]="o.id" class="auto">{{o.name + ' [' + o.price + ']'}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-stroked-button color="accent" (click)="onServiceProviderSelection(row)"
                                    *ngIf="this.providerSelected != null && selectAllowed" class="float-right">
                                {{selectButtonText}}
                            </button>
                            <a (click)="sendMessageTo(sp.administrators)" class="float-right green-text" title="Send Message to Service Provider" *ngIf="sp.administrators && sp.administrators.length >  0"><i class="material-icons">message</i></a>
                            <a (click)="viewTerms(sp.id, sp.name)" title="View Terms and Conditions" class="float-right"><i class="material-icons">import_contacts</i></a>
                            <label class="caption">Address</label><label class="x-large" *ngIf="sp.address">{{sp.address}} [<span title="Distance from property address">{{sp.distance > 100 ? "> 100" : sp.distance}}</span>]</label>
                        </div>
                        <div *ngIf="sp.staff && sp.staff.length > 0">
                            <label class="caption">Contact</label>
                            <ng-template ngFor let-s [ngForOf]="sp.staff" let-i="index">
                                <span *ngIf="i > 0"> | </span><a (click)="showAgentDetails(s.id)">{{s.name}}</a>&nbsp;<a title="Send an internal system message to {{s.name}} to contact you. (your personal information will not be shared)" (click)='sendMessageToAgent(s.id, "Please contact me.");'><i class="fa fa-comment"></i></a>
                            </ng-template>
                        </div>
                        <div>
                            <span *ngIf="utils.isNullOrEmpty(sp.licenses) === false"> <strong>Licenses</strong> : <ng-template ngFor let-l [ngForOf]="sp.licenses" let-i="index"><span *ngIf="i > 0"> | </span>{{l.license}}</ng-template></span>
                            <span *ngIf="utils.isNullOrEmpty(sp.insurances) === false"> <strong>Insurances</strong> : <ng-template ngFor let-l [ngForOf]="sp.insurances" let-i="index"><span *ngIf="i > 0"> | </span>{{l.policy}}</ng-template></span>
                        </div>
                    </div>
                    <mat-action-row *ngIf="serviceProviders.length > 1">
                        <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && serviceProviders.length > 1">Previous</button>
                        <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < serviceProviders.length && serviceProviders.length > 1">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
            <div style="text-align:center" *ngIf="this.serviceProviders.length > itemsOnPage">
                <hr />
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </ng-template>
        <ng-template #noSPS>
            No Service Providers available for your selected criteria.
        </ng-template>
    </div>
    <div *ngIf="selectedServiceProviderID > 0 && collectPayment && agreedToSPTnC == false">
        <mat-card>
            <mat-card-subtitle> Disclosures and Terms and Conditions</mat-card-subtitle>
            <mat-card-content>
                <ng-template ngFor let-a [ngForOf]="terms" let-i="index">
                    <mat-card class="narrow"><div hrefToRouterLink [innerHTML]="a | safeHtml"></div></mat-card>
                </ng-template>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-checkbox [(ngModel)]="agreedToSPTnC" >I have read and agree to {{selectedServiceProviderName}}'s Disclosures/Terms and Conditions. </mat-checkbox>
        </mat-card>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="inPopup && loadingSP"></mat-progress-bar>
</div>
