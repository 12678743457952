import { Component, ChangeDetectorRef, ViewChild, Inject, AfterViewInit} from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';

import { spProductRate } from '../../models/providers/spPricing.model';
import { ServiceProviderService } from '../serviceprovider.service';
import { appEnvironment } from '../../../environments/environment';
import { DatePickerComponent } from '../../common/datepicker.component';
import { BaseResponse } from '../../models';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-add-service-rate',
    templateUrl: './addServiceRate.dialog.html'
})
export class AddServiceRateDialog extends BaseDialog implements AfterViewInit {

    @ViewChild('txtStartDate') startDate: DatePickerComponent;
    public rate: spProductRate = new spProductRate();
    dialogHeader: string = 'Product Rate';

    isLoanProduct: boolean = false;
    loanOptionID: number = 0;
    loanTypeDetails: any;
    duration = [];
    saving: boolean = false;

    constructor(dataservice: DataService, r: Router, public dialogRef: MatDialogRef<AddServiceRateDialog>, private cdref: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addServiceRateDialog', dataservice, r, dialogRef, data);

        if (this.data) {
            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Product Rate for ' + this.data.header;
            }
            this.rate = Utils.castTo(this.data, new spProductRate());
            this.isLoanProduct = this.rate.productID === appEnvironment.lenderServiceTypeID;
        }
        this.rate.fixedPrice = this.rate.percentagePrice = null;

        if (this.data.optionID && this.data.optionID > 0) {
            let response: BaseResponse;
            (new ServiceProviderService(this.dataservice, this.route, this.myResourceCategory)).getLoanType(this.data.optionID).subscribe(
                (_data) => { response = _data; }
                , (error) => { this.saving = false; this.close(null); this.onApiError(error); }
                , () => {
                    this.saving = false;
                    if (response) {
                        if (Utils.isNullOrEmpty(response.error)) {
                            this.loanTypeDetails = response.data;
                            this.rate.adjustable = !this.loanTypeDetails.Fixed;
                            this.rate.fixedDuration = this.loanTypeDetails.FixedDuration * 12;
                            this.rate.amortizationDuration = this.loanTypeDetails.AmortizationDuration * 12;
                            this.rate.amortizationDurationID = this.data.optionID;
                            this.cdref.detectChanges();
                        }
                    }
                }
            );
        }

    }

    get isValid(): boolean {
        let _valPresent: boolean = false;
        if (this.isLoanProduct) {
            _valPresent = (this.rate.percentagePrice != null && this.rate.percentagePrice > 0) && (this.rate.adjustable ? (this.rate.fixedDuration > 0 && this.rate.rateCap > 0) : true) && this.rate.amortizationDuration > 0;
        } else {
            _valPresent = (this.rate.fixedPrice != null && this.rate.fixedPrice > 0) || (this.rate.percentagePrice != null && this.rate.percentagePrice > 0);
        }
        if (this.startDate)
            return _valPresent && moment(this.startDate.value).isSameOrAfter(this.today);
        else
            return _valPresent;
    }

    ngAfterViewInit() {
        if (this.startDate) {
            this.startDate.minDate = this.startDate.value = this.rate.startDate;
            if (moment(this.rate.startDate).isSame(this.Utils.dateTimeMinValue)) {
                // Adjust to Server TODAY;
                this.startDate.minDate = moment(this.today).add(-1 * this.user.offsetMinutesFromServer, 'minutes').toDate();
            }
        }
        this.cdref.detectChanges();
    }

    startDateChanged(value) {
        if (value && moment(value).isValid)
            this.rate.startDate = value;
    }

    endDateChanged(value) {
        if (value && moment(value).isValid)
            this.rate.endDate = value;
    }

    fixedPriceChanged() {
        if (this.rate.fixedPrice > 0) {
            this.rate.percentagePrice = this.rate.minPrice = this.rate.maxPrice = null;
        }
    }

    percentagePriceChanged() {
        if (this.rate.percentagePrice > 0) {
            this.rate.fixedPrice = null;
            this.rate.minPrice = null;
            this.rate.maxPrice = null;
        }
    }

    onAdjustableChange($event) {
        if (!this.rate.adjustable) {
            this.rate.fixedDuration = null;
            this.rate.rateCap = null;
        }
    }

    close(oResponse?: any) { this.dialogRef.close(oResponse); }
    onClosed(value: boolean) {
        this.close();
    }

    onSubmit() {

        if (this.startDate) {
            this.rate.startDate = this.startDate.value;
            this.rate.startDate.setHours(0, 0, 0, 0); // Trim Time
        }
        this.rate['serviceProviderID'] = this.user.details.CompanyID;
        this.saving = true;
        let response: BaseResponse;
        (new ServiceProviderService(this.dataservice, this.route, this.myResourceCategory)).addProductRate(this.rate).subscribe(
            (data) => response = data
            , (error) => { this.saving = false; this.close(null); this.onApiError(error); }
            , () => {
                this.saving = false;
                if (response) {
                    if (Utils.isNullOrEmpty(response.error)) {
                        this.close(response.data);
                    } else
                        this.error = response.error;
                }
            }
        );
    }
}
