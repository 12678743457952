import { Component, Inject, OnInit } from '@angular/core';

import { DataService } from '../../util/APICaller.component';

import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { escrowDeposit, Role, item } from '../../models';
import { EscrowService } from '../escrow.service';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-new-escrow-deposit-dialog',
    templateUrl: './newEscrowDeposit.dialog.html'
})
export class NewEscrowDepositDialog extends BaseDialog implements OnInit {

    dialogHeader: string = 'New Deposit';
    newDeposit: escrowDeposit = new escrowDeposit();
    escrowID: number = 0; workOrderID: number = 0;
    myRole: Role = Role.NotSet;
    roles: any = []; depositRoles: any = [];
    error: string = '';
    exclusivelyFor: string = '';
    saveReady: boolean = false;

    constructor(dataservice: DataService, r: Router , public dialogRef: MatDialogRef<NewEscrowDepositDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addEscrowDepositDialog', dataservice, r, dialogRef, data);
    }

    setSaveReady() {
        this.saveReady = this.newDeposit.amount > 0 && this.newDeposit.creditTo !== Role.NotSet;
    }

    ngOnInit() {
        this.depositRoles.push( new item(Role.Buyer, 'Buyer') );
        this.depositRoles.push( new item(Role.Seller, 'Seller') );

        if (this.data) {
            if (this.data.id) this.escrowID = this.data.id;
            if (this.data.myRole) this.myRole = this.newDeposit.depositedBy = this.data.myRole;
            if (this.data.roles) this.roles = this.data.roles;
            if (this.data.header) this.dialogHeader = this.data.header;
        }
    }

    onSubmit() {
        let _response;

        let _v: any;

        if (!Utils.isNullOrEmpty(this.exclusivelyFor)) {
            if (typeof this.exclusivelyFor === 'string') {
                _v = this.exclusivelyFor.split(':');
                if (Array.isArray(_v) && _v.length > 1)
                    this.newDeposit.exclusivelyForCompanyID = parseInt(_v[1]);
            } else if (typeof this.exclusivelyFor === 'number')
                this.newDeposit.exclusivelyForCompanyID = this.exclusivelyFor;
        }

        this.newDeposit.exclusivelyForWorkOrderID = this.workOrderID;

        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).addDeposit(this.escrowID, this.newDeposit);
        if (_es != null) {
            this.componentBusy = true;
            _es.subscribe((r) => _response = r
                , (error) => { this.componentBusy = false; }
                , () => {
                    this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.error = _response.error;
                        else if (_response.data)
                            this.close(_response.data);
                        else
                            this.close(null);
                });
        }
    }
}
