import { Routes} from '@angular/router';

import { HomeComponent } from './app/home.component';
import { ContactUsContentComponent, AboutContentComponent } from './app/contactus.component';
import { PrivacyPolicyContentComponent, TermsOfUseContentComponent } from './app/privacyPolicy.component';

import { SignInComponent } from './app/security/signin.component';
import { SignUpComponent } from './app/security/signup.component';
import { HelpComponent } from './app/help/help.component';
import { UnsubscribeComponent } from './app/security/unsubscribe.component';
import { SecurityContentComponent } from './app/security.component';

export const approutes: Routes = [
    { path: '', redirectTo: '/default', pathMatch: 'full' }
    , {
        path: 'default', component: HomeComponent
    }
    , {
        path: 'login', component: SignInComponent
    }
    , {
        path: 'forgotpassword', component: SignInComponent
    }
    , {
        path: 'unsubscribe', component: UnsubscribeComponent
    }
    , {
        path: 'register', component: SignUpComponent, data: {
                captchaProtected : true
        }
    }
    , {
        path: 'contactus', component: ContactUsContentComponent, data: {
                captchaProtected: true
        }
    }
    , {
        path: 'about', component: AboutContentComponent
    }
    , {
        path: 'privacypolicy', component: PrivacyPolicyContentComponent
    }
    , {
        path: 'termsofuse', component: TermsOfUseContentComponent
    }
    , {
        path: 'security', component: SecurityContentComponent
    }
    , {
        path: 'p', component: HelpComponent
    }
    , { path: 'account', loadChildren: () => import(`./app/account/account.module`).then((m) => m.AccountModule) }
    , { path: 'company', loadChildren: () => import(`./app/sp/serviceprovider.module`).then((m) => m.ServiceProviderModule) }
    , { path: 'agent', loadChildren: () => import(`./app/sp/serviceprovider.module`).then((m) => m.ServiceProviderModule) }
    , { path: 'register', loadChildren: () => import(`./app/sp/buy/spBuy.module`).then((m) => m.SPBuyModule) }
    , { path: 'provider', loadChildren: () => import(`./app/sp/pricing/spPricing.module`).then((m) => m.SPPricingModule) }
    , { path: 'submit', loadChildren: () => import(`./app/sp/report/submit.module`).then((m) => m.ReportSubmissionModule) }
    // , { path: 'workorder', loadChildren: () => import(`./app/workorders/workorders.module`).then((m) => m.SPWorkOrdersModule) }
    , { path: 'listing', loadChildren: () => import(`./app/listing/listing.module`).then((m) => m.ListingsModule) }
    , { path: 'home', loadChildren: () => import(`./app/buyer/searchListings.module`).then((m) => m.SearchListingsModule) }
    , { path: 'buyer', loadChildren: () => import(`./app/listing/listing.module`).then((m) => m.ListingsModule) }
    , { path: 'seller', loadChildren: () => import(`./app/listing/listing.module`).then((m) => m.ListingsModule) }
    , { path: 'system', loadChildren: () => import(`./app/admin/system/system.module`).then((m) => m.SystemModule) }
    , { path: 'offer', loadChildren: () => import(`./app/offer/offer.module`).then((m) => m.OfferModule) }
    , { path: 'loan', loadChildren: () => import(`./app/loan/loan.module`).then((m) => m.LoanModule) }
    , { path: 'help', loadChildren: () => import(`./app/help/help.module`).then((m) => m.HelpModule) }
    , { path: 'campaign', loadChildren: () => import(`./app/admin/campaign/campaign.module`).then((m) => m.CampaignModule) }
    , { path: 'admin', loadChildren: () => import(`./app/admin/admin.module`).then((m) => m.AdminModule) }
     // , { // Dynamic Routes to BE LOADED - SEE app component
     //   path: '**',
     //   component: HomeComponent, data: {
     //       meta: {
     //           title: '',
     //           description: ''
     //       }
     //   }
     //}
];
