<div class="appContent">
    <h2 class="pageHeader">Viewing Request(s)</h2>
    <mat-card *ngIf="showHeader && vlistings && vlistings.length > 1">
        <mat-form-field class="first" style="width:Calc(100% - 120px)!important">
            <mat-label class="placeholder">Properties<i class="fa fa-question-circle-o warning-color" title="Properties for Sale"></i></mat-label>
            <mat-select [(ngModel)]="listingID" (selectionChange)="setListing()">
                <mat-option *ngFor="let l of vlistings" class="auto" [value]="l.id">{{l.address}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="inline-flex" *ngIf="currentListing.isSeller === true">
            <a (click)="details()" class="btn btn-secondary float-right">Details</a>
            <a *ngIf="currentListing.acceptingOffers === true" (click)="addOpenHouse()"><i class="fa fa-home fa-2x green float-right" title="Setup new Open House"></i></a>
        </div>
    </mat-card>
    <mat-card *ngIf="(vlistings == null || vlistings.length <= 1) && currentListing" class="section">
        <a (click)="details()" class="link">{{currentListing.address.FullAddress}}</a>
        <a *ngIf="currentListing.isSeller === true && currentListing.acceptingOffers === true" (click)="addOpenHouse()"><i class="fa fa-home green float-right fa-2x" title="Setup new Open House"></i></a>
        <a *ngIf="currentListing.isSeller === true" (click)="details()" class="btn btn-secondary float-right">Details</a>
        <div *ngIf="currentListing.isSeller === true && currentListing.acceptingOffers === false" class="bold section">
            <hr />
            You are not accepting offers for this property. Change the listing to <a (click)=details()>"Accept Offers"</a>, and you may setup NEW open house.
        </div>
    </mat-card>
    <mat-progress-bar *ngIf="this.componentBusy  === true" mode="indeterminate"></mat-progress-bar>
    <mat-card class="section" *ngIf="currentListing && currentListing.id > 0">
        <mat-tab-group>
            <mat-tab label="Open House/Buyer Visiting {{this.viewings ? '[' + this.viewings.length + ']' : ''}}">
                <mat-card *ngIf="viewingSchedule.length > 10" class="section">
                    <mat-form-field>
                        <mat-select [(ngModel)]="selectedStatus">
                            <mat-label class="placeholder">Filter Status<i class="fa fa-question-circle-o warning-color" title="Select Status filter"></i></mat-label>
                            <mat-option *ngFor="let l of viewingStatus" [value]="l.id">{{l.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-card>
                <ul class="grid">
                    <li *ngFor="let viewing of viewings">
                        <figure>
                            <figcaption>
                                <div class="section">
                                    <i class="fa fa-calendar info"></i> {{utils.DisplayDateTime(viewing.isOpenHouse ? viewing.confirmedTime : viewing.requestedTime)}}
                                    <i class="fa fa-home float-right green" *ngIf="viewing.isOpenHouse" title="Open House"></i>
                                    <i class="fa fa-group float-right" *ngIf="!viewing.isOpenHouse" title="Visitor"></i>
                                </div>
                                <div class="section">
                                    <hr />
                                    <div *ngIf="!viewing.isOpenHouse"><label class="bold">{{'System.CurrentStatus' | translateTag}}</label> - {{viewing.statusName}}</div>
                                    <app-next-status-actions [actions]="viewing.nextSteps" (itemSelected)="changeStatus(viewing, $event)"></app-next-status-actions>
                                </div>
                            </figcaption>
                        </figure>
                    </li>
                </ul>
                <div *ngIf="(viewings == null || viewings.length == 0) && ( this.componentBusy === false && this.gc.busy === false )" class="section">
                    <ul>
                        <li>No one has submitted any request to visit your listed property</li>
                        <li>Currently there are no Open House scheduled</li>
                    </ul>
                    <div *ngIf="currentListing.isSeller === true && currentListing.acceptingOffers === false" class="bold section">
                        <hr />
                        You are not accepting offers for this property. Change the listing to <a (click)=details()>"Accept Offers"</a>, and you may setup NEW open house.
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Service Providers Visiting {{this.visits ? '[' + this.visits.length + ']' : ''}}">
                <div class="section">
                    <ng-template [ngIf]="(this.visits && this.visits.length > 0)" [ngIfElse]="noVisits">
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let visit of visits  | paginate: { itemsPerPage: itemsPerPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>{{visit.serviceType}}</mat-panel-title>
                                    <mat-panel-description style="display:initial">
                                        <span [ngClass]="{'warning' : !visit.approved, 'green' : visit.approved}" title="{{visit.approve ? '' : 'Pending Confirmation'}}">{{utils.DisplayDateTime(visit.visitDate)}}</span>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="section">
                                    <div>
                                        <label class="caption">Person Visting</label><label class="auto">{{visit.visitor}}</label>
                                    </div>
                                    <div>
                                        <span *ngIf="utils.isNullOrEmpty(visit?.notes) === false">
                                            <label class="caption">Request Notes</label> <label class="data-value" auto>{{visit.notes}}</label>
                                        </span>
                                        <span *ngIf="visit.canApprove && !visit.approved">
                                            <mat-form-field>
                                                <mat-label class="placeholder">Comments/Message <i class="fa fa-question-circle-o warning-color" title="Your message back to Service Provider"></i></mat-label>
                                                <input matInput appDisableAutofill [(ngModel)]="visit.responseNotes" maxlength="500" />
                                            </mat-form-field>
                                        </span>
                                        <span *ngIf="visit.canApprove && visit.approved">
                                            <label class="caption">Response Notes</label> <label class="data-value" auto>{{visit.responseNotes}}</label>
                                        </span>
                                        <app-next-status-actions [actions]="visit.nextSteps" (itemSelected)="changeVisitStatus(visit, $event)"></app-next-status-actions>
                                    </div>
                                </div>
                                <mat-action-row *ngIf="visits.length > 1">
                                    <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && visits.length > 1">Previous</button>
                                    <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < visits.length && visits.length > 1">Next</button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div style="text-align:center">
                            <hr />
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>
                    </ng-template>
                    <ng-template #noVisits>
                        <mat-card class="section" *ngIf="searched && (visits == null || visits.length === 0)">
                            There are no visit requests, for selected Property.
                        </mat-card>
                    </ng-template>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>