import { AppBase, RecordTimeStamp } from './base.model';
import { Role, UserProfileGroup } from './enums.component';
import { Utils } from '../common/utils';

export const defaultTimeout: number = 60000;

export class returnRoute{
    public routePath: string = '';
    public queryParams: any;

    constructor(){
        this.routePath = '';
    }
}

export class item extends AppBase {
    public id: number = 0;
    public name: string = '';
    public active: boolean = true;
    constructor(i?: number, n?: string, b: boolean = true) {
        super();
        this.id = (i == null ? 0 : i);
        this.name = (n == null ? '' : n);
        this.active = b === true ? true : false;
    }
}

export class searchItem extends item {
    public itemClass: string = '';
    constructor(i?: number, n?: string) {
        super();
        this.id = (i == null ? 0 : i);
        this.name = (n == null ? '' : n);
    }
}

export class propertyType extends item {
    public icon: string = '';
    constructor(i?: number, n?: string) {
        super();
        this.id = (i == null ? 0 : i);
        this.name = (n == null ? '' : n);
        this.icon = '';
    }
}

export class nextAction {
    public id: number;
    public toolTip: string;
    public actionArgument: string;
    public actionName: string;
    public displayName: string;
    public cssClass: string;
    public actionURL: string;
    public needsConfirmation: boolean;
    public confirmationMessage: string;
    public confirmationParameters: string[];
    public stateMachineAction: boolean;
    public causesValidation: boolean;
    public validationGroup: string;
}

export class Payment {
    name: string;
    ccNumber: number;
    ccYear: number;
    ccMonth: number;
    cvv: number;
    amount: number;
    authorizationID: string;
    authorizationCode: string;
    constructor() {
        this.ccMonth = this.ccNumber = this.ccYear = this.amount = this.cvv = 0;
        this.name = this.authorizationID = this.authorizationCode = '';
    }
    get IsComplete() {
        if (this.amount > 0)
            return this.name != null && this.name.trim() !== '' && this.ccMonth > 0 && this.ccNumber > 0 && this.ccYear > 0 && this.cvv > 0;
        else
            return true;
    }
}

export class downloadableContent {
    content: any = null;
    name: string = '';
    mime: string = '';
}

export class mediaItem {
    title: string = '';
    description: string = '';
    type: string = 'url';
    content: downloadableContent;
    url: string = '';
    constructor() {
        this.content = new downloadableContent();
    }
}

export class entityRoles extends RecordTimeStamp{
    public id: number = 0;
    public pid: number = 0;
    public firstName: string = '';
    public middleName: string = '';
    public lastName: string = '';
    public contactNumber: string = '';
    public entityRoleID: number = 0;
    public roleID: Role = Role.NotSet;
    public roleName: string = '';
    public isREAgent: boolean = false;
    public hasValidCertification: boolean = false;
    public poaPresent: boolean  = false;
    public canDeclinePOA: boolean = false;
    public canRevokePOA: boolean = false;
    public canVerifyPOA: boolean = false;
    public canUploadPOA: boolean = false;
    public canGivePOA: boolean = false;
    public company: string = '';
    public ownershipStake: number = 0;
    public accepted: boolean = false;
    public canAcceptAgentTerms: boolean = false;
    public hasAcceptedAgentTerms: boolean = false;
    public canRepresentCompany: boolean = false;
    public canDelete: boolean = false;
    public canAcceptPartnerShip: boolean = false;
    public canAssign: boolean = false;
    public isDCPresent: boolean = false;
    public canVerifyDC: boolean = false;

    constructor() {
        super();
    }

    public get identityComplete(): boolean {
        return !Utils.isNullOrEmpty(this.firstName) && !Utils.isNullOrEmpty(this.lastName);
    }
    public get fullName() {
        return this.firstName + ' ' + this.lastName;
    }
}

export class treeNode extends AppBase  {
    public id: number = 0 ;
    public  name: string = '';
    public tip: string = '';
    public children: treeNode[] = new Array<treeNode>();
    public parentID: number = 0;
}

export class AssetMin {
    id: number = 0;
    name: string = '';
    title: string = '';
}

export class asset extends RecordTimeStamp {
    id: number = 0;
    name: string = '';
    type: number = 0;
    title: string = '';
    content: string = '';
    helpContent: string = '';
    secured: boolean = false;
    securedByLogin: boolean = false;
    description: string = '';
    previous: AssetMin[] = [];
    next: AssetMin[] = [];
    otherTitles: string[] = [];
    keywords: string = '';
    url: string = '';
    isStatic: boolean = false;
    status: number = 0;
    adEnabled: boolean = false;
    hideInHelpTree: boolean = false;
    rateLevel: number = 0;
    helpCategoryID: number = 0;
}

export class Profile {
    pg: UserProfileGroup = UserProfileGroup.Unknown;
    bc: number = 0;
    sc: number = 0;
    pc: number = 0;
}

export class LocalKVP {
    key: string;
    value: any;
    constructor(sKey: string, oValue: any) {
        this.key = sKey;
        this.value = oValue;
    }
}

export class LocalCache {
    items: LocalKVP[] = [];
    add(o: LocalKVP) {
        if (o != null)
            this.items.push(o);
    }
    remove(o: any) {
        if (o != null && o.key != null)
            this.items = this.items.filter((i) => i.key !== o.key);
        else if (o != null && typeof (o) === 'string')
            this.items = this.items.filter((i) => i.key !== o);
    }
    exists(o: any) {
        if (o != null && o.key != null)
            return this.items.find((i) => i.key === o.key) != null;
        else if (o != null && typeof (o) === 'string')
            return this.items.filter((i) => i.key === o) != null;
        else
            return false;
    }
    getValue(sKey: string) {
        const _v = this.items.find((i) => i.key === sKey);
        if (_v != null)
            return _v.value;
        else
            return null;
    }
}
