import { Injectable, EventEmitter } from '@angular/core';
declare var $: any;
import { appEnvironment } from '../environments/environment';

import { Role, Session, item, lien, escrow, Offer, listing, BrainTree, MessageNotificationType, WorkOrder } from './models';
import { MessageNotification } from './models/messageNotification.model';
import { appSnackBarDisplay, appSnackBarWarningTop } from './models/constants';
import { ListingService } from './listing/listing.service';
import { EscrowService } from './escrow/escrow.service';
import { OfferService } from './offer/offer.service';
import { clearSession } from './common/sessionService';
import { rolePosition, Utils } from './common/utils';

@Injectable({ providedIn: 'root' })
export class GlobalComponent {

    public static _cv: boolean = false;
    public currentPageAssetID: number = 0;
    public loginChanged: EventEmitter<any> = new EventEmitter<any>();
    public langChanged: EventEmitter<string> = new EventEmitter<string>();

    public onMessageNotify: EventEmitter<MessageNotification> = new EventEmitter<MessageNotification>();
    public onError: EventEmitter<any> = new EventEmitter<any>();
    public nowBusy: EventEmitter<any> = new EventEmitter<any>();

    public lienChanged: EventEmitter<number> = new EventEmitter<number>();
    public escrowChanged: EventEmitter<number> = new EventEmitter<number>();
    public roleChanged: EventEmitter<Role> = new EventEmitter<Role>();

    public offerChanged: EventEmitter<number> = new EventEmitter<number>();
    public listingChanged: EventEmitter<number> = new EventEmitter<number>();

    public signalRBroadcast: EventEmitter<any> = new EventEmitter<any>();

    public saveVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();
    public saveClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    public onModelChanges: EventEmitter<any> = new EventEmitter<any>();
    public onCacheRefreshed: EventEmitter<any> = new EventEmitter<any>();

    public mobileScreen: boolean = false;
    public loggedIn: boolean = false;
    // public admin: boolean = false;
    // public offsetMinutesFromServer: number = 0;

    public listingService: ListingService;
    public offerService: OfferService;
    public escrowService: EscrowService;
    public currentWorkOrder: WorkOrder;
    public myWOListings: any[] = []; // SP Listings List from One Page to Another

    user: Session = new Session();

    myScripts = [
        { name: 'jquerySignalR', src: appEnvironment.jquerySignalR }
        , { name: 'signalRProxy', src: appEnvironment.signalRProxy }
    ];
    myListings: any[] = [];
    myListingsWelcome: any[] = [];

    //#region SignalR Hub Connection
    hubConnectionID: string = '';
    hubConnection: any;
    hubProxy: any;
    //#region

    public langTags: any;
    public currentLang: string = 'en';

    matDialogFullHeight: string = '400px';
    savedQueryParams: any[] = [];

    readonly stealthMode: boolean = appEnvironment.stealthMode === true && appEnvironment.production === true;

    currentListing: listing = new listing();
    currentLien: lien = new lien();
    busyBindingLien: number = 0;
    currentEscrow: escrow = new escrow();
    currentOffer: Offer = new Offer();

    countries: item[] = new Array<item>();
    escrowID: number = 0; lienID: number = 0; listingID: number = 0;
    busy: boolean = false; componentBusy: boolean = false;
    role: Role = Role.NotSet;
    offerID: number = 0;

    private _objects: any = null;

    constructor() {
        this.onError.subscribe((e) => {
            if (e && (e.status === '400' || e.status === '401' || e.status === '403' || e.status === '404'
                        || e.status === 400 || e.status === 401 || e.status === 403 || e.status === 404)) {

                this.loggedIn = (this.user?.details?.PID ?? 0) > 0;
                if (this.loggedIn === true)
                    this.showSnackBar('You connection to server was lost. Please login again and retry the steps. If the issue persists, Please contact support !!!!', appSnackBarWarningTop);

                sessionStorage.removeItem('sessionUser');
                sessionStorage.clear();
                this.user = null;
                this.loginChanged.emit(null);
                return;
            }
        });
    }

    setCountries(v: item[]) { if (v) this.countries = v; else this.countries = new Array<item>(); }

    setBusy(v: boolean) {
        this.busy = v; this.nowBusy.emit({ busy: v, modal: true});
    }

    setComponentBusy(v: boolean) {
        this.componentBusy = v; this.nowBusy.emit({ busy: v, modal: false });
    }

    setLienID(v: number) {
        if (v == null || typeof v !== 'number' || isNaN(v) || v < 0) v = 0;
        const _changed = this.lienID !== v;
        this.lienID = v;
        if (_changed) {
            this.lienChanged.emit(v ? v : null);
        }
    }

    setBusyBindingLien(v: number) { if (v == null || v < 0 ) v = 0; this.busyBindingLien = v; }

    setCurrentLien(v: lien) {
        this.currentLien = v;
        const _changed = v == null || (this.currentLien == null && v != null) || this.lienID !== v.id;
        this.lienID = v == null ? 0 : v.id;

        if (_changed) {
            this.lienChanged.emit(v ? v.id : null);
            this.setListingID((v && v.propertyID) ? v.propertyID : 0);
        }
    }

    public setEscrowID(v: number) {
        if (v == null || typeof v !== 'number' || isNaN(v) || v < 0) v = 0;
        const _changed: boolean = this.escrowID !== v;
        this.escrowID = v;

        if (_changed) {
            this.escrowChanged.emit(v ? v : null);
        }
    }

    setCurrentEscrow(v: escrow, bForceChange: boolean = false) {
        let _changed: boolean = false;

        if (v == null)
            v = new escrow();
        else
            _changed = bForceChange || ( this.escrowID !== v.id && (this.escrowID > 0 || v.id > 0));

        this.currentEscrow = v;
        this.escrowID = v.id;

        if (_changed) {
            this.escrowChanged.emit(v ? v.id : null);
            if (v.myRole)
                this.role = v.myRole;
            else
                this.role = Role.NotSet;

            this.setListingID((v && v.listingID) ? v.listingID : 0);
        }
    }

    setRole(v: Role) {

        let _changed: boolean = false;

        if (v == null)
            v = Role.NotSet;
        else
            _changed = this.role !== v;

        this.role = v;
        if (_changed)
            this.roleChanged.emit(v);
    }


    hasRole(role: rolePosition) {
        const _iaa = Utils.getInt64Bytes(this.user?.details?.roles ?? 0);
        if (_iaa != null && Array.isArray(_iaa) && _iaa.length >= (role as number)) {
            return _iaa[(role as number)];
        } else
            return false;
    }

    setListingID(v: number) {
        if (v == null || typeof v !== 'number' || isNaN(v) || v < 0) v = 0;
        const _changed = this.listingID !== v;
        this.listingID = v;
        if (_changed) {
            this.listingChanged.emit(v);
        }
    }

    setCurrentListing(v: listing, bForceChange: boolean = false) {

        let _changed: boolean = false;

        if (v == null)
            v = new listing();
        else
            _changed = bForceChange || (v.id !== this.listingID);

        this.currentListing = v;
        this.listingID = v.id;

        if (_changed) {
            if (this.currentListing.myRole)
                this.role = this.currentOffer.myRole;
            else
                this.role = Role.NotSet;
            this.listingChanged.emit(this.listingID);
        }
    }

    setuser(v: Session) {
        const _logout = (v == null);
        if (v == null) {
            v = new Session();
            if (this.user != null) {
                v = this.user;
                v.details = null;
            }
        }

        this.user = v;

        if (appEnvironment.production === true) {
            this.loggedIn = v.details != null && v.details.PID != null && v.details.PID > 0;
        } else {

            if (v.details && v.details['IsSystemAdmin'])
                delete v.details['IsSystemAdmin'];

            sessionStorage.setItem('sessionUser', JSON.stringify(v));
            if (_logout) {
                this.loggedIn = false;
            } else {
                this.loggedIn = v.details != null && v.details.PID != null && v.details.PID > 0;
            }
        }
    }

    setOfferID(v: number) {
        if (v == null || typeof v !== 'number' || isNaN(v) || v < 0) v = 0;
        const _changed = this.offerID !== v;
        this.offerID = v;
        if (_changed) {
            this.offerChanged.emit(v);
        }
    }
    setCurrentOffer(v: Offer, bForceChange: boolean = false) {

        let _changed: boolean = false;

        if (v == null) {
            v = new Offer();
            this.currentListing = this.currentEscrow = null;
        } else
            _changed = bForceChange || (v.id !== this.offerID);

        this.currentOffer = v;

        this.offerID = v ? v.id : 0;

        if (_changed) {
            if (this.currentOffer.myRole)
                this.role = this.currentOffer.myRole;
            else
                this.role = Role.NotSet;
            this.offerChanged.emit(this.offerID);
            this.setListingID((v && v.listingID) ? v.listingID : 0);
        }
    }

    geti18nTag(sTag: string = '') {
        let _t: string = '';

        if (sTag != null && sTag.trim() !== '' && this.langTags) {
            let _ta = sTag.split('.');
            // const _src = this.langTags;
            let _v = this.langTags;
            while (_ta.length >= 2) {
                if (_v[_ta[0]] != null) {
                    _v = _v[_ta[0]];
                    _ta = _ta.splice(1);
                } else
                    break;
            }

            if (_v != null && _v[_ta[0]])
                _t = _v[_ta[0]];
        }

        if (_t == null || typeof (_t) === 'undefined' || (typeof (_t) === 'string' && _t.trim() === ''))
            _t = sTag;

        return _t;
    }

    incrementMessageCount() {
        if (this.loggedIn)
            this.user.details.MessagesSinceLastLogin++;
    }

    clear() {
        this.lienID = this.escrowID = this.listingID = this.offerID = 0;
        this.setCurrentLien(null);
        this.setCurrentEscrow(null);
        this.setCurrentOffer(null);
        this.setCurrentListing(null);
        this._objects = null;
        this.myListings = [];
        this.myListingsWelcome = [];
    }

    clearAppSession() {
        this.clear();
        this.loggedIn = false;
        this.user = new Session();
        clearSession();
    }

    setContentEdit(bOption: boolean) {
        localStorage.setItem('editContent', bOption.toString());
    }

    editContent() {
        const _o = localStorage.getItem('editContent');
        if (_o === 'true')
            return true;
        else
            return false;
    }

    ccGateway(): BrainTree {
        const _cc = sessionStorage.getItem('ccGateway');
        if (_cc != null && _cc.toString().trim() !== '') {
            return JSON.parse(_cc) as BrainTree;
        } else
            return new BrainTree();
    }

    public IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public showSnackBar(sMessage: string = '', oData: any = null) {
        this.onMessageNotify.emit({ message: sMessage, messageType: MessageNotificationType.Message, data: oData != null ? oData : appSnackBarDisplay });
        // if (this._snackBar)
        //    this._snackBar.open(sMessage == null || sMessage.toString().trim() === '' ? 'Changes saved.' : sMessage, '', oData != null ? oData : appSnackBarDisplay);
    }

    public getObject(sName: string) {
        if (this._objects) {
            if (this._objects[sName])
                return this._objects[sName];
        }
        return null;
    }

    public setObject(sName: string, oValue: any) {
        if (this._objects == null)
            this._objects = {};
        this._objects[sName] = oValue;
    }

    public restoreuser() {
        if (appEnvironment.production === true) {
            if (this.user == null) this.user = new Session();
        } else {
            if (this.user == null || this.user.sessionID == null || this.user.sessionID.trim() === '') {
                const _s = sessionStorage.getItem('sessionUser');
                if (_s == null || _s.trim() === '' || !this.IsJsonString(_s))
                    this.user = new Session();
                else {
                    this.user = JSON.parse(sessionStorage.getItem('sessionUser'));
                    this.loginChanged.emit(this.user.sessionID);
                }
            }
        }

        this.loggedIn = (this.user?.details?.PID ?? 0) > 0;
    }
}
