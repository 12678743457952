import { BaseService } from './base.service';
import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }
}
