<div class="{{customContent ? '' : 'appContent'}}">
    <h2 class="pageHeader" *ngIf="!customContent">Unsubscribe</h2>
    <mat-card *ngIf="!submitted">
        <div *ngIf="!customContent">
            <p>We make best of efforts to not to spam our valuable customers. In future we will refine our mail list and provide you better control on the content of communication(s) you prefer to receive</p>
            <p>We are sorry to see you leave our mailing list, and honor your decision. Please enter your email address and click on submit.</p>
            <p>If you change your mind and want to recieve new offerings and news about {{this.myBRAND}}. Please <a [routerLink]="'/account/login'">sign in</a> your account and goto your account <a [routerLink]="'/account/profile'">profile page</a> and update your preference.</p>
        </div>
        <mat-form-field class="first">
            <mat-label class="placeholder">Email Address</mat-label>
            <input matInput maxlength="100" [(ngModel)]="email" />
        </mat-form-field>
        <hr />
        <a class="btn btn-primary" (click)="onSubmit()" *ngIf="!utils.isNullOrEmpty(email)">Submit</a>
    </mat-card>
    <mat-card *ngIf="submitted">
        <p>Your request has been recorded. Please allow us 24-48 hours to update all our affiliate systems.</p>
    </mat-card>
</div>