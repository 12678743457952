<div mat-dialog-title>
    <h4>{{paymentData.header}}</h4>
    <a class="close" title="Close" (click)="close()"><i class="fa fa-times"></i></a>
</div>
<div mat-dialog-content>
    <mat-card>
        <mat-card> {{paymentData.title}} </mat-card>
        <hr />
        <form #btPayment="ngForm" (ngSubmit)="btPayment.valid && paymentSubmit()">
            <div>
                <mat-form-field class="large">
                    <input matInput placeholder="First Name" [(ngModel)]="payment3DSInfo.billingAddress.givenName"
                           name="firstName" required />
                </mat-form-field>
                <mat-form-field class="large">
                    <input matInput placeholder="Last Name" [(ngModel)]="payment3DSInfo.billingAddress.surname"
                           name="lastName" required />
                </mat-form-field>
            </div>
            <div #dropincontainer id="dropincontainer"></div>
            <hr/>
            <div>
                <button type="submit" class="btn btn-primary inline">Submit</button>
            </div>
        </form>
    </mat-card>
</div>
