<mat-form-field class="{{itemClass}}" appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <input id="myID{{id}}" matInput appDisableAutofill [matAutocomplete]="auto" [formControl]="itemCtrl" style="width:calc(100% - 24px)"
           *ngIf="showResultsGrid === false"
           [ngClass]="{'missing': !allowNew && ((required && (this.value == null || this.value == 0)) || (this.value==0 && !utils.isNullOrEmpty(this.text))) }" (change)="onValueChange()" />
    <input id="myID{{id}}" matInput appDisableAutofill [formControl]="itemCtrl" style="width:calc(100% - 24px)" *ngIf="showResultsGrid === true"
           [ngClass]="{'missing': !allowNew && ((required && (this.value == null || this.value == 0)) || (this.value==0 && !utils.isNullOrEmpty(this.text))) }" (change)="onValueChange()" />
    <i *ngIf=loading class="fa fa-refresh fa-spin fa-fw" aria-hidden="true"></i>
    <mat-autocomplete #auto="matAutocomplete" *ngIf="showResultsGrid === false">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item.name" (onSelectionChange)="onItemSelected(item)" class="auto {{item.itemClass}}">{{item.name }}</mat-option>
    </mat-autocomplete>
</mat-form-field>
<div class="section" *ngIf="showResultsGrid === true">
    <ng-template [ngIf]="(this.resultItems && this.resultItems.length > 0)" [ngIfElse]="noResults">
        <mat-expansion-panel [expanded]="true"   #resultPanel id="resultPanel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3 class="nomargin">Search Results</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let item of resultItems | paginate: { itemsPerPage: 10 , currentPage: pageNumber }; " class="section">
                <div class="searchResultHeader"><a  class="link {{item.itemClass}}" (click)="onItemSelected(item)">{{item.name}}</a></div>
                <div [innerHTML]="item.content | safeHtml" class="searchResultText"></div>
            </div>
            <div style="text-align:center">
                <hr />
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </mat-expansion-panel>
    </ng-template>
    <ng-template #noCertifications>
        <div class="section">
            No Results. Please refine your search criteria.
        </div>
    </ng-template>
</div>