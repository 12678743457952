import { Pipe, PipeTransform } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Utils } from '../utils';

@Pipe({ name: 'dataTimeStamp' })
export class DataTimeStampPipe extends BaseComponent implements PipeTransform {

    constructor() {
        super('', null, null);
    }

    transform(item: any) {
        if (item == null) return '';

        let timeStamp: string = '';
        const lblOnTag = this.geti18nTag('System.TimeStampOn');
        const lblCreateDate = this.geti18nTag('System.CreatedDate');
        const lblCreatedBy = this.geti18nTag('System.CreatedBy');
        const lblLastUpdateDate = this.geti18nTag('System.LastUpdatedDate');
        const lblLastUpdatedBy = this.geti18nTag('System.LastUpdatedBy');

        if (item.CreatedByName !== undefined || item.CreatedBy !== undefined || item.LastUpdatedBy !== undefined ||
            item.LastUpdatedByName !== undefined) {
            if (item.CreatedByName || item.CreatedBy)
                timeStamp += lblCreatedBy;

            if (item.CreatedByPersonName && item.CreatedByPersonName.length > 2)
                timeStamp += ' ' + item.CreatedByPersonName + (item.CreatedByName ? ' (' + item.CreatedByName + ')' : ' ');
            else if (item.CreatedByName)
                timeStamp += ' ' + item.CreatedByName;
            else if (item.CreatedBy)
                timeStamp += ' ' + item.CreatedBy;
            else
                timeStamp += ' ';

            timeStamp += ' ' + (item.CreateDate ? lblOnTag + ' ' + Utils.DisplayLocalDateTime(item.CreateDate) : '');
            if (item.LastUpdatedByName || item.LastUpdatedBy)
                timeStamp += '  ' + lblLastUpdatedBy;

            if (item.LastUpdatedByPersonName && item.LastUpdatedByPersonName.length > 2)
                timeStamp += ' ' + item.LastUpdatedByPersonName + (item.LastUpdatedByName ? ' (' + item.LastUpdatedByName + ')' : ' ');
            else if (item.LastUpdatedByName)
                timeStamp += ' ' + item.LastUpdatedByName;
            else if (item.LastUpdatedBy)
                timeStamp += ' ' + item.LastUpdatedBy;
            else
                timeStamp += ' ';


            const updateDate = Utils.DisplayLocalDateTime(item.LastUpdateDate);
            timeStamp += ' ' + ((item.LastUpdateDate && updateDate.length > 5) ? lblOnTag + ' ' + updateDate : '');
        } else if (item.createdByName !== undefined || item.createdBy !== undefined ||
            item.lastUpdatedBy !== undefined || item.lastUpdatedByName !== undefined) {

            if (item.createdByName || item.createdBy)
                timeStamp += lblCreatedBy;

            if (item.createdByPersonName && item.createdByPersonName.length > 2)
                timeStamp += ' ' + item.createdByPersonName + (item.createdByName ? ' (' + item.createdByName + ')' : ' ');
            else if (item.createdByName)
                timeStamp += ' ' + item.createdByName;
            else if (item.createdBy)
                timeStamp += ' ' + item.createdBy;
            else
                timeStamp += ' ';

            timeStamp += ' ' + (item.createDate ? lblOnTag + ' ' + Utils.DisplayLocalDateTime(item.createDate) : '');
            if (item.lastUpdatedByName || item.lastUpdatedBy)
                timeStamp += ' ' + lblCreatedBy;
            if (item.lastUpdatedByPersonName && item.lastUpdatedByPersonName.length > 2)
                timeStamp += ' ' + item.lastUpdatedByPersonName + (item.lastUpdatedByName ? ' (' + item.lastUpdatedByName + ')' : ' ');
            else if (item.lastUpdatedByName)
                timeStamp += ' ' + item.lastUpdatedByName;
            else if (item.lastUpdatedBy)
                timeStamp += ' ' + item.lastUpdatedBy;
            else
                timeStamp += ' ';


            const updateDate = Utils.DisplayLocalDateTime(item.lastUpdateDate);
            timeStamp += ' ' + ((item.lastUpdateDate && updateDate.length > 5) ? lblOnTag + ' ' + updateDate : '');
        }
        return timeStamp;
    }
}
