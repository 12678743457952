import { Router} from '@angular/router';
import { BaseService } from './base.service';
import { DataService } from '../../util/APICaller.component';

// tslint:disable-next-line: import-blacklist
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GeographicService extends BaseService {
    private ds: DataService;
    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    getCountries() {
        const _data = { data: [{ id: 840, name: 'United States' }] };
        return of(_data);
        // NOTE :  Commented until Launched OUTSIDE US.
        // const data = null;
        // return this.ds.APIPost('/country/countries', data, this.requestingModule);
    }

    getStates(iCountryID: number): Observable<any> {
        const _s = localStorage.getItem('appStates');
        if (_s != null && this.IsJsonString(_s)) {
            const _d = JSON.parse(_s);
            if (_d != null && _d.length > 0)
                return of({ data: _d });
        }

        const data = { isoCountryCode: 840 }; // NOTE : Hard Coded until Launched OUTSIDE US.
        return this.ds.APIPost('/geographic/country/states', data, this.requestingModule).pipe(
            map((res: any) => {
                const _data = res.data ? res.data : null;
                if (_data != null)
                    localStorage.setItem('appStates', JSON.stringify(_data));
                else
                    localStorage.removeItem('appStates');
                return res;
            }),
        );
    }

    getCounties(iCountryID: number, iStateID: number) {
        const data = { isoCountryCode: iCountryID, stateID: iStateID };
        return this.ds.APIPost('/geographic/country/counties', data, this.requestingModule);
    }
    getCities(iCountryID: number, iStateID: number) {
        const data = { isoCountryCode: iCountryID, stateID: iStateID };
        return this.ds.APIPost('/geographic/country/cities', data, this.requestingModule, 180000);
    }
    saveTaxWithholder(oData: any) {
        const data = oData;
        return this.ds.APIPost('/geographic/saveTaxWithholder', data, this.requestingModule);
    }
}
