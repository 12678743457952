export const appEnvironment = {
    production: false
    , stealthMode : false
    , appBRAND: 'IAMOREA'
    , apiEndPoint: 'https://apiqa.iamorea.com'
    , apiVersion: '1.0'

    , signalRProxy: 'https://apiqa.iamorea.com/signalr/hubs'
    , signalREndPoint: 'https://apiqa.iamorea.com/signalr'
    , jquerySignalR: 'https://apiqa.iamorea.com/scripts/jquery.signalR-2.2.2.min.js'

    , applicationReleaseVersion: '0.1.0.20200320073529'

    , applicationBaseName: 'iamorea'
    , applicationID: 'iamorea-dev'
    , apiHostPrefix: 'api'
    , apiDomain: '.iamorea.com'
    , fallBackAPIEndPoint: 'https://apiqa.iamorea.com'

    , maxUploadFileSizeBytes: 10485760
    , defaultDateFormat: 'MM/DD/YYYY'

    , autoSaveTime: 20000
    , delayedNGInit: 5000

    , editOfferURL: '/offer/start'

    , lenderServiceTypeID: 8
    , escrowServiceTypeID: 7
    , realEstateAdvisorServiceTypeID: 9

    , defaultStaffRoleID: 2048
    , defaultPropertyTypeID: 2
    , defaultPropertyProductID: 1

    , defaultISOCurrencyCode: 'USD'
    , defaultISOLanguageCode: 'en-us'
    , defaultISOCountryCode: 840

    , braintreeTokenizationKey: 'sandbox_fdqq32cp_hs23g6yyq6yzrcj9'
    , mapboxAccessToken: 'pk.eyJ1IjoiY211ZGhhciIsImEiOiJja2QzZndqamgwdzByMnRudXRkMjV4YzduIn0.u2Y-mOm8dL9B0N6ofXaq4w'
    , googleReCaptcha: '6LcjZ-0ZAAAAAKPZKHby_JukyrPZD55QFw7w-vGp'
    , reCaptchaScript: 'https://www.google.com/recaptcha/api.js?render=6LcjZ-0ZAAAAAKPZKHby_JukyrPZD55QFw7w-vGp'
    , CARFormName: 'CAR Purchase Agreement'

    , sessionTimeout: 20
    , timeoutWarningInMinutes: 2
    , sessionTrackingEnabled: false

    , buyerHelpCategoryID: 4279
    , sellerHelpCategoryID: 4280
    , spHelpCategoryID: 4278
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// MapBox username - cmudhar,  email - charanjitsmudhar@gmail.com
