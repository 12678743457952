<div *ngIf="showHeader">
    <mat-form-field *ngIf="listings && listings.length > 0">
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        Closing Date - {{currentEscrow.closingDate}}
        <a *ngIf="currentEscrow.isValid" (click)="showCIC()">Compliance Items</a>
        <a *ngIf="currentEscrow.isValid" title='Purchase Agreement' (click)="showPA()"><span class='fa-stack'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-list-alt fa-stack-1x fa-inverse'></i></span></a>
    </div>
</div>
<div *ngIf="showHeader">
    <mat-form-field *ngIf="escrows && escrows.length > 0">
        <mat-select [(ngModel)]="escrowID">
            <mat-label class="placeholder">Escrow</mat-label>
            <mat-option *ngFor="let l of escrows" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<mat-card *ngIf="currentEscrow && currentEscrow.id > 0">
    <mat-form-field *ngIf="escrowID == 0">
        <mat-select [(ngModel)]="role">
            <mat-label class="placeholder">Taxes for Role</mat-label>
            <mat-option *ngFor="let l of currentEscrow.settlementRoles" [value]="l.id">{{l.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-card>
<mat-card *ngIf="currentEscrow && currentEscrow.id > 0">
    <ngx-datatable class="material" [loadingIndicator]="binding"
                   [footerHeight]="0" [rowHeight]="'auto'"
                   [rows]="taxes" [columnMode]="'flex'"
                   [limit]="50" [messages]="messages">
        <ngx-datatable-column name="Paid By"  [flexGrow]="2">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                {{row.paidByDescription}}&nbsp;<i class="fa fa-sticky-note" title="{{row.notes}}" *ngIf="!utils.isNullOrEmpty(row.notes)"></i>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Authority"  [flexGrow]="2">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">{{row.authority}}</ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Amount"  [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">{{FormatNumberToCurrency(row.amount)}}</ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column  [flexGrow]="1">
            <ng-template ngx-datatable-header-template><a (click)="addTax()" title="Add additional tax" *ngIf="!busy && currentEscrow.isEscrowStaff"><i class="fa fa-plus-circle float-right green"></i></a></ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"><a (click)="removeTax(row.id)" *ngIf="!busy && row.canDelete"><i class="fa fa-trash danger"></i></a></ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</mat-card>