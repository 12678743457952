import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';

import { PaymentUIData, Payment3DSInformation, BaseResponse } from '../../models';
import { BaseDialog } from '../../base.dialog';
import { ProductService } from '../service';
import { DataService } from '../../util/APICaller.component';
import { Utils } from '../utils';

// tslint:disable-next-line: no-unused-expression
'use strict';
declare var braintree: any;

@Component({
    selector: 'app-braintree-dropin',
    templateUrl: './brainTreeDropIn.dialog.html'
})

export class BrainTreeDialog extends BaseDialog implements OnInit {

    payment3DSInfo: Payment3DSInformation;
    preselectVaultedPaymentMethod: boolean = false;
    threeDSecureInstance: any;
    paymentData: PaymentUIData;

    // private CLIENT_AUTHORIZATION: string;
    private clientInstance: any;
    private threeDSEnabled: boolean = true;

    constructor(dataservice: DataService, private r: Router, dialogRef: MatDialogRef<BrainTreeDialog>, @Inject(MAT_DIALOG_DATA) public data: PaymentUIData) {
        super('ucBrainTreeDialog', dataservice, r, dialogRef, data);

        this.paymentData = data;
        if (this.paymentData.payment3DSInfo)
            this.payment3DSInfo = this.paymentData.payment3DSInfo;

        this.preselectVaultedPaymentMethod = false; // this.paymentData.preselectVaultedPaymentMethod;
        this.threeDSEnabled = false; // this.gc.threeDSEnabled) {

        if (this.dialogRef === undefined) {
            alert('Callback NOT DEFINED during DESIGN');
            this.close();
        }

        if (this.paymentData.amount <= 0 || Utils.isNullOrEmpty(this.paymentData.currency)) {
            setTimeout(() => { this.showError('Invalid payment amount and/or currency.'); this.close(); }, 1000);
        }

        if (Utils.isNullOrEmpty(this.paymentData.title))
            this.paymentData.title = 'I agree to pay ' + this.FormatNumberToCurrency(this.paymentData.amount) + '  to  ' + this.myBRAND;
    }

    ngOnInit() {

        if (!this.paymentData)
            this.close();

        const isoCode: string = 'en-us'; // this.gc.convertLangToISOStandard(this.gc.ISOLanguageCode);
        let account = this.gc.ccGateway().account;
        if (account == null || account.toString().trim() === '') {
            account = ''; // TODO GET from user/ccGatewayAccount API
        }

        let response: BaseResponse;
        (new ProductService(this.dataservice, this.r, this.myResourceCategory)).ccGateway().subscribe(
                (data) => { response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (response && response.data && braintree.dropin) {
                        braintree.dropin.create({
                            authorization: response.data.token // this.CLIENT_AUTHORIZATION
                            , container: '#dropincontainer'
                            , threeDSecure: this.threeDSEnabled
                            , preselectVaultedPaymentMethod: this.preselectVaultedPaymentMethod
                            , locale: isoCode
                            , merchant_account_id: response.data.account
                        }, (e: any, c: any) => {
                            this.onClientCreation(e, c);
                        });
                    } else
                        this.close();
                });
    }

    onClientCreation(err: any, instance: any) {
        if (err) {
            this.close();
        } else if (instance) {
            this.clientInstance = instance;

            if (this.threeDSEnabled && braintree.threeDSecure) {
                braintree.threeDSecure.create({
                    version: 2, // Will use 3DS 2 whenever possible
                    client: this.clientInstance
                }, function (threeDSecureErr, threeDSecureInstance) {
                    if (threeDSecureErr) {
                        // Handle error in 3D Secure component creation
                        return;
                    }
                    this.threeDSecureInstance = threeDSecureInstance;
                });
            }
        }
    }

    paymentSubmit() {
        if (this.payment3DSInfo.billingAddress.givenName === '' || this.payment3DSInfo.billingAddress.givenName === '') {
            // TODO this.ShowError(this.getTag('lblInvalidInput'));
            return;
        }

        if (this.clientInstance) {
            if (this.threeDSEnabled) {
                if (this.threeDSecureInstance) {
                    this.threeDSecureInstance.verifyCard({ threeDSecure: this.payment3DSInfo }, function(err, response) {
                        if (err) {
                            this.ShowError(err.message);
                        } else
                            this.clientInstance.requestPaymentMethod({ threeDSecure: this.payment3DSInfo }, (requestPaymentMethodErr: any, payload: any) => { this.onPaymentMethodSuccess(requestPaymentMethodErr, payload); });
                    });
                } else {
                    this.clientInstance.requestPaymentMethod({ threeDSecure: this.payment3DSInfo }, (requestPaymentMethodErr: any, payload: any) => { this.onPaymentMethodSuccess(requestPaymentMethodErr, payload); });
                }
            } else
                this.clientInstance.requestPaymentMethod((requestPaymentMethodErr: any, payload: any) => { this.onPaymentMethodSuccess(requestPaymentMethodErr, payload); });
        }
    }

    onPaymentMethodSuccess(err: any, payload: any) {
        if (err) {
            // TODO this.ShowError(err.message);
        } else {
            if (payload && payload.nonce) {
                this.paymentData.paymentNonce = payload.nonce;
                this.dialogRef.close(this.paymentData);
            }
        }
    }

    close() {
        this.dialogRef.close();
    }
}
