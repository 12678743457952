import { RecordTimeStamp } from './base.model';
import { Utils } from '../common/utils';
import { SimpleChanges } from '@angular/core';

export enum OwnerShipType {
    NotSpecified = 0,
    Rent = 1,
    Own = 2
}
export enum AddressType {
    NotSet = 0,
    Physical = 2,
    MailingOrShipping = 3,
    Billing = 4,
    Legal = 5,
    Tax = 6,
    Other = -7,
    Listing = -8,
    Previous = -9
}
export enum AddressEntityType {
    Person = 1,
    ServiceProvider = 2,
    Listing = 3,
    LoanProperty = 4,
    GeographicGovernmentEntity = 5,
    Place = 6
}

export class Address extends RecordTimeStamp {

    public zoomLevel: number;

    // #region Private Variables
    protected _entityAddressID: number = 0; protected _entityPrimaryKeyID: number = 0;
    protected _entityType: AddressEntityType;

    private _streetNumber: number; private _cityID: number; private _stateID: number; private _countyID: number; private _isoCountryCode: number;
    private _streetName: string = ''; private _streetSuffix: string = ''; private _streetAdditionalInformation: string = ''; private _postalCode: string = '';
    private _boxNumber: string = ''; private _unitNumber: string = ''; private _city: string = '';
    private _state: string = ''; private _stateCode: string = ''; private _county: string = ''; private _country: string = ''; private _countryCode: string = '';
    private _longitude: number; private _latitude: number;
    private _mailable: boolean; private _doNotMail: boolean;

    private _addressType: AddressType = AddressType.NotSet;
    private _startDate: Date; private _endDate: Date;
    private _ownership: OwnerShipType = OwnerShipType.NotSpecified;
    // #endregion

    constructor() {
        super();
        this._entityAddressID = this._streetNumber = this._cityID = this._stateID = this._isoCountryCode = this._latitude = this._longitude = 0;
        this._mailable = true;
        this._doNotMail = false;
    }

    public get EntityAddressID(): number {
        return this._entityAddressID;
    }
    public set EntityAddressID(value: number) {
        this.id = this._entityAddressID = value;
    }

    public get StreetName(): string {
        return this._streetName;
    }
    public set StreetName(value: string) {
        if (value == null) value = '';
        if (this._streetName !== value) {
            this.isDirty = true;
            this._longitude = 0;
            this._latitude = 0;
        }
        this._streetName = value.trim();
    }
    public get StreetAdditionalInformation(): string {
        return this._streetAdditionalInformation;
    }
    public set StreetAdditionalInformation(value: string) {
        if (value == null) value = '';

        if (this._streetAdditionalInformation !== value) {
            this.isDirty = true;
            this._longitude = 0;
            this._latitude = 0;
        }
        this._streetAdditionalInformation = value.trim();
    }
    public get CityID(): number {
        return this._cityID;
    }
    public set CityID(value: number) {
        if (this._cityID !== value) {
            this.isDirty = true;
            this._city = '';
            this._longitude = 0;
            this._latitude = 0;
        }
        this._cityID = value;
    }
    public get City(): string {
        return this._city;
    }
    public set City(value: string) {
        this._city = value;
    }
    public get StateID(): number {
        return this._stateID;
    }
    public set StateID(value: number) {
        if (this._stateID !== value) {
            this.isDirty = true;
            this._longitude = 0;
            this._latitude = 0;
        }
        this._stateID = value;
    }
    public get State(): string {
        return this._state;
    }
    public set State(value: string) {
        this._state = value;
    }
    public get StateCode(): string {
        return this._stateCode;
    }
    public set StateCode(value: string) {
        this._stateCode = value;
    }
    public get CountyID(): number {
        return this._countyID;
    }
    public set CountyID(value: number) {
        if (this._countyID !== value) {
            this.isDirty = true;
            this._longitude = 0;
            this._latitude = 0;
        }
        this._countyID = value;
    }
    public get County(): string {
        return this._county;
    }
    public set County(value: string) {
        this._county = value;
    }
    public get ISOCountryCode(): number {
        return this._isoCountryCode;
    }
    public set ISOCountryCode(value: number) {
        if (this._isoCountryCode !== value) {
            this.isDirty = true;
            this._longitude = 0;
            this._latitude = 0;
            this._country = '';
            this._countryCode = '';
        }
        this._isoCountryCode = value;
    }
    public get Country(): string {
        return this._country;
    }
    public set Country(value: string) {
        this._country = value;
    }
    public get CountryCode(): string {
        return this._countryCode;
    }
    public set CountryCode(value: string) {
        this._countryCode = value;
    }
    public get PostalCode(): string {
        return this._postalCode;
    }
    public set PostalCode(value: string) {
        if (this._postalCode !== value) {
            this.isDirty = true;
            this._longitude = 0;
            this._latitude = 0;
        }
        this._postalCode = value;
    }
    public get AddressType(): AddressType {
        return this._addressType;
    }
    public set AddressType(value: AddressType) {
        if (this._addressType !== value)
            this.isDirty = true;
        this._addressType = value;
    }

    public get Longitude(): number {
        return this._longitude;
    }
    public set Longitude(value: number) {
        if (this._longitude !== value)
            this.isDirty = true;
        this._longitude = value;
    }
    public get Latitude(): number {
        return this._latitude;
    }
    public set Latitude(value: number) {
        if (this._latitude !== value)
            this.isDirty = true;
        this._latitude = value;
    }

    public get FullStreetAddress(): string {
        if (!Utils.isNullOrEmpty(this._boxNumber))
            return 'PO BOX # ' + this._boxNumber.toString();
        if (this._streetNumber !== 0 || !Utils.isNullOrEmpty(this._streetName) || !Utils.isNullOrEmpty(this._streetAdditionalInformation) || !Utils.isNullOrEmpty(this._streetSuffix))
            return (this._streetNumber > 0 ? this._streetNumber.toString() + ' ' : '') + (Utils.isNullOrEmpty(this._streetName) ? '' : ' ')
                + this._streetName + (Utils.isNullOrEmpty(this._streetSuffix) ? '' : ' ') + this._streetSuffix + (Utils.isNullOrEmpty(this._streetAdditionalInformation) ? '' : ', ')
                + this._streetAdditionalInformation + (Utils.isNullOrEmpty(this._unitNumber) ? '' : ', Unit/Apt.-') + this._unitNumber;
        return '';
    }
    public get FullStreetAddressLower(): string {
        if (!Utils.isNullOrEmpty(this._boxNumber))
            return 'PO BOX # ' + this._boxNumber.toString();
        if (this._streetNumber !== 0 || !Utils.isNullOrEmpty(this._streetName) || !Utils.isNullOrEmpty(this._streetAdditionalInformation) || !Utils.isNullOrEmpty(this._streetSuffix))
            return (this._streetNumber > 0 ? this._streetNumber.toString() + ' ' : '') + (Utils.isNullOrEmpty(this._streetName) ? '' : ' ')
                + this._streetName.toLowerCase() + (Utils.isNullOrEmpty(this._streetSuffix) ? '' : ' ') + this._streetSuffix.toLowerCase() + (Utils.isNullOrEmpty(this._streetAdditionalInformation) ? '' : ', ')
                + this._streetAdditionalInformation.toLowerCase() + (Utils.isNullOrEmpty(this._unitNumber) ? '' : ', Unit/Apt.-') + this._unitNumber.toLowerCase();
        return '';
    }
    public get FullAddress(): string {
        const _streetAddress: string = this.FullStreetAddress;
        return Utils.isNullOrEmpty(_streetAddress) ? '' : (_streetAddress + ', ' + (Utils.isNullOrEmpty(this.City) ? '' : this.City + ', ')
            + (Utils.isNullOrEmpty(this.StateCode) ? '' : this.StateCode + ' - ') + (Utils.isNullOrEmpty(this._postalCode) ? '' : this._postalCode.toString()) + (Utils.isNullOrEmpty(this.CountryCode) ? '' : ', '
                + this.CountryCode));
    }
    public get FullAddressLower(): string {
        const _streetAddress: string = this.FullStreetAddressLower;
        return Utils.isNullOrEmpty(_streetAddress) ? '' : (_streetAddress + ', ' + (Utils.isNullOrEmpty(this.City) ? '' : this.City.toLowerCase() + ', ')
            + (Utils.isNullOrEmpty(this.StateCode) ? '' : this.StateCode.toLowerCase() + ' - ') + (Utils.isNullOrEmpty(this._postalCode) ? '' : this._postalCode.toLowerCase())
            + (Utils.isNullOrEmpty(this.CountryCode) ? '' : ', ' + this.CountryCode.toLowerCase()));
    }
    public get FullAddressHTML(): string {
        const _streetAddress: string = this.FullStreetAddress;
        return Utils.isNullOrEmpty(_streetAddress) ? '' : (_streetAddress + '<br/> ') + (Utils.isNullOrEmpty(this.City) ? '' : this.City + '<br/> ')
            + (Utils.isNullOrEmpty(this.State) ? '' : this.State + '&nbsp;-&nbsp;') + (Utils.isNullOrEmpty(this._postalCode) ? '' : this._postalCode.toString());
    }
    public get FullAddressWithCountry(): string {
        return this.FullStreetAddress + (Utils.isNullOrEmpty(this.City) ? '' : ', ') + this.City + (Utils.isNullOrEmpty(this.State) ? '' : ', ')
            + this.State + (Utils.isNullOrEmpty(this._streetName) ? '' : ' ') + this._postalCode + (Utils.isNullOrEmpty(this.Country) ? '' : ' - ') + this.Country;
    }

    public get Mailable(): boolean {
        return this._mailable;
    }
    public set Mailable(value: boolean) {
        if (this._mailable !== value)
            this.isDirty = true;
        this._mailable = value;
    }
    public get DoNotMail(): boolean {
        return this._doNotMail;
    }
    public set DoNotMail(value: boolean) {
        if (this._doNotMail !== value)
            this.isDirty = true;
        this._doNotMail = value;
    }

    public get Ownership(): OwnerShipType {
        return this._ownership;
    }
    public set Ownership(value) {
        if (this._ownership !== value)
            this.isDirty = true;
        this._ownership = value;
    }
    public get OwnershipStartDate(): Date {
        return this._startDate;
    }
    public set OwnershipStartDate(value: Date) {
        if (this._startDate !== value)
            this.isDirty = true;
        this._startDate = value;
    }
    public get OwnershipEndDate(): Date {
        return this._endDate;
    }
    public set OwnershipEndDate(value: Date) {
        if (this._endDate !== value)
            this.isDirty = true;
        this._endDate = value;
    }

    public get IsComplete() {
        if (this.ISOCountryCode === 0 || (this.ISOCountryCode > 0 && Utils.isNullOrEmpty(this._streetName) && Utils.isNullOrEmpty(this._boxNumber)))
            return false;
        else if (this.ISOCountryCode > 0 && (this._cityID === 0 || this._stateID === 0 || Utils.isNullOrEmpty(this._postalCode)))
            return false;
        return true;
    }

    public get IsPresent() {
        return this.ISOCountryCode > 0 && (this._streetNumber !== 0 || Utils.isNullOrEmpty(this._streetName) || Utils.isNullOrEmpty(this._boxNumber) || this._cityID !== 0 || this._stateID !== 0
            || !Utils.isNullOrEmpty(this._postalCode));
    }

    protected get EntityPrimaryKeyID(): number {
        return this._entityPrimaryKeyID;
    }
    protected set EntityPrimaryKeyID(value: number) {
        this._entityPrimaryKeyID = value;
    }

    public AddressTypeName(): string { return AddressType[this._addressType]; }

    toJSON() {
        return {
            EntityAddressID: this._entityAddressID,
            StreetName: this._streetName,
            StreetAdditionalInformation: this._streetAdditionalInformation,
            CityID: this._cityID,
            City : this._city,
            StateID: this._stateID,
            StateCode: this._stateCode,
            CountyID: this._countyID,
            County: this._county,
            ISOCountryCode: this._isoCountryCode,
            PostalCode: this._postalCode,
            AddressType: this._addressType,
            Mailable: this._mailable,
            DoNotMail: this._doNotMail,
            Ownership: this._ownership,
            OwnershipStartDate: this._startDate,
            OwnershipEndDate : this._endDate
        };
    }
}
