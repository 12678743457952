import { Component, OnInit, Input, Inject, Output, OnDestroy} from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { listing, assessment, BaseResponse } from '../../models';

import { ListingService } from '../listing.service';
import { AddAssessmentDialog } from '../dialogs';

import { ListingBaseComponent } from '../listing.base.component';
import { GlobalComponent } from '../../global.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-listing-assessment',
    templateUrl: './assessment.component.html'
})
export class ListingAssessmentComponent extends ListingBaseComponent implements OnDestroy {

    canAddAssessment: boolean = false;
    assessments: assessment[] = [];
    @Input() public showHeader: boolean = true;
    @Input() readonly: boolean = false;
    @Input() canAddNew: boolean = false;
    @Input() headerVisible: boolean = false;

    private _total: number;
    private _lcs: Subscription;

    constructor(dataservice: DataService, r: Router, private dialog: MatDialog, @Inject(GlobalComponent) gc: GlobalComponent) {
        super('listingAssessment', dataservice, r, gc);

        if (this._lcs != null) this._lcs.unsubscribe();
        this._lcs = this.gc.listingChanged.subscribe(() => {
            this.initMe();
            this.bindAssesments();
        });
    }

    //#region Properties

    public get Total(): number { return this._total; }

    get taxYear(): number { return this.currentListing.taxYear; }
    get assessmentTotal(): number {
        const _assessments = this.assessments.filter((a) => moment().isBefore(a.expiryDate) || a.expiryDate === this.Utils.dateTimeMinValue);
        let _total: number = 0;
        _assessments.forEach((a) => { _total += a.amount; });
        return _total;
    }

    get closingDate() { return this.currentListing.closingDate; }
    //#endregion

    ngOnDestroy() {
        if (this._lcs != null) this._lcs.unsubscribe();
        this.destoryLoginSubscription();
    }

    bindAssesments() {
        if (this.currentListing)
            this.canAddAssessment = this.currentListing.isEditable('assessments');

        if (this.currentListing && this.currentListing.assessments != null && this.currentListing.assessments.length > 0) {
            if (this.currentListing && this.currentListing.assessments != null && this.currentListing.assessments.length > 0) {
                this.assessments = this.currentListing.assessments.map((c) => Utils.castTo(c, (new assessment())));
                this.assessments = [...this.assessments];
                return;
            }

            let _response: BaseResponse;
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).getAssessments(this.listingID).subscribe(
                (data) => _response = data
                , (error) => {
                    this.onApiError(error);
                }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else if (_response.data) {
                            const _listingPresent = this.currentListing && this.currentListing.id > 0;
                            if (this.noParent && !_listingPresent) {
                                this.setCurrentListing(_response.data);
                            }

                            this.currentListing.assessments = _response.data.assessments;

                            if (this.assessments == null || this.assessments.length === 0)
                                this.assessments = _response.data.assessments.map((c) => Utils.castTo(c, (new assessment())) );
                            else
                                this.assessments = [..._response.data.assessments.map((c) => Utils.castTo(c, (new assessment())) )];
                        }
                }
            );

        }
    }

    addNewAssessment() {
        const dialogRef = this.dialog.open(AddAssessmentDialog, {
            data: {
                id: this.listingID
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.assessments.push(Utils.castTo(result, (new assessment())));
                this.assessments = [...this.assessments];
                this.currentListing.assessments.push(Utils.castTo(result, (new assessment())));
            }
        });
    }

    removeAssessment(id) {
        let listingResponseData;
        (new ListingService(this.dataservice, this.route)).removeAssessment(this.listingID, id).subscribe((r) => listingResponseData = r
            , (error) => { this.onApiError(error); }
            , () => {
                this.assessments = [...this.assessments.filter((a) => a.id !== id )];
                this.assessments = [...this.assessments];
                this.currentListing.assessments = this.currentListing.assessments.filter((a) => a.id !== id );
            });
    }
}
