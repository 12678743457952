<div class="appContent">
    <h2 class="pageHeader">
        Service Visits
        <a (click)="newVisit()" class="float-right" *ngIf="canAddNew" title="Send new service request"><i class="material-icons">touch_app</i></a>
    </h2>
    <div class="section">
        <div class="app-flex-container app-2col">
            <app-datepicker #txtStartDate id="txtStartDate" [value]="startDate" [placeholder]="'From Date'" (valueChanged)="startDateChanged($event)" [maxDate]="today" [defaultToday]="false"></app-datepicker>
            <app-datepicker #txtEndDate id="txtEndDate" [value]="endDate" [placeholder]="'To Date'" (valueChanged)="endDateChanged($event)" [maxDate]="today" [defaultToday]="false"></app-datepicker>
        </div>
        <div class="app-flex-container app-3col">
            <mat-form-field>
                <mat-select [(ngModel)]="selectedServiceTypes" placeholder="Service Types" multiple (selectionChange)="stChanged()">
                    <mat-option *ngFor="let st of serviceTypes" [value]="st.id">{{st.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <ng-template [ngIf]="(this.listings && this.listings.length > 0)" [ngIfElse]="noWOs">
                <mat-form-field>
                    <mat-select [(ngModel)]="selectedListings" placeholder="Work Order Location" multiple (selectionChange)="stChanged()">
                        <mat-option *ngFor="let l of listings" [value]="l.id" class="auto">{{l.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
            <ng-template #noWOs>
                <span class="warning" *ngIf="this.componentBusy === false">Your company do not have OPEN work orders</span>
            </ng-template>
            <button matButton (click)="bindVisits()" *ngIf="criteriaChanged">Lookup</button>
        </div>
    </div>
    <mat-progress-bar *ngIf="componentBusy" mode="buffer"></mat-progress-bar>
    <div class="section">
        <ng-template [ngIf]="(this.visits && this.visits.length > 0)" [ngIfElse]="noVisits">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let visit of visits  | paginate: { itemsPerPage: itemsPerPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{visit.address}}</mat-panel-title>
                        <mat-panel-description style="display: initial; flex-grow: 1">
                            <label class="float-right">{{visit.serviceType}}</label>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="section">
                        <div>
                            <label class="caption">Person Visting</label><label class="auto">{{visit.visitor}}</label>
                            <span [ngClass]="{'warning' : !visit.approved, 'green' : visit.approved}" title="visit.approve ? '' : 'Pending Confirmation from property Owner.' ">{{utils.DisplayDateTime(visit.visitDate)}}</span>
                        </div>
                        <div>
                            <span *ngIf="utils.isNullOrEmpty(visit?.notes) === false"><label class="caption">Request Notes</label><label class="auto">{{visit.notes}}</label></span>
                            <span *ngIf="visit.canApprove && visit.approved"><label class="caption">Response Notes</label><label class="auto">{{visit.responseNotes}}</label></span>
                            <app-next-status-actions [actions]="visit.nextSteps" (itemSelected)="changeStatus(visit.workOrderID, $event)"></app-next-status-actions>
                        </div>
                    </div>
                    <mat-action-row *ngIf="visits.length > 1">
                        <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && visits.length > 1">Previous</button>
                        <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < visits.length && visits.length > 1">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
            <div style="text-align:center" *ngIf="visits.length > itemsPerPage">
                <hr />
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </ng-template>
        <ng-template #noVisits>
            <mat-card class="section" *ngIf="searched && (visits == null || visits.length === 0)">
                No open Visit requests, for selected criteria.
            </mat-card>
        </ng-template>
    </div>
</div>