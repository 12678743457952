import { Component, Inject, HostListener, Input } from '@angular/core';
import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router} from '@angular/router';
import { BaseResponse } from '../models';
import { appEnvironment } from '../../environments/environment';
import { PersonService } from '../account/person.service';
import { ScriptService } from '../common/scriptService';

declare const grecaptcha: any;

@Component({
    selector: 'app-unsubscribe',
    templateUrl: './unsubscribe.component.html'
})

export class UnsubscribeComponent extends BaseComponent  {

    @Input() customContent: boolean = false;
    submitted: boolean = false;
    email: string = '';
    constructor(private dataservice: DataService, r: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('pgUnsubscribe', r, g);

        ScriptService.loadExternalScript(appEnvironment.reCaptchaScript, 'www.gstatic.com/recaptcha/');
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.key === 'Enter')
            this.onSubmit();
        else if (event.key === 'Escape') {
            this.clear();
        }
    }
    onSubmit() {
        if (GlobalComponent._cv === false && grecaptcha != null) {
            let _response: BaseResponse;
            grecaptcha.ready(() => {
                grecaptcha.execute(appEnvironment.googleReCaptcha, { action: 'submit' }).then((token) => {
                    (new PersonService(this.dataservice, this.route, this.myResourceCategory)).verifyRecaptcha(token).subscribe(
                        (data) => _response = data,
                        (error) => { this.showResourceError('System.CaptchaFailed'); },
                        () => {
                            GlobalComponent._cv = true;
                            this.onVerify_Proceed();
                        });
                });
            });
        } else if (GlobalComponent._cv === true)
            this.onVerify_Proceed();
    }

    private clear() {
        this.email = '';
        this.submitted = false;
    }

    private onVerify_Proceed() {
        this.gc.setComponentBusy(true);
        let _response: BaseResponse;
        (new PersonService(this.dataservice, this.route, this.myResourceCategory)).unsubscribe(this.email).subscribe(
            (data) => _response = data,
            (error) => { this.showResourceError('UnSubscribe.errFailed'); },
            () => {
                this.gc.setComponentBusy(false);
                this.clear();
                this.submitted = true;
                setTimeout(() => {
                    if (this.gc.loggedIn)
                        this.GotoWelcome();
                    else
                        this.GotoHome();
                }, 15000);
            });
    }
}
