import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from './help.component';
import { GlossaryComponent } from './glossary.component';

export const helpRoutes: Routes = [
    {
        path: 'glossary', component: GlossaryComponent
    },
    {
        path: '*', component: HelpComponent
        , data: {
            captchaProtected: true
        }
    }
    , {
        path: '', component: HelpComponent, data: {
            captchaProtected: true
        }
    }
];
export const helpRouting = RouterModule.forChild(helpRoutes);
