import { Component, Input, OnInit, Output, EventEmitter, Inject, Injectable } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from '../base.component';
import { GlobalComponent } from '../global.component';
import { Router } from '@angular/router';
import { BaseService } from '../common/service/base.service';

@Component({
    selector: 'app-geographic-selection',
    templateUrl: './geographicSelection.component.html'
})
export class GeographicSelectionComponent extends BaseComponent implements OnInit {
    @Output() stateChanged: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() countyChanged: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() cityChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

    @Input() @Output() multiSelectAllowed: boolean = false;
    @Input() @Output() isoCountryCode: number = 840;
    @Input() @Output() cityIDs: number[] = [];
    @Input() @Output() countyIDs: number[] = [];
    @Input() @Output() stateIDs: number[] = [];

    states: any = []; loadingStates: boolean = false;
    counties: any = []; loadingCounties: boolean = false;
    cities: any = []; loadingCities: boolean = false;

    constructor(private ds: DataService, private rs: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('geographicSelection', rs, g);
    }


    ngOnInit() {
        if (this.isoCountryCode > 0)
            this.bindStates();
    }

    onCityChanged(event$) {
        this.cityIDs = new Array<number>();
        if (event$ && event$.id)
            this.cityIDs.push(parseInt(event$.id));
    }
    onCountyChanged(event$) {
        this.countyIDs = new Array<number>();
        if (event$ && event$.id)
            this.countyIDs.push(parseInt(event$.id));
    }
    onStateChanged(event$) {
        this.stateIDs = new Array<number>();
        if (event$ && event$.id)
            this.stateIDs.push(parseInt(event$.id));
    }

    citiesChanged(event$) {
        this.cityChanged.emit(this.cityIDs);
    }
    countiesChanged(event$) {
        this.countyChanged.emit(this.countyIDs);
        this.bindCities();
    }
    statesChanged(event$) {
        this.stateChanged.emit(this.stateIDs);
        this.bindCounties();
    }

    clear() {
        this.stateIDs = this.countyIDs = this.cityIDs = this.counties = this.cities = [];
    }

    private bindStates() {
        this.stateIDs = this.countyIDs = this.cityIDs = this.states = this.counties = this.cities = [];
        this.loadingStates = false;
        let _response;
        (new GeographicSelectionService(this.ds, this.rs, this.myResourceCategory)).getStates(this.isoCountryCode).subscribe((r) => _response = r
            , (error) => { this.loadingStates = false; this.onApiError(error); }
            , () => {
                this.loadingStates = false;
                if (_response && _response.data) {
                    this.states = _response.data;
                }
            });
    }

    private bindCounties() {
        this.countyIDs = this.cityIDs = this.counties = this.cities = [];
        this.loadingCounties = false;
        let _response;
        (new GeographicSelectionService(this.ds, this.rs, this.myResourceCategory)).getCounties(this.stateIDs).subscribe((r) => _response = r
            , (error) => { this.loadingCounties = false; this.onApiError(error); }
            , () => {
                this.loadingCounties = false;
                if (_response && _response.data) {
                    this.counties = _response.data;
                }
            });
    }


    private bindCities() {
        this.cityIDs = this.cities = [];
        this.loadingCities = true;
        let _response;
        (new GeographicSelectionService(this.ds, this.rs, this.myResourceCategory)).getCities(this.stateIDs, this.countyIDs).subscribe((r) => _response = r
            , (error) => { this.loadingCities = false; this.onApiError(error); }
            , () => {
                this.loadingCities = false;
                if (_response && _response.data) {
                    this.cities = _response.data;
                }
            });
    }

}

@Injectable({ providedIn: 'root' })
export class GeographicSelectionService extends BaseService {
    private ds: DataService;

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule);
        this.ds = ds;
    }

    getStates(isoCountryCode: number) {
        const data = { isoCountryCode };
        return this.ds.APIPost('/country/states', data, this.requestingModule);
    }

    getCounties(stateIDs: number[]) {
        const data = { stateIDs };
        return this.ds.APIPost('/country/counties', data, this.requestingModule);
    }

    getCities(stateIDs: number[], countyIDs: number[]) {
        const data = { stateIDs, countyIDs };
        return this.ds.APIPost('/country/cities', data, this.requestingModule);
    }

}
