import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, Output } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { GlobalComponent } from '../global.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {  Role, item } from '../models';

import { EscrowService } from './escrow.service';

import { EscrowBaseComponent } from './escrow.base.component';
import { NewEscrowTaxDialog } from './dialog';

@Component({
    selector: 'app-escrow-taxes',
    templateUrl: './escrowTaxes.component.html'
})
export class EscrowTaxesComponent extends EscrowBaseComponent {
    @Input() @Output() readOnly: boolean = false;

    allTaxes: any = null;

    busy: boolean = false;
    constructor(ds: DataService, r: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('escrowTaxes', ds, r, g);
        this.messages = {
            emptyMessage: 'Currently no information is available or no taxes are added by escrow yet.',
        };
        this.gc.roleChanged.subscribe((_r) => {
            this.initMe();
            this.bindTaxes();
        });
        this.gc.escrowChanged.subscribe((_r) => {
            this.initMe();
            this.bindTaxes();
        });
    }

    get taxes() {
        if (this.allTaxes) {
            if (this.gc.role !== Role.NotSet)
                return this.allTaxes.filter(t => { return t.paidBy === this.gc.role; });
            else
                return this.allTaxes;
        } else
            return [];
    }

    bindTaxes() {
        let _response;
        this.busy = true;

        let _id = this.currentEscrow ? this.currentEscrow.id : 0;

        if (_id === 0) {
            this.initMe();
            _id = this.currentEscrow ? this.currentEscrow.id : 0;
        }

        if (_id !== this.escrowID || this.allTaxes == null || (_id === this.escrowID && this.currentEscrow.taxes == null)) {
            this.allTaxes = [];
            const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getTaxes(this.escrowID);
            if (_es != null) {
                _es.subscribe((r) => _response = r
                    , (error) => { this.busy = false; }
                    , () => {
                        this.busy = false;
                        if (_response && _response.data) {
                            this.setCurrentEscrow(_response.data);
                            this.allTaxes = _response.data.taxes;
                        }
                    });
            }
        } else if (_id === this.escrowID && this.currentEscrow.taxes)
            this.allTaxes = this.currentEscrow.taxes;
    }

    addTax() {
        const dialogRef = this.dialog.open(NewEscrowTaxDialog, {
            data: {
                id: this.escrowID
                , roles: this.currentEscrow.settlementRoles
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.allTaxes = result;
            }
        });
    }

    removeTax(taxID) {
        let _response;
        this.busy = true;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).removeTax(this.escrowID, taxID);
        if (_es != null) {
            _es.subscribe((r) => _response = r
                , (error) => { this.busy = false; }
                , () => {
                    this.busy = false;
                    if (_response && _response.data) {
                        this.allTaxes = _response.data;
                    }
                });
        }
    }
}
