<p *ngIf="buyerOrSeller">
    This is list of common taxes and fees paid. Final amounts of these Fees and Taxes are determined by Escrow, on closing date. Any additional taxes and fees will be determined as well and charged to respective party.
</p>
<mat-card>
    <br /><br />
    <ng-template [ngIf]="(this.taxesAndFees && this.taxesAndFees.length > 0)" [ngIfElse]="noTaxesAndFees">
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let taxAndFee of taxesAndFees; let i = index" [expanded]="accordionStep === i" (opened)="accordionSetStep(i)">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{taxAndFee.configuration.description}}</mat-panel-title>
                    <mat-panel-description style="display:initial">
                        <span title="* = Estimated" class="float-right">{{FormatNumberToCurrency(taxAndFee.configuration.feeApplicable) + (taxAndFee.estimated ? "*" : "")}}</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <label>Entity Name : </label>&nbsp;<span>
                        <span *ngIf="utils.isNullOrEmpty(taxAndFee?.configuration?.imformationURL)">{{taxAndFee.configuration.withholdingEntityName}}</span>
                        <a *ngIf="utils.isNullOrEmpty(taxAndFee?.configuration?.imformationURL) === false" (click)="gotoRoute(taxAndFee.configuration.imformationURL)">{{taxAndFee.configtation.withholdingEntityName}}</a>
                    </span><br />
                    <label>Paid By : </label>&nbsp;
                    <span *ngIf="readonly">{{taxAndFee.paidBy}}</span>
                    <mat-form-field *ngIf="!readonly" placeholder="Paid By" class="normal">
                        <mat-select [(ngModel)]="taxAndFee.paidBy" (selectionChange)="changePaidBy(taxAndFee.id, $event.value)" [disabled]="!readonly">
                            <mat-option *ngFor="let item of toBePaidBy | keys" [value]="item.key">{{item.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-action-row *ngIf="taxesAndFees.length > 1">
                    <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && taxesAndFees.length > 1">Previous</button>
                    <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < taxesAndFees.length && taxesAndFees.length > 1">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
    <ng-template #noTaxesAndFees>
        <h3>Taxes and Fees details not available.</h3>
    </ng-template>
</mat-card>