import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { item, downloadableContent } from '../../models';
import { WorkOrderService} from '../workorder.service';
import { EscrowService } from '../../escrow/escrow.service';
import { selectTag } from '../../common/appCommon.module';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-add-contingency-dialog',
    templateUrl: './addContingency.dialog.html'
})
export class AddContingencyDialog extends BaseComponent {

    doc: downloadableContent = null;
    fileExtension: string = 'pdf';
    @ViewChild('inst') instructions: any;
    dialogHeader: string = 'Contingency';
    workOrderID: number = 0;
    escrowID: number = 0;

    verificationRoles: any = [];
    existingContingencies: any = [];
    toBeFulfilledBy: any = [];

    openContingencyID: number = 0;
    contigencyDescription: string = '';
    selectedFulfilmentRole: number = 0;
    verificationRole: number = 0;
    optional: boolean = false;
    saving: boolean = false;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) gc: GlobalComponent, private dialog: MatDialog
        , public dialogRef: MatDialogRef<AddContingencyDialog>, fb: UntypedFormBuilder, private cdref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addContingencyDialog', r);

        if (data) {

            if (data.escrowID) this.escrowID = data.escrowID;
            if (data.workOrderID) this.workOrderID = data.workOrderID;

            if (!Utils.isNullOrEmpty(this.data.header)) {
                this.dialogHeader = 'Contingency for ' + this.data.header;
            }

            let _response: any;
            (new WorkOrderService(this.dataservice, this.r, this.myResourceCategory)).prepareToAddContingencies(this.workOrderID).subscribe(
                (_data) => { _response = _data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response && _response.data) {
                        if (_response.data.existingContingencies) {
                            this.existingContingencies = _response.data.existingContingencies;
                            if (this.existingContingencies.length > 1)
                                this.existingContingencies.splice(0, 0, new item(0, selectTag));
                        }
                        if (_response.data.verificationRoles) {
                            this.verificationRoles = _response.data.verificationRoles;
                            if (this.verificationRoles.length > 0)
                                this.verificationRoles.splice(0, 0, new item(0, selectTag));
                        }
                        if (_response.data.toBeFulfilledBy) this.toBeFulfilledBy = _response.data.toBeFulfilledBy;
                    }
                }
            );
        }
    }

    get readyToSubmit() {
        return !this.saving && !Utils.isNullOrEmpty(this.contigencyDescription) && this.selectedFulfilmentRole > 0
            && (this.verificationRoles == null || this.verificationRoles.length === 0 || (this.verificationRoles.length > 0 && this.verificationRole > 0));
    }
    close(data) {
        this.dialogRef.close(data);
    }

    addContingency() {

        this.saving = true;
        const _data = {
            id: this.escrowID
            , description: this.contigencyDescription
            , fullfiledByRole: this.selectedFulfilmentRole
            , verifiedByRole: this.verificationRole
            , optional: this.optional
            , instructions: this.doc
        };

        let _response: any;
        const _es = (new EscrowService(this.dataservice, this.r, this.myResourceCategory)).addContingency(_data);
        if (_es != null) {
            _es.subscribe(
                (data) => _response = data
                , (error) => {
                    this.close(null);
                    this.onApiError(error);
                }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.close('done');
                            this.saving = false;
                        }
                });
        }
    }

    fileUploaded($event): void {
        this.fileHandler($event);
        this.instructions.nativeElement.value = '';
    }

    fileHandler($event): void {
        const text = [];
        const _file = $event.srcElement.files;

        if (_file) {
            const _fName = _file[0].name;
            if (_fName.lastIndexOf('.') > 0) {
                const _fExtension = _fName.substring(_fName.lastIndexOf('.') + 1);
                if (this.fileExtension.indexOf(_fExtension) < 0) {
                    this.showMessage('Invalid file uploaded');
                    return;
                }
                this.doc = new downloadableContent();
                this.doc.name = _fName;
                if (_file[0].type)
                    this.doc.mime = _file[0].type;
            } else {
                this.showMessage('Invalid file uploaded');
                return;
            }
        }
        const input = $event.target;
        const reader = new FileReader();
        reader.readAsDataURL(input.files[0]);

        reader.onloadend = (data) => {
            this.doc.content = reader.result;
        };

        reader.onerror = () => {
            alert('Unable to read ' + input.files[0]);
        };
    }
}
