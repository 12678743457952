<div class="appContent">
    <h2 class="pageHeader">Contact Us</h2>
    <div class="appContent">
        <app-page-asset [assetID]=4069></app-page-asset>
        <mat-card>
            <div>
                <h2 class="pageHeader">Message</h2>
                <mat-form-field class="first">
                    <input matInput appDisableAutofill trim="blur" placeholder="Name" maxlength="50" required [(ngModel)]="name" [disabled]="this.gc.loggedIn && (name ?? '') !== ''"/>
                </mat-form-field>
                <mat-form-field>
                    <input matInput appDisableAutofill trim="blur" placeholder="Email Address" maxlength="100" required [(ngModel)]="email"  [disabled]="this.gc.loggedIn && (email ?? '') !== ''"/>
                </mat-form-field>
                <mat-form-field>
                    <textarea matInput appDisableAutofill trim="blur" placeholder="Message" maxlength="2000" required [(ngModel)]="message"></textarea>
                </mat-form-field>
                <div id="divM" class="error"></div>
                <div>
                    <a title="Click/Tap to Send Message" class="btn " (click)="contact()" tabindex="4">Send</a>
                </div>
                <hr />
                <p class="note"><strong>DO NOT</strong> send any personal information other than way of reaching you back.</p>
                <div class="sectioncopyright">By submitting your information you agree to our <a [routerLink]="appURLs.privacyPolicy">privacy policy</a>.</div>
            </div>
            <div class="section form-group">
                <hr />
                Management&#64;{{this.myBRAND}}
            </div>
        </mat-card>
    </div>
</div>