import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit, Input, Output, OnDestroy } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Role, BaseResponse, DocumentEntityType, DocumentCategory } from '../models';

import { EscrowService } from './escrow.service';
import { EscrowStatementComponent } from './escrowStatement.component';
import { ListingService } from '../listing/listing.service';
import { OfferService } from '../offer/offer.service';
import { PersonService } from '../account/person.service';
import { EscrowBaseComponent } from './escrow.base.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs/internal/Observable';
import { selectTag, selectProperty } from '../common/appCommon.module';
import { DocumentsComponent } from '../common/documents';
import { EscrowDemandComponent } from './escrowDemands.component';
import { Utils } from '../common/utils';

@Component({
    selector: 'app-escrow-detail',
    templateUrl: './escrowDetail.component.html'
})
export class EscrowDetailComponent extends EscrowBaseComponent implements OnInit, OnDestroy {

    DocumentEntityType: typeof DocumentEntityType = DocumentEntityType;
    DocumentCategory: typeof DocumentCategory = DocumentCategory;
    roleType = Role;
    @Input() @Output() forRole: Role = Role.NotSet;
    @ViewChild('escrowStatement') escrowStatement: EscrowStatementComponent;
    @ViewChild('demands') demands: EscrowDemandComponent;
    @ViewChild('escrowDocuments') escrowDocuments: DocumentsComponent;
    private _lsc: Subscription;
    private _esc: Subscription;

    constructor(ds: DataService, r: Router, @Inject(GlobalComponent)  gc: GlobalComponent, private dialog: MatDialog, private cdref: ChangeDetectorRef) {
        super('escrowDetail', ds, r, gc);

        if (this._lsc != null) this._lsc.unsubscribe();
        this._lsc = this.gc.listingChanged.subscribe((v) => {
            this.setStatement(null);
        });

        if (this._esc != null) this.gc.escrowChanged.subscribe((v) => {
            this.setStatement(v);
            if (this.escrowDocuments) {
                this.escrowDocuments.entityTypeKeyID = v;
            }
        });
    }

    ngOnDestroy() {
        if (this._lsc != null) this._lsc.unsubscribe();
        if (this._esc != null) this._esc.unsubscribe();
        this.destoryLoginSubscription();
    }

    ngOnInit() {
        // If Buyer in the route get listings for Buyer
        // if Seller in the route get listings for Seller
        // else
        // Get Service Provider Work Orders and its Escrows.

        if (this.forRole === Role.NotSet) {
            // If Searching For Agent then Force Agent Type ID and hide Changing Service Type
            if (this.route.url.indexOf('/buyer/') >= 0) {
                this.forRole = Role.Buyer;
            } else if (this.route.url.indexOf('/seller/') >= 0) {
                this.forRole = Role.Seller;
            }
        }

        this.setListingID(0);
        this.gc.setBusy(true);
        let _response: BaseResponse;
        if (this.forRole === Role.Seller) {
            this.getMyListingsForSale(this.dataservice, (listings) => {
                this.gc.setBusy(false);
                this.listings = listings;
                if (this.listings && this.listings.length > 1 && this.listings.find((l) => l.id === 0) == null)
                    this.listings.splice(0, 0, { id: 0, address: selectProperty });
                if (this.listings && this.listings.length > 0) {
                    if (!(this.listingID > 0 && this.listings.find((l) => l.id === this.listingID)))
                        this.setListingID(this.listings[0].id);
                }
            });
        } else if (this.forRole === Role.Buyer) {
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).getMyOfferListings().subscribe(
                (data) => { _response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    this.gc.setBusy(false);
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.listings = _response.data;
                            if (this.listings && this.listings.length > 1 && this.listings.find((l) => l.id === 0) == null)
                                this.listings.splice(0, 0, { id: 0, address: selectProperty });
                            if (this.listings && this.listings.length > 0 ) {
                                if (!(this.listingID > 0 && this.listings.find((l) => l.id === this.listingID)))
                                    this.setListingID(this.listings[0].id);
                            }
                        }
                }
            );
        } else {
            (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getWorkOrders(this.escrowID).subscribe(
                (data) => { _response = data; }
                , (error) => { this.onApiError(error); }
                , () => {
                    this.gc.setBusy(false);
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.escrows = _response.data;
                            if (this.escrows && this.escrows.length > 1 && this.escrows.find((l) => l.id === 0) == null)
                                this.escrows.splice(0, 0, { id: 0, address: selectTag });
                            if (this.escrows && this.escrows.length > 0) {
                                if (!(this.escrowID > 0 && this.escrows.find((l) => l.id === this.escrowID)))
                                    this.setEscrowID(this.escrows[0].id);
                            }
                        }
                }
            );
        }
    }

    setStatement(escrow: any) {
        if (this.listingID > 0) {
            this.componentBusy = true;
            if (escrow == null) {
                let _response: BaseResponse;
                const _id = this.listingID; // Save it here, when Escrow is NULL then listing ID is LOST.

                this.setCurrentEscrow(null);
                this.setEscrowID(0);

                if (_id > 0) {
                    const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).getEscrow(0, _id);
                    if (_es != null) {
                        _es.subscribe(
                            (data) => { _response = data; }
                            , (error) => { this.componentBusy = false; this.onApiError(error); }
                            , () => {
                                this.componentBusy = false;
                                if (_response)
                                    if (!Utils.isNullOrEmpty(_response.error)) {
                                        this.showError(_response.error);
                                    } else if (_response.data) {
                                        this.setCurrentEscrow(_response.data);
                                        if (this.escrowStatement) {
                                            this.gc.setRole(this.forRole);
                                            this.escrowStatement.serviceproviderID = this.forRole === Role.NotSet ? this.user.details.CompanyID : 0;
                                            this.escrowStatement.bindStatement(this.forRole);
                                        }
                                        if (this.demands) {
                                            this.demands.bindDemands();
                                        }
                                    } else {
                                        this.escrows = null;
                                        this.gc.setListingID(_id);
                                    }
                            }
                        );
                    }
                } else {
                    this.componentBusy = false;
                }
            } else {
                this.componentBusy = false;
                if (this.escrowStatement) {
                    this.gc.setRole(this.forRole);
                    this.escrowStatement.serviceproviderID = this.forRole === Role.NotSet ? this.user.details.CompanyID : 0;
                }
            }
        } else {
            this.setCurrentEscrow(null);
            if (this.demands)
                this.demands.clear();
            if (this.escrowDocuments)
                this.escrowDocuments.clear();
            if (this.escrowStatement)
                this.escrowStatement.clear();
        }
    }
}
