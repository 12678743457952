import { Component, OnInit, Input , Output, Inject} from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { LegalTermType } from '../models/legalTerm.model';
import { Role, Address } from '../models';
import { LegalService, UtilService } from './service';
import { Utils } from './utils';

export class LegalTermView {
    public versionID: number;
    public content: string;
}

@Component({
    selector: 'app-legal-term',
    templateUrl: './legalTerm.component.html'
})
export class LegalTermComponent extends BaseComponent implements OnInit {

    @Input() type: LegalTermType = LegalTermType.NotSet;
    @Input() role: Role = Role.NotSet;
    @Input() label: string = '';
    @Input() cssClass: string = '';
    @Input() title: string = '';
    @Input() height: string = '150px';
    @Input() width: string = '0';
    @Input() static: boolean = false;
    @Input() passwordProtectOnExport: boolean = false;
    @Input() versionID: number = 0;
    @Input() productID: number = 0;
    @Input() isoCountryCode: number = 0;
    @Input() stateID: number = 0;
    @Input() countyID: number = 0;
    @Input() cityID: number = 0;
    @Input() address: Address = new Address();
    @Input() needToGiveAgreement: boolean = false;
    hasLegalAgreement: boolean = false;
    @Output() hasGivenAgreement: boolean = false;

    passwordForExport: string = '';

    private _legalTerm: LegalTermView = new LegalTermView();

    constructor(private ds: DataService, private rs: Router, @Inject(GlobalComponent) g: GlobalComponent) {
        super('legalTerm', rs, g);
    }

    public get legalContent() { return this._legalTerm ? this._legalTerm.content : ''; }

    ngOnInit() {
        this.bindLegalTerm();
    }
    public refresh() { this.bindLegalTerm(); }

    download() {
        let _response;
        (new UtilService(this.ds, this.rs, this.myResourceCategory)).convertHTMLtoPDF(this.legalContent, this.passwordProtectOnExport).subscribe(
            (data) => _response = data
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.OpenDownloadableDoc(_response.data, '', '', false);
                    }
            }
        );
    }

    private bindLegalTerm() {
        let _response;
        if (this.versionID === 0) {
            (new LegalService(this.ds, this.rs, this.myResourceCategory)).getLegalTerm(this.type, this.productID, this.role, this.isoCountryCode, this.stateID, this.countyID, this.cityID).subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this._legalTerm = _response.data;
                            this.hasLegalAgreement = !Utils.isNullOrEmpty(this.legalContent);
                        }
                }
            );
        } else {
            (new LegalService(this.ds, this.rs, this.myResourceCategory)).getLegalTermByVersion(this.versionID).subscribe(
                (data) => _response = data
                , (error) => { this.onApiError(error); }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this._legalTerm = _response.data;
                            this.hasLegalAgreement = !Utils.isNullOrEmpty(this.legalContent);
                        }
                }
            );
        }
    }
}
