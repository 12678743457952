<div class="mainPanel">
    <h2 class="pageHeader">Insurances</h2>
    <p>
        Please provide all insurances relevant to your line of business. Information you provide (or do not provide) here will be used by buyer(s) and seller(s) to make informed decision.
        <a class="float-right large btnTip" title="Add additional insurances" (click)="addInsurance()" *ngIf="!loading && allowAdd"><i class="fa fa-plus-circle"></i></a>
    </p>
    <div class="section">
        <ng-template [ngIf]="(this.insurances && this.insurances.length > 0)" [ngIfElse]="noInsurances">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let insurance of insurances  | paginate: { itemsPerPage: itemsPerPage , currentPage: pageNumber }; let i = index" [expanded]="accordionStep == i" (opened)="accordionSetStep(i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{utils.isNullOrEmpty(insurance.InsuranceType) ? '[Pending Selection]' : insurance.InsuranceType}}</mat-panel-title>
                        <mat-panel-description style="display:initial">{{insurance.IssuingCompany}} - {{utils.DisplayDate(insurance.ExpiryDate).trim() == "" ? "Does not expire" : DisplayDate(insurance.ExpiryDate)}}</mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="section">
                        <div *ngIf="insurance.id === 0">
                            <label class="caption">Type</label>
                            <select *ngIf="insurance.id === 0" class="app" (change)="updateValue($event.target.value, 'InsuranceType', i)" [value]="insurance.InsuranceTypeID">
                                <option *ngFor="let st of allInsuranceTypes" [value]="st.id" [selected]="st.id==insurance.InsuranceTypeID">
                                    {{st.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label class="caption">Issued By</label>
                            <input autofocus (change)="updateValue($event.target.value, 'IssuingCompany', i)" maxlength="100" type="text" [value]="insurance.IssuingCompany" class="app" />
                        </div>
                        <div>
                            <label class="caption">Contact #</label>
                            <input autofocus (change)="updateValue($event.target.value, 'IssuingCompanyPhone', i)" maxlength="20" type="text" [value]="insurance.IssuingCompanyPhone" class="app medium" />
                        </div>
                        <div>
                            <label class="caption">Policy #</label>
                            <input autofocus (change)="updateValue($event.target.value, 'PolicyID', i)" maxlength="20" type="text" [value]="insurance.PolicyID" class="app medium" />
                        </div>
                        <div>
                            <label class="caption">Liability Amount</label>
                            <input autofocus (change)="updateValue($event.target.value, 'PolicyAmount', i)" (keydown)="utils.numOnlyLimitingLength($event, 10, false)" type="text" [value]="insurance.PolicyAmount" class="app numeric medium" />
                        </div>
                        <div>
                            <label class="caption">Expiry Date</label>
                            <app-datepicker #txtExpiryDate id="txtExpiryDate" [value]="insurance.ExpiryDate" (valueChanged)="updateValue($event, 'ExpiryDate', i)" [cssClass]="'normal'"></app-datepicker>
                        </div>
                    </div>
                    <mat-action-row *ngIf="insurances.length > 1">
                        <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && insurances.length > 1">Previous</button>
                        <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < insurances.length && insurances.length > 1">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
            <div style="text-align:center"  *ngIf="this.insurances.length > itemsPerPage">
                <hr />
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </ng-template>
        <ng-template #noInsurances>
            <mat-card class="section" *ngIf="!loading && (insurances == null || insurances.length === 0)">
                You have not provided any Insurance information.
            </mat-card>
        </ng-template>
    </div>
</div>