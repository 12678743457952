import { Component, ViewChild, Inject, Input, OnDestroy} from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { ToBePaidBy, Offer } from '../../models';
import { OfferService } from '../offer.service';

import { ListingTaxesAndFeesComponent } from '../../listing/taxesAndFees.component';
import { OfferBaseComponent } from '../offer.base.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalComponent } from '../../global.component';
import { appEnvironment } from '../../../environments/environment';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../common/utils';

@Component({
    selector: 'app-offer-compliance-item-negotiation',
    templateUrl: './complianceItems.component.html'
})
export class ComplianceItemNegotiationComponent extends OfferBaseComponent implements OnDestroy {
    toBePaidBy = ToBePaidBy;

    @Input() readOnly: boolean = false;
    @Input() complianceItemsOnly: boolean = true;
    @ViewChild('listingTFE') listingTFE: ListingTaxesAndFeesComponent;
    complianceItems: any = [];

    private _pto: any;
    private _osc: Subscription;
    constructor(dataservice: DataService, r: Router, dialog: MatDialog, @Inject(GlobalComponent)  gc: GlobalComponent) {
        super('offerComplianceItemNegotiations', dataservice, r, dialog, gc);

        if (this._osc != null) this._osc.unsubscribe();

        this._osc = this.gc.offerChanged.subscribe(() => {
            this.initMe();
            this.bindData();
        });
    }

    ngOnDestroy() {
        this.destoryLoginSubscription();
        if (this._osc != null) this._osc.unsubscribe();
    }

    bindData() {
        if (this.offerID > 0 && (this.currentOffer == null || this.currentOffer.id !== this.offerID)) {
            let _response;
            const _s = (new OfferService(this.dataservice, this.r, this.myResourceCategory)).getComplianceItemNegotiations(this.offerID);
            if (_s) {
                _s.subscribe((r) => _response = r
                    , (error) => { this.onApiError(error); }
                    , () => {
                        if (_response)
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.showError(_response.error);
                            else {
                                this.setCurrentOffer(Utils.castTo(_response.data, new Offer()));
                                this.initData();
                            }
                    });
            }
        } else
            this.initData();
    }

    complete(id: number) {
        let _response;
        (new OfferService(this.dataservice, this.r, this.myResourceCategory)).completeComplianceItem(this.offerID, id).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.complianceItems.forEach((ci) => { if (ci.id === id) { ci.completed = true; ci.canComplete = false; } });
                        this.complianceItems = [...this.complianceItems];
                    }
            });
    }

    verify(id: number) {
        let _response;
        (new OfferService(this.dataservice, this.r, this.myResourceCategory)).verifyComplianceItem(this.offerID, id).subscribe((r) => _response = r
            , (error) => { this.onApiError(error); }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        this.complianceItems.forEach((ci) => { if (ci.id === id) { ci.verified = true; ci.canVerify = false; } });
                        this.complianceItems = [...this.complianceItems];
                    }
            });
    }

    onComplianceChange(id, value) {
        const _item = this.complianceItems.find((i) => { return i.id === id; });
        if (_item) {
            this.setDirty(_item);
            this._triggerSave();
        }
    }

    private _triggerSave() {
        if (this._pto && !this.saving) clearTimeout(this._pto);
        this._pto = setTimeout(() => { this._save(); }, appEnvironment.autoSaveTime);
    }

    // Save and Clear Dirty
    private _save() {
        const items = this.complianceItems.filter((ci) => { if (ci.isDirty != null && ci.isDirty === true) return true; else return false; })
            .map((c) => { return { id: c.id, paidBy: c.paidBy }; });
        if (items) {
            this.gc.setComponentBusy(true);
            this.saving = true;
            let _response;
            (new OfferService(this.dataservice, this.r, this.myResourceCategory)).saveComplianceItems(this.offerID, items).subscribe((r) => _response = r
                , (error) => {
                    this.gc.setComponentBusy(false);
                    this.saving = false; this.onApiError(error);
                }
                , () => {
                    this.gc.setComponentBusy(false);
                    this.saving = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.complianceItems = _response.data;
                            this.complianceItems = [...this.complianceItems];
                        }
                });
        }
    }

    private initData() {
        if (this.currentOffer && this.currentOffer.complianceItems) {
            this.complianceItems = this.currentOffer.complianceItems;
            if (!this.complianceItemsOnly) {
                this.listingTFE.offerID = this.offerID;
                this.listingTFE.setListingID(this.currentOffer.listingID);
            }
        } else {
            this.complianceItems = null;
        }
    }
}
