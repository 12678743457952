import { Input, Component, OnDestroy, Directive, Inject, Injectable } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';

import { escrow, Offer, BaseResponse, listing } from '../models';
import { appEnvironment } from 'src/environments/environment';
import { OfferService } from './offer.service';
import { EscrowService } from '../escrow/escrow.service';
import { HTMLDialog } from '../common/dialogs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from '../common/utils';

export enum OfferEditSteps {
    General = 0,
    PersonalProperty = 1,
    Services = 2,
    Fees = 3,
    Contigencies = 4,
    Members = 5,
    Agreement = 6,
    Disclosures = 7
}

@Injectable()
export abstract class OfferBaseComponent extends BaseComponent implements OnDestroy {

    OfferEditSteps: typeof OfferEditSteps = OfferEditSteps;
    @Input() showHeader: boolean = true;
    @Input() readonly: boolean = false;
    @Input() noParent: boolean = true;

    saving: boolean = false;

    offerMessage: string = null;

    listingID: number = 0;
    offerID: number = 0;
    currentOffer: Offer = new Offer();
    currentEscrow: escrow = new escrow();
    currentListing: listing = new listing();

    private _to: any;

    constructor(@Inject(String) sModule: string, public dataservice: DataService
        , public r: Router, public dialog: MatDialog, gc: GlobalComponent) {
        super(sModule, r, gc);

    }

    ngOnDestroy() {
        this.destoryLoginSubscription();
        if (this._to) clearTimeout(this._to);
    }

    setOfferID(v: number) {
        if (v == null || typeof v !== 'number')
            v = 0;
        this.offerID = v;
        this.gc.setOfferID(v);
    }
    setCurrentOffer(v: Offer) {

        if (v == null) {
            v = new Offer();
            this.currentOffer = v;
        } else
            this.currentOffer.castToMe(v);

        this.offerID = this.currentOffer.id;

        if (!this.noParent) {
            this.gc.setCurrentOffer(v);
        }
    }
    setCurrentEscrow(v: escrow) {
        if (v == null) {
            v = new escrow();
            this.currentEscrow = v;
        } else
            this.currentEscrow.castToMe(v);
        if (!this.noParent) {
            this.gc.setCurrentEscrow(v);
        }
    }
    setCurrentListing(v: listing) {
        if (v == null) {
            v = new listing();
            this.currentListing = v;
        } else
            this.currentListing.castToMe(v);

        if (!this.noParent) {
            this.gc.setCurrentListing(v);
        }
    }

    onChange(oOffer, sPropertyName, sValue) {
        if (oOffer == null) return;

        if (oOffer.setDirty) oOffer.setDirty(true);
        if (oOffer.isDirty == null) oOffer['isDirty'] = true;
        if (oOffer.isDirty === false) oOffer.isDirty = true;

        if (oOffer.isDirty != null && oOffer.isDirty === true) {
            this.triggerSave();
        }
    }

    changeStatus(action$, delegate) {

        if (this.currentOffer.isDirty === true || this.isSavePending()) {
            this.showWarning('Offer data has changed, please save the changes or wait for \"auto save\" to save all changes.');
            return;
        }

        if (this.gc.busy === true || this.saving === true) {
            setTimeout(() => { this.changeStatus(action$, delegate); }, appEnvironment.autoSaveTime);
            return;
        }

        this.gc.setBusy(true);
        let _actionResponse: BaseResponse;
        (new OfferService(this.dataservice, this.r, this.myResourceCategory)).changeStatus(parseInt(action$.argument), action$.name).subscribe(
            (r) => { _actionResponse = r; this.gc.setBusy(false); }
            , (error) => {
                this.gc.setBusy(false);
                this.currentOffer.canEdit = false;
                if (error && error.name && (error.name as string).toLowerCase().indexOf('timeout') > 0) {
                    this.showMessage('Your request is taking longer than expected. Refresh this page after few minutes, to get upto date information !!!!');
                } else if (delegate != null)
                    delegate(error);
                else
                    this.onApiError(error);
            }
            , () => {
                if (_actionResponse) {
                    if (!Utils.isNullOrEmpty(_actionResponse.error))
                        this.showError(_actionResponse.error);
                    else {
                        if (delegate == null) {
                            if (!Utils.isNullOrEmpty(_actionResponse.redirectURL)) {
                                this.gotoURL(_actionResponse.redirectURL);
                                return;
                            } else {

                                if (Utils.isNullOrEmpty(_actionResponse.message))
                                    _actionResponse.message = 'Action executed successfully.';
                                this.showMessage(_actionResponse.message);

                                if (_actionResponse.data) {
                                    if (_actionResponse.data.id && _actionResponse.data.id > 0) {
                                        // When New/Updated/Counter offer is returned.
                                        this.setCurrentOffer(_actionResponse.data);
                                    } else {
                                        this.currentOffer.UpdateAfterChangeStatus(_actionResponse.data);
                                    }
                                }
                            }
                        } else {
                                delegate(_actionResponse.data);
                        }
                    }
                }
            }
        );
    }

    showOfferMessage(id: number, bShowDialog: boolean = true) {
        this.componentBusy = true;

        if (id == null || id === 0)
            id = this.currentOffer.id;

        this.offerMessage = null;
        let _response;
        const _s = (new OfferService(this.dataservice, this.r, this.myResourceCategory)).getOfferMessage(id);
        if (_s) {
            _s.subscribe(
                (_data) => { _response = _data; }
                , (error) => { this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            if (Utils.isNullOrEmpty(_response.data))
                                _response.data = 'Offer Ready to Submit';
                            else
                                this.offerMessage = _response.data;

                            if (bShowDialog === true)
                                this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                        }
                }
            );
        }
    }

    showPreCloseMessage(id: number) {
        this.componentBusy = true;

        if (id == null || id === 0)
            id = this.currentOffer.escrowID;

        if (id > 0) {
            let _response;
            const _es = (new EscrowService(this.dataservice, this.r, this.myResourceCategory)).showPreCloseMessage(id);
            if (_es != null) {
                _es.subscribe(
                    (_data) => { _response = _data; }
                    , (error) => { this.componentBusy = false; this.onApiError(error); }
                    , () => {
                        this.componentBusy = false;
                        if (_response)
                            if (!Utils.isNullOrEmpty(_response.error))
                                this.showError(_response.error);
                            else {
                                this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                            }
                    }
                );
            }
        } else
            this.showMessage('Escrow is not Open');
    }

    showPurchaseAgreement(id: number) {
        this.componentBusy = true;

        if (id == null || id === 0)
            id = this.currentOffer.id;

        let _response;
        const _os = (new OfferService(this.dataservice, this.r, this.myResourceCategory)).getPurchaseAgreement(id);
        if (_os != null) {
            _os.subscribe(
                (_data) => { _response = _data; }
                , (error) => { this.componentBusy = false; this.onApiError(error); }
                , () => {
                    this.componentBusy = false;
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.dialog.open(HTMLDialog, { data: { html: _response.data } });
                        }
                }
            );
        }
    }

    protected initMe() {
        this.listingID = this.gc.listingID;
        this.offerID = this.gc.offerID;
        this.currentListing = this.gc.currentListing;
        this.currentOffer = this.gc.currentOffer;
        this.currentEscrow = this.gc.currentEscrow;
    }

    private triggerSave() {
        this.showSave();

        if (this._to != null && !this.saving)
            clearTimeout(this._to);

        this._to = setTimeout(() => {
            this.hideSave();
            this.save();
        }, appEnvironment.autoSaveTime);

        if (this.ss.subscribe !== undefined && this._ss == null) {
            this._ss = this.ss.subscribe(() => {
                clearTimeout(this._to);
                this.save();
                this._ss = null;
            });
        }
    }

    private save() {
        const _me = this;

        _me.gc.setBusy(true);
        _me.saving = true;

        let _response: BaseResponse;
        (new OfferService(_me.dataservice, _me.r, _me.myResourceCategory)).saveOffer(_me.currentOffer).subscribe((r) => _response = r
            , (error) => { _me.gc.setBusy(false); _me.saving = false; _me.onApiError(error); }
            , () => {
                _me.gc.setBusy(false);
                _me.saving = false;
                // IMPORTANT - KEEP LOCAL Listing Copy -- IGNORE Server returned values.
                if (_response) {
                    if (!Utils.isNullOrEmpty(_response.error)) {
                        _me.showError(_response.error);
                    } else {

                        _me.hideSave();
                        _me.currentOffer.isDirty = false;
                        _me.clearDirty(_me.currentOffer);
                        clearTimeout(_me._to);

                        _me.setCurrentOffer(_me.currentOffer);

                        _me.gc.showSnackBar('Changes Saved !!!!');
                    }
                }
            });
    }
}
