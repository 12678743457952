import { Component, ViewChild, Inject} from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { downloadableContent, mediaItem } from '../../models/appCommon.models';
import { EscrowService } from '../../escrow/escrow.service';

import { BaseDialog } from '../../base.dialog';
import { FileUploader } from 'ng2-file-upload';
import { Utils } from '../../common/utils';
const URL = '/api/';

@Component({
    selector: 'app-upload-contigency-document-dialog',
    templateUrl: './uploadContingencyDocument.dialog.html'
})
export class UploadContingencyDocumentDialog extends BaseDialog {
    saving: boolean = false;
    dialogHeader: string = 'Contingency';
    escrowID: number = 0;
    contingencyID: number = 0;
    doc: downloadableContent = null;
    media: mediaItem = new mediaItem();
    public uploader: FileUploader = new FileUploader({ url: URL });
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;
    @ViewChild('fulfilDoc') fulfilDoc: any;
    fileExtension: string = 'pdf';
    constructor(ds: DataService, r: Router, dialogRef: MatDialogRef<UploadContingencyDocumentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('uploadContingencyDocumentDialog', ds, r, dialogRef, data);

        this.media.type = 'upload';

        if (data) {
            if (data.id) this.contingencyID = this.data.id;
            if (data.escrowID) this.escrowID = this.data.escrowID;
        }

        if (this.escrowID === 0) {
            setTimeout(() => { this.close(null); }, 1000);
        }
    }


//#region FILE UPLOAD
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    fileUploaded($event): void {
        this.fileHandler($event);
        this.fulfilDoc.nativeElement.value = '';
    }


    fileHandler($event): void {
        const text = [];
        const _file = $event.srcElement.files;

        if (_file) {
            const _fName = _file[0].name;
            this.media.title = _fName;
            if (_fName.lastIndexOf('.') > 0) {
                const _fExtension = _fName.substring(_fName.lastIndexOf('.') + 1);
                if (this.fileExtension.indexOf(_fExtension) < 0) {
                    this.showMessage('Invalid file uploaded');
                    return;
                }
                this.doc = new downloadableContent();
                this.doc.name = _fName;
                if (_file[0].type)
                    this.doc.mime = _file[0].type;
            } else {
                this.showMessage('Invalid file uploaded');
                return;
            }
        }

        const input = $event.target;
        const reader = new FileReader();
        reader.readAsDataURL(input.files[0]);

        reader.onloadend = (data) => {
            this.doc.content = reader.result;
        };

        reader.onerror = () => {
            alert('Unable to read ' + input.files[0]);
        };
    }
    //#endregion

    close(data) {
        this.dialogRef.close(data);
    }


    saveReady() { return this.contingencyID > 0 && this.escrowID > 0 && this.doc && this.doc.content; }

    uploadDocument() {
        this.saving = true;
        let _response: any;
        const _es = (new EscrowService(this.dataservice, this.route, this.myResourceCategory)).uploadContingencyFulfilmentDocument(this.escrowID, this.contingencyID, this.doc);
        if (_es != null) {
            _es.subscribe(
                (data) => _response = data
                , (error) => {
                    this.close(null);
                    this.onApiError(error);
                }
                , () => {
                    if (_response)
                        if (!Utils.isNullOrEmpty(_response.error))
                            this.showError(_response.error);
                        else {
                            this.close(null);
                            this.saving = false;
                        }
                });
        }
    }
}
