import { Component, Input , Output, EventEmitter, Inject} from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DocumentService } from './documents/document.service';
import { EditHTMLDialog } from './dialogs/editHTML.dialog';
import { Utils } from './utils';

@Component({
    selector: 'app-document-action',
    templateUrl: './documentAction.component.html'
})
export class DocumentActionComponent extends BaseComponent {

    @Input() cssClass: string = 'auto';
    @Input() documents: any = [];
    @Input() placeholder: string = 'Document(s)';
    @Input() placeholderHelp: string = 'Document(s)';
    @Output() selectedDocumentID: number = 0;

    @Output() onDocumentChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(private ds: DataService, private rs: Router, @Inject(GlobalComponent) g: GlobalComponent, private dialog: MatDialog) {
        super('documentActions', rs, g);
    }

    documentChanged() {
        const _doc = this.documents.find((d) => d.id === this.selectedDocumentID );
        if (_doc) this.onDocumentChanged.emit(_doc);
    }

    removeDoc(oDocument: any) {
        let _response;
        (new DocumentService(this.ds, this.rs)).removeDocument(oDocument.id).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        if (_response.data) {
                            this.documents = this.documents.filter((d) => d.id !== oDocument.id );
                        }
                    }
            }
        );
    }

    editDoc(oDocument: any) {
        if (oDocument && !oDocument.isUploadedContent && !Utils.isNullOrEmpty(oDocument.content)) {
            const dialogRef = this.dialog.open(EditHTMLDialog, {
                data: {
                    content: oDocument.content
                }
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.onSaveDoc(oDocument.id, result);
                }
            });
        }
    }

    private onSaveDoc(id: number, sContent: string) {
        let _response;
        (new DocumentService(this.ds, this.rs)).saveHTMLDocument(id, sContent).subscribe(
            (data) => _response = data
            , (error) => {
                this.onApiError(error);
            }
            , () => {
                if (_response)
                    if (!Utils.isNullOrEmpty(_response.error))
                        this.showError(_response.error);
                    else {
                        if (_response.data) {
                            this.documents.forEach((d) => { if (d.id === id) d.content = sContent; });
                        }
                    }
            }
        );
    }
}
