<mat-card *ngIf="showHeader && listings && listings.length > 0">
    <mat-form-field>
        <mat-select [(ngModel)]="listingID">
            <mat-label class="placeholder">Properties</mat-label>
            <mat-option *ngFor="let l of listings" [value]="l.id">{{l.address}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-card>
<mat-card *ngIf="currentListing && currentListing.isSeller">
    NOTE: Please add all Liens which are attached to property, including tax and judgment liens. Escrow will not be able to close the transaction without clearing/fulfilling all mandatory liens.
    <br /><br />When a Title report is produced, various liens are noted on the title as well. It is for your benefit that this information is disclosed before hand.
    <br /><br />Information provided here will be available to Buyer's Lender (if any), Escrow and Title company part of the transaction. Lien Holder and Lien Amount <label class="infoLabel bold">will be</label>  disclosed to the buyer, as part of disclosure.
    <a (click)="addNewLien()" *ngIf="canEditComponent" class="float-right"><i class="material-icons warning">add_circle</i></a>
</mat-card>
<div>
    <br /><br />
    <ng-template [ngIf]="(this.liens && this.liens.length > 0)" [ngIfElse]="noLiens">
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let lien of liens; let i = index" [expanded]="accordionStep === i" (opened)="accordionSetStep(i)">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{lien.lenderName}}</mat-panel-title>
                    <mat-panel-description style="display:initial">
                        <a (click)="removeLien($event, lien.id)" *ngIf="canEditComponent" class="float-right"><i class="fa fa-trash danger"></i></a>
                        <span class="float-right" style="padding-right:20px">{{FormatNumberToCurrency(lien.amount)}}</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="section">
                    <label class="caption">Reference #</label>&nbsp;{{lien.referenceNumber}}<br />
                    <label class="caption">Recording Date</label>&nbsp;{{utils.DisplayDate(lien.fundingDate)}}<br />
                    <label class="caption">As Of Date</label>&nbsp;{{utils.DisplayDate(lien.CreateDate)}}
                </div>
                <mat-action-row *ngIf="liens.length > 1">
                    <button mat-button color="warn" (click)="accordionPrevStep()" *ngIf="accordionStep > 0 && liens.length > 1">Previous</button>
                    <button mat-button color="primary" (click)="accordionNextStep()" *ngIf="accordionStep < liens.length && liens.length > 1">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
    <ng-template #noLiens>
        <h3>Lien details for this property not provided.</h3>
    </ng-template>
</div>