import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { appEnvironment } from '../../../environments/environment';

@Component({
    selector: 'app-html-dialog'
    ,template: `
<div mat-dialog-title>
    <h4>&nbsp;</h4>
    <a class="close" title="Close"  (click)="close()"><i class="fa fa-times"></i></a>
    <hr/>
    <button matButton *ngIf=showConfirm (click)=confirm()>{{confirmLabel}}</button>
</div>
<div mat-dialog-content hrefToRouterLink [innerHTML]="html | safeHtml"></div>
`
})
export class HTMLDialog implements OnInit {
    styles: string = '';
    html: string = 'Data Not Available';
    showConfirm: boolean = false;
    confirmLabel: string = 'Confirm';
    constructor( public dialogRef: MatDialogRef<HTMLDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        if (this.data) {
            if (this.data.styles) this.styles = this.data.styles;
            if (this.data.html) this.html = '<style>' + this.styles + '</style>' + this.data.html;
        }

        if (this.data == null || this.data.html == null || this.data.html.toString().trim() === '') {
            setTimeout(() => {
                this.close();
            }, appEnvironment.delayedNGInit);
        }
    }

    confirm() {
        this.dialogRef.close(true);
    }

    close() { this.dialogRef.close(); }
}
