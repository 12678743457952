<footer class="page-footer text-center font-small mt-4 wow fadeIn" id="appFooter">
    <div style="height:5px;background-color:none">
        <progress name="divMainBusy" id="divMainBusy" style="display:none;width:100%" data-label="Busy"></progress>
    </div>
    <hr class="my-4">
    <div class="pb-4">
        <a (click)="gotoRoute(appURLs.help)" *ngIf="!gc.stealthMode">How To</a> | 
        <a (click)="gotoRoute(appURLs.findHome)" class='btn btn-blue' title="Start your home search and more." *ngIf="!gc.stealthMode">Find a Home</a> |
        <a (click)="start(appURLs.sellerNewListing)" class="btn btn-white" *ngIf="!gc.stealthMode">List my Property</a> |
        <a (click)="gotoRoute(appURLs.serviceProviderSearch)" title="Search Professional who can help support you during transaction processing." class="btn btn-white" *ngIf="!gc.stealthMode">Find Professionals</a> |
        <!--| <a (click)=gotoRoute(appURLs.agentSearch)>Find an Agent</a> -->
        <a (click)="gotoRoute(appURLs.aboutUs, '', true)" title="Read us who we are and our Mission">About Us</a> |
        <a (click)="gotoRoute(appURLs.contactUs, '', true)" title="How to reach us.">Contact Us</a> |
        <a href="https://www.facebook.com/iamorea" target="_blank" title="Visit our Facebook Page" *ngIf="!gc.stealthMode">
            <img src="/assets/images/fb.svg">
        </a>
        <a href="https://twitter.com/_iamorea" target="_blank" title="Read and subscribe to our Twitter Channel" *ngIf="!gc.stealthMode">
            <i class="fa fa-twitter mr-3"></i>
        </a>
        <br />
    </div>
    <div class="footer-copyright py-3">
        {{this.myBRAND}} supports non discriminatory housing in compliance with the Federal Fair Housing Act<br />
        <span>
            <a (click)="gotoRoute(appURLs.privacyPolicy, '', true)"> Privacy Policy</a> |  <a (click)="gotoRoute(appURLs.termsOfUse, '', true)"> Terms Of Use </a>
            |  <a (click)="gotoRoute(appURLs.unsubscribe, '', false)"> Unsubscribe </a>
        </span>
        © 2016 Copyright: <a [routerLink]="'/'"> {{this.myBRAND.toLowerCase()}}.com </a> <span class="mobileHide" style="float:left" *ngIf="ip != null && ip.toString().trim() !== ''">&nbsp;IP:{{ip}}</span>
                                                                                         <span *ngIf="buildInfo != null && buildInfo.trim() != ''">&nbsp;[{{buildInfo}}]</span>
    </div>
</footer>
<div style="position: absolute; z-index: 999 !important; color: black; width: 100%; background-color: white; display: none; border-top: 1px solid black; border-bottom: 1px solid black" id="divCookiePolicy">
    <div style="width:75%;display: inline-block;padding:20px;font-weight:bold">
        <ul style="font-size: 12px;">
            <li>IAMOREA writes cookies to deliver better user experience</li>
            <li>Cookies help us display personalized product recommendations and&nbsp;ensure you have great experience</li>
            <li>You can review our detailed privacy policy at <a [routerLink]="'/privacypolicy'">www.iamorea.com/privacypolicy</a></li>
        </ul>
    </div>
    <div style="width:23%;display: inline-block;">
        <a class="button" style="background-color: forestgreen;padding: 10px;float: right;" (click)="agreedCookiePolicy()">I understand</a>
    </div>
</div>
<!--Source History has Fat footer details -->
