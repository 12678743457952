<div class="appContent">
    <h2 class="pageHeader">List my Property</h2>
    <div *ngIf="renewListingID === 0 ">
        <mat-card>
            <p>
                Please select a category which best fits your property. If you think your property does not fit in one of the categories listed here, <a (click)="gotoRoute(appURLs.contactUs)" class="link">Contact us</a> to submit an offline request.
            </p>
            <p>Once you list your property, you can proceed to setup an OPEN HOUSE as well. <a (click)="gotoRoute(appURLs.help)" class="link">Learn more....</a></p>
        </mat-card>
        <br />
        <mat-card>
            <div class="app-flex-container app-2col">
                <mat-form-field>
                    <mat-label class="placeholder">Property Types <i class="fa fa-question-circle-o warning-color" title="Select a property type"></i></mat-label>
                    <mat-select [(ngModel)]="newListing.propertyProductID" (selectionChange)="propertyTypeChanged()" (ngModelChange)="setSaveReady()">
                        <mat-option *ngFor="let pt of propertyTypes" [value]="pt.id" class="auto"><span [innerHTML]="pt.icon"></span> {{pt.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="medium" *ngIf="newListing.propertyProductID > 0">
                    <input matInput appDisableAutofill placeholder="Selling Price" maxlength="12" [(ngModel)]="newListing.sellingPrice" class="numeric" type="number" (keydown)="utils.numOnlyLimitingLength($event, 12, false)" autofocus autoFocus (change)="propertyTypeChanged()" />
                </mat-form-field>
            </div>
            <div *ngIf="newListing.propertyProductID > 0" class="first">
                <div *ngIf="newListing.listingPrice > 0" class="bold light-blue small-padding">Listing Order Fee (Amount you will pay today to {{myBRAND}}) - {{newListing.listingPrice.formatMoney()}}<br /></div>
                <div *ngIf="newListing.listingPrice <= 0" class="bold small-padding">Listing fee waived by {{myBRAND }}.<br /></div>
                <div *ngIf="newListing.isAgent === true">
                    <ng-template [ngIf]="newListing.sellingPrice > 0" [ngIfElse]="noPrice">
                        <div *ngIf="newListing.commission > 0" class="bold light-blue small-padding">Commission Fee (Amount you will pay to {{myBRAND}} on close of Escrow) - {{newListing.commission.formatMoney()}}<br /></div>
                        <div *ngIf="newListing.commission <= 0" class="bold small-padding">{{myBRAND}} Commission fee waived.<br /></div>
                    </ng-template>
                    <ng-template #noPrice>
                        <div *ngIf="newListing.commission > 0" class="bold light-blue small-padding">Commission fee to {{myBRAND}}, will be determined based on selling price<br /></div>
                        <div *ngIf="newListing.commission <= 0" class="bold small-padding">Commission fee waived by {{myBRAND}}.<br /></div>
                    </ng-template>
                </div>
            </div>
        </mat-card>
        <br />

        <mat-card [ngClass]="{'hidden' : newListing.propertyProductID == 0}">
            <mat-expansion-panel [expanded]="newListing.tncAgreed">
                <mat-expansion-panel-header>Address</mat-expansion-panel-header>
                <div class="section">
                    <app-base-address [showOwnerShip]="false" #listingAddress [canChangeAddressType]="false" (addressChanged)="addressChanged($event)"></app-base-address>
                </div>
            </mat-expansion-panel>
            <div *ngIf="listingAddress != null && !listingAddress.IsComplete" class="danger bold">
                Address is incomplete, your property will NOT be displayed to prospective buyers until address is completed.
            </div>
        </mat-card>
        <br />
        <mat-card *ngIf="loggedIn === true && newListing.isAgent === true">
            <div class="app-flex-container app-3col">
                <mat-checkbox [(ngModel)]="newListing.isAgent">I am an Agent and listing this property for my Customer <i class="fa fa-question-circle-o warning-color" title="You can add Seller's information after creating Listing. If you want to SELL your own home? Create/Use a different account."></i></mat-checkbox>
                <mat-checkbox *ngIf="newListing.isAgent === true" [(ngModel)]="newListing.isExclusive">This is exclusive listing</mat-checkbox>
                <span>
                    <app-datepicker #txtExpiryDate id="txtExpiryDate" [(value)]="newListing.expiryDate" [placeholder]="'Expiry Date'" (valueChanged)="expiryDateChanged($event)" [minDate]="tomorrow"></app-datepicker>
                </span>
            </div>
        </mat-card>
    </div>
    <div *ngIf="hasTerms">
        <br />
        <h4 class="tab-pane"><span class="legal">Listing Terms and Conditions. Please scroll and read the entire content to accept listing terms and conditions.</span></h4>
        <hr />
    </div>
    <mat-card *ngIf="hasTerms" class="scroll-container">
        <div hrefToRouterLink [innerHTML]="legalContent | safeHtml"></div>
        <div class="tab-pane">
            <mat-checkbox [(ngModel)]="newListing.tncAgreed" (change)="onAgreementChanged($event)">I have reviewed and agree to Listing Terms and Conditions.</mat-checkbox>
        </div>
    </mat-card>
    <mat-card *ngIf="saving === false && (newListing?.listingPrice ?? 0) > 0 && saveReady === true">
        <mat-card>
            I agree to pay {{listingPrice.formatMoney()}} to {{myBRAND}}.
        </mat-card>
        <ngx-braintree [clientTokenURL]="braintreeClientTokenEndPoint"
                       [createPurchaseURL]="servicePurchaseEndPoint"
                       [chargeAmount]="newListing.listingPrice"
                       (paymentStatus)="onPaymentStatus($event)"
                       (dropinLoaded)="onDropinLoaded($event)"
                       [enablePaypalCheckout]="false"
                       [currency]="'USD'"
                       [buttonText]="'Submit'">
        </ngx-braintree>
    </mat-card>
    <div *ngIf="saving === false && saveReady === true" class="section">
        <a class="btn btn-primary" (click)="submit()">Submit</a>
    </div>
    <mat-progress-bar mode="buffer" *ngIf="saving"></mat-progress-bar>
    <hr/>
    <mat-accordion class="hideDetailsOnCollapse">
        <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>Next Steps after Listing</mat-expansion-panel-header>
            <ul>
                <li class="star"><strong>Complete Pricing and Property Details</strong></li>
                <li class="star"><strong>Publish your Listing</strong></li>
                <li class="star"><strong>Buyer(s) Can Submit Instant Offer</strong></li>
                <li class="star"><strong>Compare, Counter and Accept Offer</strong></li>
                <li class="star"><strong>Hire Service Provider(s) to Complete Sale</strong></li>
            </ul>
        </mat-expansion-panel>
    </mat-accordion>
</div>