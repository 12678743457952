import { Component, ChangeDetectorRef, ViewChild, Inject, OnInit } from '@angular/core';

import { DataService } from '../util/APICaller.component';
import { BaseComponent } from 'src/app/base.component';
import { GlobalComponent } from 'src/app/global.component';
import { Router, Route } from '@angular/router';

import { LicenseEntity } from '../models/providers/license.model';
import { CodeService } from '../common/service/code.service';
import { ServiceProviderService } from './serviceprovider.service';

import { item } from '../models/appCommon.models';
import { trigger } from '@angular/animations';
import { appEnvironment } from '../../environments/environment';
import { navIA } from '../models/constants';
import { Utils, rolePosition } from '../common/utils';

@Component({
    selector: 'app-provider-license',
    templateUrl: './licenses.component.html'
})
export class LicenseComponent extends BaseComponent implements OnInit {

    loading: boolean = true;
    reorderable: boolean = true;
    swapColumns: boolean = false;
    allLicenseTypes: item[] = [];
    licenseTypes: item[] = [];
    messages = {
        // Message to show when array is presented
        // but contains no values
        emptyMessage: 'You have not provided any License details.',

        // Footer total message
        // totalMessage: 'total'
    };

    allowAdd: boolean = false;
    allowEdit: boolean = false;

    editing = {};
    public licenses: LicenseEntity[] = [];
    columns = [{ prop: 'LicenseType', name: 'License Type' }, { prop: 'IssuingAuthority', name: 'Issued By', sortable: false }, { prop: 'LicenseNumber', name: 'License Number', sortable: false }
        , { prop: 'ExpiryDate', name: 'Expiry Date', sortable: false }];
    saving: number = 0;

    private _to: any;

    constructor(private dataservice: DataService, private r: Router, @Inject(GlobalComponent) private g: GlobalComponent, private cdref: ChangeDetectorRef) {
        super('pgCompanyLicenses', r, g);

        this.allowEdit = document.getElementById(navIA) != null || this.gc.hasRole(rolePosition.admin);
        this.setAllowAdd();
    }
    ngOnInit() {

        let userResponseData;
        (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).getLicenses().subscribe((r) => userResponseData = r
            , (error) => { this.loading = false; this.licenses = new Array<LicenseEntity>(); this.onApiError(error); }
            , () => {
                this.loading = false;

                // To use IsDirty and other Client Side Properties, have to USE Cast ME
                if (userResponseData.data)
                    this.licenses = userResponseData.data.map((c) => {
                        return Utils.castTo(c, new LicenseEntity());
                    });

                const existingTypes: number[] = this.licenses.map((c) => { return c.LicenseTypeID; });

                let stResponseData;
                (new CodeService(this.dataservice, this.r, this.myResourceCategory)).getLicenseTypes().subscribe((r) => stResponseData = r
                    , (error) => { this.licenseTypes = new Array<item>(); this.onApiError(error); }
                    , () => {
                        this.allLicenseTypes = stResponseData.data;
                        this.licenseTypes = this.allLicenseTypes.filter((c) => { return !existingTypes.includes(c.id); });

                        this.licenses.forEach((l) => { l.LicenseType = this.allLicenseTypes.find((lt) => l.LicenseTypeID === lt.id).name; });
                    });
            });
    }

    edit(cell, editable) {
        if (this.allowEdit)
            this.editing[cell] = editable;
    }

    updateValue(value, cell, rowIndex) {
        this.editing[rowIndex + '-' + cell] = false;

        if (cell === 'LicenseType') {
            let _st: number = 0;
            _st = parseInt(value);
            if (isNaN(_st)) _st = 0;
            this.licenses[rowIndex].LicenseTypeID = _st;
            this.licenses[rowIndex].LicenseType = this.allLicenseTypes.find((c) => { return c.id === _st; }).name;
            const existingTypes: number[] = this.licenses.map((c) => { return c.LicenseTypeID; });
            this.licenseTypes = this.allLicenseTypes.filter((c) => { return !existingTypes.includes(c.id); });
        } else {
            this.licenses[rowIndex][cell] = value;
        }

        this.licenses = [...this.licenses];
        this.setAllowAdd();
        this.triggerSave();
        this.cdref.detectChanges();
    }

    addLicense() {
        this.loading = true;

        const _o = new LicenseEntity();
        _o.isDirty = true;

        const existingTypes: number[] = this.licenses.map((c) => { return c.LicenseTypeID; });
        const _t = this.allLicenseTypes.filter((c) => { return !existingTypes.includes(c.id); });
        if (_t) {
            _o.LicenseTypeID = _t[0].id;
            _o.LicenseType = _t[0].name;
        }

        this.licenses.push(_o);
        this.licenses = [...this.licenses];
        this.editing[(this.licenses.length - 1).toString() + '-LicenseType'] = true;
        this.editing[(this.licenses.length - 1).toString() + '-IssuingAuthority'] = true;
        this.editing[(this.licenses.length - 1).toString() + '-LicenseNumber'] = true;
        this.editing[(this.licenses.length - 1).toString() + '-ExpiryDate'] = true;
        this.loading = false;
        this.setAllowAdd();
        this.cdref.detectChanges();
    }

    private triggerSave() {

        if (this._to && this.saving <= 0) clearTimeout(this._to);
        if (this.saving < 0) this.saving = 0;

        this._to = setTimeout(() => { this.save(this.licenses); }, appEnvironment.autoSaveTime);
    }


    private save(items: LicenseEntity[]) {
        let _itemsToUpdate: number = 0;

        if (items != null)
            _itemsToUpdate = items.filter((c) => { return c.ServiceProviderLicenseID === 0 || c.isDirty; }).length;

        if (_itemsToUpdate > 0) {
            const _readyToSave = items.filter((c) => { return (c.id === 0 || c.isDirty) && c.IsComplete(); });
            if (_readyToSave == null || _readyToSave.length === 0) return;

            // To Save Data for Objects with Properies having GET/SET, have to use toJSON() and JSON.parse
            const _saveData: object[] = _readyToSave.map(x => { return x; });
            const _data = { serviceProviderID: this.user.details.CompanyID, licenses: _saveData };

            // Call Save API
            let saveResponseData;
            this.saving++;
            (new ServiceProviderService(this.dataservice, this.r, this.myResourceCategory)).saveLicenses(_data).subscribe((r) => saveResponseData = r
                , (error) => {
                    this.saving--; items = new Array<LicenseEntity>(); this.onApiError(error);
                }
                , () => {
                    this.saving--;
                    this.editing = [];

                    if (saveResponseData.data)
                        items = saveResponseData.data.map((c) => { return Utils.castTo(c, new LicenseEntity()); });

                    if (items)
                        this.licenses = [...items];
                    else
                        this.licenses = new Array<LicenseEntity>();
                    this.setAllowAdd();

                });
        }
    }

    private setAllowAdd() {
        // Find any item with Service Type == 0
        this.allowAdd = this.allowEdit && this.licenses != null
            && (this.licenses.filter((c) => { return c.ServiceProviderLicenseID && c.ServiceProviderLicenseID != null && c.ServiceProviderLicenseID === 0; }).length === 0);
    }
}
