import { Component, ChangeDetectorRef, ViewChild, Inject} from '@angular/core';
import { FormBuilder} from '@angular/forms';

import { DataService } from '../../util/APICaller.component';
import { BaseComponent } from '../../base.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { item, downloadableContent } from '../../models/appCommon.models';

import { ListingService } from '../../listing/listing.service';
import { OfferService } from '../../offer/offer.service';
import { Role } from 'src/app/models';
import { BaseDialog } from '../../base.dialog';
import { Utils } from '../utils';

@Component({
    selector: 'app-power-of-attorney-dialog',
    templateUrl: './powerOfAttorney.dialog.html'
})
export class PowerOfAttorneyDialog extends BaseDialog {

    entityID: number = 0; roleID: number = 0;
    pidWithPOA: number = 0;
    partners: item[] = new Array<item>();
    doc: downloadableContent = null;
    myRole: Role = Role.NotSet;

    saving: boolean = false;
    fileExtension: string = 'pdf';

    @ViewChild('poaDOC') poaDOC: any;

    constructor(dataservice: DataService, r: Router
        , dialogRef: MatDialogRef<PowerOfAttorneyDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super('powerOfAttorneyDialog', dataservice, r, dialogRef, data);

        this.dialogHeader = 'Power Of Attorney';

        if (data) {
            if (data.id) this.entityID = this.data.id;
            if (data.roleID) this.roleID = this.data.roleID;
            if (data.partners) this.partners = this.data.partners;
            if (data.myRole) this.myRole = this.data.myRole;
            if (data.header) this.dialogHeader = this.data.header;
        }

        if (this.entityID == 0 || this.partners == null || this.partners.length == 0 || (this.myRole != Role.Buyer && this.myRole != Role.Seller)) {
            setTimeout(() => { this.close(null) }, 1000);
        }
    }


    fileUploaded($event): void {
        this.fileHandler($event);
        this.poaDOC.nativeElement.value = '';
    }

    fileHandler($event): void {
        const text = [];
        const _file = $event.srcElement.files;

        if (_file) {
            const _fName = _file[0].name;

            if (_fName.lastIndexOf('.') > 0) {
                let _fExtension = _fName.substring(_fName.lastIndexOf('.') + 1);
                if (this.fileExtension.indexOf(_fExtension) < 0) {
                    this.showMessage('Invalid file uploaded');
                    return;
                }
                this.doc = new downloadableContent();
                this.doc.name = _fName;
                if (_file[0].type)
                    this.doc.mime = _file[0].type;
            }
            else {
                this.showMessage('Invalid file uploaded');
                return;
            }
        }

        const input = $event.target;
        const reader = new FileReader();
        reader.readAsDataURL(input.files[0]);

        reader.onloadend = (data) => {
            this.doc.content = reader.result;
        }
        reader.onerror = function () {
            alert('Unable to read ' + input.files[0]);
        };
    };

    get saveReady() {
        return this.doc && this.doc.content != null && this.pidWithPOA > 0;
    }

    assumePOA() {
        let __response;

        if (this.myRole == Role.Seller) {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).assumePowerOfAttorney(this.entityID, this.roleID, this.pidWithPOA, this.doc).subscribe(
                (data) => __response = data
                , (error) => { this.onApiError(error); }
                , () => { this.onResponse(__response); }
            );
        }
        else {
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).assumePowerOfAttorney(this.entityID, this.roleID, this.pidWithPOA, this.doc).subscribe(
                (data) => __response = data
                , (error) => { this.onApiError(error); }
                , () => { this.onResponse(__response); }
            );
        }
    }

    givePOA() {
        let __response;

        if (this.myRole == Role.Seller) {
            (new ListingService(this.dataservice, this.route, this.myResourceCategory)).givePowerOfAttorney(this.entityID, this.roleID, this.pidWithPOA, this.doc).subscribe(
                (data) => __response = data
                , (error) => { this.onApiError(error); }
                , () => { this.onResponse(__response); }
            );
        }
        else {
            (new OfferService(this.dataservice, this.route, this.myResourceCategory)).givePowerOfAttorney(this.entityID, this.roleID, this.pidWithPOA, this.doc).subscribe(
                (data) => __response = data
                , (error) => {this.onApiError(error);}
                , () => {this.onResponse(__response);}
            );
        }
    }

    private onResponse(reponse) {
        if (reponse)
            if (!Utils.isNullOrEmpty(reponse.error))
                this.showError(reponse.error);
            else {
                this.close(true);
            }
    }
}