import { Router, Route } from '@angular/router';
import { BaseService } from './base.service';
import { DataService } from '../../util/APICaller.component';

import { Injectable, Inject} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AssetService extends BaseService {

    constructor(ds: DataService, r: Router, @Inject(String) sModule: string = '') {
        super(r, sModule, ds);
    }

    getAssetContent(id, forEdit: boolean = false) {
        const data = { id, forEdit };
        return this._dataService.APIPreLoginPost('/asset/content', data, this.requestingModule);
    }

    getAssetHelpContent(id) {
        const data = { id };
        return this._dataService.APIPreLoginPost('/asset/helpContent', data, this.requestingModule);
    }

    getAssetRoute(id) {
        const data = { id };
        return this._dataService.APIPreLoginPost('/asset/route', data, this.requestingModule);
    }

    getAssetForURL(sRoute, withHelp: boolean = false) {
        const data = { value: sRoute, forHelp: withHelp };
        return this._dataService.APIPreLoginPost('/asset/assetForURL', data, this.requestingModule);
    }

    saveAssetContent(id, title, content) {
        const data = { id, title, content };
        return this._dataService.APIPost('/asset/saveContent', data, this.requestingModule);
    }

    publishAssetContent(id) {
        const data = { id };
        return this._dataService.APIPost('/asset/publishAsset', data, this.requestingModule);
    }

    getStaticAssets() {
        return this.getCacheData('/asset/staticAssets');
    }

    getAsset(id: number, forEdit: boolean = false) {
        const data = { id, forHelp: true, forEdit };
        return this._dataService.APIPreLoginPost('/asset/asset', data, this.requestingModule);
    }

    getTags(id: number) {
        const data = { id };
        return this._dataService.APIPreLoginPost('/asset/tags', data, this.requestingModule);
    }

    refreshTagsCache() {
        const data = {};
        return this._dataService.APIPost('/asset/refreshTagsCache', data, this.requestingModule);
    }

    saveTags(id: number, oTags: any) {
        const data = { id, tags: oTags };
        return this._dataService.APIPost('/asset/saveTags', data, this.requestingModule);
    }

    saveAsset(data: any) {
        return this._dataService.APIPost('/asset/save', data, this.requestingModule);
    }

    saveHelpContent(data: any) {
        return this._dataService.APIPost('/asset/saveHelpContent', data, this.requestingModule);
    }

    changeHelpCategory(data: any) {
        return this._dataService.APIPost('/asset/changeHelpCategory', data, this.requestingModule);
    }

    getHelpTree() {
        return this._dataService.APIPreLoginPost('/asset/helpTree', null, this.requestingModule);
    }

    assetTree(bLoggedIn: boolean) {
        if (bLoggedIn)
            return this._dataService.APIPost('/asset/assetTree', null, this.requestingModule);
        else
            return this._dataService.APIPreLoginPost('/asset/assetTree', null, this.requestingModule);
    }

    search(sValue: string) {
        const data = { value: sValue };
        return this._dataService.APIPreLoginPost('/asset/search', data, this.requestingModule);
    }
}
