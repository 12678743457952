import { Component, ViewChild, Inject, OnInit } from '@angular/core';

import { DataService } from '../../util/APICaller.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ComplianceItemNegotiationComponent } from '../edit/complianceItems.component';
import { BaseDialog } from '../../base.dialog';

@Component({
    selector: 'app-offer-compliance-item-dialog',
    templateUrl: './complianceItem.dialog.html'
})
export class ComplianceItemDialog extends BaseDialog implements OnInit {
    offerID: number = 0;
    dialogHeader = 'Compliance Items';

    @ViewChild('complianceItems') complianceItems: ComplianceItemNegotiationComponent;

    constructor(ds: DataService, private r: Router, dialogRef: MatDialogRef<ComplianceItemDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super('addComplianceItemDialog', ds, r, dialogRef, data);
    }


    ngOnInit() {
        if (this.data) {
            if (this.data.id) this.offerID = this.data.id;
            if (this.data.header) this.dialogHeader = this.data.header;
        }

        if (this.offerID > 0 && this.complianceItems) {
            this.complianceItems.setOfferID(this.offerID);
        }

        if (this.offerID === 0) {
            setTimeout(() => { this.close(null); }, 1000);
        }
    }

    close(data) {
        this.dialogRef.close(data);
    }
}
