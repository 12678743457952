<div [ngClass]="{'hidden': !canChangeAddressType}">
	<mat-form-field class="auto">
		<mat-select placeholder="Address Type" [(ngModel)]="_address.AddressType" (selectionChange)="addressUpdated(true)">
			<mat-option *ngFor="let t of addressTypes" [value]="t.id" class="auto">{{t.name}}</mat-option>
		</mat-select>
	</mat-form-field>
</div>
<div class="app-flex-container app-2col">
	<div>
		<app-autocomplete [value]="_address.ISOCountryCode" [required]="true" (onItemSelected)="countrySelected($event, true)" [id]="'acCountry' + id" [values]="Countries" 
						  [placeholder]="'Country'" [itemClass]="'full'"></app-autocomplete>
	</div>
	<div>
		<app-autocomplete [value]="_address.StateID" (onItemSelected)="stateSelected($event, true)" [id]="'acState' + id" [placeholder]="'State'" [allowNew]="true"
						  [itemClass]="'full'"></app-autocomplete>
	</div>
</div>
<div class="app-flex-container app-2col">
	<mat-form-field>
		<input matInput appDisableAutofill trim="blur" placeholder="Street Number and Address" maxlength="50" [(ngModel)]="_address.StreetName" required (change)="addressUpdated(true)" />
	</mat-form-field>
	<mat-form-field>
		<input matInput appDisableAutofill trim="blur" placeholder="Additonal Details" maxlength="50" [(ngModel)]="_address.StreetAdditionalInformation" (change)="addressUpdated(true)" />
	</mat-form-field>
</div>
<div class="app-flex-container app-2col">
	<div>
		<app-autocomplete [value]="_address.CityID" (onItemSelected)="citySelected($event, true)" [id]="'acCity' + id" [placeholder]="'City'" [allowNew]="true"
						  [required]="_address.ISOCountryCode > 0" [itemClass]="'full'"></app-autocomplete>
	</div>
	<mat-form-field>
		<input matInput appDisableAutofill trim="blur" placeholder="PostalCode" maxlength="10" [(ngModel)]="_address.PostalCode" required (change)="addressUpdated(true)" />
	</mat-form-field>
</div>
<div class="app-flex-container app-2col" [ngClass]="{'hidden': !showOwnerShip}">
	<mat-form-field>
		<mat-select placeholder="Ownership Type" [(ngModel)]="_address.Ownership" (selectionChange)="addressUpdated(true)">
			<mat-option *ngFor="let t of ownershipTypes" [value]=t.id>{{t.name}}</mat-option>
		</mat-select>
	</mat-form-field>
	<app-datepicker #txtOSD [id]="idOSD" [(value)]="_address.OwnershipStartDate" [placeholder]="'Moved in'" (valueChanged)="startDateChanged($event)"></app-datepicker>
</div>
